"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var batch_restore_service_1 = require("../batch-restore.service");
var feedback_service_1 = require("../../notifications/feedback.service");
var DialogRestoreComponent = /** @class */ (function () {
    function DialogRestoreComponent(batchRestoreService, activeModal, feedbackService) {
        this.batchRestoreService = batchRestoreService;
        this.activeModal = activeModal;
        this.feedbackService = feedbackService;
        this.selected = [];
    }
    DialogRestoreComponent.prototype.ngOnInit = function () {
        this.batchRestoreService.init();
        this.batchView = this.batchRestoreService.view;
        this.dlgView = {
            itemlist: this.selected,
            action: 'restore',
            title: 'Restore items',
            btnExecuteText: 'Restore',
            btnExecuteClass: 'btn btn-success',
            confirm: {
                check: false,
                show: false,
                notices: [],
                txt: ' Deleting files frees up your account\'s disk usage',
            },
        };
    };
    DialogRestoreComponent.prototype.getClass = function (syncID) {
        return this.batchRestoreService.isCompleted(syncID) ? '' : 'deleted';
    };
    DialogRestoreComponent.prototype.closeDialog = function () {
        if (this.batchRestoreService.view.spinner !== 0) {
            var c = window.confirm('Are you sure you want to cancel?');
            if (c) {
                this.batchRestoreService.cancel();
                this.activeModal.close();
            }
        }
        else {
            this.activeModal.close();
        }
    };
    DialogRestoreComponent.prototype.removeFromList = function (listitem) {
        for (var i = this.dlgView.itemlist.length - 1; i >= 0; i--) {
            if (this.dlgView.itemlist[i].sync_id == listitem.sync_id) {
                this.dlgView.itemlist.splice(i, 1);
                break;
            }
        }
    };
    DialogRestoreComponent.prototype.sortList = function (a, b) {
        if (a.type < b.type) {
            return -1;
        }
        else if (a.type > b.type) {
            return 1;
        }
        else {
            if (a.search_name < b.search_name) {
                return -1;
            }
            else if (a.search_name > b.search_name) {
                return 1;
            }
        }
    };
    DialogRestoreComponent.prototype.execute = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sync_id_array, list, files, directories, i, successMsg, error_1, errorMsg;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sync_id_array = [], list = this.dlgView.itemlist
                            ? this.dlgView.itemlist.sort(function (a, b) { return _this.sortList(a, b); })
                            : [];
                        files = 0, directories = 0;
                        for (i = list.length - 1; i >= 0; i--) {
                            sync_id_array.push(list[i].sync_id);
                            if (list[i].type && list[i].type.toUpperCase() === 'DIR') {
                                directories++;
                            }
                            else {
                                files++;
                            }
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.batchRestoreService.execute(sync_id_array).toPromise()];
                    case 2:
                        _a.sent();
                        successMsg = list.length + " file" + (files > 1 ? 's' : '') + " restored";
                        if (directories && files) {
                            successMsg = list.length + " items restored";
                        }
                        else if (directories) {
                            successMsg = list.length + " folder" + (directories > 1 ? 's' : '') + " restored";
                        }
                        this.feedbackService.setFeedback('success', successMsg, 10000);
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        errorMsg = list.length + " file" + (files > 1 ? 's' : '') + " failed to restore";
                        if (directories && files) {
                            errorMsg = list.length + " items failed to restore";
                        }
                        else if (directories) {
                            errorMsg = list.length + " folder" + (directories > 1 ? 's' : '') + " failed to restore";
                        }
                        this.feedbackService.setFeedback('danger', errorMsg, 10000);
                        return [3 /*break*/, 5];
                    case 4:
                        this.activeModal.close();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return DialogRestoreComponent;
}());
exports.DialogRestoreComponent = DialogRestoreComponent;
