"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var i0 = require("@angular/core");
var MemoizationService = /** @class */ (function () {
    function MemoizationService() {
        this.memoizedValues = {};
        this.cacheExpiration = 30 * 60 * 1000; // 30 minute in milliseconds
    }
    MemoizationService.prototype.memoize = function (key, func) {
        var _this = this;
        var cachedValue = this.memoizedValues[key];
        if (!cachedValue || this.isCacheExpired(cachedValue.timestamp)) {
            var result = func();
            this.memoizedValues[key] = {
                value: result,
                timestamp: Date.now(),
            };
            rxjs_1.timer(this.cacheExpiration)
                .pipe(operators_1.take(1), operators_1.switchMap(function () { return _this.memoize(key, func); }))
                .subscribe();
            return result;
        }
        return cachedValue.value;
    };
    MemoizationService.prototype.isCacheExpired = function (timestamp) {
        var currentTime = Date.now();
        return currentTime - timestamp > this.cacheExpiration;
    };
    MemoizationService.ngInjectableDef = i0.defineInjectable({ factory: function MemoizationService_Factory() { return new MemoizationService(); }, token: MemoizationService, providedIn: "root" });
    return MemoizationService;
}());
exports.MemoizationService = MemoizationService;
