<div class="modal-header">
    <button
        type="button"
        class="close"
        (click)="close.emit(false)"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">
        <a class="showhand syncblue" (click)="onState(1)">
            <i class="zmdi zmdi-chevron-left zmdi-hc-lg"></i>
        </a>
        &nbsp;
        <span>Link settings {{ link.name }}</span>
    </h4>
</div>

<form
    autocomplete="off"
    (submit)="linkForm.valid && onSubmit()"
    [formGroup]="linkForm"
    method="POST"
    ngNativeValidate
>
    <div class="modal-body">
        <!-- VIEW 2 -->
        <sync-error [errcode]="errcode"></sync-error>
        <div *ngIf="!isPro" class="panel panel-default bgwhite">
            <div class="panel-body bgwhite">
                <h4>
                    <i class="zmdi zmdi-star-circle zmdi-hc-lg"></i>
                    <b>Upgrade to get these great link features:</b>
                </h4>

                <i class="fa fa-check"></i> Expiry dates<br />
                <i class="fa fa-check"></i> File requests with upload enabled
                links<br />
                <i class="fa fa-check"></i> Set download limits
                (self-destruct)<br />
                <i class="fa fa-check"></i> Preview only mode for documents and
                images (no download)<br />
                <i class="fa fa-check"></i> Email notifications on link
                activity<br />
                <i class="fa fa-check"></i> Download count stats<br />

                <br />
                <a class="syncblue showhand" (click)="viewUpgrades()"
                    >View upgrade options now <i class="fas fa-arrow-right"></i
                ></a>
                <br />
            </div>
        </div>

        <div class="row">
            <div class="col-sm-4">Set password:</div>
            <div class="col-sm-8 cover">
                <!-- new style password editable -->
                <input
                    *ngIf="link.togglePassword"
                    type="password"
                    id="link-settings-password"
                    placeholder="Password not set"
                    class="form-control"
                    formControlName="passwordprotect"
                    syncShowHidePassword
                    identity="linkpass"
                />

                <!-- legacy password -->
                <input
                    *ngIf="!link.togglePassword"
                    type="password"
                    id="link-settings-password"
                    formControlName="key"
                    [placeholder]="link.key"
                    class="form-control"
                    disabled="disabled"
                    syncShowHidePassword
                    identity="linkpass"
                />
            </div>
        </div>
        <hr />

        <div class="row">
            <div class="col-sm-4">Set label:</div>
            <div class="col-sm-8">
                <input
                    type="text"
                    id="link-settings-label"
                    class="form-control"
                    formControlName="label"
                    type="text"
                />
            </div>
        </div>
        <hr />
        <div *ngIf="isPro">
            <div class="row">
                <div class="col-sm-4">Set expiry date:</div>
                <div class="col-sm-4">
                    <div class="input-group">
                        <input
                            type="text"
                            name="exp_date"
                            id="link-settings-expiry"
                            ngbDatepicker
                            placeholder="No expiry date"
                            class="form-control"
                            formControlName="exp_date"
                            autocomplete="off"
                            [minDate]="expDateMin"
                            [startDate]="expDateMin"
                            #d="ngbDatepicker"
                            (dateSelect)="onDateSelection($event)"
                        />
                        <span class="input-group-btn">
                            <button
                                type="button"
                                class="btn btn-default"
                                (click)="d.toggle()"
                            >
                                <i class="fa fa-calendar"></i>
                            </button>
                        </span>
                    </div>
                    <a
                        *ngIf="linkForm.get('exp_date').value"
                        class="showhand"
                        (click)="clearExp()"
                        >Clear expiry</a
                    >
                </div>
                <div class="sm-4">
                    <ngb-timepicker
                        formControlName="exp_time"
                        [meridian]="true"
                        [spinners]="false"
                    ></ngb-timepicker>
                </div>
            </div>
            <hr />
        </div>
        <div *ngIf="isPro && link.type == 'dir'">
            <div class="row">
                <div class="col-sm-4">
                    Upload permissions:<br />(Users can send you files)
                </div>
                <div class="col-sm-8">
                    <div class="radio radio-top">
                        <label>
                            <input
                                type="radio"
                                id="link-settings-upload-disabled"
                                value="0"
                                formControlName="upload"
                            />
                            Disabled
                        </label>
                    </div>
                    <div class="radio">
                        <label>
                            <input
                                type="radio"
                                id="link-settings-upload-show"
                                value="1"
                                formControlName="upload"
                            />
                            Enabled (show files in folder)
                        </label>
                    </div>
                    <div class="radio radio-top">
                        <label>
                            <input
                                type="radio"
                                id="link-settings-upload-hide"
                                value="2"
                                formControlName="upload"
                            />
                            Enabled (hide files in folder)
                        </label>
                    </div>
                </div>
            </div>
            <hr />
        </div>

        <div *ngIf="isPro && linkForm.value.upload !== '2'">
            <div class="row">
                <div class="col-sm-4">
                    File permissions:<br />
                </div>
                <div class="col-sm-8">
                        <div class="radio radio-top">
                            <label>
                                <input type="radio" id="link-settings-preview-view" value="preview"
                                    formControlName="previewonly" />
                                View only (no download)
                            </label>
                        </div>
                        <div class="radio radio">
                            <label [ngClass]="">
                                <input type="radio" id="link-settings-preview-download" value="download"
                                    formControlName="previewonly" />
                                View and download
                            </label>
                        </div>
                        <!-- show the edit file radio button if file is not shared and edit feature is allowed -->
                        <div class="radio radio-top" *ngIf="this.link.type != 'file' || this.isMSOfficeFile">
                            <!-- disable edit file radio button if upload setting is disabled on dir -->
                            <fieldset [disabled]="(link.type == 'dir' && linkForm.value.upload === '0') || item.is_shared"
                                [class.disable-label]="(link.type == 'dir' && linkForm.value.upload === '0') || item.is_shared">
                                <label>
                                    <input type="radio" id="link-settings-preview-edit" value="edit"
                                        formControlName="previewonly" />
                                    View, download and edit with MS Office*
                                </label>
                            </fieldset>
                            <i *ngIf="!item.is_shared"> 
                                <br />
                                *Edit available for authenticated users only 
                            </i>
                            <i *ngIf="item.is_shared" [class.disable-label]="item.is_shared"> 
                                <br />
                                *This link cannot be made editable as it resides within a
                                shared folder. Links within shared folders are restricted from editing to maintain
                                collaborative integrity and data security. 
                            </i>
                        </div>
                </div>
            </div>
            <hr />
        </div>

        <div *ngIf="isPro && link.type != 'dir'">
            <div class="row" *ngIf="isPro && link.type != 'dir'">
                <div class="col-sm-4">
                    Set download limit:<br />(Leave blank for unlimited)
                </div>
                <div class="col-sm-8">
                    <input
                        placeholder="Unlimited"
                        type="number"
                        class="form-control"
                        min="0"
                        formControlName="download_limit"
                    />
                    <span class="help-block">Leave blank for unlimited.</span>
                </div>
            </div>

            <hr />
        </div>

        <div *ngIf="isPro">
            <div class="row">
                <div class="col-sm-4">Email notifications:</div>
                <div class="col-sm-8">
                    <div class="radio radio-top">
                        <label>
                            <input
                                type="radio"
                                id="link-settings-notifications-enabled"
                                value="0"
                                formControlName="email_notification"
                            />
                            Disabled
                        </label>
                    </div>
                    <div class="radio radio-bottom">
                        <label>
                            <input
                                type="radio"
                                id="link-settings-notifications-disabled"
                                value="1"
                                formControlName="email_notification"
                            />
                            Enabled
                        </label>
                    </div>
                </div>
            </div>
            <hr />
        </div>

        <!-- COMMENT SETTINGS -->
        <!-- enable comments settings only for beta users or dl links -->
        <div *ngIf="hasComment && (hasBetaAccess || !isAppLink)">
            <div class="row">
                <div class="col-sm-4">Comments:<br /></div>
                <div class="col-sm-8">
                    <div class="radio radio-top">
                        <label>
                            <input
                                type="radio"
                                id="link-settings-comment-disabled"
                                value="0"
                                formControlName="allow_comment"
                            />
                            Disabled
                        </label>
                    </div>
                    <div class="radio">
                        <label>
                            <input
                                type="radio"
                                id="link-settings-comment-authenticated"
                                value="1"
                                formControlName="allow_comment"
                            />
                            Enable for authenticated users only
                        </label>
                    </div>
                    <div class="radio radio-top" *ngIf="isPro">
                        <label>
                            <input
                                type="radio"
                                id="link-settings-comment-anonymous"
                                value="2"
                                formControlName="allow_comment"
                            />
                            Enable for all users (anonymous included)
                        </label>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row" *ngIf="isPro">
                <div class="col-sm-4">Comment notifications:</div>
                <div class="col-sm-8">
                    <div class="radio radio-top">
                        <label>
                            <input
                                type="radio"
                                id="link-settings-comment-notification-off"
                                value="0"
                                formControlName="comment_notification"
                            />
                            Disabled
                        </label>
                    </div>
                    <div class="radio">
                        <label>
                            <input
                                type="radio"
                                id="link-settings-comment-notification-on"
                                value="1"
                                formControlName="comment_notification"
                            />
                            Enabled
                        </label>
                    </div>
                </div>
            </div>
            <hr *ngIf="isPro" />
        </div>
        <!-- / COMMENT SETTINGS -->

        <div *ngIf="!link.is_app_link">
            <div class="row">
                <div class="col-sm-4">Enhanced privacy:</div>
                <div class="col-sm-8">
                    <div class="radio radio-top">
                        <label>
                            <input
                                type="radio"
                                id="link-settings-compat-enabled"
                                value="1"
                                formControlName="compat"
                            />
                            Disabled
                        </label>
                    </div>
                    <div class="radio radio-bottom">
                        <label>
                            <input
                                type="radio"
                                id="link-settings-compat-disabled"
                                value="0"
                                formControlName="compat"
                            />
                            Enabled
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- VIEW 2 -->

    <div class="modal-footer">
        <button (click)="state.emit(3)" id="delete-link-dialog-btn" class="btn btn-default pull-left">
            Delete link
        </button>
        <button
            type="submit"
            class="btn btn-primary"
            id="save-settings-dialog-btn"
            [disabled]="spinner || !linkForm.valid"
        >
            <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
            Save Settings
        </button>
        <button
            type="button"
            class="btn btn-default"
            id="close-manage-link"
            (click)="close.emit(false)"
        >
            Close
        </button>
    </div>
</form>
