"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../comment/comment.component.ngfactory");
var i2 = require("../comment/comment.component");
var i3 = require("../../../../core/logger.service");
var i4 = require("../../../../core/comment.service");
var i5 = require("../../../../core/api.service");
var i6 = require("../../../../core/base64.service");
var i7 = require("@angular/platform-browser");
var i8 = require("@angular/forms");
var i9 = require("@angular/router");
var i10 = require("@angular/common");
var i11 = require("../../error/error.component.ngfactory");
var i12 = require("../../error/error.component");
var i13 = require("../../../../core/user.service");
var i14 = require("@ng-bootstrap/ng-bootstrap");
var i15 = require("./comment-block.component");
var i16 = require("../../../../link-consume/services/link-file-list.service");
var i17 = require("../../../../core/crypt/sync-crypt.service");
var i18 = require("@ngrx/store");
var styles_CommentBlockComponent = [];
var RenderType_CommentBlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CommentBlockComponent, data: {} });
exports.RenderType_CommentBlockComponent = RenderType_CommentBlockComponent;
function View_CommentBlockComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "text-center comment-notifier"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "table", [["style", "width: 100%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 10, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-bell fa-lg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" There's a new comment. "])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "a", [["class", "btn btn-default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.getComments();
        var pd_0 = (_co.scrollToBottom() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Show"])), (_l()(), i0.ɵeld(10, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "a", [["class", "btn btn-default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideNotification() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Dismiss"]))], null, null); }
function View_CommentBlockComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-sync fa-spin"]], null, null, null, null, null))], null, null); }
function View_CommentBlockComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-bell pull-right"], ["style", "color: #f9bc16; padding-right: 8px"]], null, null, null, null, null))], null, null); }
function View_CommentBlockComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-comment", [], [[1, "style", 2]], [[null, "commentCreationEvent"], [null, "anonymousCreationEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("commentCreationEvent" === en)) {
        var pd_0 = (_co.createComment($event) !== false);
        ad = (pd_0 && ad);
    } if (("anonymousCreationEvent" === en)) {
        var pd_1 = (_co.createAnonymousSession($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_CommentComponent_0, i1.RenderType_CommentComponent)), i0.ɵdid(1, 770048, null, 0, i2.CommentComponent, [i3.LoggerService, i4.CommentService, i5.ApiService, i6.Base64Service, i7.DomSanitizer, i8.FormBuilder, i9.ActivatedRoute], { user: [0, "user"], isInputBox: [1, "isInputBox"], tempSession: [2, "tempSession"], locId: [3, "locId"], item: [4, "item"], link: [5, "link"] }, { commentCreationEvent: "commentCreationEvent", anonymousCreationEvent: "anonymousCreationEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.user; var currVal_2 = true; var currVal_3 = _co.tempSession; var currVal_4 = _co.locId; var currVal_5 = _co.item; var currVal_6 = _co.link; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).customColorsCss; _ck(_v, 0, 0, currVal_0); }); }
function View_CommentBlockComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "sync-comment", [], [[1, "style", 2]], [[null, "commentEditionEvent"], [null, "commentDeletionEvent"], [null, "commentGetEvent"], [null, "anonymousCreationEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("commentEditionEvent" === en)) {
        var pd_0 = (_co.updateComment($event) !== false);
        ad = (pd_0 && ad);
    } if (("commentDeletionEvent" === en)) {
        var pd_1 = (_co.deleteComment($event) !== false);
        ad = (pd_1 && ad);
    } if (("commentGetEvent" === en)) {
        var pd_2 = (_co.getComments() !== false);
        ad = (pd_2 && ad);
    } if (("anonymousCreationEvent" === en)) {
        var pd_3 = (_co.createAnonymousSession($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_CommentComponent_0, i1.RenderType_CommentComponent)), i0.ɵdid(2, 770048, null, 0, i2.CommentComponent, [i3.LoggerService, i4.CommentService, i5.ApiService, i6.Base64Service, i7.DomSanitizer, i8.FormBuilder, i9.ActivatedRoute], { comment: [0, "comment"], user: [1, "user"], cdk: [2, "cdk"], ownerId: [3, "ownerId"], tempSession: [4, "tempSession"], locId: [5, "locId"], item: [6, "item"], publinkId: [7, "publinkId"], link: [8, "link"] }, { commentEditionEvent: "commentEditionEvent", commentDeletionEvent: "commentDeletionEvent", commentGetEvent: "commentGetEvent", anonymousCreationEvent: "anonymousCreationEvent" }), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.user; var currVal_3 = _co.cdk; var currVal_4 = _co.ownerId; var currVal_5 = _co.tempSession; var currVal_6 = _co.locId; var currVal_7 = _co.item; var currVal_8 = _co.publinkId; var currVal_9 = _co.link; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).customColorsCss; _ck(_v, 1, 0, currVal_0); }); }
function View_CommentBlockComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 18, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 17, "div", [["style", "margin-top: auto"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, [[1, 0], ["commentThread", 1]], null, 16, "div", [["class", "\n                comment-box\n                dropdown-menu\n                right-menu-dropdown-menu\n                dropdown-regular\n            "]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "comment-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 8, "div", [["style", "padding-bottom: 8px"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleComment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 278528, null, 0, i10.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 0, "i", [["class", "zmdi zmdi-comments zmdi-hc-fw zmdi-hc-lg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Comments "])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "i", [["aria-hidden", "true"], ["class", "fas pull-right visible-xs"], ["style", "padding-left: 8px"]], null, null, null, null, null)), i0.ɵdid(9, 278528, null, 0, i10.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-redo pull-right showhand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.getComments();
        var pd_0 = (_co.scrollToBottom($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommentBlockComponent_4)), i0.ɵdid(12, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 1, "sync-error", [], null, null, null, i11.View_ErrorComponent_0, i11.RenderType_ErrorComponent)), i0.ɵdid(14, 114688, null, 0, i12.ErrorComponent, [i13.UserService, i14.NgbModal, i7.DomSanitizer], { errcode: [0, "errcode"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommentBlockComponent_5)), i0.ɵdid(16, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommentBlockComponent_6)), i0.ɵdid(18, 278528, null, 0, i10.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.link.image_cachekey ? "syncblue-custom" : "syncblue"); _ck(_v, 5, 0, currVal_0); var currVal_1 = "fas pull-right visible-xs"; var currVal_2 = (_co.isCommentExpanded ? "fa-window-minimize" : "fa-window-restore"); _ck(_v, 9, 0, currVal_1, currVal_2); var currVal_3 = _co.hasNewComments; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.errCode; _ck(_v, 14, 0, currVal_4); var currVal_5 = !_co.errCode; _ck(_v, 16, 0, currVal_5); var currVal_6 = _co.comments; _ck(_v, 18, 0, currVal_6); }, null); }
function View_CommentBlockComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { scrollContainer: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommentBlockComponent_1)), i0.ɵdid(2, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommentBlockComponent_2)), i0.ɵdid(4, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommentBlockComponent_3)), i0.ɵdid(6, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 0, "iframe", [["class", "comment-cp-iframe"], ["id", "sync-comment-cp-iframe"]], [[8, "src", 5]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.allowComment && _co.hasNewComments) && _co.showNotification); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.spinner; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.allowComment && !_co.spinner); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.commentCPServiceHost; _ck(_v, 7, 0, currVal_3); }); }
exports.View_CommentBlockComponent_0 = View_CommentBlockComponent_0;
function View_CommentBlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-comment-block", [], [[1, "style", 2]], null, null, View_CommentBlockComponent_0, RenderType_CommentBlockComponent)), i0.ɵdid(1, 245760, null, 0, i15.CommentBlockComponent, [i16.LinkFileListService, i17.SyncCryptService, i18.Store, i3.LoggerService, i4.CommentService, i6.Base64Service, i5.ApiService, i7.DomSanitizer, i9.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).customColorsCss; _ck(_v, 0, 0, currVal_0); }); }
exports.View_CommentBlockComponent_Host_0 = View_CommentBlockComponent_Host_0;
var CommentBlockComponentNgFactory = i0.ɵccf("sync-comment-block", i15.CommentBlockComponent, View_CommentBlockComponent_Host_0, { item: "item", ontoggle: "ontoggle" }, {}, []);
exports.CommentBlockComponentNgFactory = CommentBlockComponentNgFactory;
