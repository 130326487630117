"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_1 = require("@angular/common/http");
var err_code_model_1 = require("../../../models/err-code.model");
var platform_browser_1 = require("@angular/platform-browser");
var api_service_1 = require("../../../../core/api.service");
var base64_service_1 = require("../../../../core/base64.service");
var comment_service_1 = require("../../../../core/comment.service");
var logger_service_1 = require("../../../../core/logger.service");
var core_1 = require("@angular/core");
var link_file_list_service_1 = require("../../../../link-consume/services/link-file-list.service");
var sync_crypt_service_1 = require("../../../../core/crypt/sync-crypt.service");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../../../reducers");
var models_1 = require("../../../models");
var environment_1 = require("../../../../../environments/environment");
var auth_actions_1 = require("../../../../actions/auth.actions");
var link_file_list_actions_1 = require("../../../../actions/link-file-list.actions");
var router_1 = require("@angular/router");
var CommentBlockComponent = /** @class */ (function () {
    function CommentBlockComponent(linkPathList, crypt, store, log, commentService, base64, api, sanitizer, route) {
        this.linkPathList = linkPathList;
        this.crypt = crypt;
        this.store = store;
        this.log = log;
        this.commentService = commentService;
        this.base64 = base64;
        this.api = api;
        this.sanitizer = sanitizer;
        this.route = route;
        this.isCommentExpanded = false;
        this.movementEvents = ['keydown', 'click']; //types of user movement that delays reloading the page
        this.refreshRate = 60 * 1000; // secs
    }
    CommentBlockComponent.sendMessage = function (action, data) {
        this.iframe.postMessage({ action: action, data: data }, this.cpHost);
    };
    CommentBlockComponent.signRequest = function (input) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.resolveCallback = resolve;
                        _this.rejectCallback = reject;
                        _this.sendMessage('sign', input);
                    })];
            });
        });
    };
    CommentBlockComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.spinner = true;
                this.commentCPServiceHost =
                    this.sanitizer.bypassSecurityTrustResourceUrl(CommentBlockComponent.cpHost + "/comment-iframe/cm");
                window.addEventListener('message', this.handleIFrameMessage.bind(this));
                this.movementEvents.forEach(function (e) {
                    return document.addEventListener(e, _this.resetError.bind(_this), false);
                });
                //get comment settings
                this.sub = this.linkPathList
                    .getSubscription()
                    .subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var shareId, derivedKey, _a, ex_1, _b, ex_2;
                    return tslib_1.__generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                if (!(data.loaded && data.sorted)) return [3 /*break*/, 9];
                                console.log('link data:', data);
                                this.link = data;
                                this.ownerId = data.owner_id;
                                this.allowComment = data.allow_comment;
                                this.publinkId = data.publink_id;
                                shareId = data.sharekey_id.match(/(.*)-1$/)[1];
                                this.locId = shareId + "-0-" + this.publinkId;
                                if (data.image_cachekey) {
                                    this.linkTextColor = data.link_text_color;
                                    this.buttonPrimaryColor = data.button_primary_color;
                                    this.buttonTextColor = data.button_text_color;
                                }
                                this.allowComment = data.allow_comment;
                                // encrypted comment key, once set, may not be unset
                                if (data.enc_comment_key) {
                                    this.encCdk = data.enc_comment_key;
                                }
                                return [4 /*yield*/, this.processLinkKey(data)];
                            case 1:
                                derivedKey = _c.sent();
                                if (!this.allowComment) return [3 /*break*/, 9];
                                if (!!this.encCdk) return [3 /*break*/, 5];
                                this.log.info('generating comment key');
                                _c.label = 2;
                            case 2:
                                _c.trys.push([2, 4, , 5]);
                                _a = this;
                                return [4 /*yield*/, this.createCommentDataKey(data, derivedKey)];
                            case 3:
                                _a.encCdk = _c.sent();
                                this.log.info('done generating comment key');
                                return [3 /*break*/, 5];
                            case 4:
                                ex_1 = _c.sent();
                                this.log.error("Error while encrypting comment data key " + JSON.stringify(ex_1));
                                this.errCode = new err_code_model_1.ErrCode(1100, 'Error while encrypting comment data key');
                                return [2 /*return*/];
                            case 5:
                                console.log('enc cdk:', this.encCdk);
                                _c.label = 6;
                            case 6:
                                _c.trys.push([6, 8, , 9]);
                                _b = this;
                                return [4 /*yield*/, this.crypt.commentDatakeyDecrypt(this.encCdk, derivedKey)];
                            case 7:
                                _b.cdk = _c.sent();
                                this.log.info('done decrypting comment key');
                                return [3 /*break*/, 9];
                            case 8:
                                ex_2 = _c.sent();
                                this.log.error("Error while decrypting comment data key " + JSON.stringify(ex_2));
                                this.errCode = new err_code_model_1.ErrCode(1100, 'Error while decrypting comment data key');
                                return [2 /*return*/];
                            case 9:
                                if (data.iframeReady) {
                                    this.getComments();
                                }
                                else {
                                    this.spinner = false;
                                }
                                return [2 /*return*/];
                        }
                    });
                }); });
                //get user info
                this.sub1 = this.store
                    .pipe(store_1.select(fromRoot.getAuthUser))
                    .subscribe(function (data) {
                    if (data) {
                        _this.user = data;
                        _this.tempSession = '';
                    }
                    else {
                        //else the user is null
                        _this.user = null;
                    }
                });
                CommentBlockComponent.iframe = (document.getElementById('sync-comment-cp-iframe')).contentWindow;
                return [2 /*return*/];
            });
        });
    };
    CommentBlockComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.sub1) {
            this.sub1.unsubscribe();
        }
        window.clearInterval(this.timer);
        // this.movementEvents.forEach((e) =>
        //     document.removeEventListener(
        //         e,
        //         this.resetInterval.bind(this),
        //         false
        //     )
        // );
        if (this.user && !this.user.exists) {
            this.user = null;
        }
    };
    CommentBlockComponent.prototype.createAnonymousSession = function (input) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newReply, _a, payload, signedInput, response, newComment, ex_3;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 6, , 7]);
                        //create a temp user for anonymous user
                        this.user = new models_1.User({
                            display_name: this.base64.encode(input.name),
                        });
                        //create a temp 'session token' for the user
                        this.tempSession = "" + this.crypt.bytesToB64(this.crypt.mkDataKey());
                        this.log.info("tempSession: " + this.tempSession);
                        if (!input.commentID) return [3 /*break*/, 4];
                        newReply = {
                            parent_id: input.commentID,
                            author_id: this.user.id,
                            author_name: this.user.display_name,
                            content: input.content,
                            temp_session: this.tempSession,
                        };
                        _a = newReply;
                        return [4 /*yield*/, this.commentService.encryptContent(this.cdk, newReply.content)];
                    case 1:
                        _a.content = _b.sent();
                        payload = tslib_1.__assign({ servtime: Date.now(), access_token: '', signature: '' }, newReply, { content_length: input.content.length.toString() });
                        return [4 /*yield*/, CommentBlockComponent.signRequest(payload)];
                    case 2:
                        signedInput = _b.sent();
                        return [4 /*yield*/, this.commentService.createReply(newReply, signedInput)];
                    case 3:
                        response = _b.sent();
                        this.log.info('Reply has been created');
                        this.getComments();
                        this.sendNotification('POST');
                        return [3 /*break*/, 5];
                    case 4:
                        if (input.content) {
                            newComment = {
                                loc_id: this.route.snapshot.params['key'],
                                author_id: this.user.id,
                                author_name: this.user.display_name,
                                content: input.content,
                                temp_session: this.tempSession,
                            };
                            this.createComment(newComment);
                        }
                        _b.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        ex_3 = _b.sent();
                        this.log.error("Error while creating/posting anonymous comment: " + JSON.stringify(ex_3));
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    CommentBlockComponent.prototype.checkNewComments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var input, signedInput, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.allowComment) {
                            return [2 /*return*/];
                        }
                        input = {
                            servtime: Date.now(),
                            access_token: '',
                            signature: '',
                            locId: this.locId,
                            requestTime: this.lastReloadTime.toString(),
                        };
                        return [4 /*yield*/, CommentBlockComponent.signRequest(input)];
                    case 1:
                        signedInput = _b.sent();
                        //check for new comments
                        _a = this;
                        return [4 /*yield*/, this.commentService.checkNewComments(signedInput)];
                    case 2:
                        //check for new comments
                        _a.hasNewComments = _b.sent();
                        this.showNotification = this.hasNewComments;
                        return [2 /*return*/];
                }
            });
        });
    };
    CommentBlockComponent.prototype.createComment = function (newComment) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contentLength, _a, input, signedInput, response, ex_4;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        newComment.loc_id = this.locId;
                        contentLength = newComment.content.length;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 7, , 8]);
                        //encrypt comment's content
                        _a = newComment;
                        return [4 /*yield*/, this.commentService.encryptContent(this.cdk, newComment.content)];
                    case 2:
                        //encrypt comment's content
                        _a.content = _b.sent();
                        input = tslib_1.__assign({ servtime: Date.now(), access_token: '', signature: '' }, newComment, { content_length: contentLength.toString() });
                        return [4 /*yield*/, CommentBlockComponent.signRequest(input)];
                    case 3:
                        signedInput = _b.sent();
                        return [4 /*yield*/, this.commentService.createComment(signedInput)];
                    case 4:
                        response = _b.sent();
                        if (!response) return [3 /*break*/, 6];
                        this.log.info('Comment has been created');
                        return [4 /*yield*/, this.getComments()];
                    case 5:
                        _b.sent();
                        this.scrollToBottom();
                        this.sendNotification('POST');
                        _b.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        ex_4 = _b.sent();
                        this.handleError(ex_4);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    CommentBlockComponent.prototype.getComments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var input, signedInput, response, data, errResponse_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        input = {
                            servtime: Date.now(),
                            access_token: '',
                            signature: '',
                        };
                        return [4 /*yield*/, CommentBlockComponent.signRequest(input)];
                    case 1:
                        signedInput = _a.sent();
                        return [4 /*yield*/, this.commentService.getComments(this.locId, signedInput)];
                    case 2:
                        response = _a.sent();
                        if (response && response['success'] === 1) {
                            this.log.info('comments have been fetched');
                            data = response['data'];
                            //decrypt comments and replies content
                            data.forEach(function (comment) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var _a;
                                var _this = this;
                                return tslib_1.__generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = comment;
                                            return [4 /*yield*/, this.commentService.decryptContent(this.cdk, comment.content, comment._id)];
                                        case 1:
                                            _a.content = _b.sent();
                                            if (comment.replies.length > 0) {
                                                comment.replies.forEach(function (reply) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                                    var _a;
                                                    return tslib_1.__generator(this, function (_b) {
                                                        switch (_b.label) {
                                                            case 0:
                                                                _a = reply;
                                                                return [4 /*yield*/, this.commentService.decryptContent(this.cdk, reply.content, reply.reply_id)];
                                                            case 1:
                                                                _a.content =
                                                                    _b.sent();
                                                                return [2 /*return*/];
                                                        }
                                                    });
                                                }); });
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); });
                            this.comments = data;
                        }
                        else {
                            this.comments = [];
                        }
                        this.lastReloadTime = new Date().getTime();
                        this.hasNewComments = false;
                        this.showNotification = this.hasNewComments;
                        this.resetReloadTimer();
                        this.spinner = false;
                        return [3 /*break*/, 4];
                    case 3:
                        errResponse_1 = _a.sent();
                        this.spinner = false;
                        this.handleError(errResponse_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CommentBlockComponent.prototype.updateComment = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contentLength, _a, commentUpdate, input, signedInput, response, ex_5;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        contentLength = data.content.length;
                        _a = data;
                        return [4 /*yield*/, this.commentService.encryptContent(this.cdk, data.content)];
                    case 1:
                        _a.content = _b.sent();
                        commentUpdate = {
                            author_id: data.author_id,
                            content: data.content,
                            temp_session: data.temp_session,
                        };
                        input = tslib_1.__assign({ servtime: Date.now(), access_token: '', signature: '' }, commentUpdate, { content_length: contentLength.toString() });
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 5, , 6]);
                        return [4 /*yield*/, CommentBlockComponent.signRequest(input)];
                    case 3:
                        signedInput = _b.sent();
                        return [4 /*yield*/, this.commentService.updateComment(data, signedInput)];
                    case 4:
                        response = _b.sent();
                        if (response && response['success'] === 1) {
                            this.log.info('Comments has been updated');
                            this.getComments();
                            this.sendNotification('PATCH');
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        ex_5 = _b.sent();
                        this.handleError(ex_5);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    CommentBlockComponent.prototype.deleteComment = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var deleteParams, input, signedInput, response, ex_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        deleteParams = {
                            author_id: data.author_id,
                            temp_session: encodeURIComponent(data.temp_session),
                        };
                        input = tslib_1.__assign({ servtime: Date.now(), access_token: '', signature: '' }, deleteParams);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, CommentBlockComponent.signRequest(input)];
                    case 2:
                        signedInput = _a.sent();
                        return [4 /*yield*/, this.commentService.deleteComment(data, signedInput)];
                    case 3:
                        response = _a.sent();
                        if (response && response['success'] === 1) {
                            this.log.info("comment id " + data._id + " has been deleted");
                            this.sendNotification('DEL', false);
                            this.getComments();
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        ex_6 = _a.sent();
                        this.handleError(ex_6);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    CommentBlockComponent.prototype.resetError = function () {
        this.errCode = null;
    };
    CommentBlockComponent.prototype.resetReloadTimer = function () {
        var _this = this;
        window.clearInterval(this.timer);
        this.timer = window.setInterval(function () { return _this.checkNewComments(); }, this.refreshRate);
    };
    CommentBlockComponent.prototype.handleIFrameMessage = function (e) {
        if (!e.origin.startsWith(CommentBlockComponent.cpHost)) {
            return;
        }
        switch (e.data.action) {
            case 'update-user':
                //update User object in the store for ln2.sync.com
                this.store.dispatch(new auth_actions_1.UpdateAction(e.data.data));
                this.store.dispatch(new link_file_list_actions_1.IFrameReadyAction(true));
                this.log.info('iframe ready');
                break;
            case 'signed':
                CommentBlockComponent.resolveCallback(e.data.data);
                break;
            default:
                this.log.error('unsupported action');
        }
    };
    /**
     *
     * @param {string} method 'POST' if event is a comment creation, 'PATCH' or 'PUT' if event is a comment update, 'DEL' if delete
     * @param {boolean} sendEmail true if an email is also sent
     */
    CommentBlockComponent.prototype.sendNotification = function (method, sendEmail) {
        if (sendEmail === void 0) { sendEmail = true; }
        if (!this.link.comment_notification) {
            this.log.info('Comment notification is disabled');
            return;
        }
        //send email only if user is not link owner
        if (this.user && this.user.id === this.ownerId) {
            sendEmail = false;
        }
        //create event for this user
        if (this.user.exists) {
            this.notify(this.user.id, method, false);
        }
        //send notif to link owner if not the same user (to avoid double notifications)
        if (this.user.id !== this.ownerId) {
            this.notify(this.ownerId, method);
        }
    };
    CommentBlockComponent.prototype.handleError = function (errResponse) {
        if (errResponse instanceof http_1.HttpErrorResponse) {
            this.errCode = new err_code_model_1.ErrCode(errResponse.status, errResponse.error.message || errResponse.message, errResponse.error.detail || errResponse.name);
        }
        else {
            this.errCode = err_code_model_1.ErrCode.fromException(errResponse);
        }
    };
    CommentBlockComponent.prototype.hideNotification = function () {
        this.showNotification = false;
    };
    CommentBlockComponent.prototype.notify = function (recipientId, method, sendEmail) {
        if (sendEmail === void 0) { sendEmail = true; }
        this.api.execute('notifycomment', {
            publink_id: this.publinkId,
            link_owner_id: this.ownerId,
            recipient_id: recipientId,
            display_name: this.user.exists
                ? this.user.display_name
                : this.base64.encode('An anonymous user'),
            link_name: this.base64.encode(this.item.name),
            link_url: this.base64.encode(window.location.href),
            method: method,
            send_email: sendEmail,
        });
    };
    CommentBlockComponent.prototype.scrollToBottom = function (event) {
        if (event && this.isCommentExpanded) {
            event.stopPropagation();
        }
        try {
            this.scrollContainer.nativeElement.scrollIntoView(false);
            this.scrollContainer.nativeElement.scrollTop =
                this.scrollContainer.nativeElement.scrollHeight;
        }
        catch (err) {
            this.log.info('scrollContainer not initalized');
        }
    };
    CommentBlockComponent.prototype.getLinkKey = function () {
        var key = this.item.context === 'applink'
            ? this.route.snapshot.params['cachekey']
            : this.route.snapshot.params['key'] || this.route.snapshot.fragment;
        return this.linkPathList.sanitizeKey(key);
    };
    CommentBlockComponent.prototype.processLinkKey = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.linkPathList.getKeyB64(data.linkversion, this.getLinkKey(), data.salt, data.iterations)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        ex_7 = _a.sent();
                        this.log.error("Error while processing link key " + this.getLinkKey() + " " + JSON.stringify(ex_7));
                        this.errCode = new err_code_model_1.ErrCode(1100, 'Error while processing link key');
                        return [2 /*return*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CommentBlockComponent.prototype.createCommentDataKey = function (data, derivedKey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, ex_8, response;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.cdk = this.crypt.bytesToB64(this.crypt.mkDataKey());
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 6, , 7]);
                        if (!(this.item.context === 'applink')) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this.crypt.appLinkDatakeyEncrypt(this.cdk, derivedKey)];
                    case 2:
                        _a.encCdk = _c.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        _b = this;
                        return [4 /*yield*/, this.crypt.datakeyEncrypt(this.cdk, derivedKey)];
                    case 4:
                        _b.encCdk = _c.sent();
                        _c.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        ex_8 = _c.sent();
                        throw ex_8;
                    case 7: return [4 /*yield*/, this.api.execute('setcommentdatakey', {
                            publink_id: this.publinkId,
                            enc_comment_key: this.encCdk,
                            passwordlock: data.passwordlock,
                        })];
                    case 8:
                        response = _c.sent();
                        return [2 /*return*/, response.enc_comment_key];
                }
            });
        });
    };
    Object.defineProperty(CommentBlockComponent.prototype, "customColorsCss", {
        /**
         * this function updates css variables based on changes of this component's attributes
         */
        get: function () {
            return this.sanitizer
                .bypassSecurityTrustStyle("--linkTextColor: " + this.linkTextColor + ";\n                                                        --buttonPrimaryColor: " + this.buttonPrimaryColor + ";\n                                                        --buttonTextColor: " + this.buttonTextColor + ";\n                                                    ");
        },
        enumerable: true,
        configurable: true
    });
    CommentBlockComponent.prototype.toggleComment = function () {
        this.isCommentExpanded = !this.isCommentExpanded;
        if (this.ontoggle) {
            this.ontoggle(this.isCommentExpanded);
        }
    };
    CommentBlockComponent.cpHost = environment_1.environment.cphost.replace(/\/+$/, '');
    return CommentBlockComponent;
}());
exports.CommentBlockComponent = CommentBlockComponent;
