"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../shared/pipes/b64.pipe");
var i2 = require("../../core/base64.service");
var i3 = require("@angular/forms");
var i4 = require("./dialog-device-rename.component");
var i5 = require("@ng-bootstrap/ng-bootstrap");
var i6 = require("../../core/api.service");
var styles_DialogDeviceRenameComponent = [];
var RenderType_DialogDeviceRenameComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogDeviceRenameComponent, data: {} });
exports.RenderType_DialogDeviceRenameComponent = RenderType_DialogDeviceRenameComponent;
function View_DialogDeviceRenameComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.B64Pipe, [i2.Base64Service]), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Rename device?"])), (_l()(), i0.ɵeld(7, 0, null, null, 17, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 4, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Current device name is "])), (_l()(), i0.ɵeld(11, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["", ""])), i0.ɵppd(13, 1), (_l()(), i0.ɵeld(14, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "div", [["class", "col-sm-4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["New device name:"])), (_l()(), i0.ɵeld(18, 0, null, null, 6, "div", [["class", "col-sm-8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 5, "input", [["class", "form-control"], ["placeholder", "Enter new display name"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 20)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 20).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 20)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 20)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.newName = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(20, 16384, null, 0, i3.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i0.ɵdid(22, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(24, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i0.ɵeld(25, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Save settings"])), (_l()(), i0.ɵeld(28, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.newName; _ck(_v, 22, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v, 0), _co.device.name)); _ck(_v, 12, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 24).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 24).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 24).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 24).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 24).ngClassValid; var currVal_6 = i0.ɵnov(_v, 24).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 24).ngClassPending; _ck(_v, 19, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
exports.View_DialogDeviceRenameComponent_0 = View_DialogDeviceRenameComponent_0;
function View_DialogDeviceRenameComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-dialog-device-rename", [], null, null, null, View_DialogDeviceRenameComponent_0, RenderType_DialogDeviceRenameComponent)), i0.ɵdid(1, 114688, null, 0, i4.DialogDeviceRenameComponent, [i5.NgbActiveModal, i6.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DialogDeviceRenameComponent_Host_0 = View_DialogDeviceRenameComponent_Host_0;
var DialogDeviceRenameComponentNgFactory = i0.ɵccf("sync-dialog-device-rename", i4.DialogDeviceRenameComponent, View_DialogDeviceRenameComponent_Host_0, { device: "device" }, {}, []);
exports.DialogDeviceRenameComponentNgFactory = DialogDeviceRenameComponentNgFactory;
