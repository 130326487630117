"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base64_service_1 = require("../core/base64.service");
var sync_crypt_service_1 = require("../core/crypt/sync-crypt.service");
var logger_service_1 = require("../core/logger.service");
var user_service_1 = require("../core/user.service");
var i0 = require("@angular/core");
var i1 = require("../core/crypt/sync-crypt.service");
var i2 = require("../core/logger.service");
var i3 = require("../core/user.service");
var i4 = require("../core/base64.service");
var ShareListService = /** @class */ (function () {
    function ShareListService(syncCryptService, loggerService, userService, base64Service) {
        this.syncCryptService = syncCryptService;
        this.loggerService = loggerService;
        this.userService = userService;
        this.base64Service = base64Service;
    }
    /**
     * @todo decrypt data[type][0].enc_name values here instead of
     *       in the template.  We want to remove the decryptName filter.
     */
    ShareListService.prototype.processList = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var i, len, _a, plist, it, j, plen, i, len, _b, plist, it, j, plen, newLeave, l, lenl, _c;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        i = 0, len = data.active.length;
                        _d.label = 1;
                    case 1:
                        if (!(i < len)) return [3 /*break*/, 4];
                        // sharekey
                        data.active[i].waitingamt = 0;
                        _a = data.active[i];
                        return [4 /*yield*/, this.syncCryptService.filenameDecrypt(data.active[i].enc_name)];
                    case 2:
                        _a.name = _d.sent();
                        plist = data.active[i].people_list;
                        if (plist.length) {
                            it = 0;
                            for (j = 0, plen = plist.length; j < plen; j++) {
                                if (plist[j].status == 'Waiting' ||
                                    plist[j].status == 'Waiting Signup' ||
                                    plist[j].status == 'Confirm') {
                                    data.active[i].waitingamt += 1;
                                }
                                if (plist[j].email != this.userService.get('email').toLowerCase()) {
                                    it += 1;
                                    data.active[i]['person' + it] = this.base64Service.decode(plist[j].displayname);
                                }
                            }
                        }
                        _d.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4:
                        i = 0, len = data.apps.length;
                        _d.label = 5;
                    case 5:
                        if (!(i < len)) return [3 /*break*/, 8];
                        // sharekey
                        data.apps[i].waitingamt = 0;
                        _b = data.apps[i];
                        return [4 /*yield*/, this.syncCryptService.filenameDecrypt(data.apps[i].enc_name)];
                    case 6:
                        _b.name = _d.sent();
                        plist = data.apps[i].people_list;
                        if (plist.length) {
                            it = 0;
                            for (j = 0, plen = plist.length; j < plen; j++) {
                                if (plist[j].status == 'Waiting' ||
                                    plist[j].status == 'Waiting Signup' ||
                                    plist[j].status == 'Confirm') {
                                    data.apps[i].waitingamt += 1;
                                }
                                if (plist[j].email != this.userService.get('email').toLowerCase()) {
                                    it += 1;
                                    data.apps[i]['person' + it] = this.base64Service.decode(plist[j].displayname);
                                }
                            }
                        }
                        _d.label = 7;
                    case 7:
                        i++;
                        return [3 /*break*/, 5];
                    case 8:
                        newLeave = [];
                        l = 0, lenl = data.leave.length;
                        _d.label = 9;
                    case 9:
                        if (!(l < lenl)) return [3 /*break*/, 13];
                        if (!data.leave[l].enc_name) return [3 /*break*/, 11];
                        _c = data.leave[l];
                        return [4 /*yield*/, this.syncCryptService.filenameDecrypt(data.leave[l].enc_name)];
                    case 10:
                        _c.name =
                            _d.sent();
                        newLeave.push(data.leave[l]);
                        return [3 /*break*/, 12];
                    case 11:
                        this.loggerService.warn('No enc_name on share id ' +
                            data.leave[l].share_id +
                            ', skip');
                        _d.label = 12;
                    case 12:
                        l++;
                        return [3 /*break*/, 9];
                    case 13:
                        data.leave = newLeave;
                        return [2 /*return*/, data];
                }
            });
        });
    };
    ShareListService.ngInjectableDef = i0.defineInjectable({ factory: function ShareListService_Factory() { return new ShareListService(i0.inject(i1.SyncCryptService), i0.inject(i2.LoggerService), i0.inject(i3.UserService), i0.inject(i4.Base64Service)); }, token: ShareListService, providedIn: "root" });
    return ShareListService;
}());
exports.ShareListService = ShareListService;
