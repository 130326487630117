"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var logger_service_1 = require("../../core/logger.service");
var user_service_1 = require("../../core/user.service");
var models_1 = require("../../shared/models");
var ErrorDisplayComponent = /** @class */ (function () {
    function ErrorDisplayComponent(loggerService, userService, sanitizer) {
        this.loggerService = loggerService;
        this.userService = userService;
        this.sanitizer = sanitizer;
    }
    ErrorDisplayComponent.prototype.ngOnInit = function () {
        console.log('this.errorItem', this.errorItem);
        this.user_display_name = this.userService.get('display_name');
        this.currentPath = window.location.pathname.substring(1, window.location.pathname.length);
        var code;
        var msg = '';
        if (!this.errorItem) {
            return;
        }
        if (typeof this.errorItem === 'string') {
            code = 1000;
            msg = this.errorItem;
        }
        else if (typeof this.errorItem === 'object') {
            if (this.errorItem.code) {
                code = this.errorItem.code;
                msg = this.errorItem.msg;
            }
            else if (this.errorItem.error_code !== undefined) {
                // straight outta compton errrm API
                code = this.errorItem.error_code;
                msg = this.errorItem.error_msg;
            }
            else {
                code = 1000;
                msg = 'An uknown error occurred';
            }
        }
        else {
            code = 1000;
            msg = 'An unknown error occurred';
        }
        this.errorCode = code;
        this.errorMsg = this.formatMsg(code, msg);
        this.loggerService.error("An error occurred " + this.errorCode + ": " + this.errorMsg);
    };
    ErrorDisplayComponent.prototype.formatMsg = function (code, msg) {
        var defaultMsg = msg || models_1.ErrorMessages[code];
        var codeStr = code !== 0 ? "(#" + code + ") " : '';
        return this.sanitizer.bypassSecurityTrustHtml(codeStr + defaultMsg);
    };
    return ErrorDisplayComponent;
}());
exports.ErrorDisplayComponent = ErrorDisplayComponent;
