"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ShareInviteLayoutComponent = /** @class */ (function () {
    function ShareInviteLayoutComponent() {
    }
    ShareInviteLayoutComponent.prototype.ngOnInit = function () {
    };
    return ShareInviteLayoutComponent;
}());
exports.ShareInviteLayoutComponent = ShareInviteLayoutComponent;
