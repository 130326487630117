import { Deserializable } from './deserializable.model';

interface User {
    user_id: number;
    email: string;
}

export interface Permission {
    // Casbin Mapping
    object: string;
    action: string;
    value?: boolean | number;

    // Display
    displayName?: string;
    hidden: boolean;
    editable: boolean;
}

export interface UserWithPermissions {
    user_id: number;
    email: string;
    roles: string[];
    permissions: Permission[];
}

// kept it for just in case usage
export interface Policy {
    files?: {
        purge: boolean;
    };
    links?: {
        create: boolean
    };
    shares?: {
        create: boolean
    };
    apps?: {
        install: boolean
    };
    agents?: {
        mobile: boolean,
        desktop: boolean
    };
    login?: {
        force2fa: boolean,
        passwordReset: number
    };
    billing?: {
        manage: boolean
    };
    admin?: {
        addUsers: boolean,
        viewUsers: boolean
    };
}

export class Role implements Deserializable<Role> {
    name = '';
    users?: User[];
    hidden = false;
    permissions: Permission[];
    editable = false;

    constructor(role: {
        name: string,
        users?: User[],
        permissions: Permission[],
        hidden: boolean,
        editable: boolean
    }) {
        this.name = role.name;
        this.users = role.users;
        this.permissions = role.permissions;
        this.hidden = role.hidden;
        this.editable = role.editable;
    }

    public deserialize(params: any) {
        for (const [key, val] of Object.entries(params)) {
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    }
}

