"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var api_service_1 = require("../../core/api.service");
var transfer_service_1 = require("../../transfer/transfer.service");
var file_item_service_1 = require("../file-item.service");
var HistoryComponent = /** @class */ (function () {
    function HistoryComponent(apiService, fileItemService, transferService, route) {
        this.apiService = apiService;
        this.fileItemService = fileItemService;
        this.transferService = transferService;
        this.route = route;
        // public active: sync.IHistoryItem;
        this.events = [];
        this.alerts = [];
        this.success = false;
        this.predicate = 'hist_id';
        this.reverse = true;
        this.isReadOnly = false;
        this.histId = 0;
        this.initialized = false;
    }
    HistoryComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.syncId = this.route.snapshot.params['id'];
                        this.events = [];
                        this.hasNextPage = true;
                        return [4 /*yield*/, this.pathGet(this.syncId)];
                    case 1:
                        data = _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.fileItemService.format(data)];
                    case 2:
                        _a.currentItem = _b.sent();
                        this.isReadOnly = this.currentItem.is_readonly > 0;
                        this.initialized = true;
                        this.getHistory();
                        return [2 /*return*/];
                }
            });
        });
    };
    HistoryComponent.prototype.pathGet = function (sync_id) {
        return this.apiService.execute('pathget', {
            sync_id: sync_id,
        });
    };
    HistoryComponent.prototype.restoreVersionApi = function (syncId, blobId) {
        return this.apiService.execute('restorewebversion', {
            sync_id: syncId,
            version_id: blobId,
        });
    };
    HistoryComponent.prototype.restoreVersion = function (blobId) {
        var _this = this;
        this.success = false;
        this.restoreVersionApi(this.syncId, blobId).then(function (data) {
            _this.alerts = [];
            _this.histId = 0;
            _this.events = [];
            _this.getHistory();
            _this.success = true;
        }, function (err) {
            if (typeof err === 'object' && err.error_code) {
                _this.alerts.push({
                    code: err.error_code,
                    msg: err.error_msg,
                });
            }
            else {
                _this.alerts.push({
                    code: 1000,
                    msg: 'Error restoring the version history',
                });
            }
        });
    };
    HistoryComponent.prototype.dismiss = function () {
        this.success = false;
    };
    HistoryComponent.prototype.download = function (eventRow) {
        eventRow.spinner = true;
        var dlVersion = Object.assign({}, this.currentItem);
        dlVersion.cachekey = eventRow.cachekey;
        dlVersion.blob_id = eventRow.blob_id;
        dlVersion.size = eventRow.size;
        dlVersion.blobtype = 'btFILE';
        for (var key in eventRow) {
            if (eventRow.hasOwnProperty(key)) {
                dlVersion[key] = eventRow[key];
            }
        }
        this.transferService.queueDownload([dlVersion]);
    };
    HistoryComponent.prototype.getHistory = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoaded = false;
                        return [4 /*yield*/, this.getHistoryApi(this.syncId, this.histId)];
                    case 1:
                        result = _a.sent();
                        if (result.events.length) {
                            this.events = this.events.concat(result.events);
                            this.histId = result.hist_id;
                        }
                        else {
                            this.hasNextPage = false;
                        }
                        this.isLoaded = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    HistoryComponent.prototype.getHistoryApi = function (syncId, histId) {
        if (histId === void 0) { histId = 0; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.historyApi(syncId, histId)];
                    case 1:
                        response = _a.sent();
                        response.events = this.decorate(response.events);
                        return [2 /*return*/, response];
                }
            });
        });
    };
    HistoryComponent.prototype.historyApi = function (sync_id, hist_id) {
        return this.apiService.execute('pathhistory', {
            sync_id: sync_id,
            hist_id: hist_id
        });
    };
    HistoryComponent.prototype.decorate = function (events) {
        var i, len, action, curEvent, lastEvent;
        var retEvents = [], prevEvents = {};
        console.log(events);
        for (i = 0, len = events.length; i < len; i++) {
            action = undefined;
            curEvent = events[i];
            lastEvent = prevEvents[curEvent.sync_id];
            if (!curEvent) {
                continue;
            }
            // don't duplicate rows in the path history
            if (lastEvent &&
                lastEvent.blob_id == curEvent.blob_id &&
                lastEvent.event_type == curEvent.event_type &&
                lastEvent.size == curEvent.size) {
                continue;
            }
            switch (parseInt(curEvent.event_type, 10)) {
                case -2:
                    action = 'Purged';
                    break;
                case -1:
                    action = 'Deleted';
                    break;
                case 0:
                    action = 'Pending';
                    break;
                case 1:
                    action = 'Added';
                    break;
                case 2:
                    action = 'Restored';
                    break;
                case 3:
                    action = 'Edited';
                    break;
                default:
                    action = '';
            }
            curEvent.action_text = action;
            prevEvents[curEvent.sync_id] = curEvent;
            retEvents.push(curEvent);
        }
        return retEvents;
    };
    return HistoryComponent;
}());
exports.HistoryComponent = HistoryComponent;
