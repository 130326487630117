"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var transfer_service_1 = require("./transfer.service");
var UploadFileSelectDirective = /** @class */ (function () {
    function UploadFileSelectDirective(transferService) {
        this.transferService = transferService;
    }
    UploadFileSelectDirective.prototype.ngOnChanges = function () {
        if (this.fileUpload) {
            if (parseInt(this.syncUploadPublic, 10) !== 0) {
                this.transferService.queuePublicUpload(this.fileUpload);
            }
            else {
                this.transferService.queueUpload(this.fileUpload, false);
            }
        }
    };
    return UploadFileSelectDirective;
}());
exports.UploadFileSelectDirective = UploadFileSelectDirective;
