"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var DialogFileZipComponent = /** @class */ (function () {
    function DialogFileZipComponent(activeModal) {
        this.activeModal = activeModal;
        this.zipUrl = '';
        this.zipName = '';
        this.spinner = '';
    }
    DialogFileZipComponent.prototype.ngOnInit = function () {
    };
    return DialogFileZipComponent;
}());
exports.DialogFileZipComponent = DialogFileZipComponent;
