"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var api_service_1 = require("../../core/api.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var user_service_1 = require("../../core/user.service");
var models_1 = require("../../shared/models");
var logger_service_1 = require("../../core/logger.service");
var blend_service_1 = require("src/app/shared/services/blend.service");
var DialogAutorenewComponent = /** @class */ (function () {
    function DialogAutorenewComponent(activeModal, api, log, userService, blendService) {
        this.activeModal = activeModal;
        this.api = api;
        this.log = log;
        this.userService = userService;
        this.blendService = blendService;
    }
    DialogAutorenewComponent.prototype.ngOnInit = function () {
        if (this.userService.checkPermission(models_1.PermissionActions.MANAGE_BILLING)) {
            if (!this.zprofile) {
                this.log.error("User in zuora " + this.user.zuora_acct_key + " but no accountInfo provided from zproxy");
                this.errcode = new models_1.ErrCode(8501);
            }
            this.autoRenew = (this.zprofile.autoPay) ? '0' : '1';
        }
        else {
            this.autoRenew = this.user.is_disableautorenew.toString();
        }
    };
    DialogAutorenewComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.spinner = true;
                        return [4 /*yield*/, this.api.execute('userinfoset', {
                                is_disableautorenew: parseInt(this.autoRenew, 10)
                            })];
                    case 1:
                        _a.sent();
                        this.spinner = false;
                        this.userService.refresh();
                        this.activeModal.close();
                        this.blendService.track(models_1.BlendEvent.CHANGE_ACCOUNT_SETTING, { autoRenew: !this.autoRenew, termEndDate: this.zprofile.subscription.termEndDate });
                        this.blendService.setUserProperty({ is_auto_renew_enabled: !this.autoRenew, term_end_date: this.zprofile.subscription.termEndDate });
                        return [3 /*break*/, 3];
                    case 2:
                        ex_1 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        this.log.error('An error occurred saving auto renew settings');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return DialogAutorenewComponent;
}());
exports.DialogAutorenewComponent = DialogAutorenewComponent;
