"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var api_service_1 = require("../core/api.service");
var base64_service_1 = require("../core/base64.service");
var dirty_out_service_1 = require("../core/crypt/dirty-out.service");
var sync_crypt_service_1 = require("../core/crypt/sync-crypt.service");
var sync_digest_service_1 = require("../core/crypt/sync-digest.service");
var logger_service_1 = require("../core/logger.service");
var sync_cookie_service_1 = require("../core/sync-cookie.service");
var url_service_1 = require("../core/url.service");
var user_service_1 = require("../core/user.service");
var link_file_list_service_1 = require("../link-consume/services/link-file-list.service");
var models_1 = require("../shared/models");
var transfer_model_1 = require("./transfer.model");
var blend_service_1 = require("../shared/services/blend.service");
var TransferItemProgressType;
(function (TransferItemProgressType) {
    TransferItemProgressType[TransferItemProgressType["CRYPT"] = 0] = "CRYPT";
    TransferItemProgressType[TransferItemProgressType["NETWORK"] = 1] = "NETWORK";
})(TransferItemProgressType || (TransferItemProgressType = {}));
var FileUploader = /** @class */ (function () {
    function FileUploader(injector) {
        this.injector = injector;
        /*
         * max size of the upload chunk.  A 100mb file is divided into CHUNKSIZE
         * bytes when transferred to MFS.
         * - MFS has a 300mb limit of chunk size.
         *
         * When tuning this value, consider the larger chunks are generally better
         * however, it also stresses the client computer more.
         */
        this.CHUNKSIZE = 1024 * 1024 * 100;
        /*
         * the maximum file size to allow to be read into memory.  If the file
         * is larger than this value, we will read the file in chunks into memory
         * else, we'll read the whole thing.
         */
        this.MAX_SIZE_READ_MEMORY = 1024 * 1024 * 256;
        this.MAX_RETRY_PER_UPLOAD_HOST = 2;
        /**
         * Max file sizes used in thumbnail creation.  Note HEIC have a smaller
         * size.  Value is in bytes
         */
        this.MAX_SIZE_IMG_THUMB = 1024 * 1024 * 20;
        this.MAX_SIZE_HEIC_THUMB = 1024 * 1024 * 5;
        this.syncDigestService = this.injector.get(sync_digest_service_1.SyncDigestService);
        this.loggerService = this.injector.get(logger_service_1.LoggerService);
        this.apiService = this.injector.get(api_service_1.ApiService);
        this.userService = this.injector.get(user_service_1.UserService);
        this.syncCryptService = this.injector.get(sync_crypt_service_1.SyncCryptService);
        this.urlService = this.injector.get(url_service_1.UrlService);
        this.http = this.injector.get(http_1.HttpClient);
        this.dirtyOutService = this.injector.get(dirty_out_service_1.DirtyOutService);
        this.base64Service = this.injector.get(base64_service_1.Base64Service);
        this.linkPathListService = this.injector.get(link_file_list_service_1.LinkFileListService);
        this.syncCookieService = this.injector.get(sync_cookie_service_1.SyncCookieService);
        this.blendService = this.injector.get(blend_service_1.BlendService);
    }
    FileUploader.prototype.initSha1Digest = function (tItem) {
        tItem.sha1_digest = this.syncDigestService.init();
        return tItem;
    };
    /**
     * Uploads a given transfer Item.  The tItem is passed via reference
     * and modified within these methods.
     * @param tItem TransferItemUpload
     * @returns ITransferItemStats
     */
    FileUploader.prototype.uploadItem = function (tItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var startMs, preUploadTime, uploadTime, finishUploadTime, startUpload, startFinishUpload, startPreUpload, item, tItemUploaded, unaccountedTime, timeTaken, bps, data_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        startMs = window.performance.now();
                        // Reset all values since each uploadItem
                        tItem.sha1_digest = this.syncDigestService.init();
                        tItem.encTime = 0;
                        tItem.digestTime = 0;
                        tItem.appendTime = 0;
                        tItem.sendTime = 0;
                        tItem.readTime = 0;
                        preUploadTime = 0;
                        uploadTime = 0;
                        finishUploadTime = 0;
                        startUpload = 0;
                        startFinishUpload = 0;
                        if (tItem.status === transfer_model_1.TransferStatus.STATUS_UPLOAD_CANCELLED) {
                            return [2 /*return*/, Promise.reject({ errcode: transfer_model_1.TransferStatus.STATUS_UPLOAD_CANCELLED })];
                        }
                        tItem.status = transfer_model_1.TransferStatus.STATUS_WORKING;
                        startPreUpload = window.performance.now();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 9]);
                        return [4 /*yield*/, this.preUpload(tItem)];
                    case 2:
                        item = _a.sent();
                        preUploadTime = window.performance.now() - startPreUpload;
                        startUpload = window.performance.now();
                        return [4 /*yield*/, this.getFile(item)];
                    case 3:
                        tItemUploaded = _a.sent();
                        this.loggerService.info('Encryption time = ' + tItem.encTime + ' ms');
                        uploadTime = window.performance.now() - startUpload;
                        unaccountedTime = (uploadTime - tItem.sendTime - tItem.encTime -
                            tItem.digestTime - tItem.appendTime - tItem.readTime).toFixed(2);
                        // prettier-ignore
                        tItemUploaded.stats = [
                            1,
                            preUploadTime.toFixed(2),
                            uploadTime.toFixed(2),
                            tItem.sendTime.toFixed(2),
                            tItem.encTime.toFixed(2),
                            tItem.digestTime.toFixed(2),
                            tItem.appendTime.toFixed(2),
                            tItem.readTime.toFixed(2),
                            unaccountedTime,
                            tItemUploaded.filesize // file size
                        ].join(',');
                        startFinishUpload = window.performance.now();
                        if (!tItemUploaded.linkID) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.finishUploadPublic(tItemUploaded)];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 5: return [4 /*yield*/, this.finishUpload(tItemUploaded)];
                    case 6:
                        _a.sent();
                        _a.label = 7;
                    case 7:
                        finishUploadTime = window.performance.now() - startFinishUpload;
                        timeTaken = window.performance.now() - startMs;
                        bps = tItem.filesize / (timeTaken / 1000);
                        // prettier-ignore
                        this.blendService.track(models_1.BlendEvent.UPLOAD, {
                            totalTimeTakenInSeconds: +(timeTaken / 1000).toFixed(2),
                            preUploadInMiliseconds: +preUploadTime.toFixed(2),
                            uploadInMiliseconds: +uploadTime.toFixed(2),
                            sendTimeInMiliseconds: +tItem.sendTime.toFixed(2),
                            encryptTimeInMiliseconds: +tItem.encTime.toFixed(2),
                            digestTimeInMiliseconds: +tItem.digestTime.toFixed(2),
                            appendTimeInMiliseconds: +tItem.appendTime.toFixed(2),
                            readTimeInMiliseconds: +tItem.readTime.toFixed(2),
                            finishUploadInMiliseconds: +finishUploadTime.toFixed(2),
                            unaccountedInMiliseconds: +unaccountedTime,
                            fileSizeInBytes: +tItem.filesize,
                            speedInKBPS: +(bps / 1024).toFixed(2),
                        });
                        this.loggerService.info([
                            ' Upload completed successfully for ',
                            tItem.fileobj.name, ': ',
                            (timeTaken / 1000).toFixed(2), 'seconds total, ',
                            ' pre-upload:', preUploadTime.toFixed(2), 'ms, ',
                            ' upload: ', uploadTime.toFixed(2), 'ms ',
                            ' sendTime: ', tItem.sendTime.toFixed(2), 'ms ',
                            ' encrypt time : ', tItem.encTime.toFixed(2), 'ms ',
                            ' digest time: ', tItem.digestTime.toFixed(2), 'ms ',
                            ' append time: ', tItem.appendTime.toFixed(2), 'ms ',
                            ' read time: ', tItem.readTime.toFixed(2), 'ms ',
                            ' finish-upload: ', finishUploadTime.toFixed(2), 'ms ',
                            ' unaccounted:', unaccountedTime, 'ms ',
                            ' file size: ', tItem.filesize, 'bytes ',
                            'at ', (bps / 1024).toFixed(2), ' kBps',
                        ].join(' '));
                        tItem.progress_percent = 100;
                        tItem.status = transfer_model_1.TransferStatus.STATUS_SUCCESS;
                        tItem.sha1_digest = undefined;
                        // console.log('UPLOAD SUCCESS ', tItem.filedata);
                        return [2 /*return*/, {
                                size: tItem.filesize,
                                sendTime: tItem.sendTime,
                                encTime: tItem.encTime,
                                elapsed: timeTaken,
                                elapsedPreUpload: preUploadTime,
                                elapsedUpload: uploadTime,
                                elapsedFinishUpload: finishUploadTime,
                                bps: bps,
                            }];
                    case 8:
                        data_1 = _a.sent();
                        this.loggerService.error('AN ERROR OCCURRED UPLOADING!!');
                        if (typeof data_1 === 'object' && data_1.errors && data_1.errors.length) {
                            tItem.status =
                                data_1.errors[0].error_code || transfer_model_1.TransferStatus.STATUS_ERROR;
                            this.loggerService.error(data_1.errors[0].error_msg);
                        }
                        else if (data_1.errcode) {
                            tItem.status = data_1.errcode;
                        }
                        else if (data_1.code) {
                            tItem.status = data_1.code;
                        }
                        else {
                            tItem.status = transfer_model_1.TransferStatus.STATUS_ERROR;
                        }
                        this.loggerService.error('TransferItem.status = ' + tItem.status);
                        throw data_1;
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    FileUploader.prototype.preUpload = function (tItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, err_1, data, sharekey, err_2, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!tItem.linkID) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiService.execute('pathpreuploadpublic', {
                                sync_pid: tItem.sync_pid,
                                enc_share_name: tItem.enc_share_name,
                                publink_id: tItem.linkID,
                            })];
                    case 2:
                        data = _a.sent();
                        tItem.user_id = data.user_id;
                        tItem.sync_id = data.sync_id;
                        tItem.servtime = data.servtime;
                        tItem.backup_id = data.backup_id;
                        tItem.device_id = data.device_id;
                        tItem.device_sig_b64 = data.device_sig_b64;
                        return [2 /*return*/, tItem];
                    case 3:
                        err_1 = _a.sent();
                        this.loggerService.e("Path preuploadpublic call failed for linkId " + tItem.linkID + " sync_pid " + tItem.sync_pid, err_1);
                        throw new models_1.ErrCode(7215);
                    case 4: return [3 /*break*/, 14];
                    case 5:
                        _a.trys.push([5, 13, , 14]);
                        return [4 /*yield*/, this.apiService.execute('pathpreupload', {
                                sync_pid: tItem.sync_pid,
                                enc_name: tItem.enc_name,
                            })];
                    case 6:
                        data = _a.sent();
                        tItem.user_id = this.userService.get('uid');
                        tItem.sync_id = data.sync_id;
                        tItem.servtime = data.servtime;
                        tItem.share_id = data.share_id;
                        tItem.share_sequence = data.share_sequence;
                        tItem.backup_id = data.backup_id;
                        tItem.device_id = data.device_id;
                        tItem.device_sig_b64 = data.device_sig_b64;
                        tItem.enc_share_key = data.enc_share_key;
                        tItem.share_key_id = data.share_key_id;
                        if (!!tItem.share_key) return [3 /*break*/, 11];
                        _a.label = 7;
                    case 7:
                        _a.trys.push([7, 9, , 10]);
                        return [4 /*yield*/, this.syncCryptService.sharekeyDecrypt(data.enc_share_key, data.share_key_id)];
                    case 8:
                        sharekey = _a.sent();
                        tItem.share_key = sharekey;
                        return [2 /*return*/, tItem];
                    case 9:
                        err_2 = _a.sent();
                        this.loggerService.e("Upload failed, could not decrypt share key " + data.share_key_id, err_2);
                        throw err_2;
                    case 10: return [3 /*break*/, 12];
                    case 11: return [2 /*return*/, tItem];
                    case 12: return [3 /*break*/, 14];
                    case 13:
                        err_3 = _a.sent();
                        this.loggerService.e("Path preupload call failed for sync_pid " + tItem.sync_pid, err_3);
                        throw new models_1.ErrCode(7215);
                    case 14: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Wrapper function to get a file by using FileReader and reading in
     * chunks.  This calls function processPiece recursively until the
     * entire file is read, encrypted and uploaded
     *
     * getFileLarge will bypass loading the file at this point and instead
     * read in chunks via loadFileLarge.
     *
     * This optimization exists since small files fit in memory so we can
     * decrease the amount of times FileReader needs to get instantiated.
     * @param  {ITransferItemUpload} tItem The current TransferItem
     * @return {Promise}      Resolved/rejected when file is completely uploaded
     */
    FileUploader.prototype.getFile = function (tItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(tItem.filesize >= this.MAX_SIZE_READ_MEMORY)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getFileLarge(tItem).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this.getFileSmall(tItem).toPromise()];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    FileUploader.prototype.getFileLarge = function (tItem) {
        this.loggerService.info('FileUploader.getFileLarge(' + tItem.filesize + ')');
        var subject = new rxjs_1.Subject(), payload = {
            pieces: [],
            datalen: 0,
            data: [],
            offset: 0,
            chunklen: 0,
            enc_offset: 0,
        };
        this.processPiece(tItem, payload, subject);
        return subject;
    };
    FileUploader.prototype.getFileSmall = function (tItem) {
        var _this = this;
        this.loggerService.info('FileUploader.getFileSmall(' + tItem.filesize + ')');
        var subject = new rxjs_1.Subject(), payload = {
            pieces: [],
            datalen: 0,
            data: [],
            offset: 0,
            chunklen: 0,
            enc_offset: 0,
        };
        var start = window.performance.now();
        var Reader = new FileReader();
        Reader.onload = function (evt) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var end;
            return tslib_1.__generator(this, function (_a) {
                end = window.performance.now();
                tItem.readTime = tItem.readTime + (end - start);
                this.loggerService.info('FileReader resolved file ' + (end - start) + ' ms');
                tItem.fileobjdata = evt.target.result;
                if (tItem.filesize != tItem.fileobj.size ||
                    (tItem.fileobj.lastModified !== undefined &&
                        tItem.filedate !=
                            new Date(tItem.fileobj.lastModified).getTime())) {
                    this.loggerService.error('Source file has changed since being queued');
                    subject.error({ errcode: 7012 });
                }
                this.processPiece(tItem, payload, subject);
                return [2 /*return*/];
            });
        }); };
        Reader.onerror = function (evt) {
            if (evt.target.error) {
                _this.loggerService.error(evt.target.error.toString());
            }
            _this.loggerService.error('Error reading input file');
            subject.error({ errcode: 7010 });
        };
        Reader.readAsArrayBuffer(tItem.fileobj);
        return subject;
    };
    FileUploader.prototype.processPiece = function (tItem, payload, subject) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var queue, buffer, ex_1, bytearray, beginDigest, beginFileDataEnc, encByteArray, ex_2, startAppend, _s_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (tItem.status === transfer_model_1.TransferStatus.STATUS_UPLOAD_CANCELLED) {
                            subject.error({ errcode: transfer_model_1.TransferStatus.STATUS_UPLOAD_CANCELLED });
                            return [2 /*return*/, subject];
                        }
                        queue = tItem.chunkqueue.shift();
                        if (payload.pieces.length === 0) {
                            payload.offset = queue.offset;
                            payload.enc_offset = queue.enc_offset;
                        }
                        tItem.status = transfer_model_1.TransferStatus.STATUS_ENC_UPLOAD;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.loadPiece(tItem, queue.offset, queue.chunklen)];
                    case 2:
                        buffer = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _a.sent();
                        this.loggerService.e('Failed to load piece', ex_1);
                        return [2 /*return*/, subject.error(new models_1.ErrCode(7216))];
                    case 4:
                        bytearray = this.syncCryptService.arraybufferToBytes(buffer);
                        beginDigest = window.performance.now();
                        tItem.sha1_digest = this.syncDigestService.update(tItem.sha1_digest, bytearray);
                        tItem.digestTime += window.performance.now() - beginDigest;
                        beginFileDataEnc = window.performance.now();
                        _a.label = 5;
                    case 5:
                        _a.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, this.syncCryptService.filedataEncrypt(bytearray, tItem.data_key, queue.offset)];
                    case 6:
                        encByteArray = _a.sent();
                        this.updateProgress(TransferItemProgressType.CRYPT, tItem, encByteArray.length);
                        return [3 /*break*/, 8];
                    case 7:
                        ex_2 = _a.sent();
                        throw new models_1.ErrCode(2050);
                    case 8:
                        tItem.encTime += window.performance.now() - beginFileDataEnc;
                        // must .push() because encByteArray could be instances of Uint8Array
                        payload.pieces.push(encByteArray);
                        payload.datalen += encByteArray.length;
                        payload.chunklen += queue.chunklen;
                        // console.log ((end - start) + ' ms enc time added for this chunk');
                        // this.encTime = this.encTime + (end - start);
                        if (payload.chunklen >= this.CHUNKSIZE || !tItem.chunkqueue.length) {
                            if (tItem.status === transfer_model_1.TransferStatus.STATUS_UPLOAD_CANCELLED) {
                                subject.error({
                                    errcode: transfer_model_1.TransferStatus.STATUS_UPLOAD_CANCELLED,
                                });
                                return [2 /*return*/, subject];
                            }
                            this.loggerService.warn('Network transfer started');
                            startAppend = window.performance.now();
                            payload.data = this.syncCryptService.filedataAppend(payload.pieces, payload.datalen);
                            tItem.appendTime += window.performance.now() - startAppend;
                            this.loggerService.info("Payload contains " + payload.data.length + " chunks");
                            tItem.status = transfer_model_1.TransferStatus.STATUS_UPLOAD; // STATUS_UPLOAD
                            _s_1 = window.performance.now();
                            this.sendChunk(tItem, payload).subscribe(function (event) {
                                if (event.type === http_1.HttpEventType.Response) {
                                    var data = event.body;
                                    var _e = window.performance.now();
                                    tItem.sendTime = tItem.sendTime + (_e - _s_1);
                                    // console.log((_e - _s) + ' ms for send now ')
                                    tItem.blob_id = data.chunks[0].blob_id;
                                    tItem.cachekey = data.chunks[0].cachekey;
                                    payload.pieces = [];
                                    payload.datalen = 0;
                                    payload.data = [];
                                    payload.offset = 0;
                                    payload.chunklen = 0;
                                    payload.enc_offset = 0;
                                    if (tItem.chunkqueue.length) {
                                        _this.processPiece(tItem, payload, subject);
                                    }
                                    else {
                                        subject.next(tItem);
                                        subject.complete();
                                    }
                                }
                                if (event.type === http_1.HttpEventType.UploadProgress) {
                                    _this.updateProgress(TransferItemProgressType.NETWORK, tItem, payload.offset + event.loaded);
                                    // let bytes = payload.offset + event.loaded;
                                    // let p =
                                    //     Math.round((bytes / tItem.filesize) * 100 * 100) /
                                    //     100 /
                                    //     2;
                                    // tItem.progress_percent = p >= 100 ? 99 : p;
                                    // tItem.bytes_sent =
                                    //     bytes > tItem.filesize ? tItem.filesize : bytes;
                                }
                            }, function (err) {
                                subject.error(err);
                            });
                        }
                        else {
                            if (tItem.chunkqueue.length) {
                                this.processPiece(tItem, payload, subject);
                            }
                            else {
                                subject.next(tItem);
                            }
                        }
                        return [2 /*return*/, subject];
                }
            });
        });
    };
    FileUploader.prototype.updateProgress = function (type, tItem, bytes) {
        switch (type) {
            case TransferItemProgressType.CRYPT:
                tItem.progress_crypt += bytes;
                break;
            case TransferItemProgressType.NETWORK:
                tItem.progress_network = bytes;
                break;
        }
        var progressBytes = tItem.progress_crypt + tItem.progress_network;
        // uncomment to debug the progress bar
        // console.log(['updateProgress: ',
        //     'bytes added: ' , bytes,
        //     'bytes_progress: ', progressBytes,
        //     'total_bytes: ', (tItem.filesize * 2),
        //     'math.round: ' , Math.round(progressBytes / (tItem.filesize * 2) * 100),
        // ].join(' '))
        var pct = Math.round(progressBytes / (tItem.filesize * 2) * 100);
        tItem.progress_percent = pct >= 100 ? 99 : pct;
    };
    /**
     * Loads individual chunks.  For large files we return a portion of the file
     * via the FileReader, but if it's smaller we can just slice off the
     * arraybuffer
     * @param tItem TransferItem
     * @param offset byte offset
     * @param len  length to read
     * @returns ArrayBuffer
     */
    FileUploader.prototype.loadPiece = function (tItem, offset, len) {
        if (tItem.filesize >= this.MAX_SIZE_READ_MEMORY) {
            return this.loadPieceLarge(tItem, offset, len);
        }
        else {
            return this.loadPieceSmall(tItem, offset, len);
        }
    };
    FileUploader.prototype.loadPieceLarge = function (tItem, offset, len) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var stop = len + offset || tItem.fileobj.size - 1;
            var Reader = new FileReader();
            var start = window.performance.now();
            Reader.onload = function (evt) {
                var end = window.performance.now();
                tItem.readTime = tItem.readTime + (end - start);
                var bytesRead = evt.target.result.byteLength;
                if (bytesRead !== len) {
                    _this.loggerService.error([
                        'Attempting to read the file returned ',
                        bytesRead,
                        ' when we asked for ',
                        len,
                    ].join(''));
                    reject({ errcode: 7010 });
                }
                else {
                    // this.Logger.info('FileReader resolved file ' + bytesRead);
                    resolve(evt.target.result);
                }
            };
            Reader.onerror = function (evt) {
                _this.loggerService.error('Error reading file');
                reject({ errcode: 7010 });
            };
            Reader.readAsArrayBuffer(tItem.fileobj.slice(offset, stop));
        });
    };
    FileUploader.prototype.loadPieceSmall = function (tItem, offset, len) {
        var stop = len + offset || tItem.fileobj.size - 1;
        return Promise.resolve(tItem.fileobjdata.slice(offset, stop));
    };
    FileUploader.prototype.sendChunk = function (tItem, payload) {
        var encChunkLen = payload.chunklen +
            Math.ceil(payload.chunklen / this.syncCryptService.GCM_PAYLOAD_SIZE) *
                36;
        return this.uploadMultiChunk(this.syncCryptService.prepareDataSend(payload.data), {
            cachekey: tItem.cachekey || '',
            enc_offset_byte: payload.enc_offset,
            enc_chunk_size_bytes: encChunkLen,
            payload_crc32: 'DeadBeef',
            payload_len: encChunkLen,
            // authentication data
            device_sig_b64: tItem.device_sig_b64,
            servtime: tItem.servtime,
            backup_id: tItem.backup_id,
            user_id: tItem.user_id || this.userService.get('uid'),
            device_id: tItem.device_id,
        }, 0);
    };
    FileUploader.prototype.uploadMultiChunk = function (payload, json, attempt, sub) {
        var _this = this;
        if (attempt === void 0) { attempt = 0; }
        var subject = sub || new rxjs_1.Subject();
        var formData = new FormData();
        var retryAttempt = attempt;
        var blob = new Blob([payload]);
        var startTime = window.performance.now();
        formData.append('json', JSON.stringify({
            device_sig_b64: json.device_sig_b64,
            // used for auth
            backup_id: json.backup_id,
            device_id: json.device_id,
            user_id: json.user_id,
            servtime: json.servtime,
            chunks: [
                {
                    object_type: 'btFILE',
                    cachekey: json.cachekey || '',
                    payload_crc32: 'DeadBeef',
                    enc_offset_byte: json.enc_offset_byte,
                    payload_len: json.payload_len,
                    enc_chunk_size_bytes: json.enc_chunk_size_bytes,
                },
            ],
        }));
        formData.append('payload0', blob, 'payload0.data');
        if (blob.size !== json.payload_len) {
            this.loggerService.error("Upload size mismatch. " + blob.size + " != " + json.payload_len);
            subject.error({ code: 7011 });
            return subject;
        }
        var hostIndex = retryAttempt % this.urlService.uploadhosts.length;
        var url = this.urlService.mkUpload(this.urlService.uploadhosts[hostIndex], 'uploadmultichunk');
        var httpOptions = new Headers();
        httpOptions.append('Content-Type', undefined);
        this.http
            .post(url, formData, { reportProgress: true, observe: 'events' })
            .pipe(operators_1.timeout(100000000), operators_1.catchError(function (err) {
            if (err instanceof rxjs_1.TimeoutError) {
                _this.loggerService.error('upload timed out');
                _this.loggerService.error("INPUT: " + JSON.stringify(json));
                _this.loggerService.error('Upload timed out on first chunk');
                return rxjs_1.throwError({ errcode: 7005 });
            }
            else {
                blob = null;
                _this.loggerService.error(status + ' ' + url);
                _this.loggerService.error('uploadChunk xhr onabort ' + JSON.stringify(err));
                return rxjs_1.throwError({ errcode: 7000 });
            }
        }))
            .subscribe(function (response) {
            var endTime = window.performance.now();
            _this.loggerService.info('Upload transfer took ' + (endTime - startTime) + ' ms');
            var jsonData = response.body;
            // errcode 2 means the API thinks I should retry
            if (jsonData &&
                jsonData.success == 0 &&
                jsonData.errcode == 2) {
                _this.loggerService.warn('Error received errcode 2, retrying');
                _this.retryUpload(payload, json, retryAttempt, subject);
                return;
            }
            else if (jsonData &&
                jsonData.success === 0 &&
                !jsonData.errcode) {
                _this.loggerService.error(response.status + ' ' + url);
                _this.loggerService.error("INPUT: " + JSON.stringify(json));
                _this.loggerService.error("OUTPUT: " + JSON.stringify(response));
                _this.loggerService.error('uploadChunk success == 0, errcode = 7020 in upload chunk');
                return subject.error({ errcode: 7020 });
            }
            else if (jsonData && jsonData.success == 0) {
                _this.loggerService.error(response.status + ' ' + url);
                _this.loggerService.error("INPUT: " + JSON.stringify(json));
                _this.loggerService.error("OUTPUT: " + response);
                if (jsonData.errors) {
                    _this.loggerService.error(JSON.stringify(jsonData.errors));
                }
                _this.loggerService.error('uploadChunk success == 0 error in upload chunk');
                return subject.error({ errcode: 7020 });
            }
            else {
                _this.loggerService.info(status + ' ' + url + ' success');
                if (jsonData) {
                    if (jsonData.success == 1 &&
                        jsonData.chunks.length == 1) {
                        subject.next(jsonData.chunks[0]);
                    }
                    else {
                        _this.loggerService.error('An unexpected result was received during upload');
                        _this.loggerService.error("INPUT: " + JSON.stringify(json));
                        _this.loggerService.error("OUTPUT: " + JSON.stringify(jsonData));
                        subject.error({ errcode: 7020 });
                    }
                }
            }
            subject.next(response);
        }, function (resp) {
            _this.loggerService.error(resp.status + " " + resp.xhrStatus + " " + url);
            console.log(resp);
            if (resp.status === 0 || !resp) {
                _this.loggerService.error('uploadChunk status = 0 || !response ' + url);
                _this.loggerService.error("INPUT: " + JSON.stringify(json));
                _this.loggerService.error("OUTPUT: " + JSON.stringify(resp));
                subject.error({ errcode: 7023 });
                return;
            }
            else if (resp.status === -1 || resp.status >= 500) {
                _this.retryUpload(payload, json, retryAttempt, subject);
                return;
            }
            else {
                _this.loggerService.error('An unknown error occurred');
                _this.loggerService.error("INPUT: " + JSON.stringify(json));
                _this.loggerService.error("OUTPUT: " + JSON.stringify(resp));
                subject.error({ errcode: 7000 });
            }
        });
        return subject;
    };
    FileUploader.prototype.retryUpload = function (payload, json, retryAttempt, subject) {
        var _this = this;
        var nextRetryAttempt = retryAttempt + 1;
        var totalRetries = this.urlService.uploadhosts.length * this.MAX_RETRY_PER_UPLOAD_HOST;
        if (nextRetryAttempt < totalRetries) {
            this.loggerService.warn("Retrying upload with host " + this.urlService.uploadhosts[nextRetryAttempt % this.urlService.uploadhosts.length]);
            setTimeout(function () {
                _this.uploadMultiChunk(payload, json, nextRetryAttempt, subject);
            }, 300 * (nextRetryAttempt + 1));
        }
        else {
            this.loggerService.error("Failed all retry attempts. INPUT: " + JSON.stringify(json));
            subject.error({ code: 7000 });
        }
    };
    /**
        Send job to setjob api. The job would be inserted in resque for thumbnail generation.
     */
    FileUploader.prototype.sendThumbnailJob = function (compat_url, type, enc_datakey, cachekey, user_id, device_id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.apiService
                        .execute('setjob', {
                        job_name: 'publink.ThumbnailGeneration',
                        compat_url: compat_url,
                        type: type,
                        enc_datakey: enc_datakey,
                        cachekey: cachekey,
                        user_id: user_id,
                        device_id: device_id,
                    })
                        .then(function (response) {
                        return response;
                    })];
            });
        });
    };
    FileUploader.prototype.getThumbnail = function (tItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var thumbnailProcessing, mfsUrl_1, host, rsaDatakey_1, encHost_1, pathListSubscription_1, err_4;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        thumbnailProcessing = false;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        host = window.location.href;
                        if (window.location.search) {
                            host = host.replace(window.location.search, '');
                        }
                        return [4 /*yield*/, this.syncCryptService.compatDatakeyEncrypt(this.syncCryptService.bytesToB64(tItem.data_key))];
                    case 2:
                        rsaDatakey_1 = _a.sent();
                        return [4 /*yield*/, this.syncCryptService.compatDatakeyEncrypt(this.base64Service.encode(host))];
                    case 3:
                        encHost_1 = _a.sent();
                        pathListSubscription_1 = this.linkPathListService
                            .getSubscription()
                            .subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var uploadedItem, params, result, sendThumbnailJobResponse;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(data.loaded && data.sorted)) return [3 /*break*/, 6];
                                        uploadedItem = data.pathlist.find(function (val) {
                                            if (val.enc_share_name === tItem.enc_share_name) {
                                                return true;
                                            }
                                        });
                                        if (!(uploadedItem !== undefined &&
                                            !thumbnailProcessing)) return [3 /*break*/, 6];
                                        thumbnailProcessing = true;
                                        tItem.link_owner_id = uploadedItem.link_owner_id;
                                        tItem.sync_id = uploadedItem.sync_id;
                                        params = this.urlService.getDownloadPubLinkParams(tItem, rsaDatakey_1, encHost_1);
                                        return [4 /*yield*/, this.apiService
                                                .execute('linksignrequest', {
                                                req: params,
                                            })
                                                .catch(function (err) {
                                                throw err;
                                            })];
                                    case 1:
                                        result = _a.sent();
                                        params = result.response;
                                        this.syncCookieService.deleteCookie('passwordlock');
                                        this.syncCookieService.deleteCookie('thumbnail_sign');
                                        if (!uploadedItem.linkpasswordlock) return [3 /*break*/, 2];
                                        this.syncCookieService.setDownloadPubLink(uploadedItem.linkpasswordlock);
                                        mfsUrl_1 =
                                            this.urlService.internalDownloadPubLinkPassword(tItem, params);
                                        mfsUrl_1 = window.location.origin + mfsUrl_1;
                                        return [3 /*break*/, 4];
                                    case 2: return [4 /*yield*/, this.urlService.internalDownloadPubLink(tItem, params)];
                                    case 3:
                                        mfsUrl_1 =
                                            _a.sent();
                                        _a.label = 4;
                                    case 4: return [4 /*yield*/, this.sendThumbnailJob(this.base64Service.encode(mfsUrl_1), tItem.filedata.mimetype, this.base64Service.encode(rsaDatakey_1.replace(/=/g, '')), tItem.cachekey, parseInt(uploadedItem.user_id, 10), data.cwd.event_device_id)];
                                    case 5:
                                        sendThumbnailJobResponse = _a.sent();
                                        if (pathListSubscription_1) {
                                            pathListSubscription_1.unsubscribe();
                                        }
                                        _a.label = 6;
                                    case 6: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 5];
                    case 4:
                        err_4 = _a.sent();
                        this.loggerService.error("Error in getThumbnail for public uploads:: " + err_4);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    FileUploader.prototype.finishUploadPublic = function (tItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data_key, filedigest, filedigestPromise, datakeyPromise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                data_key = tItem.data_key, filedigest = this.syncDigestService.finish(tItem.sha1_digest), filedigestPromise = this.syncCryptService.filedigestEncrypt(filedigest, tItem.data_key), datakeyPromise = this.syncCryptService.datakeyEncrypt(this.syncCryptService.bytesToB64(data_key), tItem.share_key);
                if (this.allowThumbnails(tItem)) {
                    this.getThumbnail(tItem);
                }
                return [2 /*return*/, Promise.all([filedigestPromise, datakeyPromise]).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var keys;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    keys = [
                                        {
                                            sync_id: tItem.sync_id || tItem.sync_pid,
                                            sharekey_id: tItem.share_key_id,
                                            enc_share_name: tItem.enc_share_name,
                                            servtime: tItem.servtime,
                                            enc_data_key: result[1],
                                        },
                                    ];
                                    return [4 /*yield*/, this.finishUploadFiles({
                                            cachekey: tItem.cachekey,
                                            backup_id: tItem.backup_id,
                                            publink_id: tItem.linkID,
                                            blob_id: tItem.blob_id,
                                            size_bytes: tItem.filesize,
                                            piece_size_bytes: tItem.filesize,
                                            file_digest: result[0],
                                            piece_digest: result[0],
                                            enc_piece_size_bytes: tItem.filesize + 36 * tItem.chunkamt,
                                            share_id: tItem.share_id,
                                            share_sequence: tItem.share_sequence,
                                            keys: keys,
                                            sync_pid: tItem.sync_pid,
                                            user_id: tItem.user_id,
                                            servtime: tItem.servtime,
                                            device_id: tItem.device_id,
                                            device_sig_b64: tItem.device_sig_b64,
                                            enc_name: tItem.enc_name,
                                            enc_piece_digest: 'DeadBeef',
                                            payload_crc32: 'DeadBeef',
                                            stats: tItem.stats
                                        })];
                                case 1:
                                    _a.sent();
                                    tItem.sha1_digest = undefined;
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    FileUploader.prototype.finishUploadFiles = function (json) {
        var _this = this;
        var xhr = new XMLHttpRequest();
        var formData = new FormData();
        formData.append('json', JSON.stringify(json));
        return new Promise(function (resolve, reject) {
            xhr.open('POST', _this.urlService.mkUpload(_this.urlService.uploadhosts[0], 'webfinishbackup'), true);
            // xhr.setRequestHeader('X-SYNC-UPLOAD-METRICS', '');
            xhr.ontimeout = function (evt) {
                xhr = null;
                formData = null;
                _this.loggerService.error('Finish upload timed out');
                return reject({ errcode: 7005 });
            };
            xhr.onload = function () {
                var status = xhr.status === 1223 ? 204 : xhr.status;
                var response = xhr.response;
                // const statustext = xhr.statusText || '';
                _this.loggerService.info(status + ' is the status');
                xhr = null;
                formData = null;
                if (status === 0 || !response) {
                    _this.loggerService.error('webfinishbackup An unknown error occurred, no response received and status = 0 during finish backup');
                    _this.loggerService.error("INPUT: " + JSON.stringify(json));
                    _this.loggerService.error("OUTPUT: " + response);
                    reject({ errcode: 7023 });
                }
                if (status === -1 || status >= 500) {
                    _this.loggerService.error('finishUpload() status ' + status);
                    _this.loggerService.error("INPUT: " + JSON.stringify(json));
                    _this.loggerService.error("OUTPUT: " + response);
                    reject({ errcode: 7020 });
                }
                var jsonData = {};
                try {
                    jsonData = JSON.parse(response);
                }
                catch (ex) {
                    _this.loggerService.error('Exception parsing finishUpload() response ' +
                        ex.toString());
                    _this.loggerService.error("INPUT: " + JSON.stringify(json));
                    _this.loggerService.error("OUTPUT: " + response);
                    reject({ errcode: 7020 });
                }
                if (parseInt(jsonData.success, 10) === 0 &&
                    jsonData.errcode === 2) {
                    _this.loggerService.error("INPUT: " + JSON.stringify(json));
                    _this.loggerService.error("OUTPUT: " + response);
                    reject({ errcode: 7000 });
                }
                else if (parseInt(jsonData.success, 10) === 0 &&
                    !jsonData.errcode) {
                    _this.loggerService.error('webfinishbackup success == 0, errcode = 7022');
                    _this.loggerService.error("OUTPUT: " + response);
                    _this.loggerService.error("INPUT: " + JSON.stringify(json));
                    return reject({ errcode: 7022 });
                }
                else if (jsonData.success == 1) {
                    return resolve(jsonData);
                }
                else {
                    _this.loggerService.error('webfinishbackup case from response output');
                    _this.loggerService.error("OUTPUT: " + response);
                    _this.loggerService.error("INPUT: " + JSON.stringify(json));
                    return reject({ errcode: 7024 });
                }
            };
            var reqError = function (evt) {
                xhr = null;
                formData = null;
                reject({ errcode: 7000 });
            };
            xhr.onerror = reqError;
            xhr.onabort = reqError;
            xhr.send(formData);
        });
    };
    FileUploader.prototype.buildFinishBackup = function (tItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var keysArray, digest, linkid, encFileDigest, finishBackupPayload, ts, rsaDatakey, mfsUrl, input, defaults;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dirtyOutService.executeForSync(tItem.sync_id || tItem.sync_pid, tItem.filename, this.syncCryptService.bytesToB64(tItem.data_key))];
                    case 1:
                        keysArray = _a.sent();
                        digest = this.syncDigestService.finish(tItem.sha1_digest);
                        linkid = tItem.linkID || undefined;
                        return [4 /*yield*/, this.syncCryptService.filedigestEncrypt(digest, tItem.data_key)];
                    case 2:
                        encFileDigest = _a.sent();
                        finishBackupPayload = {
                            cachekey: tItem.cachekey,
                            publink_id: linkid,
                            blob_id: tItem.blob_id,
                            size_bytes: tItem.filesize,
                            piece_size_bytes: tItem.filesize,
                            file_digest: encFileDigest,
                            piece_digest: encFileDigest,
                            enc_piece_size_bytes: tItem.filesize + 36 * tItem.chunkamt,
                            share_id: tItem.share_id,
                            share_sequence: tItem.share_sequence,
                            keys: keysArray,
                            sync_pid: tItem.sync_pid,
                            // user_id: tItem.user_id || this.User.get('uid'),
                            // servtime: tItem.servtime,
                            // device_id: tItem.device_id,
                            // device_sig_b64: tItem.device_sig_b64,
                            enc_name: tItem.enc_name,
                            stats: tItem.stats
                        };
                        if (!this.allowThumbnails(tItem)) return [3 /*break*/, 6];
                        this.syncCookieService.deleteCookie('thumbnail_sign');
                        ts = Date.now();
                        return [4 /*yield*/, this.syncCryptService.compatDatakeyEncrypt(this.syncCryptService.bytesToB64(tItem.data_key))];
                    case 3:
                        rsaDatakey = _a.sent();
                        return [4 /*yield*/, this.urlService.internalDownloadMfsUrl(tItem, rsaDatakey, ts)];
                    case 4:
                        mfsUrl = _a.sent();
                        input = new models_1.BaseApiInput();
                        input.servtime = ts;
                        return [4 /*yield*/, this.syncCryptService
                                .signApiReq(input)
                                .catch(function (err) {
                                throw err;
                            })];
                    case 5:
                        defaults = _a.sent();
                        mfsUrl =
                            window.location.origin +
                                mfsUrl +
                                '&access_token=' +
                                defaults.access_token;
                        finishBackupPayload.compat_url = this.base64Service.encode(mfsUrl);
                        finishBackupPayload.type = tItem.filedata.mimetype;
                        finishBackupPayload.enc_datakey = this.base64Service.encode(rsaDatakey.replace(/=/g, ''));
                        this.syncCookieService.setThumbnailSignature(defaults.signature);
                        _a.label = 6;
                    case 6: return [2 /*return*/, finishBackupPayload];
                }
            });
        });
    };
    FileUploader.prototype.finishUpload = function (tItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var finishBackupPayload;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.buildFinishBackup(tItem)];
                    case 1:
                        finishBackupPayload = _a.sent();
                        return [4 /*yield*/, this.apiService.execute('webfinishbackup', finishBackupPayload)];
                    case 2:
                        _a.sent();
                        tItem.sha1_digest = undefined;
                        return [2 /*return*/];
                }
            });
        });
    };
    FileUploader.prototype.allowThumbnails = function (tItem) {
        var allowThumbs = false;
        if (this.isImage(tItem) &&
            tItem.fileobj.type === 'image/heic' &&
            tItem.fileobj.size <= this.MAX_SIZE_HEIC_THUMB) {
            allowThumbs = true;
        }
        else if (this.isImage(tItem) &&
            tItem.fileobj.size <= this.MAX_SIZE_IMG_THUMB) {
            allowThumbs = true;
        }
        return allowThumbs;
    };
    FileUploader.prototype.isImage = function (item) {
        var name = item.filename;
        var ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
        var canPreview = false;
        switch (ext) {
            case 'jpg':
            case 'png':
            case 'jpeg':
            case 'gif':
            case 'heic':
            case 'webp':
            case 'avif':
            case 'tiff':
                canPreview = true;
                break;
            default:
                canPreview = false;
        }
        return canPreview && ext != name.toLowerCase();
    };
    return FileUploader;
}());
exports.FileUploader = FileUploader;
