"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var RewardsListApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(RewardsListApiOutput, _super);
    function RewardsListApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RewardsListApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.RewardsListApiOutput = RewardsListApiOutput;
var RewardsListApiInput = /** @class */ (function (_super) {
    tslib_1.__extends(RewardsListApiInput, _super);
    function RewardsListApiInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RewardsListApiInput;
}(base_api_model_1.BaseApiInput));
exports.RewardsListApiInput = RewardsListApiInput;
