"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var GetUserKeysApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(GetUserKeysApiOutput, _super);
    function GetUserKeysApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return GetUserKeysApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.GetUserKeysApiOutput = GetUserKeysApiOutput;
var GetUserKeysApiInput = /** @class */ (function (_super) {
    tslib_1.__extends(GetUserKeysApiInput, _super);
    function GetUserKeysApiInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return GetUserKeysApiInput;
}(base_api_model_1.BaseApiInput));
exports.GetUserKeysApiInput = GetUserKeysApiInput;
