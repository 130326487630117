"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var models_1 = require("../../shared/models");
var api_service_1 = require("../../core/api.service");
var rewards_service_1 = require("../services/rewards.service");
var DialogRewardsComponent = /** @class */ (function () {
    function DialogRewardsComponent(activeModal, api, rewards) {
        this.activeModal = activeModal;
        this.api = api;
        this.rewards = rewards;
    }
    DialogRewardsComponent.prototype.ngOnInit = function () {
        this.spinner = false;
    };
    DialogRewardsComponent.prototype.redeem = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, err_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.spinner = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.api.execute('rewardclaim', { referrer_code: this.refcode })];
                    case 2:
                        _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.rewards.getData()];
                    case 3:
                        _a.userRewards = _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _b.sent();
                        console.warn('error redeem', err_1);
                        if (err_1 instanceof models_1.ErrCode) {
                            this.errcode = err_1;
                        }
                        else {
                            this.errcode = models_1.ErrCode.fromException(err_1);
                        }
                        return [3 /*break*/, 5];
                    case 5:
                        this.spinner = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    return DialogRewardsComponent;
}());
exports.DialogRewardsComponent = DialogRewardsComponent;
