"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("./api.service");
var models_1 = require("../shared/models");
var logger_service_1 = require("./logger.service");
var notifications_service_1 = require("./notifications.service");
var i0 = require("@angular/core");
var i1 = require("./api.service");
var i2 = require("./logger.service");
var i3 = require("./notifications.service");
var JoblockService = /** @class */ (function () {
    function JoblockService(api, loggerService, notificationsService) {
        this.api = api;
        this.loggerService = loggerService;
        this.notificationsService = notificationsService;
        this.pollingInterval = 2000; // 2 seconds
    }
    JoblockService.prototype.getJobLock = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resp;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.execute('getjoblock', {})];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp];
                }
            });
        });
    };
    JoblockService.prototype.pollJobStatus = function () {
        var _this = this;
        this.notificationsService.stopNotificationLoop();
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var response, apiError_1, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 8, 9, 10]);
                        _a.label = 1;
                    case 1:
                        if (!true) return [3 /*break*/, 7];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.api.execute('getjoblock', {})];
                    case 3:
                        response = _a.sent();
                        if (response.has_joblock == 0) {
                            resolve(true);
                            return [3 /*break*/, 7];
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        apiError_1 = _a.sent();
                        this.loggerService.error("API call failed in pollJobStatus: " + apiError_1);
                        return [3 /*break*/, 5];
                    case 5: return [4 /*yield*/, this.delay(this.pollingInterval)];
                    case 6:
                        _a.sent();
                        return [3 /*break*/, 1];
                    case 7: return [3 /*break*/, 10];
                    case 8:
                        error_1 = _a.sent();
                        this.loggerService.error("Error in pollJobStatus:: " + error_1);
                        return [3 /*break*/, 10];
                    case 9:
                        this.notificationsService.startNotificationLoop();
                        return [7 /*endfinally*/];
                    case 10: return [2 /*return*/];
                }
            });
        }); });
    };
    JoblockService.prototype.delay = function (ms) {
        return new Promise(function (resolve) { return setTimeout(resolve, ms); });
    };
    JoblockService.ngInjectableDef = i0.defineInjectable({ factory: function JoblockService_Factory() { return new JoblockService(i0.inject(i1.ApiService), i0.inject(i2.LoggerService), i0.inject(i3.NotificationsService)); }, token: JoblockService, providedIn: "root" });
    return JoblockService;
}());
exports.JoblockService = JoblockService;
