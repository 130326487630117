"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./share-leave.component");
var i2 = require("../share.service");
var i3 = require("../../notifications/feedback.service");
var i4 = require("../../shared/services/broadcast.service");
var styles_ShareLeaveComponent = [];
var RenderType_ShareLeaveComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShareLeaveComponent, data: {} });
exports.RenderType_ShareLeaveComponent = RenderType_ShareLeaveComponent;
function View_ShareLeaveComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalInstance.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["Leave ", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" If you continue, you won't be able to see future changes to this shared folder. "])), (_l()(), i0.ɵeld(10, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["id", "leave-share-dialog-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.leaveShare() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Leave share "])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalInstance.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Cancel "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shareData.name; _ck(_v, 5, 0, currVal_0); }); }
exports.View_ShareLeaveComponent_0 = View_ShareLeaveComponent_0;
function View_ShareLeaveComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-share-leave", [], null, null, null, View_ShareLeaveComponent_0, RenderType_ShareLeaveComponent)), i0.ɵdid(1, 114688, null, 0, i1.ShareLeaveComponent, [i2.ShareService, i3.FeedbackService, i4.BroadcastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ShareLeaveComponent_Host_0 = View_ShareLeaveComponent_Host_0;
var ShareLeaveComponentNgFactory = i0.ɵccf("sync-share-leave", i1.ShareLeaveComponent, View_ShareLeaveComponent_Host_0, { openState: "openState", modalInstance: "modalInstance", shareData: "shareData" }, {}, []);
exports.ShareLeaveComponentNgFactory = ShareLeaveComponentNgFactory;
