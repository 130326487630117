"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var password_service_1 = require("../../auth/services/password.service");
var logger_service_1 = require("../../core/logger.service");
var user_service_1 = require("../../core/user.service");
var models_1 = require("../../shared/models");
/**
 * Generic password confirmation dialog.
 *
 * It will return the user's plain text password when the dialog resolves.
 *
 * Usage:
 *      const ref = this.modalService.open(DialogConfirmPasswordComponent, {
 *          backdropClass: 'in',
 *          windowClass: 'in',
 *          backdrop: 'static',
 *          // size: 'lg'
 *      });
 *      const result = await ref.result;
 *
 *      if (typeof result === 'string') { // this is their password validated }
 *      if (result === false) { // they cancelled the confirmation }
 *
 */
var DialogConfirmPasswordComponent = /** @class */ (function () {
    function DialogConfirmPasswordComponent(activeModal, log, PasswordService, userService) {
        this.activeModal = activeModal;
        this.log = log;
        this.PasswordService = PasswordService;
        this.userService = userService;
        this.errcode = undefined;
        this.password = '';
        this.spinner = false;
        this.user = undefined;
    }
    DialogConfirmPasswordComponent.prototype.ngOnInit = function () {
        this.user = this.userService.getUser();
    };
    DialogConfirmPasswordComponent.prototype.check = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.PasswordService.passwordValid(this.user.email, this.password)];
                    case 2:
                        _a.sent();
                        this.spinner = false;
                        this.log.info('Password is valid, continue');
                        this.activeModal.close(this.password);
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _a.sent();
                        this.spinner = false;
                        if (ex_1 instanceof models_1.ErrCode) {
                            if (ex_1.code == 8101) {
                                // password incorrect
                                this.log.warn(ex_1.code + " - user entered incorrect");
                            }
                            this.errcode = ex_1;
                        }
                        else if (ex_1 instanceof Error) {
                            this.log.error(ex_1.toString());
                            this.errcode = new models_1.ErrCode(1000, ex_1.toString());
                        }
                        else {
                            this.log.error(ex_1.toString());
                            this.log.error(ex_1);
                            this.log.error('DialogConfirmPassword encountered unhandled error');
                            this.errcode = new models_1.ErrCode(1000, 'An unknown error occcured');
                        }
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DialogConfirmPasswordComponent.prototype.cancel = function () {
        this.activeModal.close(false);
    };
    return DialogConfirmPasswordComponent;
}());
exports.DialogConfirmPasswordComponent = DialogConfirmPasswordComponent;
