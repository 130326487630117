import { Injectable } from '@angular/core';
import * as blend from 'mixpanel-browser';

import { environment } from '../../../environments/environment';
import { SkuService } from './sku.service';
import { DefaultProperties, BlendBroadcastPayload, User, BlendEvent } from '../models';
import { FeatureService } from './feature.service';
import { ApiService } from '../../core/api.service';
import { LoggerService } from '../../core/logger.service';
import { BroadcastService } from './broadcast.service';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../reducers';

@Injectable({
  providedIn: 'root'
})
export class BlendService {

  private blend: any;
  private userSubscribe: Subscription;
  private user: User;
  private lastUpdated: number;
  private ONE_HOUR = 60 * 60 * 1000; /* 1 hour in ms */

  constructor(
    private skuService: SkuService,
    private featureService: FeatureService,
    private apiService: ApiService,
    private broadcastService: BroadcastService,
    private log: LoggerService,
    private store: Store<fromRoot.State>,
  ) {
    this.blend = blend.init(
      environment.blendToken,
      {
        'persistence': 'localStorage', //This prevents getting a "Cookie Too Large" error and in general is a more reliable way to persist state on the browser.
        'api_host': environment.blendHost,
        ...(!environment.production && { 'debug': true })
      },
      'Sync'
    );

    this.broadcastService.on('sync.reset.user').subscribe(() => {
      this.reset();
    });
    this.broadcastService.on('sync.track.blend').subscribe((payload: BlendBroadcastPayload) => {
      this.track(payload.eventName, payload.parameters);
    });
  }

  async identifyUser(newUserId?: number): Promise<void> {
    let userId = null;
    if (newUserId) {
      userId = newUserId;
    } else {
      userId = this.user.id;
    }
    if (userId) {
      if (this.blend.get_distinct_id() != userId) {
        this.blend.reset();
        // Set a super property so that the URLs don't get posted with identify events
        this.blend.register({
          '$current_url': this.linkURLSanitizer(window.location.href),
          '$referrer': this.linkURLSanitizer(document.referrer)
        });
        this.blend.identify(userId);
        this.blend.unregister('$current_url');
        this.blend.unregister('$referrer');
        this.updateUserProperties();
      } else if (!this.lastUpdated || (Date.now() - this.lastUpdated) >= this.ONE_HOUR) {
        this.updateUserProperties();
      }
    }
  }

  async updateUserProperties(): Promise<void> {
    this.apiService.execute('updateblend', {});
    this.lastUpdated = Date.now();
  }

  async track(event: string, parameters: any = {}): Promise<void> {

    await this.getUser();
    const isBlendAllowed = this.user && this.user.is_disableanalytics != 1 || event == BlendEvent.ANALYTICS_OPT_IN;
    if (!isBlendAllowed) {
      return;
    }
    await this.identifyUser();
    this.blend.track(event, {
      ...this.getDefaultProperties(),
      ...parameters,
    });
  }

  async trackSignUp(newUserId: number, parameters: any = {}): Promise<void> {
    await this.identifyUser(newUserId);
    this.blend.track(BlendEvent.SIGN_UP, {
      ...this.getDefaultProperties(),
      ...parameters,
    });
  }

  async setUserProperty(obj) {
    const id = this.user.id;
    this.blend.identify(id);
    this.blend.people.set({ ...obj });
  }

  async trackPageview(): Promise<void> {
    await this.getUser();
    const isBlendAllowed = this.user && this.user.is_disableanalytics != 1;
    if (!isBlendAllowed) {
      return;
    }
      await this.identifyUser();
    this.blend.track_pageview(this.getDefaultProperties());
  }

  optOut(): void {
    this.blend.opt_out_tracking();
  }

  reset(): void {
    this.blend.reset();
    this.user = null;
    this.lastUpdated = null;
  }

  linkURLSanitizer(url) {
    try {
      // Parse the URL
      const parsedUrl = new URL(url);

      // Split the pathname into parts
      const pathParts = parsedUrl.pathname.split('/').filter(part => part !== '');
      const rootPath = (pathParts.length) ? pathParts[0] : '';

      switch (rootPath) {
        case 'dl': // e.g., /dl/aaaabbbb0/xxx-xxx-xx-xxx
          parsedUrl.pathname = '/' + pathParts.slice(0, 2).join('/');
          break;
      }

      return parsedUrl.href;
    } catch (error) {
      this.log.error('Error processing URL: ' + error.message);
      return url; // Return the original URL if there's an error
    }
  }

  isUrlHistoryClean() {
    // Parse the referrer and current URL
    const referrer = new URL(document.referrer, window.location.origin);
    const currentUrl = new URL(window.location.href);

    // Check if the referrer path starts with '/dl/'
    const referrerHasDl = referrer.pathname.startsWith('/dl/');

    // Check if the current URL path starts with '/dl/'
    const currentHasDl = currentUrl.pathname.startsWith('/dl/');

    return !referrerHasDl && !currentHasDl;
}

  getDefaultProperties(): DefaultProperties {
    if (!this.isUrlHistoryClean()) {
      const defaultProperties: DefaultProperties = {
        'current_page_title': 'Sync.com — link',
        'current_url_path': '-',
        '$current_url': this.linkURLSanitizer(window.location.href), // Sanitize the URL if it's a link
        'platform': 'CP',
        '$referrer': this.linkURLSanitizer(document.referrer),
      };
      return defaultProperties;
    }
    const defaultProperties: DefaultProperties = {
      'current_url_path': window.location.pathname,
      'platform': 'CP'
    };
    return defaultProperties;
  }

  private async getUser(): Promise<any> {
    return await new Promise(resolve => {
      if (this.user) {
        if (this.userSubscribe) { this.userSubscribe.unsubscribe(); }
        resolve(true);
        return;
      }
      this.userSubscribe = this.store
      .pipe(select(fromRoot.getAuthUser))
      .subscribe((data) => {
          this.user = data;
        if (this.userSubscribe) { this.userSubscribe.unsubscribe(); }
          resolve(true);
      });
    });
  }

  /**
   * I have add this function as it is intended to be used in multiple click events
   */
  public async addEventsForUpgradeButton() {
    const sku = await this.skuService.getSku();
    this.track(BlendEvent.UPGRADE_INQUIRY, {
        plan: sku,
        plan_type: await this.skuService.getPlanType(sku)
    });
  }
}
