"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var api_service_1 = require("../../core/api.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var user_service_1 = require("../../core/user.service");
var password_service_1 = require("../../auth/services/password.service");
var DialogPasswordResetComponent = /** @class */ (function () {
    function DialogPasswordResetComponent(activeModal, api, injector, password, userService) {
        this.activeModal = activeModal;
        this.api = api;
        this.injector = injector;
        this.password = password;
        this.userService = userService;
    }
    DialogPasswordResetComponent.prototype.ngOnInit = function () {
        this.status = this.user.is_resetpassword ? '1' : '0';
    };
    DialogPasswordResetComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var keyData, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        return [4 /*yield*/, this.password.passwordValid(this.user.email, this.curPass)];
                    case 2:
                        keyData = _a.sent();
                        if (!(this.status == '1')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.password.enablePassReset(this.curPass, keyData)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.password.disablePassReset()];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        this.userService.refresh();
                        this.activeModal.close(true);
                        return [3 /*break*/, 8];
                    case 7:
                        ex_1 = _a.sent();
                        console.error('Error with ', ex_1);
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        return [3 /*break*/, 8];
                    case 8:
                        this.spinner = false;
                        console.log('save');
                        return [2 /*return*/];
                }
            });
        });
    };
    return DialogPasswordResetComponent;
}());
exports.DialogPasswordResetComponent = DialogPasswordResetComponent;
