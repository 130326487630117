"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".wrapper[_ngcontent-%COMP%]{display:flex;padding-left:8px}.popover-wrapper[_ngcontent-%COMP%]{background-color:#fff;border-radius:4px;color:#000;display:inline-block;max-width:400px;position:relative;box-shadow:rgba(34,40,42,.48) 0 0 24px -3px}.popover-container[_ngcontent-%COMP%]{display:flex;flex-direction:row;align-items:center;padding:8px 8px 8px 16px}  .popover.bs-popover-bottom .arrow::after{z-index:1;border-bottom-color:#fff!important;bottom:5px;left:-15px}  .popover.bs-popover-top .arrow::after{z-index:1;border-top-color:#fff!important;left:-15px;top:40px}.popover-avatar[_ngcontent-%COMP%]{display:inline-flex;align-self:flex-start}.avatar-info[_ngcontent-%COMP%]{margin-left:8px;overflow:hidden}.avatar-info.disabled[_ngcontent-%COMP%]{background-color:#fff;opacity:.5}.avatar-displayname[_ngcontent-%COMP%]{display:-webkit-box;-webkit-line-clamp:2;overflow:hidden;word-break:break-all;max-height:40px;font-family:inherit;font-size:14px;letter-spacing:.15px;line-height:20px;color:#000;font-style:inherit;font-weight:700}.popover-view-all[_ngcontent-%COMP%]{padding:8px 8px 8px 16px}\n/*# sourceMappingURL=avatar-stack.component.css.map*/"];
exports.styles = styles;
