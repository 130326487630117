"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LINK_FILE_LIST_RESET = '[LinkFileList] Reset';
exports.LINK_FILE_LIST_REFRESH = '[LinkFileList] Refresh';
exports.LINK_FILE_LIST_LOAD = '[LinkFileList] Load';
exports.LINK_FILE_LIST_LOAD_APP = '[LinkFileList] Load App';
exports.LINK_FILE_LIST_CD = '[LinkFileList] cd';
exports.LINK_FILE_LIST_ERROR = '[LinkFileList] Error';
exports.LINK_FILE_LIST_SUCCESS = '[LinkFileList] Success';
exports.LINK_FILE_LIST_SORT = '[LinkFileList] Sort';
exports.LINK_FILE_LIST_SET_PASSWORD = '[LinkFileList] Set Password';
exports.LINK_FILE_LIST_LOAD_WHITELABEL = '[LinkFileList] Load WhiteLabel';
exports.LINK_FILE_LIST_SUCCESS_WHITELABEL = '[LinkFileList] WhiteLabel Success';
exports.IFRAME_READY = '[LinkFileList] IFrameReady';
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = exports.LINK_FILE_LIST_RESET;
    }
    return ResetAction;
}());
exports.ResetAction = ResetAction;
var SuccessAction = /** @class */ (function () {
    function SuccessAction(payload) {
        this.payload = payload;
        this.type = exports.LINK_FILE_LIST_SUCCESS;
    }
    return SuccessAction;
}());
exports.SuccessAction = SuccessAction;
var RefreshAction = /** @class */ (function () {
    function RefreshAction() {
        this.type = exports.LINK_FILE_LIST_REFRESH;
    }
    return RefreshAction;
}());
exports.RefreshAction = RefreshAction;
var LoadAction = /** @class */ (function () {
    function LoadAction(payload) {
        this.payload = payload;
        this.type = exports.LINK_FILE_LIST_LOAD;
    }
    return LoadAction;
}());
exports.LoadAction = LoadAction;
var LoadAppAction = /** @class */ (function () {
    function LoadAppAction(payload) {
        this.payload = payload;
        this.type = exports.LINK_FILE_LIST_LOAD_APP;
    }
    return LoadAppAction;
}());
exports.LoadAppAction = LoadAppAction;
var LoadWhiteLabelAction = /** @class */ (function () {
    function LoadWhiteLabelAction(payload) {
        this.payload = payload;
        this.type = exports.LINK_FILE_LIST_LOAD_WHITELABEL;
    }
    return LoadWhiteLabelAction;
}());
exports.LoadWhiteLabelAction = LoadWhiteLabelAction;
var WhiteLabelSuccessAction = /** @class */ (function () {
    function WhiteLabelSuccessAction(payload) {
        this.payload = payload;
        this.type = exports.LINK_FILE_LIST_SUCCESS_WHITELABEL;
    }
    return WhiteLabelSuccessAction;
}());
exports.WhiteLabelSuccessAction = WhiteLabelSuccessAction;
var ChangeDirAction = /** @class */ (function () {
    function ChangeDirAction(payload) {
        this.payload = payload;
        this.type = exports.LINK_FILE_LIST_CD;
    }
    return ChangeDirAction;
}());
exports.ChangeDirAction = ChangeDirAction;
var ErrorAction = /** @class */ (function () {
    function ErrorAction(payload) {
        this.payload = payload;
        this.type = exports.LINK_FILE_LIST_ERROR;
    }
    return ErrorAction;
}());
exports.ErrorAction = ErrorAction;
var IFrameReadyAction = /** @class */ (function () {
    function IFrameReadyAction(ready) {
        this.ready = ready;
        this.type = exports.IFRAME_READY;
    }
    return IFrameReadyAction;
}());
exports.IFrameReadyAction = IFrameReadyAction;
var SortAction = /** @class */ (function () {
    function SortAction(payload) {
        this.payload = payload;
        this.type = exports.LINK_FILE_LIST_SORT;
    }
    return SortAction;
}());
exports.SortAction = SortAction;
var SetPasswordAction = /** @class */ (function () {
    function SetPasswordAction(payload) {
        this.payload = payload;
        this.type = exports.LINK_FILE_LIST_SET_PASSWORD;
    }
    return SetPasswordAction;
}());
exports.SetPasswordAction = SetPasswordAction;
