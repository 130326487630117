"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var user_service_1 = require("../../core/user.service");
var models_1 = require("../../shared/models");
var environment_1 = require("../../../environments/environment");
var api_service_1 = require("../../core/api.service");
var logger_service_1 = require("../../core/logger.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_billing_profile_delete_component_1 = require("../dialog-billing-profile-delete/dialog-billing-profile-delete.component");
var dialog_contact_support_component_1 = require("../dialog-contact-support/dialog-contact-support.component");
var router_1 = require("@angular/router");
var zuora_account_service_1 = require("../services/zuora-account.service");
var sku_service_1 = require("../../shared/services/sku.service");
var blend_service_1 = require("../../shared/services/blend.service");
var BillingProfileComponent = /** @class */ (function () {
    function BillingProfileComponent(api, log, modalService, sanitizer, userService, router, zuora, skuService, blendService) {
        this.api = api;
        this.log = log;
        this.modalService = modalService;
        this.sanitizer = sanitizer;
        this.userService = userService;
        this.router = router;
        this.zuora = zuora;
        this.skuService = skuService;
        this.blendService = blendService;
        this.permissionActions = models_1.PermissionActions;
    }
    BillingProfileComponent.prototype.ngOnInit = function () {
        this.spinner = true;
        this.user = this.userService.getUser();
        this.planName = this.user.plan_name;
        if (this.checkPermission(this.permissionActions.VIEW_BILLING)) {
            this.loadBillingProfile();
        }
        else {
            this.planExpiry = this.user.account_duedate;
        }
        this.init();
    };
    BillingProfileComponent.prototype.loadBillingProfile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.zuora.getProfile()];
                    case 1:
                        _a.zprofile = _b.sent();
                        if (this.zprofile.subscription) {
                            this.planName = this.zprofile.subscription.productFullName;
                            this.planSku = this.zprofile.subscription.sku;
                            this.cancelInFuture = this.zprofile.subscription.status === 'Cancelled';
                            this.planExpiry = this.userService.dateConversionForTrials(this.zprofile.subscription.termEndDate, this.user.is_on_trial).getTime();
                            this.currentTerm = this.skuService.getPlanTerm(this.planSku);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BillingProfileComponent.prototype.checkPermission = function (permName) {
        return this.userService.checkPermission(permName);
    };
    BillingProfileComponent.prototype.init = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sess, baseUrl, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.log.info('Running init');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.execute('ordersessionnew', {})];
                    case 2:
                        sess = _a.sent();
                        if (!sess.sessionId) {
                            this.error = new models_1.ErrCode(8550);
                            return [2 /*return*/];
                        }
                        this.log.info('got session ' + sess.sessionId);
                        this.sessionId = sess.sessionId;
                        baseUrl = environment_1.environment.ordershost + "/profile";
                        // if (this.user.zuora_acct_key) {
                        //     baseUrl = baseUrl + `?acctkey=${this.user.zuora_acct_key}`;
                        // }
                        this.profileSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(baseUrl);
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _a.sent();
                        this.log.error('Error initializing billing profile');
                        this.error = models_1.ErrCode.fromException(ex_1);
                        this.log.e('An error occurred loading session', ex_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BillingProfileComponent.prototype.onPostMessage = function (e) {
        var _this = this;
        if (!e.data) {
            this.log.warn('Received a message without any data');
            return;
        }
        if (e.origin.indexOf('.paypal.com') > -1) {
            return;
        }
        if (e.origin !== environment_1.environment.ordershost) {
            this.log.warn("Billing profile e.origin does not match, do nothing origin: \"" + e.origin + "\" env: \"" + environment_1.environment.ordershost + "\"");
            return;
        }
        var regex = /^[a-z-]+::[\w -:,.{}'"\[\]]+$/;
        if (!regex.test(e.data)) {
            this.log.info("A message was received from " + e.origin + ", but did not pass regex " + e.data);
            // set a default error message
            // this.log.warn(`An invalid message was received from ${e.origin} "${e.data}"`);
            return;
        }
        // split on first occurence of "::"
        var data = e.data.split(/::(.*)/);
        var cmd = data[0];
        var payload = data[1];
        switch (cmd) {
            case 'sync-profile-dimensions':
                var num = parseInt(payload, 10);
                var newHeight = num + 32;
                // console.log('data =  ', e.data, 'num = ' , num, ' new height = ' , newHeight);
                this.iframe.nativeElement.height = newHeight + "px";
                break;
            case 'sync-profile-session':
                this.postToIframe('sync-profile-session', this.sessionId);
                break;
            case 'sync-profile-ready':
                if (payload === 'error') {
                    this.log.error('Error occurred loading the profile');
                    this.spinner = false;
                    this.error = new models_1.ErrCode(8500);
                }
                else {
                    this.spinner = false;
                    this.log.info('profile page has loaded');
                }
                break;
            case 'sync-profile-error':
                this.log.error("Error with sync-profile-error " + payload);
                this.framedError = payload;
                break;
            case 'sync-profile-saved':
                // clear the cache
                if (payload === 'error') {
                    this.log.error('Error saving the profile');
                }
                else {
                    this.api.execute('userinfoset', { cc_billingprofile: 1 })
                        .then(function () {
                        _this.userService.refresh();
                        _this.log.info('Cleared cache back to billing');
                        if (_this.user.is_on_trial && !_this.zprofile.defaultPaymentMethod) {
                            var baseUrl = environment_1.environment.webhost + "/welcome-trial-billing/?sku=" + _this.zprofile.subscription.sku + "&uid=" + _this.user.id;
                            window.location.href = baseUrl;
                        }
                        else {
                            _this.router.navigate(['/account/billing'], { queryParams: { newprofile: 1 } });
                        }
                    }).catch(function () {
                        _this.log.error('Error clearing the billing profile');
                        _this.router.navigate(['/account/billing']);
                    });
                }
                break;
            case 'sync-profile-delete':
                this.log.info('Delete billing profile started');
                this.deleteProfile();
                break;
            default:
                this.log.warn('Command not found');
        }
    };
    BillingProfileComponent.prototype.clearFramedError = function () {
        this.framedError = undefined;
    };
    BillingProfileComponent.prototype.postToIframe = function (cmd, payload) {
        this.iframe.nativeElement.contentWindow.postMessage(cmd + "::" + payload, '*');
    };
    BillingProfileComponent.prototype.openContactSupport = function () {
        var ref = this.modalService.open(dialog_contact_support_component_1.DialogContactSupportComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
    };
    BillingProfileComponent.prototype.deleteProfile = function () {
        var _this = this;
        var ref = this.modalService.open(dialog_billing_profile_delete_component_1.DialogBillingProfileDeleteComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.result.then(function (result) {
            if (result === true) {
                _this.userService.refresh();
                _this.router.navigate(['/account/billing'], { queryParams: { newprofile: 0 } });
            }
        });
    };
    BillingProfileComponent.prototype.onUpgradeClick = function () {
        this.blendService.addEventsForUpgradeButton();
        this.router.navigate(['/account/upgrade']);
    };
    return BillingProfileComponent;
}());
exports.BillingProfileComponent = BillingProfileComponent;
