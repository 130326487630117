"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../../models");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../../../reducers");
var logger_service_1 = require("../../../../core/logger.service");
var platform_browser_1 = require("@angular/platform-browser");
var environment_1 = require("../../../../../environments/environment");
var dialog_contact_support_component_1 = require("../../../../account/dialog-contact-support/dialog-contact-support.component");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var router_1 = require("@angular/router");
var HeaderPreviewsComponent = /** @class */ (function () {
    function HeaderPreviewsComponent(store, log, sanitizer, pageTitleService, modalService, router, route) {
        this.store = store;
        this.log = log;
        this.sanitizer = sanitizer;
        this.pageTitleService = pageTitleService;
        this.modalService = modalService;
        this.router = router;
        this.route = route;
        this.avatarEndpoint = environment_1.environment.logohost + "avatar/";
        this.cpHost = environment_1.environment.cphost;
        this.PAGE_TITLE = 'Sync.com | Control Panel';
        this.compat = 0;
    }
    HeaderPreviewsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sub = this.store
            .pipe(store_1.select(fromRoot.getAuthUser))
            .subscribe(function (data) {
            if (data) {
                _this.user = data;
            }
            else {
                _this.user = null;
            }
            _this.userLoaded = true;
        });
        //only fetch white label when it's a shared link
        if (this.item.context === 'link' || this.item.context === 'applink') {
            this.sub1 = this.store
                .pipe(store_1.select(fromRoot.getLinkFileListState))
                .subscribe(function (data) {
                if (data && data.loaded && data.sorted) {
                    if (data.whitelabel_loaded) {
                        _this.imageCachekey = data.image_cachekey;
                        _this.primaryColor = data.header_primary_color;
                        _this.textColor = data.header_text_color;
                        _this.linkColor = data.link_text_color;
                        _this.whiteLabelLoaded = data.whitelabel_loaded;
                        _this.logoHost = environment_1.environment.logohost;
                        _this.isReady = true;
                        _this.compat = data.compat;
                    }
                }
                //observe when iframe in comment-block completely loaded
                if (data.iframeReady) {
                    _this.userLoaded = true;
                }
            });
        }
        else {
            this.isReady = true;
        }
    };
    HeaderPreviewsComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.sub1) {
            this.sub1.unsubscribe();
        }
        this.pageTitleService.setTitle("" + this.PAGE_TITLE);
    };
    HeaderPreviewsComponent.prototype.goBack = function () {
        if (this.router.url.includes('/file')) {
            this.router.navigate(['/files', this.item.pid], {
                queryParams: {
                    view: this.route.snapshot.queryParams.view,
                    page: this.route.snapshot.queryParams.page,
                }
            });
        }
        else {
            var _a = this.route.snapshot.params, cachekey = _a.cachekey, key = _a.key;
            var navigationParams = tslib_1.__assign({ relativeTo: this.route.parent }, this.decorateFragment(), { queryParams: {
                    sync_id: this.item.pid,
                    view: this.route.snapshot.params['view'],
                    page: this.route.snapshot.queryParams['page']
                } });
            key ? this.router.navigate(['.', cachekey, key], navigationParams)
                : this.router.navigate(['.', cachekey], navigationParams);
        }
        // window.location.href = '/files/' + this.item.pid;
    };
    HeaderPreviewsComponent.prototype.decorateFragment = function () {
        if (!this.compat || this.route.snapshot.fragment) {
            return { fragment: this.route.snapshot.fragment };
        }
    };
    HeaderPreviewsComponent.prototype.openContactSupport = function () {
        var ref = this.modalService.open(dialog_contact_support_component_1.DialogContactSupportComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
    };
    Object.defineProperty(HeaderPreviewsComponent.prototype, "customColorsCss", {
        /**
         * this function updates css variables based on changes of this component's attributes
         */
        get: function () {
            return this.sanitizer
                .bypassSecurityTrustStyle("--headerPrimaryColor: " + this.primaryColor + ";\n                                                        --headerTextColor: " + this.textColor + ";\n                                                        --linkTextColor: " + this.linkColor + ";\n                                                    ");
        },
        enumerable: true,
        configurable: true
    });
    return HeaderPreviewsComponent;
}());
exports.HeaderPreviewsComponent = HeaderPreviewsComponent;
