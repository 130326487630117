"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var logger_service_1 = require("../../core/logger.service");
var notifications_service_1 = require("../../core/notifications.service");
var broadcast_service_1 = require("../../shared/services/broadcast.service");
var transfer_model_1 = require("../transfer.model");
var build_transfer_item_service_1 = require("./build-transfer-item.service");
var transfer_item_service_1 = require("./transfer-item.service");
var file_uploader_1 = require("../file-uploader");
var i0 = require("@angular/core");
var i1 = require("./transfer-item.service");
var i2 = require("../../core/logger.service");
var i3 = require("./build-transfer-item.service");
var i4 = require("../../core/notifications.service");
var i5 = require("../../shared/services/broadcast.service");
var RunUploadFolderService = /** @class */ (function () {
    function RunUploadFolderService(transferItemService, loggerService, buildTransferItemService, notificationsService, broadcastService, injector) {
        this.transferItemService = transferItemService;
        this.loggerService = loggerService;
        this.buildTransferItemService = buildTransferItemService;
        this.notificationsService = notificationsService;
        this.broadcastService = broadcastService;
        this.injector = injector;
        this.view = {
            fileCount: 0,
            filePendingCount: 0,
            cwdName: '',
            currentFolder: '',
            folderCount: 0,
            folderMadeCount: 0,
            totalBytes: 0,
            pendingBytes: 0,
            fileErrorCount: 0,
            current: undefined,
            isProcessing: false,
            isMakingFolders: false,
            isMakingTransfers: false,
            isScanning: false,
            eta: 0,
            error: undefined
        };
        this.workDirs = [];
        this.queueFiles = [];
        this.shouldCancel = false;
        this.dirMap = {};
        this.workFiles = [];
        this.folderUploadInProgress = false;
        this.fileUploader = new file_uploader_1.FileUploader(this.injector);
    }
    RunUploadFolderService.prototype.restart = function () {
        var result = [];
        var bytes = 0;
        for (var i = 0, len = this.workFiles.length; i < len; i++) {
            var tItem = this.workFiles[i];
            if (tItem.status !== transfer_model_1.TransferStatus.STATUS_SUCCESS) {
                tItem.status = transfer_model_1.TransferStatus.STATUS_WAITING;
                tItem.chunkqueue = this.transferItemService.getChunkQueue(tItem);
                bytes += tItem.filesize;
                result.push(tItem);
            }
        }
        this.reset();
        this.workFiles = result;
        this.view.fileCount = result.length;
        // this.view.filePendingCount = result.length;
        // this.view.pendingBytes = bytes;
        this.view.totalBytes = bytes;
        this.view.error = undefined;
        this.runUploadQueue();
    };
    RunUploadFolderService.prototype.runUploadQueue = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var next, tItem, stats, timePreUpload, timeFinishUpload, bps, estimated, total, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.shouldCancel) {
                            this.loggerService.error('Cancel requested');
                            throw { code: 7210 };
                        }
                        if (!this.workFiles.length) return [3 /*break*/, 12];
                        next = this.buildTransferItemService.getLatestItem(this.workFiles);
                        this.view.filePendingCount = next.pending;
                        this.view.pendingBytes = next.pendingBytes;
                        if (!(next.idx !== -1)) return [3 /*break*/, 10];
                        this.view.isProcessing = true;
                        tItem = this.workFiles[next.idx];
                        if (!tItem) return [3 /*break*/, 7];
                        this.view.current = tItem;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 6]);
                        return [4 /*yield*/, this.fileUploader.uploadItem(tItem)];
                    case 2:
                        stats = _a.sent();
                        timePreUpload = this.view.filePendingCount * stats.elapsedPreUpload;
                        timeFinishUpload = this.view.filePendingCount * stats.elapsedFinishUpload;
                        bps = stats.size / stats.elapsedUpload;
                        estimated = this.view.pendingBytes / bps;
                        total = (timePreUpload + timeFinishUpload + estimated) / 1000;
                        this.view.eta = total;
                        this.loggerService.info('Estimated time remaining ' + total + ' seconds');
                        return [4 /*yield*/, this.runUploadQueue()];
                    case 3: return [2 /*return*/, _a.sent()];
                    case 4:
                        ex_1 = _a.sent();
                        this.view.fileErrorCount += 1;
                        this.loggerService.error('Uploading failed for ' + tItem.backup_id);
                        this.loggerService.info('runUploadQueue()');
                        return [4 /*yield*/, this.runUploadQueue()];
                    case 5: return [2 /*return*/, _a.sent()];
                    case 6: return [3 /*break*/, 9];
                    case 7:
                        this.loggerService.error('Could not locate transfer item');
                        return [4 /*yield*/, this.runUploadQueue()];
                    case 8: return [2 /*return*/, _a.sent()];
                    case 9: return [3 /*break*/, 11];
                    case 10:
                        // could not find any more, I think we're done.
                        this.loggerService.info('Completed upload: ' + this.view.fileCount);
                        this.notificationsService.startNotificationLoop();
                        this.broadcastService.broadcast('event:link-file-list.reload');
                        this.view.isProcessing = false;
                        return [2 /*return*/, true];
                    case 11: return [3 /*break*/, 13];
                    case 12:
                        this.view.isProcessing = false;
                        this.notificationsService.startNotificationLoop();
                        return [2 /*return*/, false];
                    case 13: return [2 /*return*/];
                }
            });
        });
    };
    RunUploadFolderService.prototype.reset = function () {
        this.view.fileCount = 0;
        this.view.filePendingCount = 0;
        this.view.fileErrorCount = 0;
        this.view.cwdName = '';
        this.view.currentFolder = '';
        this.view.folderCount = 0;
        this.view.folderMadeCount = 0;
        this.view.totalBytes = 0;
        this.view.pendingBytes = 0;
        this.view.error = undefined;
        this.view.current = undefined;
        this.view.isScanning = false;
        this.view.isProcessing = false;
        this.view.isMakingFolders = false;
        this.view.isMakingTransfers = false;
        this.view.error = undefined;
        this.dirMap = {};
        this.workFiles = [];
        this.workDirs = [];
        this.queueFiles = [];
        this.shouldCancel = false;
        this.cwd = undefined;
        this.folderUploadInProgress = false;
    };
    RunUploadFolderService.prototype.cancel = function () {
        this.shouldCancel = true;
        this.reset();
    };
    RunUploadFolderService.ngInjectableDef = i0.defineInjectable({ factory: function RunUploadFolderService_Factory() { return new RunUploadFolderService(i0.inject(i1.TransferItemService), i0.inject(i2.LoggerService), i0.inject(i3.BuildTransferItemService), i0.inject(i4.NotificationsService), i0.inject(i5.BroadcastService), i0.inject(i0.INJECTOR)); }, token: RunUploadFolderService, providedIn: "root" });
    return RunUploadFolderService;
}());
exports.RunUploadFolderService = RunUploadFolderService;
