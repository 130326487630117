"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var UserDiskUsageApiInput = /** @class */ (function (_super) {
    tslib_1.__extends(UserDiskUsageApiInput, _super);
    function UserDiskUsageApiInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserDiskUsageApiInput;
}(base_api_model_1.BaseApiOutput));
exports.UserDiskUsageApiInput = UserDiskUsageApiInput;
var UserDiskUsageApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(UserDiskUsageApiOutput, _super);
    function UserDiskUsageApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserDiskUsageApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.UserDiskUsageApiOutput = UserDiskUsageApiOutput;
