"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DirtyOutNeedEncItem = /** @class */ (function () {
    function DirtyOutNeedEncItem() {
    }
    return DirtyOutNeedEncItem;
}());
exports.DirtyOutNeedEncItem = DirtyOutNeedEncItem;
var DirtyOutNeedEncDataKeys = /** @class */ (function () {
    function DirtyOutNeedEncDataKeys() {
    }
    return DirtyOutNeedEncDataKeys;
}());
exports.DirtyOutNeedEncDataKeys = DirtyOutNeedEncDataKeys;
