"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./save-file.component");
var i3 = require("../../../core/api.service");
var i4 = require("@angular/platform-browser");
var styles_SaveFileComponent = [];
var RenderType_SaveFileComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SaveFileComponent, data: {} });
exports.RenderType_SaveFileComponent = RenderType_SaveFileComponent;
function View_SaveFileComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["class", "syncwhite btn btn-primary btn-xs"]], [[8, "id", 0], [8, "download", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveDlAttr() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Download "])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-arrow-down"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.saveFile.uniqid, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.saveFile.filename, ""); var currVal_2 = _co.bypassSecurityTrustUrl(((_co.saveFile == null) ? null : ((_co.saveFile.renderFile == null) ? null : _co.saveFile.renderFile.url))); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_SaveFileComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["class", "syncwhite btn btn-primary btn-xs"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.msBlobSave(_co.saveFile.renderFile.msblob, _co.saveFile.filename) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Download "])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-arrow-down"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.saveFile.uniqid, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_SaveFileComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SaveFileComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SaveFileComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.saveFile == null) ? null : ((_co.saveFile.renderFile == null) ? null : _co.saveFile.renderFile.savemethod)) == "dlattr"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_co.saveFile == null) ? null : ((_co.saveFile.renderFile == null) ? null : _co.saveFile.renderFile.savemethod)) == "msblob"); _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_SaveFileComponent_0 = View_SaveFileComponent_0;
function View_SaveFileComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-save-file", [], null, null, null, View_SaveFileComponent_0, RenderType_SaveFileComponent)), i0.ɵdid(1, 638976, null, 0, i2.SaveFileComponent, [i3.ApiService, i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SaveFileComponent_Host_0 = View_SaveFileComponent_Host_0;
var SaveFileComponentNgFactory = i0.ɵccf("sync-save-file", i2.SaveFileComponent, View_SaveFileComponent_Host_0, { saveFile: "syncSaveFile", autodownload: "autodownload" }, {}, []);
exports.SaveFileComponentNgFactory = SaveFileComponentNgFactory;
