"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var KeysNeedEncBySyncApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(KeysNeedEncBySyncApiOutput, _super);
    function KeysNeedEncBySyncApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return KeysNeedEncBySyncApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.KeysNeedEncBySyncApiOutput = KeysNeedEncBySyncApiOutput;
var KeysNeedEncBySyncApiInput = /** @class */ (function () {
    function KeysNeedEncBySyncApiInput() {
    }
    return KeysNeedEncBySyncApiInput;
}());
exports.KeysNeedEncBySyncApiInput = KeysNeedEncBySyncApiInput;
