import { BlendProperties } from '../shared/models';

interface BlendEditableLinkOptions {
    allow_comment: string;
    comment_notification: boolean;
    compat: boolean;
    download_limit: number;
    email_notification: boolean;
    exp_servtime: any;
    file_permissions: string;
    type: string;
    label: string;
    publink_id: string;
    share_id: number;
    sync_id: number;
    upload: boolean;
}


export const renameLinkOptions = (options): BlendEditableLinkOptions => {


    // FILE PERMISSIONS
    let file_permissions;
    if (options['previewonly']) {
        file_permissions = BlendProperties.LINK_PERMISSION_VIEW;
    }
    if (!options['previewonly'] && !options['file_edit']) {
        file_permissions = BlendProperties.LINK_PERMISSION_DOWNLOAD;
    }
    if (options['file_edit']) {
        file_permissions = BlendProperties.LINK_PERMISSION_EDITABLE;
    }


    // FILE TYPE
    let type;
    if (options['type'] === 'dir') {
        type = BlendProperties.LINK_TYPE_FOLDER;
    } else {
        type = options['file_extension'];
    }


    // ALLOW COMMENT
    let allow_comment;
    switch (options['allow_comment']) {
        case 1:
            allow_comment = BlendProperties.LINK_COMMENTS_ENABLED_AUTH;
            break;
        case 2:
            allow_comment = BlendProperties.LINK_COMMENTS_ENABLED_ALL;
            break;
        default:
            allow_comment = BlendProperties.LINK_COMMENTS_DISABLED;
    }

    return {
        allow_comment,
        comment_notification: !!+options['comment_notification'],
        compat: !!+options['compat'],
        download_limit: options['download_limit'],
        email_notification: !!+options['email_notification'],
        exp_servtime: options['exp_servtime'],
        file_permissions,
        type,
        label: options['label'],
        publink_id: options['publink_id'],
        share_id: options['sync_id'],
        sync_id: options['sync_id'],
        upload: !!+options['upload']
    };
};
