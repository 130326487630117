"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UserRewards = /** @class */ (function () {
    function UserRewards(params) {
        this.total = 0;
        this.count = 0;
        this.is_referred = false;
        this.referral_code = '';
        this.list = [];
        this.from_sync = [];
        this.last_reward_date = 0;
        this.last_reward_amt = 0;
        if (params) {
            return this.deserialize(params);
        }
    }
    UserRewards.prototype.deserialize = function (params) {
        for (var _i = 0, _a = Object.entries(params); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], val = _b[1];
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    };
    return UserRewards;
}());
exports.UserRewards = UserRewards;
