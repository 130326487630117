"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var api_service_1 = require("../../core/api.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var DialogDeviceDeleteComponent = /** @class */ (function () {
    function DialogDeviceDeleteComponent(activeModal, api) {
        this.activeModal = activeModal;
        this.api = api;
    }
    DialogDeviceDeleteComponent.prototype.ngOnInit = function () {
    };
    DialogDeviceDeleteComponent.prototype.delete = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.confirm) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.api.execute('devicedelete', {
                                device_id: this.device.id
                            })];
                    case 1:
                        _a.sent();
                        this.activeModal.close(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    return DialogDeviceDeleteComponent;
}());
exports.DialogDeviceDeleteComponent = DialogDeviceDeleteComponent;
