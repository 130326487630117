"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var user_service_1 = require("../../core/user.service");
var rewards_service_1 = require("../services/rewards.service");
var Dialog1UpComponent = /** @class */ (function () {
    function Dialog1UpComponent(activeModal, rewardsService, userService) {
        this.activeModal = activeModal;
        this.rewardsService = rewardsService;
        this.userService = userService;
        this.spinner = false;
    }
    Dialog1UpComponent.prototype.ngOnInit = function () { };
    Dialog1UpComponent.prototype.closeModal = function () {
    };
    Dialog1UpComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.returnCode = -1;
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.rewardsService.claimRewardOnce('sync-konami-code')];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.userService.refresh()];
                    case 3:
                        _a.sent();
                        this.returnCode = 0;
                        this.spinner = false;
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        this.returnCode = 1;
                        this.spinner = false;
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return Dialog1UpComponent;
}());
exports.Dialog1UpComponent = Dialog1UpComponent;
