"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var sync_crypt_service_1 = require("./crypt/sync-crypt.service");
var logger_service_1 = require("./logger.service");
var models_1 = require("../shared/models");
var router_1 = require("@angular/router");
var i0 = require("@angular/core");
var i1 = require("./crypt/sync-crypt.service");
var i2 = require("./logger.service");
var i3 = require("@angular/router");
var DispatchService = /** @class */ (function () {
    function DispatchService(crypt, log, router, route) {
        this.crypt = crypt;
        this.log = log;
        this.router = router;
        this.route = route;
    }
    DispatchService.prototype.getDipatchData = function () {
        var locParam = this.route.snapshot.queryParams['loc'];
        if (!locParam) {
            return undefined;
        }
        var locData = {};
        try {
            var json = this.crypt.bytesToString(this.crypt.hexToBytes(locParam));
            locData = JSON.parse(json);
            if (!locData || !locData.action) {
                this.log.error('No action provided in location');
                return undefined;
            }
            return locData;
        }
        catch (ex) {
            this.log.e('An invalid string was passed to Dispatch, ignoring', models_1.ErrCode.fromException(ex));
            this.log.error('loc = ' + locParam);
            return undefined;
        }
    };
    DispatchService.prototype.run = function () {
        // console.log('dispatch run');
        // console.log('state = ' , this.$stateParams);
        var locData = this.getDipatchData();
        if (!locData) {
            return;
        }
        var locTransition = {
            loc: this.route.snapshot.queryParams['loc']
        };
        var locWithPlanTransition = tslib_1.__assign({ loc: this.route.snapshot.queryParams['loc'], p: this.route.snapshot.queryParams['p'], rp: this.route.snapshot.queryParams['rp'], rpc: this.route.snapshot.queryParams['rpc'], promocode: this.route.snapshot.queryParams['promocode'], showform: this.route.snapshot.queryParams['showform'], discp: this.route.snapshot.queryParams['discp'], discrp: this.route.snapshot.queryParams['discrp'], discrpc: this.route.snapshot.queryParams['discrpc'], cnc: this.route.snapshot.queryParams['cnc'], allowtrial: this.route.snapshot.queryParams['allowtrial'], payrequired: this.route.snapshot.queryParams['payrequired'] }, (this.route.snapshot.queryParams['min_user'] ? { min_user: this.route.snapshot.queryParams['min_user'] } : {}));
        switch (locData.action) {
            case 'goto_events':
                // this.removeDispatch();
                this.router.navigate(['/events'], { queryParams: undefined });
                break;
            case 'default':
                // this.removeDispatch();
                this.router.navigate(['/files'], { queryParams: undefined });
                break;
            case 'goto_sync_pid':
                if (locData.sync_pid) {
                    this.router.navigate(['/files', locData.sync_pid], { queryParams: undefined });
                }
                else {
                    this.router.navigate(['/files'], { queryParams: undefined });
                }
                break;
            case 'manage_share':
                if (!this.router.url.includes('/shares')) {
                    this.router.navigate(['/shares'], { queryParams: locTransition });
                }
                break;
            case 'manage_publink':
                if (!this.router.url.includes('/links')) {
                    this.router.navigate(['/links'], { queryParams: locTransition });
                }
                break;
            case 'device_activate':
                this.router.navigate(['/account/devices'], { queryParams: locTransition });
                break;
            case 'more_space':
                this.router.navigate(['/account/upgrade'], { queryParams: locWithPlanTransition });
                break;
            default:
                this.router.navigate(['/files'], { queryParams: undefined });
        }
    };
    DispatchService.prototype.removeDispatch = function () {
        this.router.navigate([], { relativeTo: this.route, queryParams: { loc: undefined } });
    };
    DispatchService.ngInjectableDef = i0.defineInjectable({ factory: function DispatchService_Factory() { return new DispatchService(i0.inject(i1.SyncCryptService), i0.inject(i2.LoggerService), i0.inject(i3.Router), i0.inject(i3.ActivatedRoute)); }, token: DispatchService, providedIn: "root" });
    return DispatchService;
}());
exports.DispatchService = DispatchService;
