"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var api_service_1 = require("../../../core/api.service");
var SaveFileComponent = /** @class */ (function () {
    function SaveFileComponent(apiService, sanitizer) {
        this.apiService = apiService;
        this.sanitizer = sanitizer;
    }
    SaveFileComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['saveFile']
            && changes['saveFile'].currentValue
            && parseInt(this.autodownload, 10)
            && !this.saveFile.renderFile.downloadSuccess) {
            setTimeout(function () {
                var elem = document.getElementById(_this.saveFile.uniqid);
                if (elem) {
                    elem.click();
                    // Set 'downloadSuccess' to 1 to indicate that the download has been triggered successfully
                    _this.saveFile.renderFile.downloadSuccess = 1;
                }
            }, 1000);
        }
    };
    SaveFileComponent.prototype.bypassSecurityTrustUrl = function (url) {
        if (url) {
            return this.sanitizer.bypassSecurityTrustUrl(url);
        }
        return null;
    };
    SaveFileComponent.prototype.ngOnInit = function () { };
    SaveFileComponent.prototype.msBlobSave = function (blob, filename) {
        if (this.saveFile.linkID) {
            this.consume([this.saveFile.linkID]);
        }
        window.navigator.msSaveOrOpenBlob(blob, filename);
    };
    SaveFileComponent.prototype.saveDlAttr = function () {
        if (this.saveFile.linkID) {
            this.consume([this.saveFile.linkID]);
        }
    };
    SaveFileComponent.prototype.consume = function (links) {
        var publinks = [];
        for (var i = 0, len = links.length; i < len; i++) {
            publinks.push({ publink_id: links[i] });
        }
        return this.apiService.execute('linkconsume', { links: publinks });
    };
    return SaveFileComponent;
}());
exports.SaveFileComponent = SaveFileComponent;
