/* tslint:disable:no-bitwise */

import { WebPath } from './webpath.model';
import { Deserializable } from './deserializable.model';

export class LinkListItem extends WebPath implements Deserializable<LinkListItem> {

    public publink_id: string;
    public loc_id: string; //this field only gets set in link-list.service, not in the API
    public spinner: boolean;
    public download_limit: number;
    public download_count: number;
    public comment_count: number; //this field only gets set in link-list.component, not in the API
    public is_download_limit: boolean;
    public is_download_close: boolean;
    public is_expired: boolean;
    public exp_servtime: number;
    public email_notification: number;
    public passwordprotect: string;
    public is_suspended: boolean;
    public label: string;
    public parent_id: number;
    public upload: number;
    public password: string;
    public allow_comment: 0 | 1 | 2;
    public file_extension: string;

    constructor(params?: {[k: string]: any}) {
        super(params);
        if (params) {
            this.deserialize(params);
        }
    }

    public deserialize(params: any) {
        for (const [key, val] of Object.entries(params)) {
            if (key in this) {
                this[key] = val;
            }
        }

        return this;
    }
}
