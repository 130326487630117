"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("../../shared/components/error/error.component.ngfactory");
var i3 = require("../../shared/components/error/error.component");
var i4 = require("../../core/user.service");
var i5 = require("@ng-bootstrap/ng-bootstrap");
var i6 = require("@angular/platform-browser");
var i7 = require("@angular/forms");
var i8 = require("../../shared/directives/trim.directive");
var i9 = require("./dialog-email-billing-doc.component");
var i10 = require("../../core/logger.service");
var i11 = require("../services/zuora-account.service");
var i12 = require("../../core/validate.service");
var styles_DialogEmailBillingDocComponent = [];
var RenderType_DialogEmailBillingDocComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogEmailBillingDocComponent, data: {} });
exports.RenderType_DialogEmailBillingDocComponent = RenderType_DialogEmailBillingDocComponent;
function View_DialogEmailBillingDocComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "badge"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeQueue(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_DialogEmailBillingDocComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-spin fa-spinner"]], null, null, null, null, null))], null, null); }
function View_DialogEmailBillingDocComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.CurrencyPipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Email billing document"])), (_l()(), i0.ɵeld(7, 0, null, null, 24, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 23, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 22, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "sync-error", [], null, null, null, i2.View_ErrorComponent_0, i2.RenderType_ErrorComponent)), i0.ɵdid(11, 114688, null, 0, i3.ErrorComponent, [i4.UserService, i5.NgbModal, i6.DomSanitizer], { errcode: [0, "errcode"] }, null), (_l()(), i0.ɵted(-1, null, [" Email a copy of the document "])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(14, null, ["", ""])), (_l()(), i0.ɵted(15, null, [" ", " for ", " "])), i0.ɵppd(16, 1), (_l()(), i0.ɵeld(17, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 10, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 6, "input", [["autocomplete", "off"], ["class", "form-control"], ["id", "text-sharemember-add-sharewith"], ["name", "email"], ["placeholder", "Enter email address"], ["type", "email"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 20)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 20).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 20)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 20)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("blur" === en)) {
        var pd_4 = (i0.ɵnov(_v, 25).onBlur() !== false);
        ad = (pd_4 && ad);
    } if (("change" === en)) {
        var pd_5 = (i0.ɵnov(_v, 25).onChange() !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = ((_co.email = $event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(20, 16384, null, 0, i7.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i0.ɵdid(22, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i0.ɵdid(24, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), i0.ɵdid(25, 16384, null, 0, i8.TrimDirective, [i0.Renderer2, i0.ElementRef, [2, i7.NgModel], [2, i7.NgControl]], null, null), (_l()(), i0.ɵeld(26, 0, null, null, 2, "div", [["class", "input-group-btn"]], null, null, null, null, null)), (_l()(), i0.ɵeld(27, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addToQueue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(28, 0, null, null, 0, "i", [["class", "fas fa-plus"]], null, null, null, null, null)), (_l()(), i0.ɵeld(29, 0, null, null, 2, "div", [["class", "shareuserbadges"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogEmailBillingDocComponent_1)), i0.ɵdid(31, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(32, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(33, 0, null, null, 3, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogEmailBillingDocComponent_2)), i0.ɵdid(35, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, [" Email "])), (_l()(), i0.ɵeld(37, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errcode; _ck(_v, 11, 0, currVal_0); var currVal_11 = "email"; var currVal_12 = _co.email; _ck(_v, 22, 0, currVal_11, currVal_12); var currVal_13 = _co.emailQueue; _ck(_v, 31, 0, currVal_13); var currVal_14 = _co.spinner; _ck(_v, 35, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.doc.number; _ck(_v, 14, 0, currVal_1); var currVal_2 = _co.doc.date; var currVal_3 = i0.ɵunv(_v, 15, 1, _ck(_v, 16, 0, i0.ɵnov(_v, 0), _co.doc.amount)); _ck(_v, 15, 0, currVal_2, currVal_3); var currVal_4 = i0.ɵnov(_v, 24).ngClassUntouched; var currVal_5 = i0.ɵnov(_v, 24).ngClassTouched; var currVal_6 = i0.ɵnov(_v, 24).ngClassPristine; var currVal_7 = i0.ɵnov(_v, 24).ngClassDirty; var currVal_8 = i0.ɵnov(_v, 24).ngClassValid; var currVal_9 = i0.ɵnov(_v, 24).ngClassInvalid; var currVal_10 = i0.ɵnov(_v, 24).ngClassPending; _ck(_v, 19, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
exports.View_DialogEmailBillingDocComponent_0 = View_DialogEmailBillingDocComponent_0;
function View_DialogEmailBillingDocComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-dialog-email-billing-doc", [], null, null, null, View_DialogEmailBillingDocComponent_0, RenderType_DialogEmailBillingDocComponent)), i0.ɵdid(1, 114688, null, 0, i9.DialogEmailBillingDocComponent, [i5.NgbActiveModal, i10.LoggerService, i11.ZuoraAccountService, i12.ValidateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DialogEmailBillingDocComponent_Host_0 = View_DialogEmailBillingDocComponent_Host_0;
var DialogEmailBillingDocComponentNgFactory = i0.ɵccf("sync-dialog-email-billing-doc", i9.DialogEmailBillingDocComponent, View_DialogEmailBillingDocComponent_Host_0, { doc: "doc" }, {}, []);
exports.DialogEmailBillingDocComponentNgFactory = DialogEmailBillingDocComponentNgFactory;
