"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var api_service_1 = require("../core/api.service");
var base64_service_1 = require("../core/base64.service");
var sync_crypt_service_1 = require("../core/crypt/sync-crypt.service");
var logger_service_1 = require("../core/logger.service");
var user_service_1 = require("../core/user.service");
var broadcast_service_1 = require("../shared/services/broadcast.service");
var dialog_share_details_component_1 = require("./dialog-share-details/dialog-share-details.component");
var link_password_service_1 = require("../links/services/link-password.service");
var joblock_service_1 = require("../core/joblock.service");
var i0 = require("@angular/core");
var i1 = require("../core/api.service");
var i2 = require("../core/base64.service");
var i3 = require("../core/logger.service");
var i4 = require("../core/crypt/sync-crypt.service");
var i5 = require("../core/user.service");
var i6 = require("@ng-bootstrap/ng-bootstrap");
var i7 = require("../shared/services/broadcast.service");
var i8 = require("../links/services/link-password.service");
var i9 = require("../core/joblock.service");
var ShareService = /** @class */ (function () {
    function ShareService(apiService, base64Service, loggerService, syncCryptService, userService, modalService, broadcastService, linkPasswordService, jobLockService) {
        this.apiService = apiService;
        this.base64Service = base64Service;
        this.loggerService = loggerService;
        this.syncCryptService = syncCryptService;
        this.userService = userService;
        this.modalService = modalService;
        this.broadcastService = broadcastService;
        this.linkPasswordService = linkPasswordService;
        this.jobLockService = jobLockService;
    }
    /**
     * @ngdoc method
     * @name  stopShare
     * @methodOf sync.service:Share
     * @description
     * Stops a share.  Only the owner can initiate this request.  Refreshes
     * the list of shares on completion.
     * @param {Integer} shareId The share id
     * @param {Integer} sharememberId The sharemember's id
     * @param {Boolean} purge Whether to purge the share
     * @return {Promise} The promise chain from the API call
     */
    ShareService.prototype.stopShare = function (shareId, sharememberId, purge) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resp, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.apiService.execute('sharestop', {
                                sharemember_id: sharememberId,
                                share_id: shareId,
                                purge: purge,
                            }, false)];
                    case 1:
                        resp = _a.sent();
                        this.broadcastService.broadcast('event:share-list.reload');
                        return [2 /*return*/, resp];
                    case 2:
                        err_1 = _a.sent();
                        this.handleErr(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * @ngdoc method
     * @name  leaveShare
     * @methodOf sync.service:Share
     * @description
     * Leaves a share voluntarily.  Initiated by a user who is not the owner
     * but no longer wants to be part of the share folder.
     * @param {Integer} shareId The share id
     * @param {Integer} sharememberId The sharemember's id
     * @return {Promise} The promise chain from the API call
     */
    ShareService.prototype.leaveShare = function (shareId, sharememberId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var input, resp, bg, response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        input = {
                            share_id: shareId,
                            sharemember_id: sharememberId,
                            bg: 1,
                        };
                        return [4 /*yield*/, this.apiService.execute('sharememberleave', input)];
                    case 1:
                        resp = _a.sent();
                        bg = resp['bg'];
                        if (!bg) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.jobLockService.pollJobStatus()];
                    case 2:
                        response = _a.sent();
                        this.loggerService.info('Sharememberleave_JobComplete');
                        _a.label = 3;
                    case 3:
                        this.broadcastService.broadcast('event:share-list.reload');
                        return [2 /*return*/, true];
                    case 4:
                        err_2 = _a.sent();
                        this.handleErr(err_2);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * @ngdoc method
     * @name  getShare
     * @methodOf sync.service:Share
     * @description
     * Retrieves all data for a given share and set it on the view object
     * for binding to controllers/directives.
     * @param {Integer} shareId The share to retrieve
     * @return {Promise} The promise chain from the API call
     */
    ShareService.prototype.getShare = function (shareId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.execute('shareget', { share_id: shareId })];
                    case 1:
                        data = _a.sent();
                        return [4 /*yield*/, this.processShare(data)];
                    case 2:
                        res = _a.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    };
    /**
     * @ngdoc method
     * @name  setPermission
     * @methodOf sync.service:Share
     * @description
     * Sets the label for a share
     * @param {Integer} shareId The share id
     * @param {String} label     The label in plain text
     * @returns {Promise} The promise from the API call
     */
    ShareService.prototype.setLabel = function (shareId, label) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var labelB64, response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        labelB64 = this.base64Service.encode(label);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.apiService.execute('sharelabel', {
                                share_id: shareId,
                                label: labelB64,
                            }, true)];
                    case 2:
                        response = _a.sent();
                        this.broadcastService.broadcast('event:share-list.reload');
                        return [4 /*yield*/, this.getShare(shareId)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, response];
                    case 4:
                        err_3 = _a.sent();
                        this.handleErr(err_3);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * @ngdoc method
     * @name  setPermission
     * @methodOf sync.service:Share
     * @description
     * Sets the permission of a share member.  Whatever the permission is,
     * it is toggled, e.g., if it was true or set, it will be false after.
     *
     * This is a network call for instant change.
     * @param {String} perm The permission to toggle.  Defined in the
     *                      Permission class of the backend api.
     * @param {Object} member The share member to set the permission on.
     * @return {Promise} The promise chain from converting a folder to share
     */
    ShareService.prototype.setPermission = function (shareData, perm, member) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newVal, pass, newPass, response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newVal = !member.permissions[perm] ? 1 : 0;
                        this.loggerService.info("Changing share permission " + perm + " to new value " + newVal);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 9]);
                        if (!(['perINVITE', 'perOWNER'].includes(perm) && newVal == 1)) return [3 /*break*/, 6];
                        if (!(shareData.encPassword && member.pubkey)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.syncCryptService.linkpasswordDecrypt(shareData.encPassword)];
                    case 2:
                        pass = _a.sent();
                        return [4 /*yield*/, this.syncCryptService.linkpasswordEncrypt(pass, member.pubkey)];
                    case 3:
                        newPass = _a.sent();
                        return [4 /*yield*/, this.apiService.execute('grantinvitepermission', {
                                uniqid: member.sharememberId,
                                share_id: member.shareId,
                                enc_password: newPass
                            })];
                    case 4:
                        response = _a.sent();
                        if (perm === 'perINVITE') {
                            return [2 /*return*/, response];
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        this.loggerService.error('Cannot find encPassword or member pubkey');
                        this.loggerService.error(' - encPassword = ' + shareData.encPassword);
                        this.loggerService.error(' - member pubkey = ' + member.pubkey);
                        this.loggerService.error('Share ID: ' + shareData.shareId);
                        return [2 /*return*/];
                    case 6: return [4 /*yield*/, this.apiService.execute('sharememberpermission', {
                            uniqid: member.sharememberId,
                            share_id: member.shareId,
                            permission: perm,
                            value: newVal
                        })];
                    case 7: return [2 /*return*/, _a.sent()];
                    case 8:
                        e_1 = _a.sent();
                        return [2 /*return*/, this.handleErr(e_1)];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * @ngdoc method
     * @name  resendInvite
     * @methodOf sync.service:Share
     * @description
     * Re-sends a share invite email to the  sharemember.
     * @param {Integer} shareId The share id
     * @param {Integer} sharememberId The sharemember's id
     * @return {Promise} The promise chain from the API call
     */
    ShareService.prototype.resendInvite = function (shareData, email) {
        var _this = this;
        var inviteKeyP = shareData.encPassword
            ? this.syncCryptService.linkpasswordDecrypt(shareData.encPassword)
            : Promise.resolve('');
        return inviteKeyP.then(function (inviteKey) {
            return _this.apiService.execute('sharemailinvite', {
                share_id: shareData.shareId,
                emaillist: [email],
                enc_password: inviteKey
            }, false);
        });
    };
    /**
     * @ngdoc method
     * @name  processMembers
     * @methodOf sync.service:Share
     * @description
     * Prepares the sharemember list for display in the CP
     * @return {Array}
     */
    ShareService.prototype.processMembers = function (people) {
        var result = [];
        for (var i = 0, len = people.length; i < len; i++) {
            var p = people[i];
            result.push({
                sharememberId: p.uniqid,
                userId: p.userid,
                email: p.email,
                user_status_id: p.user_status_id,
                displayname: this.base64Service.decode(p.displayname),
                status: p.status,
                pubkey: p.pubkey,
                pubkeyId: p.pubkey_id,
                shareId: p.share_id,
                permissions: p.permissions,
                isMe: p.userid == this.userService.get('id'),
                selected: false,
                num_apps: p.num_apps,
            });
        }
        return result;
    };
    ShareService.prototype.openDetails = function (shareId, openState) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var state, ref, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        state = openState ? openState : 1;
                        ref = this.modalService.open(dialog_share_details_component_1.DialogShareDetailsComponent, {
                            backdropClass: 'in',
                            windowClass: 'in',
                            backdrop: 'static',
                        });
                        ref.componentInstance.shareService = this;
                        _a = ref.componentInstance;
                        return [4 /*yield*/, this.getShare(shareId)];
                    case 1:
                        _a.shareData = _b.sent();
                        ref.componentInstance.openState = state;
                        ref.result.then(function () { });
                        return [2 /*return*/];
                }
            });
        });
    };
    ShareService.prototype.removeAppShare = function (syncId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.execute('shareintegrationremove', {
                            sync_id: syncId
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ShareService.prototype.processShare = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var share, linkPass, shareData, ret, name_1, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        share = data.share;
                        if (!(share && share.is_owner && share.enc_password == 'NULL' && share.share_id)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.linkPasswordService.makeLinkPassword(24)];
                    case 1:
                        linkPass = _a.sent();
                        return [4 /*yield*/, this.syncCryptService.mkShareData(linkPass, this.userService.get('pubkey'))];
                    case 2:
                        shareData = _a.sent();
                        share.enc_password = shareData.enc_password;
                        return [4 /*yield*/, this.apiService.execute('grantinvitepermission', {
                                uniqid: share.uniqid,
                                share_id: share.share_id,
                                enc_password: share.enc_password
                            })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        ret = {
                            people: this.processMembers(share.people),
                            peoplex: this.processMembers(share.peoplex),
                            syncId: share.sync_id,
                            encName: share.enc_name,
                            label: this.base64Service.decode(share.label),
                            name: '',
                            inviteStatus: share.invite_status,
                            isReadOnly: share.is_readonly,
                            isOwner: share.is_owner,
                            isSeeOthers: share.is_seeothers,
                            isInvite: share.is_invite,
                            isAddApp: share.is_addapp,
                            shareId: share.share_id,
                            shareSequence: share.share_sequence,
                            sharememberId: share.uniqid,
                            encPassword: share.enc_password,
                            salt: share.salt,
                            iterations: share.iterations,
                            app_integrated: share.app_integrated,
                        };
                        _a.label = 5;
                    case 5:
                        _a.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, this.syncCryptService.filenameDecrypt(share.enc_name)];
                    case 6:
                        name_1 = _a.sent();
                        ret.name = name_1;
                        return [2 /*return*/, ret];
                    case 7:
                        error_1 = _a.sent();
                        this.loggerService.error('Unable to decrypt the file name');
                        this.loggerService.error(JSON.stringify(share));
                        throw { code: 2023 };
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ShareService.prototype.handleErr = function (err) {
        var code;
        if (err.error_code) {
            code = err.error_code;
        }
        else if (err.code) {
            code = err.code;
        }
        else {
            code = 9000;
        }
        return Promise.reject({ code: code });
    };
    ShareService.ngInjectableDef = i0.defineInjectable({ factory: function ShareService_Factory() { return new ShareService(i0.inject(i1.ApiService), i0.inject(i2.Base64Service), i0.inject(i3.LoggerService), i0.inject(i4.SyncCryptService), i0.inject(i5.UserService), i0.inject(i6.NgbModal), i0.inject(i7.BroadcastService), i0.inject(i8.LinkPasswordService), i0.inject(i9.JoblockService)); }, token: ShareService, providedIn: "root" });
    return ShareService;
}());
exports.ShareService = ShareService;
