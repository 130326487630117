"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var OrderByPipe = /** @class */ (function () {
    function OrderByPipe() {
    }
    OrderByPipe.prototype.transform = function (array, sortBy, reverse) {
        var sortOrder = reverse ? 'des' : 'asc'; // setting default ascending order
        if (array && Array.isArray(array)) {
            if (sortOrder == 'asc') {
                return array.sort(function (a, b) { return a[sortBy] < b[sortBy] ? -1 : 1; });
            }
            else {
                return array.sort(function (a, b) { return a[sortBy] < b[sortBy] ? 1 : -1; });
            }
        }
        else {
            return array;
        }
    };
    return OrderByPipe;
}());
exports.OrderByPipe = OrderByPipe;
