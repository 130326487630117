"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var logger_service_1 = require("../../core/logger.service");
var share_service_1 = require("../../shares/share.service");
var InlineShareInfoComponent = /** @class */ (function () {
    function InlineShareInfoComponent(loggerService, shareService) {
        this.loggerService = loggerService;
        this.shareService = shareService;
        this.initialized = false;
    }
    InlineShareInfoComponent.prototype.ngOnInit = function () {
        this.initialized = false;
        this.getData(this.shareId);
    };
    InlineShareInfoComponent.prototype.getData = function (shareId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var share, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.shareService.getShare(shareId)];
                    case 1:
                        share = _a.sent();
                        this.share = share;
                        this.initialized = true;
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.loggerService.error('Error retrieving share inline for ' + this.shareId);
                        this.loggerService.error(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    InlineShareInfoComponent.prototype.openDetails = function () {
        this.shareService.openDetails(this.shareId, 0);
    };
    return InlineShareInfoComponent;
}());
exports.InlineShareInfoComponent = InlineShareInfoComponent;
