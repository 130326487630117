"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var environment_1 = require("../../../../environments/environment");
var logger_service_1 = require("../../../core/logger.service");
var avatar_user_model_1 = require("../../models/avatar-user.model");
var AvatarComponent = /** @class */ (function () {
    function AvatarComponent(log) {
        this.log = log;
        this.avatarEndpoint = environment_1.environment.logohost + "avatar/saved/";
    }
    AvatarComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.user) {
                    this.initial = this.user.decodedDisplayName[0];
                    if (this.user.userid) {
                        this.avatarUrl = "" + this.avatarEndpoint + this.user.userid;
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    AvatarComponent.prototype.handleMissingImage = function () {
        this.avatarUrl = '';
    };
    return AvatarComponent;
}());
exports.AvatarComponent = AvatarComponent;
