"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var RequestPassResetApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(RequestPassResetApiOutput, _super);
    function RequestPassResetApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RequestPassResetApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.RequestPassResetApiOutput = RequestPassResetApiOutput;
var RequestPassResetApiInput = /** @class */ (function (_super) {
    tslib_1.__extends(RequestPassResetApiInput, _super);
    function RequestPassResetApiInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RequestPassResetApiInput;
}(base_api_model_1.BaseApiInput));
exports.RequestPassResetApiInput = RequestPassResetApiInput;
