"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var i0 = require("@angular/core");
var BroadcastService = /** @class */ (function () {
    function BroadcastService() {
        this.eventsDispatcher = new rxjs_1.Subject();
    }
    BroadcastService.prototype.broadcast = function (type, payload) {
        this.eventsDispatcher.next({ type: type, payload: payload });
    };
    BroadcastService.prototype.on = function (type) {
        return this.eventsDispatcher.asObservable().pipe(operators_1.filter(function (eventDetails) { return eventDetails.type === type; }), operators_1.map(function (eventDetails) { return eventDetails.payload; }));
    };
    BroadcastService.ngInjectableDef = i0.defineInjectable({ factory: function BroadcastService_Factory() { return new BroadcastService(); }, token: BroadcastService, providedIn: "root" });
    return BroadcastService;
}());
exports.BroadcastService = BroadcastService;
