"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var transfer_service_1 = require("../../transfer/transfer.service");
var logger_service_1 = require("../../core/logger.service");
var api_service_1 = require("../../core/api.service");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var platform_browser_1 = require("@angular/platform-browser");
var build_transfer_item_service_1 = require("../../transfer/services/build-transfer-item.service");
var i0 = require("@angular/core");
var i1 = require("../../core/api.service");
var i2 = require("../../core/crypt/sync-crypt.service");
var i3 = require("../../core/logger.service");
var i4 = require("@angular/platform-browser");
var i5 = require("../../transfer/transfer.service");
var i6 = require("../../transfer/services/build-transfer-item.service");
var ThumbnailsService = /** @class */ (function () {
    function ThumbnailsService(api, crypt, injector, log, sanitizer, transferService, buildTransferItemService) {
        this.api = api;
        this.crypt = crypt;
        this.injector = injector;
        this.log = log;
        this.sanitizer = sanitizer;
        this.transferService = transferService;
        this.buildTransferItemService = buildTransferItemService;
    }
    ThumbnailsService.prototype.getThumbs = function (displayed, paginate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var start, list, items, keyData, response, byteLen, manifestStart, manifest, thumbs, m;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        start = Date.now();
                        list = this.getThumbList(displayed, paginate);
                        this.log.d((Date.now() - start) + ' ms to getThumbList');
                        // if this list is empty, there's no thumbs to get, return
                        if (!list || !list.length) {
                            this.log.d('No items to get');
                            return [2 /*return*/];
                        }
                        this.isRunning = true;
                        items = list.map(function (curFile) {
                            return {
                                cachekey: curFile.cachekey,
                                blobtype: 'btTHUMB1',
                                maxsize: 262144
                            };
                        });
                        return [4 /*yield*/, this.buildTransferItemService.getFileKeys(list)];
                    case 1:
                        keyData = _a.sent();
                        return [4 /*yield*/, this.api.fetchThumbs(items)];
                    case 2:
                        response = _a.sent();
                        byteLen = response.byteLength, manifestStart = response.byteLength - 4096, manifest = response.slice(manifestStart), thumbs = response.slice(0, manifestStart), m = JSON.parse(this.crypt.bytesToString(new Uint8Array(manifest)));
                        this.processThumb(list, m, thumbs, displayed, keyData);
                        return [2 /*return*/];
                }
            });
        });
    };
    ThumbnailsService.prototype.processThumb = function (list, manifest, thumbs, displayed, keyData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var curItem, man, start, end, thumb, startTime, tItem, j, jlen;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        curItem = list.shift();
                        man = manifest.shift(), start = man[0], end = start + man[1], thumb = thumbs.slice(start, end);
                        if (!curItem || !man || end === 0) {
                            return [2 /*return*/];
                        }
                        startTime = Date.now();
                        return [4 /*yield*/, this.transferService.getThumb(curItem, keyData, thumb)];
                    case 1:
                        tItem = _a.sent();
                        this.log.d((Date.now() - startTime) + ' ms to ');
                        for (j = 0, jlen = displayed.length; j < jlen; j++) {
                            if (tItem.cachekey == displayed[j].cachekey && tItem.status == 1) {
                                // ignore this for now, it's non fatal - required due to angular requiring a trusted url
                                // and angular 1 whitelisting it via thumbsrc
                                displayed[j].imgsrc = this.sanitizer.bypassSecurityTrustUrl(tItem.renderFile.url);
                                displayed[j].thumbsrc = tItem.renderFile.url;
                                if (displayed[j].thumbData) {
                                    displayed[j].thumbData.processed = true;
                                }
                            }
                        }
                        if (list.length) {
                            man = [];
                            start = 0;
                            end = 0;
                            thumb = null;
                            this.processThumb(list, manifest, thumbs, displayed, keyData);
                        }
                        else {
                            this.log.info('Thumbnails finished ' + (Date.now() - startTime) + ' ms');
                            this.isRunning = false;
                            return [2 /*return*/];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ThumbnailsService.prototype.getThumbList = function (list, paginate) {
        var thumbs = [];
        var start = (paginate.currentPage - 1) * paginate.itemsPerPage;
        var end = (start + paginate.itemsPerPage > paginate.total)
            ? paginate.total
            : start + paginate.itemsPerPage;
        for (var i = end - 1; i >= start; i--) {
            var cur = list[i];
            if (!cur || (cur.thumbData && cur.thumbData.processed == true)) {
                continue;
            }
            if (cur.size > 0 && cur.has_thumb1 == 1) {
                cur.thumbData = {
                    processed: false,
                    thumbType: 1,
                    cachekey: cur.cachekey,
                    blobtype: 'btTHUMB1'
                };
                thumbs.push(cur);
            }
            else if (cur.kind == 'image' && cur.size > 0 &&
                cur.size < 1024 * 100 && cur.type == 'file' &&
                !cur.has_thumb1 && (cur.filetype && cur.filetype.viewable)) {
                cur.thumbData = {
                    processed: false,
                    thumbType: 1,
                    cachekey: cur.cachekey,
                    blobtype: 'btFILE'
                };
                thumbs.push(cur);
            }
        }
        return thumbs;
    };
    ThumbnailsService.ngInjectableDef = i0.defineInjectable({ factory: function ThumbnailsService_Factory() { return new ThumbnailsService(i0.inject(i1.ApiService), i0.inject(i2.SyncCryptService), i0.inject(i0.INJECTOR), i0.inject(i3.LoggerService), i0.inject(i4.DomSanitizer), i0.inject(i5.TransferService), i0.inject(i6.BuildTransferItemService)); }, token: ThumbnailsService, providedIn: "root" });
    return ThumbnailsService;
}());
exports.ThumbnailsService = ThumbnailsService;
