"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// import { Sort } from '@angular/material';
exports.FILE_LIST_RESET = '[FileList] Reset';
exports.FILE_LIST_REFRESH = '[FileList] Refresh';
exports.FILE_LIST_RELOAD = '[FileList] Reload';
exports.FILE_LIST_ERROR = '[FileList] Error';
exports.FILE_LIST_SUCCESS = '[FileList] Success';
exports.FILE_LIST_SORT = '[FileList] Sort';
exports.FILE_LIST_SELECT = '[FileList] Select';
exports.FILE_LIST_SELECT_ALL = '[FileList] Select all';
exports.FILE_LIST_SELECT_TOGGLE = '[FileList] Select toggle';
exports.FILE_LIST_SELECT_TYPE = '[FileList] Select by type';
exports.FILE_LIST_SET_CWD = '[FileList] Set cwd';
exports.FILE_LIST_SET_PARENTS = '[FileList] Set parents';
exports.FILE_LIST_SET_HIST_ID = '[FileList] Set hist_id';
exports.FILE_LIST_SET_PROGRESS = '[FileList] Set progress';
exports.FILE_LIST_SHOW_DELETED = '[FileList] Show deleted';
exports.FILE_LIST_SHOW_FAVORITES = '[FileList] Show favorites';
exports.FILE_LIST_TOTAL_FAVORITE = '[FileList] Total favorite';
var FileListResetAction = /** @class */ (function () {
    function FileListResetAction() {
        this.type = exports.FILE_LIST_RESET;
    }
    return FileListResetAction;
}());
exports.FileListResetAction = FileListResetAction;
var FileListSuccessAction = /** @class */ (function () {
    function FileListSuccessAction(payload) {
        this.payload = payload;
        this.type = exports.FILE_LIST_SUCCESS;
    }
    return FileListSuccessAction;
}());
exports.FileListSuccessAction = FileListSuccessAction;
var FileListSetCwdAction = /** @class */ (function () {
    function FileListSetCwdAction(payload) {
        this.payload = payload;
        this.type = exports.FILE_LIST_SET_CWD;
    }
    return FileListSetCwdAction;
}());
exports.FileListSetCwdAction = FileListSetCwdAction;
var FileListSetParentsAction = /** @class */ (function () {
    function FileListSetParentsAction(payload) {
        this.payload = payload;
        this.type = exports.FILE_LIST_SET_PARENTS;
    }
    return FileListSetParentsAction;
}());
exports.FileListSetParentsAction = FileListSetParentsAction;
var FileListSetProgressAction = /** @class */ (function () {
    function FileListSetProgressAction(payload) {
        this.payload = payload;
        this.type = exports.FILE_LIST_SET_PROGRESS;
    }
    return FileListSetProgressAction;
}());
exports.FileListSetProgressAction = FileListSetProgressAction;
var FileListSetHistIdAction = /** @class */ (function () {
    function FileListSetHistIdAction(payload) {
        this.payload = payload;
        this.type = exports.FILE_LIST_SET_HIST_ID;
    }
    return FileListSetHistIdAction;
}());
exports.FileListSetHistIdAction = FileListSetHistIdAction;
var FileListShowDeletedAction = /** @class */ (function () {
    function FileListShowDeletedAction() {
        this.type = exports.FILE_LIST_SHOW_DELETED;
    }
    return FileListShowDeletedAction;
}());
exports.FileListShowDeletedAction = FileListShowDeletedAction;
var FileListShowFavoritesAction = /** @class */ (function () {
    function FileListShowFavoritesAction(payload) {
        this.payload = payload;
        this.type = exports.FILE_LIST_SHOW_FAVORITES;
    }
    return FileListShowFavoritesAction;
}());
exports.FileListShowFavoritesAction = FileListShowFavoritesAction;
var FileListRefreshAction = /** @class */ (function () {
    function FileListRefreshAction(payload) {
        this.payload = payload;
        this.type = exports.FILE_LIST_REFRESH;
    }
    return FileListRefreshAction;
}());
exports.FileListRefreshAction = FileListRefreshAction;
var FileListReloadAction = /** @class */ (function () {
    function FileListReloadAction() {
        this.type = exports.FILE_LIST_RELOAD;
    }
    return FileListReloadAction;
}());
exports.FileListReloadAction = FileListReloadAction;
var FileListErrorAction = /** @class */ (function () {
    function FileListErrorAction(payload) {
        this.payload = payload;
        this.type = exports.FILE_LIST_ERROR;
    }
    return FileListErrorAction;
}());
exports.FileListErrorAction = FileListErrorAction;
var FileListSortAction = /** @class */ (function () {
    function FileListSortAction(payload) {
        this.payload = payload;
        this.type = exports.FILE_LIST_SORT;
    }
    return FileListSortAction;
}());
exports.FileListSortAction = FileListSortAction;
var FileListSelectAction = /** @class */ (function () {
    function FileListSelectAction(payload) {
        this.payload = payload;
        this.type = exports.FILE_LIST_SELECT;
    }
    return FileListSelectAction;
}());
exports.FileListSelectAction = FileListSelectAction;
var FileListSelectAllAction = /** @class */ (function () {
    function FileListSelectAllAction(payload) {
        this.payload = payload;
        this.type = exports.FILE_LIST_SELECT_ALL;
    }
    return FileListSelectAllAction;
}());
exports.FileListSelectAllAction = FileListSelectAllAction;
var FileListSelectToggleAction = /** @class */ (function () {
    function FileListSelectToggleAction(payload) {
        this.payload = payload;
        this.type = exports.FILE_LIST_SELECT_TOGGLE;
    }
    return FileListSelectToggleAction;
}());
exports.FileListSelectToggleAction = FileListSelectToggleAction;
var FileListSelectTypeAction = /** @class */ (function () {
    function FileListSelectTypeAction(payload) {
        this.payload = payload;
        this.type = exports.FILE_LIST_SELECT_TYPE;
    }
    return FileListSelectTypeAction;
}());
exports.FileListSelectTypeAction = FileListSelectTypeAction;
var FileListTotalFavoriteAction = /** @class */ (function () {
    function FileListTotalFavoriteAction(payload) {
        this.payload = payload;
        this.type = exports.FILE_LIST_TOTAL_FAVORITE;
    }
    return FileListTotalFavoriteAction;
}());
exports.FileListTotalFavoriteAction = FileListTotalFavoriteAction;
