"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var forms_1 = require("@angular/forms");
var user_service_1 = require("../../core/user.service");
var base64_service_1 = require("../../core/base64.service");
var logger_service_1 = require("../../core/logger.service");
var api_service_1 = require("../../core/api.service");
var validate_service_1 = require("../../core/validate.service");
var notifications_service_1 = require("../../core/notifications.service");
var LinkManageComponent = /** @class */ (function () {
    function LinkManageComponent(api, base64, fb, log, userService, validateService, notificationsService) {
        this.api = api;
        this.base64 = base64;
        this.fb = fb;
        this.log = log;
        this.userService = userService;
        this.validateService = validateService;
        this.notificationsService = notificationsService;
        this.state = new core_1.EventEmitter();
        this.close = new core_1.EventEmitter();
        this.isPro = 0;
        this.linkSendResult = 0;
        this.isOnTrial = 0;
        this.isEnced = true;
        this.spinner = false;
        this.copyText = 'Copy link';
    }
    LinkManageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isPro = this.userService.get('is_pro');
        this.isOnTrial = this.userService.get('is_on_trial');
        this.shareForm = this.fb.group({
            email: ['', [forms_1.Validators.email, forms_1.Validators.required]],
            message: [''],
            displayname: [this.base64.decode(this.userService.get('display_name')), forms_1.Validators.required]
        });
        this.isEnced = this.link.cnt > 0 ? false : true;
        if (!this.isEnced) {
            this.subscription = this.notificationsService.getNotificationStatus().subscribe(function (isTaskRunning) {
                if (!isTaskRunning && !_this.isEnced) {
                    _this.isEnced = true;
                    _this.subscription.unsubscribe();
                }
            });
        }
    };
    LinkManageComponent.prototype.copy = function (elemId) {
        var _this = this;
        var copyInput = document.getElementById(elemId);
        if (!copyInput) {
            this.log.warn('Could not find #' + elemId + ' ignoring');
            return;
        }
        else {
            this.copyText = 'Copied';
            copyInput.select();
            document.execCommand('copy');
            this.log.d('copied link url');
            window.setTimeout(function () {
                _this.copyText = 'Copy link';
            }, 2000);
        }
    };
    LinkManageComponent.prototype.setState = function (state) {
        this.state.emit(state);
    };
    LinkManageComponent.prototype.closeDialog = function () {
        this.close.emit(false);
    };
    LinkManageComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var email, displayName, label, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        email = this.shareForm.get('email').value;
                        displayName = this.shareForm.get('displayname').value;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.linkSendResult = 0;
                        this.spinner = true;
                        if (!this.validateService.isDisplayNameValid(displayName)) {
                            throw { code: 1659 };
                        }
                        this.errcode = null;
                        label = this.link.label || this.base64.encode('(No name provided)');
                        return [4 /*yield*/, this.api.execute('linksendurl', {
                                publink_id: this.link.cachekey,
                                sync_id: this.link.sync_id,
                                displayname: this.shareForm.get('displayname').value,
                                linkname: label,
                                linkurl: this.base64.encode(this.link.linkUrl),
                                emailaddress: this.base64.encode(email),
                                message: (this.userService.get('is_pro'))
                                    ? this.base64.encode(this.shareForm.get('message').value)
                                    : ''
                            })];
                    case 2:
                        _a.sent();
                        this.linkSendResult = 1;
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _a.sent();
                        this.spinner = false;
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        this.log.e('Error sending email to ' + email, ex_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.spinner = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    LinkManageComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return LinkManageComponent;
}());
exports.LinkManageComponent = LinkManageComponent;
