"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var logger_service_1 = require("../../core/logger.service");
var models_1 = require("../../shared/models");
var http_1 = require("@angular/common/http");
var api_service_1 = require("../../core/api.service");
var environment_1 = require("../../../environments/environment");
var url_service_1 = require("../../core/url.service");
var i0 = require("@angular/core");
var i1 = require("../../core/api.service");
var i2 = require("../../core/crypt/sync-crypt.service");
var i3 = require("../../core/logger.service");
var i4 = require("../../core/url.service");
var DownloadWorkerService = /** @class */ (function () {
    function DownloadWorkerService(api, crypt, log, url) {
        this.api = api;
        this.crypt = crypt;
        this.log = log;
        this.url = url;
    }
    DownloadWorkerService.prototype.abort = function () { };
    DownloadWorkerService.prototype.getChunk = function (tItem, offset, reqLength, retry) {
        if (retry === void 0) { retry = 0; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.WorkerObj = new Worker(environment_1.environment.jslibpath + 'filedecrypt-worker.js?d=' + Date.now());
                        _this.WorkerObj.addEventListener('message', function (ev) {
                            var agentCmd = ev.data.agentcmd, cmdData = ev.data.data;
                            switch (agentCmd) {
                                case 'error':
                                    _this.log.error("Received error " + agentCmd + " with data: " + JSON.stringify(cmdData));
                                    var errcode = parseInt(ev.data.errcode, 10) || 1000;
                                    _this.log.error(errcode + ' error code download-chunk-worker');
                                    reject(new models_1.ErrCode(errcode));
                                    break;
                                case 'log':
                                    _this.log.info(cmdData);
                                    break;
                                case 'runFinished':
                                    // reject(new ErrCode(1000, 'blah'));
                                    resolve(cmdData);
                                    break;
                                default:
                                    _this.log.error(agentCmd + " does not exist");
                                    reject(new models_1.ErrCode(8221));
                                    break;
                            }
                        }, false);
                        _this.api.fetchFile(tItem, offset, reqLength, retry)
                            .subscribe(function (resp) {
                            if (resp.type === http_1.HttpEventType.DownloadProgress) {
                                // console.log('download preogress');
                                var bytesSent = resp.loaded + offset;
                                var gcmTags = Math.ceil(bytesSent / 131072) * 36;
                                tItem.bytes_sent = bytesSent - gcmTags;
                                var percent = Math.round(tItem.bytes_sent / tItem.filesize * 100 * 100) / 100;
                                if (percent > 100) {
                                    percent = 100;
                                }
                                tItem.progress_percent = percent;
                                // console.log('Download progress: ' + percent);
                            }
                            else if (resp.type === http_1.HttpEventType.Response) {
                                // console.log('HTTP RESPONSE EVENT');
                                _this.WorkerObj.postMessage({
                                    workercmd: 'run',
                                    data: {
                                        response: resp.body,
                                        tItem: tItem,
                                        jsliburl: environment_1.environment.jslibpath,
                                        datakey: _this.crypt.bytesToB64(tItem.data_key),
                                        offset: offset,
                                        reqLength: reqLength,
                                    }
                                });
                            }
                        }, function (err) {
                            // reject(err);
                            _this.log.e('Error subscribing to fetch file in download-worker', err);
                            if (_this.url.downloadhosts[retry + 1]) {
                                _this.log.warn("Retrying download on host " + _this.url.downloadhosts[retry + 1]);
                                _this.WorkerObj.terminate();
                                _this.WorkerObj = null;
                                resolve(_this.getChunk(tItem, offset, reqLength, retry + 1));
                            }
                            else {
                                _this.log.error("Attempted " + retry + " retries and failed, rejecting file");
                                reject(err);
                            }
                        });
                    })];
            });
        });
    };
    DownloadWorkerService.prototype.completed = function () {
        this.log.info('calling terminate on workerobj');
        this.WorkerObj.terminate();
        this.WorkerObj = null;
        // this.dfd = null;
    };
    DownloadWorkerService.ngInjectableDef = i0.defineInjectable({ factory: function DownloadWorkerService_Factory() { return new DownloadWorkerService(i0.inject(i1.ApiService), i0.inject(i2.SyncCryptService), i0.inject(i3.LoggerService), i0.inject(i4.UrlService)); }, token: DownloadWorkerService, providedIn: "root" });
    return DownloadWorkerService;
}());
exports.DownloadWorkerService = DownloadWorkerService;
