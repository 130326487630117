"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var UserInfoSetApiInput = /** @class */ (function (_super) {
    tslib_1.__extends(UserInfoSetApiInput, _super);
    function UserInfoSetApiInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.billing = undefined;
        _this.passreset = undefined;
        _this.sessionttl = undefined;
        _this.display_name_b64 = undefined;
        _this.is_emailmarketing = undefined;
        _this.is_emailproduct = undefined;
        _this.is_emailrewards = undefined;
        _this.is_disableautorenew = undefined;
        _this.rm_billingprofile = undefined;
        _this.is_office = undefined;
        _this.is_displayedofficeoptin = undefined;
        _this.enc_password = undefined;
        return _this;
    }
    return UserInfoSetApiInput;
}(base_api_model_1.BaseApiOutput));
exports.UserInfoSetApiInput = UserInfoSetApiInput;
var UserInfoSetApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(UserInfoSetApiOutput, _super);
    function UserInfoSetApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserInfoSetApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.UserInfoSetApiOutput = UserInfoSetApiOutput;
