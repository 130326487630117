export enum BlendEvent {
    IDENTIFY = '$identify',
    BUTTON_CLICKED = 'button_clicked',
    DELETE_ACCOUNT = 'Delete Account',
    CONTACT_SUPPORT = 'Contact Support',
    HELP_EVENT = 'Help Event',
    GET_HELP_EVENT = 'Get Help Event',
    CANCEL_HELP = 'Cancel Help',
    ADD_FAVORITE_TAG = 'Add Favorite Tag',
    REMOVE_FAVORITE_TAG = 'Remove Favorite Tag',
    VIEW_FAVORITE_LIST = 'View Favorite List',
    SYNC_KNOWLEDGEBASE = 'Sync Knowledgebase',
    UPLOAD_FILE = 'Upload File',
    CREATE_FILE = 'Create File',
    DOWNLOAD_FILE = 'Download File',
    DOWNLOAD_AS_ZIP = 'Download As Zip',
    VIEW_FILE = 'View File',
    EDIT_FILE_OFFICE = 'Edit File Office',
    SHARE_LINK = 'Share Link',
    SHARED_FOLDER_CREATED = 'shared_folder_created',
    SAVE_LINK_SETTINGS = 'save_link_settings',
    UPGRADE_INQUIRY = 'Upgrade Inquiry',
    ANALYTICS_OPT_IN = 'Opt In',
    ANALYTICS_OPT_OUT = 'Opt Out',
    NEW_UI_OPT_IN = 'New UI Opt In',
    NEW_UI_OPT_OUT = 'New UI Opt Out',
    SIGN_UP = 'SignUp',
    SUB_USER_ADDED = 'sub_user_added',
    UPLOAD = 'upload',
    DELETE_ITEM = 'delete_item',
    MOVE_ITEMS = 'move_items',
    CHANGE_ACCOUNT_SETTING = 'change_account_setting'
}

export enum BlendProperties {
    LINK_TYPE_FOLDER = 'folder',
    LINK_PERMISSION_VIEW = 'view only',
    LINK_PERMISSION_DOWNLOAD = 'view & download',
    LINK_PERMISSION_EDITABLE = 'view, downlaod & edit',
    LINK_COMMENTS_DISABLED = 'disabled',
    LINK_COMMENTS_ENABLED_AUTH = 'enabled for authenticated users',
    LINK_COMMENTS_ENABLED_ALL = 'enabled for all users'
}

export enum PermissionActions {
    CREATED_AT = 'created_at',
    MANAGE_USERS = 'manageUsers',
    MANAGE_ROLES = 'manageRoles',
    VIEW_USERS = 'viewUsers',
    ADD_EDIT_USERS = 'addEditUsers',
    VIEW_ROLES = 'viewRoles',
    ADD_EDIT_ROLES = 'addEditRoles',
    VIEW_BILLING = 'viewBilling',
    MANAGE_BILLING = 'manageBilling',
    PURGE_FILES = 'purgeFiles',
    FORCE_2FA_ON_FIRST_LOGIN = 'force2faOnFirstLogin',
    SHARE_LINKS = 'sharelinks',
    SHARE_FOLDERS = 'shareFolders',
}

export enum PlanType {
    bpt = 'business-teams',
    per = 'personal',
    lper = 'personal-legacy',
    ps = 'business-solo',
    psp = 'pro-solo-pro',
    bptu = 'business-teams-advanced',
    bptsp = 'business-teams-plus',
    bptup = 'business-teams-advanced-plus',
    free = 'free',
}

export enum WebpathActionType {
    COPY = 'Copy',
    MOVE = 'Move',
}

export enum FieldTypes {
    TEXTBOX = 'textbox',
    DROPDOWN = 'dropdown'
}

export enum FieldIdentities {
    ROLENAME = 'roleName',
    CANPURGEFILES = 'canPurgeFiles',
    CANMANAGEUSERS = 'canManageUsers',
    CANMANAGEROLES = 'canManageRoles',
    CANVIEWUSERS = 'canViewUsers',
    CANADDEDITUSERS = 'canAddEditUsers',
    CANVIEWROLES = 'canViewRoles',
    CANADDEDITROLES = 'canAddEitRoles',
    CANVIEWBILLING = 'canViewBilling',
    CANMANAGEBILLING = 'canManageBilling',
    CANFORCE2FALOGIN = 'canForce2faLogin',
    CANSHARELINKS = 'CanSharelinks',
    CANSHAREFOLDERS = 'canShareFolders',
}

export enum ModalCloseStates {
    USER_ROLE_UPDATED = 'userRoleUpdated',
    REMOVED = 'removed',
    PASSWORD_UPDATED = 'passwordUpdated',
}

export enum PermissionObjects {
    METADATA = '_metadata',
    ADMIN = 'admin',
    BILLING = 'billing',
    FILES = 'files',
    SETTINGS = 'settings',
    SHARE = 'share',
    FOLDERS = 'folders',
}
