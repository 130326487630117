import { Optional, SkipSelf, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { reducers, metaReducers  } from '../reducers';

import { ApiService } from './api.service';
import { BrowserSupportService } from './browser-support.service';
import { Base64Service } from './base64.service';
import { DispatchService } from './dispatch.service';
import { LoggerService } from './logger.service';
import { SyncCookieService } from './sync-cookie.service';
import { UserService } from './user.service';

import { JoblockService } from './joblock.service';

import { SyncCryptService } from './crypt/sync-crypt.service';
import { SyncDigestService } from './crypt/sync-digest.service';
import { CryptBufferService } from './crypt/crypt-buffer.service';
import { CryptLegacyService } from './crypt/crypt-legacy.service';
import { DirtyOutService } from './crypt/dirty-out.service';
import { DirtyInService } from './crypt/dirty-in.service';
import { UrlService } from './url.service';
import { ValidateService } from './validate.service';


import { StorageService } from './storage/storage.service';
import { StorageBrowserService } from './storage/storage-browser.service';
import { StorageFilesystemService } from './storage/storage-filesystem.service';
import { StorageMemoryService } from './storage/storage-memory.service';

import { CompatService } from './compat.service';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './interceptors/api-timeout.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forRoot(reducers, { metaReducers })
    ],
    declarations: [],
    providers: [
        ApiService,
        LoggerService,
        UserService,
        JoblockService,

        Base64Service,
        BrowserSupportService,
        DispatchService,
        LoggerService,
        SyncCookieService,

        SyncCryptService,
        SyncDigestService,
        CryptBufferService,
        CryptLegacyService,
        DirtyOutService,
        DirtyInService,


        StorageService,
        StorageBrowserService,
        StorageFilesystemService,
        StorageMemoryService,

        UrlService,
        ValidateService,

        CompatService,
        { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
        { provide: DEFAULT_TIMEOUT, useValue: 120000 }
    ]
})
export class CoreModule {}


