"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../directives/stop-propagation.directive");
var i2 = require("@angular/common");
var i3 = require("./modal.component");
var i4 = require("@ng-bootstrap/ng-bootstrap");
var styles_ModalComponent = [];
var RenderType_ModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalComponent, data: {} });
exports.RenderType_ModalComponent = RenderType_ModalComponent;
function View_ModalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], null, null); }
function View_ModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["syncStopPropagation", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).click($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.StopPropagationDirective, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(10, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), i0.ɵncd(null, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalComponent_2)), i0.ɵdid(13, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showCancelButton; _ck(_v, 13, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 7, 0, currVal_0); }); }
function View_ModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { modalContent: 0 }), (_l()(), i0.ɵand(0, [[1, 2], ["modal", 2]], null, 0, null, View_ModalComponent_1))], null, null); }
exports.View_ModalComponent_0 = View_ModalComponent_0;
function View_ModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i0.ɵdid(1, 573440, null, 0, i3.ModalComponent, [i4.NgbModal], null, null)], null, null); }
exports.View_ModalComponent_Host_0 = View_ModalComponent_Host_0;
var ModalComponentNgFactory = i0.ɵccf("sync-modal", i3.ModalComponent, View_ModalComponent_Host_0, { title: "title", open: "open", showCancelButton: "showCancelButton", onClose: "onClose" }, {}, ["*", "[buttons]"]);
exports.ModalComponentNgFactory = ModalComponentNgFactory;
