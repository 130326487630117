"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var api_service_1 = require("../../core/api.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var models_1 = require("../../shared/models");
var logger_service_1 = require("../../core/logger.service");
var zuora_account_service_1 = require("../services/zuora-account.service");
var DialogBillingProfileDeleteComponent = /** @class */ (function () {
    function DialogBillingProfileDeleteComponent(activeModal, api, log, zuora) {
        this.activeModal = activeModal;
        this.api = api;
        this.log = log;
        this.zuora = zuora;
    }
    DialogBillingProfileDeleteComponent.prototype.ngOnInit = function () {
        this.loadBillingProfile();
    };
    DialogBillingProfileDeleteComponent.prototype.loadBillingProfile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var zprofile, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.zuora.getProfile()];
                    case 2:
                        zprofile = _a.sent();
                        if (zprofile.subscription) {
                            this.planExpiry = new Date(zprofile.subscription.termEndDate).getTime();
                            this.loading = false;
                        }
                        else {
                            this.log.warn('This user is in zuora but does not have a valid subscription');
                            this.loading = false;
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        this.log.error("Error loading the billing profile " + JSON.stringify(ex_1));
                        this.loading = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DialogBillingProfileDeleteComponent.prototype.removeProfile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.execute('userinfoset', {
                                rm_billingprofile: 1
                            })];
                    case 2:
                        _a.sent();
                        this.spinner = false;
                        this.activeModal.close(true);
                        return [3 /*break*/, 4];
                    case 3:
                        ex_2 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_2);
                        this.log.error("Error removing billing profile " + JSON.stringify(ex_2));
                        this.spinner = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return DialogBillingProfileDeleteComponent;
}());
exports.DialogBillingProfileDeleteComponent = DialogBillingProfileDeleteComponent;
