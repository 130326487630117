"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// import { Sort } from '@angular/material';
exports.USER_LIST_SUCCESS = '[UserList] Success';
exports.USER_LIST_SELECT_ALL = '[UserList] Select all';
exports.USER_LIST_SELECT_ALL_ACTIVE = '[UserList] Select all active';
exports.USER_LIST_SELECT_ALL_INVITED = '[UserList] Select all invited';
exports.USER_LIST_SELECT_TOGGLE = '[UserList] Select toggle';
var UserListSuccessAction = /** @class */ (function () {
    function UserListSuccessAction(payload) {
        this.payload = payload;
        this.type = exports.USER_LIST_SUCCESS;
    }
    return UserListSuccessAction;
}());
exports.UserListSuccessAction = UserListSuccessAction;
var UserListSelectAllAction = /** @class */ (function () {
    function UserListSelectAllAction(payload) {
        this.payload = payload;
        this.type = exports.USER_LIST_SELECT_ALL;
    }
    return UserListSelectAllAction;
}());
exports.UserListSelectAllAction = UserListSelectAllAction;
var UserListSelectAllActiveAction = /** @class */ (function () {
    function UserListSelectAllActiveAction() {
        this.type = exports.USER_LIST_SELECT_ALL_ACTIVE;
    }
    return UserListSelectAllActiveAction;
}());
exports.UserListSelectAllActiveAction = UserListSelectAllActiveAction;
var UserListSelectAllInvitedAction = /** @class */ (function () {
    function UserListSelectAllInvitedAction() {
        this.type = exports.USER_LIST_SELECT_ALL_INVITED;
    }
    return UserListSelectAllInvitedAction;
}());
exports.UserListSelectAllInvitedAction = UserListSelectAllInvitedAction;
var UserListSelectToggleAction = /** @class */ (function () {
    function UserListSelectToggleAction(payload) {
        this.payload = payload;
        this.type = exports.USER_LIST_SELECT_TOGGLE;
    }
    return UserListSelectToggleAction;
}());
exports.UserListSelectToggleAction = UserListSelectToggleAction;
