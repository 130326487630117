"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var DialogShareDetailsComponent = /** @class */ (function () {
    function DialogShareDetailsComponent(activeModal) {
        this.activeModal = activeModal;
    }
    DialogShareDetailsComponent.prototype.ngOnInit = function () { };
    DialogShareDetailsComponent.prototype.closeDialog = function () {
        this.activeModal.close();
    };
    DialogShareDetailsComponent.prototype.onStateChange = function (state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var share;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.shareService.getShare(this.shareData.shareId)];
                    case 1:
                        share = _a.sent();
                        this.shareData = share;
                        this.openState = state;
                        return [2 /*return*/];
                }
            });
        });
    };
    return DialogShareDetailsComponent;
}());
exports.DialogShareDetailsComponent = DialogShareDetailsComponent;
