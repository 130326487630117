"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var comment_block_component_1 = require("../comment-block/comment-block.component");
var environment_1 = require("../../../../../environments/environment");
var platform_browser_1 = require("@angular/platform-browser");
var base64_service_1 = require("../../../../core/base64.service");
var api_service_1 = require("../../../../core/api.service");
var comment_service_1 = require("../../../../core/comment.service");
var logger_service_1 = require("../../../../core/logger.service");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var fromLinkFileList = require("../../../../reducers/link-file-list.reducer");
var user_model_1 = require("../../../models/user.model");
var http_1 = require("@angular/common/http");
var err_code_model_1 = require("../../../models/err-code.model");
var router_1 = require("@angular/router");
var CommentComponent = /** @class */ (function () {
    function CommentComponent(log, commentService, api, base64, sanitizer, fb, route) {
        this.log = log;
        this.commentService = commentService;
        this.api = api;
        this.base64 = base64;
        this.sanitizer = sanitizer;
        this.fb = fb;
        this.route = route;
        this.commentCreationEvent = new core_1.EventEmitter();
        this.commentEditionEvent = new core_1.EventEmitter();
        this.commentDeletionEvent = new core_1.EventEmitter();
        this.commentGetEvent = new core_1.EventEmitter();
        this.replyCreationEvent = new core_1.EventEmitter();
        this.replyEditionEvent = new core_1.EventEmitter();
        this.replyDeletionEvent = new core_1.EventEmitter();
        this.anonymousCreationEvent = new core_1.EventEmitter();
        this.avatarEndpoint = environment_1.environment.logohost + "avatar";
        this.contentLength = 200;
        this.anonymousNameLength = 35;
        this.movementEvents = ['keydown', 'click']; //types of user movement that delays reloading the page
    }
    CommentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.postButtonEnabled = false;
        var contentValidators = [
            forms_1.Validators.maxLength(this.contentLength),
            forms_1.Validators.minLength(1),
        ];
        var nameValidators = [
            forms_1.Validators.required,
            forms_1.Validators.minLength(1),
            forms_1.Validators.maxLength(this.anonymousNameLength),
        ];
        if (this.comment) {
            this.replies = this.comment.replies;
        }
        if (this.isInputBox) {
            this.commentCreationForm = new forms_1.FormControl('', contentValidators);
        }
        if (this.comment) {
            this.commentEditionForm = new forms_1.FormControl({ value: this.comment.content, disabled: true }, contentValidators);
            this.viewDetailAnonymousForm = this.fb.group({
                name: ['', nameValidators],
                email: ['', forms_1.Validators.email],
            });
            this.replyAnonymousForm = this.fb.group({
                name: ['', nameValidators],
                email: ['', forms_1.Validators.email],
                content: [
                    '',
                    [
                        forms_1.Validators.required,
                        forms_1.Validators.minLength(1),
                        forms_1.Validators.maxLength(this.contentLength),
                    ],
                ],
            });
        }
        else {
            this.commentEditionForm = new forms_1.FormControl({ value: '', disabled: true }, contentValidators);
        }
        //set avatar for current user and comment author
        if (this.user && this.user.exists) {
            this.userAvatar = this.avatarEndpoint + "/" + this.user.id;
        }
        else {
            this.userAvatar = '../../../images/anonymous.png';
        }
        if (this.comment && !this.comment.temp_session) {
            this.authorAvatar = this.avatarEndpoint + "/" + this.comment.author_id;
        }
        else {
            this.authorAvatar = '../../../images/anonymous.png';
        }
        this.anonymousForm = this.fb.group({
            name: ['', nameValidators],
            email: ['', forms_1.Validators.email],
            content: [
                '',
                [
                    forms_1.Validators.required,
                    forms_1.Validators.minLength(1),
                    forms_1.Validators.maxLength(this.contentLength),
                ],
            ],
        });
        //comment feature
        if (this.link.image_cachekey) {
            this.buttonPrimaryColor = this.link.button_primary_color;
            this.buttonTextColor = this.link.button_text_color;
            this.linkTextColor = this.link.link_text_color;
        }
        this.commentInputBoxEnabled = true;
        var pathname = window.location.pathname.substring(1, window.location.pathname.length);
        this.currentPath = pathname.concat(window.location.hash); // append #hash part if any for enhanced privacy links
        this.loginUrl = environment_1.environment.cphost + "/login";
        this.movementEvents.forEach(function (e) {
            return document.addEventListener(e, _this.clearError.bind(_this), false);
        });
    };
    CommentComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        this.movementEvents.forEach(function (e) {
            return document.removeEventListener(e, _this.clearError.bind(_this), false);
        });
    };
    CommentComponent.prototype.ngOnChanges = function (changes) {
        if (changes.comment) {
            this.replies = changes.comment.currentValue.replies;
        }
        if (changes.user) {
            this.user = changes.user.currentValue;
        }
        if (changes.link) {
            this.link = changes.link.currentValue;
        }
        if (changes.tempSession) {
            this.tempSession = changes.tempSession.currentValue;
        }
        if (changes.commentInputBoxEnabled) {
            this.commentInputBoxEnabled =
                changes.commentInputBoxEnabled.currentValue;
        }
    };
    CommentComponent.prototype.createComment = function () {
        var newComment;
        newComment = {
            loc_id: this.route.snapshot.params['key'],
            author_id: this.user.id,
            author_name: this.user.display_name,
            content: this.commentCreationForm.value,
            temp_session: this.tempSession,
        };
        this.commentCreationEvent.emit(newComment);
        this.commentCreationForm.reset();
        this.postButtonEnabled = false;
    };
    CommentComponent.prototype.editComment = function () {
        this.editMode = true;
        this.commentEditionForm.enable();
    };
    CommentComponent.prototype.updateComment = function () {
        var data;
        data = {
            _id: this.comment._id,
            author_id: this.user.id,
            content: this.commentEditionForm.value,
            temp_session: this.tempSession,
        };
        this.commentEditionEvent.emit(data);
        this.editMode = false;
        this.commentEditionForm.disable();
    };
    CommentComponent.prototype.deleteComment = function () {
        var data;
        data = {
            _id: this.comment._id,
            author_id: this.user.id,
            temp_session: this.tempSession,
        };
        this.commentDeletionEvent.emit(data);
    };
    CommentComponent.prototype.createReply = function (newReply) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contentLength, _a, input, signedInput, response, ex_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        contentLength = newReply.content.length;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 5, , 6]);
                        //encrypt reply content
                        _a = newReply;
                        return [4 /*yield*/, this.commentService.encryptContent(this.cdk, newReply.content)];
                    case 2:
                        //encrypt reply content
                        _a.content = _b.sent();
                        input = tslib_1.__assign({ servtime: Date.now(), access_token: '', signature: '' }, newReply, { content_length: contentLength.toString() });
                        return [4 /*yield*/, comment_block_component_1.CommentBlockComponent.signRequest(input)];
                    case 3:
                        signedInput = _b.sent();
                        return [4 /*yield*/, this.commentService.createReply(newReply, signedInput)];
                    case 4:
                        response = _b.sent();
                        this.log.info('Reply has been created');
                        this.commentGetEvent.emit();
                        this.sendNotification('POST');
                        this.replyInputBoxEnabled = false;
                        return [3 /*break*/, 6];
                    case 5:
                        ex_1 = _b.sent();
                        this.handleError(ex_1);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    CommentComponent.prototype.updateReply = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contentLength, _a, input, signedInput, response, ex_2;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 5]);
                        contentLength = data.content.length;
                        _a = data;
                        return [4 /*yield*/, this.commentService.encryptContent(this.cdk, data.content)];
                    case 1:
                        _a.content = _b.sent();
                        input = tslib_1.__assign({ servtime: Date.now(), access_token: '', signature: '' }, data, { content_length: contentLength.toString() });
                        return [4 /*yield*/, comment_block_component_1.CommentBlockComponent.signRequest(input)];
                    case 2:
                        signedInput = _b.sent();
                        return [4 /*yield*/, this.commentService.updateReply(data, signedInput)];
                    case 3:
                        response = _b.sent();
                        this.log.info('Reply has been updated');
                        this.commentGetEvent.emit();
                        this.sendNotification('PATCH');
                        return [3 /*break*/, 5];
                    case 4:
                        ex_2 = _b.sent();
                        this.handleError(ex_2);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    CommentComponent.prototype.deleteReply = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var deleteParams, input, signedInput, response, ex_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        deleteParams = {
                            author_id: data.author_id,
                            temp_session: encodeURIComponent(data.temp_session),
                        };
                        input = tslib_1.__assign({ servtime: Date.now(), access_token: '', signature: '' }, deleteParams);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, comment_block_component_1.CommentBlockComponent.signRequest(input)];
                    case 2:
                        signedInput = _a.sent();
                        return [4 /*yield*/, this.commentService.deleteReply(data, signedInput)];
                    case 3:
                        response = _a.sent();
                        this.log.info('Reply has been deleted');
                        this.commentGetEvent.emit();
                        this.sendNotification('DEL', false);
                        return [3 /*break*/, 5];
                    case 4:
                        ex_3 = _a.sent();
                        this.handleError(ex_3);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    CommentComponent.prototype.handleError = function (errResponse) {
        if (errResponse instanceof http_1.HttpErrorResponse) {
            this.errCode = new err_code_model_1.ErrCode(errResponse.status, errResponse.error.message || errResponse.message, errResponse.error.detail || errResponse.name);
        }
        else {
            this.errCode = err_code_model_1.ErrCode.fromException(errResponse);
        }
    };
    CommentComponent.prototype.clearError = function () {
        this.errCode = null;
    };
    CommentComponent.prototype.toggleReplyInputBox = function () {
        if (!this.user) {
            this.replyAnonymousPromptEnabled = true;
        }
        else {
            this.replyInputBoxEnabled = !this.replyInputBoxEnabled;
        }
    };
    CommentComponent.prototype.submitAnonymousForm = function () {
        this.anonymousCreationEvent.emit(this.anonymousForm.value);
        this.anonymousForm.reset();
        this.anonymousFormEnabled = false;
        this.commentInputBoxEnabled = true;
    };
    CommentComponent.prototype.submitReplyAnonymousForm = function () {
        this.anonymousCreationEvent.emit(tslib_1.__assign({ commentID: this.comment._id }, this.replyAnonymousForm.value));
        this.replyAnonymousForm.reset();
        this.replyAnonymousFormEnabled = false;
    };
    CommentComponent.prototype.submitViewDetailAnonymousForm = function () {
        this.anonymousCreationEvent.emit(this.viewDetailAnonymousForm.value);
        this.viewDetailAnonymousForm.reset();
        this.viewDetailPromptEnabled = false;
        this.viewDetailAnonymousFormEnabled = false;
        this.detailEnabled = true;
        this.hideViewDetailAnonymousForm();
    };
    CommentComponent.prototype.toggleDetail = function () {
        // if (!this.user) {
        //     this.viewDetailPromptEnabled = true;
        // }
        this.detailEnabled = !this.detailEnabled;
    };
    CommentComponent.prototype.hideAnonymousForm = function () {
        this.anonymousFormEnabled = false;
        this.commentInputBoxEnabled = true;
        this.anonymousForm.reset();
    };
    CommentComponent.prototype.hideViewDetailAnonymousForm = function () {
        this.viewDetailPromptEnabled = false;
        this.viewDetailAnonymousFormEnabled = false;
        this.viewDetailAnonymousForm.reset();
    };
    /**
     *send request to create email and event notification to the php api
     *if fails, do not throw errors since this feature is non-essential
     */
    CommentComponent.prototype.sendNotification = function (method, sendEmail) {
        if (sendEmail === void 0) { sendEmail = true; }
        if (!this.link.comment_notification) {
            this.log.info('Comment notification is disabled');
            return;
        }
        //create event for this user
        if (this.user.exists) {
            this.notify(this.user.id, method, false);
        }
        //send notification to link owner if this user is not link owner (to avoid double notifications)
        if (this.user.id !== this.ownerId) {
            this.notify(this.ownerId, method);
        }
        //send notification to parent author if this user is not parent comment author AND not link owner
        if (this.user.id !== this.comment.author_id &&
            this.comment.author_id != this.ownerId) {
            this.notify(this.comment.author_id, method);
        }
    };
    CommentComponent.prototype.toggleConfirm = function () {
        this.confirmedDelete = !this.confirmedDelete;
    };
    CommentComponent.prototype.cancelEditMode = function () {
        this.editMode = false;
        this.commentEditionForm.reset();
        this.commentEditionForm.setValue(this.comment.content);
    };
    CommentComponent.prototype.cancelConfirm = function () {
        this.confirmedDelete = false;
    };
    CommentComponent.prototype.cancelNewComment = function () {
        this.postButtonEnabled = false;
        this.commentCreationForm.reset();
        this.commentCreationForm.setValue('');
    };
    CommentComponent.prototype.hidePromptOptions = function () {
        this.viewDetailPromptEnabled = false;
        this.replyAnonymousPromptEnabled = false;
        this.anonymousPromptEnabled = false;
        this.postButtonEnabled = false;
        this.commentInputBoxEnabled = true;
    };
    CommentComponent.prototype.hideReplyAnonymousForm = function () {
        this.replyAnonymousFormEnabled = false;
        this.replyAnonymousPromptEnabled = false;
        this.replyAnonymousForm.reset();
    };
    CommentComponent.prototype.enableViewDetailAnonymousForm = function () {
        this.viewDetailAnonymousFormEnabled = true;
    };
    CommentComponent.prototype.enableAnonymousPrompt = function () {
        if (!this.user) {
            this.anonymousPromptEnabled = true;
            this.commentInputBoxEnabled = false;
        }
    };
    CommentComponent.prototype.enableAnonymousForm = function () {
        this.commentInputBoxEnabled = false;
        this.anonymousFormEnabled = true;
    };
    CommentComponent.prototype.enableRepyAnonymousForm = function () {
        this.replyAnonymousFormEnabled = true;
        this.replyAnonymousPromptEnabled = false;
    };
    CommentComponent.prototype.notify = function (recipientId, method, sendEmail) {
        if (sendEmail === void 0) { sendEmail = true; }
        this.api.execute('notifycomment', {
            publink_id: this.publinkId,
            link_owner_id: this.ownerId,
            recipient_id: recipientId,
            display_name: this.user.exists
                ? this.user.display_name
                : this.base64.encode('An anonymous user'),
            link_name: this.base64.encode(this.item.name),
            link_url: this.base64.encode(window.location.href),
            method: method,
            send_email: sendEmail,
        });
    };
    CommentComponent.prototype.validContent = function (value) {
        return value != undefined && value.trim().length !== 0;
    };
    CommentComponent.prototype.getLinkKey = function () {
        return this.route.snapshot.params['key'] || this.route.snapshot.fragment;
    };
    Object.defineProperty(CommentComponent.prototype, "customColorsCss", {
        /**
        * this function updates css variables based on changes of this component's attributes
        */
        get: function () {
            return this.sanitizer
                .bypassSecurityTrustStyle("--buttonPrimaryColor: " + this.buttonPrimaryColor + ";\n                                                        --buttonTextColor: " + this.buttonTextColor + ";\n                                                        --linkTextColor: " + this.linkTextColor + ";\n                                                    ");
        },
        enumerable: true,
        configurable: true
    });
    return CommentComponent;
}());
exports.CommentComponent = CommentComponent;
