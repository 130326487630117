"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var user_service_1 = require("../../core/user.service");
var i0 = require("@angular/core");
var i1 = require("../../core/user.service");
var ResolveIsAdminResolver = /** @class */ (function () {
    function ResolveIsAdminResolver(userService) {
        this.userService = userService;
    }
    ResolveIsAdminResolver.prototype.resolve = function () {
        var user = this.userService.getUser();
        return user.is_multi_admin;
    };
    ResolveIsAdminResolver.ngInjectableDef = i0.defineInjectable({ factory: function ResolveIsAdminResolver_Factory() { return new ResolveIsAdminResolver(i0.inject(i1.UserService)); }, token: ResolveIsAdminResolver, providedIn: "root" });
    return ResolveIsAdminResolver;
}());
exports.ResolveIsAdminResolver = ResolveIsAdminResolver;
