"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var api_service_1 = require("../core/api.service");
var dirty_out_service_1 = require("../core/crypt/dirty-out.service");
var sync_crypt_service_1 = require("../core/crypt/sync-crypt.service");
var logger_service_1 = require("../core/logger.service");
var notifications_service_1 = require("../core/notifications.service");
var link_file_list_service_1 = require("../link-consume/services/link-file-list.service");
var broadcast_service_1 = require("../shared/services/broadcast.service");
var dialog_folder_upload_component_1 = require("./dialog-folder-upload/dialog-folder-upload.component");
var build_transfer_item_service_1 = require("./services/build-transfer-item.service");
var run_upload_folder_service_1 = require("./services/run-upload-folder.service");
var transfer_service_1 = require("./transfer.service");
var file_item_service_1 = require("../file/file-item.service");
var blend_service_1 = require("../shared/services/blend.service");
var models_1 = require("../shared/models");
var i0 = require("@angular/core");
var i1 = require("./transfer.service");
var i2 = require("@ng-bootstrap/ng-bootstrap");
var i3 = require("../core/logger.service");
var i4 = require("../core/notifications.service");
var i5 = require("../link-consume/services/link-file-list.service");
var i6 = require("../core/api.service");
var i7 = require("../core/crypt/dirty-out.service");
var i8 = require("./services/build-transfer-item.service");
var i9 = require("../core/crypt/sync-crypt.service");
var i10 = require("./services/run-upload-folder.service");
var i11 = require("../shared/services/broadcast.service");
var i12 = require("../file/file-item.service");
var i13 = require("../shared/services/blend.service");
var UploadFolderService = /** @class */ (function () {
    function UploadFolderService(transferService, modalService, loggerService, notificationsService, LinkPathList, apiService, dirtyOutService, buildTransferItemService, syncCryptService, runUploadFolderService, broadcastService, fileItemService, blendService) {
        this.transferService = transferService;
        this.modalService = modalService;
        this.loggerService = loggerService;
        this.notificationsService = notificationsService;
        this.LinkPathList = LinkPathList;
        this.apiService = apiService;
        this.dirtyOutService = dirtyOutService;
        this.buildTransferItemService = buildTransferItemService;
        this.syncCryptService = syncCryptService;
        this.runUploadFolderService = runUploadFolderService;
        this.broadcastService = broadcastService;
        this.fileItemService = fileItemService;
        this.blendService = blendService;
        this.file_ext = [];
        this.mime_type = [];
        this.file_size = 0;
        // set a delay between mkdir commands to prevent causing too high of a load
        // on the live servers.  The number is in milliseconds.
        this.MKDIR_DELAY = 300;
        // once we encounter > maxFiles we will stop the folder upload.
        this.maxFiles = 20000;
    }
    UploadFolderService.prototype.showFolderUpload = function (isUploadInProgress) {
        isUploadInProgress ? this.runUploadFolderService.folderUploadInProgress = true : this.runUploadFolderService.folderUploadInProgress = false;
        this.transferService.hideTransfer();
        var instance = this.modalService.open(dialog_folder_upload_component_1.DialogFolderUploadComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
        });
        instance.result.then(function (result) {
            console.warn('showFolderUpload closed');
        }, function () {
            console.log('showFolderUpload cancelled');
        });
        return instance;
    };
    UploadFolderService.prototype.run = function (cwd, items, linkId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var stTime, scanData, scanTime, mkDirTime, mkDirTimeTotal, makeTime, makeTimeTotal, prepTime, upTime, upTimeTotal, ex_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.runUploadFolderService.view.isScanning || this.runUploadFolderService.view.isProcessing) {
                            this.showFolderUpload(true);
                            return [2 /*return*/];
                        }
                        stTime = Date.now();
                        if (!cwd || !cwd.sync_id) {
                            this.loggerService.error('Unable to determine root folder');
                            this.handleErr({ code: 7213, msg: 'Unable to determine root folder' });
                            return [2 /*return*/];
                        }
                        this.notificationsService.stopNotificationLoop();
                        this.runUploadFolderService.reset();
                        this.showFolderUpload();
                        this.runUploadFolderService.view.isScanning = true;
                        this.runUploadFolderService.cwd = cwd;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        return [4 /*yield*/, this.getScanData(cwd.sync_id, linkId)];
                    case 2:
                        scanData = _a.sent();
                        return [4 /*yield*/, this.processEntries(scanData, items)];
                    case 3:
                        _a.sent();
                        scanTime = Date.now() - stTime;
                        this.loggerService.warn("Scanning took " + scanTime + "ms for " + this.runUploadFolderService.queueFiles.length + " files and " + this.runUploadFolderService.view.folderCount);
                        mkDirTime = Date.now();
                        return [4 /*yield*/, this.makeDirs(cwd.sync_id, scanData)];
                    case 4:
                        _a.sent();
                        mkDirTimeTotal = Date.now() - mkDirTime;
                        this.loggerService.warn("Creating directories took " + mkDirTimeTotal + "ms for " + this.runUploadFolderService.view.folderCount + " folders");
                        makeTime = Date.now();
                        return [4 /*yield*/, this.makeTransferItems(cwd.sync_id, scanData)];
                    case 5:
                        _a.sent();
                        makeTimeTotal = Date.now() - makeTime;
                        this.loggerService.warn("Creating transfer items took " + makeTimeTotal + "ms for " + this.runUploadFolderService.workFiles.length + " folders");
                        prepTime = Date.now() - stTime;
                        this.loggerService.warn("Preparation took " + prepTime + "ms for " + this.runUploadFolderService.workFiles.length + " files and " + this.runUploadFolderService.view.folderCount + " ");
                        this.runUploadFolderService.view.isScanning = false;
                        upTime = Date.now();
                        return [4 /*yield*/, this.runUploadFolderService.runUploadQueue()];
                    case 6:
                        _a.sent();
                        upTimeTotal = Date.now() - upTime;
                        this.runUploadFolderService.folderUploadInProgress = false;
                        this.loggerService.info(['Upload completed: scanTime:',
                            scanTime, 'ms',
                            ' , mkdir: ', mkDirTimeTotal, 'ms',
                            ' , tItem: ', makeTimeTotal, 'ms',
                            ' , prep total: ', prepTime, 'ms',
                            ' , upload: ', upTimeTotal, 'ms',
                            ' for a total of ', this.runUploadFolderService.workFiles.length, ' files and ',
                            this.runUploadFolderService.view.folderCount, ' folders'
                        ].join(''));
                        if (this.file_ext && this.file_ext.length > 0) {
                            this.file_ext.forEach(function (element) {
                                var type = _this.fileItemService.getmimeType(element);
                                if (_this.file_ext.indexOf(type) === -1) {
                                    _this.mime_type.push(type);
                                }
                            });
                        }
                        this.blendService.track(models_1.BlendEvent.UPLOAD_FILE, {
                            isDragDrop: 'Yes',
                            mimeType: this.mime_type,
                            fileSize: this.fileItemService.bytesToSize(this.file_size)
                        });
                        return [3 /*break*/, 8];
                    case 7:
                        ex_1 = _a.sent();
                        this.loggerService.error('An error occurred ');
                        this.handleErr(ex_1);
                        this.runUploadFolderService.view.error = ex_1;
                        return [3 /*break*/, 8];
                    case 8:
                        this.loggerService.info('Starting services');
                        this.notificationsService.startNotificationLoop();
                        this.LinkPathList.reload();
                        this.broadcastService.broadcast('event:file-list.reload');
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadFolderService.prototype.makeDirs = function (pid, scanData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sortedDirKeys, i, len, fullPath, item, newPid, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.runUploadFolderService.view.isMakingFolders = true;
                        sortedDirKeys = Object.keys(this.runUploadFolderService.dirMap).sort(function (a, b) {
                            return a.split('/').length - b.split('/').length;
                        });
                        i = 0, len = sortedDirKeys.length;
                        _a.label = 1;
                    case 1:
                        if (!(i < len)) return [3 /*break*/, 4];
                        if (this.runUploadFolderService.queueFiles.length > this.maxFiles) {
                            throw { code: 7240 };
                        }
                        if (this.runUploadFolderService.shouldCancel) {
                            this.loggerService.error('Cancel requested');
                            throw { code: 7210 };
                        }
                        fullPath = sortedDirKeys[i], item = this.runUploadFolderService.dirMap[fullPath], newPid = this.translatePidFromPath(pid, fullPath);
                        this.runUploadFolderService.view.currentFolder = fullPath;
                        return [4 /*yield*/, this.mkDir(newPid, item.name, scanData)];
                    case 2:
                        result = _a.sent();
                        item.id = result.id;
                        this.runUploadFolderService.view.folderMadeCount += 1;
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.runUploadFolderService.view.isMakingFolders = false;
                        return [2 /*return*/, true];
                }
            });
        });
    };
    UploadFolderService.prototype.makeTransferItems = function (rootPid, scanData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var i, len, dest, pid, _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.runUploadFolderService.view.isMakingTransfers = true;
                        i = 0, len = this.runUploadFolderService.queueFiles.length;
                        _c.label = 1;
                    case 1:
                        if (!(i < len)) return [3 /*break*/, 4];
                        if (this.runUploadFolderService.queueFiles.length > this.maxFiles) {
                            throw { code: 7240 };
                        }
                        if (this.runUploadFolderService.shouldCancel) {
                            this.loggerService.error('Cancel requested');
                            throw { code: 7210 };
                        }
                        dest = this.runUploadFolderService.dirMap[this.runUploadFolderService.queueFiles[i].pidPath];
                        if (!this.runUploadFolderService.queueFiles[i].file) {
                            this.loggerService.error('Could not find entry, or returned null');
                        }
                        pid = (dest && dest.id) ? dest.id : rootPid;
                        _b = (_a = this.runUploadFolderService.workFiles).push;
                        return [4 /*yield*/, this.getTransferItem(scanData, pid, this.runUploadFolderService.queueFiles[i])];
                    case 2:
                        _b.apply(_a, [_c.sent()]);
                        _c.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.runUploadFolderService.view.isMakingTransfers = false;
                        this.loggerService.info('Made all transfer items');
                        return [2 /*return*/, true];
                }
            });
        });
    };
    UploadFolderService.prototype.processEntries = function (scanData, items) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var process;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        process = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var item, entries, ex_2, ex_3;
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        // try to break out here, but we may have a hard time getting out
                                        // since this function needs to run quickly so that MS Edge will
                                        // complete it.
                                        if (this.runUploadFolderService.queueFiles.length > this.maxFiles) {
                                            throw { code: 7240 };
                                        }
                                        if (items.length == 0) {
                                            return [2 /*return*/];
                                        }
                                        if (this.runUploadFolderService.shouldCancel) {
                                            this.loggerService.error('Cancel requested');
                                            throw { code: 7210 };
                                        }
                                        item = items.shift();
                                        if (!item.isDirectory) return [3 /*break*/, 7];
                                        // this.Logger.info('adding dir  ' + item.name);
                                        // this.mkDir(pid, item.name, scanData)
                                        //     .then((parent) => {
                                        this.runUploadFolderService.dirMap[item.fullPath] = {
                                            fullPath: item.fullPath,
                                            name: item.name
                                        };
                                        this.runUploadFolderService.view.folderCount += 1;
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 5, , 6]);
                                        return [4 /*yield*/, this.getListOfEntries(item)];
                                    case 2:
                                        entries = _a.sent();
                                        return [4 /*yield*/, this.processEntries(scanData, entries)];
                                    case 3:
                                        _a.sent();
                                        return [4 /*yield*/, process()];
                                    case 4:
                                        _a.sent();
                                        return [3 /*break*/, 6];
                                    case 5:
                                        ex_2 = _a.sent();
                                        this.loggerService.error(ex_2);
                                        this.loggerService.error('Trying to re-throw');
                                        throw ex_2;
                                    case 6: return [3 /*break*/, 13];
                                    case 7:
                                        if (!item.isFile) return [3 /*break*/, 12];
                                        this.runUploadFolderService.view.filePendingCount += 1;
                                        item.file(function (file) {
                                            _this.runUploadFolderService.view.totalBytes += file.size;
                                            _this.runUploadFolderService.view.fileCount += 1;
                                            _this.runUploadFolderService.queueFiles.push({
                                                fullPath: item.fullPath,
                                                pidPath: item.fullPath.substr(0, item.fullPath.lastIndexOf('/')),
                                                file: file
                                            });
                                            _this.file_ext.push(_this.fileItemService.getFileExt(file.name));
                                            _this.file_size += file.size;
                                        }, function (err) {
                                            _this.loggerService.error('Error getting file');
                                            throw new Error('Error getting file from entry.file()');
                                        });
                                        _a.label = 8;
                                    case 8:
                                        _a.trys.push([8, 10, , 11]);
                                        return [4 /*yield*/, process()];
                                    case 9:
                                        _a.sent();
                                        return [3 /*break*/, 11];
                                    case 10:
                                        ex_3 = _a.sent();
                                        this.loggerService.error(ex_3);
                                        this.loggerService.error('Trying to re-throw');
                                        throw ex_3;
                                    case 11: return [3 /*break*/, 13];
                                    case 12:
                                        this.loggerService.error('Received an unknown type of entry while processing');
                                        _a.label = 13;
                                    case 13: return [2 /*return*/];
                                }
                            });
                        }); };
                        return [4 /*yield*/, process()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadFolderService.prototype.translatePidFromPath = function (rootPid, pidPath) {
        var pathComp = pidPath.split('/');
        var newPid = rootPid;
        // path Comp length will contain 2 components
        // ["", "Dirname"] - since a path of "/Dirname" splits to two
        if (pathComp.length == 2) {
            newPid = rootPid;
        }
        else if (pathComp.length > 2) {
            var parentPath = pidPath.substr(0, pidPath.lastIndexOf('/'));
            // console.log('Path comp is > 2 ', parentPath, pathComp, fullPath);
            newPid = this.runUploadFolderService.dirMap[parentPath].id;
        }
        return newPid;
    };
    UploadFolderService.prototype.getListOfEntries = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var reader = item.createReader();
                        var items = [];
                        var getEntries = function () {
                            reader.readEntries(function (entries) {
                                if (entries.length == 0) {
                                    resolve(items);
                                    reader = undefined;
                                    return;
                                }
                                else {
                                    items = items.concat(entries);
                                    getEntries();
                                }
                            }, function (err) {
                                _this.loggerService.error('Read entries error');
                            });
                        };
                        getEntries();
                    })];
            });
        });
    };
    UploadFolderService.prototype.getScanData = function (syncId, linkId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, (linkId)
                        ? this.apiService.execute('pathpreuploadpublic', {
                            sync_pid: syncId,
                            enc_share_name: '1:RGVhZEJlZWY=',
                            publink_id: linkId
                        })
                        : this.dirtyOutService.getShareKeyDataForSyncId(syncId)];
            });
        });
    };
    UploadFolderService.prototype.getTransferItem = function (scanData, pid, item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tItem, _a, encShareName, error_1, err_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 11, , 12]);
                        if (!(Array.isArray(scanData))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.buildTransferItemService.mkuploadItem(item.file)];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.buildTransferItemService.mkPublicUploadItem(item.file)];
                    case 3:
                        _a = _b.sent();
                        _b.label = 4;
                    case 4:
                        tItem = _a;
                        tItem.filename = item.file.name.slice(0);
                        tItem.fullPath = item.fullPath.slice(0);
                        tItem.sync_pid = pid;
                        if (!!Array.isArray(scanData)) return [3 /*break*/, 9];
                        this.runUploadFolderService.view.totalBytes += tItem.filesize;
                        _b.label = 5;
                    case 5:
                        _b.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, this.LinkPathList.encName(item.file.name)];
                    case 6:
                        encShareName = _b.sent();
                        tItem.enc_share_name = encShareName;
                        return [2 /*return*/, tItem];
                    case 7:
                        error_1 = _b.sent();
                        this.loggerService.error('Unable to encrypt the file name for the link path list');
                        return [3 /*break*/, 8];
                    case 8: return [3 /*break*/, 10];
                    case 9: return [2 /*return*/, tItem];
                    case 10: return [3 /*break*/, 12];
                    case 11:
                        err_1 = _b.sent();
                        this.loggerService.error("unable to make upload item for " + item.file.name.slice(0));
                        throw { code: 7215 };
                    case 12: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * We use this mkDir since we get the sharedata required initially and
     * skip making the API call since we can re-use it for the entire upload
     * process
     * @param pid {number} the sync pid to make the dir in
     * @param name {string} the plain text name of the file.
     * @param sharedata {sync.IDirtyOutShareKeyItem[]} an array of sharekey items
     */
    UploadFolderService.prototype.mkDir = function (pid, name, scanData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var keyArray, enc_name, result_1, err_2, err_3, err_4, encShareName, data, res_1, err_5;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!Array.isArray(scanData)) return [3 /*break*/, 13];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 11, , 12]);
                        return [4 /*yield*/, this.dirtyOutService.getKeyArrayForSyncId(scanData, pid, name)];
                    case 2:
                        keyArray = _a.sent();
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 9, , 10]);
                        return [4 /*yield*/, this.syncCryptService.filenameEncrypt(name)];
                    case 4:
                        enc_name = _a.sent();
                        _a.label = 5;
                    case 5:
                        _a.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, this.apiService.execute('pathadd', {
                                sync_pid: pid,
                                new_name: enc_name,
                                share_names: keyArray
                            })];
                    case 6:
                        result_1 = _a.sent();
                        return [2 /*return*/, new Promise(function (resolve) {
                                setTimeout(function () {
                                    resolve({
                                        id: result_1.pathitem.sync_id,
                                        pid: result_1.pathitem.pid,
                                        is_publink: result_1.pathitem.is_publink
                                    });
                                }, _this.MKDIR_DELAY);
                            })];
                    case 7:
                        err_2 = _a.sent();
                        this.handleErr(err_2);
                        return [3 /*break*/, 8];
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        err_3 = _a.sent();
                        this.handleErr(err_3);
                        return [3 /*break*/, 10];
                    case 10: return [3 /*break*/, 12];
                    case 11:
                        err_4 = _a.sent();
                        this.handleErr(err_4);
                        return [3 /*break*/, 12];
                    case 12: return [3 /*break*/, 18];
                    case 13:
                        if (pid == this.runUploadFolderService.cwd.sync_id) {
                            name = this.LinkPathList.getValidFileName(name);
                        }
                        return [4 /*yield*/, this.LinkPathList.encName(name)];
                    case 14:
                        encShareName = _a.sent();
                        data = {
                            pid: pid,
                            sharekey_id: this.runUploadFolderService.cwd.share_key_id,
                            enc_share_name: encShareName,
                            device_sig_b64: scanData.device_sig_b64,
                            backup_id: scanData.backup_id,
                            user_id: scanData.user_id,
                            device_id: scanData.device_id,
                            servtime: scanData.servtime
                        };
                        _a.label = 15;
                    case 15:
                        _a.trys.push([15, 17, , 18]);
                        return [4 /*yield*/, this.apiService.execute('pathaddshare', data)];
                    case 16:
                        res_1 = _a.sent();
                        return [2 /*return*/, new Promise(function (resolve) {
                                setTimeout(function () {
                                    resolve({
                                        id: res_1.pathitem.id,
                                        pid: res_1.pathitem.pid,
                                        is_publink: res_1.pathitem.is_publink
                                    });
                                }, _this.MKDIR_DELAY);
                            })];
                    case 17:
                        err_5 = _a.sent();
                        this.handleErr(err_5);
                        return [3 /*break*/, 18];
                    case 18: return [2 /*return*/];
                }
            });
        });
    };
    UploadFolderService.prototype.handleErr = function (err, promise) {
        this.loggerService.error(JSON.stringify(err));
        if (typeof err == 'object') {
            this.loggerService.error(JSON.stringify(err));
            if (err.code) {
                this.runUploadFolderService.view.error = err;
            }
            else {
                this.runUploadFolderService.view.error = { code: 7214 };
            }
        }
        else if (typeof err == 'string') {
            this.runUploadFolderService.view.error = { code: 7214, msg: err };
        }
        else {
            this.runUploadFolderService.view.error = { code: 7214 };
        }
        // this.Logger.error(angular.toJson(this.view, true));
        if (promise) {
            Promise.reject(this.runUploadFolderService.view.error);
        }
    };
    UploadFolderService.ngInjectableDef = i0.defineInjectable({ factory: function UploadFolderService_Factory() { return new UploadFolderService(i0.inject(i1.TransferService), i0.inject(i2.NgbModal), i0.inject(i3.LoggerService), i0.inject(i4.NotificationsService), i0.inject(i5.LinkFileListService), i0.inject(i6.ApiService), i0.inject(i7.DirtyOutService), i0.inject(i8.BuildTransferItemService), i0.inject(i9.SyncCryptService), i0.inject(i10.RunUploadFolderService), i0.inject(i11.BroadcastService), i0.inject(i12.FileItemService), i0.inject(i13.BlendService)); }, token: UploadFolderService, providedIn: "root" });
    return UploadFolderService;
}());
exports.UploadFolderService = UploadFolderService;
