"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_share_new_component_1 = require("../../shares/dialog-share-new/dialog-share-new.component");
var DialogShareNewDirective = /** @class */ (function () {
    function DialogShareNewDirective(modalService) {
        this.modalService = modalService;
        this.selected = [];
    }
    DialogShareNewDirective.prototype.click = function (event) {
        this.openShareNewDailog();
    };
    DialogShareNewDirective.prototype.openShareNewDailog = function () {
        var ref = this.modalService.open(dialog_share_new_component_1.DialogShareNewComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.selected = (Array.isArray(this.selected)) ? this.selected : [this.selected];
        ref.componentInstance.pid = this.pid;
    };
    return DialogShareNewDirective;
}());
exports.DialogShareNewDirective = DialogShareNewDirective;
