"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var fromRoot = require("../../../../reducers");
var store_1 = require("@ngrx/store");
var models_1 = require("../../../models");
var logger_service_1 = require("../../../../core/logger.service");
var validate_service_1 = require("../../../../core/validate.service");
var platform_browser_1 = require("@angular/platform-browser");
var environment_1 = require("../../../../../environments/environment");
var user_service_1 = require("../../../../core/user.service");
var dialog_contact_support_component_1 = require("../../../../account/dialog-contact-support/dialog-contact-support.component");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var zuora_account_service_1 = require("../../../../account/services/zuora-account.service");
var share_invite_service_1 = require("../../../../shares/share-invite.service");
var router_1 = require("@angular/router");
var HeaderSharesAcceptComponent = /** @class */ (function () {
    function HeaderSharesAcceptComponent(store, Validate, logger, sanitizer, userService, modalService, zuora, shareInviteService, route, router) {
        this.store = store;
        this.Validate = Validate;
        this.logger = logger;
        this.sanitizer = sanitizer;
        this.userService = userService;
        this.modalService = modalService;
        this.zuora = zuora;
        this.shareInviteService = shareInviteService;
        this.route = route;
        this.router = router;
        this.permissionActions = models_1.PermissionActions;
    }
    HeaderSharesAcceptComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.store.select(fromRoot.getAuthUser)
            .subscribe(function (data) {
            _this.user = data;
        });
        this.validate = this.Validate;
        this.cachekey = this.route.snapshot.params['invite_id'];
        this.shareId = parseInt(this.route.snapshot.queryParams['share_id'], 10);
        this.wrapKey = this.route.snapshot.fragment;
        if (!this.cachekey) {
            this.logger.error('The url is missing the invite id');
            this.errcode = new models_1.ErrCode(1702);
        }
        else {
            this.shareInviteService.getInvite(this.cachekey, this.wrapKey, this.shareId)
                .then(function (result) {
                //load white label attributes
                if (result.image_cachekey) {
                    _this.imageCachekey = result.image_cachekey;
                    _this.headerPrimaryColor = result.header_primary_color;
                    _this.headerTextColor = result.header_text_color;
                    _this.logoHost = environment_1.environment.logohost;
                    _this.whitelabelLoaded = true;
                }
            });
        }
        this.loadProfile();
    };
    HeaderSharesAcceptComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    HeaderSharesAcceptComponent.prototype.openContactSupport = function () {
        var ref = this.modalService.open(dialog_contact_support_component_1.DialogContactSupportComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
    };
    HeaderSharesAcceptComponent.prototype.checkPermission = function (permName) {
        return this.userService.checkPermission(permName);
    };
    Object.defineProperty(HeaderSharesAcceptComponent.prototype, "customColorsCss", {
        /**
         * this function updates css variables based on changes of this component's attributes
         */
        get: function () {
            return this.sanitizer.bypassSecurityTrustStyle("--headerPrimaryColor: " + this.headerPrimaryColor + ";\n                                                        --headerTextColor: " + this.headerTextColor);
        },
        enumerable: true,
        configurable: true
    });
    HeaderSharesAcceptComponent.prototype.onUsersClick = function () {
        if ((this.user.is_multi_admin || !this.user.is_multi_admin) &&
            !this.user.is_multi_child &&
            !this.user.is_multi_new) {
            this.logger.info('Using old multiuser');
            this.router.navigate(['/multi-user']);
        }
        else if (!this.user.is_multi_admin &&
            this.user.is_multi_child &&
            this.user.is_multi_new) {
            this.logger.info('Using new multiadmin');
            if (!this.checkPermission(this.permissionActions.VIEW_USERS) && this.checkPermission(this.permissionActions.VIEW_ROLES)) {
                this.router.navigate(['/multi-user/roles']);
            }
            else {
                this.router.navigate(['/multi-user/users']);
            }
        }
    };
    HeaderSharesAcceptComponent.prototype.onUpgradeClick = function () {
        if (this.user.is_on_trial && this.zprofile && !this.zprofile.defaultPaymentMethod) {
            this.router.navigate(['/account/billing/profile']);
        }
        else {
            this.router.navigate(['/account/upgrade']);
        }
    };
    HeaderSharesAcceptComponent.prototype.loadProfile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.checkPermission(this.permissionActions.VIEW_BILLING)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.zuora.getProfile()];
                    case 1:
                        _a.zprofile = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return HeaderSharesAcceptComponent;
}());
exports.HeaderSharesAcceptComponent = HeaderSharesAcceptComponent;
