"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var rxjs_1 = require("rxjs");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var CoreActions = require("../../actions/core.actions");
var AuthActions = require("../../actions/auth.actions");
var FileListActions = require("../../actions/file-list.actions");
var api_service_1 = require("../../core/api.service");
var base64_service_1 = require("../../core/base64.service");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var sync_digest_service_1 = require("../../core/crypt/sync-digest.service");
var models_1 = require("../../shared/models");
var api_1 = require("../../shared/models/api");
var logger_service_1 = require("../../core/logger.service");
var url_service_1 = require("../../core/url.service");
var user_service_1 = require("../../core/user.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var transfer_service_1 = require("../../transfer/transfer.service");
var notifications_service_1 = require("../../core/notifications.service");
var broadcast_service_1 = require("src/app/shared/services/broadcast.service");
var i0 = require("@angular/core");
var i1 = require("../../core/api.service");
var i2 = require("../../core/base64.service");
var i3 = require("../../core/crypt/sync-crypt.service");
var i4 = require("../../core/crypt/sync-digest.service");
var i5 = require("../../core/logger.service");
var i6 = require("@ng-bootstrap/ng-bootstrap");
var i7 = require("@ngrx/store");
var i8 = require("../../core/user.service");
var i9 = require("../../core/url.service");
var i10 = require("../../transfer/transfer.service");
var i11 = require("../../core/notifications.service");
var i12 = require("../../shared/services/broadcast.service");
var AuthService = /** @class */ (function () {
    function AuthService(api, base64, crypt, digest, log, modalService, store, userService, url, transferService, notificationsService, broadcastService) {
        this.api = api;
        this.base64 = base64;
        this.crypt = crypt;
        this.digest = digest;
        this.log = log;
        this.modalService = modalService;
        this.store = store;
        this.userService = userService;
        this.url = url;
        this.transferService = transferService;
        this.notificationsService = notificationsService;
        this.broadcastService = broadcastService;
        this.authenticated = false;
        this.bCrypt = new bCrypt();
    }
    // helper for ng1 to get store data.
    AuthService.prototype.authState = function () {
        return this.store.select(fromRoot.getAuthState);
    };
    // helper for ng1 to get store data. share-provision-controller
    AuthService.prototype.login = function (username, password, twofacode) {
        var item = new models_1.AuthUserPassword(username, password, twofacode);
        this.store.dispatch(new AuthActions.LoginAction(item));
    };
    AuthService.prototype.loginUserPass = function (username, password, twofaCode) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        username = username.toLowerCase();
                        return [4 /*yield*/, this.authenticate(username, password, twofaCode)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AuthService.prototype.authenticate = function (username, password, twofaCode) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var hashPass, accessData, keys, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        username = username.toLowerCase();
                        return [4 /*yield*/, this.getHashedPassword(username, password)];
                    case 1:
                        hashPass = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 7, , 8]);
                        return [4 /*yield*/, this.api.send('sessionnew', {
                                username: username,
                                password: hashPass,
                                twofacode: twofaCode,
                            })];
                    case 3:
                        accessData = _a.sent();
                        if (accessData.success == 1 && accessData.errors) {
                            throw models_1.ErrCode.fromApiLegacy(accessData.errors);
                        }
                        return [4 /*yield*/, this.api.send('getuserkeys', {
                                username: username,
                                password: hashPass,
                            })];
                    case 4:
                        keys = _a.sent();
                        // console.log(' ---- storekeys');
                        return [4 /*yield*/, this.storeKeys(keys, password)];
                    case 5:
                        // console.log(' ---- storekeys');
                        _a.sent();
                        // console.log(' ---- storeaccessdata');
                        return [4 /*yield*/, this.storeAccessData(accessData)];
                    case 6:
                        // console.log(' ---- storeaccessdata');
                        _a.sent();
                        // console.log(' ---- return User');
                        this.broadcastService.broadcast('sync.sessionnew.user');
                        return [2 /*return*/, new models_1.User(accessData)];
                    case 7:
                        ex_1 = _a.sent();
                        this.log.error('authenticate() failed');
                        this.log.error(ex_1);
                        throw models_1.ErrCode.fromException(ex_1);
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.authenticateAndGetUserKeys = function (username, hashPass, twofaCode, isLegacyToCNC) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var accessData, keys, ex_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        username = username.toLowerCase();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        accessData = void 0;
                        if (!(isLegacyToCNC !== undefined && isLegacyToCNC === true)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.api.send('userverifypassword', {
                                username: username,
                                password: hashPass,
                                isLegacyToCNC: isLegacyToCNC ? true : false,
                            })];
                    case 2:
                        accessData = _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.api.send('sessionnew', {
                            username: username,
                            password: hashPass,
                            twofacode: twofaCode,
                        })];
                    case 4:
                        accessData = _a.sent();
                        _a.label = 5;
                    case 5:
                        if (accessData.success == 1 && accessData.errors) {
                            throw models_1.ErrCode.fromApiLegacy(accessData.errors);
                        }
                        return [4 /*yield*/, this.api.send('getuserkeys', {
                                username: username,
                                password: hashPass,
                                isLegacyToCNC: isLegacyToCNC ? true : false,
                            })];
                    case 6:
                        keys = _a.sent();
                        return [2 /*return*/, keys];
                    case 7:
                        ex_2 = _a.sent();
                        throw models_1.ErrCode.fromException(ex_2);
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.loginSSO = function (loginData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resp;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.logout()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.api.send('usersso', {
                                key: loginData.lookupkey,
                                twofacode: loginData.twofacode,
                            })];
                    case 2:
                        resp = _a.sent();
                        if (!(resp && resp.success == 1)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.storeKeys(resp, loginData.password)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.storeAccessData(resp.userattr)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        // startNotificationLoop
                        this.notificationsService.startNotificationLoop();
                        return [2 /*return*/, new models_1.User(resp.userattr)];
                }
            });
        });
    };
    AuthService.prototype.storeAccessData = function (accessData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var secret;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.crypt.storeEncrypt(accessData.access_secret)];
                    case 1:
                        secret = _a.sent();
                        this.store.dispatch(new CoreActions.SetValueAction({
                            key: 'access_token',
                            value: accessData.access_token,
                        }));
                        this.store.dispatch(new CoreActions.SetValueAction({
                            key: 'access_secret',
                            value: secret,
                        }));
                        return [2 /*return*/, true];
                }
            });
        });
    };
    AuthService.prototype.storeKeys = function (keys, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var meta, priv, encMeta, encPriv, ex_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.crypt.userkeyDecrypt(keys.enc_meta_key, password)];
                    case 1:
                        meta = _a.sent();
                        return [4 /*yield*/, this.crypt.userkeyDecrypt(keys.enc_priv_key, password)];
                    case 2:
                        priv = _a.sent();
                        return [4 /*yield*/, this.crypt.storeEncrypt(meta)];
                    case 3:
                        encMeta = _a.sent();
                        return [4 /*yield*/, this.crypt.storeEncrypt(priv)];
                    case 4:
                        encPriv = _a.sent();
                        this.store.dispatch(new CoreActions.SetValueAction({ key: 'meta_key', value: encMeta }));
                        this.store.dispatch(new CoreActions.SetValueAction({
                            key: 'private_key',
                            value: encPriv,
                        }));
                        return [2 /*return*/, true];
                    case 5:
                        ex_3 = _a.sent();
                        this.log.e('Error storing keys on user, key decryption failed', ex_3);
                        throw new models_1.ErrCode(6002);
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.storeJwtToken = function (token) {
        this.store.dispatch(new CoreActions.SetValueAction({
            key: 'multiadmin_jwt_token',
            value: token,
        }));
    };
    AuthService.prototype.isAuthenticated = function () {
        return rxjs_1.of(this.authenticated);
    };
    AuthService.prototype.getHashedPassword = function (username, password, noUpdate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, ex_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.api.send('getloginsalt', {
                                username_b64: this.base64.encode(username),
                            })];
                    case 1:
                        data = _a.sent();
                        return [4 /*yield*/, this.hashPassword(data.salt, password, noUpdate)];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3:
                        ex_4 = _a.sent();
                        this.log.e('getHashedPassword username or password does not exist', ex_4);
                        throw new models_1.ErrCode(6001);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.hashPassword = function (salt, password, noUpdate) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // let sha1pass
            var sha1pass = _this.digest.hash(password), mangle = _this.digest.hash256(sha1pass + sha1pass.substring(4, 24));
            var number = 0;
            _this.bCrypt.hashpw(mangle, salt, function (hashedPass) {
                // console.log('hashed password = ' + hashedPass);
                resolve(hashedPass);
            }, function () {
                if (noUpdate) {
                    return;
                }
                number += 1;
                _this.store.dispatch(new AuthActions.LoginProgressAction(number));
                // console.log('Progress is running' , number);
            });
        });
    };
    /**
     * @ngdoc method
     * @name  logout
     * @methodOf sync.service:AuthService
     * @description
     * Logs a user out and resets the state of the cp.
     *
     */
    AuthService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.log.info('Logging out user');
                        this.notificationsService.handleLogout();
                        if (!this.userService.isAuthenticated()) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.execute('sessiondelete', {})];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        ex_5 = _a.sent();
                        this.log.warn('Error logging out');
                        this.log.warn(ex_5);
                        return [3 /*break*/, 4];
                    case 4:
                        this.store.dispatch(new CoreActions.StoreClearAction());
                        this.store.dispatch(new AuthActions.LogoutAction());
                        this.store.dispatch(new FileListActions.FileListResetAction());
                        this.transferService.reset();
                        this.modalService.dismissAll();
                        this.broadcastService.broadcast('sync.reset.user');
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.ApiService), i0.inject(i2.Base64Service), i0.inject(i3.SyncCryptService), i0.inject(i4.SyncDigestService), i0.inject(i5.LoggerService), i0.inject(i6.NgbModal), i0.inject(i7.Store), i0.inject(i8.UserService), i0.inject(i9.UrlService), i0.inject(i10.TransferService), i0.inject(i11.NotificationsService), i0.inject(i12.BroadcastService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
exports.AuthService = AuthService;
