"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var logger_service_1 = require("../../core/logger.service");
var link_file_list_service_1 = require("../../link-consume/services/link-file-list.service");
var build_transfer_item_service_1 = require("../../transfer/services/build-transfer-item.service");
var transfer_model_1 = require("../../transfer/transfer.model");
var transfer_service_1 = require("../../transfer/transfer.service");
var models_1 = require("../../shared/models");
var blend_service_1 = require("../../shared/services/blend.service");
var PreviewPdfComponent = /** @class */ (function () {
    function PreviewPdfComponent(linkPathListService, transferService, buildTransferItemService, loggerService, blendService) {
        var _this = this;
        this.linkPathListService = linkPathListService;
        this.transferService = transferService;
        this.buildTransferItemService = buildTransferItemService;
        this.loggerService = loggerService;
        this.blendService = blendService;
        this.spinner = false;
        this.onCommentToggle = function (isCommentExpanded) {
            _this.isCommentExpanded = isCommentExpanded;
        };
    }
    PreviewPdfComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sub = this.linkPathListService
            .getSubscription()
            .subscribe(function (data) {
            if (data.loaded && data.sorted) {
                _this.allowComment = data.allow_comment;
            }
        });
        if (this.transferItem) {
            this.transferItem.renderFile.url = undefined;
            this.transferItem.renderFile.msblob = undefined;
            this.transferItem.renderFile.dl_ready = false;
        }
        this.reset();
        this.loadPdf();
        this.blendService.track(models_1.BlendEvent.VIEW_FILE, {
            type: 'pdf',
            preview_preference: 'N/A',
            fileSize: this.item.filesize,
            mimeType: this.item.mime_type
        });
    };
    PreviewPdfComponent.prototype.reset = function () {
        this.content = undefined;
        this.errorMsg = undefined;
        this.spinner = false;
    };
    PreviewPdfComponent.prototype.loadPdf = function () {
        var _this = this;
        this.spinner = true; // toggled in renderPdf
        var isPreview = true;
        this.transferService.getFile(this.item, isPreview).then(function (tItem) {
            if (tItem.status == transfer_model_1.TransferStatus.STATUS_ERR_SIZE ||
                tItem.status == transfer_model_1.TransferStatus.STATUS_ERR_OPEN) {
                // Logger.error('Cannot view file');
                _this.errorMsg = 'Unable to view this file.';
            }
            if (tItem.renderFile.url) {
                // this.url = tItem.renderFile.url;
                _this.loggerService.info('loadPdf success for ' +
                    _this.item.sync_id +
                    '  ' +
                    tItem.renderFile.url);
                _this.renderPdf(tItem.renderFile.url);
            }
        }, function (err) {
            _this.spinner = false;
            _this.errorMsg = 'An error occurred loading the preview';
            _this.loggerService.error('loadPdf failed  for ' + _this.item.sync_id);
            if (typeof err === 'object') {
                _this.loggerService.error(JSON.stringify(err));
            }
            else if (typeof err === 'string') {
                _this.loggerService.error(err);
            }
            else {
                _this.loggerService.error('Unknown error occurred');
            }
        });
    };
    PreviewPdfComponent.prototype.renderPdf = function (url) {
        var _this = this;
        if (!PDFJS.PDFViewer || !PDFJS.getDocument) {
            alert('PDFJS is not currently installed');
        }
        PDFJS.workerSrc = '/workers/pdf.worker.min.js';
        PDFJS.imageResourcesPath = '/assets/pdfjs/images/';
        // var SEARCH_FOR = ''; // try 'Mozilla';
        var container = document.getElementById('viewerContainer');
        // // (Optionally) enable hyperlinks within PDF files.
        var pdfLinkService = new PDFJS.PDFLinkService();
        var pdfViewer = new PDFJS.PDFViewer({
            container: container,
            linkService: pdfLinkService,
        });
        pdfLinkService.setViewer(pdfViewer);
        // (Optionally) enable find controller.
        // var pdfFindController = new PDFJS.PDFFindController({
        //     pdfViewer: pdfViewer
        // });
        // pdfViewer.setFindController(pdfFindController);
        container.addEventListener('pagesinit', function () {
            // We can use pdfViewer now, e.g. let's change default scale.
            pdfViewer.currentScaleValue = 'page-width';
            // if (SEARCH_FOR) { // We can try search for things
            // pdfFindController.executeCommand('find', {query: SEARCH_FOR});
            // }
        });
        // Loading document.
        PDFJS.getDocument(url).then(function (pdfDocument) {
            // Document loaded, specifying document for the viewer and
            // the (optional) linkService.
            // console.log('getting document ' , DEFAULT_URL, ' pdfDocument = ', pdfDocument)
            pdfViewer.setDocument(pdfDocument);
            pdfLinkService.setDocument(pdfDocument, null);
            _this.spinner = false;
        }, function (err) {
            if (typeof err === 'object') {
                _this.loggerService.error(JSON.stringify(err));
                if (err.name == 'InvalidPDFException') {
                    _this.errorMsg =
                        'Failed to load the document - PDF was invalid nor not downloaded correctly (' +
                            err.message +
                            ')';
                }
                else {
                    _this.errorMsg = 'Failed to load the document';
                }
            }
            else if (typeof err === 'string') {
                _this.loggerService.error(err);
            }
            else {
                _this.errorMsg = 'Failed to load the document';
                _this.loggerService.error('Unknown error occurred');
            }
            _this.spinner = false;
        });
    };
    PreviewPdfComponent.prototype.ngOnDestroy = function () {
        // must reset this since it never gets unset and causes old images
        // to briefly flash on screen.
        this.buildTransferItemService.view.single = undefined;
    };
    return PreviewPdfComponent;
}());
exports.PreviewPdfComponent = PreviewPdfComponent;
