"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var logger_service_1 = require("../logger.service");
var storage_filesystem_service_1 = require("./storage-filesystem.service");
var storage_memory_service_1 = require("./storage-memory.service");
var browser_support_service_1 = require("../browser-support.service");
var i0 = require("@angular/core");
var i1 = require("../browser-support.service");
var i2 = require("../logger.service");
var i3 = require("./storage-filesystem.service");
var i4 = require("./storage-memory.service");
var StorageService = /** @class */ (function () {
    function StorageService(browser, log, storefs, storemem) {
        this.browser = browser;
        this.log = log;
        this.storefs = storefs;
        this.storemem = storemem;
    }
    StorageService.prototype.prepare = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var method, _a, err_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.log.info('Preparing storage service');
                        if (this._Storage) {
                            this.log.info('Storage is set');
                            return [2 /*return*/];
                        }
                        method = this.browser.getFileStorageMethod();
                        _a = method;
                        switch (_a) {
                            case 'filesystem': return [3 /*break*/, 1];
                            case 'memory': return [3 /*break*/, 5];
                        }
                        return [3 /*break*/, 5];
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        this.log.info('TRANSER: testing filesystem');
                        return [4 /*yield*/, this.storefs.test()];
                    case 2:
                        _b.sent();
                        this.log.info("TRANSFER STORAGE: 'filesystem'");
                        this._Storage = this.storefs;
                        this.maxsize = this.storefs.maxsize;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _b.sent();
                        this.log.info('TRANSFER STORAGE: memory-filesystemfail');
                        this.log.warn('Detected chrome incognito, falling back to memory');
                        this.log.warn(err_1);
                        this._Storage = this.storemem;
                        this.maxsize = this.storemem.maxsize;
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this._Storage = this.storemem;
                        this.maxsize = this.storemem.maxsize;
                        this.log.info("TRANSFER STORAGE: 'memory'");
                        return [3 /*break*/, 6];
                    case 6:
                        this.log.info('TRANSFER SAVE: ' + this.browser.getFileSaveMethod());
                        this.log.info('storage init completed');
                        return [2 /*return*/];
                }
            });
        });
    };
    StorageService.prototype.getMemoryStorage = function () {
        return this.storemem;
    };
    StorageService.prototype.getDefaultStorage = function () {
        return this._Storage;
    };
    StorageService.ngInjectableDef = i0.defineInjectable({ factory: function StorageService_Factory() { return new StorageService(i0.inject(i1.BrowserSupportService), i0.inject(i2.LoggerService), i0.inject(i3.StorageFilesystemService), i0.inject(i4.StorageMemoryService)); }, token: StorageService, providedIn: "root" });
    return StorageService;
}());
exports.StorageService = StorageService;
