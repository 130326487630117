"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var link_file_list_service_1 = require("./../../link-consume/services/link-file-list.service");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var base64_service_1 = require("../../core/base64.service");
var api_service_1 = require("../../core/api.service");
var user_service_1 = require("../../core/user.service");
var forms_1 = require("@angular/forms");
var logger_service_1 = require("../../core/logger.service");
var store_1 = require("@ngrx/store");
var LinkListActions = require("../../actions/link-list.actions");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var router_1 = require("@angular/router");
var blend_service_1 = require("../../shared/services/blend.service");
var blend_property_names_1 = require("../../util/blend-property-names");
var file_item_service_1 = require("../../file/file-item.service");
var LinkSettingsComponent = /** @class */ (function () {
    function LinkSettingsComponent(api, base64, fb, log, store, userService, router, crypt, linkFileList, blendService, fileItemService) {
        this.api = api;
        this.base64 = base64;
        this.fb = fb;
        this.log = log;
        this.store = store;
        this.userService = userService;
        this.router = router;
        this.crypt = crypt;
        this.linkFileList = linkFileList;
        this.blendService = blendService;
        this.fileItemService = fileItemService;
        this.state = new core_1.EventEmitter();
        this.close = new core_1.EventEmitter();
        this.isPro = false;
        this.spinner = false;
        this.hasComment = false;
        this.hasBetaAccess = false;
        this.isAppLink = false;
        this.isMSOfficeFile = false;
    }
    LinkSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var dt = new Date();
        this.expDateMin = {
            year: dt.getFullYear(),
            month: dt.getMonth() + 1,
            day: dt.getDate(),
        };
        this.isMSOfficeFile = this.fileItemService.isMSOffice(this.item, 'edit');
        this.isPro = this.userService.get('is_pro');
        this.hasComment = this.userService.get('has_comment');
        this.hasBetaAccess = this.userService.get('has_beta_access');
        this.isAppLink = this.link.baseUrl.includes('/al');
        console.log('beta:', this.hasBetaAccess);
        console.log('hascomment:', this.hasComment);
        console.log('applink:', this.link.baseUrl.includes('/al'));
        if (this.link.exp_servtime) {
            var exp = new Date(this.link.exp_servtime);
            this.expDate = {
                year: exp.getFullYear(),
                month: exp.getMonth() + 1,
                day: exp.getDate(),
            };
            this.expTime = {
                hour: exp.getHours(),
                minute: exp.getMinutes(),
                second: 0,
            };
        }
        //if file_edit is enabled, file is not shared and file is MSOffice editable, enable the third radio button for edit file in file permissions
        //else check previewonly link setting and enable preview/download radio button
        if (this.link.file_edit === 1 && (!this.item.is_shared && (this.link.type != 'file' || this.isMSOfficeFile))) {
            this.previewFieldValue = 'edit';
        }
        else {
            this.previewFieldValue = this.link.previewonly === 0 ? 'download' : 'preview';
        }
        this.linkForm = this.fb.group({
            label: [this.base64.decode(this.link.label)],
            passwordprotect: [this.link.passwordprotect],
            key: [this.link.key],
            upload: [this.link.upload.toString() || '0', forms_1.Validators.required],
            previewonly: [
                this.previewFieldValue,
                forms_1.Validators.required,
            ],
            download_limit: [this.link.download_limit],
            email_notification: [
                this.link.email_notification.toString() || '0',
                forms_1.Validators.required,
            ],
            compat: [this.link.compat.toString() || '0', forms_1.Validators.required],
            exp_date: [this.expDate],
            exp_time: [this.expTime],
            allow_comment: [
                this.link.allow_comment.toString() || '1',
                forms_1.Validators.required,
            ],
            comment_notification: [
                this.link.comment_notification.toString() || '1',
                forms_1.Validators.required,
            ],
        });
        this.linkForm.controls.upload.valueChanges.subscribe(function (uploadFieldValue) {
            // file permission radio buttons are disbled according to the upload radio values
            // reset previewonly field value when all file permission radio buttons are disabled
            // or when the edit file radio button is disabled
            if (_this.link.type === 'dir') {
                if (uploadFieldValue === '2' || (uploadFieldValue === '0' && _this.linkForm.value.previewonly === 'edit')) {
                    _this.linkForm.controls.previewonly.setValue(_this.link.previewonly === 0 ? 'download' : 'preview');
                }
            }
        });
    };
    LinkSettingsComponent.prototype.onDateSelection = function (date) {
        if (!this.link.exp_servtime) {
            var dt = new Date();
            this.linkForm.get('exp_time').setValue({
                hour: dt.getHours(),
                minute: dt.getMinutes(),
                second: 0,
            });
        }
    };
    LinkSettingsComponent.prototype.clearExp = function () {
        this.linkForm.get('exp_date').setValue(null);
        this.linkForm.get('exp_time').setValue(null);
    };
    LinkSettingsComponent.prototype.viewUpgrades = function () {
        this.blendService.addEventsForUpgradeButton();
        this.router.navigate(['/account/upgrade']);
        this.close.emit(false);
    };
    /**
     * @ngdoc method
     * @name  setLinkOptions
     * @methodOf sync.service:Link
     * @description
     * Sets options on the link.
     * @return {Promise} Resolves the API call
     */
    LinkSettingsComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var expDate, expTime, expServtime, downloadLimit, exp, dt, allowComment, linkSetOptions, password, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        this.errcode = null;
                        this.spinner = true;
                        expDate = this.linkForm.get('exp_date').value;
                        expTime = this.linkForm.get('exp_time').value;
                        expServtime = void 0;
                        downloadLimit = this.linkForm.get('download_limit').value;
                        if (expDate) {
                            if (!expTime) {
                                exp = new Date();
                                expTime = {
                                    hour: exp.getHours(),
                                    minute: exp.getMinutes(),
                                    second: 0,
                                };
                            }
                            dt = new Date(expDate.year, expDate.month - 1, expDate.day, expTime.hour, expTime.minute, expTime.second);
                            expServtime = dt.getTime();
                        }
                        if (downloadLimit === undefined) {
                            downloadLimit = 0;
                        }
                        allowComment = parseInt(this.linkForm.get('allow_comment').value, 10);
                        linkSetOptions = {
                            sync_id: this.link.sync_id,
                            share_id: this.link.share_id,
                            publink_id: this.link.cachekey,
                            label: this.base64.encode(this.linkForm.get('label').value),
                            exp_servtime: parseInt(expServtime, 10),
                            download_limit: parseInt(downloadLimit, 10),
                            email_notification: parseInt(this.linkForm.get('email_notification').value, 10),
                            upload: parseInt(this.linkForm.get('upload').value, 10),
                            // if preview only option is selected from file permissions, set previewonly in link options
                            previewonly: this.linkForm.value.previewonly === 'preview' ? 1 : 0,
                            // if edit option is selected from file permissions, set file_edit in link options
                            // this will set the lnkFILEEDIT in LinkFeatures
                            file_edit: this.linkForm.value.previewonly === 'edit' ? 1 : 0,
                            compat: parseInt(this.linkForm.get('compat').value, 10),
                            allow_comment: allowComment,
                            comment_notification: parseInt(this.linkForm.get('comment_notification').value, 10)
                        };
                        // if the link is to a file and edit permission is enabled, enable the upload permission as well
                        if (this.link.type === 'file') {
                            linkSetOptions.upload = this.linkForm.value.previewonly === 'edit' ? 1 : 0;
                        }
                        return [4 /*yield*/, this.api.execute('linksetoptions', linkSetOptions)];
                    case 1:
                        _a.sent();
                        password = this.base64.encode(this.linkForm.get('passwordprotect').value);
                        return [4 /*yield*/, this.api.execute('linksetpassword', {
                                sync_id: this.link.sync_id,
                                share_id: this.link.share_id,
                                publink_id: this.link.cachekey,
                                password: password,
                            })];
                    case 2:
                        _a.sent();
                        this.blendService.track(models_1.BlendEvent.SAVE_LINK_SETTINGS, blend_property_names_1.renameLinkOptions(tslib_1.__assign({}, linkSetOptions, { type: this.link.type, file_extension: this.item.file_extension })));
                        this.log.info('Saved link for ' + this.link.cachekey);
                        this.store.dispatch(new LinkListActions.LinkListRefreshAction());
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _a.sent();
                        this.log.e('Error saving link settings for ' + this.link.cachekey, ex_1);
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.spinner = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    LinkSettingsComponent.prototype.onState = function (state) {
        this.state.emit(state);
    };
    return LinkSettingsComponent;
}());
exports.LinkSettingsComponent = LinkSettingsComponent;
