import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BatchPurgeService } from '../batch-purge.service';
import { FeedbackService } from '../../notifications/feedback.service';

@Component({
    selector: 'sync-dialog-purge',
    templateUrl: './dialog-purge.component.html'
})
export class DialogPurgeComponent implements OnInit {
    public dlgView: sync.IBatchDialogView;
    public batchView: sync.IBatchActionView;
    @Input() selected: sync.IFile[] = [];

    constructor(
        protected batchPurgeService: BatchPurgeService,
        public activeModal: NgbActiveModal,
        private feedbackService: FeedbackService
    ) {}

    ngOnInit() {
        this.batchPurgeService.init();
        this.batchView = this.batchPurgeService.view;
        this.dlgView = {
            itemlist: this.selected,
            action: 'purge',
            title: 'Purge items',
            btnExecuteText: 'Purge',
            btnExecuteClass: 'btn btn-danger',
            confirm: {
                check: false,
                show: true,
                notices: [],
                txt: ' I understand I am permanently deleting these items.',
            },
        };
    }

    public getClass(syncID: number) {
        return this.batchPurgeService.isCompleted(syncID) ? 'purged' : 'deleted';
    }

    public closeDialog() {
        if (this.batchPurgeService.view.spinner !== 0) {
            const c = window.confirm('Are you sure you want to cancel?');
            if (c) {
                this.batchPurgeService.cancel();
                this.activeModal.close();
            }
        } else {
            this.activeModal.close();
        }
    }

    public removeFromList(listitem: sync.IFile) {
        for (let i = this.dlgView.itemlist.length - 1; i >= 0; i--) {
            if (this.dlgView.itemlist[i].sync_id == listitem.sync_id) {
                this.dlgView.itemlist.splice(i, 1);
                break;
            }
        }
    }

    public async execute() {
        if (this.dlgView.confirm.show && !this.dlgView.confirm.check) {
            this.dlgView.confirm.notices = [
                {
                    type: 'warning',
                    msg: 'You must confirm this change before clicking purge.',
                },
            ];
            return;
        }
        const sync_id_array: number[] = [];
        let files = 0,
            directories = 0;
        for (let i = this.dlgView.itemlist.length - 1; i >= 0; i--) {
            const element = this.dlgView.itemlist[i];
            sync_id_array.push(element.sync_id);
            if (element.type && element.type.toUpperCase() === 'DIR') {
                directories++;
            } else {
                files++;
            }
        }

        try {
            await this.batchPurgeService.execute(sync_id_array).toPromise();
        } catch (err) {
            if (err.code) {
                this.batchView.errors.push(err);
            }
            this.batchView.spinner = 0;
            let errorMsg = `${this.dlgView.itemlist.length} file${files > 1 ? 's' : ''} failed to purge`;
            if (directories && files) {
                errorMsg = `${this.dlgView.itemlist.length} items failed to purge`;
            } else if (directories) {
                errorMsg = `${this.dlgView.itemlist.length} folder${directories > 1 ? 's' : ''} failed to purge`;
            }
            this.feedbackService.setFeedback('danger', errorMsg, 10000);
        } finally {
            this.activeModal.close();
        }
    }

    public confirmAction() {
        this.dlgView.confirm.notices = [];
        return;
    }
}
