"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Actions = require("../actions/file-list.actions");
var func_1 = require("../shared/func");
exports.initialState = {
    cwd: undefined,
    hist_id: 0,
    parents: [],
    pathlist: [],
    sync_id: 0,
    error: undefined,
    progress: 0,
    loaded: false,
    sorted: false,
    showdeleted: false,
    deletedAmt: 0,
    predicate: 'default',
    direction: '',
    is_vault: false,
    total_favorite: 0
};
function reducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case Actions.FILE_LIST_RESET:
            return exports.initialState;
        case Actions.FILE_LIST_SUCCESS:
            return Object.assign({}, state, {
                progress: 100,
                loaded: true,
                pathlist: action.payload
            });
        case Actions.FILE_LIST_ERROR:
            console.error('An error occurred');
            return Object.assign({}, state, { error: action.payload });
        case Actions.FILE_LIST_SET_CWD:
            return Object.assign({}, state, { cwd: action.payload });
        case Actions.FILE_LIST_SET_PARENTS:
            return Object.assign({}, state, { parents: action.payload });
        case Actions.FILE_LIST_SET_PROGRESS:
            return Object.assign({}, state, { progress: action.payload });
        case Actions.FILE_LIST_SET_HIST_ID:
            return Object.assign({}, state, { hist_id: action.payload });
        case Actions.FILE_LIST_REFRESH:
            return Object.assign({}, state, {
                loaded: false,
                sorted: false,
                hist_id: action.payload.hist_id,
                sync_id: action.payload.sync_id,
                showdeleted: (action.payload.showdeleted > 0),
                is_vault: action.payload.is_vault
            });
        case Actions.FILE_LIST_RELOAD:
            return Object.assign({}, state, {
                hist_id: 0,
                loaded: false,
                sorted: false,
            });
        case Actions.FILE_LIST_SHOW_DELETED:
            return Object.assign({}, state, {
                showdeleted: !state.showdeleted,
                loaded: false,
                sorted: false
            });
        case Actions.FILE_LIST_SELECT:
            var orig = state.pathlist.slice(0);
            for (var i = 0, len = orig.length; i < len; i++) {
                if (orig[i].sync_id == action.payload.sync_id) {
                    orig[i].active = true;
                }
            }
            return Object.assign({}, state, {
                pathlist: orig
            });
        case Actions.FILE_LIST_SELECT_TOGGLE:
            var origToggle = state.pathlist.slice(0);
            for (var i = 0, len = origToggle.length; i < len; i++) {
                if (origToggle[i].sync_id == action.payload.sync_id) {
                    origToggle[i].active = !origToggle[i].active;
                }
            }
            return Object.assign({}, state, {
                pathlist: origToggle
            });
        case Actions.FILE_LIST_SELECT_ALL:
            var origAll = state.pathlist.slice(0);
            for (var i = 0, len = origAll.length; i < len; i++) {
                if (action.payload.active && action.payload.filteredIds && action.payload.filteredIds.length) {
                    if (action.payload.filteredIds.indexOf(origAll[i].id) > -1) {
                        origAll[i].active = true;
                    }
                    else {
                        origAll[i].active = false;
                    }
                }
                else {
                    origAll[i].active = action.payload.active;
                }
            }
            return Object.assign({}, state, {
                pathlist: origAll
            });
        case Actions.FILE_LIST_SELECT_TYPE:
            var origType = state.pathlist.slice(0);
            var key = action.payload.key;
            var value = action.payload.value;
            for (var i = 0, len = origType.length; i < len; i++) {
                if (action.payload.filteredIds && action.payload.filteredIds.length) {
                    if (action.payload.filteredIds.indexOf(origType[i].id) > -1 && origType[i][key] == value) {
                        origType[i].active = true;
                    }
                    else {
                        origType[i].active = false;
                    }
                }
                else {
                    if (origType[i][key] == value) {
                        origType[i].active = true;
                    }
                }
            }
            return Object.assign({}, state, {
                pathlist: origType
            });
        case Actions.FILE_LIST_SORT:
            var isAsc_1 = action.payload.direction === 'asc';
            var newlist = state.pathlist.slice(0);
            if (action.payload.direction === '') {
                newlist.sort(function (a, b) { return func_1.compareWebPath(a, b, isAsc_1, true); });
            }
            else {
                switch (action.payload.active) {
                    case 'name':
                        newlist.sort(function (a, b) { return func_1.compareWebPath(a, b, isAsc_1, false); });
                        break;
                    case 'usertime':
                        newlist.sort(function (a, b) { return func_1.compare(a.usertime, b.usertime, isAsc_1); });
                        break;
                    case 'size':
                        newlist.sort(function (a, b) { return (a.is_dir - b.is_dir) || func_1.compare(a.size, b.size, isAsc_1); });
                        break;
                    case 'default':
                        newlist.sort(function (a, b) { return func_1.compareWebPath(a, b, isAsc_1, true); });
                        break;
                    default:
                        newlist.sort(function (a, b) { return func_1.compareWebPath(a, b, isAsc_1, true); });
                        break;
                }
            }
            return Object.assign({}, state, {
                pathlist: newlist,
                sorted: true,
                predicate: action.payload.active,
                direction: action.payload.direction
            });
        case Actions.FILE_LIST_TOTAL_FAVORITE:
            return Object.assign({}, state, {
                total_favorite: action.payload,
            });
        default:
            return state;
    }
}
exports.reducer = reducer;
exports.getCwd = function (state) { return state.cwd; };
// export const getParents = (state: State) => state.parents;
// export const getAuthenticated = (state: State) => state.authenticated;
// export const getUser = (state: State) => state.user;
// export const getLoginProgress = (state: State) => state.progress;
// export const getErrCode = (state: State) => state.error;
