"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var SupportTicketService = /** @class */ (function () {
    function SupportTicketService() {
    }
    SupportTicketService.prototype.sendSupportTicket = function (params) {
        var key, field;
        var form = document.createElement('form');
        form.setAttribute('method', 'POST');
        form.setAttribute('action', 'https://www.sync.com/support-submit.php');
        for (key in params) {
            if (params.hasOwnProperty(key)) {
                if (key == 'z_description') {
                    field = document.createElement('textarea');
                    field.setAttribute('name', key);
                    field.value = params[key];
                    // field.setAttribute('value', params[key]);
                }
                else {
                    field = document.createElement('input');
                    field.setAttribute('type', 'hidden');
                    field.setAttribute('name', key);
                    field.setAttribute('value', params[key]);
                }
                form.appendChild(field);
            }
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    };
    SupportTicketService.ngInjectableDef = i0.defineInjectable({ factory: function SupportTicketService_Factory() { return new SupportTicketService(); }, token: SupportTicketService, providedIn: "root" });
    return SupportTicketService;
}());
exports.SupportTicketService = SupportTicketService;
