"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var PlanDetailsApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(PlanDetailsApiOutput, _super);
    function PlanDetailsApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PlanDetailsApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.PlanDetailsApiOutput = PlanDetailsApiOutput;
var PlanTerm;
(function (PlanTerm) {
    PlanTerm["MONTHLY"] = "MONTHLY";
    PlanTerm["ANNUAL"] = "ANNUAL";
})(PlanTerm = exports.PlanTerm || (exports.PlanTerm = {}));
