"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base64_service_1 = require("../core/base64.service");
var sync_crypt_service_1 = require("../core/crypt/sync-crypt.service");
var logger_service_1 = require("../core/logger.service");
var event_item_service_1 = require("./event-item.service");
var events_api_service_1 = require("./events-api.service");
var i0 = require("@angular/core");
var i1 = require("./events-api.service");
var i2 = require("../core/logger.service");
var i3 = require("../core/crypt/sync-crypt.service");
var i4 = require("../core/base64.service");
var i5 = require("./event-item.service");
var EventsListService = /** @class */ (function () {
    function EventsListService(eventsApiService, loggerService, syncCryptService, base64Service, eventItemService) {
        this.eventsApiService = eventsApiService;
        this.loggerService = loggerService;
        this.syncCryptService = syncCryptService;
        this.base64Service = base64Service;
        this.eventItemService = eventItemService;
    }
    EventsListService.prototype.isFile = function (synctype) {
        return synctype.indexOf('stDIR') == -1;
    };
    EventsListService.prototype.getList = function (opts) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var apiResult, data, eventList, shareList, errData_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        apiResult = void 0;
                        return [4 /*yield*/, this.eventsApiService.getList(opts)];
                    case 1:
                        data = _a.sent();
                        apiResult = data;
                        return [4 /*yield*/, this.processEventList(data.events)];
                    case 2:
                        eventList = _a.sent();
                        apiResult.events = eventList;
                        return [4 /*yield*/, this.processShareList(apiResult.shares)];
                    case 3:
                        shareList = _a.sent();
                        apiResult.shares = shareList;
                        return [2 /*return*/, apiResult];
                    case 4:
                        errData_1 = _a.sent();
                        this.loggerService.apiError(errData_1);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    EventsListService.prototype.processShareList = function (list) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var shareList, i, len;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shareList = [];
                        for (i = 0, len = list.length; i < len; i++) {
                            shareList.push(this._shareItem(list[i]));
                        }
                        return [4 /*yield*/, Promise.all(shareList)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    EventsListService.prototype._shareItem = function (shareitem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sname, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.syncCryptService.filenameDecrypt(shareitem.enc_meta_name)];
                    case 1:
                        sname = _a.sent();
                        shareitem.name = sname;
                        shareitem.label = this.base64Service.decode(shareitem.label);
                        shareitem.display_name = shareitem.label
                            ? sname + " [ " + shareitem.label + " ]"
                            : sname;
                        return [2 /*return*/, shareitem];
                    case 2:
                        error_1 = _a.sent();
                        this.loggerService.error('Error decrypting share name for ' + shareitem.share_id);
                        shareitem.display_name =
                            this.base64Service.decode(shareitem.label) ||
                                shareitem.share_id;
                        return [2 /*return*/, shareitem];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    EventsListService.prototype.shouldExclude = function (event) {
        return false;
    };
    EventsListService.prototype.processEventList = function (events) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var eventItems, i, len;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        eventItems = [];
                        for (i = 0, len = events.length; i < len; i++) {
                            if (!this.shouldExclude(events[i])) {
                                eventItems.push(this.eventItemService.format(events[i]));
                            }
                        }
                        return [4 /*yield*/, Promise.all(eventItems)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    EventsListService.ngInjectableDef = i0.defineInjectable({ factory: function EventsListService_Factory() { return new EventsListService(i0.inject(i1.EventsApiService), i0.inject(i2.LoggerService), i0.inject(i3.SyncCryptService), i0.inject(i4.Base64Service), i0.inject(i5.EventItemService)); }, token: EventsListService, providedIn: "root" });
    return EventsListService;
}());
exports.EventsListService = EventsListService;
