"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var UserSsoApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(UserSsoApiOutput, _super);
    function UserSsoApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserSsoApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.UserSsoApiOutput = UserSsoApiOutput;
var UserSsoApiInput = /** @class */ (function (_super) {
    tslib_1.__extends(UserSsoApiInput, _super);
    function UserSsoApiInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserSsoApiInput;
}(base_api_model_1.BaseApiInput));
exports.UserSsoApiInput = UserSsoApiInput;
