"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FooterLayoutComponent = /** @class */ (function () {
    function FooterLayoutComponent() {
    }
    FooterLayoutComponent.prototype.ngOnInit = function () {
    };
    return FooterLayoutComponent;
}());
exports.FooterLayoutComponent = FooterLayoutComponent;
