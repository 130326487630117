"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var SyncShowHidePasswordDirective = /** @class */ (function () {
    function SyncShowHidePasswordDirective(el) {
        this.el = el;
        this._shown = false;
        this.eye = "<i alt=\"show\" class=\"fa fa-eye\"></i>";
        this.eyeSlash = "<i alt=\"hide\" class=\"fa fa-eye-slash\"></i>";
    }
    SyncShowHidePasswordDirective.prototype.ngAfterViewInit = function () {
        this.setup();
    };
    SyncShowHidePasswordDirective.prototype.toggle = function (span) {
        this._shown = !this._shown;
        this.el.nativeElement.setAttribute('type', this._shown ? 'text' : 'password');
        span.innerHTML = this._shown ? this.eye : this.eyeSlash;
    };
    SyncShowHidePasswordDirective.prototype.setup = function () {
        var _this = this;
        var parent = this.el.nativeElement.parentNode;
        var span = document.createElement('span');
        span.classList.add('showhideIcon');
        span.innerHTML = this.eyeSlash;
        span.addEventListener('click', function () {
            _this.toggle(span);
        });
        parent.classList.add('passcover');
        parent.classList.add(this.identity);
        parent.appendChild(span);
    };
    return SyncShowHidePasswordDirective;
}());
exports.SyncShowHidePasswordDirective = SyncShowHidePasswordDirective;
