"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var store_1 = require("@ngrx/store");
var effects_1 = require("@ngrx/effects");
var FileListActions = require("../actions/file-list.actions");
var file_list_service_1 = require("../file-list/services/file-list.service");
var starred_list_service_1 = require("../file-list/services/starred-list.service");
var reducers_1 = require("../reducers");
var FileListEffects = /** @class */ (function () {
    function FileListEffects(files, starred, actions$, store$) {
        var _this = this;
        this.files = files;
        this.starred = starred;
        this.actions$ = actions$;
        this.store$ = store$;
        this.refresh$ = this.actions$.pipe(effects_1.ofType(FileListActions.FILE_LIST_REFRESH), operators_1.mergeMap(function (action) {
            return rxjs_1.from(_this.files.getData(action.payload.sync_id, action.payload.hist_id, action.payload.showdeleted, action.payload.is_vault)).pipe(operators_1.map(function (data) { return new FileListActions.FileListSuccessAction(data); }), operators_1.catchError(function (err) {
                return rxjs_1.of(new FileListActions.FileListErrorAction(err));
            }));
        }));
        this.reload$ = this.actions$.pipe(effects_1.ofType(FileListActions.FILE_LIST_RELOAD), operators_1.withLatestFrom(this.store$.select(reducers_1.getFileListState)), operators_1.map(function (_a) {
            var action = _a[0], storeState = _a[1];
            return new FileListActions.FileListRefreshAction({
                sync_id: storeState.sync_id,
                hist_id: storeState.hist_id,
                showdeleted: storeState.showdeleted ? 1 : 0,
                offset_metaname_digest: '0',
                is_vault: storeState.is_vault
            });
        }));
        this.success$ = this.actions$.pipe(effects_1.ofType(FileListActions.FILE_LIST_SUCCESS), operators_1.withLatestFrom(this.store$.select(reducers_1.getFileListState)), operators_1.map(function (_a) {
            var action = _a[0], storeState = _a[1];
            return new FileListActions.FileListSortAction({
                active: storeState.predicate,
                direction: storeState.direction,
            });
        }));
        this.showDeleted$ = this.actions$.pipe(effects_1.ofType(FileListActions.FILE_LIST_SHOW_DELETED), operators_1.withLatestFrom(this.store$.select(reducers_1.getFileListState)), operators_1.map(function (_a) {
            var action = _a[0], storeState = _a[1];
            return new FileListActions.FileListRefreshAction({
                sync_id: storeState.sync_id,
                hist_id: storeState.hist_id,
                showdeleted: storeState.showdeleted ? 1 : 0,
                offset_metaname_digest: '0',
                is_vault: storeState.is_vault
            });
        }));
        this.showFavorites$ = this.actions$.pipe(effects_1.ofType(FileListActions.FILE_LIST_SHOW_FAVORITES), operators_1.mergeMap(function (action) {
            return rxjs_1.from(_this.starred.showFavoritesList(action.payload.syncId)).pipe(operators_1.map(function (data) { return new FileListActions.FileListSuccessAction(data); }), operators_1.catchError(function (err) {
                return rxjs_1.of(new FileListActions.FileListErrorAction(err));
            }));
        }));
    }
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], FileListEffects.prototype, "refresh$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], FileListEffects.prototype, "reload$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], FileListEffects.prototype, "success$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], FileListEffects.prototype, "showDeleted$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], FileListEffects.prototype, "showFavorites$", void 0);
    return FileListEffects;
}());
exports.FileListEffects = FileListEffects;
