"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_service_1 = require("../core/api.service");
var i0 = require("@angular/core");
var i1 = require("../core/api.service");
var EventsApiService = /** @class */ (function () {
    function EventsApiService(apiService) {
        this.apiService = apiService;
    }
    EventsApiService.prototype.getList = function (opts) {
        var params = {};
        if (opts) {
            if (opts._id) {
                params._id = opts._id;
            }
            if (opts.shareId) {
                params.share_id = opts.shareId;
            }
            if (opts.date) {
                params.date = opts.date;
            }
            if (opts.lastServtime) {
                params.lt_servtime = opts.lastServtime;
            }
            if (opts.sliceOffset) {
                params.slice_offset = opts.sliceOffset;
            }
            if (opts.limit) {
                params.limit = opts.limit;
            }
            if (opts.eventTypes) {
                params.event_types = opts.eventTypes;
            }
        }
        return this.apiService.execute('eventslist', params);
    };
    EventsApiService.ngInjectableDef = i0.defineInjectable({ factory: function EventsApiService_Factory() { return new EventsApiService(i0.inject(i1.ApiService)); }, token: EventsApiService, providedIn: "root" });
    return EventsApiService;
}());
exports.EventsApiService = EventsApiService;
