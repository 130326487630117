"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var EmailExistsApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(EmailExistsApiOutput, _super);
    function EmailExistsApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmailExistsApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.EmailExistsApiOutput = EmailExistsApiOutput;
var EmailExistsApiInput = /** @class */ (function () {
    function EmailExistsApiInput() {
    }
    return EmailExistsApiInput;
}());
exports.EmailExistsApiInput = EmailExistsApiInput;
var NewOrderResponse = /** @class */ (function (_super) {
    tslib_1.__extends(NewOrderResponse, _super);
    function NewOrderResponse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return NewOrderResponse;
}(base_api_model_1.BaseApiOutput));
exports.NewOrderResponse = NewOrderResponse;
