"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var dispatch_service_1 = require("../../core/dispatch.service");
var api_service_1 = require("../../core/api.service");
var base64_service_1 = require("../../core/base64.service");
var feedback_service_1 = require("../../notifications/feedback.service");
var share_list_service_1 = require("../share-list.service");
var logger_service_1 = require("../../core/logger.service");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var share_new_service_1 = require("../share-new.service");
var share_service_1 = require("../share.service");
var broadcast_service_1 = require("../../shared/services/broadcast.service");
var router_1 = require("@angular/router");
var user_service_1 = require("../../core/user.service");
var file_item_service_1 = require("src/app/file/file-item.service");
var models_1 = require("src/app/shared/models");
var ShareListComponent = /** @class */ (function () {
    function ShareListComponent(Dispatch, api, Base64, feedbackService, shareListService, fileItemService, loggerService, syncCryptService, shareService, shareNewService, broadcastService, router, userService) {
        this.Dispatch = Dispatch;
        this.api = api;
        this.Base64 = Base64;
        this.feedbackService = feedbackService;
        this.shareListService = shareListService;
        this.fileItemService = fileItemService;
        this.loggerService = loggerService;
        this.syncCryptService = syncCryptService;
        this.shareService = shareService;
        this.shareNewService = shareNewService;
        this.broadcastService = broadcastService;
        this.router = router;
        this.userService = userService;
        this.filter = '';
        this.showFilter = false;
        this.isLoaded = false;
        this.showInfo = false;
        this.showRemoved = false;
        this.predicate = 'name';
        this.predicateReverse = false;
        this.isTeamsPlusUser = false;
        this.shareListInProgress = false;
        this.spinners = {
            confirm: 0,
            remove: false,
        };
    }
    ShareListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isTeamsPlusUser =
            this.userService.get('is_multi_new') &&
                this.userService.get('is_unlimited_plan')
                ? true
                : false;
        this.loadShare();
        // The notifications is running this code here.
        this.shareListReloadSub = this.broadcastService.on('event:share-list.reload').subscribe(function () {
            console.log('event reload');
            _this.loadShare();
        });
    };
    ShareListComponent.prototype.ngOnDestroy = function () {
        if (this.shareListReloadSub) {
            this.shareListReloadSub.unsubscribe();
        }
    };
    ShareListComponent.prototype.trackByFn = function (index, share) {
        return share.uniqid;
    };
    ShareListComponent.prototype.hasShareFolderPermission = function () {
        return this.userService.getPermssionByKey(models_1.PermissionActions.SHARE_FOLDERS);
    };
    ShareListComponent.prototype.confirmUser = function (share) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var inviteKey;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinners.confirm = share.shareId;
                        return [4 /*yield*/, this.shareNewService.sanitizeShare(share.share_id)];
                    case 1:
                        inviteKey = _a.sent();
                        return [4 /*yield*/, this.confirmUserApi(share, inviteKey)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.loadShare()];
                    case 3:
                        _a.sent();
                        this.spinners.confirm = 0;
                        this.feedbackService.setFeedback('success', "An email has been sent to " + share.email + " to accept the share", 5000);
                        return [2 /*return*/];
                }
            });
        });
    };
    /* API call for confirm user */
    ShareListComponent.prototype.confirmUserApi = function (share, inviteKey) {
        return this.api.execute('sharemailinvite', {
            share_id: share.share_id,
            emaillist: [share.email],
            enc_password: '',
            pm_b64: this.Base64.encode("You have been confirmed, please log in and join the share"),
        }, false);
    };
    ShareListComponent.prototype.loadShare = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sharelist, locData, i, len;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.shareListInProgress) {
                            return [2 /*return*/];
                        }
                        this.isLoaded = false;
                        return [4 /*yield*/, this.getList()];
                    case 1:
                        sharelist = _a.sent();
                        this.shares = sharelist;
                        if (this.shares.confirm) {
                            this.shares.confirm.map(function (share) {
                                share.displayFileName = _this.fileItemService.decryptName(share, _this.shares.sharekeys);
                                return share;
                            });
                        }
                        this.isLoaded = true;
                        locData = this.Dispatch.getDipatchData();
                        if (locData && locData.sync_id) {
                            for (i = 0, len = this.shares.active.length; i < len; i++) {
                                if (this.shares.active[i].sync_id == locData.sync_id) {
                                    this.shareService.openDetails(this.shares.active[i].share_id);
                                    break;
                                }
                            }
                            this.loggerService.info(this.shares);
                            this.Dispatch.removeDispatch();
                        }
                        this.setPredicate(this.predicate, this.predicateReverse);
                        return [2 /*return*/];
                }
            });
        });
    };
    /* API call for getting share list */
    ShareListComponent.prototype.getList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 3, 4]);
                        this.shareListInProgress = true;
                        return [4 /*yield*/, this.api.execute('sharelist', {})];
                    case 1:
                        data = _a.sent();
                        return [4 /*yield*/, this.shareListService.processList(data)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, data];
                    case 3:
                        this.shareListInProgress = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ShareListComponent.prototype.openShareDetailsDailog = function (share, openState) {
        var webpath = Array.isArray(share) ? share[0] : share;
        var shareId = webpath.shareId || webpath.share_id;
        var state = !Number.isNaN(parseInt(openState, 10))
            ? parseInt(openState, 10)
            : 0;
        this.shareService.openDetails(shareId, state);
    };
    ShareListComponent.prototype.onToFiles = function (sync_id) {
        this.router.navigate(['/files', sync_id]);
    };
    ShareListComponent.prototype.onToInvite = function (cachekey) {
        this.router.navigate(['/shares/invite', cachekey]);
    };
    ShareListComponent.prototype.onToJoin = function (cachekey) {
        this.router.navigate(['/shares/rejoin'], { queryParams: { invite_id: cachekey } });
    };
    ShareListComponent.prototype.setPredicate = function (field, predicateReverse) {
        this.predicate = field;
        this.predicateReverse = predicateReverse;
        var isAsc = !predicateReverse;
        if (this.shares.active.length > 1) {
            this.shares.active.sort(function (a, b) {
                if (isAsc) {
                    return a[field].toString().localeCompare(b[field]);
                }
                else {
                    return b[field].toString().localeCompare(a[field]);
                }
            });
        }
    };
    ShareListComponent.prototype.dismissNotification = function (share) {
        var index = this.shares.pending.indexOf(share);
        var length = this.shares.pending.length;
        if (index !== -1) {
            // Set isDismissed property to true
            this.shares.pending[index].isDismissed = true;
            // Remove the share from the array
            this.shares.pending.splice(index, 1);
            this.shares = tslib_1.__assign({}, this.shares); // Update shares object to trigger change detection
        }
        else {
            this.loggerService.info('Share not found in pending shares array');
        }
    };
    return ShareListComponent;
}());
exports.ShareListComponent = ShareListComponent;
