"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ContentLengthDisplayComponent = /** @class */ (function () {
    function ContentLengthDisplayComponent() {
    }
    ContentLengthDisplayComponent.prototype.ngOnInit = function () { };
    return ContentLengthDisplayComponent;
}());
exports.ContentLengthDisplayComponent = ContentLengthDisplayComponent;
