"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UserRewardItem = /** @class */ (function () {
    function UserRewardItem(params) {
        this.bonus_servtime = 0;
        this.status = 0;
        this.bonus_bytes = 0;
        this.bonus_type = '';
        this.bonus_type_text = '';
        this.bonus_text = '';
        this.bonus_from = '';
        this.bonus_from_id = '';
        this.comment = '';
        if (params) {
            return this.deserialize(params);
        }
    }
    UserRewardItem.prototype.deserialize = function (params) {
        for (var _i = 0, _a = Object.entries(params); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], val = _b[1];
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    };
    return UserRewardItem;
}());
exports.UserRewardItem = UserRewardItem;
