"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var user_multi_admin_component_1 = require("../multi-admin/user-multi-admin/user-multi-admin.component");
var user_admin_component_1 = require("./user-admin/user-admin.component");
var ɵ0 = { rolesActive: true };
exports.ɵ0 = ɵ0;
var routes = [
    { path: '', component: user_admin_component_1.UserAdminComponent },
    { path: 'users', component: user_multi_admin_component_1.UserMultiAdminComponent },
    { path: 'roles', component: user_multi_admin_component_1.UserMultiAdminComponent, data: ɵ0 }
];
var MultiUserRoutingModule = /** @class */ (function () {
    function MultiUserRoutingModule() {
    }
    return MultiUserRoutingModule;
}());
exports.MultiUserRoutingModule = MultiUserRoutingModule;
