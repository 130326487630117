"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ShareListMember = /** @class */ (function () {
    function ShareListMember() {
    }
    ShareListMember.prototype.deserialize = function (input) {
        Object.assign(this, input);
        return this;
    };
    return ShareListMember;
}());
exports.ShareListMember = ShareListMember;
