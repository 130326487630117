"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var DialogAccountUpgradeComponent = /** @class */ (function () {
    function DialogAccountUpgradeComponent(activeModal) {
        this.activeModal = activeModal;
        this.state = new core_1.EventEmitter();
        this.close = new core_1.EventEmitter();
    }
    DialogAccountUpgradeComponent.prototype.ngOnInit = function () { };
    return DialogAccountUpgradeComponent;
}());
exports.DialogAccountUpgradeComponent = DialogAccountUpgradeComponent;
