"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var crypt_buffer_service_1 = require("./crypt-buffer.service");
var sjcl = require("sjcl");
var SyncDigestService = /** @class */ (function () {
    function SyncDigestService(crypt) {
        this.crypt = crypt;
    }
    SyncDigestService.prototype.hash = function (str) {
        // console.log(sjcl);
        return sjcl.codec.hex.fromBits(sjcl.hash.sha1.hash(str));
    };
    SyncDigestService.prototype.hash256 = function (str) {
        return sjcl.codec.hex.fromBits(sjcl.hash.sha256.hash(str));
    };
    SyncDigestService.prototype.init = function () {
        return this.crypt.sha1init();
    };
    SyncDigestService.prototype.reset = function (shaObj) {
        return this.crypt.sha1reset(shaObj);
    };
    SyncDigestService.prototype.update = function (shaObj, data) {
        return this.crypt.sha1update(shaObj, data);
    };
    SyncDigestService.prototype.finish = function (shaObj) {
        return this.crypt.sha1finish(shaObj);
    };
    return SyncDigestService;
}());
exports.SyncDigestService = SyncDigestService;
