"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../shared/components/verify-email-required/verify-email-required.component.ngfactory");
var i2 = require("../../shared/components/verify-email-required/verify-email-required.component");
var i3 = require("../../core/api.service");
var i4 = require("../../core/user.service");
var i5 = require("../../error/error-display-hash/error-display-hash.component.ngfactory");
var i6 = require("../../error/error-display-hash/error-display-hash.component");
var i7 = require("../sharemember-add/sharemember-add.component.ngfactory");
var i8 = require("../sharemember-add/sharemember-add.component");
var i9 = require("../../core/logger.service");
var i10 = require("@ng-bootstrap/ng-bootstrap");
var i11 = require("../../core/multiadmin.service");
var i12 = require("../../core/validate.service");
var i13 = require("@angular/common");
var i14 = require("./sharemember-invite.component");
var i15 = require("../../core/base64.service");
var i16 = require("../share.service");
var i17 = require("../share-new.service");
var styles_SharememberInviteComponent = [];
var RenderType_SharememberInviteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SharememberInviteComponent, data: {} });
exports.RenderType_SharememberInviteComponent = RenderType_SharememberInviteComponent;
function View_SharememberInviteComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-verify-email-required", [], null, null, null, i1.View_VerifyEmailRequiredComponent_0, i1.RenderType_VerifyEmailRequiredComponent)), i0.ɵdid(1, 114688, null, 0, i2.VerifyEmailRequiredComponent, [i3.ApiService, i4.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_SharememberInviteComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "sync-error-display-hash", [], null, null, null, i5.View_ErrorDisplayHashComponent_0, i5.RenderType_ErrorDisplayHashComponent)), i0.ɵdid(2, 114688, null, 0, i6.ErrorDisplayHashComponent, [], { errors: [0, "errors"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "alert alert-info"]], [[2, "hide", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.invitedEmails = "") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "ion-checkmark-circled fa-lg syncblue"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, [" You have successfully invited ", " "])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "sync-sharemember-add", [], null, [[null, "sharememberInviteChange"], [null, "shareDataChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("sharememberInviteChange" === en)) {
        var pd_0 = ((_co.sharememberInvite = $event) !== false);
        ad = (pd_0 && ad);
    } if (("shareDataChange" === en)) {
        var pd_1 = ((_co.shareData = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_SharememberAddComponent_0, i7.RenderType_SharememberAddComponent)), i0.ɵdid(7, 114688, null, 0, i8.SharememberAddComponent, [i4.UserService, i3.ApiService, i9.LoggerService, i10.NgbActiveModal, i11.MultiAdminService, i12.ValidateService], { sharememberInvite: [0, "sharememberInvite"], shareData: [1, "shareData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors; _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.sharememberInvite; var currVal_4 = _co.shareData; _ck(_v, 7, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.invitedEmails; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.invitedEmails; _ck(_v, 5, 0, currVal_2); }); }
function View_SharememberInviteComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalInstance.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["Add user ", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "modal-body modal-body-overflow"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SharememberInviteComponent_1)), i0.ɵdid(8, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SharememberInviteComponent_2)), i0.ɵdid(10, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["id", "add-users-dialog-btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendInvites() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 0, "i", [["class", "fa fa-spin fa-spinner"]], [[2, "hide", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Add users "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isVerified; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.isVerified; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shareData.name; _ck(_v, 5, 0, currVal_0); var currVal_3 = !_co.isValidInvite(); _ck(_v, 12, 0, currVal_3); var currVal_4 = !_co.spinner; _ck(_v, 13, 0, currVal_4); }); }
exports.View_SharememberInviteComponent_0 = View_SharememberInviteComponent_0;
function View_SharememberInviteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-sharemember-invite", [], null, null, null, View_SharememberInviteComponent_0, RenderType_SharememberInviteComponent)), i0.ɵdid(1, 114688, null, 0, i14.SharememberInviteComponent, [i4.UserService, i15.Base64Service, i9.LoggerService, i16.ShareService, i17.ShareNewService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SharememberInviteComponent_Host_0 = View_SharememberInviteComponent_Host_0;
var SharememberInviteComponentNgFactory = i0.ɵccf("sync-sharemember-invite", i14.SharememberInviteComponent, View_SharememberInviteComponent_Host_0, { shareData: "shareData", openState: "openState", modalInstance: "modalInstance" }, {}, []);
exports.SharememberInviteComponentNgFactory = SharememberInviteComponentNgFactory;
