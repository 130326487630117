"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var user_service_1 = require("../../core/user.service");
var logger_service_1 = require("../../core/logger.service");
var api_service_1 = require("../../core/api.service");
var events_list_service_1 = require("../../events/events-list.service");
var router_1 = require("@angular/router");
var MigrateComponent = /** @class */ (function () {
    function MigrateComponent(
    // private $timeout: ng.ITimeoutService,
    api, crypt, userService, log, eventsListService, route) {
        this.api = api;
        this.crypt = crypt;
        this.userService = userService;
        this.log = log;
        this.eventsListService = eventsListService;
        this.route = route;
    }
    MigrateComponent.prototype.ngOnInit = function () {
        this.code = this.route.snapshot.queryParams['code'];
        this.loading = true;
        this.poll(true);
        this.getMigrateEvents();
        this.filesprocessed = 0;
        this.bytesprocessed = 0;
        this.user = this.userService.getUser();
    };
    MigrateComponent.prototype.doMigrate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pubkey, migrationkeys, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.migratespinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, this.api.fetchText('/key/migration/pubkey')];
                    case 2:
                        pubkey = _a.sent();
                        return [4 /*yield*/, this.crypt.prepareMigrationKeys(pubkey)];
                    case 3:
                        migrationkeys = _a.sent();
                        return [4 /*yield*/, this.api.execute('queueformigration', {
                                type: 'dropbox',
                                code: this.route.snapshot.queryParams['code'],
                                migrate_pass: migrationkeys.encPass,
                                migrate_priv_key: migrationkeys.encPriv,
                                migrate_meta_key: migrationkeys.encMeta
                            })];
                    case 4:
                        _a.sent();
                        this.migratespinner = false;
                        this.migrationstarted = true;
                        window.clearTimeout(this.polltimer);
                        // this.$timeout.cancel(this.polltimer);
                        this.poll(false);
                        return [3 /*break*/, 6];
                    case 5:
                        err_1 = _a.sent();
                        if (typeof err_1 === 'object') {
                            this.migrationreqstatus = err_1.error_code;
                        }
                        else {
                            this.migrationreqstatus = 0;
                        }
                        this.migratespinner = false;
                        this.migrationstarted = false;
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    MigrateComponent.prototype.stopMigrate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.jobspinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.execute('stopmigration', {
                                type: 'dropbox'
                            })];
                    case 2:
                        _a.sent();
                        this.jobspinner = false;
                        //this.Feedback.setFeedback('success', 'Migration successfully cancelled.', 10000);
                        this.errMsg = 'Migration successfully cancelled.';
                        this.cancelerrcode = 0;
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        if (this.cancelerrcode != 0) {
                            if (typeof err_2 === 'object') {
                                switch (err_2.code) {
                                    case 1:
                                        this.cancelerrcode = 1;
                                        this.log.warn('No migration job is running');
                                        break;
                                    case 2:
                                        this.cancelerrcode = 2;
                                        this.log.warn('Migration is already cancelled');
                                        //this.Feedback.setFeedback('danger', 'You already cancelled this migration job, please wait...', 10000);
                                        this.errMsg = 'You already cancelled this migration job, please wait...';
                                        break;
                                    default:
                                        this.log.warn(JSON.stringify(err_2));
                                        this.cancelerrcode = -1;
                                        this.log.warn('Unknown error occurred when cancelling the migration');
                                }
                            }
                            else {
                                this.cancelerrcode = -2;
                                this.log.warn('An error occurred when stopping the migration');
                            }
                        }
                        this.jobspinner = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MigrateComponent.prototype.poll = function (checkmongo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, repoll, totalfolders, donefolders, folder_upload_prog, cur_dl_bytes, err_3;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.execute('migratestatus', {
                                type: 'dropbox',
                                checkmongo: checkmongo ? 1 : 0
                            })];
                    case 1:
                        result = _a.sent();
                        repoll = true;
                        this.polldata = result;
                        switch (parseInt(result.jobstatus, 10)) {
                            case 0: {
                                this.jobprogresspercent = 0;
                                break;
                            }
                            case 1: {
                                this.jobprogresspercent = 2.5;
                                break;
                            }
                            case 2: {
                                this.jobprogresspercent = 5;
                                break;
                            }
                            case 3: {
                                totalfolders = this.polldata.totalfolders;
                                donefolders = (this.polldata.foldersdone +
                                    this.polldata.foldersfailed +
                                    this.polldata.foldersexcluded);
                                folder_upload_prog = donefolders / totalfolders;
                                if (totalfolders > 0) {
                                    this.jobprogresspercent = 15 + folder_upload_prog * 15;
                                }
                                else {
                                    this.jobprogresspercent = 15;
                                }
                                break;
                            }
                            case 4: {
                                cur_dl_bytes = 0;
                                if (this.polldata.curfiles.length > 0) {
                                    cur_dl_bytes = this.polldata.curfiles
                                        .map(function (f) { return f.ul_bytes == f.size ? 0 : f.dl_bytes + f.ul_bytes; })
                                        .reduce(function (sum, current) {
                                        return sum + current;
                                    });
                                }
                                this.bytesprocessed = (result.bytesdone +
                                    result.bytesexcluded +
                                    result.bytesfailed +
                                    (cur_dl_bytes / 2));
                                this.totalbytes = result.totalbytes;
                                this.bytesdonepercent = 100 * this.bytesprocessed / result.totalbytes;
                                this.filesprocessed = (result.filesdone +
                                    result.filesexcluded +
                                    result.filesfailed);
                                this.totalfiles = result.totalfiles;
                                this.filesdonepercent = 100 * this.filesprocessed / result.totalfiles;
                                this.jobprogresspercent = 30 + this.filesdonepercent * 0.25 + this.bytesdonepercent * 0.45;
                                break;
                            }
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9: {
                                this.jobprogresspercent = 100;
                                repoll = false;
                                break;
                            }
                            default: {
                                this.jobprogresspercent = 0;
                            }
                        }
                        this.code = undefined;
                        this.loading = false;
                        if (repoll) {
                            this.polltimer = window.setTimeout(function () { return _this.poll(false); }, 5000);
                        }
                        else {
                            window.clearTimeout(this.polltimer);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        this.polldata = undefined;
                        this.loading = false;
                        this.polltimer = window.setTimeout(function () { return _this.poll(false); }, 30000);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MigrateComponent.prototype.getMigrateEvents = function () {
        var _this = this;
        this.migrateeventsloading = true;
        var opts = { limit: 10, eventTypes: [300, 301] };
        return this.eventsListService.getList(opts)
            .then(function (res) { return _this.setEventList(res); })
            .catch(function (err) { return _this.handleEventErrors(err); });
    };
    MigrateComponent.prototype.setEventList = function (migrateeventresult) {
        this.migrateeventlist = migrateeventresult.events;
        this.migrateeventsloading = false;
    };
    MigrateComponent.prototype.handleEventErrors = function (err) {
        //this.Feedback.setFeedback('danger', 'An error occurred retrieving migration history', 10000);
        this.errMsg = 'An error occurred retrieving migration history';
        this.log.error(JSON.stringify(err));
        this.log.error('An error occurred retrieving migration events');
        this.migrateeventsloading = false;
    };
    MigrateComponent.prototype.migrateEventIsOverQuota = function (event) {
        return event.items && (event.items.hasfailedfiles & 4) == 4;
    };
    return MigrateComponent;
}());
exports.MigrateComponent = MigrateComponent;
