"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("../../core/api.service");
var i0 = require("@angular/core");
var i1 = require("../../core/api.service");
var VersionService = /** @class */ (function () {
    function VersionService(api) {
        this.api = api;
        this.localStorageKey = 'sync-appVersion';
        this.versionUrl = '../../../assets/version.txt';
        this.isReloading = false; // Add a flag to track reloading state
        this.isPromptOpen = false;
        this.isVersionChanged = false;
    }
    VersionService.prototype.isLocalStorageAllowed = function () {
        try {
            localStorage.setItem('test', 'test');
            localStorage.removeItem('test');
            return true;
        }
        catch (error) {
            console.error('Error checking localStorage permission:', error);
            return false;
        }
    };
    VersionService.prototype.loadVersion = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isLocalStorageAllowed()) {
                            return [2 /*return*/];
                        }
                        if (this.isReloading) {
                            return [2 /*return*/];
                        }
                        this.isReloading = true;
                        // Ensure storedOldVersion is initialized
                        this.getVersionFromLocalStorage();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.api.fetchText(this.versionUrl)];
                    case 2:
                        data = _a.sent();
                        this.newVersionData = JSON.parse(data).version;
                        if (this.storedOldVersion !== this.newVersionData) {
                            this.isVersionChanged = true;
                            this.isPromptOpen = true;
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Error loading version:', error_1);
                        return [3 /*break*/, 5];
                    case 4:
                        this.isReloading = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/, this.isVersionChanged];
                }
            });
        });
    };
    VersionService.prototype.setVersionInLocalStorage = function () {
        var jsonString = this.newVersionData || '';
        localStorage.setItem(this.localStorageKey, jsonString);
    };
    VersionService.prototype.getVersionFromLocalStorage = function () {
        this.storedOldVersion = localStorage.getItem(this.localStorageKey);
    };
    VersionService.prototype.reloadApp = function () {
        this.setVersionInLocalStorage();
        // Reload the application
        window.location.reload();
    };
    VersionService.ngInjectableDef = i0.defineInjectable({ factory: function VersionService_Factory() { return new VersionService(i0.inject(i1.ApiService)); }, token: VersionService, providedIn: "root" });
    return VersionService;
}());
exports.VersionService = VersionService;
