"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var base64_service_1 = require("../../core/base64.service");
var logger_service_1 = require("../../core/logger.service");
var user_service_1 = require("../../core/user.service");
var validate_service_1 = require("../../core/validate.service");
var file_list_service_1 = require("../../file-list/services/file-list.service");
var share_new_service_1 = require("../share-new.service");
var share_service_1 = require("../share.service");
var models_1 = require("../../shared/models");
var rxjs_1 = require("rxjs");
var store_1 = require("@ngrx/store");
var joblock_service_1 = require("../../core/joblock.service");
var blend_service_1 = require("src/app/shared/services/blend.service");
var DialogShareNewComponent = /** @class */ (function () {
    function DialogShareNewComponent(base64Service, userService, validateService, fileListService, loggerService, activeModal, shareService, shareNewService, JobLockService, store, blendService) {
        var _this = this;
        this.base64Service = base64Service;
        this.userService = userService;
        this.validateService = validateService;
        this.fileListService = fileListService;
        this.loggerService = loggerService;
        this.activeModal = activeModal;
        this.shareService = shareService;
        this.shareNewService = shareNewService;
        this.JobLockService = JobLockService;
        this.store = store;
        this.blendService = blendService;
        this.state = new core_1.EventEmitter();
        this.close = new core_1.EventEmitter();
        this.isChecking = false;
        this.isShareLimit = false;
        this.errors = [];
        this.selected = [];
        this.sharememberInvite = {
            permissions: {
                'perDOWNLOAD': 1,
                'perUPLOAD': 1,
                'perINVITE': 0,
                'perSEEOTHERS': 1,
                'perADDAPP': 0,
            },
            displayName: this.base64Service.decode(this.userService.get('display_name')),
            queue: [],
            roleQueue: [],
            roleName: '',
            privateMessage: ''
        };
        this.newshareform = new forms_1.FormGroup({
            filename: new forms_1.FormControl('', [forms_1.Validators.required, this.leadingSpace.bind(this), this.trailingChar.bind(this), this.reservedNames.bind(this),
                this.illegalCharacters.bind(this), this.exist.bind(this)])
        });
        this.isVerified = this.userService.get('is_verified');
        this.isChecking = true;
        this.completed = 0;
        this.validate = this.validateService;
        this.pid = 0;
        if (this.selected.length && this.selected[0]) {
            this.fileListService.getListSubscription()
                .subscribe(function (data) {
                if (data && data.cwd) {
                    _this.cwd = data.cwd;
                }
                if (_this.selected[0].sync_id == data.cwd.sync_id) {
                    if (data && data.pathlist.length) {
                        _this.pathlist = data.pathlist;
                    }
                }
            });
        }
    }
    DialogShareNewComponent.prototype.onChange = function (event) {
        this.filename = event.target.value;
    };
    DialogShareNewComponent.prototype.leadingSpace = function (control) {
        var fileName = control.value;
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        return (fileName && fileName.charAt(0) !== ' ') ? null : { 'leadingSpace': true };
    };
    DialogShareNewComponent.prototype.trailingChar = function (control) {
        var fileName = control.value;
        var lastChar = fileName ? fileName.charAt(fileName.length - 1) : '';
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        return !(lastChar === '.' || lastChar === ' ') ? null : { 'trailingChar': true };
    };
    DialogShareNewComponent.prototype.reservedNames = function (control) {
        var fileName = control.value;
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        return !(/^(CLOCK\$|PRN|AUX|NUL|CON|COM[0-9]|LPT[0-9])$/i).test(fileName) &&
            fileName.toLowerCase() !== '.syncinfo' &&
            fileName.toLowerCase() !== 'thumbs.db' &&
            fileName.toLowerCase() !== 'desktop.ini' &&
            fileName.toLowerCase() !== 'sync.cache' &&
            fileName.toLowerCase() !== '$recycle.bin' &&
            fileName.indexOf('.~') !== 0 &&
            fileName.indexOf('~') !== 0 &&
            fileName.indexOf('~$') === -1 &&
            !(/^(PRN\.[a-z0-9]+|AUX\.[a-z0-9]+|NUL\.[a-z0-9]+|CON\.[a-z0-9]+|COM[0-9]\.[a-z0-9]+|LPT[0-9]\.[a-z0-9]+)$/i).test(fileName) ? null : { 'reserved': true };
    };
    DialogShareNewComponent.prototype.illegalCharacters = function (control) {
        var fileName = control.value;
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        return (/^[^\/:\*\?"<>\|]+$/).test(fileName) ? null : { 'illegal': true };
    };
    DialogShareNewComponent.prototype.exist = function (control) {
        var fileName = control.value;
        if (!this.pathlist) {
            return true;
        }
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        var found = this.pathlist.filter(function (val) {
            return (val.name.toLowerCase() == fileName.toLowerCase());
        });
        return !(found && found.length > 0) ? null : { 'exist': true };
    };
    DialogShareNewComponent.prototype.ngOnInit = function () {
        this.bg = 1; //use background job 1 = true; change this variable to switch between background jobs for 'shareadd'
        this.init();
    };
    DialogShareNewComponent.prototype.exists = function (value, isPristine) {
        if (isPristine || this.validateService.isEmpty(value)) {
            return true;
        }
        if (!this.pathlist) {
            return true;
        }
        var found = this.pathlist.filter(function (val) {
            return (val.name.toLowerCase() == value.toLowerCase());
        });
        return !(found && found.length > 0);
    };
    DialogShareNewComponent.prototype.init = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, item, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.syncId = null;
                        this.isChecking = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 9]);
                        data = void 0;
                        if (!(this.selected && this.selected.length && this.selected[0])) return [3 /*break*/, 2];
                        item = this.selected[0];
                        this.syncId = item.sync_id;
                        this.filename = item.name;
                        this.newshareform.patchValue({ filename: this.filename });
                        return [3 /*break*/, 6];
                    case 2:
                        if (!(this.pid && this.pid != 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.fileListService.getDataNoState(this.pid, '0')];
                    case 3:
                        data = _a.sent();
                        this.pathlist = data.pathlist;
                        return [3 /*break*/, 6];
                    case 4:
                        this.pid = this.userService.get('syncus_sync_id');
                        return [4 /*yield*/, this.fileListService.getDataNoState(this.userService.get('syncus_sync_id'), '0')];
                    case 5:
                        data = _a.sent();
                        this.pathlist = data.pathlist;
                        _a.label = 6;
                    case 6: return [4 /*yield*/, this.shareNewService.checkShare(this.syncId)];
                    case 7:
                        _a.sent();
                        this.isShareLimit = false;
                        return [3 /*break*/, 9];
                    case 8:
                        ex_1 = _a.sent();
                        if (ex_1 && ex_1.error_code == 8027) {
                            this.isShareLimit = true;
                        }
                        else {
                            this.handleErr(ex_1);
                        }
                        return [3 /*break*/, 9];
                    case 9:
                        this.isChecking = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogShareNewComponent.prototype.shareMemberInviteChange = function (value) {
        this.sharememberInvite = value;
    };
    DialogShareNewComponent.prototype.postmkShareSteps = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, data, share_id;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.bg == 1)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.JobLockService.pollJobStatus()];
                    case 1:
                        response = _a.sent();
                        this.loggerService.info({ func: 'postShareJobCompleteAction', inviteKey: this.inviteKey });
                        return [4 /*yield*/, this.shareNewService.postshareDataProcess(this.syncId)];
                    case 2:
                        data = _a.sent();
                        share_id = data.shareId;
                        return [4 /*yield*/, this.shareNewService.shareMailInvite(share_id, this.inviteKey, this.sharememberInvite)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.spinner = false;
                        this.fileListService.reload();
                        this.activeModal.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogShareNewComponent.prototype.makeShare = function () {
        if (this.newshareform.valid) {
            this.spinner = true;
            this.completed = 0;
            // this.startTimeInterval();
            if (!this.sharememberInvite.displayName) {
                this.errors.push({ code: 1654 });
                return;
            }
            if (this.selected && this.selected[0] && this.selected[0].is_hide_share && this.selected[0].is_shared) {
                // This effectively just "Un-hides" the share
                this.loggerService.info('Share New: Adding share to hidden share.');
                return this.unhideShare();
            }
            else if (this.syncId) {
                this.loggerService.info('Share New: Converting existing directory to share');
                return this.convertShare();
            }
            else {
                this.loggerService.info('Share New: Creating new directory for new share');
                return this.newShare();
            }
        }
    };
    DialogShareNewComponent.prototype.unhideShare = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var shareData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.selected[0].share_id) {
                            // TODO Errors
                            this.loggerService.error('Directory is a hidden share but doesn\'t have a share_id');
                            this.errors.push({ code: 11201 });
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.shareService.getShare(this.selected[0].share_id)
                                .catch(function (err) {
                                _this.handleErr(err);
                                return;
                            })];
                    case 1:
                        shareData = _a.sent();
                        if (!shareData) {
                            this.loggerService.error("No sharedata for share_id=" + this.selected[0].share_id);
                            this.errors.push({ code: 11201 });
                        }
                        else {
                            this.shareNewService.addMember(shareData, this.sharememberInvite).then(function () {
                                _this.spinner = false;
                                _this.activeModal.close();
                            }).catch(function (err) {
                                _this.spinner = false;
                                _this.handleErr(err);
                                return;
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogShareNewComponent.prototype.convertShare = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    if (!this.validateService.isDisplayNameValid(this.sharememberInvite.displayName)) {
                        this.handleErr({ code: 1659 });
                        return [2 /*return*/];
                    }
                    this.shareJobSubscription = this.shareNewService
                        .mkShare(this.syncId, this.newshareform.value.filename, this.newshareform.value.filename, this.sharememberInvite, null, this.bg)
                        .subscribe(function (next) {
                        if (next.count) {
                            _this.updateProgress(next);
                        }
                        else {
                            _this.shareId = next.shareId;
                            _this.inviteKey = next.inviteKey;
                            _this.cachekey = next.cachekey;
                            _this.bg = next.bg;
                            _this.sharekey = next.sharekey;
                            _this.postmkShareSteps();
                        }
                    }, function (error) {
                        _this.handleErr(error);
                    });
                }
                catch (err) {
                    this.loggerService.error("error in convertShare: ," + err);
                    this.handleErr(err);
                }
                return [2 /*return*/];
            });
        });
    };
    DialogShareNewComponent.prototype.newShare = function () {
        var _this = this;
        try {
            if (!this.validateService.isDisplayNameValid(this.sharememberInvite.displayName)) {
                this.handleErr({ code: 1659 });
                return;
            }
            this.blendService.track(models_1.BlendEvent.BUTTON_CLICKED, {
                button_text: 'Create Share'
            });
            this.fileListService
                .mkdir(this.pid, this.newshareform.value.filename)
                .then(function (data) {
                _this.syncId = data.pathitem.sync_id;
                return _this.shareNewService
                    .mkShare(_this.syncId, _this.newshareform.value.filename, _this.newshareform.value.filename, _this.sharememberInvite, null, _this.bg)
                    .toPromise()
                    .then(function (next) {
                    _this.shareId = next.shareId;
                    _this.inviteKey = next.inviteKey;
                    _this.cachekey = next.cachekey;
                    _this.bg = next.bg;
                    _this.sharekey = next.sharekey;
                    _this.postmkShareSteps();
                });
            })
                .catch(function (err) { return _this.handleErr(err); });
        }
        catch (err) {
            this.loggerService.error("error in newShare: ," + err);
            this.handleErr(err);
        }
    };
    DialogShareNewComponent.prototype.isValid = function () {
        return !this.spinner && this.newshareform.valid && !this.isChecking && !this.isShareLimit;
    };
    DialogShareNewComponent.prototype.handleErr = function (err) {
        this.spinner = false;
        if (typeof err === 'object') {
            if (err.error_code) {
                this.errors.push({ code: err.error_code, msg: err.error_msg });
            }
            else if (err.code) {
                this.errors.push(err);
            }
            else {
                this.errors.push({ code: 1000, msg: 'An unknown error occurred' });
            }
        }
    };
    DialogShareNewComponent.prototype.updateProgress = function (prog) {
        this.count = prog.count;
        this.completed += prog.completed;
        if (prog.completed >= prog.count) {
            // we're done
        }
    };
    return DialogShareNewComponent;
}());
exports.DialogShareNewComponent = DialogShareNewComponent;
