"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../notifications/notifications/notifications.component.ngfactory");
var i2 = require("../../notifications/notifications/notifications.component");
var i3 = require("../../notifications/feedback.service");
var i4 = require("../../core/notifications.service");
var i5 = require("../../core/user.service");
var i6 = require("@angular/router");
var i7 = require("./preview-layout.component");
var styles_PreviewLayoutComponent = [];
var RenderType_PreviewLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PreviewLayoutComponent, data: {} });
exports.RenderType_PreviewLayoutComponent = RenderType_PreviewLayoutComponent;
function View_PreviewLayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-notifications", [], null, null, null, i1.View_NotificationsComponent_0, i1.RenderType_NotificationsComponent)), i0.ɵdid(1, 114688, null, 0, i2.NotificationsComponent, [i3.FeedbackService, i4.NotificationsService, i5.UserService], null, null), (_l()(), i0.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(3, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, null); }
exports.View_PreviewLayoutComponent_0 = View_PreviewLayoutComponent_0;
function View_PreviewLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-preview-layout", [], null, null, null, View_PreviewLayoutComponent_0, RenderType_PreviewLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i7.PreviewLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_PreviewLayoutComponent_Host_0 = View_PreviewLayoutComponent_Host_0;
var PreviewLayoutComponentNgFactory = i0.ɵccf("sync-preview-layout", i7.PreviewLayoutComponent, View_PreviewLayoutComponent_Host_0, {}, {}, []);
exports.PreviewLayoutComponentNgFactory = PreviewLayoutComponentNgFactory;
