"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./dialog-confirm-ownership-change.component");
var i2 = require("@ng-bootstrap/ng-bootstrap");
var styles_DialogConfirmOwnershipChangeComponent = [];
var RenderType_DialogConfirmOwnershipChangeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogConfirmOwnershipChangeComponent, data: {} });
exports.RenderType_DialogConfirmOwnershipChangeComponent = RenderType_DialogConfirmOwnershipChangeComponent;
function View_DialogConfirmOwnershipChangeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "ion-android-close fa-lg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "ion-android-people fa-lg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Change Share Owner "])), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "alert alert-warning"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" You are about to change the ownership of your shared folder "])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), (_l()(), i0.ɵted(-1, null, [" to the share member selected above. After this change you will still have access to the share folder, but permissions will no longer be managed by you. This action cannot be undone. "])), (_l()(), i0.ɵeld(12, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-default"], ["id", "btn-share-new-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Cancel "])), (_l()(), i0.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-primary pull-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Proceed "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shareData.name; _ck(_v, 10, 0, currVal_0); }); }
exports.View_DialogConfirmOwnershipChangeComponent_0 = View_DialogConfirmOwnershipChangeComponent_0;
function View_DialogConfirmOwnershipChangeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-dialog-confirm-ownership-change", [], null, null, null, View_DialogConfirmOwnershipChangeComponent_0, RenderType_DialogConfirmOwnershipChangeComponent)), i0.ɵdid(1, 114688, null, 0, i1.DialogConfirmOwnershipChangeComponent, [i2.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DialogConfirmOwnershipChangeComponent_Host_0 = View_DialogConfirmOwnershipChangeComponent_Host_0;
var DialogConfirmOwnershipChangeComponentNgFactory = i0.ɵccf("sync-dialog-confirm-ownership-change", i1.DialogConfirmOwnershipChangeComponent, View_DialogConfirmOwnershipChangeComponent_Host_0, { shareData: "shareData" }, {}, []);
exports.DialogConfirmOwnershipChangeComponentNgFactory = DialogConfirmOwnershipChangeComponentNgFactory;
