"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var logger_service_1 = require("../../../core/logger.service");
var user_service_1 = require("../../../core/user.service");
var AvatarStackComponent = /** @class */ (function () {
    function AvatarStackComponent(userService, log) {
        this.userService = userService;
        this.log = log;
        /**
        * @property {number} maxDisplayAvatar
        * Accepts a number of avatars that should be displayed in the stack.
        * Default value is 5.
        * e.g. Setting this value as 5 will show max 5 avatars. If users passed are more than 5,
        * then it will display 4 avatars and 5th avatar will show as overflowing avatar with remaining count.
        */
        this.maxDisplayAvatar = 5;
        /**
        * @property {number} maxOverflowAvatar
        * Accepts a number of avatars that should be displayed on overflowing popover.
        * Default value is 5.
        * e.g. Setting this value as 5 will show max 5 users on click of overflowing avatar.
        */
        this.maxOverflowAvatar = 5;
        /**
        * @property {Function} onViewAll
        * callback function on click of view all on overfow popover
        */
        this.onViewAll = function () { };
    }
    AvatarStackComponent.prototype.ngOnInit = function () {
    };
    AvatarStackComponent.prototype.initUsers = function () {
        var _this = this;
        this.users = this.users.filter(function (user) {
            try {
                user.decodedDisplayName = atob(user.displayname);
            }
            catch (_a) {
                // displayname already decoded
            }
            user.disabled = user.status != 'Active';
            return user.userid != _this.userService.get('id');
        }).sort(function (user) { return user.disabled ? 1 : -1; });
        if (this.users.length > this.maxDisplayAvatar) {
            this.avatarUsers = this.users.slice(0, this.maxDisplayAvatar - 1);
            this.overflowUsersCount = this.users.length - this.avatarUsers.length;
            this.overflowUsers = this.users.slice(this.maxDisplayAvatar - 1, this.maxDisplayAvatar + this.maxOverflowAvatar);
        }
        else {
            this.avatarUsers = this.users;
            this.overflowUsers = [];
        }
    };
    AvatarStackComponent.prototype.ngOnChanges = function (changes) {
        if (changes.users && changes.users.currentValue) {
            this.users = changes.users.currentValue;
            this.initUsers();
        }
    };
    return AvatarStackComponent;
}());
exports.AvatarStackComponent = AvatarStackComponent;
