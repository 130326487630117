"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".avatar-container[_ngcontent-%COMP%]{margin-left:-8px;display:inline-flex;justify-content:center;align-items:center;border-radius:100%;overflow:hidden;position:relative;color:#fff;background-color:#000;text-decoration:none;font-size:14px;height:40px;width:40px;min-height:40px;min-width:40px;cursor:pointer}.hover-effect[_ngcontent-%COMP%]{width:100%;height:100%;position:absolute;top:0;left:0;border-radius:100%;background-color:#000;opacity:0}.disabled-effect[_ngcontent-%COMP%]{width:100%;height:100%;position:absolute;top:0;left:0;border-radius:100%;background-color:#fff;opacity:.5}.avatar-container[_ngcontent-%COMP%]:hover{z-index:1}.avatar-container[_ngcontent-%COMP%]:hover   .hover-effect[_ngcontent-%COMP%]{opacity:.3}.avatar-0[_ngcontent-%COMP%]{cursor:pointer;border:1px solid #fff;background-color:#1952b3}.avatar-1[_ngcontent-%COMP%]{cursor:pointer;border:1px solid #fff;background-color:#7b4389}.avatar-2[_ngcontent-%COMP%]{cursor:pointer;border:1px solid #fff;background-color:#cc4100}.avatar-3[_ngcontent-%COMP%]{cursor:pointer;border:1px solid #fff;background-color:#5e696e}.avatar-4[_ngcontent-%COMP%]{cursor:pointer;border:1px solid #fff;color:#000;background-color:#d6dadc}.avatar-initial[_ngcontent-%COMP%]{font-size:25px;text-transform:capitalize}.avatar-overflow[_ngcontent-%COMP%]{font-size:16px}  .popover.bs-popover-bottom .arrow::after{z-index:1;border-bottom-color:#fff!important;bottom:5px;left:-15px}  .popover.bs-popover-top .arrow::after{z-index:1;border-top-color:#fff!important;left:-15px;top:40px}\n/*# sourceMappingURL=avatar.component.css.map*/"];
exports.styles = styles;
