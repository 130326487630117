"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PreventRightClickDirective = /** @class */ (function () {
    function PreventRightClickDirective() {
    }
    PreventRightClickDirective.prototype.click = function (event) {
        event.preventDefault();
    };
    return PreventRightClickDirective;
}());
exports.PreventRightClickDirective = PreventRightClickDirective;
