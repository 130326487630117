"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var user_service_1 = require("../../core/user.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_multiadminuser_settings_component_1 = require("..//dialog-multiadminuser-settings/dialog-multiadminuser-settings.component");
var dialog_multiadminuser_add_component_1 = require("../dialog-multiadminuser-add/dialog-multiadminuser-add.component");
var multiadmin_service_1 = require("../../core/multiadmin.service");
var logger_service_1 = require("../../core/logger.service");
var field_control_service_1 = require("../../core/field-control.service");
var validate_service_1 = require("../../core/validate.service");
var dialog_multiadminuser_delete_component_1 = require("../dialog-multiadminuser-delete/dialog-multiadminuser-delete.component");
var user_selection_service_1 = require("../services/user-selection.service");
var api_service_1 = require("../../core/api.service");
var broadcast_service_1 = require("../../shared/services/broadcast.service");
var router_1 = require("@angular/router");
var defaultRole = new models_1.Role({
    name: '',
    permissions: [],
    hidden: false,
    editable: true,
    users: []
});
var UserMultiAdminComponent = /** @class */ (function () {
    function UserMultiAdminComponent(modalService, userService, multiadminService, log, fieldControlService, validateService, UserSelect, api, broadcastService, router, route) {
        var _this = this;
        this.modalService = modalService;
        this.userService = userService;
        this.multiadminService = multiadminService;
        this.log = log;
        this.fieldControlService = fieldControlService;
        this.validateService = validateService;
        this.UserSelect = UserSelect;
        this.api = api;
        this.broadcastService = broadcastService;
        this.router = router;
        this.route = route;
        this.rolesActive = false;
        this.currentList = [];
        this.rolesList = [];
        this.filteredRoleList = [];
        this.fields = [];
        this.roleEditorOpen = false;
        this.isAddNewRole = false;
        this.isRolesDataFetched = false;
        this.usersInProvision = false;
        this.inputValidationErr = { roleName: '' };
        this.permissionActions = models_1.PermissionActions;
        // Edit version of the role
        this.roleObject = new models_1.Role({
            name: '',
            permissions: [],
            hidden: false,
            editable: true,
            users: []
        });
        // Unedited version of the role
        this.unEditedRole = new models_1.Role({
            name: '',
            permissions: [],
            hidden: false,
            editable: true,
            users: []
        });
        this.spinner = false;
        this.deleteSpinner = false;
        this.alerts = [];
        this.showUsersLoader = false;
        this.roleFilter = '';
        this.selectedUsers = [];
        this.showRemoveUsersModal = false;
        this.selectedType = '';
        this.rmPurge = '0';
        this.totalUsers = 0;
        this.showChangeRolesModal = false;
        this.role = '';
        this.openRemoveUsersModal = function () {
            _this.showRemoveUsersModal = true;
        };
        this.closeRemoveUsersModal = function () {
            _this.showRemoveUsersModal = false;
            _this.removeUsersAlert = null;
        };
        this.removeUsers = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var deletedUsers, errorUsers, _i, _a, user, ex_1;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.spinner = true;
                        deletedUsers = [];
                        errorUsers = [];
                        _i = 0, _a = this.selectedUsers;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 11];
                        user = _a[_i];
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 9, , 10]);
                        if (!(user.user_status_str === 'invited' || user.user_status_str === 'declined' || user.user_status_str === 'error' || (user.isExpired && user.user_status_str !== 'email sent'))) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.multiadminService.deleteProvisioningUser(user.job_id)];
                    case 3:
                        _b.sent();
                        deletedUsers.push(user.email);
                        return [3 /*break*/, 8];
                    case 4:
                        if (!(user.user_status_str === 'email sent' || user.user_status_str === 'Active' || (user.isExpired && user.user_status_str !== 'invited'))) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.multiadminService.deleteProvisionedUser(user.user_id, user.roles)];
                    case 5:
                        _b.sent();
                        return [4 /*yield*/, this.api.execute('multiuserupdate', {
                                action: user.user_status_str === 'email sent' ? 'suspenduser' : 'remove',
                                email: user.email,
                                is_purge: parseInt(this.rmPurge, 10)
                            })];
                    case 6:
                        _b.sent();
                        deletedUsers.push(user.email);
                        return [3 /*break*/, 8];
                    case 7:
                        errorUsers.push(user.email);
                        _b.label = 8;
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        ex_1 = _b.sent();
                        this.log.error('Exception occured while removing user: ' + user.user_id);
                        errorUsers.push(user.email);
                        return [3 /*break*/, 10];
                    case 10:
                        _i++;
                        return [3 /*break*/, 1];
                    case 11:
                        this.spinner = false;
                        this.rmPurge = '0';
                        this.getList();
                        if (deletedUsers.length === this.selectedUsers.length) {
                            setTimeout(function () {
                                _this.closeRemoveUsersModal();
                            }, 5000);
                            if (deletedUsers.length > 5) {
                                this.removeUsersAlert = new models_1.AlertMessage(deletedUsers.length + " users are removed.");
                            }
                            else {
                                this.removeUsersAlert = new models_1.AlertMessage(deletedUsers.length > 1 ?
                                    deletedUsers.slice(0, -1).join(', ') + ' and ' + deletedUsers.slice(-1) + " are removed." :
                                    deletedUsers[0] + " is removed.");
                            }
                        }
                        else {
                            if (errorUsers.length > 5) {
                                this.removeUsersAlert = new models_1.AlertMessage(errorUsers.length + " users are failed to remove", models_1.AlertMessageType.ERROR);
                            }
                            else {
                                this.removeUsersAlert = new models_1.AlertMessage(errorUsers.length > 1 ?
                                    "Failed to remove users- " + (errorUsers.slice(0, -1).join(', ') + ' and ' + errorUsers.slice(-1)) :
                                    "Failed to remove user- " + errorUsers[0], models_1.AlertMessageType.ERROR);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.closeChangeRolesModal = function () {
            _this.showChangeRolesModal = false;
            _this.updateUsersAlert = null;
            _this.role = _this.filteredRoleList.length ? _this.filteredRoleList[0].name : '';
        };
    }
    UserMultiAdminComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            if (data.rolesActive) {
                _this.rolesActive = data.rolesActive;
            }
        });
        if (!this.userService.checkPermission(this.permissionActions.VIEW_USERS) && !this.userService.checkPermission(this.permissionActions.VIEW_ROLES)) {
            // Error. Not a multi_admin at all. Shouldn't have navigated here (url OR button)
            this.log.error('User isn\'t allowed to view users');
            this.router.navigate(['/files']);
            return;
        }
        this.user = this.userService.getUser();
        if (this.rolesActive && !this.user.is_unlimited_plan) {
            this.rolesActive = false;
            this.log.error('User isn\'t allowed to view roles');
            this.router.navigate(['/multi-user/users']);
            return;
        }
        this.usersLimit = this.userService.MAX_USERS;
        this.roleFilter = this.route.snapshot.queryParams['selectedRole'] || '';
        this.getList();
        this.broadcastService.on('event:hide.tools').subscribe(function () { return _this.unselectAll(); });
    };
    UserMultiAdminComponent.prototype.checkPermission = function (permName) {
        return this.userService.checkPermission(permName);
    };
    UserMultiAdminComponent.prototype.closeAlert = function () {
        this.alerts = [];
    };
    UserMultiAdminComponent.prototype.resetDefaultRolesObjects = function () {
        this.roleObject = new models_1.Role({
            name: '',
            permissions: [],
            hidden: false,
            editable: true,
            users: []
        });
        this.unEditedRole = new models_1.Role({
            name: '',
            permissions: [],
            hidden: false,
            editable: true,
            users: []
        });
    };
    UserMultiAdminComponent.prototype.addRole = function () {
        var newRole = JSON.parse(JSON.stringify(defaultRole));
        this.resetDefaultRolesObjects();
        this.roleObject.name = '';
        this.roleObject.permissions = newRole.permissions;
        this.roleEditorOpen = true;
        this.isAddNewRole = true;
        this.fields = this.multiadminService.createFields(this.alterRoleData(newRole), !this.isAddNewRole);
        this.form = this.fieldControlService.toFormGroup(this.fields);
    };
    UserMultiAdminComponent.prototype.editRole = function (role) {
        var newRole = JSON.parse(JSON.stringify(role));
        this.resetDefaultRolesObjects();
        this.unEditedRole = tslib_1.__assign({}, newRole);
        this.roleObject.name = newRole.name;
        this.roleObject.permissions = this.roleObject.name === this.userService.DEFAULT_ROLE ? newRole.permissions.map(function (perm) { return perm.editable = false; }) : newRole.permissions;
        this.roleEditorOpen = true;
        this.isAddNewRole = false;
        this.fields = this.multiadminService.createFields(this.alterRoleData(newRole), (['Administrator', 'Default User'].includes(newRole.name)));
        this.form = this.fieldControlService.toFormGroup(this.fields);
    };
    UserMultiAdminComponent.prototype.alterRoleData = function (role) {
        var manageUserPermission = {
            action: models_1.PermissionActions.MANAGE_USERS,
            editable: role.name !== this.userService.DEFAULT_ROLE,
            hidden: false,
            object: models_1.PermissionObjects.ADMIN,
            value: 0
        };
        var manageRolePermission = {
            action: models_1.PermissionActions.MANAGE_ROLES,
            editable: role.name !== this.userService.DEFAULT_ROLE,
            hidden: false,
            object: models_1.PermissionObjects.ADMIN,
            value: 0
        };
        role.permissions.filter(function (perm) { return perm.object === models_1.PermissionObjects.ADMIN; }).forEach(function (perm) {
            if (perm.action === models_1.PermissionActions.VIEW_USERS && perm.value && perm.editable && !perm.hidden && manageUserPermission.value != 2) {
                manageUserPermission.value = 1;
            }
            if (perm.action === models_1.PermissionActions.ADD_EDIT_USERS && perm.value && perm.editable && !perm.hidden) {
                manageUserPermission.value = 2;
            }
            if (perm.action === models_1.PermissionActions.VIEW_ROLES && perm.value && perm.editable && !perm.hidden && manageRolePermission.value != 2) {
                manageRolePermission.value = 1;
            }
            if (perm.action === models_1.PermissionActions.ADD_EDIT_ROLES && perm.value && perm.editable && !perm.hidden) {
                manageRolePermission.value = 2;
            }
        });
        role.permissions = role.permissions.filter(function (perm) { return perm.object !== models_1.PermissionObjects.ADMIN; });
        role.permissions.push(manageUserPermission, manageRolePermission);
        return role;
    };
    UserMultiAdminComponent.prototype.getList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, atLeastOnePending_1, ex_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.showUsersLoader = true;
                        return [4 /*yield*/, this.multiadminService.getUserList()];
                    case 1:
                        result = _a.sent();
                        this.totalUsers = result.users.length;
                        if (this.totalUsers < 1) {
                            this.showUsersLoader = false;
                        }
                        this.currentList = [];
                        if (!this.isRolesDataFetched) {
                            this.getRolesList();
                            this.getDefaultPermissions();
                        }
                        this.currentUser = null;
                        result.users.forEach(function (user) {
                            if (!_this.roleFilter || user.roles.toString().includes(_this.roleFilter)) {
                                if (user.email === _this.user.email && user.user_id === _this.user.id) {
                                    _this.currentUser = user;
                                }
                                else {
                                    user.user_status_str = _this.multiadminService.statusHandler(user.user_status_str);
                                    _this.currentList.push(user);
                                }
                            }
                        });
                        atLeastOnePending_1 = false;
                        this.currentList.forEach(function (user) { return user.user_status_str === 'pending' || user.user_status_str === 'working' ? (atLeastOnePending_1 = true, _this.usersInProvision = true) : null; });
                        this.UserSelect.setList(this.currentList);
                        this.checkSelected();
                        if (!atLeastOnePending_1) {
                            this.usersInProvision = false;
                        }
                        this.limit = result.user_limit;
                        this.plan_product_id = result.plan_product_id;
                        this.plan_rate_plan_id = result.plan_rate_plan_id;
                        this.plan_rate_plan_charge_id = result.plan_rate_plan_charge_id;
                        this.isRolesDataFetched = true;
                        this.showUsersLoader = false;
                        return [3 /*break*/, 3];
                    case 2:
                        ex_2 = _a.sent();
                        this.log.error(ex_2);
                        this.errcode = models_1.ErrCode.fromException(ex_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserMultiAdminComponent.prototype.getRolesList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, ex_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.multiadminService.getRoles()];
                    case 1:
                        response = _a.sent();
                        this.rolesList = response['roles'];
                        this.filteredRoleList = this.rolesList.filter(function (role) { return role.name !== 'Administrator'; });
                        this.role = this.filteredRoleList.length ? this.filteredRoleList[0].name : '';
                        return [3 /*break*/, 3];
                    case 2:
                        ex_3 = _a.sent();
                        this.log.error(ex_3);
                        this.errcode = models_1.ErrCode.fromException(ex_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserMultiAdminComponent.prototype.getDefaultPermissions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resultUser, ex_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.multiadminService.getCurrentUserPermissions(this.user.id)];
                    case 1:
                        resultUser = _a.sent();
                        defaultRole.permissions = resultUser.permissions;
                        return [3 /*break*/, 3];
                    case 2:
                        ex_4 = _a.sent();
                        this.log.error(ex_4);
                        this.errcode = models_1.ErrCode.fromException(ex_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserMultiAdminComponent.prototype.deleteRole = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ref, isConfirm, isSuccess, ex_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.errcode = null;
                        if (!this.unEditedRole.name) {
                            return [2 /*return*/];
                        }
                        ref = this.modalService.open(dialog_multiadminuser_delete_component_1.DialogMultiAdminuserDeleteComponent, {
                            backdropClass: 'in',
                            windowClass: 'in',
                            backdrop: 'static',
                            size: 'lg'
                        });
                        ref.componentInstance.openState = 3;
                        return [4 /*yield*/, ref.result];
                    case 1:
                        isConfirm = _a.sent();
                        if (!isConfirm) {
                            return [2 /*return*/];
                        }
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 6]);
                        this.deleteSpinner = true;
                        return [4 /*yield*/, this.multiadminService.deleteRole(this.unEditedRole.name)];
                    case 3:
                        isSuccess = _a.sent();
                        return [4 /*yield*/, this.getRolesList()];
                    case 4:
                        _a.sent();
                        if (isSuccess) {
                            this.createAlert('success', 'Role deleted');
                        }
                        else {
                            this.createAlert('danger', 'Failed to delete role');
                        }
                        this.deleteSpinner = false;
                        return [3 /*break*/, 6];
                    case 5:
                        ex_5 = _a.sent();
                        this.log.error(ex_5);
                        this.errcode = models_1.ErrCode.fromException(ex_5);
                        this.deleteSpinner = false;
                        return [3 /*break*/, 6];
                    case 6:
                        this.roleEditorOpen = false;
                        this.resetDefaultRolesObjects();
                        return [2 /*return*/];
                }
            });
        });
    };
    UserMultiAdminComponent.prototype.addUpdateRole = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var oldRoleObject, isEditRoleNameNotUnique, ex_6;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.errcode = null;
                        oldRoleObject = tslib_1.__assign({}, this.roleObject);
                        this.roleObject.name = this.form.controls[models_1.FieldIdentities.ROLENAME].value;
                        // Make sure there is no two roles with same name
                        if (!this.isAddNewRole && this.roleObject.name !== oldRoleObject.name) {
                            isEditRoleNameNotUnique = this.rolesList.filter(function (item) { return item.name === _this.roleObject.name; }).length > 0;
                            if (isEditRoleNameNotUnique) {
                                this.inputValidationErr.roleName = 'This name is already in use, please try a different name';
                                return [2 /*return*/];
                            }
                        }
                        if (!this.validateService.isAlphaNumeric(this.roleObject.name)) {
                            this.errcode = new models_1.ErrCode(1701);
                            return [2 /*return*/];
                        }
                        this.spinner = true;
                        this.roleObject.permissions.forEach(function (permission) {
                            if (permission.object === models_1.PermissionObjects.METADATA) {
                                permission.value = Date.now();
                            }
                            else {
                                for (var _i = 0, _a = Object.entries(_this.form.controls); _i < _a.length; _i++) {
                                    var key = _a[_i][0];
                                    var val = _this.form.controls[key].value;
                                    switch (key) {
                                        case models_1.FieldIdentities.CANMANAGEUSERS:
                                            if (permission.object === models_1.PermissionObjects.ADMIN && permission.action === models_1.PermissionActions.VIEW_USERS) {
                                                permission.value = val >= 1;
                                            }
                                            if (permission.object === models_1.PermissionObjects.ADMIN && permission.action === models_1.PermissionActions.ADD_EDIT_USERS) {
                                                permission.value = val == 2;
                                            }
                                            break;
                                        case models_1.FieldIdentities.CANMANAGEROLES:
                                            if (permission.object === models_1.PermissionObjects.ADMIN && permission.action === models_1.PermissionActions.VIEW_ROLES) {
                                                permission.value = val >= 1;
                                            }
                                            if (permission.object === models_1.PermissionObjects.ADMIN && permission.action === models_1.PermissionActions.ADD_EDIT_ROLES) {
                                                permission.value = val == 2;
                                            }
                                            break;
                                        case models_1.FieldIdentities.CANVIEWUSERS:
                                            if (permission.object === models_1.PermissionObjects.ADMIN && permission.action === models_1.PermissionActions.VIEW_USERS) {
                                                permission.value = val;
                                            }
                                            break;
                                        case models_1.FieldIdentities.CANADDEDITUSERS:
                                            if (permission.object === models_1.PermissionObjects.ADMIN && permission.action === models_1.PermissionActions.ADD_EDIT_USERS) {
                                                permission.value = val;
                                            }
                                            break;
                                        case models_1.FieldIdentities.CANVIEWROLES:
                                            if (permission.object === models_1.PermissionObjects.ADMIN && permission.action === models_1.PermissionActions.VIEW_ROLES) {
                                                permission.value = val;
                                            }
                                            break;
                                        case models_1.FieldIdentities.CANADDEDITROLES:
                                            if (permission.object === models_1.PermissionObjects.ADMIN && permission.action === models_1.PermissionActions.ADD_EDIT_ROLES) {
                                                permission.value = val;
                                            }
                                            break;
                                        case models_1.FieldIdentities.CANVIEWBILLING:
                                            if (permission.object === models_1.PermissionObjects.BILLING && permission.action === models_1.PermissionActions.VIEW_BILLING) {
                                                permission.value = val;
                                            }
                                            break;
                                        case models_1.FieldIdentities.CANMANAGEBILLING:
                                            if (permission.object === models_1.PermissionObjects.BILLING && permission.action === models_1.PermissionActions.MANAGE_BILLING) {
                                                permission.value = val;
                                            }
                                            break;
                                        case models_1.FieldIdentities.CANPURGEFILES:
                                            if (permission.object === models_1.PermissionObjects.FILES && permission.action === models_1.PermissionActions.PURGE_FILES) {
                                                permission.value = val;
                                            }
                                            break;
                                        case models_1.FieldIdentities.CANFORCE2FALOGIN:
                                            if (permission.object === models_1.PermissionObjects.SETTINGS && permission.action === models_1.PermissionActions.FORCE_2FA_ON_FIRST_LOGIN) {
                                                permission.value = val;
                                            }
                                            break;
                                        case models_1.FieldIdentities.CANSHARELINKS:
                                            if (permission.object === models_1.PermissionObjects.SHARE && permission.action === models_1.PermissionActions.SHARE_LINKS) {
                                                permission.value = val;
                                            }
                                            break;
                                        case models_1.FieldIdentities.CANSHAREFOLDERS:
                                            if (permission.object === models_1.PermissionObjects.FOLDERS && permission.action === models_1.PermissionActions.SHARE_FOLDERS) {
                                                permission.value = val;
                                            }
                                            break;
                                    }
                                }
                            }
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        if (!this.isAddNewRole) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.multiadminService.addRole(this.roleObject)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.multiadminService.addUpdateRole(oldRoleObject, // before update role object
                        this.roleObject // updated role object
                        )];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [4 /*yield*/, this.getRolesList()];
                    case 6:
                        _a.sent();
                        this.createAlert('success', 'Role ' + (this.isAddNewRole ? 'saved.' : 'updated.'));
                        this.spinner = false;
                        this.roleEditorOpen = false;
                        return [3 /*break*/, 8];
                    case 7:
                        ex_6 = _a.sent();
                        this.log.error(ex_6);
                        this.errcode = models_1.ErrCode.fromException(ex_6);
                        this.spinner = false;
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    UserMultiAdminComponent.prototype.hasEmptySlots = function () {
        return (this.totalUsers < this.limit);
    };
    UserMultiAdminComponent.prototype.openSettings = function (child, openState) {
        var _this = this;
        var ref = this.modalService.open(dialog_multiadminuser_settings_component_1.DialogMultiAdminuserSettingsComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
        ref.componentInstance.rolesList = this.filteredRoleList;
        ref.componentInstance.child = child;
        ref.componentInstance.openState = openState;
        ref.componentInstance.multiadminService = this.multiadminService;
        ref.result.then(function (result) {
            if (result === models_1.ModalCloseStates.REMOVED || result === models_1.ModalCloseStates.USER_ROLE_UPDATED) {
                _this.getList();
                _this.createAlert('success', result === models_1.ModalCloseStates.USER_ROLE_UPDATED ? 'User role updated successfully.' : 'Removed successfully.');
            }
            if (result === models_1.ModalCloseStates.PASSWORD_UPDATED) {
                _this.createAlert('success', 'Password updated successfully.');
            }
        });
    };
    UserMultiAdminComponent.prototype.resendInviteEmail = function (child) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resent, ex_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.multiadminService.resendInviteEmail(child.user_id, child.email, child.roles.toString())];
                    case 2:
                        resent = _a.sent();
                        if (resent.success) {
                            this.createAlert('success', 'Email sent');
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        ex_7 = _a.sent();
                        this.log.error(ex_7);
                        this.errcode = models_1.ErrCode.fromException(ex_7);
                        return [3 /*break*/, 4];
                    case 4:
                        this.spinner = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    UserMultiAdminComponent.prototype.resenduserTeamInviteEmail = function (child) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resent, ex_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.multiadminService.resenduserTeamInviteEmail(child)];
                    case 2:
                        resent = _a.sent();
                        resent.success ? this.createAlert('success', 'User invite sent') : this.createAlert('danger', 'User invite failed');
                        return [3 /*break*/, 4];
                    case 3:
                        ex_8 = _a.sent();
                        this.log.error(ex_8);
                        this.errcode = models_1.ErrCode.fromException(ex_8);
                        return [3 /*break*/, 4];
                    case 4:
                        this.spinner = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    UserMultiAdminComponent.prototype.createAlert = function (type, message) {
        var _this = this;
        this.alerts.push({
            type: type,
            msg: message
        });
        setTimeout(function () {
            _this.closeAlert();
        }, 5000);
    };
    UserMultiAdminComponent.prototype.addUser = function (modalType) {
        var _this = this;
        if (this.limit > 0) {
            var ref = this.modalService.open(dialog_multiadminuser_add_component_1.DialogMultiAdminuserAddComponent, {
                backdropClass: 'in',
                windowClass: 'in',
                backdrop: 'static'
            });
            ref.componentInstance.hasEmptySlots = this.hasEmptySlots();
            ref.componentInstance.rolesList = this.filteredRoleList;
            ref.componentInstance.plan_user_limit = this.limit;
            ref.componentInstance.plan_product_id = this.plan_product_id;
            ref.componentInstance.plan_rate_plan_id = this.plan_rate_plan_id;
            ref.componentInstance.plan_rate_plan_charge_id = this.plan_rate_plan_charge_id;
            ref.componentInstance.remainingUsersAmount = this.limit - this.totalUsers;
            ref.componentInstance.multiadminService = this.multiadminService;
            ref.componentInstance.currentUser = this.currentUser;
            ref.componentInstance.currentList = this.currentList;
            if (modalType === 'INVITE_USERS') {
                ref.componentInstance.openState = 4;
                ref.result.then(function () { return _this.getList(); });
            }
            else {
                ref.componentInstance.openState = 3;
            }
        }
        else {
            this.router.navigate(['/account/upgrade']);
        }
    };
    UserMultiAdminComponent.prototype.redirectToUsers = function (selectedRole) {
        this.router.navigate(['/multi-user/users'], { queryParams: { selectedRole: selectedRole } });
    };
    UserMultiAdminComponent.prototype.selectAllActive = function () {
        this.UserSelect.selectAll(this.UserSelect.selectTypes.active);
        this.checkSelected();
    };
    UserMultiAdminComponent.prototype.selectAllInvited = function () {
        this.UserSelect.selectAll(this.UserSelect.selectTypes.invited);
        this.checkSelected();
    };
    UserMultiAdminComponent.prototype.unselectAll = function () {
        this.isUserSelected = false;
        this.UserSelect.selectAll(this.UserSelect.selectTypes.all, false);
        this.checkSelected();
    };
    UserMultiAdminComponent.prototype.isSelectionDisabled = function (userStatus) {
        if (this.selectedType) {
            if (this.selectedType === this.UserSelect.selectTypes.active) {
                return userStatus.toLowerCase() !== this.UserSelect.selectTypes.active;
            }
            else {
                return userStatus.toLowerCase() === this.UserSelect.selectTypes.active;
            }
        }
        return false;
    };
    UserMultiAdminComponent.prototype.selectToggle = function (user) {
        if (this.isSelectionDisabled(user.user_status_str)) {
            return;
        }
        this.UserSelect.toggle(user);
        this.checkSelected();
    };
    UserMultiAdminComponent.prototype.checkSelected = function () {
        this.selectedUsers = this.UserSelect.getSelected();
        this.isUserSelected = this.selectedUsers.length > 0;
        if (this.isUserSelected) {
            if (this.selectedUsers[0].user_status_str.toLowerCase() === this.UserSelect.selectTypes.active) {
                this.selectedType = this.UserSelect.selectTypes.active;
            }
            else {
                this.selectedType = this.UserSelect.selectTypes.invited;
            }
        }
        else {
            this.selectedType = '';
        }
    };
    UserMultiAdminComponent.prototype.getSelectedUserEmails = function () {
        if (!this.selectedUsers.length) {
            return '';
        }
        return this.selectedUsers.slice(0, -1).map(function (user) { return user.email; }).join(', ') + ' and ' + this.selectedUsers.slice(-1)[0].email;
    };
    UserMultiAdminComponent.prototype.openChangeRolesModal = function () {
        this.showChangeRolesModal = true;
    };
    UserMultiAdminComponent.prototype.updateUserRole = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userRoles, result, ex_9;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        userRoles = this.selectedUsers.map(function (user) {
                            return { user_id: user.user_id, roleName: user.roles[0] };
                        });
                        return [4 /*yield*/, this.multiadminService.updateMultipleUsersRole(userRoles, this.role)];
                    case 2:
                        result = _a.sent();
                        this.spinner = false;
                        this.getList();
                        if (result.success) {
                            setTimeout(function () {
                                _this.closeChangeRolesModal();
                            }, 5000);
                            if (this.selectedUsers.length > 5) {
                                this.updateUsersAlert = new models_1.AlertMessage(this.selectedUsers.length + " users role updated successfully.");
                            }
                            else {
                                this.updateUsersAlert = new models_1.AlertMessage(this.selectedUsers.length > 1 ?
                                    this.selectedUsers.slice(0, -1).map(function (user) { return user.email; }).join(', ') + ' and ' + this.selectedUsers.slice(-1).map(function (user) { return user.email; }) + " users role updated successfully." :
                                    this.selectedUsers[0].email + " user role updated successfully.");
                            }
                        }
                        else {
                            this.updateUsersAlert = new models_1.AlertMessage("Failed to update role for select users", models_1.AlertMessageType.ERROR);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        ex_9 = _a.sent();
                        this.spinner = false;
                        this.getList();
                        this.log.error('Exception occured while updating role' + JSON.stringify(ex_9));
                        this.updateUsersAlert = new models_1.AlertMessage("Failed to update role for select users", models_1.AlertMessageType.ERROR);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Whether to show Remove user invite or not
     * @param person - User detail
     * @returns boolean
     */
    UserMultiAdminComponent.prototype.removeUserInvite = function (person) {
        var isExpired = (person.isExpired && person.user_status_str !== 'email sent');
        var isLegacyToCNC = person.isLegacyToCNC;
        return ((['invited', 'declined', 'error'].includes(person.user_status_str) || isExpired) && !isLegacyToCNC);
    };
    return UserMultiAdminComponent;
}());
exports.UserMultiAdminComponent = UserMultiAdminComponent;
