"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var feature_service_1 = require("../services/feature.service");
var IsFeatureAllowedDirective = /** @class */ (function () {
    function IsFeatureAllowedDirective(elRef, featureService, renderer2) {
        this.elRef = elRef;
        this.featureService = featureService;
        this.renderer2 = renderer2;
    }
    IsFeatureAllowedDirective.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isFeatureAllowed;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.featureService.isAllowed(this.featureName)];
                    case 1:
                        isFeatureAllowed = _a.sent();
                        if (!isFeatureAllowed) {
                            this.renderer2.setStyle(this.elRef.nativeElement, 'display', 'none');
                        }
                        else {
                            this.renderer2.removeStyle(this.elRef.nativeElement, 'display');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return IsFeatureAllowedDirective;
}());
exports.IsFeatureAllowedDirective = IsFeatureAllowedDirective;
