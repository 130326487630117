"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./link-suspended.component");
var i2 = require("@ng-bootstrap/ng-bootstrap");
var styles_LinkSuspendedComponent = [];
var RenderType_LinkSuspendedComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LinkSuspendedComponent, data: {} });
exports.RenderType_LinkSuspendedComponent = RenderType_LinkSuspendedComponent;
function View_LinkSuspendedComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Link Suspended: "])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "p", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" This link has been suspended, please contact us at abuse@sync.com and quote: \"", "\" "])), (_l()(), i0.ɵeld(10, 0, null, null, 0, "div", [["class", "modal-footer"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link.cachekey; _ck(_v, 9, 0, currVal_0); }); }
exports.View_LinkSuspendedComponent_0 = View_LinkSuspendedComponent_0;
function View_LinkSuspendedComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-link-suspended", [], null, null, null, View_LinkSuspendedComponent_0, RenderType_LinkSuspendedComponent)), i0.ɵdid(1, 114688, null, 0, i1.LinkSuspendedComponent, [i2.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_LinkSuspendedComponent_Host_0 = View_LinkSuspendedComponent_Host_0;
var LinkSuspendedComponentNgFactory = i0.ɵccf("sync-link-suspended", i1.LinkSuspendedComponent, View_LinkSuspendedComponent_Host_0, { link: "link" }, { state: "state", close: "close" }, []);
exports.LinkSuspendedComponentNgFactory = LinkSuspendedComponentNgFactory;
