"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ShowFocusDirective = /** @class */ (function () {
    function ShowFocusDirective(el) {
        this.el = el;
    }
    ShowFocusDirective.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['syncShowFocus'] && changes['syncShowFocus'].currentValue) {
            setTimeout(function () {
                _this.el.nativeElement.focus();
            }, 0);
        }
    };
    return ShowFocusDirective;
}());
exports.ShowFocusDirective = ShowFocusDirective;
