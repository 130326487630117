"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var transfer_model_1 = require("../../transfer/transfer.model");
var models_1 = require("../models");
var TranslateTransferItemPipe = /** @class */ (function () {
    function TranslateTransferItemPipe() {
    }
    TranslateTransferItemPipe.prototype.transform = function (status, shouldReturnAsHtml) {
        var str = '';
        if (models_1.ErrorMessages[status]) {
            return models_1.ErrorMessages[status];
        }
        switch (parseInt(status, 10)) {
            case transfer_model_1.TransferStatus.STATUS_WAITING:
                str = 'Waiting';
                break;
            case transfer_model_1.TransferStatus.STATUS_WORKING:
                str = 'Working ...';
                break;
            case transfer_model_1.TransferStatus.STATUS_ENC_UPLOAD:
                str = 'Encrypting file';
                break;
            case transfer_model_1.TransferStatus.STATUS_UPLOAD:
                str = 'Uploading encrypted file';
                break;
            case transfer_model_1.TransferStatus.STATUS_DECRYPT_DOWNLOAD:
                str = 'Decrypting';
                break;
            case transfer_model_1.TransferStatus.STATUS_DOWNLOAD:
                str = 'Downloading encrypted file';
                break;
            case transfer_model_1.TransferStatus.STATUS_PREPAREDOWNLOAD:
                str = 'Preparing file for download';
                break;
            case transfer_model_1.TransferStatus.STATUS_ERR_SIZE:
                str = 'File is too large for this browser';
                break;
            case transfer_model_1.TransferStatus.STATUS_ABORT:
                str = 'Stopped by user';
                break;
            case transfer_model_1.TransferStatus.STATUS_ERROR:
                str = 'An error occurred.';
                break;
            case transfer_model_1.TransferStatus.STATUS_ERR_OPEN:
                str = 'The browser cannot save/view this file';
                break;
            case transfer_model_1.TransferStatus.STATUS_SUCCESS:
                str = 'Complete';
                break;
            case transfer_model_1.TransferStatus.STATUS_UPLOAD_CANCELLED:
                str = 'Upload canceled';
                break;
            default:
                str = 'Unknown status.';
                break;
        }
        return str;
    };
    return TranslateTransferItemPipe;
}());
exports.TranslateTransferItemPipe = TranslateTransferItemPipe;
