"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var models_1 = require("../../shared/models");
var api_service_1 = require("../../core/api.service");
var logger_service_1 = require("../../core/logger.service");
var environment_1 = require("../../../environments/environment");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_multiuser_add_component_1 = require("../../multi-user/dialog-multiuser-add/dialog-multiuser-add.component");
var sync_cookie_service_1 = require("../../core/sync-cookie.service");
var zuora_account_service_1 = require("../services/zuora-account.service");
var user_service_1 = require("../../core/user.service");
var sku_service_1 = require("../../shared/services/sku.service");
var multiadmin_service_1 = require("../../core/multiadmin.service");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var plandetails_model_1 = require("../../shared/models/api/plandetails.model");
var router_1 = require("@angular/router");
var dialog_confirm_password_component_1 = require("../dialog-confirm-password/dialog-confirm-password.component");
var platform_browser_1 = require("@angular/platform-browser");
var blend_service_1 = require("src/app/shared/services/blend.service");
var UpgradeComponent = /** @class */ (function () {
    function UpgradeComponent(api, log, modalService, store, syncCookie, zuora, userService, skuService, syncCrypt, multiadminService, route, sanitize, cookieService, blendService) {
        var _this = this;
        this.api = api;
        this.log = log;
        this.modalService = modalService;
        this.store = store;
        this.syncCookie = syncCookie;
        this.zuora = zuora;
        this.userService = userService;
        this.skuService = skuService;
        this.syncCrypt = syncCrypt;
        this.multiadminService = multiadminService;
        this.route = route;
        this.sanitize = sanitize;
        this.cookieService = cookieService;
        this.blendService = blendService;
        this.view = 'teams';
        this.viewTerm = 'annual';
        this.isZuoraUpgrade = false;
        this.isTeamsOnTrial = false;
        this.isTrialUserWithoutCC = false;
        this.isTeamsPlusUser = false;
        this.upgradePromoHtml = '';
        this.selectedProduct = {
            annual: {},
            monthly: {}
        };
        this.selectedTrialPlan = {};
        this.displayProductsY = {
            PS: [],
            BPT: [],
            PER: []
        };
        this.displayProductsM = {
            PS: [],
            BPT: [],
            PER: []
        };
        this.personalPlan = {
            name: 'Personal',
            family: 'PER',
            isPro: false,
            features: [
                '200 GB storage space'
            ],
            annual: {},
            monthly: {},
            trialPlan: {}
        };
        this.soloBasicPlan = {
            name: 'Solo Basic',
            family: 'PS',
            isPro: true,
            features: [
                '2 TB (2,000 GB) storage space',
                'Document previews',
                'File requests',
                'Sync CloudFiles on-demand'
            ],
            annual: {},
            monthly: {},
            trialPlan: {}
        };
        this.soloProfessionalPlan = {
            name: 'Solo Professional',
            family: 'PS',
            isPro: true,
            features: [
                '6 TB storage space',
                'Document previews',
                'File requests',
                'Sync CloudFiles on-demand',
                'Custom branding',
                'Priority support'
            ],
            annual: {},
            monthly: {},
            trialPlan: {}
        };
        this.teamsStandardPlan = {
            name: 'Teams Standard',
            family: 'BPT',
            isPro: true,
            features: [
                '1 TB storage space per user',
                'Adminstrator account',
                'Document previews',
                'File requests',
                'Sync CloudFiles on-demand'
            ],
            annual: {},
            monthly: {},
            trialPlan: {}
        };
        this.teamsUnlimited = {
            name: 'Teams Unlimited',
            family: 'BPT',
            isPro: true,
            features: [
                'Unlimited storage space',
                'Adminstrator account',
                'Document previews',
                'File requests',
                'Sync CloudFiles on-demand',
                'Custom branding',
                'Phone support'
            ],
            annual: {},
            monthly: {},
            trialPlan: {}
        };
        /**
         * Called from the template.
         * @param family String the product sku
         * @param rp rate plan id
         * @param qty quantity for multi user
         * @returns
         */
        this.onUpgrade = function (family, rp, qty, isTrial, term) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var type, params, item, productId;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        type = (this.user.is_pro)
                            ? 'upgrade'
                            : 'signup';
                        params = [
                            "rp=" + rp
                        ];
                        if (qty && qty > 1) {
                            params.push("qty=" + qty);
                        }
                        if (type === 'signup') {
                            params.push('hasacct=1');
                        }
                        if (isTrial) {
                            params.push('allowtrial=1');
                            // term ? params.push('termaftertrial=' + term) : null;
                            params.push('payrequired=0');
                        }
                        if (this.user.is_on_trial) {
                            params.push('ontrial=1');
                        }
                        if (!this.products[family]) return [3 /*break*/, 4];
                        item = void 0;
                        item = this.products[family].yearlyRatePlans.filter(function (item) { return item.id == rp; });
                        if (!item || item.length == 0) {
                            item = this.products[family].monthlyRatePlans.filter(function (item) { return item.id == rp; });
                        }
                        if (isTrial) {
                            item = this.products[family].trialRatePlans.filter(function (item) { return item.id == rp; });
                        }
                        if (!item || item.length == 0) {
                            this.log.error("Could not locate family: " + family + " with rate plan id: " + rp + " in " + JSON.stringify(this.products));
                            this.errcode = new models_1.ErrCode(8520);
                            return [2 /*return*/];
                        }
                        if (!this.skuService.isTeamsPlusUser(item[0].product_sku)) return [3 /*break*/, 2];
                        params.push('cnc=1');
                        if (!!this.isTeamsPlusUser) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.cookiesSetterForCNC()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        productId = item[0].product_id;
                        this.cookieService.setDisableAnalytics(this.userService.get('is_disableanalytics'));
                        return [4 /*yield*/, this.blendService.track(models_1.BlendEvent.BUTTON_CLICKED, {
                                Attribution_Mtags: this.cookieService.getMarketingRef(),
                                'Page Name': 'Upgrade page',
                                button_text: this.user.is_on_trial ? 'Change plan' : 'Buy now',
                            })];
                    case 3:
                        _a.sent();
                        window.location.href = environment_1.environment.ordershost + "/" + type + "/" + productId + "?" + params.join('&');
                        return [3 /*break*/, 5];
                    case 4:
                        this.log.error("Could not locate family: " + family);
                        this.errcode = new models_1.ErrCode(8520);
                        return [2 /*return*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
    }
    UpgradeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.spinner = true;
        this.sub = this.store
            .select(fromRoot.getAuthUser)
            .subscribe(function (data) {
            if (data) {
                _this.user = data;
                // @TODO once zuora is a go for all new orders:
                // if (this.user.zuora_acct_key || this.user.plan_id === 1) {
                if (_this.user.zuora_acct_key
                    || _this.route.snapshot.queryParams['forcezuora'] === 1
                    || _this.user.plan_id === 1) {
                    _this.teamsSubUser = _this.user.is_multi_new ? !_this.userService.checkPermission(models_1.PermissionActions.MANAGE_BILLING) : !_this.user.is_multi_admin && _this.user.is_multi_child;
                    _this.isTeamsPlusUser = _this.skuService.isTeamsPlusUser(_this.user.sku);
                    if (!_this.teamsSubUser) {
                        _this.showOrderForm();
                    }
                }
                else if (_this.user.plan_id !== 1) {
                    // showing an error to contact billing for assistance since
                    // upgrading from legacy system to Zuora requires manual work
                    _this.errcode = new models_1.ErrCode(8505);
                    _this.spinner = false;
                }
                else {
                    _this.errcode = new models_1.ErrCode(8506);
                    _this.spinner = false;
                }
            }
        });
    };
    UpgradeComponent.prototype.ngAfterViewInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isFreeUser, url, html, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.skuService.isFreeUser()];
                    case 1:
                        isFreeUser = _b.sent();
                        if (!isFreeUser) return [3 /*break*/, 5];
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        url = environment_1.environment.upgradePromoBannerUrl + "?syncuid=" + this.user.uid;
                        return [4 /*yield*/, this.api.fetchText(url)];
                    case 3:
                        html = _b.sent();
                        this.upgradePromoHtml = this.sanitize.bypassSecurityTrustHtml(html);
                        return [3 /*break*/, 5];
                    case 4:
                        _a = _b.sent();
                        this.upgradePromoHtml = '';
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UpgradeComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    };
    /**
     * Throws on exception
     *  ex.code == 8024 - authentication credentials incorrect
     */
    UpgradeComponent.prototype.cookiesSetterForCNC = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var password, sData, encryptedData, ex_1, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 13]);
                        return [4 /*yield*/, this.syncCrypt.userPasswordDecrypt(this.encHash)];
                    case 1:
                        password = _a.sent();
                        return [4 /*yield*/, this.multiadminService.getPwdKeysDataAuthenticated(this.userService.get('email'), password)];
                    case 2:
                        sData = _a.sent();
                        return [4 /*yield*/, this.syncCrypt.userkeyEncrypt(btoa(password), this.sessionId)];
                    case 3:
                        encryptedData = _a.sent();
                        this.syncCookie.setOrderSessionHashData(encryptedData);
                        this.syncCookie.setOrderSessionSaltData(JSON.stringify(sData));
                        return [3 /*break*/, 13];
                    case 4:
                        ex_1 = _a.sent();
                        if (!(ex_1 instanceof models_1.ErrCode)) return [3 /*break*/, 11];
                        if (!(ex_1.code == 8024)) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.confirmPassword()];
                    case 5:
                        result = _a.sent();
                        if (!(typeof result === 'string')) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.fixUpgradePassword(result)];
                    case 6:
                        _a.sent();
                        return [2 /*return*/, this.cookiesSetterForCNC()];
                    case 7:
                        if (result === true) {
                            return [2 /*return*/, this.cookiesSetterForCNC()];
                        }
                        else {
                            this.errcode = ex_1;
                        }
                        _a.label = 8;
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        if (ex_1.code == 2100) {
                            this.log.error('The enc_password field is incorrect and not decryptable');
                            this.errcode = new models_1.ErrCode(2123);
                            // spawn password dialog
                        }
                        else {
                            this.errcode = ex_1;
                        }
                        _a.label = 10;
                    case 10: return [3 /*break*/, 12];
                    case 11:
                        this.errcode = new models_1.ErrCode(1000, 'An unhandled error occurred');
                        _a.label = 12;
                    case 12:
                        this.log.error("Re-throwing exception in cookiesSetterForCNC");
                        throw this.errcode;
                    case 13: return [2 /*return*/];
                }
            });
        });
    };
    UpgradeComponent.prototype.showOrderForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var zprofile, result, skus_1, _i, _a, _b, key, value, type, params, isOnAnnualPlan, isOnMonthlyPlan, canToggleToMonthly, endDate, curDate, showMonthlyPlan, isUserOnTeamsPlus, personalPlanSku_1, personalProductSku_1, soloBasicPlanSku_1, soloBasicProductSku_1, soloProfessionalPlanSku_1, soloProfessionalProductSku_1, teamsStandardPlanSku_1, teamsStandardProductSku_1, teamsUnlimitedPlanSku_1, teamsUnlimitedProductSku_1, sku, _c, product, productSku, ex_2;
            var _this = this;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.zuora.getProfile()];
                    case 1:
                        zprofile = _d.sent();
                        if (zprofile) {
                            this.cancelInFuture = (zprofile.subscription && zprofile.subscription.status === 'Cancelled');
                            this.unpaidInvoices = (zprofile.balance > 0 || zprofile.unpaidInvoiceCount > 0);
                            this.trialPlanExpiry = zprofile.subscription && this.userService.dateConversionForTrials(zprofile.subscription.termEndDate, this.user.is_on_trial).getTime();
                            this.isTrialUserWithoutCC = !zprofile.defaultPaymentMethod;
                        }
                        if (this.user.zuora_acct_key && this.user.zuora_acct_key !== '') {
                            this.isZuoraUpgrade = true;
                        }
                        _d.label = 2;
                    case 2:
                        _d.trys.push([2, 9, , 10]);
                        return [4 /*yield*/, this.api.execute('orderupgrades', {})];
                    case 3:
                        result = _d.sent();
                        if (!(result && result.sessionId)) return [3 /*break*/, 7];
                        this.syncCookie.setOrderSessionId(result.sessionId);
                        this.sessionId = result.sessionId;
                        this.encHash = result.encHash;
                        this.products = result.products;
                        if (this.route.snapshot.queryParams['payfortrial'] && this.user.is_on_trial) {
                            skus_1 = zprofile.subscription.sku.split('-');
                            for (_i = 0, _a = Object.entries(this.products[skus_1[0]]); _i < _a.length; _i++) {
                                _b = _a[_i], key = _b[0], value = _b[1];
                                this.products[skus_1[0]][key].forEach(function (p) {
                                    if (p.product_sku === skus_1[0] && p.sku === skus_1[1]) {
                                        _this.onUpgrade(p.product_sku, p.id, parseInt(skus_1[skus_1.length - 1], 10));
                                    }
                                });
                            }
                        }
                        if (!(this.route.snapshot.queryParams['p'] && this.route.snapshot.queryParams['rp'] && !this.teamsSubUser && !this.unpaidInvoices)) return [3 /*break*/, 6];
                        type = (this.user.is_pro) ? 'upgrade' : 'signup';
                        params = [
                            "rp=" + this.route.snapshot.queryParams['rp'],
                            'hasacct=1'
                        ];
                        if (this.route.snapshot.queryParams['qty'] && this.route.snapshot.queryParams['qty'] > 1) {
                            params.push("qty=" + this.route.snapshot.queryParams['qty']);
                        }
                        if (this.route.snapshot.queryParams['rpc']) {
                            params.push("rpc=" + this.route.snapshot.queryParams['rpc']);
                        }
                        if (this.route.snapshot.queryParams['promocode']) {
                            params.push("promocode=" + this.route.snapshot.queryParams['promocode']);
                        }
                        // showform can be '0' or '1' which in js translates to true/false.
                        if (this.route.snapshot.queryParams['showform'] != undefined && this.route.snapshot.queryParams['showform'] != '') {
                            params.push("showform=" + this.route.snapshot.queryParams['showform']);
                        }
                        // handle legacy style URLs
                        if (this.route.snapshot.queryParams['discp']) {
                            params.push("discp=" + this.route.snapshot.queryParams['discp']);
                        }
                        if (this.route.snapshot.queryParams['discrp']) {
                            params.push("discrp=" + this.route.snapshot.queryParams['discrp']);
                        }
                        if (this.route.snapshot.queryParams['discrpc']) {
                            params.push("discrpc=" + this.route.snapshot.queryParams['discrpc']);
                        }
                        if (!this.route.snapshot.queryParams['cnc']) return [3 /*break*/, 5];
                        params.push("cnc=" + this.route.snapshot.queryParams['cnc']);
                        if (!!this.isTeamsPlusUser) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.cookiesSetterForCNC()];
                    case 4:
                        _d.sent();
                        _d.label = 5;
                    case 5:
                        if (this.route.snapshot.queryParams['allowtrial']) {
                            params.push("allowtrial=" + this.route.snapshot.queryParams['allowtrial']);
                        }
                        if (this.route.snapshot.queryParams['payrequired']) {
                            params.push("payrequired=" + this.route.snapshot.queryParams['payrequired']);
                        }
                        if (this.route.snapshot.queryParams['min_user']) {
                            params.push("min_user=" + this.route.snapshot.queryParams['min_user']);
                        }
                        window.location.href = environment_1.environment.ordershost + "/" + type + "/" + this.route.snapshot.queryParams['p'] + "?" + params.join('&');
                        return [2 /*return*/];
                    case 6:
                        if (this.user.plan_user_limit < this.userService.MIN_USERS && !this.user.is_multi_new) {
                            this.teamsQty = this.userService.MIN_USERS;
                        }
                        else if (this.user.is_multi_new && zprofile && zprofile.subscription) {
                            this.teamsQty = zprofile.subscription.quantity;
                        }
                        else {
                            this.teamsQty = this.user.plan_user_limit;
                        }
                        this.userLimit = Array(this.userService.MAX_USERS - (this.teamsQty - 2)).fill(0).map(function (x, i) { return i + _this.teamsQty; });
                        this.planterm = this.user.is_multi_new ? this.skuService.getPlanTerm(zprofile.subscription.sku) : this.skuService.getPlanTerm(this.user.sku);
                        isOnAnnualPlan = this.planterm == plandetails_model_1.PlanTerm.ANNUAL;
                        isOnMonthlyPlan = this.planterm == plandetails_model_1.PlanTerm.MONTHLY;
                        canToggleToMonthly = true;
                        if (isOnAnnualPlan && zprofile && zprofile.subscription) {
                            endDate = this.userService.dateConversionForTrials(zprofile.subscription.termEndDate, this.user.is_on_trial);
                            curDate = new Date();
                            if ((endDate.getTime() - curDate.getTime()) > 30 * 24 * 60 * 60 * 1000) {
                                canToggleToMonthly = false;
                            }
                        }
                        showMonthlyPlan = (isOnMonthlyPlan || (isOnAnnualPlan && canToggleToMonthly)) || this.user.is_on_trial;
                        isUserOnTeamsPlus = (this.user.is_multi_new) || (this.user.is_pro && this.user.plan_user_limit === 1) || !(this.user.is_pro);
                        this.isTeamsOnTrial = !(zprofile && zprofile.subscription && zprofile.subscription.quantity > 1 && this.user.is_on_trial);
                        if (this.products.PER && this.isTeamsOnTrial) {
                            personalPlanSku_1 = '200G';
                            personalProductSku_1 = 'PER';
                            this.personalPlan.annual = this.products.PER.yearlyRatePlans.filter(function (plan) { return plan.sku.toUpperCase() == personalPlanSku_1 && plan.product_sku.toUpperCase() == personalProductSku_1; })[0];
                            this.personalPlan.trialPlan.annual = this.products.PER.trialRatePlans.filter(function (trialRatePlan) { return trialRatePlan.sku === _this.personalPlan.annual.sku && trialRatePlan.product_sku === _this.personalPlan.annual.product_sku; })[0];
                        }
                        if (this.products.PS && this.isTeamsOnTrial) {
                            soloBasicPlanSku_1 = '2T';
                            soloBasicProductSku_1 = 'PS';
                            this.soloBasicPlan.annual = this.products.PS.yearlyRatePlans.filter(function (plan) { return plan.sku.toUpperCase() == soloBasicPlanSku_1 && plan.product_sku.toUpperCase() == soloBasicProductSku_1; })[0];
                            this.soloBasicPlan.trialPlan.annual = this.products.PS.trialRatePlans.filter(function (trialRatePlan) { return trialRatePlan.sku === _this.soloBasicPlan.annual.sku && trialRatePlan.product_sku === _this.soloBasicPlan.annual.product_sku; })[0];
                            soloProfessionalPlanSku_1 = '6T';
                            soloProfessionalProductSku_1 = 'PSP';
                            this.soloProfessionalPlan.annual = this.products.PS.yearlyRatePlans.filter(function (plan) { return plan.sku.toUpperCase() == soloProfessionalPlanSku_1 && plan.product_sku.toUpperCase() == soloProfessionalProductSku_1; })[0];
                            this.soloProfessionalPlan.monthly = showMonthlyPlan && this.products.PS.monthlyRatePlans.filter(function (plan) { return plan.sku.toUpperCase() == soloProfessionalPlanSku_1 && plan.product_sku.toUpperCase() == soloProfessionalProductSku_1; })[0];
                            this.soloProfessionalPlan.trialPlan.annual = this.products.PS.trialRatePlans.filter(function (trialRatePlan) { return trialRatePlan.sku === _this.soloProfessionalPlan.annual.sku && trialRatePlan.product_sku === _this.soloProfessionalPlan.annual.product_sku; })[0];
                            this.soloProfessionalPlan.trialPlan.monthly = showMonthlyPlan && this.products.PS.trialRatePlans.filter(function (trialRatePlan) { return trialRatePlan.sku === _this.soloProfessionalPlan.monthly.sku && trialRatePlan.product_sku === _this.soloProfessionalPlan.monthly.product_sku; })[0];
                        }
                        if (this.products.BPT) {
                            teamsStandardPlanSku_1 = '1T';
                            teamsStandardProductSku_1 = isUserOnTeamsPlus ? 'BPTSP' : 'BPT';
                            this.teamsStandardPlan.annual = this.products.BPT.yearlyRatePlans.filter(function (plan) { return plan.sku.toUpperCase() == teamsStandardPlanSku_1 && plan.product_sku.toUpperCase() == teamsStandardProductSku_1; })[0];
                            this.teamsStandardPlan.trialPlan.annual = this.products.BPT.trialRatePlans.filter(function (trialRatePlan) { return trialRatePlan.sku === _this.teamsStandardPlan.annual.sku && trialRatePlan.product_sku === _this.teamsStandardPlan.annual.product_sku; })[0];
                            if (isUserOnTeamsPlus) {
                                this.teamsStandardPlan.name = 'Teams+ Standard';
                            }
                            teamsUnlimitedPlanSku_1 = 'UNLIMITED';
                            teamsUnlimitedProductSku_1 = isUserOnTeamsPlus ? 'BPTUP' : 'BPTU';
                            this.teamsUnlimited.annual = this.products.BPT.yearlyRatePlans.filter(function (plan) { return plan.sku.toUpperCase() == teamsUnlimitedPlanSku_1 && plan.product_sku.toUpperCase() == teamsUnlimitedProductSku_1; })[0];
                            this.teamsUnlimited.monthly = showMonthlyPlan && this.products.BPT.monthlyRatePlans.filter(function (plan) { return plan.sku.toUpperCase() == teamsUnlimitedPlanSku_1 && plan.product_sku.toUpperCase() == teamsUnlimitedProductSku_1; })[0];
                            this.teamsUnlimited.trialPlan.annual = this.products.BPT.trialRatePlans.filter(function (trialRatePlan) { return trialRatePlan.sku === _this.teamsUnlimited.annual.sku && trialRatePlan.product_sku === _this.teamsUnlimited.annual.product_sku; })[0];
                            this.teamsUnlimited.trialPlan.monthly = showMonthlyPlan && this.products.BPT.trialRatePlans.filter(function (trialRatePlan) { return trialRatePlan.sku === _this.teamsUnlimited.monthly.sku && trialRatePlan.product_sku === _this.teamsUnlimited.monthly.product_sku; })[0];
                            if (isUserOnTeamsPlus) {
                                this.teamsUnlimited.name = 'Teams+ Unlimited';
                            }
                        }
                        if (this.user.is_on_trial) {
                            sku = this.user.sku;
                            if (!sku) {
                                sku = zprofile && zprofile.subscription && zprofile.subscription.sku;
                            }
                            _c = sku.toUpperCase().split('-'), product = _c[0], productSku = _c[1];
                            if ((product === 'BPT' || product === 'BPTSP') && productSku === '1T') {
                                this.currentTrialPlan = this.teamsStandardPlan;
                            }
                            else if ((product === 'BPTU' || product === 'BPTUP') && productSku === 'UNLIMITED') {
                                this.currentTrialPlan = this.teamsUnlimited;
                            }
                            else if ((product === 'PS' || product === 'PSP') && (productSku === '2T' || productSku === '6T')) {
                                this.currentTrialPlan = product === 'PS' ? this.soloBasicPlan : this.soloProfessionalPlan;
                            }
                        }
                        this.spinner = false;
                        return [3 /*break*/, 8];
                    case 7:
                        this.spinner = false;
                        this.errcode = new models_1.ErrCode(8520);
                        _d.label = 8;
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        ex_2 = _d.sent();
                        this.spinner = false;
                        this.errcode = models_1.ErrCode.fromException(ex_2);
                        this.log.error("Loading the account upgrades page failed " + JSON.stringify(ex_2));
                        return [3 /*break*/, 10];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    UpgradeComponent.prototype.addUsers = function () {
        var ref = this.modalService.open(dialog_multiuser_add_component_1.DialogMultiuserAddComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.openState = 3; // should be an enum
        ref.componentInstance.addAction = 'order';
    };
    // COM-1188
    UpgradeComponent.prototype.confirmPassword = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ref;
            return tslib_1.__generator(this, function (_a) {
                ref = this.modalService.open(dialog_confirm_password_component_1.DialogConfirmPasswordComponent, {
                    backdropClass: 'in',
                    windowClass: 'in',
                    backdrop: 'static',
                });
                return [2 /*return*/, ref.result];
            });
        });
    };
    // COM-1188
    UpgradeComponent.prototype.fixUpgradePassword = function (password) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var encPassword;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.syncCrypt.userpasswordEncrypt(password, this.user.pubkey)];
                    case 1:
                        encPassword = _a.sent();
                        return [4 /*yield*/, this.api.execute('userinfoset', {
                                enc_password: encPassword
                            })];
                    case 2:
                        _a.sent();
                        this.encHash = encPassword;
                        return [2 /*return*/];
                }
            });
        });
    };
    return UpgradeComponent;
}());
exports.UpgradeComponent = UpgradeComponent;
