"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Role = /** @class */ (function () {
    function Role(role) {
        this.name = '';
        this.hidden = false;
        this.editable = false;
        this.name = role.name;
        this.users = role.users;
        this.permissions = role.permissions;
        this.hidden = role.hidden;
        this.editable = role.editable;
    }
    Role.prototype.deserialize = function (params) {
        for (var _i = 0, _a = Object.entries(params); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], val = _b[1];
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    };
    return Role;
}());
exports.Role = Role;
