"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var build_transfer_item_service_1 = require("../services/build-transfer-item.service");
var DownloadCompatComponent = /** @class */ (function () {
    function DownloadCompatComponent(activeModal, buildTransferItemService) {
        this.activeModal = activeModal;
        this.buildTransferItemService = buildTransferItemService;
    }
    DownloadCompatComponent.prototype.ngOnInit = function () {
        this.urlDownload = undefined;
        this.urlPreview = undefined;
        this.currentItem = this.item;
        this.currentItem.compat = true;
        this.currentItem.linkID = undefined;
    };
    DownloadCompatComponent.prototype.downloadFile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.urlDownload) {
                            window.location.href = this.urlDownload;
                        }
                        return [4 /*yield*/, this.buildTransferItemService.getCompatUrl(this.currentItem, this.buildTransferItemService.ACT_DOWNLOAD)];
                    case 1:
                        url = _a.sent();
                        this.urlDownload = url;
                        window.location.href = this.urlDownload;
                        return [2 /*return*/];
                }
            });
        });
    };
    DownloadCompatComponent.prototype.openFile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.urlPreview) {
                            window.location.href = this.urlPreview;
                        }
                        return [4 /*yield*/, this.buildTransferItemService.getCompatUrl(this.currentItem, this.buildTransferItemService.ACT_PREVIEW)];
                    case 1:
                        url = _a.sent();
                        this.urlPreview = url;
                        window.location.href = this.urlPreview;
                        return [2 /*return*/];
                }
            });
        });
    };
    return DownloadCompatComponent;
}());
exports.DownloadCompatComponent = DownloadCompatComponent;
