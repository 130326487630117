"use strict";
/* tslint:disable:no-bitwise */
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var webpath_model_1 = require("./webpath.model");
var LinkListItem = /** @class */ (function (_super) {
    tslib_1.__extends(LinkListItem, _super);
    function LinkListItem(params) {
        var _this = _super.call(this, params) || this;
        if (params) {
            _this.deserialize(params);
        }
        return _this;
    }
    LinkListItem.prototype.deserialize = function (params) {
        for (var _i = 0, _a = Object.entries(params); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], val = _b[1];
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    };
    return LinkListItem;
}(webpath_model_1.WebPath));
exports.LinkListItem = LinkListItem;
