"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var FeedbackService = /** @class */ (function () {
    function FeedbackService() {
        this.view = {
            globalTasks: {
                uniqid: '',
                msg: '',
                type: ''
            },
            globalError: [],
            feedbackType: '',
            showFeedback: false,
            feedbackMsg: ''
        };
    }
    /**
       * @ngdoc method
       * @name  setFeedback
       * @methodOf sync.service:Feedback
       * @description
       * Sets a feedback message
       * @param {String} type     The type of feedback corresponding to
       *                          bootstrap's 'success'|info|'danger'
       * @param {String} msg      The message to display
       * @param {Integer=} displayTime If defined, will auto-hide after X ms
       */
    FeedbackService.prototype.setFeedback = function (type, msg, displayTime) {
        var _this = this;
        this.view.feedbackType = type;
        this.view.showFeedback = true;
        this.view.feedbackMsg = msg;
        if (displayTime && displayTime > 0) {
            setTimeout(function () {
                _this.hideFeedback();
            }, displayTime);
        }
    };
    /**
     * @ngdoc method
     * @name  hideFeedback
     * @methodOf sync.service:Feedback
     * @description
     * Hides the feedback box
     */
    FeedbackService.prototype.hideFeedback = function () {
        this.view.feedbackType = '';
        this.view.showFeedback = false;
        this.view.feedbackMsg = '';
    };
    FeedbackService.ngInjectableDef = i0.defineInjectable({ factory: function FeedbackService_Factory() { return new FeedbackService(); }, token: FeedbackService, providedIn: "root" });
    return FeedbackService;
}());
exports.FeedbackService = FeedbackService;
