"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var LinkNewApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(LinkNewApiOutput, _super);
    function LinkNewApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LinkNewApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.LinkNewApiOutput = LinkNewApiOutput;
var LinkNewApiInput = /** @class */ (function () {
    function LinkNewApiInput() {
        this.command = 'linknew';
    }
    return LinkNewApiInput;
}());
exports.LinkNewApiInput = LinkNewApiInput;
