"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Progress = /** @class */ (function () {
    function Progress(total, progress) {
        this.total = total;
        this.progress = progress;
    }
    return Progress;
}());
exports.Progress = Progress;
