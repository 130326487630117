"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var models_1 = require("../../shared/models");
var file_item_service_1 = require("../file-item.service");
var file_list_service_1 = require("../../file-list/services/file-list.service");
var link_file_list_service_1 = require("../../link-consume/services/link-file-list.service");
var api_service_1 = require("../../core/api.service");
var router_1 = require("@angular/router");
var PreviewComponent = /** @class */ (function () {
    function PreviewComponent(pageTitleSevice, fileItemService, fileListService, linkPathListService, apiService, route, router) {
        this.pageTitleSevice = pageTitleSevice;
        this.fileItemService = fileItemService;
        this.fileListService = fileListService;
        this.linkPathListService = linkPathListService;
        this.apiService = apiService;
        this.route = route;
        this.router = router;
        this.list = [];
        this.initialized = false;
        this.upsell = false;
        this.notice = 0;
        this.isPro = 0; // not used, but the template requires it to be set
        this.PAGE_TITLE = 'Sync.com';
        this.notPreviewable = false;
    }
    PreviewComponent.prototype.ngOnInit = function () {
        this.prevRouterState = this.router.routeReuseStrategy.shouldReuseRoute;
        this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
        this.pageTitleSevice.setTitle(this.PAGE_TITLE);
        this.locId = this.route.snapshot.params['id'];
        this.ctx = this.route.snapshot.queryParams['ctx'];
        if (!this.type) {
            this.type = this.route.snapshot.params['type'];
        }
        this.initialize();
    };
    PreviewComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.router.routeReuseStrategy.shouldReuseRoute = this.prevRouterState;
    };
    PreviewComponent.prototype.onStateChange = function (event) {
        this.type = event.type;
        this.upsell = event.upsell;
        this.notPreviewable = true;
        console.log('onstatechange , ', event.type, event.upsell);
        this.initialize();
    };
    PreviewComponent.prototype.initialize = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dispatched, _a, _b, _c, previewType, ex_1;
            var _this = this;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.initialized = false;
                        dispatched = false;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _a = this;
                        _c = (_b = this.fileItemService).format;
                        return [4 /*yield*/, this.apiService.execute('pathget', {
                                sync_id: this.locId,
                            })];
                    case 2: return [4 /*yield*/, _c.apply(_b, [_d.sent()])];
                    case 3:
                        _a.item = _d.sent();
                        previewType = this.fileItemService.previewFileType(this.item);
                        if (!this.notPreviewable && this.type !== previewType && this.type != 'officefse' && this.type != 'officefsv') {
                            this.type = previewType;
                            this.changeUrlType(this.type);
                        }
                        this.pageTitleSevice.setTitle(this.PAGE_TITLE + " - " + this.item.name);
                        if ((this.type != 'default' && this.type != 'office' && this.type != 'officefse' && this.type != 'officefsv') &&
                            !this.fileItemService.isPreviewable(this.item)) {
                            this.type = 'default';
                            this.notice = 1;
                        }
                        if (this.type == 'image') {
                            this.subscription = this.fileListService
                                .getListSubscription()
                                .subscribe(function (data) {
                                if (data && data.pathlist && data.pathlist.length) {
                                    _this.list = data.pathlist.filter(function (val) {
                                        return _this.fileItemService.isImage(val);
                                    });
                                    _this.initialized = true;
                                }
                                else if (!dispatched) {
                                    var input = new models_1.PathListApiInput();
                                    input.sync_id = _this.item.pid;
                                    input.hist_id = 0;
                                    input.showdeleted = 0;
                                    _this.fileListService.dispatch(input);
                                    dispatched = true;
                                }
                            });
                        }
                        else {
                            this.initialized = true;
                        }
                        //white label attributes
                        this.subscription = this.linkPathListService
                            .getSubscription()
                            .subscribe(function (data) {
                            if (data.loaded && data.sorted) {
                                if (data.image_cachekey) {
                                    _this.isWhiteLabel = true;
                                    _this.linkTextColor = data.link_text_color;
                                }
                            }
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        ex_1 = _d.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        this.type = 'error';
                        this.initialized = true;
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PreviewComponent.prototype.changeUrlType = function (newType) {
        var fullUrl = this.router.url;
        // Replace the last segment (e.g., /default) with the new type (e.g., /doc)
        // Regex: /\/[^\/]*$/ matches the last segment after the final '/' in the URL
        // Example: /file/15000002/view/default -> /file/15000002/view/doc
        var newUrl = fullUrl.replace(/\/[^\/]*$/, "/" + newType);
        this.router.navigateByUrl(newUrl);
    };
    return PreviewComponent;
}());
exports.PreviewComponent = PreviewComponent;
