"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var rxjs_1 = require("rxjs");
var api_service_1 = require("../core/api.service");
var sync_crypt_service_1 = require("../core/crypt/sync-crypt.service");
var logger_service_1 = require("../core/logger.service");
var notifications_service_1 = require("../core/notifications.service");
var broadcast_service_1 = require("../shared/services/broadcast.service");
var batch_copy_service_1 = require("./batch-copy.service");
var models_1 = require("../shared/models");
var joblock_service_1 = require("../../../src/app/core/joblock.service");
var i0 = require("@angular/core");
var i1 = require("../shared/services/broadcast.service");
var i2 = require("../core/logger.service");
var i3 = require("../core/crypt/sync-crypt.service");
var i4 = require("../core/notifications.service");
var i5 = require("../core/api.service");
var i6 = require("../core/joblock.service");
var BatchMoveService = /** @class */ (function (_super) {
    tslib_1.__extends(BatchMoveService, _super);
    function BatchMoveService(broadcastService, loggerService, syncCryptService, notificationsService, apiService, JobLockService) {
        var _this = _super.call(this, broadcastService, loggerService, syncCryptService, notificationsService, apiService) || this;
        _this.broadcastService = broadcastService;
        _this.loggerService = loggerService;
        _this.syncCryptService = syncCryptService;
        _this.notificationsService = notificationsService;
        _this.apiService = apiService;
        _this.JobLockService = JobLockService;
        return _this;
    }
    BatchMoveService.prototype.execute = function (syncIds, destSyncId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var syncArray;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loggerService.info("batchMove(" + syncIds.toString() + ")");
                        this.ACTION = this.ACT_MOVE;
                        this.view.globalCancel = false;
                        this.view.completedBatch = [];
                        this.view.total = syncIds.length;
                        syncArray = syncIds.slice();
                        return [4 /*yield*/, this._moveFolder(syncArray, destSyncId)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    BatchMoveService.prototype._moveFolder = function (syncIds, syncPid, nameData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var shareData, dumpDirsList, syncId, dumpList, retryAmt, syncIDList, syncHash, key, syncArray, shareAcceptData, i, len, pathdata, shareAccept, bg, response, err_1, syncIdArray, errData_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        syncId = syncIds.pop();
                        this.view.spinner = syncId;
                        this.loggerService.info("sync ids " + syncIds.toString() + " to " + syncPid);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 21, , 22]);
                        return [4 /*yield*/, this.dumpDirs(syncId)];
                    case 2:
                        dumpList = _a.sent();
                        retryAmt = 0, syncIDList = [], syncHash = dumpList.sync_ids;
                        dumpDirsList = dumpList;
                        shareData = dumpList.share;
                        if (!syncHash[syncId]) {
                            this.loggerService.error('Could not find the sync id in the batchdumpdirs result? ' +
                                syncId);
                            throw { errcode: 9000 };
                        }
                        syncHash[syncId].new_sync_id = syncPid;
                        this.view.total = dumpList.cnt * 2;
                        // convert to array first so we can recursively run a function and
                        // not worry about a loop outracing our API call.
                        for (key in syncHash) {
                            if (syncHash[key].sync_id == syncId) {
                                syncHash[key].nameData = nameData;
                            }
                            syncIDList.push(syncHash[key]);
                        }
                        return [4 /*yield*/, this._copyFolderContents(syncIDList, 0, models_1.WebpathActionType.MOVE)];
                    case 3:
                        syncArray = _a.sent();
                        shareAcceptData = void 0;
                        if (!(shareData && shareData.share_id && shareData.sharemember_id)) return [3 /*break*/, 14];
                        this.loggerService.info('share data exists');
                        for (i = 0, len = syncArray.length; i < len; i++) {
                            if (syncArray[i].new_metaname &&
                                syncArray[i].new_sync_pid) {
                                shareAcceptData = syncArray[i];
                            }
                        }
                        if (!(shareAcceptData &&
                            shareAcceptData.new_sync_pid &&
                            shareAcceptData.new_metaname)) return [3 /*break*/, 12];
                        this.loggerService.info('found share on original sync id, leaving');
                        this.loggerService.info('share id: ' + shareData.share_id);
                        this.loggerService.info('share member: ' + shareData.sharemember_id);
                        return [4 /*yield*/, this.apiService.execute('sharememberleave', {
                                share_id: shareData.share_id,
                                sharemember_id: shareData.sharemember_id,
                                bg: 0,
                            })];
                    case 4:
                        _a.sent();
                        this.loggerService.info('Checking if new sync id is a share ...');
                        return [4 /*yield*/, this.apiService.execute('pathget', {
                                sync_id: shareAcceptData.new_sync_pid,
                            })];
                    case 5:
                        pathdata = _a.sent();
                        this.loggerService.info(pathdata.is_shared
                            ? 'New sync is shared'
                            : 'New sync is not shared, re-attach');
                        if (!pathdata.is_shared) return [3 /*break*/, 6];
                        return [2 /*return*/]; // resolve immediately
                    case 6:
                        _a.trys.push([6, 10, , 11]);
                        return [4 /*yield*/, this.apiService.execute('shareaccept', {
                                uniqid: shareData.sharemember_id,
                                enc_name: shareAcceptData.new_metaname,
                                accept: 1,
                                sync_pid: shareAcceptData.new_sync_pid,
                                sharedata: {},
                                displayname: shareData.display_name,
                                bg: 1,
                            })];
                    case 7:
                        shareAccept = _a.sent();
                        bg = shareAccept['bg'];
                        if (!bg) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.JobLockService.pollJobStatus()];
                    case 8:
                        response = _a.sent();
                        this.loggerService.info('ShareAccept_JobComplete');
                        _a.label = 9;
                    case 9: return [3 /*break*/, 11];
                    case 10:
                        err_1 = _a.sent();
                        this.loggerService.handleError('_moveFolder.shareacceptdata', err_1);
                        return [3 /*break*/, 11];
                    case 11: return [3 /*break*/, 13];
                    case 12:
                        this.loggerService.error('Failed to find share accept data to re-attach');
                        _a.label = 13;
                    case 13: return [3 /*break*/, 15];
                    case 14:
                        this.loggerService.info('No share data found');
                        _a.label = 15;
                    case 15:
                        if (this.view.globalCancel) {
                            return [2 /*return*/];
                        }
                        syncIdArray = this._dumpDirsToSyncIds(dumpDirsList);
                        return [4 /*yield*/, this.executeMove(syncIdArray).toPromise()];
                    case 16:
                        _a.sent();
                        return [4 /*yield*/, this.batchSuccess()];
                    case 17:
                        _a.sent();
                        if (!syncIds.length) return [3 /*break*/, 19];
                        return [4 /*yield*/, this._moveFolder(syncIds, syncPid, nameData)];
                    case 18:
                        _a.sent();
                        return [3 /*break*/, 20];
                    case 19:
                        this.view.spinner = 0;
                        return [2 /*return*/];
                    case 20: return [3 /*break*/, 22];
                    case 21:
                        errData_1 = _a.sent();
                        this.loggerService.handleError('_moveRecursive', errData_1);
                        return [3 /*break*/, 22];
                    case 22: return [2 /*return*/];
                }
            });
        });
    };
    BatchMoveService.prototype._dumpDirsToSyncIds = function (data) {
        var list = [];
        for (var key in data.sync_ids) {
            list.push(data.sync_ids[key].sync_id);
        }
        return list;
    };
    BatchMoveService.prototype.dumpDirs = function (syncId, activeLevel, completed) {
        var string = activeLevel || 1;
        this.view.completed = completed || 0;
        return this.apiService.execute('batchdumpdirs', {
            sync_id: syncId,
            active_level: string.toString(),
        });
    };
    BatchMoveService.prototype.executeMove = function (syncIds) {
        var subject = new rxjs_1.Subject();
        this.loggerService.info("batchDeleteMove(" + syncIds.toString() + ")");
        this.ACTION = this.ACT_MOVE;
        console.log('executeMove running for ' + this.view.total);
        this.runBatchItems(syncIds, subject);
        return subject;
    };
    BatchMoveService.prototype.prepareItemForAction = function (syncId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dumpList, dumpItems, syncIds, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.view.globalCancel) return [3 /*break*/, 1];
                        return [2 /*return*/, this.handleCancel()];
                    case 1:
                        this.resetProgress();
                        this.view.spinner = syncId;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 7, , 8]);
                        return [4 /*yield*/, this.dumpDirs(syncId, 1, this.view.completed)];
                    case 3:
                        dumpList = _a.sent();
                        return [4 /*yield*/, this.dropShare(dumpList)];
                    case 4:
                        dumpItems = _a.sent();
                        return [4 /*yield*/, this.convertToArray(dumpItems)];
                    case 5:
                        syncIds = _a.sent();
                        if (this.view.globalCancel) {
                            return [2 /*return*/, this.handleCancel()];
                        }
                        syncIds.reverse();
                        return [4 /*yield*/, this.runProcessTask(syncIds, 0)];
                    case 6: return [2 /*return*/, _a.sent()];
                    case 7:
                        err_2 = _a.sent();
                        console.log('batch-move prepareItemForAction err: ', err_2);
                        return [2 /*return*/, this.loggerService.handleError('batch-move prepareItemForAction', err_2)];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    BatchMoveService.prototype.apiExecuteTask = function (syncId) {
        return this.apiService.execute('batchdelete', { sync_id: syncId });
    };
    BatchMoveService.ngInjectableDef = i0.defineInjectable({ factory: function BatchMoveService_Factory() { return new BatchMoveService(i0.inject(i1.BroadcastService), i0.inject(i2.LoggerService), i0.inject(i3.SyncCryptService), i0.inject(i4.NotificationsService), i0.inject(i5.ApiService), i0.inject(i6.JoblockService)); }, token: BatchMoveService, providedIn: "root" });
    return BatchMoveService;
}(batch_copy_service_1.BatchCopyService));
exports.BatchMoveService = BatchMoveService;
