"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var environment_1 = require("./../../environments/environment");
var base64_service_1 = require("./base64.service");
var sync_crypt_service_1 = require("./crypt/sync-crypt.service");
var logger_service_1 = require("../core/logger.service");
var http_1 = require("@angular/common/http");
var commments_model_1 = require("../shared/models/commments.model");
var api_service_1 = require("./api.service");
var link_list_service_1 = require("../links/services/link-list.service");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("./logger.service");
var i3 = require("./crypt/sync-crypt.service");
var i4 = require("./api.service");
var i5 = require("../links/services/link-list.service");
var i6 = require("./base64.service");
var CommentService = /** @class */ (function () {
    function CommentService(httpClient, log, crypt, api, linkList, base64) {
        this.httpClient = httpClient;
        this.log = log;
        this.crypt = crypt;
        this.api = api;
        this.linkList = linkList;
        this.base64 = base64;
        this.path = environment_1.environment.commenthost;
        // window.addEventListener('message', this.handleIFrameMessage.bind(this));
    }
    CommentService.prototype.createComment = function (signedInput) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.httpClient
                                .post(this.path, signedInput)
                                .toPromise()];
                    case 1:
                        result = _a.sent();
                        this.log.info(result.status + " " + this.path + ": successful");
                        return [2 /*return*/, Promise.resolve(result)];
                    case 2:
                        err_1 = _a.sent();
                        this.handleError(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CommentService.prototype.getComments = function (key, signedInput) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, _i, _a, _b, key_1, val, result, err_2;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        url = this.path + "/link/" + key;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        params = new http_1.HttpParams();
                        for (_i = 0, _a = Object.entries(signedInput); _i < _a.length; _i++) {
                            _b = _a[_i], key_1 = _b[0], val = _b[1];
                            params = params.set(key_1, val); //since HttpParams is immutable, reasingment needed
                        }
                        return [4 /*yield*/, this.httpClient
                                .get(url, { params: params })
                                .toPromise()];
                    case 2:
                        result = _c.sent();
                        this.log.info(result.status + " " + url + ": successful");
                        return [2 /*return*/, Promise.resolve(result)];
                    case 3:
                        err_2 = _c.sent();
                        this.handleError(err_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CommentService.prototype.updateComment = function (data, signedInput) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, result, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.path + "/" + data._id;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.httpClient
                                .patch(url, signedInput)
                                .toPromise()];
                    case 2:
                        result = _a.sent();
                        this.log.info(result.status + " " + this.path + ": successful");
                        return [2 /*return*/, Promise.resolve(result)];
                    case 3:
                        err_3 = _a.sent();
                        this.handleError(err_3);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CommentService.prototype.deleteComment = function (data, signedInput) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, _i, _a, _b, key, val, result, err_4;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        url = this.path + "/" + data._id;
                        params = new http_1.HttpParams();
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        for (_i = 0, _a = Object.entries(signedInput); _i < _a.length; _i++) {
                            _b = _a[_i], key = _b[0], val = _b[1];
                            params = params.set(key, val); //since HttpParams is immutable, reassingment needed
                        }
                        return [4 /*yield*/, this.httpClient
                                .delete(url, { params: params })
                                .toPromise()];
                    case 2:
                        result = _c.sent();
                        this.log.info(result.status + " " + url + ": successful");
                        return [2 /*return*/, Promise.resolve(result)];
                    case 3:
                        err_4 = _c.sent();
                        this.handleError(err_4);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CommentService.prototype.handleError = function (response) {
        if (response.status) {
            switch (response.status) {
                case 422:
                    this.log.error("error status " + response.status + ": " + response.error.message + ", detail: " + JSON.stringify(response.error.detail, null, 4));
                    break;
                case 429:
                    this.log.error("error status " + response.status + ": " + response.error.message);
                    break;
                default:
                    this.log.error("error status " + response.status + ": " + response.error.message + "\n detail:" + JSON.stringify(response.error.detail, null, 4));
            }
        }
        else {
            this.log.error(JSON.stringify(response));
        }
        throw response;
    };
    CommentService.prototype.createReply = function (newReply, signedInput) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, result, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.path + "/" + newReply.parent_id;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.httpClient
                                .post(url, signedInput)
                                .toPromise()];
                    case 2:
                        result = _a.sent();
                        this.log.info(result.status + " " + url + ": successful");
                        return [2 /*return*/, Promise.resolve(result)];
                    case 3:
                        err_5 = _a.sent();
                        this.handleError(err_5);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CommentService.prototype.updateReply = function (data, signedInput) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, result, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.path + "/" + data.parent_id + "/" + data.reply_id;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.httpClient
                                .patch(url, signedInput)
                                .toPromise()];
                    case 2:
                        result = _a.sent();
                        this.log.info(result.status + " " + url + ": successful");
                        return [2 /*return*/, Promise.resolve(result)];
                    case 3:
                        err_6 = _a.sent();
                        this.handleError(err_6);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CommentService.prototype.deleteReply = function (data, signedInput) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, _i, _a, _b, key, val, result, err_7;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        url = this.path + "/" + data.parent_id + "/" + data.reply_id;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        params = new http_1.HttpParams();
                        for (_i = 0, _a = Object.entries(signedInput); _i < _a.length; _i++) {
                            _b = _a[_i], key = _b[0], val = _b[1];
                            params = params.set(key, val); //since HttpParams is immutable, reasingment needed
                        }
                        return [4 /*yield*/, this.httpClient
                                .delete(url, { params: params })
                                .toPromise()];
                    case 2:
                        result = _c.sent();
                        this.log.info(result.status + " " + url + ": successfully deleted reply");
                        return [2 /*return*/, result];
                    case 3:
                        err_7 = _c.sent();
                        this.handleError(err_7);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * encrypt a comment/reply content using a naked comment key
     * @param key naked/decrypted comment key
     * @param content a comment/reply content
     * @return content String based64 encrypted string content
     */
    CommentService.prototype.encryptContent = function (key, content) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var encryptedData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.crypt.filedataEncrypt(this.crypt.stringToBytes(content), this.crypt.b64ToBytes(key), 0)];
                    case 1:
                        encryptedData = _a.sent();
                        return [2 /*return*/, this.crypt.bytesToB64(encryptedData)];
                }
            });
        });
    };
    /**
     * decrypt comment/reply content using a naked comment key
     * @param key decrypted/naked comment key
     * @param content comment/reply content
     * @param id comment or reply ID
     * @return content String the naked content
     */
    CommentService.prototype.decryptContent = function (key, content, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var decryptedData, err_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.crypt.filedataDecrypt(this.crypt.b64ToBytes(content), this.crypt.b64ToBytes(key), 0)];
                    case 1:
                        decryptedData = _a.sent();
                        return [2 /*return*/, this.crypt.bytesToString(decryptedData)];
                    case 2:
                        err_8 = _a.sent();
                        // console.log('CDK: ' + key);
                        // console.log('comment content: ' + content);
                        this.log.warn("failed to decrypt content for comment or reply id: " + id + ". Ignoring...");
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CommentService.prototype.getCommentCount = function (locIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, input, signedInput, params, _i, _a, _b, key, val, result, response, err_9;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        url = this.path + "/count";
                        input = {
                            servtime: Date.now(),
                            access_token: '',
                            signature: '',
                        };
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.crypt.signApiReq(input)];
                    case 2:
                        signedInput = _c.sent();
                        params = new http_1.HttpParams();
                        for (_i = 0, _a = Object.entries(signedInput); _i < _a.length; _i++) {
                            _b = _a[_i], key = _b[0], val = _b[1];
                            params = params.set(key, val); //since HttpParams is immutable, reasingment needed
                        }
                        params = params.append('locIds', locIds.join(','));
                        return [4 /*yield*/, this.httpClient
                                .get(url, { params: params })
                                .toPromise()];
                    case 3:
                        result = _c.sent();
                        this.log.info(result.status + " " + url + ": successful");
                        response = result;
                        if (response.data) {
                            return [2 /*return*/, Promise.resolve(response.data)];
                        }
                        else {
                            return [2 /*return*/, Promise.resolve(null)];
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        err_9 = _c.sent();
                        this.handleError(err_9);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    CommentService.prototype.checkNewComments = function (signedInput) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, _i, _a, _b, key, val, result, response, err_10;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        url = this.path + "/peek";
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        params = new http_1.HttpParams();
                        for (_i = 0, _a = Object.entries(signedInput); _i < _a.length; _i++) {
                            _b = _a[_i], key = _b[0], val = _b[1];
                            params = params.set(key, val); //since HttpParams is immutable, reasingment needed
                        }
                        return [4 /*yield*/, this.httpClient
                                .get(url, { params: params })
                                .toPromise()];
                    case 2:
                        result = _c.sent();
                        this.log.info(result.status + " " + url + ": successful");
                        response = result;
                        return [2 /*return*/, response.data];
                    case 3:
                        err_10 = _c.sent();
                        this.handleError(err_10);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CommentService.ngInjectableDef = i0.defineInjectable({ factory: function CommentService_Factory() { return new CommentService(i0.inject(i1.HttpClient), i0.inject(i2.LoggerService), i0.inject(i3.SyncCryptService), i0.inject(i4.ApiService), i0.inject(i5.LinkListService), i0.inject(i6.Base64Service)); }, token: CommentService, providedIn: "root" });
    return CommentService;
}());
exports.CommentService = CommentService;
