"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../shared/components/header/user/header-user.component.ngfactory");
var i2 = require("../../shared/components/header/user/header-user.component");
var i3 = require("@ngrx/store");
var i4 = require("../../core/user.service");
var i5 = require("@ng-bootstrap/ng-bootstrap");
var i6 = require("../../core/logger.service");
var i7 = require("../../account/services/zuora-account.service");
var i8 = require("@angular/router");
var i9 = require("../../shared/services/blend.service");
var i10 = require("../../core/api.service");
var i11 = require("../../shared/services/feature.service");
var i12 = require("../../notifications/notifications/notifications.component.ngfactory");
var i13 = require("../../notifications/notifications/notifications.component");
var i14 = require("../../notifications/feedback.service");
var i15 = require("../../core/notifications.service");
var i16 = require("../../shared/components/footer/footer.component.ngfactory");
var i17 = require("../../shared/components/footer/footer.component");
var i18 = require("./footer-layout.component");
var styles_FooterLayoutComponent = [];
var RenderType_FooterLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterLayoutComponent, data: {} });
exports.RenderType_FooterLayoutComponent = RenderType_FooterLayoutComponent;
function View_FooterLayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-header-user", [], null, null, null, i1.View_HeaderUserComponent_0, i1.RenderType_HeaderUserComponent)), i0.ɵdid(1, 245760, null, 0, i2.HeaderUserComponent, [i3.Store, i4.UserService, i5.NgbModal, i6.LoggerService, i7.ZuoraAccountService, i8.Router, i9.BlendService, i10.ApiService, i11.FeatureService], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "sync-notifications", [], null, null, null, i12.View_NotificationsComponent_0, i12.RenderType_NotificationsComponent)), i0.ɵdid(3, 114688, null, 0, i13.NotificationsComponent, [i14.FeedbackService, i15.NotificationsService, i4.UserService], null, null), (_l()(), i0.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(5, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "sync-footer", [], null, null, null, i16.View_FooterComponent_0, i16.RenderType_FooterComponent)), i0.ɵdid(7, 114688, null, 0, i17.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
exports.View_FooterLayoutComponent_0 = View_FooterLayoutComponent_0;
function View_FooterLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-footer-layout", [], null, null, null, View_FooterLayoutComponent_0, RenderType_FooterLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i18.FooterLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_FooterLayoutComponent_Host_0 = View_FooterLayoutComponent_Host_0;
var FooterLayoutComponentNgFactory = i0.ɵccf("sync-footer-layout", i18.FooterLayoutComponent, View_FooterLayoutComponent_Host_0, {}, {}, []);
exports.FooterLayoutComponentNgFactory = FooterLayoutComponentNgFactory;
