"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../shared/directives/stop-propagation.directive");
var i2 = require("@angular/common");
var i3 = require("./inline-share-info.component");
var i4 = require("../../core/logger.service");
var i5 = require("../../shares/share.service");
var styles_InlineShareInfoComponent = [];
var RenderType_InlineShareInfoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InlineShareInfoComponent, data: {} });
exports.RenderType_InlineShareInfoComponent = RenderType_InlineShareInfoComponent;
function View_InlineShareInfoComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " users collaborating"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.share.people.length; _ck(_v, 1, 0, currVal_0); }); }
function View_InlineShareInfoComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-default"], ["syncStopPropagation", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).click($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.openDetails() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.StopPropagationDirective, [], null, null), (_l()(), i0.ɵted(-1, null, [" Manage share folder "])), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "right-menu-button-info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["class", "zmdi zmdi-account-circle"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InlineShareInfoComponent_2)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.share.isSeeOthers == 1); _ck(_v, 7, 0, currVal_0); }, null); }
function View_InlineShareInfoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_InlineShareInfoComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.initialized && (_co.share.people.length > 1)); _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_InlineShareInfoComponent_0 = View_InlineShareInfoComponent_0;
function View_InlineShareInfoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-inline-share-info", [], null, null, null, View_InlineShareInfoComponent_0, RenderType_InlineShareInfoComponent)), i0.ɵdid(1, 114688, null, 0, i3.InlineShareInfoComponent, [i4.LoggerService, i5.ShareService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_InlineShareInfoComponent_Host_0 = View_InlineShareInfoComponent_Host_0;
var InlineShareInfoComponentNgFactory = i0.ɵccf("sync-inline-share-info", i3.InlineShareInfoComponent, View_InlineShareInfoComponent_Host_0, { shareId: "syncInlineShareInfo" }, {}, []);
exports.InlineShareInfoComponentNgFactory = InlineShareInfoComponentNgFactory;
