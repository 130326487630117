"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var share_list_member_model_1 = require("./share-list-member.model");
var AvatarUser = /** @class */ (function (_super) {
    tslib_1.__extends(AvatarUser, _super);
    function AvatarUser() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AvatarUser.prototype.deserialize = function (input) {
        Object.assign(this, input);
        return this;
    };
    return AvatarUser;
}(share_list_member_model_1.ShareListMember));
exports.AvatarUser = AvatarUser;
