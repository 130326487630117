"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var broadcast_service_1 = require("../services/broadcast.service");
var HideToolsDirective = /** @class */ (function () {
    function HideToolsDirective(broadcastService) {
        this.broadcastService = broadcastService;
    }
    HideToolsDirective.prototype.click = function (event) {
        this.broadcastService.broadcast('sync.hide.tools');
        this.broadcastService.broadcast('event:hide.tools');
    };
    return HideToolsDirective;
}());
exports.HideToolsDirective = HideToolsDirective;
