"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var sku_service_1 = require("../../shared/services/sku.service");
var logger_service_1 = require("../../core/logger.service");
var PlanDetailsComponent = /** @class */ (function () {
    function PlanDetailsComponent(store, skuService, loggerService) {
        this.store = store;
        this.skuService = skuService;
        this.loggerService = loggerService;
        this.viewTerm = 'annual';
        this.teamsQty = 0;
        this.isDisabled = false;
        this.showTrial = false;
        this.showPlanUpgradedNote = false;
        this.isButtonDisabled = false;
        this.planSKU = '';
    }
    PlanDetailsComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.subscription = this.store.select(fromRoot.getAuthUser)
                    .subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var _a;
                    return tslib_1.__generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                this.user = data;
                                this.loggerService.info('Generating plans for user.sku = ' + this.user.sku);
                                _a = this;
                                return [4 /*yield*/, this.skuService.getProductPlanSKU(this.user.sku)];
                            case 1:
                                _a.planSKU = _b.sent();
                                if (this.plan.family == 'BPT') {
                                    if (this.planSKU === 'PS' && this.teamsQty < 3) {
                                        this.teamsQty = 3;
                                    }
                                    else if (this.planSKU === 'PSP' && this.teamsQty < 4) {
                                        this.teamsQty = this.user.is_on_trial ? 3 : 4;
                                    }
                                }
                                return [2 /*return*/];
                        }
                    });
                }); });
                this.viewTerm = this.plan[this.viewTerm].name ? this.viewTerm : this.viewTerm === 'annual' ? 'monthly' : 'annual';
                this.onTermChange(this.viewTerm);
                return [2 /*return*/];
            });
        });
    };
    PlanDetailsComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    PlanDetailsComponent.prototype.onTermChange = function (viewTerm) {
        this.viewTerm = viewTerm;
        if (this.plan['trialPlan'][viewTerm]) {
            this.showTrial = true;
        }
        else {
            this.showTrial = false;
        }
    };
    // add this function when current plan is Solo and upgraded to the team+ on standard plan.
    PlanDetailsComponent.prototype.onQtyChange = function (event) {
        this.showPlanUpgradedNote = false;
        this.isButtonDisabled = false;
        // handle special user limit rules for BPTSP
        if (this.plan[this.viewTerm].product_sku === 'BPTSP') {
            if (this.planSKU === 'PS') {
                this.loggerService.info("qty change PS -> BPTSP, chose " + event.target.value + " setting warning");
                // Set minimum users when going from PS-x-x sku to BPTSP-x-x to 3
                this.showPlanUpgradedNote = (event.target.value < 3);
                this.isButtonDisabled = (event.target.value < 3);
            }
            else if (this.planSKU === 'PSP' && !this.user.is_on_trial) {
                this.loggerService.info("qty change PSP -> BPTSP, chose " + event.target.value + " setting warning");
                // Set minimum users when going from PSP-x-x sku to BPTSP-x-x to 4
                this.showPlanUpgradedNote = (event.target.value < 4);
                this.isButtonDisabled = (event.target.value < 4);
            }
            else {
                this.showPlanUpgradedNote = false;
                this.isButtonDisabled = false;
            }
        }
    };
    return PlanDetailsComponent;
}());
exports.PlanDetailsComponent = PlanDetailsComponent;
