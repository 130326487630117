<!-- modalcontent -->
<div class="modal-header" ngbAutofocus>
  <button (click)="activeModal.close(true)" type="button" class="close" data-dismiss="modal" aria-label="Close"><span
      aria-hidden="true">&times;</span></button>
  <h4 class="modal-title">File transfers</h4>
</div>
<div class="modal-body">

  <div *ngIf="showUploadBtn()">
    <!-- no queue -->
    <div *ngIf="!isPublic" class="modal-transfer-empty"
      [ngClass]="(transferView.download.queue.length || transferView.upload.queue.length) ? 'modal-transfer-empty-height' : ''">
      <div class="modal-transfer-empty-inner">
        Upload folders or files by dragging them from your computer into this box,<br /> or click the choose files
        button, to select files.
        <p *ngIf="!testDirUpload()">
          <strong>Note:</strong> drag and drop folders require Chrome, Firefox or Microsoft Edge
        </p>
      </div>
    </div>
    <!-- no queue -->
    <div *ngIf="isPublic && showUploadBtn() && !transferView.download.queue.length && !transferView.upload.queue.length"
      class="modal-transfer-empty">
      <div class="modal-transfer-empty-inner">
        Upload folders or files to this link by dragging them from your computer into this box,<br /> or click the
        choose files button, to select files.
        <p *ngIf="!testDirUpload()">
          <strong>Note:</strong> drag and drop folders require Chrome, Firefox or Microsoft Edge
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="isPublic && !showUploadBtn() && !transferView.download.queue.length && !transferView.upload.queue.length">
    <p>No files currently transferring.</p>
  </div>

  <!--  queued files -->
  <div *ngFor="let queue of [transferView.download.queue, transferView.upload.queue]; let i=index;">
    <div [hidden]="!queue.length" class="row">
      <div class="col-sm-12">

        <table class="table list-table list-table-modal ">
          <thead>
            <tr>
              <th class="table-transferpreview" colspan="2">{{ queue === transferView.download.queue ? 'Downloads' : 'Uploads' }} ({{ queue.length }})</th>
              <th class="table-transfersize hidden-xs"></th>
              <th class="table-transferstatus"></th>
            </tr>
          </thead>
  
          <tbody *ngFor="let transferitem of queue;
          let i=index; trackBy: trackbyFunction">
            <tr>
              <td class="table-transferpreview"><img [src]="'images/icons/' + transferitem.filedata.cssclass + '.svg'"/></td>
              <td class="table-transfername">
                <div class="tdmore"><span [innerHtml]="transferitem.fullPath"></span>{{transferitem.filename}}</div>
              </td>
              <td class="table-transfersize hidden-xs">
                <div class="tdmore">{{transferitem.filesize | bytesToSize}}</div>
              </td>
              <td class="table-transferstatus">
                <i [class.hide]="transferitem.status > 1000" [ngClass]="{
                                'fa fa-spin fa-sync': transferitem.status >= 10 && transferitem.status < 20,
                                'fas fa-check': transferitem.status == 1 && transferitem.type == 20
                            }" title="{{ transferitem.status | translateTransferItem }}"></i>

                <button
                  *ngIf="transferitem.status >= 7000 
                        && transferitem.status <= 7244
                        && transferitem.type == 20
                        && transferitem.retryCount < 3
                        && isRetryAllowed"
                  (click)="retryUpload(transferitem)"
                  style="margin-right: 8px; margin-bottom: 2px;"
                  class="btn btn-primary btn-xs"> Retry
                </button>
                <i [class.hide]="transferitem.status <= 1000" class="fas fa-exclamation-triangle hidden-xs hidden-sm"
                  [ngbPopover]="transferitem.status | translateTransferItem:true"></i>
  
                <i [class.hide]="transferitem.status <= 1000"
                  class="fas fa-exclamation-triangle hidden-md hidden-lg hidden-xl"
                  [ngbPopover]="transferitem.status | translateTransferItem:true" placement="start"></i>
                <!-- hide all icons above if button is available -->
                <i *ngIf="transferitem.type == 20 && (transferitem.status >= 10 && transferitem.status <= 20 && transferitem.status != 1001 || transferitem.status == 0)" (click)="cancelUpload(transferitem)" class="zmdi zmdi-hc-fw zmdi-hc-lg zmdi-close-circle" title="Cancel"></i>
                <button *ngIf="transferitem.status >= 3000 && transferitem.status < 3200"
                  (click)="downloadCompatible(transferitem)" class="btn btn-primary btn-xs">Download</button>
  
                <sync-save-file 
                  *ngIf="transferitem?.renderFile?.dl_ready" 
                  [syncSaveFile]="transferitem"
                  [autodownload]="1"
                ></sync-save-file>
              </td>
            </tr>
            <tr>
              <td class="table-transferprogress" colspan="4">
                <ngb-progressbar *ngIf="transferitem.status >= 10 && transferitem.status <= 20 && transferitem.status != 1001" animate="false"
                  [value]="transferitem.progress_percent" class="progress modal-transfer-progress"
                  style="margin:0px;padding:0px;background-color:white;margin-top:-3px;box-shadow: none;width:100%;">
                  {{transferitem.progress_percent}}%</ngb-progressbar>
  
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>


<div class="modal-footer">

  <button [class.hide]="!transferView.download.queue.length && !transferView.upload.queue.length" (click)="clear()"
    type="button" class="btn btn-default pull-left" id="btn-clear-completed-transfers">Clear<span class="hidden-xs hidden-sm"> completed transfers</span></button>

  <button syncStopPropagation *ngIf="testFileUpload() && showUploadBtn()" type="button" (click)="file.click()"
    class="btn btn-primary">Choose files +</button>

  <input [hidden]="!testFileUpload()" multiple="multiple" type="file" #file class="hidden-file-upload" id="ufile"
    name="ufile" [fileUpload]="ufile" [syncUploadFileSelect]="isPublic" (change)="uploadFileSelect($event)" />
  <button (click)="activeModal.close(true)" type="button" id="upload-close-btn" class="btn btn-default" style="margin-left: 3px;" id="btn-close-file-transfers-dialog">Close</button>

</div>