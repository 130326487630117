"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var LinkSendUrlApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(LinkSendUrlApiOutput, _super);
    function LinkSendUrlApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LinkSendUrlApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.LinkSendUrlApiOutput = LinkSendUrlApiOutput;
var LinkSendUrlApiInput = /** @class */ (function () {
    function LinkSendUrlApiInput() {
    }
    return LinkSendUrlApiInput;
}());
exports.LinkSendUrlApiInput = LinkSendUrlApiInput;
