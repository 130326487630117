"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var user_service_1 = require("../../core/user.service");
var file_item_service_1 = require("../../file/file-item.service");
var file_selection_service_1 = require("../services/file-selection.service");
var dialog_share_app_list_component_1 = require("../../shares/dialog-share-app-list/dialog-share-app-list.component");
var share_service_1 = require("../../shares/share.service");
var dialog_copy_component_1 = require("../../file-actions/dialog-copy/dialog-copy.component");
var dialog_purge_component_1 = require("../../file-actions/dialog-purge/dialog-purge.component");
var dialog_delete_component_1 = require("../../file-actions/dialog-delete/dialog-delete.component");
var dialog_restore_component_1 = require("../../file-actions/dialog-restore/dialog-restore.component");
var rxjs_1 = require("rxjs");
var func_1 = require("../../shared/func");
var build_transfer_item_service_1 = require("../../transfer/services/build-transfer-item.service");
var router_1 = require("@angular/router");
var api_service_1 = require("../../core/api.service");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var dialog_file_zip_component_1 = require("../dialog-file-zip/dialog-file-zip.component");
var file_list_service_1 = require("../services/file-list.service");
var broadcast_service_1 = require("../../shared/services/broadcast.service");
var blend_service_1 = require("../../shared/services/blend.service");
var models_1 = require("../../shared/models");
var sku_service_1 = require("../../shared/services/sku.service");
var feedback_service_1 = require("src/app/notifications/feedback.service");
var starred_list_service_1 = require("../services/starred-list.service");
var FileListMenuComponent = /** @class */ (function () {
    function FileListMenuComponent(fileSelectionService, userService, modalService, fileItemService, shareService, buildTransferItemService, router, route, apiService, fileListService, broadcastService, skuService, feedbackService, SyncCrypt, blendService, starredListService) {
        this.fileSelectionService = fileSelectionService;
        this.userService = userService;
        this.modalService = modalService;
        this.fileItemService = fileItemService;
        this.shareService = shareService;
        this.buildTransferItemService = buildTransferItemService;
        this.router = router;
        this.route = route;
        this.apiService = apiService;
        this.fileListService = fileListService;
        this.broadcastService = broadcastService;
        this.skuService = skuService;
        this.feedbackService = feedbackService;
        this.SyncCrypt = SyncCrypt;
        this.blendService = blendService;
        this.starredListService = starredListService;
        this.selectedParams = {
            allDeleted: true,
            allNotDeleted: true,
            allShared: true,
            allViewable: true,
            allRootShare: true,
            allLink: true,
            allWritable: true,
            allOwned: true,
            isMultiUserChild: false,
            dirAmt: 0,
            fileAmt: 0,
        };
        this.is_tagEnabled = false;
        this.showRightMenu = false;
        this.isFreeUser = false;
        this.total_favorite = 0;
        this.checkFuncs = {
            isDirs: this.isDirs,
            isFiles: this.isFiles,
            isLink: this.isLink,
            isNotLink: this.isNotLink,
            isViewable: this.isViewable,
            isOpenable: this.isOpenable,
            isSingle: this.isSingle,
            isShared: this.isShared,
            isRootShare: this.isRootShare,
            isNotShared: this.isNotShared,
            isMulti: this.isMulti,
            isMultiChild: this.isMultiChild,
            isNotMultiChild: this.isNotMultiChild,
            isDeleted: this.isDeleted,
            isNotDeleted: this.isNotDeleted,
            isInvalid: this.isInvalid,
            isWritable: this.isWritable,
            isNotWritable: this.isNotWritable,
            // isOwner: isOwner,
            isMSOfficeEdit: this.isMSOfficeEdit,
            isMSOfficeView: this.isMSOfficeView,
            isAppShare: this.isAppShare,
            canMakeAppShare: this.canMakeAppShare,
            isHideShare: this.isHideShare,
            isNotHideShare: this.isNotHideShare,
            hasPurgePermission: this.hasPurgePermission,
            hasShareFolderPermission: this.hasShareFolderPermission,
            hasAppsNewFeature: this.hasAppsNewFeature,
            hasShareLinkPermission: this.hasShareLinkPermission,
        };
    }
    FileListMenuComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.fileListService.getListSubscription().subscribe(function (data) {
                            if (data.loaded && data.sorted) {
                                _this.total_favorite = data.total_favorite;
                            }
                        });
                        this.isAuth = this.userService.isAuthenticated();
                        this.fileSelectionService.watchSelected().subscribe(function (selected) {
                            _this.selected = selected;
                            _this.initSelectedParams();
                            _this.selected.map(function (item, idx, state) {
                                if (!item.active) {
                                    return;
                                }
                                if (item.type === 'dir') {
                                    _this.selectedParams.dirAmt += 1;
                                }
                                else if (item.type === 'file') {
                                    _this.selectedParams.fileAmt += 1;
                                }
                                else {
                                }
                                if (item.filetype && item.filetype.viewable !== true) {
                                    _this.selectedParams.allViewable = false;
                                }
                                if (item.is_deleted == 0) {
                                    _this.selectedParams.allDeleted = false;
                                }
                                if (item.is_deleted == 1) {
                                    _this.selectedParams.allNotDeleted = false;
                                }
                                if (item.is_publink != 1) {
                                    _this.selectedParams.allLink = false;
                                }
                                if (item.is_shared != 1 || item.is_hide_share == 1) {
                                    _this.selectedParams.allShared = false;
                                }
                                if (item.is_share_root != 1) {
                                    _this.selectedParams.allRootShare = false;
                                }
                                if (item.is_readonly == 1) {
                                    _this.selectedParams.allWritable = false;
                                }
                            });
                        });
                        _a = this;
                        return [4 /*yield*/, this.skuService.isFreeUser()];
                    case 1:
                        _a.isFreeUser = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FileListMenuComponent.prototype.initSelectedParams = function () {
        this.selectedParams.allDeleted = true;
        this.selectedParams.allDeleted = true;
        this.selectedParams.allNotDeleted = true;
        this.selectedParams.allShared = true;
        this.selectedParams.allViewable = true;
        this.selectedParams.allRootShare = true;
        this.selectedParams.allLink = true;
        this.selectedParams.allOwned = true;
        this.selectedParams.allWritable = true;
        this.selectedParams.dirAmt = 0;
        this.selectedParams.fileAmt = 0;
        this.selectedParams.isMultiUserChild =
            this.userService.get('is_multi_child');
    };
    FileListMenuComponent.prototype.isVisible = function (filterFuncs) {
        var visible = true;
        if (this.selectedParams.fileAmt == 0 &&
            this.selectedParams.dirAmt == 0) {
            return false;
        }
        for (var i = 0, len = filterFuncs.length; i < len; i++) {
            var func = this.checkFuncs[filterFuncs[i]] || rxjs_1.noop();
            if (func.bind(this)(this.selectedParams) == false) {
                visible = false;
                break;
            }
        }
        return visible;
    };
    FileListMenuComponent.prototype.downloadCompat = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (this.selected[0].context == 'applink') {
                    window.location.href = this.selected[0].compaturl_dl;
                }
                else {
                    this.buildTransferItemService.getCompatUrl(this.selected[0], this.buildTransferItemService.ACT_DOWNLOAD).
                        then(function (url) {
                        _this.selected[0].compaturl_dl = url;
                        window.location.href = _this.selected[0].compaturl_dl;
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    FileListMenuComponent.prototype.openFile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isMSOfficeEdit()) return [3 /*break*/, 1];
                        this.openOfficeEdit();
                        return [3 /*break*/, 4];
                    case 1:
                        if (!(this.selected[0].context == 'applink')) return [3 /*break*/, 2];
                        window.location.href = this.selected[0].compaturl;
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.buildTransferItemService.getCompatUrl(this.selected[0], this.buildTransferItemService.ACT_PREVIEW)];
                    case 3:
                        url = _a.sent();
                        this.selected[0].compaturl = url;
                        window.location.href = this.selected[0].compaturl;
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    FileListMenuComponent.prototype.isWritable = function (selected) {
        return selected.allWritable;
    };
    FileListMenuComponent.prototype.isNotWritable = function (selected) {
        return !selected.allWritable;
    };
    FileListMenuComponent.prototype.isDirs = function (selected) {
        return selected.fileAmt === 0 && selected.dirAmt > 0;
    };
    FileListMenuComponent.prototype.isFiles = function (selected) {
        return selected.fileAmt > 0 && selected.dirAmt == 0;
    };
    FileListMenuComponent.prototype.isLink = function (selected) {
        return selected.allLink;
    };
    FileListMenuComponent.prototype.isNotLink = function (selected) {
        return !selected.allLink;
    };
    FileListMenuComponent.prototype.isViewable = function (selected) {
        var item = this.selected[0];
        if (!item) {
            return false;
        }
        return (this.fileItemService.isText(item) ||
            this.fileItemService.isDocument(item) ||
            this.fileItemService.isImage(item) ||
            this.fileItemService.isPDF(item));
    };
    FileListMenuComponent.prototype.isOpenable = function () {
        var item = this.selected[0];
        if (!item) {
            return false;
        }
        if (item.context == 'link' && item.previewonly == 8) {
            return false;
        }
        if (item.context == 'link' &&
            func_1.getFileExt(item.name) == 'pdf' &&
            !item.link_is_business) {
            return false;
        }
        if (!func_1.canBrowserOpen(item.name)) {
            return false;
        }
        return true;
    };
    FileListMenuComponent.prototype.isSingle = function (selected) {
        return selected.fileAmt + selected.dirAmt === 1;
    };
    FileListMenuComponent.prototype.isShared = function (selected) {
        return selected.allShared;
    };
    FileListMenuComponent.prototype.isNotShared = function (selected) {
        return !selected.allShared;
    };
    FileListMenuComponent.prototype.isMulti = function () {
        return true;
    };
    FileListMenuComponent.prototype.isMultiChild = function (selected) {
        return selected.isMultiUserChild;
    };
    FileListMenuComponent.prototype.isNotMultiChild = function (selected) {
        return !selected.isMultiUserChild;
    };
    FileListMenuComponent.prototype.isDeleted = function (selected) {
        return selected.allDeleted;
    };
    FileListMenuComponent.prototype.isNotDeleted = function (selected) {
        return selected.allNotDeleted;
    };
    FileListMenuComponent.prototype.isInvalid = function (selected) {
        return !selected.allNotDeleted && !selected.allDeleted;
    };
    FileListMenuComponent.prototype.isMSOfficeEdit = function () {
        return (this.fileItemService.isMSOffice(this.selected[0], 'edit') && this.isAuth);
    };
    FileListMenuComponent.prototype.isMSOfficeView = function () {
        return this.fileItemService.isMSOffice(this.selected[0], 'view');
    };
    FileListMenuComponent.prototype.isAppShare = function () {
        return this.selected[0] && this.selected[0].is_app_share;
    };
    FileListMenuComponent.prototype.canMakeAppShare = function () {
        return (this.selected[0] && !this.selected[0].is_app_share &&
            ((this.selected[0].is_shared && this.selected[0].is_share_root) ||
                !this.selected[0].is_shared));
    };
    FileListMenuComponent.prototype.isHideShare = function () {
        return this.selected[0] && this.selected[0].is_hide_share;
    };
    FileListMenuComponent.prototype.isNotHideShare = function () {
        return this.selected[0] && !this.selected[0].is_hide_share;
    };
    // function isOwner(selected: sync.IFileSelectParams) {
    //     return selected.allOwned;
    // }
    FileListMenuComponent.prototype.isRootShare = function (selected) {
        return selected.allRootShare;
    };
    FileListMenuComponent.prototype.hasPurgePermission = function (selected) {
        // console.log('hasPurgePermission ' + this.userService.get('is_disablepurge'));
        return this.userService.get('is_disablepurge') == 0;
    };
    FileListMenuComponent.prototype.hasShareLinkPermission = function (selected) {
        return (this.userService.getPermssionByKey(models_1.PermissionActions.SHARE_LINKS));
    };
    FileListMenuComponent.prototype.hasShareFolderPermission = function () {
        return (this.userService.getPermssionByKey(models_1.PermissionActions.SHARE_FOLDERS));
    };
    FileListMenuComponent.prototype.hasAppsNewFeature = function () {
        // return (this.userService.get('new_feature') == 1);
        return (this.userService.get('new_feature') == 1 &&
            this.userService.get('app_count') > 0);
    };
    FileListMenuComponent.prototype.openAppList = function () {
        var ref = this.modalService.open(dialog_share_app_list_component_1.DialogShareAppListComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
        });
        ref.componentInstance.item = this.selected[0];
    };
    FileListMenuComponent.prototype.openShareDetailsDailog = function (share, openState) {
        var webpath = Array.isArray(share) ? share[0] : share;
        var shareId = webpath.shareId || webpath.share_id;
        var state = !Number.isNaN(parseInt(openState, 10)) ? parseInt(openState, 10) : 0;
        this.shareService.openDetails(shareId, state);
    };
    FileListMenuComponent.prototype.openOfficeView = function () {
        this.router.navigate(['/file', this.selected[0].sync_id, 'view', 'officefsv'], {
            queryParams: {
                view: this.route.snapshot.queryParams['view'],
                page: this.route.snapshot.queryParams['page']
            }
        });
    };
    FileListMenuComponent.prototype.openOfficeEdit = function () {
        this.router.navigate(['/file', this.selected[0].sync_id, 'view', 'officefse'], {
            queryParams: {
                view: this.route.snapshot.queryParams['view'],
                page: this.route.snapshot.queryParams['page']
            }
        });
    };
    FileListMenuComponent.prototype.openCopyDialog = function (action) {
        var ref = this.modalService.open(dialog_copy_component_1.DialogCopyComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
        });
        ref.componentInstance.selected = this.selected;
        ref.componentInstance.action = action;
        ref.componentInstance.shouldDuplicate = action == 'copy' && this.selected.every(function (item) { return item.type == 'file'; });
        if (action == 'move') {
            this.blendService.track(models_1.BlendEvent.BUTTON_CLICKED, {
                button_text: 'Move',
                num_items: this.selected.length
            });
        }
    };
    FileListMenuComponent.prototype.openPurgeDialog = function () {
        var ref = this.modalService.open(dialog_purge_component_1.DialogPurgeComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
        });
        ref.componentInstance.selected = this.selected;
    };
    FileListMenuComponent.prototype.openDeleteDialog = function () {
        var ref = this.modalService.open(dialog_delete_component_1.DialogDeleteComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
        });
        ref.componentInstance.selected = this.selected;
    };
    FileListMenuComponent.prototype.openRestoreDialog = function () {
        var ref = this.modalService.open(dialog_restore_component_1.DialogRestoreComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
        });
        ref.componentInstance.selected = this.selected;
    };
    FileListMenuComponent.prototype.duplicateFile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var parentId, parentName, parent_1, ref;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        parentId = this.selected[0].pid;
                        parentName = '';
                        if (!(this.userService.get('syncus_sync_id') != parentId)) return [3 /*break*/, 4];
                        if (!(this.userService.get('web_sync_id') == parentId)) return [3 /*break*/, 1];
                        parentName = 'Vault';
                        return [3 /*break*/, 4];
                    case 1: return [4 /*yield*/, this.apiService.execute('pathget', {
                            sync_id: parentId,
                        })];
                    case 2:
                        parent_1 = _a.sent();
                        return [4 /*yield*/, this.SyncCrypt.filenameDecrypt(parent_1.enc_name)];
                    case 3:
                        parentName = _a.sent();
                        _a.label = 4;
                    case 4:
                        ref = this.modalService.open(dialog_copy_component_1.DialogCopyComponent, {
                            backdropClass: 'in',
                            windowClass: 'in',
                            backdrop: true,
                        });
                        ref.componentInstance.selected = this.selected;
                        ref.componentInstance.action = 'copy';
                        ref.componentInstance.shouldDuplicate = true;
                        ref.componentInstance.parentDir = {
                            sync_id: parentId,
                            name: parentName
                        };
                        return [2 /*return*/];
                }
            });
        });
    };
    FileListMenuComponent.prototype.downloadZipFile = function () {
        var ref = this.modalService.open(dialog_file_zip_component_1.DialogFileZipComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        this.blendService.track(models_1.BlendEvent.DOWNLOAD_AS_ZIP, {});
    };
    FileListMenuComponent.prototype.addFavoriteTag = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file_extension, file_size, mime_type, isDir, user, sync_id_list, pathlist, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        file_extension = [];
                        file_size = 0;
                        mime_type = [];
                        this.starredListService.setTotalFavorite(this.total_favorite);
                        user = this.userService.getUser();
                        sync_id_list = [];
                        pathlist = this.selected;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        if (!(this.isFreeUser && this.total_favorite + pathlist.length > 3)) return [3 /*break*/, 2];
                        this.starredListService.openAccountUpgradeDialog();
                        return [3 /*break*/, 4];
                    case 2:
                        pathlist.map(function (item) {
                            item.is_favorite = true;
                            item.is_tagEnabled = true;
                            _this.is_tagEnabled = true;
                            if (item.sync_id) {
                                sync_id_list.push(item.sync_id);
                            }
                        });
                        return [4 /*yield*/, this.starredListService.addTag(sync_id_list, user.uid)];
                    case 3:
                        _a.sent();
                        pathlist.map(function (item) {
                            item.is_tagEnabled = false;
                            _this.is_tagEnabled = false;
                            file_extension.push(item.file_extension);
                            file_size += item.size;
                            var mimeType = (item.mime_type);
                            if (mime_type.indexOf(mimeType) === -1) {
                                mime_type.push(mimeType);
                            }
                            isDir = item.type == 'dir' ? true : false;
                        });
                        this.blendService.track(models_1.BlendEvent.ADD_FAVORITE_TAG, isDir
                            ? {}
                            : {
                                fileSize: this.fileItemService.bytesToSize(file_size),
                                mimeType: mime_type
                            });
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        this.feedbackService.setFeedback('danger', "Failed to add star", 1801);
                        this.broadcastService.broadcast('event:file-list.reload');
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    FileListMenuComponent.prototype.removeFavoriteTag = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file_extension, file_size, mime_type, isDir, user, sync_id_list, pathlist, error_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        file_extension = [];
                        file_size = 0;
                        mime_type = [];
                        this.starredListService.setTotalFavorite(this.total_favorite);
                        user = this.userService.getUser();
                        sync_id_list = [], pathlist = this.selected;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        pathlist.map(function (item) {
                            item.is_favorite = false;
                            item.is_tagEnabled = true;
                            _this.is_tagEnabled = true;
                            file_extension.push(item.file_extension);
                            file_size += item.size;
                            var mimeType = (item.mime_type);
                            if (mime_type.indexOf(mimeType) === -1) {
                                mime_type.push(mimeType);
                            }
                            isDir = item.type == 'dir' ? true : false;
                            if (item.sync_id) {
                                sync_id_list.push(item.sync_id);
                            }
                        });
                        return [4 /*yield*/, this.starredListService.removeTag(sync_id_list)];
                    case 2:
                        _a.sent();
                        pathlist.map(function (item) {
                            item.is_tagEnabled = false;
                            _this.is_tagEnabled = false;
                        });
                        this.blendService.track(models_1.BlendEvent.REMOVE_FAVORITE_TAG, isDir
                            ? {}
                            : {
                                fileSize: this.fileItemService.bytesToSize(file_size),
                                mimeType: mime_type
                            });
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        this.feedbackService.setFeedback('danger', "Failed to remove star", 1802);
                        this.broadcastService.broadcast('event:file-list.reload');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    FileListMenuComponent.prototype.isEnabled = function (name) {
        var pathlist = this.selected;
        var unTagged = pathlist.find(function (item) { return !item.is_favorite; });
        var tagged = pathlist.find(function (item) { return item.is_favorite; });
        if (name == 'add-fav' && unTagged && !tagged) {
            return true;
        }
        else if (name == 'remove-fav' && tagged && !unTagged) {
            return true;
        }
        return false;
    };
    return FileListMenuComponent;
}());
exports.FileListMenuComponent = FileListMenuComponent;
