"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var zuora_account_service_1 = require("../services/zuora-account.service");
var models_1 = require("../../shared/models");
var validate_service_1 = require("../../core/validate.service");
var logger_service_1 = require("../../core/logger.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var DialogEmailBillingDocComponent = /** @class */ (function () {
    function DialogEmailBillingDocComponent(activeModal, log, zuora, validate) {
        this.activeModal = activeModal;
        this.log = log;
        this.zuora = zuora;
        this.validate = validate;
        this.emailQueue = [];
    }
    DialogEmailBillingDocComponent.prototype.ngOnInit = function () {
    };
    DialogEmailBillingDocComponent.prototype.addToQueue = function () {
        var email = this.email.toLowerCase();
        if (this.emailQueue.indexOf(email) > -1) {
            return;
        }
        if (this.emailQueue.length > 20) {
            this.errcode = new models_1.ErrCode(1655);
            return;
        }
        if (!this.validate.isEmail(email)) {
            this.errcode = new models_1.ErrCode(8107);
            return;
        }
        this.errcode = undefined;
        this.emailQueue.push(email);
        this.email = '';
    };
    DialogEmailBillingDocComponent.prototype.removeQueue = function (email) {
        this.emailQueue.splice(this.emailQueue.indexOf(email.toLowerCase()), 1);
    };
    DialogEmailBillingDocComponent.prototype.sendEmail = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.email) {
                            this.addToQueue();
                        }
                        if (!this.emailQueue) {
                            this.errcode = new models_1.ErrCode(1656, 'No email addresses added to queue');
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this.spinner = true;
                        return [4 /*yield*/, this.zuora.emailBillingDocument(this.emailQueue, this.doc.type, this.doc.id)];
                    case 2:
                        _a.sent();
                        this.activeModal.close(true);
                        return [3 /*break*/, 5];
                    case 3:
                        ex_1 = _a.sent();
                        this.log.error("An error occurred sending an email document " + JSON.stringify(this.doc) + " " + JSON.stringify(ex_1));
                        this.errcode = new models_1.ErrCode(1657, 'An error occurred attempting to send the document.  Please try again later');
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return DialogEmailBillingDocComponent;
}());
exports.DialogEmailBillingDocComponent = DialogEmailBillingDocComponent;
