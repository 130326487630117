"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var LinkPathListApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(LinkPathListApiOutput, _super);
    function LinkPathListApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LinkPathListApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.LinkPathListApiOutput = LinkPathListApiOutput;
var LinkPathListApiInput = /** @class */ (function () {
    function LinkPathListApiInput() {
    }
    return LinkPathListApiInput;
}());
exports.LinkPathListApiInput = LinkPathListApiInput;
