"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var JobLockApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(JobLockApiOutput, _super);
    function JobLockApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return JobLockApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.JobLockApiOutput = JobLockApiOutput;
var JobLockApiInput = /** @class */ (function () {
    function JobLockApiInput() {
        this.command = 'getjoblock';
    }
    return JobLockApiInput;
}());
exports.JobLockApiInput = JobLockApiInput;
