"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var api_service_1 = require("../../core/api.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var password_service_1 = require("../../auth/services/password.service");
var DialogMultiuserSettingsComponent = /** @class */ (function () {
    function DialogMultiuserSettingsComponent(api, activeModal, password) {
        this.api = api;
        this.activeModal = activeModal;
        this.password = password;
    }
    DialogMultiuserSettingsComponent.prototype.ngOnInit = function () {
        this.spinner = false;
        this.disablePurge = this.child.has_disable_purge.toString();
        this.rmPurge = '0';
    };
    DialogMultiuserSettingsComponent.prototype.removeUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.execute('multiuserupdate', {
                                action: 'remove',
                                email: this.child.email,
                                is_purge: parseInt(this.rmPurge, 10)
                            })];
                    case 2:
                        _a.sent();
                        this.activeModal.close(true);
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DialogMultiuserSettingsComponent.prototype.saveSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        if (!this.pass1 || !this.pass2 || !this.parentPass) {
                            this.errcode = new models_1.ErrCode(8305);
                            this.spinner = false;
                            return [2 /*return*/];
                        }
                        if (this.pass1 && !this.password.isPasswordStrong(this.pass1)) {
                            this.errcode = new models_1.ErrCode(8304);
                            this.spinner = false;
                            return [2 /*return*/];
                        }
                        if (!(this.pass1 && this.pass2 && this.parentPass)) return [3 /*break*/, 3];
                        if (this.pass1 != this.pass2) {
                            this.errcode = new models_1.ErrCode(8121);
                            this.spinner = false;
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.password.resetPasswordMultiUser(this.child.user_id, this.parentPass, this.pass1)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this.api.execute('multiuserpermissions', {
                            child_user_id: this.child.user_id,
                            disablepurge: this.disablePurge
                        })];
                    case 4:
                        _a.sent();
                        this.activeModal.close(true);
                        return [3 /*break*/, 6];
                    case 5:
                        ex_2 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_2);
                        return [3 /*break*/, 6];
                    case 6:
                        this.spinner = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    return DialogMultiuserSettingsComponent;
}());
exports.DialogMultiuserSettingsComponent = DialogMultiuserSettingsComponent;
