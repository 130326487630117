"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var api_service_1 = require("../../core/api.service");
var user_service_1 = require("../../core/user.service");
var models_1 = require("../../shared/models");
var WopiOptinComponent = /** @class */ (function () {
    function WopiOptinComponent(userService, activeModal, apiService) {
        this.userService = userService;
        this.activeModal = activeModal;
        this.apiService = apiService;
        this.spinner = false;
    }
    WopiOptinComponent.prototype.ngOnInit = function () {
        this.user = this.userService.getUser();
        this.status = this.user.is_office === 1 ? '1' : '0';
    };
    WopiOptinComponent.prototype.optin = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.apiService.execute('userinfoset', {
                                is_office: 1,
                            })];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.apiService.execute('userinfoset', {
                                is_displayedofficeoptin: 1,
                            })];
                    case 3:
                        _a.sent();
                        this.userService.refresh();
                        this.spinner = false;
                        this.activeModal.close(true);
                        return [3 /*break*/, 5];
                    case 4:
                        ex_1 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    WopiOptinComponent.prototype.optout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.apiService.execute('userinfoset', {
                                is_office: 0,
                            })];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.apiService.execute('userinfoset', {
                                is_displayedofficeoptin: 1,
                            })];
                    case 3:
                        _a.sent();
                        this.userService.refresh();
                        this.spinner = false;
                        this.activeModal.close(false);
                        return [3 /*break*/, 5];
                    case 4:
                        ex_2 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_2);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return WopiOptinComponent;
}());
exports.WopiOptinComponent = WopiOptinComponent;
