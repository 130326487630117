"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../shared/components/header/default/header-default.component.ngfactory");
var i2 = require("../../shared/components/header/default/header-default.component");
var i3 = require("@ng-bootstrap/ng-bootstrap");
var i4 = require("../../notifications/notifications/notifications.component.ngfactory");
var i5 = require("../../notifications/notifications/notifications.component");
var i6 = require("../../notifications/feedback.service");
var i7 = require("../../core/notifications.service");
var i8 = require("../../core/user.service");
var i9 = require("@angular/router");
var i10 = require("../../shared/components/footer/footer.component.ngfactory");
var i11 = require("../../shared/components/footer/footer.component");
var i12 = require("./slack-layout.component");
var styles_SlackLayoutComponent = [];
var RenderType_SlackLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SlackLayoutComponent, data: {} });
exports.RenderType_SlackLayoutComponent = RenderType_SlackLayoutComponent;
function View_SlackLayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-header-default", [], null, null, null, i1.View_HeaderDefaultComponent_0, i1.RenderType_HeaderDefaultComponent)), i0.ɵdid(1, 114688, null, 0, i2.HeaderDefaultComponent, [i3.NgbModal], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "sync-notifications", [], null, null, null, i4.View_NotificationsComponent_0, i4.RenderType_NotificationsComponent)), i0.ɵdid(3, 114688, null, 0, i5.NotificationsComponent, [i6.FeedbackService, i7.NotificationsService, i8.UserService], null, null), (_l()(), i0.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(5, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "sync-footer", [], null, null, null, i10.View_FooterComponent_0, i10.RenderType_FooterComponent)), i0.ɵdid(7, 114688, null, 0, i11.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
exports.View_SlackLayoutComponent_0 = View_SlackLayoutComponent_0;
function View_SlackLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-slack-layout", [], null, null, null, View_SlackLayoutComponent_0, RenderType_SlackLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i12.SlackLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SlackLayoutComponent_Host_0 = View_SlackLayoutComponent_Host_0;
var SlackLayoutComponentNgFactory = i0.ɵccf("sync-slack-layout", i12.SlackLayoutComponent, View_SlackLayoutComponent_Host_0, {}, {}, []);
exports.SlackLayoutComponentNgFactory = SlackLayoutComponentNgFactory;
