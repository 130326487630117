"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var RequestHintApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(RequestHintApiOutput, _super);
    function RequestHintApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RequestHintApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.RequestHintApiOutput = RequestHintApiOutput;
var RequestHintApiInput = /** @class */ (function (_super) {
    tslib_1.__extends(RequestHintApiInput, _super);
    function RequestHintApiInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RequestHintApiInput;
}(base_api_model_1.BaseApiInput));
exports.RequestHintApiInput = RequestHintApiInput;
