"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var error_messages_model_1 = require("./error-messages.model");
var ErrCode = /** @class */ (function () {
    function ErrCode(code, msg, data) {
        this.code = code;
        this.msg = msg;
        this.data = data;
        if (!msg && error_messages_model_1.ErrorMessages[code]) {
            this.msg = error_messages_model_1.ErrorMessages[code];
        }
    }
    ErrCode.fromError = function (e) {
        return new ErrCode(1000, e.message);
    };
    ErrCode.fromApiLegacy = function (e) {
        if (e.error_code) {
            return new ErrCode(e.error_code, e.error_msg, e.data);
        }
        else {
            return new ErrCode(1000);
        }
    };
    ErrCode.fromException = function (e, ctx) {
        var err = new Error();
        console.error("ErrCode.fromException call stack " + err.stack);
        if (e instanceof ErrCode) {
            return e;
        }
        else if (e.status > 0) {
            return new ErrCode(e.status, e.error.message);
        }
        else if (e.code > 0) {
            return new ErrCode(e.code, e.msg);
        }
        else if (e instanceof Error) {
            return ErrCode.fromError(e);
        }
        else if (e.error_code) {
            return ErrCode.fromApiLegacy(e);
        }
        else {
            if (ctx) {
                return new ErrCode(1000, ctx);
            }
            else {
                return new ErrCode(1000, 'An unknown error occurred');
            }
        }
    };
    return ErrCode;
}());
exports.ErrCode = ErrCode;
