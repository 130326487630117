"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DirtyInTasks = /** @class */ (function () {
    function DirtyInTasks() {
    }
    return DirtyInTasks;
}());
exports.DirtyInTasks = DirtyInTasks;
var DirtyInTaskItem = /** @class */ (function () {
    function DirtyInTaskItem() {
    }
    return DirtyInTaskItem;
}());
exports.DirtyInTaskItem = DirtyInTaskItem;
var DirtyInTaskResult = /** @class */ (function () {
    function DirtyInTaskResult() {
    }
    return DirtyInTaskResult;
}());
exports.DirtyInTaskResult = DirtyInTaskResult;
