"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var support_ticket_service_1 = require("../../account/services/support-ticket.service");
var forms_1 = require("@angular/forms");
var DialogReportLinkComponent = /** @class */ (function () {
    function DialogReportLinkComponent(activeModal, fb, ticket) {
        this.activeModal = activeModal;
        this.fb = fb;
        this.ticket = ticket;
        this.frmValues = {
            requester: '',
            name: '',
            subject: null,
            description: null
        };
    }
    DialogReportLinkComponent.prototype.isCopyright = function () {
        return (this.val('subject').indexOf('Infringes') > -1);
    };
    DialogReportLinkComponent.prototype.ngOnInit = function () {
        this.reportForm = this.fb.group({
            name: ['', forms_1.Validators.required],
            subject: ['', forms_1.Validators.required],
            email: ['', forms_1.Validators.required],
            digitalsignature: ['', forms_1.Validators.required],
            description: ['', forms_1.Validators.required],
            copyright: this.fb.group({
                jobtitle: [''],
                behalfof: [''],
                address: [''],
                city: [''],
                province: [''],
                postalcode: [''],
                country: [''],
                phone: [''],
                fax: [''],
                email: [''],
                name: [''],
                dmca1: [''],
                dmca2: [''],
                dmca3: [''],
            })
        });
    };
    DialogReportLinkComponent.prototype.val = function (field) {
        console.log('val = ' + field);
        return this.reportForm.get(field).value;
    };
    DialogReportLinkComponent.prototype.onSubmit = function () {
        var fullDetails = '';
        if (this.isCopyright()) {
            fullDetails = [
                'Job title: ' + this.val('copyright.jobtitle'),
                'Reporting on behalf of: ' + this.val('copyright.behalfof'),
                '----',
                this.val('copyright.address'),
                this.val('copyright.city')
                    + ' ' + this.val('copyright.province')
                    + ' ' + this.val('copyright.postalcode'),
                this.val('copyright.country'),
                '----',
                'Phone: ' + this.val('copyright.phone'),
                'Fax: ' + this.val('copyright.fax'),
                'Email: ' + this.val('copyright.email'),
                '----',
                'Checked: I hereby state that I have a good faith belief that the sharing of copyrighted material at the location above is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use).',
                'Checked: I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of, the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed.',
                'Checked: I acknowledge that under Section 512(f) any person who knowingly materially misrepresents that material or activity is infringing may be subject to liability for damages.',
            ].join('\n\n');
        }
        var description = [
            'Link: ' + window.location.href,
            'Subject: ' + this.val('subject'),
            '',
            'Full name: ' + this.val('name'),
            'Email: ' + this.val('email'),
            fullDetails,
            '',
            this.val('description'),
            '',
            'Digital Signature included: ' + this.val('digitalsignature')
        ].join('\n\n');
        var postData = {
            z_requester: 'abuse@sync.com',
            z_name: 'Sync Abuse',
            z_subject: 'ABUSE for public link' + this.val('subject'),
            z_description: description
        };
        this.ticket.sendSupportTicket(postData);
    };
    return DialogReportLinkComponent;
}());
exports.DialogReportLinkComponent = DialogReportLinkComponent;
