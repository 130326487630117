"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var forms_1 = require("@angular/forms");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var http_1 = require("@angular/common/http");
var logger_service_1 = require("../../core/logger.service");
var api_service_1 = require("../../core/api.service");
var user_service_1 = require("../../core/user.service");
var environment_1 = require("../../../environments/environment");
var store_1 = require("@ngrx/store");
var rxjs_1 = require("rxjs");
var DialogDisplayAvatarComponent = /** @class */ (function () {
    function DialogDisplayAvatarComponent(activeModal, fb, userService, httpClient, logger, api, store) {
        this.activeModal = activeModal;
        this.fb = fb;
        this.userService = userService;
        this.httpClient = httpClient;
        this.logger = logger;
        this.api = api;
        this.store = store;
    }
    DialogDisplayAvatarComponent.prototype.ngOnInit = function () {
        this.defaultLogo = this.user.avatar.avatar_data;
        this.avatarEndpoint = environment_1.environment.logohost + "avatar/";
        if (!this.defaultLogo) {
            this.defaultLogo = '';
        }
        this.errcode = null;
        this.logoInput = null;
        //set things to saved settings or default
        this.logo = this.defaultLogo;
        this.spinner = false;
        this.createForm();
    };
    DialogDisplayAvatarComponent.prototype.createForm = function () {
        //logo input is not required if a custom logo exists
        if (this.logo) {
            this.avatarForm = this.fb.group({
                logo: [this.logoInput],
            });
        }
        else {
            this.avatarForm = this.fb.group({
                logo: [this.logoInput, forms_1.Validators.required],
            });
        }
    };
    DialogDisplayAvatarComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.execute('uploadavatar', { imgBase64: this.logo })];
                    case 1:
                        _a.sent();
                        this.userService.refresh();
                        this.activeModal.close(this.logo);
                        return [3 /*break*/, 3];
                    case 2:
                        ex_1 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DialogDisplayAvatarComponent.prototype.uploadFile = function (files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var logoFile, path, formData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!files || files.length == 0) {
                            return [2 /*return*/];
                        }
                        this.errcode = null;
                        this.setSpinner();
                        logoFile = files.item(0);
                        path = '/upload/whitelabel';
                        formData = new FormData();
                        formData.append('file', logoFile, logoFile.name);
                        //api call for image preprocessing
                        return [4 /*yield*/, this.httpClient
                                .post(environment_1.environment.imageconversionhost + path, formData)
                                .subscribe(function (response) {
                                if (response['success']) {
                                    _this.logo = response['imgdata'];
                                    _this.setSpinner();
                                }
                            }, function (err) {
                                _this.errcode = new models_1.ErrCode(err.error.error_code, 'The file format is unsupported. Please try again');
                                _this.setSpinner();
                            })];
                    case 1:
                        //api call for image preprocessing
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogDisplayAvatarComponent.prototype.clearAvatar = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.execute('uploadavatar', { imgBase64: '' })];
                    case 1:
                        _a.sent();
                        this.userService.refresh();
                        this.activeModal.close('');
                        return [3 /*break*/, 3];
                    case 2:
                        ex_2 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DialogDisplayAvatarComponent.prototype.setSpinner = function () {
        this.spinner = !this.spinner;
    };
    return DialogDisplayAvatarComponent;
}());
exports.DialogDisplayAvatarComponent = DialogDisplayAvatarComponent;
