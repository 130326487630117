"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BaseApiOutput = /** @class */ (function () {
    function BaseApiOutput() {
        this.error_code = 0;
        this.error_msg = undefined;
        this.logout = 0;
    }
    return BaseApiOutput;
}());
exports.BaseApiOutput = BaseApiOutput;
var BaseApiInput = /** @class */ (function () {
    function BaseApiInput() {
        this.servtime = 0;
        this.api_version = 2;
    }
    return BaseApiInput;
}());
exports.BaseApiInput = BaseApiInput;
var JwtTokenApiOutput = /** @class */ (function () {
    function JwtTokenApiOutput() {
    }
    return JwtTokenApiOutput;
}());
exports.JwtTokenApiOutput = JwtTokenApiOutput;
