"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var models_1 = require("../../shared/models");
var api_service_1 = require("../../core/api.service");
var base64_service_1 = require("../../core/base64.service");
var user_service_1 = require("../../core/user.service");
var validate_service_1 = require("../../core/validate.service");
var DialogDisplaynameComponent = /** @class */ (function () {
    function DialogDisplaynameComponent(activeModal, api, base64, userService, validateService) {
        this.activeModal = activeModal;
        this.api = api;
        this.base64 = base64;
        this.userService = userService;
        this.validateService = validateService;
    }
    DialogDisplaynameComponent.prototype.ngOnInit = function () {
    };
    DialogDisplaynameComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        if (!this.validateService.isDisplayNameValid(this.displayName)) {
                            throw { code: 1659 };
                        }
                        return [4 /*yield*/, this.api.execute('userinfoset', {
                                display_name_b64: this.base64.encode(this.displayName)
                            })];
                    case 2:
                        _a.sent();
                        this.userService.refresh();
                        this.activeModal.close(true);
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _a.sent();
                        this.spinner = false;
                        console.error(ex_1);
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return DialogDisplaynameComponent;
}());
exports.DialogDisplaynameComponent = DialogDisplaynameComponent;
