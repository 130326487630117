"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var SessionNewApiInput = /** @class */ (function () {
    function SessionNewApiInput() {
    }
    return SessionNewApiInput;
}());
exports.SessionNewApiInput = SessionNewApiInput;
var SessionNewApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(SessionNewApiOutput, _super);
    function SessionNewApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return SessionNewApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.SessionNewApiOutput = SessionNewApiOutput;
