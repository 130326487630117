"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var api_service_1 = require("../../core/api.service");
var models_1 = require("../../shared/models");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_device_rename_component_1 = require("../dialog-device-rename/dialog-device-rename.component");
var dialog_device_delete_component_1 = require("../dialog-device-delete/dialog-device-delete.component");
var dialog_device_permission_component_1 = require("../dialog-device-permission/dialog-device-permission.component");
var dialog_device_activate_component_1 = require("../dialog-device-activate/dialog-device-activate.component");
var dispatch_service_1 = require("../../core/dispatch.service");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var DevicesComponent = /** @class */ (function () {
    function DevicesComponent(api, dispatch, modalService, store) {
        this.api = api;
        this.dispatch = dispatch;
        this.modalService = modalService;
        this.store = store;
    }
    DevicesComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.sub = this.store
                            .select(fromRoot.getAuthState)
                            .subscribe(function (data) {
                            if (!data.authenticated) {
                                console.warn('User is not authenicated');
                                _this.user = new models_1.User();
                                // this.initialized = false;
                                return;
                            }
                            if (data.user) {
                                console.log('Setting user');
                                _this.user = data.user;
                                // this.initialized = true;
                            }
                        });
                        return [4 /*yield*/, this.loadDevices()];
                    case 1:
                        _a.sent();
                        this.loadSessions();
                        return [2 /*return*/];
                }
            });
        });
    };
    DevicesComponent.prototype.activate = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.execute('deviceactivate', { device_id: item.id })];
                    case 1:
                        _a.sent();
                        this.loadDevices();
                        return [2 /*return*/];
                }
            });
        });
    };
    DevicesComponent.prototype.rename = function (item) {
        var _this = this;
        var ref = this.modalService.open(dialog_device_rename_component_1.DialogDeviceRenameComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.device = item;
        ref.result.then(function (result) {
            if (result === true) {
                _this.loadDevices();
            }
        }, function () { });
    };
    DevicesComponent.prototype.delete = function (item) {
        var _this = this;
        var ref = this.modalService.open(dialog_device_delete_component_1.DialogDeviceDeleteComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.device = item;
        ref.result.then(function (result) {
            if (result === true) {
                _this.loadDevices();
            }
        }, function () { });
    };
    DevicesComponent.prototype.restore = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.execute('devicerestore', { device_id: item.id })];
                    case 1:
                        _a.sent();
                        this.loadDevices();
                        return [2 /*return*/];
                }
            });
        });
    };
    DevicesComponent.prototype.logout = function (session) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.execute('usersessionkill', { id: session.id })];
                    case 1:
                        _a.sent();
                        this.loadSessions();
                        return [2 /*return*/];
                }
            });
        });
    };
    DevicesComponent.prototype.setPermission = function (item) {
        var _this = this;
        var ref = this.modalService.open(dialog_device_permission_component_1.DialogDevicePermissionComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.device = item;
        ref.result.then(function (result) {
            if (result === true) {
                _this.loadDevices();
            }
        }, function () { });
    };
    DevicesComponent.prototype.activateDevice = function (item) {
        var _this = this;
        var ref = this.modalService.open(dialog_device_activate_component_1.DialogDeviceActivateComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.device = item;
        ref.result.then(function (result) {
            if (result === true) {
                _this.loadDevices();
            }
        }, function () { });
    };
    DevicesComponent.prototype.loadSessions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sessions;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.execute('usersessionlist', {})];
                    case 1:
                        sessions = _a.sent();
                        this.sessions = sessions.sessions;
                        return [2 /*return*/];
                }
            });
        });
    };
    DevicesComponent.prototype.loadDevices = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var devices, locData, i, len;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.execute('devicelist', {})];
                    case 1:
                        devices = _a.sent();
                        this.devices = devices.devices;
                        locData = this.dispatch.getDipatchData();
                        if (locData && locData.device_id) {
                            for (i = 0, len = this.devices.length; i < len; i++) {
                                if (this.devices[i].id == locData.device_id) {
                                    this.activateDevice(this.devices[i]);
                                    break;
                                }
                            }
                            this.dispatch.removeDispatch();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return DevicesComponent;
}());
exports.DevicesComponent = DevicesComponent;
