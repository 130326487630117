"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var ModalComponent = /** @class */ (function () {
    function ModalComponent(modalService) {
        this.modalService = modalService;
        /**
        * @property {boolean} open
        * Flag to open modal
        * Default value is false. passing this value true will open the modal with given properties
        */
        this.open = false;
        /**
        * @property {boolean} showCancelButton
        * Flag to show cancel button
        * Default value is true. passing this value false will hide the cancel button from modal footer.
        */
        this.showCancelButton = true;
        this.onClose = function () { };
    }
    ModalComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.open && changes.open.currentValue) {
            this.activeModal = this.modalService.open(this.modalContent, {
                backdropClass: 'in',
                windowClass: 'in',
                backdrop: 'static'
            });
            this.activeModal.result.then(function () {
                _this.close();
            }, function () {
                _this.close();
            });
        }
        else if (changes.open && changes.open.previousValue === true && changes.open.currentValue === false) {
            this.activeModal.close();
        }
    };
    ModalComponent.prototype.close = function () {
        this.onClose();
        this.activeModal.close();
    };
    return ModalComponent;
}());
exports.ModalComponent = ModalComponent;
