"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../shared/components/header/sharesaccept/header-shares-accept.component.ngfactory");
var i2 = require("../../shared/components/header/sharesaccept/header-shares-accept.component");
var i3 = require("@ngrx/store");
var i4 = require("../../core/validate.service");
var i5 = require("../../core/logger.service");
var i6 = require("@angular/platform-browser");
var i7 = require("../../core/user.service");
var i8 = require("@ng-bootstrap/ng-bootstrap");
var i9 = require("../../account/services/zuora-account.service");
var i10 = require("../../shares/share-invite.service");
var i11 = require("@angular/router");
var i12 = require("../../shared/components/footer/footer.component.ngfactory");
var i13 = require("../../shared/components/footer/footer.component");
var i14 = require("./share-invite-layout.component");
var styles_ShareInviteLayoutComponent = [];
var RenderType_ShareInviteLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShareInviteLayoutComponent, data: {} });
exports.RenderType_ShareInviteLayoutComponent = RenderType_ShareInviteLayoutComponent;
function View_ShareInviteLayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-header-shares-accept", [], [[1, "style", 2]], null, null, i1.View_HeaderSharesAcceptComponent_0, i1.RenderType_HeaderSharesAcceptComponent)), i0.ɵdid(1, 245760, null, 0, i2.HeaderSharesAcceptComponent, [i3.Store, i4.ValidateService, i5.LoggerService, i6.DomSanitizer, i7.UserService, i8.NgbModal, i9.ZuoraAccountService, i10.ShareInviteService, i11.ActivatedRoute, i11.Router], null, null), (_l()(), i0.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(3, 212992, null, 0, i11.RouterOutlet, [i11.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "sync-footer", [], null, null, null, i12.View_FooterComponent_0, i12.RenderType_FooterComponent)), i0.ɵdid(5, 114688, null, 0, i13.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).customColorsCss; _ck(_v, 0, 0, currVal_0); }); }
exports.View_ShareInviteLayoutComponent_0 = View_ShareInviteLayoutComponent_0;
function View_ShareInviteLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-share-invite-layout", [], null, null, null, View_ShareInviteLayoutComponent_0, RenderType_ShareInviteLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i14.ShareInviteLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ShareInviteLayoutComponent_Host_0 = View_ShareInviteLayoutComponent_Host_0;
var ShareInviteLayoutComponentNgFactory = i0.ɵccf("sync-share-invite-layout", i14.ShareInviteLayoutComponent, View_ShareInviteLayoutComponent_Host_0, {}, {}, []);
exports.ShareInviteLayoutComponentNgFactory = ShareInviteLayoutComponentNgFactory;
