"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("../error/error.component.ngfactory");
var i3 = require("../error/error.component");
var i4 = require("../../../core/user.service");
var i5 = require("@ng-bootstrap/ng-bootstrap");
var i6 = require("@angular/platform-browser");
var i7 = require("./verify-email-required.component");
var i8 = require("../../../core/api.service");
var styles_VerifyEmailRequiredComponent = [];
var RenderType_VerifyEmailRequiredComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VerifyEmailRequiredComponent, data: {} });
exports.RenderType_VerifyEmailRequiredComponent = RenderType_VerifyEmailRequiredComponent;
function View_VerifyEmailRequiredComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-spinner fa-spin"]], null, null, null, null, null))], null, null); }
function View_VerifyEmailRequiredComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "alert alert-info"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Please verify your account. "])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "button", [["class", "btn btn-primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resendVerification() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VerifyEmailRequiredComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, [" Re-send verification "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.spinner; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.spinner; _ck(_v, 3, 0, currVal_0); }); }
function View_VerifyEmailRequiredComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-success"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" You should receive an email at ", " soon.\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userEmail; _ck(_v, 1, 0, currVal_0); }); }
function View_VerifyEmailRequiredComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-error", [], null, null, null, i2.View_ErrorComponent_0, i2.RenderType_ErrorComponent)), i0.ɵdid(1, 114688, null, 0, i3.ErrorComponent, [i4.UserService, i5.NgbModal, i6.DomSanitizer], { errcode: [0, "errcode"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VerifyEmailRequiredComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VerifyEmailRequiredComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errcode; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.success; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.success; _ck(_v, 5, 0, currVal_2); }, null); }
exports.View_VerifyEmailRequiredComponent_0 = View_VerifyEmailRequiredComponent_0;
function View_VerifyEmailRequiredComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-verify-email-required", [], null, null, null, View_VerifyEmailRequiredComponent_0, RenderType_VerifyEmailRequiredComponent)), i0.ɵdid(1, 114688, null, 0, i7.VerifyEmailRequiredComponent, [i8.ApiService, i4.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_VerifyEmailRequiredComponent_Host_0 = View_VerifyEmailRequiredComponent_Host_0;
var VerifyEmailRequiredComponentNgFactory = i0.ɵccf("sync-verify-email-required", i7.VerifyEmailRequiredComponent, View_VerifyEmailRequiredComponent_Host_0, {}, {}, []);
exports.VerifyEmailRequiredComponentNgFactory = VerifyEmailRequiredComponentNgFactory;
