"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var share_new_crypt_data_model_1 = require("./share-new-crypt-data.model");
var ShareNewData = /** @class */ (function (_super) {
    tslib_1.__extends(ShareNewData, _super);
    function ShareNewData() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ShareNewData;
}(share_new_crypt_data_model_1.ShareNewCryptData));
exports.ShareNewData = ShareNewData;
