"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var logger_service_1 = require("../../core/logger.service");
var http_1 = require("@angular/common/http");
var transfer_service_1 = require("../../transfer/transfer.service");
var link_file_list_service_1 = require("../../link-consume/services/link-file-list.service");
var build_transfer_item_service_1 = require("../../transfer/services/build-transfer-item.service");
var api_service_1 = require("../../core/api.service");
var models_1 = require("../../shared/models");
var blend_service_1 = require("../../shared/services/blend.service");
var PreviewTextComponent = /** @class */ (function () {
    function PreviewTextComponent(http, Logger, Transfer, LinkPathList, buildTransferItemService, apiService, blendService) {
        var _this = this;
        this.http = http;
        this.Logger = Logger;
        this.Transfer = Transfer;
        this.LinkPathList = LinkPathList;
        this.buildTransferItemService = buildTransferItemService;
        this.apiService = apiService;
        this.blendService = blendService;
        this.spinner = false;
        this.onCommentToggle = function (isCommentExpanded) {
            _this.isCommentExpanded = isCommentExpanded;
        };
    }
    PreviewTextComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.transferItem) {
            this.transferItem.renderFile.url = undefined;
            this.transferItem.renderFile.msblob = undefined;
            this.transferItem.renderFile.dl_ready = false;
        }
        this.reset();
        // console.log(this.item);
        if (this.item.compat && this.item.context == 'applink') {
            this.loadCompatDocApp();
        }
        else if (this.item.compat) {
            this.loadCompatDoc();
        }
        else {
            this.loadText();
        }
        this.sub = this.LinkPathList.getSubscription().subscribe(function (data) {
            if (data.loaded && data.sorted) {
                _this.allowComment = data.allow_comment;
            }
        });
        this.blendService.track(models_1.BlendEvent.VIEW_FILE, {
            type: 'text',
            preview_preference: 'N/A',
            fileSize: this.item.filesize,
            mimeType: this.item.mime_type
        });
    };
    PreviewTextComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    };
    PreviewTextComponent.prototype.reset = function () {
        this.content = undefined;
        this.errorMsg = undefined;
        this.spinner = false;
    };
    PreviewTextComponent.prototype.loadText = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isPreview, tItem, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.reset();
                        this.spinner = true; // toggled in renderPdf
                        isPreview = true;
                        return [4 /*yield*/, this.Transfer.getFile(this.item, isPreview)];
                    case 1:
                        tItem = _a.sent();
                        if (tItem.renderFile.url) {
                            this.Logger.info('loadText success for ' + this.item.sync_id);
                            if (this.item.linkID) {
                                try {
                                    this.Logger.info('Consume link');
                                    this.consume([this.item.linkID]);
                                }
                                catch (ex) {
                                    this.Logger.e('Error consuming link ', ex);
                                }
                            }
                            // this.renderPdf(tItem.renderFile.url);
                            this.renderText(tItem.renderFile.url);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.spinner = false;
                        this.errorMsg = 'An error occurred loading the preview';
                        this.Logger.error('loadText failed  for ' + this.item.sync_id);
                        if (typeof err_1 === 'object') {
                            this.Logger.error(JSON.stringify(err_1));
                        }
                        else if (typeof err_1 === 'string') {
                            this.Logger.error(err_1);
                        }
                        else {
                            this.Logger.error('Unknown error occurred');
                        }
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PreviewTextComponent.prototype.consume = function (links) {
        var publinks = [];
        for (var i = 0, len = links.length; i < len; i++) {
            publinks.push({ publink_id: links[i] });
        }
        return this.apiService.execute('linkconsume', { links: publinks });
    };
    PreviewTextComponent.prototype.loadCompatDocApp = function () {
        this.spinner = true;
        this.renderText(this.item.compaturl);
    };
    PreviewTextComponent.prototype.loadCompatDoc = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.spinner = true;
                        return [4 /*yield*/, this.buildTransferItemService.getCompatUrl(this.item, this.buildTransferItemService.ACT_PREVIEW)];
                    case 1:
                        url = _a.sent();
                        this.Logger.info('loadCompatDoc success for ' + this.item.sync_id);
                        if (this.item.linkID) {
                            try {
                                this.Logger.info('Consume link');
                                this.consume([this.item.linkID]);
                            }
                            catch (ex) {
                                this.Logger.e('Error consuming link ', ex);
                            }
                        }
                        this.renderText(url);
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        this.spinner = false;
                        this.errorMsg = 'An error occurred loading the preview';
                        this.Logger.error('loadCompatDoc failed  for ' + this.item.sync_id);
                        if (typeof err_2 === 'object') {
                            this.Logger.error(JSON.stringify(err_2));
                        }
                        else if (typeof err_2 === 'string') {
                            this.Logger.error(err_2);
                        }
                        else {
                            this.Logger.error('Unknown error occurred');
                        }
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PreviewTextComponent.prototype.renderText = function (url) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.url = url;
                        return [4 /*yield*/, this.http.get(url, { responseType: 'text' }).toPromise()];
                    case 1:
                        result = _a.sent();
                        this.content = result;
                        this.spinner = false;
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        this.spinner = false;
                        this.Logger.error(err_3);
                        this.errorMsg = 'Could not load text file for preview';
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return PreviewTextComponent;
}());
exports.PreviewTextComponent = PreviewTextComponent;
