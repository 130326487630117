"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var share_list_member_model_1 = require("./share-list-member.model");
var ShareListActiveItem = /** @class */ (function () {
    function ShareListActiveItem() {
    }
    ShareListActiveItem.prototype.deserialize = function (input) {
        Object.assign(this, input);
        var it = 0;
        for (var i = 0, len = input.people_list.length; i < len; i++) {
            if (input.people_list[i].uniqid !== input.uniqid) {
                console.log('looping people');
                it += 1;
                var person = new share_list_member_model_1.ShareListMember().deserialize(input.people_list[i]);
                if (it === 1) {
                    this.person1 = person;
                }
                else if (it === 2) {
                    this.person2 = person;
                }
                else if (it === 3) {
                    this.person3 = person;
                }
                else if (it === 4) {
                    this.person4 = person;
                }
            }
            // this.people_list.push(person);
        }
        return this;
    };
    return ShareListActiveItem;
}());
exports.ShareListActiveItem = ShareListActiveItem;
