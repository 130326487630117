"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AuthActions = require("../actions/auth.actions");
exports.initialState = {
    user: undefined,
    authenticated: false,
    error: undefined,
    progress: 0,
    twofarequired: false,
};
function reducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case AuthActions.AUTH_LOGIN_SSO:
        case AuthActions.AUTH_LOGIN:
            return Object.assign({}, state, { error: undefined });
        case AuthActions.AUTH_LOGIN_SUCCESS:
            return Object.assign({}, state, {
                user: action.payload,
                authenticated: true,
                error: undefined,
                progress: 0,
            });
        case AuthActions.AUTH_LOGIN_FAILURE:
            if (action.payload && action.payload.code === 8022) {
                return Object.assign({}, state, {
                    twofarequired: true,
                    progress: 0,
                });
            }
            else {
                return Object.assign({}, state, {
                    error: action.payload,
                    progress: 0,
                });
            }
        // case AuthActions.AUTH_REFRESH:
        //     console.log('Action type refresh');
        //     console.log(action.payload);
        //     break;
        case AuthActions.AUTH_UPDATE_USER:
            return Object.assign({}, state, {
                user: action.payload,
            });
        case AuthActions.AUTH_LOGIN_TWOFACTOR:
            return Object.assign({}, state, { twofarequired: true });
        case AuthActions.AUTH_LOGOUT:
            return exports.initialState;
        case AuthActions.AUTH_LOGIN_PROGRESS:
            return Object.assign({}, state, { progress: action.payload });
        default:
            return state;
    }
}
exports.reducer = reducer;
exports.getAuthenticated = function (state) { return state.authenticated; };
exports.getUser = function (state) { return state.user; };
exports.getLoginProgress = function (state) { return state.progress; };
exports.getErrCode = function (state) { return state.error; };
