"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("../../core/api.service");
var logger_service_1 = require("../../core/logger.service");
var models_1 = require("../../shared/models");
var i0 = require("@angular/core");
var i1 = require("../../core/api.service");
var i2 = require("../../core/logger.service");
var RewardsService = /** @class */ (function () {
    function RewardsService(api, log) {
        this.api = api;
        this.log = log;
    }
    /**
     * @ngdoc method
     * @name  getData
     * @methodOf sync.service:RewardsService
     * @description
     * Gets a list of rewards
     * @returns {Promise} The newly created promise
     */
    RewardsService.prototype.getData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, rewards, i, len;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.execute('rewardlist', {})];
                    case 1:
                        data = _a.sent();
                        rewards = new models_1.UserRewards();
                        rewards.list = [];
                        rewards.from_sync = [];
                        rewards.referral_code = data.referral_code;
                        rewards.is_referred = data.signup_referred;
                        rewards.total = 0;
                        for (i = 0, len = data.list.length; i < len; i++) {
                            if (data.list[i].status == 1) {
                                rewards.total += data.list[i].bonus_bytes;
                                if (data.list[i].bonus_type == 'admin') {
                                    data.list[i].bonus_text = data.list[i].comment;
                                    rewards.from_sync.push(data.list[i]);
                                }
                                else if (data.list[i].bonus_type.indexOf('sync-') === 0) {
                                    data.list[i].bonus_text = data.list[i].bonus_type_text;
                                    rewards.from_sync.push(data.list[i]);
                                }
                                else {
                                    data.list[i].bonus_text = data.list[i].bonus_type_text;
                                    rewards.list.push(data.list[i]);
                                }
                            }
                        }
                        rewards.count = rewards.from_sync.length + rewards.list.length;
                        return [2 /*return*/, rewards];
                }
            });
        });
    };
    /*
     * Some rewards can only be claimed once, this will attempt to claim
     * them and wait for the API to reject or allow.
     * @param {STring} rewardCode The code to attempt to claim
     * @returns {Promise} The newly created promise
     */
    RewardsService.prototype.claimRewardOnce = function (code) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.execute('rewardclaimonce', { reward_code: code })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    RewardsService.prototype.claimReward = function (code) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.log.info('Claiming reward ' + code);
                        return [4 /*yield*/, this.api.execute('rewardclaim', { referrer_code: code })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.getData()];
                }
            });
        });
    };
    /**
     * Returns user id of user who referred for sign up.
     */
    RewardsService.prototype.getSignupReferralUserId = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var rewards, signupReferred;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getData()];
                    case 1:
                        rewards = _a.sent();
                        if (rewards.is_referred) {
                            signupReferred = rewards.list.filter(function (reward) { return reward.bonus_type === 'signup-referred'; })[0];
                            return [2 /*return*/, signupReferred && signupReferred.bonus_from_id];
                        }
                        return [2 /*return*/, null];
                }
            });
        });
    };
    RewardsService.ngInjectableDef = i0.defineInjectable({ factory: function RewardsService_Factory() { return new RewardsService(i0.inject(i1.ApiService), i0.inject(i2.LoggerService)); }, token: RewardsService, providedIn: "root" });
    return RewardsService;
}());
exports.RewardsService = RewardsService;
