"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var environment_1 = require("../../../environments/environment");
var models_1 = require("../../shared/models");
var logger_service_1 = require("../../core/logger.service");
var link_file_list_service_1 = require("../../link-consume/services/link-file-list.service");
var file_item_service_1 = require("../file-item.service");
var transfer_item_service_1 = require("../../transfer/services/transfer-item.service");
var http_1 = require("@angular/common/http");
var api_service_1 = require("../../core/api.service");
var build_transfer_item_service_1 = require("../../transfer/services/build-transfer-item.service");
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var blend_service_1 = require("../../shared/services/blend.service");
var file_uploader_1 = require("../../transfer/file-uploader");
var PreviewWopiComponent = /** @class */ (function () {
    function PreviewWopiComponent(loggerService, linkPathListService, fileItemService, transferItemService, http, apiService, buildTransferItemService, router, route, store, blendService, injector) {
        var _this = this;
        this.loggerService = loggerService;
        this.linkPathListService = linkPathListService;
        this.fileItemService = fileItemService;
        this.transferItemService = transferItemService;
        this.http = http;
        this.apiService = apiService;
        this.buildTransferItemService = buildTransferItemService;
        this.router = router;
        this.route = route;
        this.store = store;
        this.blendService = blendService;
        this.injector = injector;
        // Important environment for providing to WOPI
        this.wopiUrl = environment_1.environment.wopihost + '/wopi/init/';
        this.hostUrl = environment_1.environment.currenthost;
        this.spinner = true;
        this.stateChange = new core_1.EventEmitter();
        // Initialize values, to POST to Wopiinit
        this.isReadOnly = false;
        this.initialized = false;
        this.timeout = 15000; // Timeout the entire operation after 15s
        this.faviconurl = '';
        this.faviconurl_original = '';
        this.compat = 0;
        this.onCommentToggle = function (isCommentExpanded) {
            _this.isCommentExpanded = isCommentExpanded;
        };
        this.fileUploader = new file_uploader_1.FileUploader(this.injector);
    }
    PreviewWopiComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var wopiFileExt, _a, _b, ex_1;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.linkPassword = this.route.snapshot.params['key'] || this.route.snapshot.fragment;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 6, , 7]);
                        // Set a timeout for the entirety of the wopi initialization operation
                        this.startTime = Date.now();
                        this.userSubscribe = this.store
                            .pipe(store_1.select(fromRoot.getAuthUser))
                            .subscribe(function (data) {
                            if (data) {
                                _this.user = data;
                            }
                            else {
                                _this.user = null;
                            }
                        });
                        this.sub = this.linkPathListService
                            .getSubscription()
                            .subscribe(function (data) {
                            if (data.loaded && data.sorted) {
                                _this.isUploadAllowed = data.upload;
                                _this.isFileEditAllowed = data.file_edit;
                                _this.allowComment = data.allow_comment;
                                _this.isCwdShared = data.cwd.is_shared;
                                _this.compat = data.compat;
                            }
                        });
                        setTimeout(function () {
                            if (_this.spinner && !_this.errcode) {
                                _this.spinner = false;
                                throw new models_1.ErrCode(11006, 'Request timed out. Please contact support');
                            }
                        }, this.timeout);
                        this.type = this.route.snapshot.params['type'];
                        // Init all of the required data, and then create the request payload
                        this.inLink = this.item.context !== 'files';
                        wopiFileExt = this.fileItemService.getFileExt(this.item.name);
                        if (this.type === 'officefse') {
                            this.blendService.track(models_1.BlendEvent.EDIT_FILE_OFFICE, {
                                type: wopiFileExt,
                                platform: this.inLink ? 'Link' : 'CP',
                                filesize: this.item.filesize || this.item.size + ' bytes',
                                mimeType: this.item.mime_type
                            });
                        }
                        else {
                            this.blendService.track(models_1.BlendEvent.VIEW_FILE, {
                                type: wopiFileExt,
                                preview_preference: 'Office',
                                platform: this.inLink ? 'Link' : 'CP',
                                filesize: this.item.filesize || this.item.size + ' bytes',
                                mimeType: this.item.mime_type
                            });
                        }
                        return [4 /*yield*/, this.init()];
                    case 2:
                        _c.sent();
                        _a = this;
                        return [4 /*yield*/, this.buildRequest()];
                    case 3:
                        _a.wopiInitReq = _c.sent();
                        this.loggerService.info('Built Wopi Request, sending to ' + this.wopiUrl);
                        this.loggerService.info(this.wopiInitReq);
                        // Send the request to Wopi
                        _b = this;
                        return [4 /*yield*/, this.wopiInit(this.wopiInitReq)];
                    case 4:
                        // Send the request to Wopi
                        _b.wopiInitResp = _c.sent();
                        this.loggerService.info('Recieved successful response from Wopi. Attempting to pass to MS.');
                        this.loggerService.info(this.wopiInitResp);
                        // Submit the data received from Wopi to MS
                        return [4 /*yield*/, this.buildAndSubmitForm()];
                    case 5:
                        // Submit the data received from Wopi to MS
                        _c.sent();
                        this.spinner = false;
                        return [3 /*break*/, 7];
                    case 6:
                        ex_1 = _c.sent();
                        this.loggerService.error(ex_1);
                        if (ex_1 instanceof models_1.ErrCode) {
                            this.errcode = ex_1;
                        }
                        else {
                            this.errcode = new models_1.ErrCode(11000, 'Microsoft Preview unable to load');
                        }
                        this.spinner = false;
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    PreviewWopiComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.userSubscribe) {
            this.userSubscribe.unsubscribe();
        }
        document
            .getElementsByName('defaultfavicon')[0]
            .setAttribute('href', this.faviconurl_original);
    };
    PreviewWopiComponent.prototype.unauthUserInLink = function () {
        return this.inLink && !this.user;
    };
    PreviewWopiComponent.prototype.allowLinkEdit = function () {
        if (this.inLink) {
            return this.fileItemService.isOfficeLinkEdit(this.item, this.isUploadAllowed, this.isFileEditAllowed, this.isCwdShared);
        }
        return true;
    };
    PreviewWopiComponent.prototype.buildAndSubmitForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var queryParams, MSQueryParams, _i, _a, key, actionUrl, form, field1, field2, sendTime, message, iframe, iWindow;
            return tslib_1.__generator(this, function (_b) {
                queryParams = this.route.snapshot.queryParams;
                MSQueryParams = '';
                for (_i = 0, _a = Object.keys(queryParams); _i < _a.length; _i++) {
                    key = _a[_i];
                    MSQueryParams += key + "=" + queryParams[key] + "&";
                }
                // do not strip queryParams from url in publink, as we require sync_id in publinks for linkpathlist
                this.inLink ?
                    this.router.navigate([], tslib_1.__assign({ relativeTo: this.route }, this.linkPathListService.decorateQueryParams(this.route.snapshot.queryParams), this.linkPathListService.decorateFragment(this.compat, this.route.snapshot.fragment)))
                    : this.router.navigate([], { relativeTo: this.route, queryParams: {} });
                // TODO: Is there a better way to set the faviconurl?
                this.faviconurl = this.wopiInitResp.faviconurl;
                if (this.type !== 'office' && this.faviconurl) {
                    this.faviconurl_original = document
                        .getElementsByName('defaultfavicon')[0]
                        .getAttribute('href');
                    document
                        .getElementsByName('defaultfavicon')[0]
                        .setAttribute('href', this.faviconurl);
                }
                this.iFrameTime = Date.now();
                actionUrl = this.wopiInitResp.actionurl + '?' + MSQueryParams;
                this.loggerService.info("Posting to \"" + actionUrl + "\"");
                form = document.createElement('form');
                form.setAttribute('method', 'POST');
                form.setAttribute('id', 'office_form');
                form.setAttribute('name', 'office_form');
                form.setAttribute('target', 'office_frame');
                form.setAttribute('action', actionUrl);
                field1 = document.createElement('input');
                field2 = document.createElement('input');
                field1.setAttribute('name', 'access_token');
                field1.setAttribute('value', this.wopiInitResp.access_token);
                field2.setAttribute('name', 'access_token_ttl');
                field2.setAttribute('value', this.wopiInitResp.access_token_ttl.toString());
                form.appendChild(field1);
                form.appendChild(field2);
                document.body.appendChild(form);
                if (!this.errcode) {
                    form.submit();
                }
                document.body.removeChild(form);
                sendTime = Date.now();
                message = {
                    MessageId: 'Host_PerfTiming',
                    SendTime: sendTime,
                    Values: {
                        Click: this.startTime,
                        Iframe: this.iFrameTime,
                        HostFrameFetchStart: 0,
                        RedirectCount: 0,
                    },
                };
                iframe = document.getElementById('office_frame');
                iWindow = iframe.contentWindow;
                iWindow.postMessage(message, window.parent.origin);
                this.loggerService.info(JSON.parse(JSON.stringify(message)));
                return [2 /*return*/];
            });
        });
    };
    PreviewWopiComponent.prototype.init = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var keys, navigationParams, retries, delay_1, i, res, _a, _b, _c, app;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!(this.type === 'office' || (this.unauthUserInLink()))) return [3 /*break*/, 2];
                        this.msOperation = 'embedview';
                        return [4 /*yield*/, this.buildTransferItemService.getFileKeys([this.item])];
                    case 1:
                        keys = _d.sent();
                        this.loggerService.warn(keys);
                        if (keys && (!keys.previewtoken || keys.previewtoken == '')) {
                            this.loggerService.info('No preview token available ' + this.item.sync_id);
                            this.stateChange.emit({ type: 'default', upsell: true });
                            return [2 /*return*/];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        if (this.type === 'officefse') {
                            this.msOperation = 'edit';
                        }
                        else if (this.type === 'officefsv') {
                            this.msOperation = 'view';
                        }
                        else {
                            this.loggerService.error('Unknown command type hit');
                            throw new models_1.ErrCode(11009, 'This action cannot be performed on this file.');
                        }
                        _d.label = 3;
                    case 3:
                        if (!!this.allowLinkEdit()) return [3 /*break*/, 4];
                        // WHEN LINKS ARE ENABLED (in the future)
                        // If the user is in a link we want to:
                        // 1. Let them work on local and shares (but only in the publink layer)
                        // 2. Let them use actions: embedded
                        // 3. Ignore their authentication, if present
                        if (this.router.url.includes('/dl')) {
                            navigationParams = ['/dl', this.route.snapshot.params['cachekey'], 'view', 'doc', this.item.sync_id];
                            // add key in navigation params array if it exists in router params, otherwise key after # in fragment will be used
                            if (this.route.snapshot.params['key']) {
                                navigationParams.splice(2, 0, this.route.snapshot.params['key']);
                            }
                            this.router.navigate(navigationParams, tslib_1.__assign({}, this.linkPathListService.decorateQueryParams(this.route.snapshot.queryParams), this.linkPathListService.decorateFragment(this.compat, this.route.snapshot.fragment)));
                        }
                        this.loggerService.error('User attempted to open a file in a link');
                        this.errcode = new models_1.ErrCode(11010, 'User attempted to open a file in a link');
                        throw this.errcode;
                    case 4:
                        if (!this.item.is_shared) return [3 /*break*/, 11];
                        retries = 3;
                        delay_1 = 3000;
                        i = retries;
                        _d.label = 5;
                    case 5:
                        if (!(i > 0)) return [3 /*break*/, 10];
                        return [4 /*yield*/, this.apiService.execute('pathdirtycheck', {
                                sync_id: this.item.sync_id,
                                share_id: this.item.share_id
                            })];
                    case 6:
                        res = _d.sent();
                        if (!(res.status === 0)) return [3 /*break*/, 8];
                        this.loggerService.error("Users path is dirty, waitng " + delay_1 + "ms and retrying " + i + " more times.");
                        return [4 /*yield*/, new Promise(function (resolve) {
                                return setTimeout(resolve, delay_1);
                            })];
                    case 7:
                        _d.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        this.masterlinks_id = res.masterlinks_id;
                        return [3 /*break*/, 10];
                    case 9:
                        i--;
                        return [3 /*break*/, 5];
                    case 10:
                        if (!this.masterlinks_id) {
                            this.loggerService.error("Couldn't clean users path after " + retries + " attempts.");
                            throw new models_1.ErrCode(11001, 'This file is too out of date to work on collaboratively; please wait or contact support.');
                        }
                        _d.label = 11;
                    case 11:
                        _a = this;
                        return [4 /*yield*/, this.getUpload(this.item)];
                    case 12:
                        _a.uploadItem = _d.sent();
                        this.loggerService.info('UploadItem');
                        this.loggerService.info(this.uploadItem);
                        this.loggerService.info([
                            'Wopi upload item',
                            'syncPId:',
                            this.uploadItem.sync_pid,
                            'mime:',
                            this.uploadItem.mimetype,
                            'size:',
                            this.uploadItem.filesize,
                            'date:',
                            this.uploadItem.filedate,
                        ].join(' '));
                        if (!!this.inLink) return [3 /*break*/, 14];
                        _b = this;
                        return [4 /*yield*/, this.getDump(this.item, this.uploadItem)];
                    case 13:
                        _b.webfinishbackup = _d.sent();
                        _d.label = 14;
                    case 14:
                        this.isReadOnly = Boolean(this.item.is_shared && this.item.is_readonly);
                        _d.label = 15;
                    case 15:
                        // TODO: We may want user data for people accessing links at some point. This might do it:
                        // this.currentUser = this.userService.isAuthenticated() ? this.userService.getUser() : undefined;
                        this.currentUser = this.unauthUserInLink() ? undefined : this.user;
                        _c = this;
                        return [4 /*yield*/, this.getDownload(this.item)];
                    case 16:
                        _c.downloadItem = _d.sent();
                        this.loggerService.info('DownloadItem');
                        this.loggerService.info(this.downloadItem);
                        this.loggerService.info([
                            'Wopi download item: ',
                            'syncId:',
                            this.downloadItem.sync_id,
                            'blobType:',
                            this.downloadItem.blobtype,
                            'mime:',
                            this.downloadItem.mimetype,
                            'size:',
                            this.downloadItem.filesize,
                            'date:',
                            this.downloadItem.filedate,
                        ].join(' '));
                        // this.serverUrls = await this.getServers(this.item);
                        this.wopiFileId = this.getWopiFileId(this.item, this.currentUser);
                        this.wopiUrl = this.wopiUrl + this.wopiFileId;
                        app = this.fileItemService.getMSOfficeApp(this.item);
                        if (app === 'None') {
                            this.loggerService.error('User attempted to open a filetype not compatible with office: ' +
                                this.item.name);
                            throw new models_1.ErrCode(11010, 'This file is not compatible with Office 365');
                        }
                        else {
                            this.msProgram = app;
                        }
                        this.initialized = true;
                        this.loggerService.info('Init Complete');
                        return [2 /*return*/];
                }
            });
        });
    };
    PreviewWopiComponent.prototype.getDownload = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d, _e, _f;
            return tslib_1.__generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        if (!this.inLink) return [3 /*break*/, 2];
                        _b = (_a = this.buildTransferItemService).mkDownloadItem;
                        _c = [item];
                        return [4 /*yield*/, this.buildTransferItemService.getFileKeys([item])];
                    case 1: return [2 /*return*/, _b.apply(_a, _c.concat([_g.sent()]))];
                    case 2:
                        _e = (_d = this.buildTransferItemService).mkDownloadItem;
                        _f = [item];
                        return [4 /*yield*/, this.buildTransferItemService.getFileKeys([item])];
                    case 3: return [2 /*return*/, _e.apply(_d, _f.concat([_g.sent()]))];
                }
            });
        });
    };
    PreviewWopiComponent.prototype.getUpload = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uploadItem;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.transferItemService.get({
                            type: this.transferItemService.TYPE_UPLOAD,
                            sync_pid: item.pid,
                            filename: item.name,
                            filesize: 1,
                            fileobj: undefined,
                            status: 0,
                            filedate: Date.now(),
                        })];
                    case 1:
                        uploadItem = (_a.sent());
                        return [4 /*yield*/, this.fileUploader.initSha1Digest(uploadItem)];
                    case 2:
                        uploadItem = _a.sent();
                        uploadItem.sync_pid = item.pid;
                        // sync_id of the file is required to fetch the correct sharekeys for webfinishbackup
                        // webfinishbackup is invoked from wopi and the sharekeys are passed from wopi to the api
                        uploadItem.sync_id = item.id;
                        return [2 /*return*/, uploadItem];
                }
            });
        });
    };
    PreviewWopiComponent.prototype.getDump = function (item, upload) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var webfinishbackup;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fileUploader.buildFinishBackup(upload)];
                    case 1:
                        webfinishbackup = _a.sent();
                        webfinishbackup.is_wopi_save = 1;
                        return [2 /*return*/, webfinishbackup];
                }
            });
        });
    };
    PreviewWopiComponent.prototype.getWopiFileId = function (item, user) {
        this.loggerService.info('Item and then User');
        this.loggerService.info(item);
        this.loggerService.info(user);
        var wopiFileId;
        if (item.u_id && item.u_id !== '') {
            wopiFileId = item.u_id;
        }
        else {
            if (this.inLink) {
                // NOTE: A user could have a publink on top of a share.
                // This will /NOT/ collide with that.
                // TODO: Edit collaboratively with people outside of sync will have to choose what to edit on
                // 1. The publink (will make it difficult to also collab with sharers)
                // 2. The webpaths/share (will make it difficult to write from unauth)
                wopiFileId = 'P' + item.link_owner_id + '-' + item.id;
            }
            else {
                item.is_shared
                    ? wopiFileId = 'S' + item.share_id + '-' + this.masterlinks_id
                    : wopiFileId = 'U' + user.id + '-' + item.id;
            }
        }
        this.loggerService.warn('Wopi FileID defined as "' + wopiFileId + '"');
        return wopiFileId;
    };
    PreviewWopiComponent.prototype.buildRequest = function () {
        if (!this.initialized) {
            throw new models_1.ErrCode(11000, "Could not make request, file was not properly initialized");
        }
        else {
            return {
                file: {
                    file_id: this.wopiFileId,
                    cachekey: btoa(this.downloadItem.cachekey),
                    datakey_old: btoa(this.downloadItem.data_key.toString()),
                    filename: this.downloadItem.name,
                    filesize: this.downloadItem.filesize,
                    timestamp: this.item.usertime,
                    // TODO: Does a real owner_id exist?
                    owner_id: this.inLink
                        ? this.item.link_owner_id
                        : this.currentUser.id,
                    mode: 'legacy'
                },
                user: this.inLink
                    ? {
                        friendly_name: this.unauthUserInLink() ? 'Anonymous User' : atob(this.currentUser.display_name) ||
                            this.currentUser.email,
                        user_id: this.unauthUserInLink() ? 0 : this.currentUser.id,
                        user_email: this.unauthUserInLink() ? 'Anonymous User' : this.currentUser.email,
                        // TODO: What are the connotations of a device_id of 0 beyond no write API calls?
                        // Should we use this.currentUser.web_device_id or this.item.event_device_id?
                        device_id: this.unauthUserInLink() ? 0 : this.currentUser.web_device_id,
                        readonly: this.isReadOnly,
                        is_business: true,
                        datakey_new: btoa(this.uploadItem.data_key.toString()),
                        dump: {},
                        parent_dir_url: this.hostUrl +
                            '/dl/' + this.item.linkID +
                            '/' + this.linkPassword,
                        parent_dir_name: 'Back to Sync',
                        view_url: this.hostUrl +
                            '/dl/' + this.item.linkID +
                            '/' + this.linkPassword +
                            '/view/officefsv/' + this.item.sync_id,
                        edit_url: this.hostUrl +
                            '/dl/' + this.item.linkID +
                            '/' + this.linkPassword +
                            '/view/officefse/' + this.item.sync_id,
                        msprogram: this.msProgram,
                        action: this.msOperation,
                        mode: 'link',
                        sync_id: this.item.sync_id,
                        sync_pid: this.item.pid,
                        share_id: this.item.share_id,
                        share_key: this.item.share_key,
                        share_key_id: this.item.share_key_id,
                        linkPasswordLock: this.item.linkpasswordlock,
                        linkPassword: this.linkPassword,
                        linkId: this.item.linkID,
                    }
                    : {
                        friendly_name: atob(this.currentUser.display_name) ||
                            this.currentUser.email,
                        user_id: this.currentUser.id,
                        user_email: this.currentUser.email,
                        device_id: this.currentUser.web_device_id,
                        readonly: this.isReadOnly,
                        is_business: this.currentUser.is_office_personal !== 1,
                        datakey_new: btoa(this.uploadItem.data_key.toString()),
                        dump: this.webfinishbackup,
                        parent_dir_url: this.hostUrl +
                            '/files/' +
                            this.item.pid.toString(),
                        parent_dir_name: 'Back to Sync',
                        view_url: this.hostUrl +
                            '/file/' +
                            this.item.sync_id +
                            '/view/officefsv',
                        edit_url: this.hostUrl +
                            '/file/' +
                            this.item.sync_id +
                            '/view/officefse',
                        msprogram: this.msProgram,
                        action: this.msOperation,
                        mode: 'legacy'
                    },
            };
        }
    };
    PreviewWopiComponent.prototype.wopiInit = function (requestbody) {
        var _this = this;
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.http.post(this.wopiUrl, requestbody).subscribe(function (response) {
                    var data = response;
                    if (data &&
                        data['actionurl'] &&
                        data['access_token'] &&
                        data['access_token_ttl']) {
                        resolve(data);
                    }
                    else {
                        _this.loggerService.error('No data recieved from WOPI, but an error did not occur');
                        reject(new models_1.ErrCode(11004, 'The handshake could not be started to initialize the communication with Microsoft'));
                    }
                }, function (error) {
                    _this.loggerService.error('An error occured sending request to WopiInit');
                    reject(error);
                });
                return [2 /*return*/];
            });
        }); });
    };
    return PreviewWopiComponent;
}());
exports.PreviewWopiComponent = PreviewWopiComponent;
