"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var zuora_account_service_1 = require("src/app/account/services/zuora-account.service");
var logger_service_1 = require("src/app/core/logger.service");
var user_service_1 = require("src/app/core/user.service");
var enums_1 = require("../models/enums");
var broadcast_service_1 = require("./broadcast.service");
var plandetails_model_1 = require("../../shared/models/api/plandetails.model");
var i0 = require("@angular/core");
var i1 = require("../../core/user.service");
var i2 = require("../../account/services/zuora-account.service");
var i3 = require("../../core/logger.service");
var i4 = require("./broadcast.service");
var SkuService = /** @class */ (function () {
    function SkuService(userService, zuora, log, broadcastService) {
        var _this = this;
        this.userService = userService;
        this.zuora = zuora;
        this.log = log;
        this.broadcastService = broadcastService;
        this.permissionActions = enums_1.PermissionActions;
        this.broadcastService.on('sync.reset.user').subscribe(function () {
            _this.sku = '';
            _this.zprofile = null;
            _this.planLimit = null;
        });
        this.broadcastService.on('sync.sessionnew.user').subscribe(function () {
            _this.sku = '';
            _this.zprofile = null;
            _this.planLimit = null;
        });
    }
    SkuService.prototype.getSku = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.sku) {
                    return [2 /*return*/, this.sku];
                }
                if (!this.userService.isAuthenticated()) {
                    return [2 /*return*/, ''];
                }
                this.sku = this.userService.get('plan_sku') || 'free';
                if (this.sku === 'free') {
                    this.log.info('Sku not found, defaulting to free');
                }
                return [2 /*return*/, this.sku];
            });
        });
    };
    // Function to tell if teams+ user using sku
    SkuService.prototype.isTeamsPlusUser = function (sku) {
        // expect the sku string as it can be reused at other places as well
        return sku ? (sku.includes('BPTSP') || sku.includes('BPTUP')) : false;
    };
    // Function Add to get the Product Plan SKU value product code like PSP-6T-Y-1 and we get PSP only.
    SkuService.prototype.getProductPlanSKU = function (sku) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!sku) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getSku()];
                    case 1:
                        sku = _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/, sku.split('-')[0]];
                }
            });
        });
    };
    // Function Add to get the  Plan Term value product code like PSP-6T-Y-1 and we get M or Y only.
    SkuService.prototype.getPlanTerm = function (plan) {
        var term = '';
        var planTerm = '';
        if (plan.split('-').length > 2) {
            term = plan.split('-')[2];
        }
        switch (term) {
            case 'M':
                planTerm = plandetails_model_1.PlanTerm.MONTHLY;
                break;
            case 'Y':
                planTerm = plandetails_model_1.PlanTerm.ANNUAL;
                break;
            default:
                planTerm = plandetails_model_1.PlanTerm.ANNUAL;
                this.log.info('Could not determine plan term, defaulting to yearly');
                break;
        }
        return planTerm;
    };
    SkuService.prototype.getPlanType = function (sku) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var productPlan, planType;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getProductPlanSKU(sku)];
                    case 1:
                        productPlan = _a.sent();
                        planType = enums_1.PlanType[productPlan.toLowerCase()] || 'free';
                        return [2 /*return*/, planType];
                }
            });
        });
    };
    SkuService.prototype.isFreeUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isTeamsPlusUser;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isTeamsPlusUser = this.userService.get('is_multi_new') &&
                            this.userService.get('is_unlimited_plan')
                            ? true
                            : false;
                        return [4 /*yield*/, this.getSku()];
                    case 1: return [2 /*return*/, (_a.sent()) == enums_1.PlanType.free && !isTeamsPlusUser];
                }
            });
        });
    };
    SkuService.prototype.getPanLimit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.planLimit) {
                            return [2 /*return*/, this.planLimit];
                        }
                        if (!this.userService.isAuthenticated()) return [3 /*break*/, 5];
                        this.planLimit = this.userService.get('plan_user_limit');
                        if (!!this.planLimit) return [3 /*break*/, 4];
                        if (!(this.zprofile && this.zprofile.subscription)) return [3 /*break*/, 1];
                        this.planLimit = this.zprofile.subscription.quantity;
                        return [3 /*break*/, 4];
                    case 1:
                        if (!(this.userService.get('is_multi_new') && !this.zprofile)) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this.zuora.getProfile()];
                    case 2:
                        _a.zprofile = _b.sent();
                        this.planLimit = this.zprofile.subscription.quantity;
                        return [3 /*break*/, 4];
                    case 3:
                        this.planLimit = 1;
                        _b.label = 4;
                    case 4: return [2 /*return*/, this.planLimit];
                    case 5: return [2 /*return*/, 1];
                }
            });
        });
    };
    SkuService.ngInjectableDef = i0.defineInjectable({ factory: function SkuService_Factory() { return new SkuService(i0.inject(i1.UserService), i0.inject(i2.ZuoraAccountService), i0.inject(i3.LoggerService), i0.inject(i4.BroadcastService)); }, token: SkuService, providedIn: "root" });
    return SkuService;
}());
exports.SkuService = SkuService;
