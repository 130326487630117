"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var api_service_1 = require("../../core/api.service");
var share_list_service_1 = require("../share-list.service");
var logger_service_1 = require("../../core/logger.service");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var share_service_1 = require("../share.service");
var user_service_1 = require("../../core/user.service");
var broadcast_service_1 = require("src/app/shared/services/broadcast.service");
var router_1 = require("@angular/router");
var models_1 = require("src/app/shared/models");
var ExternalShareListComponent = /** @class */ (function () {
    function ExternalShareListComponent(api, shareListService, loggerService, syncCryptService, shareService, userService, router, broadcastService) {
        this.api = api;
        this.shareListService = shareListService;
        this.loggerService = loggerService;
        this.syncCryptService = syncCryptService;
        this.shareService = shareService;
        this.userService = userService;
        this.router = router;
        this.broadcastService = broadcastService;
        this.filter = '';
        this.showFilter = false;
        this.isLoaded = false;
        this.showInfo = false;
        this.showRemoved = false;
        this.predicate = 'name';
        this.predicateReverse = false;
        this.externalShareCount = 0;
        this.isTeamsPlusUser = false;
        this.shareListInProgress = false;
        this.spinners = {
            confirm: 0,
            remove: false,
        };
    }
    ExternalShareListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isTeamsPlusUser =
            this.userService.get('is_multi_new') &&
                this.userService.get('is_unlimited_plan')
                ? true
                : false;
        this.loadShare();
        // The notifications is running this code here.
        this.shareListReloadSub = this.broadcastService.on('event:share-list.reload').subscribe(function () {
            console.log('event reload');
            _this.loadShare();
        });
    };
    ExternalShareListComponent.prototype.ngOnDestroy = function () {
        if (this.shareListReloadSub) {
            this.shareListReloadSub.unsubscribe();
        }
    };
    ExternalShareListComponent.prototype.trackByFn = function (index, share) {
        return share.uniqid;
    };
    ExternalShareListComponent.prototype.hasShareFolderPermission = function () {
        return this.userService.getPermssionByKey(models_1.PermissionActions.SHARE_FOLDERS);
    };
    ExternalShareListComponent.prototype.loadShare = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sharelist, isShareAdmin;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.shareListInProgress) {
                            return [2 /*return*/];
                        }
                        if (!this.isTeamsPlusUser) return [3 /*break*/, 2];
                        this.isLoaded = false;
                        return [4 /*yield*/, this.getList()];
                    case 1:
                        sharelist = _a.sent();
                        this.shares = sharelist;
                        isShareAdmin = this.shares.active.find(function (share) { return share.is_owner === 1; })
                            ? true
                            : false;
                        this.externalShares = isShareAdmin
                            ? this.shares.active.filter(function (share) { return share.is_owner === 1; })
                            : [];
                        this.externalShareCount = isShareAdmin
                            ? this.externalShares.length
                            : 0;
                        this.isLoaded = true;
                        return [3 /*break*/, 3];
                    case 2:
                        this.isLoaded = true;
                        this.externalShareCount = 0;
                        this.externalShares = [];
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /* API call for getting share list */
    ExternalShareListComponent.prototype.getList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 3, 4]);
                        this.shareListInProgress = true;
                        return [4 /*yield*/, this.api.execute('sharelist', { external_user: 1 })];
                    case 1:
                        data = _a.sent();
                        return [4 /*yield*/, this.shareListService.processList(data)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, data];
                    case 3:
                        this.shareListInProgress = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ExternalShareListComponent.prototype.openShareDetailsDailog = function (share, openState) {
        var webpath = Array.isArray(share) ? share[0] : share;
        var shareId = webpath.shareId || webpath.share_id;
        var state = !Number.isNaN(parseInt(openState, 10))
            ? parseInt(openState, 10)
            : 0;
        this.shareService.openDetails(shareId, state);
    };
    ExternalShareListComponent.prototype.onToFiles = function (sync_id) {
        this.router.navigate(['/files', sync_id]);
    };
    ExternalShareListComponent.prototype.setPredicate = function (field, predicateReverse) {
        this.predicate = field;
        this.predicateReverse = predicateReverse;
        var isAsc = !predicateReverse;
        if (this.shares.active.length > 1) {
            this.shares.active.sort(function (a, b) {
                if (isAsc) {
                    return a[field].toString().localeCompare(b[field]);
                }
                else {
                    return b[field].toString().localeCompare(a[field]);
                }
            });
        }
    };
    return ExternalShareListComponent;
}());
exports.ExternalShareListComponent = ExternalShareListComponent;
