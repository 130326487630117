"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_contact_support_component_1 = require("../../account/dialog-contact-support/dialog-contact-support.component");
var logger_service_1 = require("../../core/logger.service");
var models_1 = require("../../shared/models");
var PreviewErrorComponent = /** @class */ (function () {
    function PreviewErrorComponent(modalService, loggerService) {
        this.modalService = modalService;
        this.loggerService = loggerService;
    }
    PreviewErrorComponent.prototype.openContactSupport = function () {
        var ref = this.modalService.open(dialog_contact_support_component_1.DialogContactSupportComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
    };
    PreviewErrorComponent.prototype.ngOnInit = function () {
        this.loggerService.error([
            'Preview encountered an error, location:',
            this.syncId,
            this.errcode.code,
            this.errcode.msg
        ].join(' '));
    };
    return PreviewErrorComponent;
}());
exports.PreviewErrorComponent = PreviewErrorComponent;
