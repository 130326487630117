"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ShareData = /** @class */ (function () {
    function ShareData() {
    }
    ShareData.prototype.deserialize = function (input) {
        var data = input.share;
        this.syncId = data.sync_id;
        this.encName = data.enc_name;
        this.label = data.label;
        this.inviteStatus = data.invite_status;
        this.isReadOnly = data.is_readonly;
        this.isOwner = data.is_owner;
        this.isSeeOthers = data.is_seeothers;
        this.isInvite = data.is_invite;
        this.shareId = data.share_id;
        this.shareSequence = data.share_sequence;
        this.encPassword = data.enc_password;
        this.salt = data.salt;
        this.iterations = data.iterations;
        // this.people = input.people;
        // for (let i = 0, len = data.people.length; i < len; i++) {
        //     this.people.push(new SharememberItem().deserialize(data.people[i]));
        // }
        return this;
    };
    return ShareData;
}());
exports.ShareData = ShareData;
