"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var logger_service_1 = require("../logger.service");
var models_1 = require("../../shared/models");
var i0 = require("@angular/core");
var i1 = require("../logger.service");
var StorageFilesystemService = /** @class */ (function () {
    function StorageFilesystemService(log) {
        this.log = log;
        this.maxsize = 0; // no maximum size
        this.dirname = 'syncpreview';
        this.options = {};
    }
    StorageFilesystemService.prototype.init = function (initOpts) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var key, _a, _b, _c;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        key = initOpts.blobtype + "-" + initOpts.cachekey;
                        this.dirname = initOpts.dirname;
                        _a = this.options;
                        _b = key;
                        _c = {
                            dirname: initOpts.dirname,
                            filesize: initOpts.filesize,
                            filename: initOpts.filename,
                            cachekey: initOpts.cachekey,
                            blobtype: initOpts.blobtype,
                            mimetype: initOpts.mimetype,
                            path: initOpts.dirname + "/" + key
                        };
                        return [4 /*yield*/, this.getSpace(initOpts.filesize)];
                    case 1:
                        _a[_b] = (_c.storetype = _d.sent(),
                            _c);
                        return [2 /*return*/];
                }
            });
        });
    };
    StorageFilesystemService.prototype.test = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var key, storeType, bytearray;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        key = 'btFILE-000test000';
                        this.dirname = 'syncpreview';
                        this.options[key] = {
                            dirname: 'syncpreview',
                            filesize: 1,
                            filename: 'filename.jpg',
                            cachekey: '000test000',
                            blobtype: 'btFILE',
                            mimetype: '',
                            path: 'syncpreview/' + key,
                            storetype: 0
                        };
                        return [4 /*yield*/, this.getSpace(this.options[key].filesize)];
                    case 1:
                        storeType = _a.sent();
                        this.options[key].storetype = storeType;
                        return [4 /*yield*/, this.getFS(storeType, this.options[key])];
                    case 2:
                        _a.sent();
                        bytearray = [0];
                        return [4 /*yield*/, this.writeChunk(this.options[key], bytearray, 0)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    StorageFilesystemService.prototype.clearSpace = function (type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var list;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        list = [];
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                _this.queryUsageAndQuota(type, function (used, remain) {
                                    if (used + remain > 0) {
                                        _this.getFileSystem(type, 1024, function (fs) {
                                            fs.root.getDirectory(_this.dirname, { create: true }, function (dirEntry) {
                                                _this.log.info('Clear space found root dir');
                                                var dirReader = dirEntry.createReader();
                                                dirReader.readEntries(function (entries) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                                    var len, i, entry;
                                                    return tslib_1.__generator(this, function (_a) {
                                                        switch (_a.label) {
                                                            case 0:
                                                                len = entries.length;
                                                                if (!len || len == 0) {
                                                                    this.log.info('The dir is empty');
                                                                    resolve();
                                                                }
                                                                for (i = 0; i < len; i++) {
                                                                    entry = entries[i];
                                                                    if (entry.isFile) {
                                                                        list.push(entry);
                                                                    }
                                                                }
                                                                return [4 /*yield*/, this.deleteFiles(list)
                                                                        .then(function () { return resolve(); }, function (err) { return reject(err); })];
                                                            case 1:
                                                                _a.sent();
                                                                return [2 /*return*/];
                                                        }
                                                    });
                                                }); }, function (err) { return reject(err); });
                                            }, function (err) { return reject(err); });
                                        }, function (err) { return reject(err); });
                                    }
                                    else {
                                        _this.log.info('Cleared all possible');
                                        resolve();
                                    }
                                }, function (err) { return reject(err); });
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    StorageFilesystemService.prototype.exists = function (tItem) {
        return Promise.reject();
    };
    StorageFilesystemService.prototype.getRenderData = function (tItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var key, opts;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        key = tItem.blobtype + "-" + tItem.cachekey, opts = this.options[key];
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                _this.getFileSystem(opts.storetype, tItem.filesize, function (fs) {
                                    fs.root.getFile(opts.path, {}, function (fileEntry) {
                                        resolve({
                                            type: 'url',
                                            url: fileEntry.toURL(),
                                            blob: null
                                        });
                                    }, function (err) { return reject(err); });
                                }, function (err) { return reject(err); });
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    StorageFilesystemService.prototype.writeChunk = function (tItem, bytearray, offset, hasCleaned) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var time, key, opts;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        time = Date.now(), key = tItem.blobtype + "-" + tItem.cachekey, opts = this.options[key];
                        hasCleaned = !!hasCleaned;
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                _this.getFileSystem(opts.storetype, tItem.filesize, function (fs) {
                                    fs.root.getFile(opts.path, { create: true }, function (fileEntry) {
                                        fileEntry.createWriter(function (writer) {
                                            var truncated = false;
                                            var blob;
                                            writer.onwriteend = function () {
                                                if (!truncated) {
                                                    var truncsize = (tItem.blobtype != 'btFILE')
                                                        ? tItem[tItem.blobtype + 'size']
                                                        : opts.filesize;
                                                    _this.log.info(' - truncate - ' + truncsize);
                                                    truncated = true;
                                                    writer.truncate(truncsize);
                                                }
                                                _this.log.info("WRITE OP " + (Date.now() - time) + " ms write data");
                                                blob = null;
                                                resolve();
                                            };
                                            writer.onerror = function (e) {
                                                _this.log.info(writer.error.toString());
                                                _this.log.error("Write failed " + writer.error.name);
                                                if (!hasCleaned && writer.error.name == 'QuotaExceededError') {
                                                    _this.log.info('Clearing space');
                                                    return _this.clearSpace(opts.storetype)
                                                        .then(function () { return _this.writeChunk(tItem, bytearray, offset, hasCleaned); });
                                                }
                                                else {
                                                    reject(new models_1.ErrCode(3101));
                                                }
                                            };
                                            if (typeof bytearray == 'object' && bytearray instanceof Uint8Array) {
                                                blob = new Blob([bytearray], { type: 'application/octet-binary' });
                                            }
                                            else {
                                                blob = new Blob(bytearray, { type: 'application/octet-binary' });
                                            }
                                            if (tItem.blobtype != 'btFILE') {
                                                tItem[tItem.blobtype + 'size'] = blob.size;
                                            }
                                            writer.seek(offset);
                                            writer.write(blob);
                                        }, function (err) {
                                            _this.log.error('Error creating a filewriter');
                                            reject(new models_1.ErrCode(3112));
                                        });
                                    }, function (err) {
                                        _this.log.error('Error getting a file entry for ' + opts.path);
                                        _this.log.error(err.toString());
                                        reject(new models_1.ErrCode(3111));
                                    });
                                }, function (err) { return reject(err); });
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Recursively delete a file from the list.
     *
     * This will take an array of objects from the list and then shift off
     * items to delete them.  It will call itself again passing the deferred
     * object, which will only be resolved once completed.
     */
    StorageFilesystemService.prototype.deleteFiles = function (list) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve, reject) {
                            if (!list || !list.length) {
                                resolve();
                                return;
                            }
                            var entry = list.shift();
                            if (!entry) {
                                resolve();
                                return;
                            }
                            entry.getMetadata(function (meta) {
                                // account for file's just downloaded or in process of moving from
                                // FileSystem storage to the user's disk + 60sec
                                var deltime = meta.modificationTime.getTime() + +meta.size / 1024 + 60000;
                                if (deltime < Date.now()) {
                                    _this.log.warn(entry.fullPath + " attempt deletion");
                                    entry.remove(function () {
                                        _this.log.warn("Deleted " + entry.fullPath + ", size: " + meta.size);
                                        return _this.deleteFiles(list);
                                    }, function (err) { return reject(err); });
                                }
                                else {
                                    _this.log.warn("Skipping " + entry.fullPath + " because it's too new");
                                    return _this.deleteFiles(list);
                                }
                            }, function (err) { return reject(err); });
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    StorageFilesystemService.prototype.getSpace = function (size, hasCleaned) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var reqBytes, tempReqBytes, quotas;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        reqBytes = size, tempReqBytes = size * 6;
                        // first pass hasCleaned is false
                        hasCleaned = !!hasCleaned;
                        // debugging clearSpace() function
                        // return this.clearSpace().then(() => {
                        //     this.getUsage().then((quotas) => {
                        //         Logger.info('PERSISTENT storage exists');
                        //         Logger.info(' - Persistent used: ' + quotas.pUsed);
                        //         Logger.info(' - Persistent remain: ' + quotas.pRemain);
                        //         Logger.info(' - Persistent total: ' + quotas.pTotal);
                        //         Logger.info(' - Temporary used: ' + quotas.tUsed);
                        //         Logger.info(' - Temporary remain: ' + quotas.tRemain);
                        //         Logger.info(' - Temporary total: ' + quotas.tTotal);
                        //         Logger.info(' - ' + quotas.tTotal + ' > ' + tempReqBytes);
                        //     });
                        // });
                        this.log.info(' Requesting disk space storage');
                        this.log.info('   size: ' + size);
                        this.log.info('   temp storage needed: ' + tempReqBytes);
                        return [4 /*yield*/, this.getUsage()];
                    case 1:
                        quotas = _a.sent();
                        if (!(quotas.pTotal > 0)) return [3 /*break*/, 11];
                        this.log.info('PERSISTENT storage exists');
                        this.log.info(' - Persistent used: ' + quotas.pUsed);
                        this.log.info(' - Persistent remain: ' + quotas.pRemain);
                        this.log.info(' - Persistent total: ' + quotas.pTotal);
                        if (!(quotas.pRemain < reqBytes && !hasCleaned)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.clearSpace(window.PERSISTENT)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.getSpace(size, !hasCleaned)];
                    case 3: return [2 /*return*/, _a.sent()];
                    case 4:
                        if (!(quotas.pRemain > reqBytes)) return [3 /*break*/, 5];
                        this.log.info('Persistent storage should accomodate this');
                        return [2 /*return*/, window.PERSISTENT];
                    case 5:
                        if (!(quotas.tTotal > tempReqBytes && !hasCleaned)) return [3 /*break*/, 8];
                        this.log.info('Temporary space should accomodate, clearing space');
                        return [4 /*yield*/, this.clearSpace(window.TEMPORARY)];
                    case 6:
                        _a.sent();
                        return [4 /*yield*/, this.getSpace(size, !hasCleaned)];
                    case 7: return [2 /*return*/, _a.sent()];
                    case 8:
                        this.log.info('Asking for 2^40 bytes persistent');
                        return [4 /*yield*/, this.requestQuota(reqBytes)];
                    case 9: return [2 /*return*/, _a.sent()];
                    case 10: return [3 /*break*/, 17];
                    case 11:
                        this.log.info('PERSISTENT does not exist, check if temp can hold');
                        this.log.info(' - Temporary used: ' + quotas.tUsed);
                        this.log.info(' - Temporary remain: ' + quotas.tRemain);
                        this.log.info(' - Temporary total: ' + quotas.tTotal);
                        this.log.info(' - ' + quotas.tTotal + ' > ' + tempReqBytes);
                        if (!(quotas.tRemain > tempReqBytes)) return [3 /*break*/, 12];
                        this.log.info('Temporary storage should accomodate this');
                        return [2 /*return*/, window.TEMPORARY];
                    case 12:
                        if (!(quotas.tTotal > tempReqBytes && !hasCleaned)) return [3 /*break*/, 15];
                        return [4 /*yield*/, this.clearSpace(window.TEMPORARY)];
                    case 13:
                        _a.sent();
                        return [4 /*yield*/, this.getSpace(size, !hasCleaned)];
                    case 14: return [2 /*return*/, _a.sent()];
                    case 15:
                        this.log.info('Failed getting temporary space, asking for persistent');
                        return [4 /*yield*/, this.requestQuota(reqBytes)];
                    case 16: return [2 /*return*/, _a.sent()];
                    case 17: return [2 /*return*/];
                }
            });
        });
    };
    StorageFilesystemService.prototype.getUsage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve, reject) {
                            _this.queryUsageAndQuota(window.TEMPORARY, function (tUsed, tRemain) {
                                _this.queryUsageAndQuota(window.PERSISTENT, function (pUsed, pRemain) {
                                    resolve({
                                        pUsed: pUsed,
                                        pRemain: pRemain,
                                        pTotal: (pUsed + pRemain),
                                        tUsed: tUsed,
                                        tRemain: tRemain,
                                        tTotal: (tUsed + tRemain)
                                    });
                                }, function (err) { return reject(err); });
                            }, function (err) { return reject(err); });
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    StorageFilesystemService.prototype.getFS = function (type, initOpts) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve, reject) {
                            _this.log.d('attempting getFS for type = ' + type);
                            _this.log.d(initOpts);
                            _this.getFileSystem(type, initOpts.filesize, function (fs) {
                                _this.log.d('getFileSystem returned success');
                                fs.root.getDirectory(initOpts.dirname, { create: true }, function () {
                                    fs.root.getFile(initOpts.path, { create: true }, function (res) {
                                        resolve(res);
                                    }, function (err) {
                                        _this.log.e('reject getFS', err);
                                        reject(err);
                                    });
                                }, function (err) {
                                    _this.log.e('reject getDirectory', err);
                                    reject(err);
                                });
                            }, function (err) {
                                _this.log.e('Error getting filesystem', err);
                                reject(err);
                            });
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    StorageFilesystemService.prototype.getFileSystem = function (type, size, cb, errCb) {
        if (window.webkitRequestFileSystem) {
            window.webkitRequestFileSystem(type, size, cb, errCb);
        }
        else if (window.requestFileSystem) {
            window.requestFileSystem(type, size, cb, errCb);
        }
        else {
            this.log.error('requestFileSystem does not exist on window');
        }
    };
    StorageFilesystemService.prototype.queryUsageAndQuota = function (type, cb, errCb) {
        var str = (type == window.TEMPORARY) ? 'Temporary' : 'Persistent';
        var navKey = "webkit" + str + "Storage";
        if (navigator[navKey]) {
            return navigator[navKey].queryUsageAndQuota(cb, errCb);
        }
        else if (window.webkitStorageInfo) {
            return window.webkitStorageInfo.queryUsageAndQuota(type, cb, errCb);
        }
        else {
            this.log.error('queryUsageAndQuota: no methods found');
        }
    };
    StorageFilesystemService.prototype.requestQuota = function (reqBytes) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var size;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        size = 1099511627776;
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                var cb = function (grantedBytes) {
                                    if (grantedBytes === 0) {
                                        _this.log.error('We were granted 0 bytes');
                                        reject(new models_1.ErrCode(3100));
                                    }
                                    else if (reqBytes > grantedBytes) {
                                        _this.log.error('The requested byets are greater than the granted byets');
                                        _this.log.error("Asked for " + reqBytes + ", granted " + grantedBytes + " ");
                                        reject(new models_1.ErrCode(3100));
                                    }
                                    else {
                                        _this.log.info('PERSISTENT storage chosen');
                                        resolve(window.PERSISTENT);
                                    }
                                };
                                var errCb = function (err) {
                                    reject(err);
                                };
                                if (navigator['webkitPersistentStorage']) {
                                    navigator['webkitPersistentStorage'].requestQuota(size, cb, errCb);
                                }
                                else if (window.webkitStorageInfo) {
                                    window.webkitStorageInfo.requestQuota(window.PERSISTENT, size, cb, errCb);
                                }
                                else {
                                    _this.log.error('requestQuota: no methods found');
                                }
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    StorageFilesystemService.ngInjectableDef = i0.defineInjectable({ factory: function StorageFilesystemService_Factory() { return new StorageFilesystemService(i0.inject(i1.LoggerService)); }, token: StorageFilesystemService, providedIn: "root" });
    return StorageFilesystemService;
}());
exports.StorageFilesystemService = StorageFilesystemService;
