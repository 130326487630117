import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../../core/api.service';
import { LoggerService } from '../../core/logger.service';
import { UserService } from '../../core/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private apiService: ApiService,
    private loggerService: LoggerService,
    private userService: UserService,
    private router: Router
  ) { }
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    try {
      await this.apiService.execute<any>('sessionvalid', {});
      return true;
    } catch (err) {
        this.loggerService.error('Session is not valid');
        this.loggerService.error(err);
        const return_to = (window.location.pathname || '') + (window.location.search || '');
        this.router.navigate(['/login'], { queryParams: { return_to: return_to } });
        return false;
    }
  }
}
