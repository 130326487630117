"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PubkeyData = /** @class */ (function () {
    function PubkeyData() {
    }
    PubkeyData.prototype.deserialize = function (params) {
        Object.assign(this, params);
        return this;
    };
    return PubkeyData;
}());
exports.PubkeyData = PubkeyData;
