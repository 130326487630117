"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var base64_service_1 = require("../../core/base64.service");
var logger_service_1 = require("../../core/logger.service");
var user_service_1 = require("../../core/user.service");
var support_ticket_service_1 = require("../services/support-ticket.service");
var blend_service_1 = require("src/app/shared/services/blend.service");
var models_1 = require("src/app/shared/models");
var DialogContactSupportComponent = /** @class */ (function () {
    function DialogContactSupportComponent(userService, base64Service, loggerService, supportTicketService, activeModal, BlendService) {
        this.userService = userService;
        this.base64Service = base64Service;
        this.loggerService = loggerService;
        this.supportTicketService = supportTicketService;
        this.activeModal = activeModal;
        this.BlendService = BlendService;
    }
    DialogContactSupportComponent.prototype.ngOnInit = function () {
        this.frmValues = {
            requester: this.userService.get('email'),
            name: this.base64Service.decode(this.userService.get('display_name')),
            subject: null,
            description: null,
            attachLogs: true
        };
    };
    DialogContactSupportComponent.prototype.sendSupportTicket = function () {
        if (!this.contactForm.valid) {
            return;
        }
        var logs = this.frmValues.attachLogs
            ? this.loggerService.dumpLogsText('all')
            : 'Not provided\n';
        // This formatting is very important
        // support-submit.php splits the message
        // into sections delimited by '--------' for zendesk.
        // don't touch unless change support-submit
        var description = [
            'USER ID: ',
            this.userService.get('uid'),
            '--------',
            'Customer wrote:',
            this.frmValues.description,
            '--------',
            'Javascript logging:',
            logs,
            '--------',
        ].join('\n\n');
        var postData = {
            z_requester: this.frmValues.requester,
            z_name: this.frmValues.name,
            z_subject: this.frmValues.subject,
            z_description: description,
        };
        this.supportTicketService.sendSupportTicket(postData);
        this.BlendService.track(models_1.BlendEvent.CONTACT_SUPPORT, { 'logs': this.frmValues.attachLogs });
    };
    DialogContactSupportComponent.prototype.cancel = function () {
        this.activeModal.close();
        this.BlendService.track(models_1.BlendEvent.CANCEL_HELP);
    };
    DialogContactSupportComponent.prototype.syncKnowledgebase = function () {
        this.BlendService.track(models_1.BlendEvent.SYNC_KNOWLEDGEBASE);
    };
    return DialogContactSupportComponent;
}());
exports.DialogContactSupportComponent = DialogContactSupportComponent;
