"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var MultiUserListApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(MultiUserListApiOutput, _super);
    function MultiUserListApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return MultiUserListApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.MultiUserListApiOutput = MultiUserListApiOutput;
var MultiUserListApiInput = /** @class */ (function (_super) {
    tslib_1.__extends(MultiUserListApiInput, _super);
    function MultiUserListApiInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cmd = 'multiuserlist';
        return _this;
    }
    return MultiUserListApiInput;
}(base_api_model_1.BaseApiInput));
exports.MultiUserListApiInput = MultiUserListApiInput;
// export class MultiUserListApi {
//     cmd = 'multiuserlist';
//     constructor() {
//     }
// }
