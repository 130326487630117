"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var storejs = require("store");
var logger_service_1 = require("../logger.service");
var i0 = require("@angular/core");
var i1 = require("../logger.service");
var StorageBrowserService = /** @class */ (function () {
    function StorageBrowserService(log) {
        var _this = this;
        this.log = log;
        this._data = {};
        this._userId = 0;
        this._nameSpace = 'sync';
        this.store = storejs;
        if (this.store.enabled) {
            this.log.info('store is enabled');
            // console.log('This store is enabled');
            this.storageType = 'store';
            this._storage = this.store;
        }
        else {
            this.log.info('store is using memory, store test failed probably private browsing');
            this.storageType = 'memory';
            this._storage = {
                get: function (key) {
                    return _this._data[key];
                },
                set: function (key, value) {
                    _this._data[key] = value;
                },
                clear: function () { _this._data = {}; },
                getAll: function () { return this._data; }
            };
        }
    }
    StorageBrowserService.prototype.setMemoryStorage = function () {
        var _this = this;
        this.storageType = 'memory';
        this._storage = {
            get: function (key) {
                return _this._data[key];
            },
            set: function (key, value) {
                _this._data[key] = value;
            },
            clear: function () { _this._data = {}; },
            getAll: function () { return this._data; }
        };
    };
    StorageBrowserService.prototype.getStorageType = function () {
        return this.storageType;
    };
    /**
     * @ngdoc method
     * @name  setUserId
     * @methodOf sync.service:BrowserStorage
     * @description
     * Sets the user id for the browser storage
     * @param {Integer} userid The user's id
     */
    StorageBrowserService.prototype.setUserId = function (userid) {
        this._userId = userid;
    };
    /**
     * @ngdoc method
     * @name  set
     * @methodOf sync.service:BrowserStorage
     * @description
     * Sets a value in local storage
     * @param {String} key   The key name
     * @param {*} value      Mixed value, array, object, str, int, etc.
     */
    StorageBrowserService.prototype.set = function (key, value) {
        return this._storage.set(this.mkKey(key), value);
    };
    /**
     * @ngdoc method
     * @name  get
     * @methodOf sync.service:BrowserStorage
     * @description
     * Gets a value based on the key
     * @param  {String} key The key to retrieve
     * @return {*}     The value, array, obj, str, int, etc.
     */
    StorageBrowserService.prototype.get = function (key) {
        return this._storage.get(this.mkKey(key));
    };
    /**
     * @ngdoc method
     * @name  getAll
     * @methodOf sync.service:BrowserStorage
     * @description
     * Gets all key=>value stored in local storage
     * @return {[type]} [description]
     */
    StorageBrowserService.prototype.getAll = function () {
        return this._storage.getAll();
    };
    /**
     * @ngdoc method
     * @name  clear
     * @methodOf sync.service:BrowserStorage
     * @description
     * Clears the local storage.
     */
    StorageBrowserService.prototype.clear = function () {
        return this._storage.clear();
    };
    StorageBrowserService.prototype.mkKey = function (key) {
        return [this._nameSpace, this._userId, key].join('.');
    };
    StorageBrowserService.ngInjectableDef = i0.defineInjectable({ factory: function StorageBrowserService_Factory() { return new StorageBrowserService(i0.inject(i1.LoggerService)); }, token: StorageBrowserService, providedIn: "root" });
    return StorageBrowserService;
}());
exports.StorageBrowserService = StorageBrowserService;
