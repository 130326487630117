"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var user_service_1 = require("../../core/user.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var router_1 = require("@angular/router");
var WopiUpgradeComponent = /** @class */ (function () {
    function WopiUpgradeComponent(userService, activeModal, router, route) {
        this.userService = userService;
        this.activeModal = activeModal;
        this.router = router;
        this.route = route;
        this.spinner = false;
    }
    WopiUpgradeComponent.prototype.ngOnInit = function () {
        this.user = this.userService.getUser();
    };
    WopiUpgradeComponent.prototype.open = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.spinner = true;
                this.activeModal.close();
                this.router.navigate(['/file', this.id, 'view', 'officefse'], {
                    queryParams: {
                        view: this.route.snapshot.queryParams['view'],
                        page: this.route.snapshot.queryParams['page']
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    WopiUpgradeComponent.prototype.upgrade = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.spinner = true;
                this.activeModal.close();
                this.router.navigate(['/account/upgrade']);
                return [2 /*return*/];
            });
        });
    };
    return WopiUpgradeComponent;
}());
exports.WopiUpgradeComponent = WopiUpgradeComponent;
