"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var store_1 = require("@ngrx/store");
var LinkFileListActions = require("../../actions/link-file-list.actions");
var fromRoot = require("../../reducers");
var logger_service_1 = require("../../core/logger.service");
var sync_digest_service_1 = require("../../core/crypt/sync-digest.service");
var api_service_1 = require("../../core/api.service");
var models_1 = require("../../shared/models");
var url_service_1 = require("../../core/url.service");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var link_password_service_1 = require("../../links/services/link-password.service");
var file_item_service_1 = require("../../file/file-item.service");
var i0 = require("@angular/core");
var i1 = require("../../core/api.service");
var i2 = require("../../core/crypt/sync-crypt.service");
var i3 = require("../../core/crypt/sync-digest.service");
var i4 = require("../../links/services/link-password.service");
var i5 = require("../../core/logger.service");
var i6 = require("@ngrx/store");
var i7 = require("../../core/url.service");
var i8 = require("../../file/file-item.service");
var LinkFileListService = /** @class */ (function () {
    function LinkFileListService(api, crypt, digest, linkPassword, log, store, url, fileItemService) {
        this.api = api;
        this.crypt = crypt;
        this.digest = digest;
        this.linkPassword = linkPassword;
        this.log = log;
        this.store = store;
        this.url = url;
        this.fileItemService = fileItemService;
    }
    // ng1 support
    LinkFileListService.prototype.getSubscription = function () {
        return this.store.select(fromRoot.getLinkFileListState);
    };
    LinkFileListService.prototype.getCwdSubscription = function () {
        return this.store.select(fromRoot.getLinkFileListCwd);
    };
    LinkFileListService.prototype.dispatch = function (input) {
        this.store.dispatch(new LinkFileListActions.LoadWhiteLabelAction(input.publink_id));
        this.store.dispatch(new LinkFileListActions.LoadAction(input));
    };
    LinkFileListService.prototype.dispatchApp = function (input) {
        this.store.dispatch(new LinkFileListActions.LoadWhiteLabelAction(input.publink_id));
        this.store.dispatch(new LinkFileListActions.LoadAppAction(input));
    };
    LinkFileListService.prototype.reload = function () {
        return this.store.dispatch(new LinkFileListActions.RefreshAction());
    };
    LinkFileListService.prototype.success = function (payload) {
        return this.store.dispatch(new LinkFileListActions.SuccessAction(payload));
    };
    /**
     * Returns a processed link file list.
     */
    LinkFileListService.prototype.getData = function (key, linkId, locid, passlock) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, _a, _b, list, i, len, _c, _d, ex_1, errcode;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!key || !linkId) {
                            return [2 /*return*/];
                        }
                        this.PASSWORDLOCK = passlock;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 10, , 11]);
                        return [4 /*yield*/, this.api.send('linkpathlist', {
                                publink_id: linkId,
                                sync_id: locid,
                                passwordlock: passlock,
                            })];
                    case 2:
                        data = _e.sent();
                        // if (data.servers) {
                        //     this.url.setHosts(data.servers);
                        // }
                        if (!data.pathitems.length && !Object.keys(data.cwd).length) {
                            this.log.error('The link is empty');
                            throw new models_1.ErrCode(1700);
                        }
                        this.url.setHostMulti(data);
                        if (!!this.LINKKEY) return [3 /*break*/, 4];
                        _a = this;
                        return [4 /*yield*/, this.getKeyB64(data.linkversion, key, data.salt, data.iterations)];
                    case 3:
                        _a.LINKKEY = _e.sent();
                        _e.label = 4;
                    case 4:
                        _b = data;
                        return [4 /*yield*/, this.processItem(data.cwd, data, this.LINKKEY, linkId)];
                    case 5:
                        _b.cwd = _e.sent();
                        list = [];
                        i = 0, len = data.pathitems.length;
                        _e.label = 6;
                    case 6:
                        if (!(i < len)) return [3 /*break*/, 9];
                        data.pathitems[i].compat = data.compat == 1;
                        _d = (_c = list).push;
                        return [4 /*yield*/, this.processItem(data.pathitems[i], data, this.LINKKEY, linkId)];
                    case 7:
                        _d.apply(_c, [_e.sent()]);
                        _e.label = 8;
                    case 8:
                        i++;
                        return [3 /*break*/, 6];
                    case 9:
                        data.pathitems = list;
                        // Once the user has downgraded, don't allow the links to
                        // utilize the pro features.
                        if (!data.is_pro) {
                            data.upload = 0;
                        }
                        // done
                        return [2 /*return*/, data];
                    case 10:
                        ex_1 = _e.sent();
                        errcode = models_1.ErrCode.fromException(ex_1);
                        this.log.e('Error retrieving link list from cachekey: ' + linkId, errcode);
                        throw errcode;
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Returns a processed link file list. Pre-decrypted External App Link.
     */
    LinkFileListService.prototype.getDataExternal = function (linkId, locid, passlock) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, params, pwEnabled, _a, list, i, len, _b, _c, ex_2, errcode;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!linkId) {
                            return [2 /*return*/];
                        }
                        this.PASSWORDLOCK = passlock;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 8, , 9]);
                        this.log.info("getDataExt for link:" + linkId + ", locid:" + locid + ", passlock:" + passlock);
                        params = passlock ? { passlock: passlock } : {};
                        return [4 /*yield*/, (this.api.sendExt("link/" + linkId, 'GET', params))];
                    case 2:
                        data = _d.sent();
                        if (!data.pathitems.length && !Object.keys(data.cwd).length) {
                            this.log.error('The link is empty');
                            throw new models_1.ErrCode(1700);
                        }
                        pwEnabled = passlock ? true : false;
                        // this.url.setHostMulti(data);
                        // if (!this.LINKKEY) {
                        //     this.LINKKEY = await this.getKeyB64(data.linkversion, key, data.salt, data.iterations);
                        // }
                        // data.cwd = await this.processItem(data.cwd, data, this.LINKKEY, linkId);
                        _a = data;
                        return [4 /*yield*/, this.processItemExternal(data.cwd, data, linkId, pwEnabled)];
                    case 3:
                        // this.url.setHostMulti(data);
                        // if (!this.LINKKEY) {
                        //     this.LINKKEY = await this.getKeyB64(data.linkversion, key, data.salt, data.iterations);
                        // }
                        // data.cwd = await this.processItem(data.cwd, data, this.LINKKEY, linkId);
                        _a.cwd = _d.sent();
                        list = [];
                        i = 0, len = data.pathitems.length;
                        _d.label = 4;
                    case 4:
                        if (!(i < len)) return [3 /*break*/, 7];
                        data.pathitems[i].compat = data.compat == 1;
                        data.pathitems[i].previewtoken = data.previewtoken;
                        _c = (_b = list).push;
                        return [4 /*yield*/, this.processItemExternal(data.pathitems[i], data, linkId, pwEnabled)];
                    case 5:
                        _c.apply(_b, [_d.sent()]);
                        _d.label = 6;
                    case 6:
                        i++;
                        return [3 /*break*/, 4];
                    case 7:
                        data.pathitems = list;
                        // Once the user has downgraded, don't allow the links to
                        // utilize the pro features.
                        if (!data.is_pro) {
                            data.upload = 0;
                        }
                        // done
                        return [2 /*return*/, data];
                    case 8:
                        ex_2 = _d.sent();
                        this.log.error(ex_2);
                        errcode = models_1.ErrCode.fromException(ex_2);
                        this.log.e('Error retrieving data from external ' + linkId, errcode);
                        throw errcode;
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    LinkFileListService.prototype.isRoot = function () {
        return this.syncID == this.rootSyncID;
    };
    LinkFileListService.prototype.sanitizeKey = function (key) {
        // strip out any n
        var safekey = key.replace(/[^0-9a-zA-Z-]/gi, '');
        if (safekey !== key) {
            this.log.warn('Key was different, stripped out characters');
            key = safekey;
        }
        var encoded = encodeURIComponent(key);
        if (key !== encoded) {
            this.log.warn('Key url was encoded by another process, attempting to decode into ascii');
            key = encoded.replace(/%E2%80%90/g, '-');
        }
        return key;
    };
    LinkFileListService.prototype.setPasswordLock = function (password) {
        this.PASSWORDLOCK = this.digest.hash(password);
        // getData
    };
    LinkFileListService.prototype.getPassword = function () {
        return this.PASSWORDLOCK;
    };
    LinkFileListService.prototype.encName = function (filename) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.crypt.filenameEncrypt(filename, this.LINKKEY)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LinkFileListService.prototype.getValidFileName = function (filename) {
        // console.log(filename);
        var dt = new Date();
        var parts = filename.split('.');
        var ext = parts[parts.length - 1];
        var newFileName = [
            filename,
            '-',
            dt.getFullYear(),
            '-',
            dt.getMonth() + 1,
            '-',
            dt.getDate(),
            ' ',
            dt.getHours(),
            '.',
            dt.getMinutes(),
            '.',
            dt.getSeconds(),
        ];
        if (ext != filename) {
            newFileName.push('.', ext);
        }
        return newFileName.join('');
    };
    /**
     * Gets the plain text password as a valid share key in base64
     */
    LinkFileListService.prototype.getKeyB64 = function (version, key, salt, iterations) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var skey;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(version == 2)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.linkPassword.convertToShareKey(key, salt, iterations)];
                    case 1:
                        skey = _a.sent();
                        return [2 /*return*/, this.crypt.bytesToB64(skey)];
                    case 2: return [2 /*return*/, key];
                }
            });
        });
    };
    LinkFileListService.prototype.processItem = function (item, settings, linkkey, linkId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!item) return [3 /*break*/, 1];
                        this.log.warn('Cannot find pathitem');
                        return [3 /*break*/, 5];
                    case 1:
                        if (!!item.enc_share_name) return [3 /*break*/, 2];
                        item.name = item.sync_id + " failed decryption 1";
                        this.log.error("syncid: " + item.sync_id + " failed decryption,\n                missing enc_share_name on\n                cachekey: " + linkId + "\n                shareId: " + item.share_id);
                        return [3 /*break*/, 5];
                    case 2:
                        if (!!linkkey) return [3 /*break*/, 3];
                        item.name = item.sync_id + " failed decryption 2";
                        this.log.error("syncid: " + item.sync_id + " failed decryption,\n                missing linkkey on\n                cachekey: " + linkId + "\n                shareId: " + item.share_id);
                        return [3 /*break*/, 5];
                    case 3:
                        _a = item;
                        return [4 /*yield*/, this.crypt.filenameDecrypt(item.enc_share_name, linkkey)];
                    case 4:
                        _a.name = _b.sent();
                        _b.label = 5;
                    case 5:
                        if (item.name == item.enc_share_name) {
                            throw new models_1.ErrCode(2110);
                        }
                        if (item.name == '') {
                            item.name = "Error " + item.sync_id + " - file has a blank name";
                        }
                        return [4 /*yield*/, this.fileItemService.format(item)];
                    case 6:
                        item = _b.sent();
                        item.context = 'link';
                        item.compat = settings.compat == 1;
                        item.share_key = linkkey;
                        item.linkID = linkId;
                        item.link_owner_id = settings.oid;
                        item.link_is_business = settings.is_business > 0;
                        item.link_is_pro = settings.is_pro > 0;
                        item.is_publink = 1;
                        item.linkpasswordlock = this.PASSWORDLOCK;
                        item.previewonly = settings.previewonly;
                        if (item.size > 0 && item.has_thumb1 == 1) {
                            item.thumbData = {
                                processed: false,
                                thumbType: 1,
                                cachekey: item.cachekey,
                                blobtype: 'btTHUMB1',
                            };
                        }
                        else if (item.kind == 'image' &&
                            item.size > 0 &&
                            item.size < 1024 * 100 &&
                            !item.has_thumb1 &&
                            !item.has_thumb2) {
                            // Logger.info('No thumbnail, use original');
                            item.thumbData = {
                                processed: false,
                                thumbType: 1,
                                cachekey: item.cachekey,
                                blobtype: 'btFILE',
                            };
                        }
                        return [2 /*return*/, item];
                }
            });
        });
    };
    LinkFileListService.prototype.processItemExternal = function (item, settings, linkId, pwProtected) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var replaceDomain;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // item.name = item.share_name;
                        // if (item.name == item.enc_share_name) {
                        //     throw new ErrCode(2110);
                        // }
                        if (pwProtected && window.location.host.indexOf('sync.us') > -1) {
                            replaceDomain = '';
                            item.compaturl = settings.url.replace(/https?\:\/\/[a-zA-Z0-9\-]*\.(sync|Sync|SYNC)\.(com|us|COM|US)/g, replaceDomain);
                            item.compaturl_dl = settings.url_dl.replace(/https?\:\/\/[a-zA-Z0-9\-]*\.(sync|Sync|SYNC)\.(com|us|COM|US)/g, replaceDomain);
                            this.log.info('Removed domain from compaturl so that cookie is passed along - New URL: ' +
                                item.compaturl +
                                ' Old URL: ' +
                                settings.url);
                        }
                        else {
                            item.compaturl = settings.url;
                            item.compaturl_dl = settings.url_dl;
                        }
                        return [4 /*yield*/, this.fileItemService.format(item)];
                    case 1:
                        item = _a.sent();
                        if (!item) {
                            this.log.info('Cannot find pathitem');
                        }
                        item.context = 'applink';
                        item.compat = settings.compat == 1;
                        item.linkID = linkId;
                        item.link_owner_id = settings.oid;
                        item.link_is_business = settings.is_business > 0;
                        item.link_is_pro = settings.is_pro > 0;
                        item.is_publink = 1;
                        item.linkpasswordlock = this.PASSWORDLOCK;
                        item.previewonly = settings.previewonly;
                        if (item.size > 0 && item.has_thumb1 == 1) {
                            item.thumbData = {
                                processed: false,
                                thumbType: 1,
                                cachekey: item.cachekey,
                                blobtype: 'btTHUMB1',
                            };
                        }
                        else if (item.kind == 'image' &&
                            item.size > 0 &&
                            item.size < 1024 * 100 &&
                            !item.has_thumb1 &&
                            !item.has_thumb2) {
                            // Logger.info('No thumbnail, use original');
                            item.thumbData = {
                                processed: false,
                                thumbType: 1,
                                cachekey: item.cachekey,
                                blobtype: 'btFILE',
                            };
                        }
                        return [2 /*return*/, item];
                }
            });
        });
    };
    LinkFileListService.prototype.getWhiteLabel = function (pubLinkID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_3, errcode;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.send('getwhitelabel', {
                                publink_id: pubLinkID,
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        ex_3 = _a.sent();
                        errcode = models_1.ErrCode.fromException(ex_3);
                        this.log.e('Error retrieving whitelabel from ' + pubLinkID, errcode);
                        throw errcode;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LinkFileListService.prototype.decorateQueryParams = function (queryParams) {
        if (queryParams['sync_id']) {
            return { queryParams: { sync_id: queryParams['sync_id'] } };
        }
    };
    LinkFileListService.prototype.decorateFragment = function (isLinkCompat, fragment) {
        if (!isLinkCompat || fragment) {
            return { fragment: fragment };
        }
    };
    LinkFileListService.ngInjectableDef = i0.defineInjectable({ factory: function LinkFileListService_Factory() { return new LinkFileListService(i0.inject(i1.ApiService), i0.inject(i2.SyncCryptService), i0.inject(i3.SyncDigestService), i0.inject(i4.LinkPasswordService), i0.inject(i5.LoggerService), i0.inject(i6.Store), i0.inject(i7.UrlService), i0.inject(i8.FileItemService)); }, token: LinkFileListService, providedIn: "root" });
    return LinkFileListService;
}());
exports.LinkFileListService = LinkFileListService;
