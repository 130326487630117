"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var apps_service_1 = require("../services/apps.service");
var models_1 = require("../../shared/models");
var core_1 = require("@angular/core");
var logger_service_1 = require("../../core/logger.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var router_1 = require("@angular/router");
var DialogAppInfoComponent = /** @class */ (function () {
    function DialogAppInfoComponent(activeModal, Logger, AppsService, router) {
        this.activeModal = activeModal;
        this.Logger = Logger;
        this.AppsService = AppsService;
        this.router = router;
        this.spinner = false;
    }
    DialogAppInfoComponent.prototype.ngOnInit = function () {
        // These are default permissions.
        this.permissions = {
            apACCOUNT: 1,
            apREAD: 1,
            apWRITE: 1,
            apSHARE: 0,
            apLINK: 1,
        };
    };
    DialogAppInfoComponent.prototype.revoke = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        this.Logger.info("Disabling app " + this.app.app_id);
                        return [4 /*yield*/, this.AppsService.disableApp(this.app.app_id)
                                .then(function () {
                                _this.spinner = false;
                                _this.router.navigate(['/account/apps']);
                                _this.activeModal.close();
                            })
                                .catch(function (ex) {
                                _this.spinner = false;
                                _this.Logger.error('An error occurred revoking apps');
                                _this.Logger.error(ex);
                                _this.errcode = models_1.ErrCode.fromException(ex);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogAppInfoComponent.prototype.closeModal = function () {
        this.activeModal.close();
    };
    return DialogAppInfoComponent;
}());
exports.DialogAppInfoComponent = DialogAppInfoComponent;
