"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dirty_out_service_1 = require("../../core/crypt/dirty-out.service");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var file_list_service_1 = require("../../file-list/services/file-list.service");
var feedback_service_1 = require("../../notifications/feedback.service");
var dir_picker_service_1 = require("../../file-list/dir-picker.service");
var batch_copy_service_1 = require("../batch-copy.service");
var notifications_service_1 = require("../../core/notifications.service");
var batch_move_service_1 = require("../batch-move.service");
var models_1 = require("../../shared/models");
var blend_service_1 = require("src/app/shared/services/blend.service");
var DialogCopyComponent = /** @class */ (function () {
    function DialogCopyComponent(feedbackService, activeModal, dirPickerService, batchCopyService, notificationsService, batchMoveService, SyncCrypt, DirtyOut, FileList, blendService) {
        this.feedbackService = feedbackService;
        this.activeModal = activeModal;
        this.dirPickerService = dirPickerService;
        this.batchCopyService = batchCopyService;
        this.notificationsService = notificationsService;
        this.batchMoveService = batchMoveService;
        this.SyncCrypt = SyncCrypt;
        this.DirtyOut = DirtyOut;
        this.FileList = FileList;
        this.blendService = blendService;
        this.selected = [];
        this.shouldDuplicate = false;
        this.skipShareFolders = 0;
    }
    DialogCopyComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var i, len;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.BatchType = this.action == 'copy' ? this.batchCopyService : this.batchMoveService;
                        this.skipShareFolders = 0;
                        this.batchView = this.BatchType.view;
                        this.itemlist = this.selected;
                        this.dirPickerService.view.skipSyncIds = [];
                        for (i = 0, len = this.itemlist.length; i < len; i++) {
                            this.dirPickerService.view.skipSyncIds.push(this.itemlist[i].sync_id);
                            if (this.itemlist[i].is_share_root && this.action !== 'copy') {
                                this.skipShareFolders = 1;
                            }
                        }
                        this.BatchType.init();
                        this.dirPickerService.init(this.dirPickerService.INIT_ALL);
                        if (!(this.shouldDuplicate && this.parentDir)) return [3 /*break*/, 2];
                        this.dirPickerSpinner = true;
                        return [4 /*yield*/, this.dirPickerService.cd(this.parentDir)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.dirPickerSpinner = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogCopyComponent.prototype.isCompleted = function (syncId) {
        return this.BatchType.isCompleted(syncId);
    };
    DialogCopyComponent.prototype.exists = function (key, value) {
        return this.dirPickerService.exists(key, value);
    };
    DialogCopyComponent.prototype.getDestination = function () {
        return this.dirPickerService.getDestination();
    };
    DialogCopyComponent.prototype.removeFromList = function (listitem) {
        for (var i = this.itemlist.length - 1; i >= 0; i--) {
            if (this.itemlist[i].sync_id == listitem.sync_id) {
                this.itemlist.splice(i, 1);
                break;
            }
        }
    };
    DialogCopyComponent.prototype.getActionName = function () {
        if (this.shouldDuplicate && this.dirPickerService.view.selected && this.dirPickerService.view.selected.sync_id === this.itemlist[0].pid && this.action == 'copy') {
            return 'duplicate';
        }
        else {
            return this.action;
        }
    };
    DialogCopyComponent.prototype.disableExecute = function () {
        return (this.batchView.spinner > 0 ||
            !this.dirPickerService.view.selected ||
            !this.dirPickerService.view.selected.sync_id ||
            (this.itemlist.length > 0 && this.dirPickerService.view.selected.sync_id === this.itemlist[0].pid && !this.shouldDuplicate));
    };
    DialogCopyComponent.prototype.showRow = function (item) {
        for (var i = this.itemlist.length - 1; i >= 0; i--) {
            if (item.search_name == this.itemlist[i].name.toLowerCase()) {
                return false;
            }
        }
        return true;
    };
    DialogCopyComponent.prototype.sortList = function (a, b) {
        if (a.type < b.type) {
            return -1;
        }
        else if (a.type > b.type) {
            return 1;
        }
        else {
            if (a.search_name < b.search_name) {
                return -1;
            }
            else if (a.search_name > b.search_name) {
                return 1;
            }
        }
    };
    DialogCopyComponent.prototype.getDuplicateFileName = function (filename) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, filesInCurrentFolder, dotIndex, newFileName;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.FileList.getPathList()];
                    case 1:
                        data = _a.sent();
                        return [4 /*yield*/, Promise.all(data.map(function (file) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.SyncCrypt.filenameDecrypt(file.enc_name)];
                                        case 1: return [2 /*return*/, _a.sent()];
                                    }
                                });
                            }); }))];
                    case 2:
                        filesInCurrentFolder = _a.sent();
                        dotIndex = filename.lastIndexOf('.');
                        newFileName = filename;
                        if (dotIndex == -1) {
                            newFileName = filename + ' copy';
                            while (filesInCurrentFolder.includes(newFileName)) {
                                newFileName += ' copy';
                            }
                            return [2 /*return*/, newFileName];
                        }
                        else {
                            newFileName = filename.substring(0, dotIndex);
                            while (filesInCurrentFolder.includes(newFileName + filename.substring(dotIndex))) {
                                newFileName += ' copy';
                            }
                            return [2 /*return*/, newFileName + filename.substring(dotIndex)];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogCopyComponent.prototype.execute = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sync_id_array, list, duplicateNameMap, files, directories, sharedata, i, duplicateName, share_names, new_name, destination, actionMsg, successMsg, error_1, destination, errorMsg;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sync_id_array = [], list = this.itemlist
                            ? this.itemlist.sort(function (a, b) { return _this.sortList(a, b); })
                            : [], duplicateNameMap = {};
                        files = 0, directories = 0;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 12, 13, 14]);
                        i = list.length - 1;
                        _a.label = 2;
                    case 2:
                        if (!(i >= 0)) return [3 /*break*/, 9];
                        if (list[i].pid == this.dirPickerService.view.selected.sync_id && this.action != 'copy') {
                            this.BatchType.view.errors.push({ code: 3610 }); //'Cannot ' + ctrl.action + ' to the same location');
                            return [2 /*return*/];
                        }
                        sync_id_array.push(list[i].sync_id);
                        if (list[i].type && list[i].type.toUpperCase() === 'DIR') {
                            directories++;
                        }
                        else {
                            files++;
                        }
                        if (!(this.shouldDuplicate && this.dirPickerService.view.selected && this.dirPickerService.view.selected.sync_id === list[i].pid && this.action == 'copy')) return [3 /*break*/, 8];
                        this.batchView.spinner = 1;
                        return [4 /*yield*/, this.getDuplicateFileName(list[i].name)];
                    case 3:
                        duplicateName = _a.sent();
                        if (!!sharedata) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.DirtyOut.getShareKeyDataForSyncId(list[i].pid)];
                    case 4:
                        sharedata = _a.sent();
                        _a.label = 5;
                    case 5: return [4 /*yield*/, this.DirtyOut.getKeyArrayForSyncId(sharedata, list[i].pid, duplicateName)];
                    case 6:
                        share_names = _a.sent();
                        return [4 /*yield*/, this.SyncCrypt.filenameEncrypt(duplicateName)];
                    case 7:
                        new_name = _a.sent();
                        duplicateNameMap[list[i].sync_id] = {
                            share_names: share_names,
                            new_name: new_name
                        };
                        this.batchView.spinner = 0;
                        _a.label = 8;
                    case 8:
                        i--;
                        return [3 /*break*/, 2];
                    case 9: return [4 /*yield*/, this.notificationsService.stopNotificationLoop()];
                    case 10:
                        _a.sent();
                        return [4 /*yield*/, this.BatchType.execute(sync_id_array, this.dirPickerService.view.selected.sync_id, this.action == 'move', duplicateNameMap)];
                    case 11:
                        _a.sent();
                        destination = this.getDestination();
                        actionMsg = this.action === 'move' ? 'moved' : (this.getActionName() == 'duplicate' ? 'duplicated' : 'copied');
                        successMsg = list.length + " file" + (files > 1 ? 's' : '') + " " + actionMsg + " to " + destination;
                        if (directories && files) {
                            successMsg = list.length + " items " + actionMsg + " to " + destination;
                        }
                        else if (directories) {
                            successMsg = list.length + " folder" + (directories > 1 ? 's' : '') + " " + actionMsg + " to " + destination;
                        }
                        this.feedbackService.setFeedback('success', successMsg, 10000);
                        this.blendService.track(models_1.BlendEvent.MOVE_ITEMS, { num_items: list.length });
                        return [3 /*break*/, 14];
                    case 12:
                        error_1 = _a.sent();
                        destination = this.getDestination();
                        errorMsg = list.length + " file" + (files > 1 ? 's' : '') + " failed to " + this.action + " to " + destination;
                        if (directories && files) {
                            errorMsg = list.length + " items failed to " + this.action + " to " + destination;
                        }
                        else if (directories) {
                            errorMsg = list.length + " folder" + (directories > 1 ? 's' : '') + " failed to " + this.action + " to " + destination;
                        }
                        this.feedbackService.setFeedback('danger', errorMsg, 10000);
                        return [3 /*break*/, 14];
                    case 13:
                        this.notificationsService.startNotificationLoop();
                        this.activeModal.close();
                        return [7 /*endfinally*/];
                    case 14: return [2 /*return*/];
                }
            });
        });
    };
    DialogCopyComponent.prototype.closeDialog = function () {
        if (this.BatchType.view.spinner !== 0) {
            var c = window.confirm('Are you sure you want to cancel?');
            if (c) {
                this.BatchType.cancel();
                this.activeModal.close();
            }
        }
        else {
            this.activeModal.close();
        }
    };
    return DialogCopyComponent;
}());
exports.DialogCopyComponent = DialogCopyComponent;
