"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MultiAdminUserData = /** @class */ (function () {
    function MultiAdminUserData() {
    }
    MultiAdminUserData.prototype.deserialize = function (params) {
        for (var _i = 0, _a = Object.entries(params); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], val = _b[1];
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    };
    return MultiAdminUserData;
}());
exports.MultiAdminUserData = MultiAdminUserData;
