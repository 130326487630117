"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var url_service_1 = require("../../core/url.service");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var logger_service_1 = require("../../core/logger.service");
var api_service_1 = require("../../core/api.service");
var http_1 = require("@angular/common/http");
var i0 = require("@angular/core");
var i1 = require("../../core/api.service");
var i2 = require("../../core/crypt/sync-crypt.service");
var i3 = require("../../core/logger.service");
var i4 = require("../../core/url.service");
var DownloadLegacyService = /** @class */ (function () {
    function DownloadLegacyService(api, crypt, log, url) {
        this.api = api;
        this.crypt = crypt;
        this.log = log;
        this.url = url;
    }
    DownloadLegacyService.prototype.abort = function () { };
    DownloadLegacyService.prototype.getChunk = function (tItem, offset, reqLength, retry) {
        var _this = this;
        if (retry === void 0) { retry = 0; }
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.api.fetchFile(tItem, offset, reqLength, retry)
                    .subscribe(function (resp) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var bytesSent, gcmTags, percent, respBuffer, encStart, data, processData, result, res;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(resp.type === http_1.HttpEventType.DownloadProgress)) return [3 /*break*/, 1];
                                bytesSent = resp.loaded + offset;
                                gcmTags = Math.ceil(bytesSent / 131072) * 36;
                                tItem.bytes_sent = bytesSent - gcmTags;
                                percent = Math.round(tItem.bytes_sent / tItem.filesize * 100 * 100) / 100;
                                if (percent > 100) {
                                    percent = 100;
                                }
                                tItem.progress_percent = percent;
                                return [3 /*break*/, 3];
                            case 1:
                                if (!(resp.type === http_1.HttpEventType.Response)) return [3 /*break*/, 3];
                                respBuffer = resp.body, encStart = Date.now(), data = [], processData = {
                                    buffer: respBuffer,
                                    byteLen: respBuffer.byteLength,
                                    bytearray: [],
                                    datakey: tItem.data_key,
                                    packetAmt: Math.ceil(respBuffer.byteLength / this.crypt.GCM_PACKET_SIZE),
                                    sliceStart: 0,
                                    sliceEnd: (respBuffer.byteLength > this.crypt.GCM_PACKET_SIZE) ?
                                        this.crypt.GCM_PACKET_SIZE :
                                        respBuffer.byteLength,
                                    cryptOffset: offset,
                                    iterations: 0
                                };
                                return [4 /*yield*/, this.processPackets(processData)];
                            case 2:
                                result = _a.sent();
                                res = {
                                    tItem: tItem,
                                    bytearray: result.bytearray,
                                    offset: offset,
                                    reqlength: reqLength,
                                    chunkLength: result.byteLen
                                };
                                resolve(res);
                                _a.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                }); }, function (err) {
                    // reject(err);
                    _this.log.e('Error subscribing to fetch file in download-worker', err);
                    if (_this.url.downloadhosts[retry + 1]) {
                        _this.log.warn("Retrying download on host " + _this.url.downloadhosts[retry + 1]);
                        resolve(_this.getChunk(tItem, offset, reqLength, retry + 1));
                    }
                    else {
                        _this.log.error("Attempted " + retry + " retries and failed, rejecting file");
                        reject(err);
                    }
                });
                return [2 /*return*/];
            });
        }); });
        // }).then((resp) => {
        //     let dataDefer: ng.IDeferred<ChunkProcessData> = this.$q.defer(),
        //         respBuffer: ArrayBuffer = <ArrayBuffer>resp.data,
        //         encStart = Date.now(),
        //         data = [],
        //         processData: ChunkProcessData = {
        //             buffer: respBuffer,
        //             byteLen: respBuffer.byteLength,
        //             bytearray: [],
        //             datakey: tItem.data_key,
        //             packetAmt: Math.ceil(respBuffer.byteLength/ this.SyncCrypt.GCM_PACKET_SIZE),
        //             sliceStart: 0,
        //             sliceEnd: (respBuffer.byteLength > this.SyncCrypt.GCM_PACKET_SIZE) ?
        //                         this.SyncCrypt.GCM_PACKET_SIZE :
        //                         respBuffer.byteLength,
        //             cryptOffset: offset,
        //             iterations: 0
        //         }
        //     this.processPackets(dataDefer, processData);
        //     return dataDefer.promise;
        // }).then((result) => {
        //     let res: sync.IStoreData = {
        //         tItem: tItem,
        //         bytearray: result.bytearray,
        //         offset: offset,
        //         reqlength: reqLength,
        //         chunkLength: result.byteLen
        //     }
        //     dfd.resolve(res)
        // })
        // .catch((err) => {
        //     this.Logger.error('Error occurred downloading the file');
        //     this.Logger.error(err);
        //     if (err.status == -1) {
        //         dfd.reject({code: 7101 });
        //     }
        //     else if (err.status == 404) {
        //         dfd.reject({code: 7102 });
        //     }
        //     else {
        //         dfd.reject({code: 7100});
        //     }
        // })
        // return dfd.promise;
    };
    DownloadLegacyService.prototype.completed = function () {
        // this.dfd = null;
    };
    DownloadLegacyService.prototype.processPackets = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var crypted, chunkStart, decryptedBuffer;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(data.iterations < data.packetAmt)) return [3 /*break*/, 2];
                        data.iterations++;
                        crypted = data.buffer.slice(data.sliceStart, data.sliceEnd), chunkStart = Date.now();
                        return [4 /*yield*/, this.crypt.filedataDecrypt(new Uint8Array(crypted), data.datakey, data.cryptOffset)];
                    case 1:
                        decryptedBuffer = _a.sent();
                        data.bytearray = data.bytearray.concat(decryptedBuffer);
                        data.sliceStart += this.crypt.GCM_PACKET_SIZE;
                        data.sliceEnd = (data.byteLen - data.sliceStart > this.crypt.GCM_PACKET_SIZE)
                            ? data.sliceEnd + this.crypt.GCM_PACKET_SIZE
                            : data.sliceEnd + data.byteLen - data.sliceStart;
                        data.cryptOffset += this.crypt.GCM_PACKET_SIZE;
                        return [3 /*break*/, 0];
                    case 2: return [2 /*return*/, data];
                }
            });
        });
    };
    DownloadLegacyService.ngInjectableDef = i0.defineInjectable({ factory: function DownloadLegacyService_Factory() { return new DownloadLegacyService(i0.inject(i1.ApiService), i0.inject(i2.SyncCryptService), i0.inject(i3.LoggerService), i0.inject(i4.UrlService)); }, token: DownloadLegacyService, providedIn: "root" });
    return DownloadLegacyService;
}());
exports.DownloadLegacyService = DownloadLegacyService;
