"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var NoSignupLayoutComponent = /** @class */ (function () {
    function NoSignupLayoutComponent() {
    }
    NoSignupLayoutComponent.prototype.ngOnInit = function () {
    };
    return NoSignupLayoutComponent;
}());
exports.NoSignupLayoutComponent = NoSignupLayoutComponent;
