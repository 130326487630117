"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var OrderSessionNewApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(OrderSessionNewApiOutput, _super);
    function OrderSessionNewApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return OrderSessionNewApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.OrderSessionNewApiOutput = OrderSessionNewApiOutput;
var OrderSessionNewApiInput = /** @class */ (function (_super) {
    tslib_1.__extends(OrderSessionNewApiInput, _super);
    function OrderSessionNewApiInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return OrderSessionNewApiInput;
}(base_api_model_1.BaseApiInput));
exports.OrderSessionNewApiInput = OrderSessionNewApiInput;
