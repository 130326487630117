"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MultiSelectDropdownComponent = /** @class */ (function () {
    function MultiSelectDropdownComponent() {
        /**
        * @property {boolean} showSearch
        * Flag to show search box inside items dropdown or not.
        * Default value is true
        */
        this.showSearch = true;
        /**
        * @property {boolean} showAll
        * Flag to show Select All option or not.
        * Default value is true
        */
        this.showAll = true;
        /**
        * @property {boolean} showStatus
        * Flag to show Selected/unselected count at the bottom of the list.
        * Default value is false
        */
        this.showStatus = false;
        /**
        * @property {boolean} showError
        * Flag to set error state to the dropdown
        * Default value is false
        */
        this.showError = false;
        /**
        * @property {function} itemChange
        * Callback function on itemChange.
        * Callback is called with all selected Items
        */
        this.itemChange = new core_1.EventEmitter(null);
        this.multiSelectItems = [];
        this.filtered = [];
        this.all = {
            id: this.generateID(),
            name: 'All',
            checked: false,
            visible: true
        };
        this.searchText = '';
    }
    Object.defineProperty(MultiSelectDropdownComponent.prototype, "items", {
        /**
        * @property {array} items
        * Array of items to display on the dropdown.
        */
        set: function (items) {
            var _this = this;
            this.multiSelectItems = items || [];
            this.multiSelectItems.map(function (item) {
                item.id = item.id || _this.generateID();
                item.checked = item.checked || false;
                item.visible = item.visible || true;
                item.disabled = item.disabled || false;
            });
            this.filtered = Object.assign(this.multiSelectItems);
            if (!this.filtered.length) {
                this.all.visible = false;
            }
            else {
                this.all.visible = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiSelectDropdownComponent.prototype, "search", {
        get: function () {
            return this.searchText;
        },
        set: function (searchText) {
            this.searchText = searchText;
            var search = this.searchText.toLowerCase();
            if (!search) {
                this.filtered = Object.assign(this.multiSelectItems);
                this.all.visible = true;
                return;
            }
            this.filtered = this.multiSelectItems.filter(function (i) { return i.name.toLowerCase().indexOf(search) !== -1; });
            if (this.all.name.toLowerCase().indexOf(search) !== -1) {
                this.all.visible = true;
            }
            else {
                this.all.visible = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiSelectDropdownComponent.prototype, "selected", {
        get: function () {
            if (this.selectedText) {
                return this.selectedText;
            }
            return this.all && this.all.checked ? this.all.name :
                this.multiSelectItems.filter(function (i) { return i.checked && i.visible; }).map(function (i) { return i.name; }).join(', ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiSelectDropdownComponent.prototype, "isEmpty", {
        get: function () {
            return this.filtered.filter(function (i) { return i.visible; }).length === 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiSelectDropdownComponent.prototype, "checked", {
        get: function () {
            return this.multiSelectItems.filter(function (i) { return i.checked; }).length;
        },
        enumerable: true,
        configurable: true
    });
    MultiSelectDropdownComponent.prototype.trackByID = function (index, item) {
        return item.id;
    };
    MultiSelectDropdownComponent.prototype.onChange = function ($event, item) {
        var checked = $event.target.checked;
        var index = this.multiSelectItems.findIndex(function (i) { return i.id === item.id; });
        if (item.name === 'All') {
            this.all.checked = checked;
            for (var _i = 0, _a = this.multiSelectItems; _i < _a.length; _i++) {
                var iterator = _a[_i];
                iterator.checked = checked;
            }
        }
        else {
            this.multiSelectItems[index].checked = checked;
            if (this.all) {
                if (this.all.checked) {
                    this.all.checked = false;
                }
                var allChecked = this.multiSelectItems.every(function (i) { return i.checked; });
                this.all.checked = allChecked;
            }
        }
        this.itemChange.emit(this.multiSelectItems.filter(function (i) { return i.checked && i.visible; }));
    };
    MultiSelectDropdownComponent.prototype.generateID = function () {
        return Math.floor(Math.random() * 10000000);
    };
    return MultiSelectDropdownComponent;
}());
exports.MultiSelectDropdownComponent = MultiSelectDropdownComponent;
