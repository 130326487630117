"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("../../core/api.service");
var base64_service_1 = require("../../core/base64.service");
var logger_service_1 = require("../../core/logger.service");
var user_service_1 = require("../../core/user.service");
var share_new_service_1 = require("../../shares/share-new.service");
var share_list_service_1 = require("../../shares/share-list.service");
var share_service_1 = require("../../shares/share.service");
var i0 = require("@angular/core");
var i1 = require("../../core/logger.service");
var i2 = require("../../core/user.service");
var i3 = require("../../core/base64.service");
var i4 = require("../../core/api.service");
var i5 = require("../../shares/share.service");
var i6 = require("../../shares/share-new.service");
var i7 = require("../../shares/share-list.service");
var AppsService = /** @class */ (function () {
    function AppsService(Logger, User, base64, api, shareService, shareNewService, shareListService) {
        this.Logger = Logger;
        this.User = User;
        this.base64 = base64;
        this.api = api;
        this.shareService = shareService;
        this.shareNewService = shareNewService;
        this.shareListService = shareListService;
    }
    AppsService.prototype.OauthValidate = function (nonce, redirect_url) {
        return this.api.execute('appsoauthvalidate', {
            nonce: nonce,
            url: redirect_url,
        });
    };
    AppsService.prototype.OauthComplete = function (nonce, email) {
        return this.api.execute('appsoauthcomplete', {
            nonce: nonce,
            email: email,
        });
    };
    AppsService.prototype.getApps = function (share_id) {
        if (!share_id) {
            return this.api.execute('appsgetapps', {});
        }
        else {
            return this.api.execute('appsgetapps', { share_id: share_id });
        }
    };
    AppsService.prototype.setApps = function (share_id, set_status, app_ids) {
        return this.api.execute('appssetapps', {
            share_id: share_id,
            set_status: set_status,
            app_ids: app_ids
        });
    };
    AppsService.prototype.getDirs = function (app_id) {
        return this.api.execute('appsgetdirs', { app_id: app_id });
    };
    AppsService.prototype.revokeApps = function (app_ids) {
        return this.api.execute('appsrevoke', { app_ids: app_ids });
    };
    AppsService.prototype.readApps = function (shareId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resp, _i, _a, app;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.getApps(shareId)];
                    case 1:
                        resp = _b.sent();
                        if (resp.success == 0) {
                            this.Logger.error('Could not read apps from API');
                            throw ({ code: 11200 });
                        }
                        else {
                            if (resp.apps.length == 0) {
                            }
                            else {
                                for (_i = 0, _a = resp.apps; _i < _a.length; _i++) {
                                    app = _a[_i];
                                    app.selected = false;
                                }
                                return [2 /*return*/, resp.apps];
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AppsService.prototype.writeApps = function (shareId, apps, status) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var appIds, resp, _i, _a, app;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (shareId === 0) {
                            this.Logger.error('Could not read share as Share ID = 0 ');
                            throw ({ code: 11201, msg: 'This folder has become invalid.' });
                        }
                        appIds = [];
                        apps.map(function (cur) {
                            if (cur.selected) {
                                appIds.push(cur.app_id);
                            }
                        });
                        this.Logger.info((status ? 'Enabling' : 'Disabling') + " apps " + JSON.stringify(appIds) + " to share " + shareId);
                        return [4 /*yield*/, this.setApps(shareId, status, appIds)
                                .catch(function (err) {
                                _this.Logger.error('WriteApps failed');
                                _this.Logger.error(err);
                                throw err;
                            })];
                    case 1:
                        resp = _b.sent();
                        if (resp.success == 0) {
                            this.Logger.error('Writing apps failed with success == 0');
                            throw resp;
                        }
                        else {
                            for (_i = 0, _a = resp.apps; _i < _a.length; _i++) {
                                app = _a[_i];
                                app.selected = false;
                            }
                            return [2 /*return*/, resp.apps];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AppsService.prototype.addAppToExistingShare = function (sync_id, share_id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.shareNewService.addAppShare(sync_id, share_id)
                            .then(function (shareInfo) {
                            _this.Logger.info('Added App to Existing Share');
                            // What to do after add?
                        })
                            .catch(function (err) {
                            _this.Logger.error('Adding Apps to existing share failed');
                            _this.Logger.error(err);
                            throw err;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppsService.prototype.addAppAndCreateShare = function (sync_id, dir_name) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sharememberInvite;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sharememberInvite = {
                            permissions: {
                                'perDOWNLOAD': 1,
                                'perUPLOAD': 1,
                                'perINVITE': 0,
                                'perSEEOTHERS': 1,
                                'perADDAPP': 0
                            },
                            displayName: this.base64.decode(this.User.get('display_name')),
                            queue: [],
                            roleQueue: [],
                            roleName: '',
                            privateMessage: ''
                        };
                        return [4 /*yield*/, this.shareNewService.mkShare(sync_id, dir_name, dir_name, sharememberInvite, true).toPromise()
                                .then(function (shareInfo) {
                                _this.Logger.info('Made share with share ID: ' + shareInfo.shareId);
                                return shareInfo.shareId;
                            }).catch(function (err) {
                                _this.Logger.error('Making share for apps failed');
                                _this.Logger.error(err);
                                throw err;
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AppsService.prototype.disableAppShare = function (sync_id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.shareService.removeAppShare(sync_id)
                            .then(function (data) {
                            if (data.success == 1) {
                                _this.Logger.info("Successfully removed apps from " + sync_id);
                                // this.modalInstance.close();
                            }
                            else {
                                _this.Logger.error("Could not remove apps from " + sync_id);
                                _this.Logger.error(data);
                                throw data;
                            }
                        }).catch(function (err) {
                            _this.Logger.error("Could not remove apps - " + err);
                            _this.Logger.error(err);
                            throw err;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppsService.prototype.disableApp = function (app_id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, sharelist, dirlist, _i, _a, dir, _b, _c, share;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.Logger.info("Apps Service - Disabling app " + app_id);
                        return [4 /*yield*/, this.api.execute('sharelist', {})];
                    case 1:
                        data = _d.sent();
                        return [4 /*yield*/, this.shareListService.processList(data)];
                    case 2:
                        sharelist = _d.sent();
                        return [4 /*yield*/, this.getDirs(app_id)];
                    case 3:
                        dirlist = _d.sent();
                        _i = 0, _a = dirlist.dirs;
                        _d.label = 4;
                    case 4:
                        if (!(_i < _a.length)) return [3 /*break*/, 9];
                        dir = _a[_i];
                        _b = 0, _c = sharelist.apps;
                        _d.label = 5;
                    case 5:
                        if (!(_b < _c.length)) return [3 /*break*/, 8];
                        share = _c[_b];
                        if (!(dir.share_id == share.share_id &&
                            share.app_integrated &&
                            share.num_apps === 1)) return [3 /*break*/, 7];
                        this.Logger.info("Share " + dir.share_id + " is last removing app " + app_id + ". Disabling users app-share. on " + share.sync_id);
                        return [4 /*yield*/, this.disableAppShare(share.sync_id)];
                    case 6:
                        _d.sent();
                        _d.label = 7;
                    case 7:
                        _b++;
                        return [3 /*break*/, 5];
                    case 8:
                        _i++;
                        return [3 /*break*/, 4];
                    case 9: return [4 /*yield*/, this.revokeApps([app_id])];
                    case 10:
                        _d.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppsService.ngInjectableDef = i0.defineInjectable({ factory: function AppsService_Factory() { return new AppsService(i0.inject(i1.LoggerService), i0.inject(i2.UserService), i0.inject(i3.Base64Service), i0.inject(i4.ApiService), i0.inject(i5.ShareService), i0.inject(i6.ShareNewService), i0.inject(i7.ShareListService)); }, token: AppsService, providedIn: "root" });
    return AppsService;
}());
exports.AppsService = AppsService;
