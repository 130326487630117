"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MultiUserData = /** @class */ (function () {
    function MultiUserData() {
        this.user_id = 0;
        this.email = '';
        this.plan_id = 1;
        this.plan_name = '';
        this.verified = 0;
        this.cre_servtime = 0;
        this.disk_usage_bytes = 0;
        this.disk_limit_bytes = 0;
        this.last_filesync_time = 0;
        this.display_name = '';
        this.has_password_reset = 0;
        this.has_disable_purge = 0;
    }
    MultiUserData.prototype.deserialize = function (params) {
        for (var _i = 0, _a = Object.entries(params); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], val = _b[1];
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    };
    return MultiUserData;
}());
exports.MultiUserData = MultiUserData;
