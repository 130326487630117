"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransferStatus = {
    STATUS_WAITING: 0,
    STATUS_WORKING: 10,
    STATUS_ABORT: 5,
    STATUS_SUCCESS: 1,
    STATUS_UPLOAD: 11,
    STATUS_ENC_UPLOAD: 15,
    STATUS_UPLOAD_CANCELLED: 1001,
    STATUS_DOWNLOAD: 12,
    STATUS_DECRYPT_DOWNLOAD: 16,
    STATUS_PREPAREDOWNLOAD: 18,
    STATUS_ERROR: 3000,
    STATUS_ERR_SIZE: 3020,
    STATUS_ERR_OPEN: 3010,
    STATUS_ERR_NAME: 3500,
    STATUS_ERR_SHARE_KEY: 2000,
    STATUS_ERR_DATA_KEY: 2010,
};
var TransferQueueType;
(function (TransferQueueType) {
    TransferQueueType["UPLOAD"] = "upload";
    TransferQueueType["DOWNLOAD"] = "download";
})(TransferQueueType = exports.TransferQueueType || (exports.TransferQueueType = {}));
exports.ValidUploadStatus = [
    exports.TransferStatus.STATUS_WORKING,
    exports.TransferStatus.STATUS_UPLOAD,
    exports.TransferStatus.STATUS_ENC_UPLOAD
];
exports.ValidDownloadStatus = [
    exports.TransferStatus.STATUS_WORKING,
    exports.TransferStatus.STATUS_DOWNLOAD,
    exports.TransferStatus.STATUS_DECRYPT_DOWNLOAD,
    exports.TransferStatus.STATUS_PREPAREDOWNLOAD
];
