"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../sharemember-list/sharemember-list.component.ngfactory");
var i2 = require("../sharemember-list/sharemember-list.component");
var i3 = require("../../core/logger.service");
var i4 = require("../../core/user.service");
var i5 = require("../share.service");
var i6 = require("@ng-bootstrap/ng-bootstrap");
var i7 = require("../../notifications/feedback.service");
var i8 = require("../share-new.service");
var i9 = require("../share-options/share-options.component.ngfactory");
var i10 = require("../share-options/share-options.component");
var i11 = require("../../shared/services/broadcast.service");
var i12 = require("../sharemember-invite/sharemember-invite.component.ngfactory");
var i13 = require("../sharemember-invite/sharemember-invite.component");
var i14 = require("../../core/base64.service");
var i15 = require("../sharemember-remove/sharemember-remove.component.ngfactory");
var i16 = require("../sharemember-remove/sharemember-remove.component");
var i17 = require("../../core/joblock.service");
var i18 = require("@ngrx/store");
var i19 = require("../../core/notifications.service");
var i20 = require("../share-delete/share-delete.component.ngfactory");
var i21 = require("../share-delete/share-delete.component");
var i22 = require("../share-leave/share-leave.component.ngfactory");
var i23 = require("../share-leave/share-leave.component");
var i24 = require("@angular/common");
var i25 = require("./dialog-share-details.component");
var styles_DialogShareDetailsComponent = [];
var RenderType_DialogShareDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogShareDetailsComponent, data: {} });
exports.RenderType_DialogShareDetailsComponent = RenderType_DialogShareDetailsComponent;
function View_DialogShareDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-sharemember-list", [], null, [[null, "openStateChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("openStateChange" === en)) {
        var pd_0 = ((_co.openState = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SharememberListComponent_0, i1.RenderType_SharememberListComponent)), i0.ɵdid(1, 114688, null, 0, i2.SharememberListComponent, [i3.LoggerService, i4.UserService, i5.ShareService, i6.NgbModal, i7.FeedbackService, i8.ShareNewService], { shareData: [0, "shareData"], openState: [1, "openState"], modalInstance: [2, "modalInstance"] }, { openStateChange: "openStateChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shareData; var currVal_1 = _co.openState; var currVal_2 = _co.activeModal; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DialogShareDetailsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-share-options", [], null, [[null, "openStateChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("openStateChange" === en)) {
        var pd_0 = ((_co.openState = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ShareOptionsComponent_0, i9.RenderType_ShareOptionsComponent)), i0.ɵdid(1, 114688, null, 0, i10.ShareOptionsComponent, [i4.UserService, i3.LoggerService, i5.ShareService, i11.BroadcastService], { shareData: [0, "shareData"], modalInstance: [1, "modalInstance"], openState: [2, "openState"] }, { openStateChange: "openStateChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shareData; var currVal_1 = _co.activeModal; var currVal_2 = _co.openState; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DialogShareDetailsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-sharemember-invite", [], null, null, null, i12.View_SharememberInviteComponent_0, i12.RenderType_SharememberInviteComponent)), i0.ɵdid(1, 114688, null, 0, i13.SharememberInviteComponent, [i4.UserService, i14.Base64Service, i3.LoggerService, i5.ShareService, i8.ShareNewService], { shareData: [0, "shareData"], openState: [1, "openState"], modalInstance: [2, "modalInstance"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shareData; var currVal_1 = _co.openState; var currVal_2 = _co.activeModal; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DialogShareDetailsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-sharemember-remove", [], null, [[null, "stateChangeEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("stateChangeEvent" === en)) {
        var pd_0 = (_co.onStateChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_SharememberRemoveComponent_0, i15.RenderType_SharememberRemoveComponent)), i0.ɵdid(1, 114688, null, 0, i16.SharememberRemoveComponent, [i5.ShareService, i8.ShareNewService, i11.BroadcastService, i17.JoblockService, i3.LoggerService, i18.Store, i19.NotificationsService], { openState: [0, "openState"], modalInstance: [1, "modalInstance"], shareData: [2, "shareData"] }, { stateChangeEvent: "stateChangeEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.openState; var currVal_1 = _co.activeModal; var currVal_2 = _co.shareData; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DialogShareDetailsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-share-delete", [], null, null, null, i20.View_ShareDeleteComponent_0, i20.RenderType_ShareDeleteComponent)), i0.ɵdid(1, 114688, null, 0, i21.ShareDeleteComponent, [i7.FeedbackService, i5.ShareService, i11.BroadcastService, i17.JoblockService, i3.LoggerService, i18.Store], { openState: [0, "openState"], shareData: [1, "shareData"], modalInstance: [2, "modalInstance"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.openState; var currVal_1 = _co.shareData; var currVal_2 = _co.activeModal; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DialogShareDetailsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-share-leave", [], null, null, null, i22.View_ShareLeaveComponent_0, i22.RenderType_ShareLeaveComponent)), i0.ɵdid(1, 114688, null, 0, i23.ShareLeaveComponent, [i5.ShareService, i7.FeedbackService, i11.BroadcastService], { openState: [0, "openState"], modalInstance: [1, "modalInstance"], shareData: [2, "shareData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.openState; var currVal_1 = _co.activeModal; var currVal_2 = _co.shareData; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DialogShareDetailsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogShareDetailsComponent_1)), i0.ɵdid(2, 16384, null, 0, i24.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogShareDetailsComponent_2)), i0.ɵdid(4, 16384, null, 0, i24.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogShareDetailsComponent_3)), i0.ɵdid(6, 16384, null, 0, i24.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogShareDetailsComponent_4)), i0.ɵdid(8, 16384, null, 0, i24.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogShareDetailsComponent_5)), i0.ɵdid(10, 16384, null, 0, i24.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogShareDetailsComponent_6)), i0.ɵdid(12, 16384, null, 0, i24.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.openState == 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.openState == 2); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.openState == 3); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.openState == 4); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.openState == 5); _ck(_v, 10, 0, currVal_4); var currVal_5 = (_co.openState == 6); _ck(_v, 12, 0, currVal_5); }, null); }
exports.View_DialogShareDetailsComponent_0 = View_DialogShareDetailsComponent_0;
function View_DialogShareDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-dialog-share-details", [], null, null, null, View_DialogShareDetailsComponent_0, RenderType_DialogShareDetailsComponent)), i0.ɵdid(1, 114688, null, 0, i25.DialogShareDetailsComponent, [i6.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DialogShareDetailsComponent_Host_0 = View_DialogShareDetailsComponent_Host_0;
var DialogShareDetailsComponentNgFactory = i0.ɵccf("sync-dialog-share-details", i25.DialogShareDetailsComponent, View_DialogShareDetailsComponent_Host_0, { shareData: "shareData", openState: "openState", shareService: "shareService" }, {}, []);
exports.DialogShareDetailsComponentNgFactory = DialogShareDetailsComponentNgFactory;
