"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var OrderListApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(OrderListApiOutput, _super);
    function OrderListApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return OrderListApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.OrderListApiOutput = OrderListApiOutput;
var OrderListApiInput = /** @class */ (function (_super) {
    tslib_1.__extends(OrderListApiInput, _super);
    function OrderListApiInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = 'orderlist';
        return _this;
    }
    return OrderListApiInput;
}(base_api_model_1.BaseApiInput));
exports.OrderListApiInput = OrderListApiInput;
