"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var logger_service_1 = require("../../core/logger.service");
var user_service_1 = require("../../core/user.service");
var share_service_1 = require("../share.service");
var dialog_choose_share_owner_component_1 = require("../dialog-choose-share-owner/dialog-choose-share-owner.component");
var dialog_confirm_ownership_change_component_1 = require("../dialog-confirm-ownership-change/dialog-confirm-ownership-change.component");
var feedback_service_1 = require("../../notifications/feedback.service");
var share_new_service_1 = require("../share-new.service");
var SharememberListComponent = /** @class */ (function () {
    function SharememberListComponent(loggerService, userService, shareService, modalService, Feedback, ShareNewService) {
        this.loggerService = loggerService;
        this.userService = userService;
        this.shareService = shareService;
        this.modalService = modalService;
        this.Feedback = Feedback;
        this.ShareNewService = ShareNewService;
        this.openStateChange = new core_1.EventEmitter();
        this.errors = [];
        this.selectedPurge = true;
        this.canChangeOwner = false;
        this.predicate = ['isMe', 'displayname'];
        this.isPro = false;
        this.hasAppsNewFeature = false;
        this.spinners = {
            member: 0,
        };
        this.memberResent = 0;
    }
    SharememberListComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pending_users;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.hasAppsNewFeature = this.userService.get('new_feature');
                        this.isPro = this.userService.get('is_pro');
                        if (!this.shareData) {
                            this.loggerService.error('Unable to find shareData for sharemember-list');
                            this.loggerService.error(JSON.parse(JSON.stringify(this.shareData)));
                            this.errors.push({
                                code: 9000,
                                msg: 'No share member information provided',
                            });
                        }
                        this.canChangeOwner = (this.shareData.people.filter(function (member) { return !(member.isMe ||
                            ['Waiting Signup', 'Waiting'].includes(member.status)); }).length > 0) && !!this.shareData.isOwner;
                        pending_users = this.shareData.people.filter(function (member) {
                            if (member.status === 'Confirm') {
                                return member;
                            }
                        });
                        if (!(pending_users.length > 0)) return [3 /*break*/, 3];
                        this.loggerService.info("enc pending user found : " + pending_users.length);
                        return [4 /*yield*/, this.ShareNewService.postshareDataProcess(this.shareData.syncId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.updateUserStatus()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SharememberListComponent.prototype.updateUserStatus = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var share;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.shareService.getShare(this.shareData.shareId)];
                    case 1:
                        share = _a.sent();
                        this.shareData = share;
                        return [2 /*return*/];
                }
            });
        });
    };
    SharememberListComponent.prototype.resendInvite = function (member) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.errors = [];
                        this.spinners.member = member.sharememberId;
                        return [4 /*yield*/, this.shareService.resendInvite(this.shareData, member.email)];
                    case 1:
                        _a.sent();
                        this.spinners.member = 0;
                        this.memberResent = member.sharememberId;
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.errors.push(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SharememberListComponent.prototype.setPermission = function (permission, sharemember) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newVal, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinners.member = sharemember.sharememberId;
                        this.spinner = true;
                        this.errors = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        newVal = !sharemember.permissions[permission] ? 1 : 0;
                        return [4 /*yield*/, this.shareService.setPermission(this.shareData, permission, sharemember)];
                    case 2:
                        _a.sent();
                        sharemember.permissions[permission] = newVal;
                        this.spinners.member = 0;
                        this.spinner = false;
                        return [2 /*return*/, null];
                    case 3:
                        err_2 = _a.sent();
                        this.errors.push(err_2);
                        this.spinner = false;
                        if (typeof err_2 === 'string') {
                            return [2 /*return*/, err_2];
                        }
                        else {
                            return [2 /*return*/, err_2.message || 'Failed to change ownership!'];
                        }
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SharememberListComponent.prototype.chooseNewOwner = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ref, newOwnerEmail, newOwner;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.modalInstance.close();
                        ref = this.modalService.open(dialog_choose_share_owner_component_1.DialogShareChooseOwnerComponent, {
                            backdropClass: 'in',
                            windowClass: 'in',
                            backdrop: 'static',
                            size: 'lg'
                        });
                        ref.componentInstance.openState = 3;
                        ref.componentInstance.shareData = this.shareData;
                        return [4 /*yield*/, ref.result];
                    case 1:
                        newOwnerEmail = _a.sent();
                        if (!newOwnerEmail) {
                            return [2 /*return*/];
                        }
                        newOwner = this.shareData.people.find(function (member) { return newOwnerEmail === member.email; });
                        if (!newOwner) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.changeOwnership(newOwner)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SharememberListComponent.prototype.changeOwnership = function (sharemember) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ref, isConfirm, error;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.modalInstance.close();
                        ref = this.modalService.open(dialog_confirm_ownership_change_component_1.DialogConfirmOwnershipChangeComponent, {
                            backdropClass: 'in',
                            windowClass: 'in',
                            backdrop: 'static',
                            size: 'lg'
                        });
                        ref.componentInstance.openState = 3;
                        ref.componentInstance.shareData = this.shareData;
                        return [4 /*yield*/, ref.result];
                    case 1:
                        isConfirm = _a.sent();
                        if (!isConfirm) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.setPermission('perOWNER', sharemember)];
                    case 2:
                        error = _a.sent();
                        if (!error) {
                            return [2 /*return*/, this.Feedback.setFeedback('success', 'Ownership has been transferred successfully', 3000)];
                        }
                        else {
                            return [2 /*return*/, this.Feedback.setFeedback('error', "Failed to transfer the ownership. " + error, 3000)];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SharememberListComponent.prototype.getSelectedAmt = function () {
        var amt = 0;
        this.shareData.people.map(function (cur) {
            if (cur.selected) {
                amt++;
            }
        });
        return amt;
    };
    SharememberListComponent.prototype.openPopup = function (state) {
        this.openStateChange.next(state);
    };
    return SharememberListComponent;
}());
exports.SharememberListComponent = SharememberListComponent;
