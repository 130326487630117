"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var blend = require("mixpanel-browser");
var environment_1 = require("../../../environments/environment");
var sku_service_1 = require("./sku.service");
var models_1 = require("../models");
var feature_service_1 = require("./feature.service");
var api_service_1 = require("../../core/api.service");
var logger_service_1 = require("../../core/logger.service");
var broadcast_service_1 = require("./broadcast.service");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var i0 = require("@angular/core");
var i1 = require("./sku.service");
var i2 = require("./feature.service");
var i3 = require("../../core/api.service");
var i4 = require("./broadcast.service");
var i5 = require("../../core/logger.service");
var i6 = require("@ngrx/store");
var BlendService = /** @class */ (function () {
    function BlendService(skuService, featureService, apiService, broadcastService, log, store) {
        var _this = this;
        this.skuService = skuService;
        this.featureService = featureService;
        this.apiService = apiService;
        this.broadcastService = broadcastService;
        this.log = log;
        this.store = store;
        this.ONE_HOUR = 60 * 60 * 1000; /* 1 hour in ms */
        this.blend = blend.init(environment_1.environment.blendToken, tslib_1.__assign({ 'persistence': 'localStorage', 'api_host': environment_1.environment.blendHost }, (!environment_1.environment.production && { 'debug': true })), 'Sync');
        this.broadcastService.on('sync.reset.user').subscribe(function () {
            _this.reset();
        });
        this.broadcastService.on('sync.track.blend').subscribe(function (payload) {
            _this.track(payload.eventName, payload.parameters);
        });
    }
    BlendService.prototype.identifyUser = function (newUserId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userId;
            return tslib_1.__generator(this, function (_a) {
                userId = null;
                if (newUserId) {
                    userId = newUserId;
                }
                else {
                    userId = this.user.id;
                }
                if (userId) {
                    if (this.blend.get_distinct_id() != userId) {
                        this.blend.reset();
                        // Set a super property so that the URLs don't get posted with identify events
                        this.blend.register({
                            '$current_url': this.linkURLSanitizer(window.location.href),
                            '$referrer': this.linkURLSanitizer(document.referrer)
                        });
                        this.blend.identify(userId);
                        this.blend.unregister('$current_url');
                        this.blend.unregister('$referrer');
                        this.updateUserProperties();
                    }
                    else if (!this.lastUpdated || (Date.now() - this.lastUpdated) >= this.ONE_HOUR) {
                        this.updateUserProperties();
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    BlendService.prototype.updateUserProperties = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.apiService.execute('updateblend', {});
                this.lastUpdated = Date.now();
                return [2 /*return*/];
            });
        });
    };
    BlendService.prototype.track = function (event, parameters) {
        if (parameters === void 0) { parameters = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isBlendAllowed;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUser()];
                    case 1:
                        _a.sent();
                        isBlendAllowed = this.user && this.user.is_disableanalytics != 1 || event == models_1.BlendEvent.ANALYTICS_OPT_IN;
                        if (!isBlendAllowed) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.identifyUser()];
                    case 2:
                        _a.sent();
                        this.blend.track(event, tslib_1.__assign({}, this.getDefaultProperties(), parameters));
                        return [2 /*return*/];
                }
            });
        });
    };
    BlendService.prototype.trackSignUp = function (newUserId, parameters) {
        if (parameters === void 0) { parameters = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.identifyUser(newUserId)];
                    case 1:
                        _a.sent();
                        this.blend.track(models_1.BlendEvent.SIGN_UP, tslib_1.__assign({}, this.getDefaultProperties(), parameters));
                        return [2 /*return*/];
                }
            });
        });
    };
    BlendService.prototype.setUserProperty = function (obj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var id;
            return tslib_1.__generator(this, function (_a) {
                id = this.user.id;
                this.blend.identify(id);
                this.blend.people.set(tslib_1.__assign({}, obj));
                return [2 /*return*/];
            });
        });
    };
    BlendService.prototype.trackPageview = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isBlendAllowed;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUser()];
                    case 1:
                        _a.sent();
                        isBlendAllowed = this.user && this.user.is_disableanalytics != 1;
                        if (!isBlendAllowed) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.identifyUser()];
                    case 2:
                        _a.sent();
                        this.blend.track_pageview(this.getDefaultProperties());
                        return [2 /*return*/];
                }
            });
        });
    };
    BlendService.prototype.optOut = function () {
        this.blend.opt_out_tracking();
    };
    BlendService.prototype.reset = function () {
        this.blend.reset();
        this.user = null;
        this.lastUpdated = null;
    };
    BlendService.prototype.linkURLSanitizer = function (url) {
        try {
            // Parse the URL
            var parsedUrl = new URL(url);
            // Split the pathname into parts
            var pathParts = parsedUrl.pathname.split('/').filter(function (part) { return part !== ''; });
            var rootPath = (pathParts.length) ? pathParts[0] : '';
            switch (rootPath) {
                case 'dl': // e.g., /dl/aaaabbbb0/xxx-xxx-xx-xxx
                    parsedUrl.pathname = '/' + pathParts.slice(0, 2).join('/');
                    break;
            }
            return parsedUrl.href;
        }
        catch (error) {
            this.log.error('Error processing URL: ' + error.message);
            return url; // Return the original URL if there's an error
        }
    };
    BlendService.prototype.isUrlHistoryClean = function () {
        // Parse the referrer and current URL
        var referrer = new URL(document.referrer, window.location.origin);
        var currentUrl = new URL(window.location.href);
        // Check if the referrer path starts with '/dl/'
        var referrerHasDl = referrer.pathname.startsWith('/dl/');
        // Check if the current URL path starts with '/dl/'
        var currentHasDl = currentUrl.pathname.startsWith('/dl/');
        return !referrerHasDl && !currentHasDl;
    };
    BlendService.prototype.getDefaultProperties = function () {
        if (!this.isUrlHistoryClean()) {
            var defaultProperties_1 = {
                'current_page_title': 'Sync.com — link',
                'current_url_path': '-',
                '$current_url': this.linkURLSanitizer(window.location.href),
                'platform': 'CP',
                '$referrer': this.linkURLSanitizer(document.referrer),
            };
            return defaultProperties_1;
        }
        var defaultProperties = {
            'current_url_path': window.location.pathname,
            'platform': 'CP'
        };
        return defaultProperties;
    };
    BlendService.prototype.getUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve) {
                            if (_this.user) {
                                if (_this.userSubscribe) {
                                    _this.userSubscribe.unsubscribe();
                                }
                                resolve(true);
                                return;
                            }
                            _this.userSubscribe = _this.store
                                .pipe(store_1.select(fromRoot.getAuthUser))
                                .subscribe(function (data) {
                                _this.user = data;
                                if (_this.userSubscribe) {
                                    _this.userSubscribe.unsubscribe();
                                }
                                resolve(true);
                            });
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * I have add this function as it is intended to be used in multiple click events
     */
    BlendService.prototype.addEventsForUpgradeButton = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sku, _a, _b, _c;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.skuService.getSku()];
                    case 1:
                        sku = _d.sent();
                        _a = this.track;
                        _b = [models_1.BlendEvent.UPGRADE_INQUIRY];
                        _c = {
                            plan: sku
                        };
                        return [4 /*yield*/, this.skuService.getPlanType(sku)];
                    case 2:
                        _a.apply(this, _b.concat([(_c.plan_type = _d.sent(),
                                _c)]));
                        return [2 /*return*/];
                }
            });
        });
    };
    BlendService.ngInjectableDef = i0.defineInjectable({ factory: function BlendService_Factory() { return new BlendService(i0.inject(i1.SkuService), i0.inject(i2.FeatureService), i0.inject(i3.ApiService), i0.inject(i4.BroadcastService), i0.inject(i5.LoggerService), i0.inject(i6.Store)); }, token: BlendService, providedIn: "root" });
    return BlendService;
}());
exports.BlendService = BlendService;
