"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var user_service_1 = require("../core/user.service");
var file_item_service_1 = require("../file/file-item.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var wopi_optin_component_1 = require("../file/wopi-optin/wopi-optin.component");
var broadcast_service_1 = require("../shared/services/broadcast.service");
var router_1 = require("@angular/router");
/**
 * @description
 * Binds a click event to the element and when clicked, will activate
 * the item.
 *
 * Depending on the path item object passed in, the activation will result in
 * different default behaviors.  Directories will be entered, files will
 * be downloaded and images will be previewed.
 */
var PathActivateActionDirective = /** @class */ (function () {
    function PathActivateActionDirective(userService, fileItemService, modalService, broadcastService, router, route) {
        this.userService = userService;
        this.fileItemService = fileItemService;
        this.modalService = modalService;
        this.broadcastService = broadcastService;
        this.router = router;
        this.route = route;
    }
    PathActivateActionDirective.prototype.click = function (event) {
        this.execute(this.item);
    };
    PathActivateActionDirective.prototype.execute = function (item) {
        var _this = this;
        var prev = this.router.routeReuseStrategy.shouldReuseRoute;
        this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
        this.broadcastService.broadcast('event:hide.tools');
        this.user = this.userService.getUser();
        this.is_starred = this.router.url.includes('/starred') ? true : false;
        var ctx = item.context == 'link' || item.context == 'applink' ? 'p' : 'u';
        var type = 'default';
        if (item.type == 'dir') {
            if (this.is_starred) {
                if (item.rootpath == 'vault') {
                    this.router.navigate(["/vault/" + item.sync_id]);
                }
                else {
                    this.router.navigate(["/files/" + item.sync_id]);
                }
                return;
            }
            this.router.navigate([[], item.sync_id], { queryParams: { page: undefined }, queryParamsHandling: 'merge' }).then(function () {
                _this.router.routeReuseStrategy.shouldReuseRoute = prev;
            });
            return;
        }
        else if (this.fileItemService.isImage(item)) {
            type = 'image';
        }
        else if (this.fileItemService.isText(item)) {
            type = 'text';
        }
        else if (this.fileItemService.isPDF(item) &&
            (item.context == 'link' || item.context == 'applink') &&
            item.previewonly) {
            type = 'doc';
        }
        else if (this.fileItemService.isPDF(item) &&
            item.context == 'files') {
            type = 'doc';
        }
        else if (this.display_office_optin(item)) {
            var ref = this.modalService.open(wopi_optin_component_1.WopiOptinComponent, {
                backdropClass: 'in',
                windowClass: 'in',
                backdrop: 'static',
            });
            return;
        }
        else if (this.display_office(item)) {
            type = 'office';
        }
        else if (this.display_doc(item)) {
            type = 'doc';
        }
        else {
            type = 'default';
        }
        this.router.navigate(['/file', item.sync_id, 'view', type], {
            queryParams: {
                view: this.route.snapshot.queryParams['view'],
                page: this.route.snapshot.queryParams['page']
            }
        }).then(function () {
            _this.router.routeReuseStrategy.shouldReuseRoute = prev;
        });
        return;
    };
    PathActivateActionDirective.prototype.display_doc = function (item) {
        return (this.fileItemService.isDocument(item) &&
            // only compat links
            ((item.compat &&
                (item.context == 'link' || item.context == 'applink')) ||
                item.context == 'files'));
    };
    PathActivateActionDirective.prototype.display_office = function (item) {
        return (this.fileItemService.isMSOffice(item, 'embedview') &&
            // Only enabled in files, for now
            item.context === 'files' &&
            this.user.is_office === 1);
    };
    PathActivateActionDirective.prototype.display_office_optin = function (item) {
        return (this.fileItemService.isMSOffice(item, 'embedview') &&
            // Only enabled in files, for now
            item.context === 'files' &&
            this.user.is_displayedofficeoptin === 0 &&
            this.user.can_preview === 1);
    };
    return PathActivateActionDirective;
}());
exports.PathActivateActionDirective = PathActivateActionDirective;
