"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var apps_service_1 = require("../../apps/services/apps.service");
var logger_service_1 = require("../../core/logger.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_app_info_component_1 = require("../../apps/dialog-app-info/dialog-app-info.component");
var AppsComponent = /** @class */ (function () {
    function AppsComponent(Logger, modalService, AppsService) {
        this.Logger = Logger;
        this.modalService = modalService;
        this.AppsService = AppsService;
        this.isAppsLoaded = false;
        this.showInfo = false;
        this.apps = [];
    }
    AppsComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.load();
                return [2 /*return*/];
            });
        });
    };
    AppsComponent.prototype.load = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resp, _i, _a, app;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.AppsService.getApps()
                            .catch(function (ex) {
                            _this.Logger.error(ex);
                            _this.error = models_1.ErrCode.fromException(ex);
                        })];
                    case 1:
                        resp = _b.sent();
                        if (!resp || resp.success != 1) {
                            this.apps = [];
                            this.isAppsLoaded = true;
                        }
                        else {
                            for (_i = 0, _a = resp.apps; _i < _a.length; _i++) {
                                app = _a[_i];
                                app.icon_link = "images/apps/" + app.app_id + ".svg";
                            }
                            this.apps = resp.apps;
                            this.isAppsLoaded = true;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AppsComponent.prototype.openAppInfo = function (app) {
        var ref = this.modalService.open(dialog_app_info_component_1.DialogAppInfoComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.app = app;
    };
    return AppsComponent;
}());
exports.AppsComponent = AppsComponent;
