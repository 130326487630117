"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dir_picker_service_1 = require("../../file-list/dir-picker.service");
var DirPickerComponent = /** @class */ (function () {
    function DirPickerComponent(dirPickerService) {
        this.dirPickerService = dirPickerService;
    }
    DirPickerComponent.prototype.ngOnInit = function () {
        this.pickerView = this.dirPickerService.view;
        var mode = this.skipShareFolders
            ? this.dirPickerService.INIT_SKIPSHARE
            : this.dirPickerService.INIT_ALL;
        this.dirPickerService.init(mode);
    };
    DirPickerComponent.prototype.cd = function (item) {
        return this.dirPickerService.cd(item);
    };
    DirPickerComponent.prototype.select = function (item) {
        return this.dirPickerService.select(item);
    };
    DirPickerComponent.prototype.selectByName = function (name) {
        return this.dirPickerService.selectByName(name);
    };
    DirPickerComponent.prototype.isSelectedByName = function (name) {
        return this.dirPickerService.isSelectedByName(name);
    };
    DirPickerComponent.prototype.isSelected = function (item) {
        return this.dirPickerService.isSelected(item);
    };
    DirPickerComponent.prototype.getDestination = function () {
        return this.dirPickerService.getDestination();
    };
    DirPickerComponent.prototype.openRoot = function (name) {
        console.log(name);
        this.dirPickerService.openRoot(name);
    };
    DirPickerComponent.prototype.cdUp = function () {
        if (this.pickerView.cwd.pid) {
            this.cd({ sync_id: this.pickerView.cwd.pid });
        }
        else {
            var mode = this.skipShareFolders
                ? this.dirPickerService.INIT_SKIPSHARE
                : this.dirPickerService.INIT_ALL;
            this.dirPickerService.init(mode);
        }
    };
    return DirPickerComponent;
}());
exports.DirPickerComponent = DirPickerComponent;
