"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../shared/guards/auth.guard");
var apps_component_1 = require("./apps/apps.component");
var billing_invoice_component_1 = require("./billing-invoice/billing-invoice.component");
var billing_profile_component_1 = require("./billing-profile/billing-profile.component");
var billing_component_1 = require("./billing/billing.component");
var dashboard_component_1 = require("./dashboard/dashboard.component");
var devices_component_1 = require("./devices/devices.component");
var getstarted_component_1 = require("./getstarted/getstarted.component");
var migrate_component_1 = require("./migrate/migrate.component");
var renew_component_1 = require("./renew/renew.component");
var security_component_1 = require("./security/security.component");
var upgrade_component_1 = require("./upgrade/upgrade.component");
var footer_layout_component_1 = require("../layout/footer-layout/footer-layout.component");
var routes = [
    {
        path: '', component: footer_layout_component_1.FooterLayoutComponent,
        canActivate: [auth_guard_1.AuthGuard],
        children: [
            { path: '', redirectTo: '/account/info', pathMatch: 'full' },
            { path: 'info', component: dashboard_component_1.DashboardComponent },
            { path: 'apps', component: apps_component_1.AppsComponent },
            { path: 'billing', component: billing_component_1.BillingComponent },
            { path: 'billing/profile', component: billing_profile_component_1.BillingProfileComponent },
            { path: 'billing/invoice/:id', component: billing_invoice_component_1.BillingInvoiceComponent },
            { path: 'devices', component: devices_component_1.DevicesComponent },
            { path: 'security', component: security_component_1.SecurityComponent },
            { path: 'migrate', component: migrate_component_1.MigrateComponent },
            { path: 'upgrade', component: upgrade_component_1.UpgradeComponent },
            { path: 'getspace', redirectTo: 'upgrade' },
            { path: 'renew', component: renew_component_1.RenewComponent },
            { path: 'getstarted', component: getstarted_component_1.GetstartedComponent },
        ]
    }
];
var AccountRoutingModule = /** @class */ (function () {
    function AccountRoutingModule() {
    }
    return AccountRoutingModule;
}());
exports.AccountRoutingModule = AccountRoutingModule;
