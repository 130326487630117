"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var SlackLayoutComponent = /** @class */ (function () {
    function SlackLayoutComponent() {
    }
    SlackLayoutComponent.prototype.ngOnInit = function () {
    };
    return SlackLayoutComponent;
}());
exports.SlackLayoutComponent = SlackLayoutComponent;
