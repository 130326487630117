"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var feedback_service_1 = require("../../notifications/feedback.service");
var broadcast_service_1 = require("../../shared/services/broadcast.service");
var share_service_1 = require("../share.service");
var joblock_service_1 = require("src/app/core/joblock.service");
var store_1 = require("@ngrx/store");
var logger_service_1 = require("../../core/logger.service");
var ShareDeleteComponent = /** @class */ (function () {
    function ShareDeleteComponent(feedbackService, shareService, broadcastService, JobLockService, loggerService, store) {
        this.feedbackService = feedbackService;
        this.shareService = shareService;
        this.broadcastService = broadcastService;
        this.JobLockService = JobLockService;
        this.loggerService = loggerService;
        this.store = store;
        this.selectedPurge = '1'; // default true
        this.spinner = false;
        this.errors = [];
        this.bg = 0;
    }
    ShareDeleteComponent.prototype.ngOnInit = function () { };
    ShareDeleteComponent.prototype.poststopShareAction = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.stopShareJob.bg == 1)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.JobLockService.pollJobStatus()];
                    case 1:
                        response = _a.sent();
                        _a.label = 2;
                    case 2:
                        this.broadcastService.broadcast('event:file-list.reload');
                        this.broadcastService.broadcast('event:share-list.reload');
                        this.spinner = false;
                        this.feedbackService.setFeedback('success', "The share " + this.shareData.name + " has been stopped", 2000);
                        this.modalInstance.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    ShareDeleteComponent.prototype.stopShare = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, err_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        this.spinner = true;
                        this.errors = [];
                        _a = this;
                        return [4 /*yield*/, this.shareService.stopShare(this.shareData.shareId, this.shareData.sharememberId, this.selectedPurge == '1')];
                    case 1:
                        _a.stopShareJob = _b.sent();
                        this.poststopShareAction();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _b.sent();
                        this.spinner = false;
                        this.errors.push(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ShareDeleteComponent.prototype.close = function () {
        this.modalInstance.close();
    };
    return ShareDeleteComponent;
}());
exports.ShareDeleteComponent = ShareDeleteComponent;
