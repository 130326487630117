"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./content-length-display.component");
var styles_ContentLengthDisplayComponent = [];
var RenderType_ContentLengthDisplayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContentLengthDisplayComponent, data: {} });
exports.RenderType_ContentLengthDisplayComponent = RenderType_ContentLengthDisplayComponent;
function View_ContentLengthDisplayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "pull-right comment-text-box-content-length"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.maxLength - _co.value.length); _ck(_v, 1, 0, currVal_0); }); }
function View_ContentLengthDisplayComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContentLengthDisplayComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_ContentLengthDisplayComponent_0 = View_ContentLengthDisplayComponent_0;
function View_ContentLengthDisplayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-content-length-display", [], null, null, null, View_ContentLengthDisplayComponent_0, RenderType_ContentLengthDisplayComponent)), i0.ɵdid(1, 114688, null, 0, i2.ContentLengthDisplayComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ContentLengthDisplayComponent_Host_0 = View_ContentLengthDisplayComponent_Host_0;
var ContentLengthDisplayComponentNgFactory = i0.ɵccf("sync-content-length-display", i2.ContentLengthDisplayComponent, View_ContentLengthDisplayComponent_Host_0, { value: "value", maxLength: "maxLength" }, {}, []);
exports.ContentLengthDisplayComponentNgFactory = ContentLengthDisplayComponentNgFactory;
