"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var models_1 = require("../../shared/models");
var api_service_1 = require("../../core/api.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_autorenew_component_1 = require("../dialog-autorenew/dialog-autorenew.component");
var zuora_account_service_1 = require("../services/zuora-account.service");
var logger_service_1 = require("../../core/logger.service");
var dialog_email_billing_doc_component_1 = require("../dialog-email-billing-doc/dialog-email-billing-doc.component");
var user_service_1 = require("../../core/user.service");
var dialog_multiadminuser_add_component_1 = require("../../multi-admin/dialog-multiadminuser-add/dialog-multiadminuser-add.component");
var router_1 = require("@angular/router");
var BillingComponent = /** @class */ (function () {
    function BillingComponent(api, log, modalService, store, zuora, userService, router, route) {
        this.api = api;
        this.log = log;
        this.modalService = modalService;
        this.store = store;
        this.zuora = zuora;
        this.userService = userService;
        this.router = router;
        this.route = route;
        this.permissionActions = models_1.PermissionActions;
        this.profileExists = false;
        this.recordScrubbed = false;
        this.spinner = false;
        this.emailSpinner = '';
    }
    BillingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.usersLimit = this.userService.MAX_USERS;
        this.sub = this.store
            .select(fromRoot.getAuthState)
            .subscribe(function (data) {
            if (!data.authenticated) {
                console.warn('User is not authenicated');
                _this.user = new models_1.User();
                // this.initialized = false;
                return;
            }
            if (data.user) {
                console.log('Setting user');
                _this.user = data.user;
                // this.initialized = true;
            }
        });
        this.init();
    };
    BillingComponent.prototype.checkPermission = function (permName) {
        return this.userService.checkPermission(permName);
    };
    BillingComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    };
    BillingComponent.prototype.editProfile = function () {
        this.router.navigate(['/account/billing/profile']);
    };
    BillingComponent.prototype.autoRenewDialog = function () {
        var _this = this;
        var ref = this.modalService.open(dialog_autorenew_component_1.DialogAutorenewComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
        ref.componentInstance.zprofile = this.zprofile;
        ref.result.then(function () { return _this.loadBillingProfile(); });
    };
    BillingComponent.prototype.loadProfileWithInterval = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var billingProfileRetryTimeout, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        billingProfileRetryTimeout = 1000;
                        _b.label = 1;
                    case 1:
                        if (!(billingProfileRetryTimeout < 120000)) return [3 /*break*/, 7];
                        _a = this;
                        return [4 /*yield*/, this.zuora.getProfile()];
                    case 2:
                        _a.zprofile = _b.sent();
                        billingProfileRetryTimeout += billingProfileRetryTimeout;
                        if (!(this.route.snapshot.queryParams['newprofile'] == 0 && this.zprofile && !this.zprofile.defaultPaymentMethod)) return [3 /*break*/, 3];
                        return [3 /*break*/, 7];
                    case 3:
                        if (!(this.route.snapshot.queryParams['newprofile'] == 1 && this.zprofile && this.zprofile.defaultPaymentMethod)) return [3 /*break*/, 4];
                        return [3 /*break*/, 7];
                    case 4: return [4 /*yield*/, new Promise(function (resolve) {
                            setTimeout(function () { return resolve(true); }, billingProfileRetryTimeout);
                        })];
                    case 5:
                        _b.sent();
                        _b.label = 6;
                    case 6: return [3 /*break*/, 1];
                    case 7: return [2 /*return*/, this.zprofile];
                }
            });
        });
    };
    BillingComponent.prototype.loadBillingProfile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, ex_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        this.spinner = true;
                        if (!(this.checkPermission(this.permissionActions.VIEW_BILLING) || this.route.snapshot.queryParams['newprofile'])) return [3 /*break*/, 5];
                        if (!this.route.snapshot.queryParams['newprofile']) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.loadProfileWithInterval()];
                    case 1:
                        _a.zprofile = _c.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        _b = this;
                        return [4 /*yield*/, this.zuora.getProfile()];
                    case 3:
                        _b.zprofile = _c.sent();
                        _c.label = 4;
                    case 4:
                        this.recordScrubbed = (this.zprofile.name === 'Record scrubbed');
                        if (this.zprofile && this.zprofile.accountNumber && !this.recordScrubbed) {
                            this.profileExists = true;
                        }
                        // sort by time, then if same, invoice number
                        this.zprofile.billingDocuments = this.zprofile.billingDocuments
                            .sort(function (a, b) {
                            var result = 0;
                            var dateDiff = new Date(a.date).getTime() - new Date(b.date).getTime();
                            if (dateDiff == 0) {
                                result = a.number.toString().localeCompare(b.number, undefined, { numeric: true, sensitivity: 'base' });
                            }
                            else {
                                result = dateDiff;
                            }
                            return result * -1;
                        });
                        return [3 /*break*/, 6];
                    case 5:
                        if (this.user.plan_id == 1) {
                            // free account
                        }
                        else {
                            this.errcode = new models_1.ErrCode(8505);
                        }
                        _c.label = 6;
                    case 6:
                        this.spinner = false;
                        return [3 /*break*/, 8];
                    case 7:
                        ex_1 = _c.sent();
                        this.spinner = false;
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        this.log.e('errcode loading billing profile', ex_1);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    BillingComponent.prototype.emailInvoice = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var docs, ref;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                docs = this.zprofile.billingDocuments.filter(function (val) { return val.id === id; });
                if (docs.length !== 1) {
                    this.log.error("Error locating document id " + id + " from billing documents " + JSON.stringify(this.zprofile.billingDocuments));
                    this.errcode = new models_1.ErrCode(1658);
                    return [2 /*return*/];
                }
                ref = this.modalService.open(dialog_email_billing_doc_component_1.DialogEmailBillingDocComponent, {
                    backdropClass: 'in',
                    windowClass: 'in',
                    backdrop: 'static',
                });
                ref.componentInstance.doc = docs[0];
                ref.result.then(function (val) {
                    _this.emailSentSuccess = val;
                    window.setTimeout(function () { return _this.emailSentSuccess = false; }, 5000);
                });
                return [2 /*return*/];
            });
        });
    };
    BillingComponent.prototype.purchaseUsers = function () {
        var ref = this.modalService.open(dialog_multiadminuser_add_component_1.DialogMultiAdminuserAddComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.openState = 3;
        ref.componentInstance.plan_user_limit = this.planDetails.plan_user_limit;
        ref.componentInstance.plan_product_id = this.planDetails.plan_product_id;
        ref.componentInstance.plan_rate_plan_id = this.planDetails.plan_rate_plan_id;
        ref.componentInstance.plan_rate_plan_charge_id = this.planDetails.plan_rate_plan_charge_id;
    };
    BillingComponent.prototype.init = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.loadBillingProfile()];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.api.execute('orderlist', {})];
                    case 2:
                        result = _b.sent();
                        this.invoices = result.invoicelist;
                        if (!this.userService.checkPermission(this.permissionActions.ADD_EDIT_USERS)) return [3 /*break*/, 4];
                        _a = this;
                        return [4 /*yield*/, this.api.execute('getPlanDetails', {})];
                    case 3:
                        _a.planDetails = _b.sent();
                        this.showPurchaseUsersOption = true;
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return BillingComponent;
}());
exports.BillingComponent = BillingComponent;
