"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../shared/components/error/error.component.ngfactory");
var i2 = require("../../shared/components/error/error.component");
var i3 = require("../../core/user.service");
var i4 = require("@ng-bootstrap/ng-bootstrap");
var i5 = require("@angular/platform-browser");
var i6 = require("@angular/common");
var i7 = require("./renew.component");
var i8 = require("../../core/api.service");
var i9 = require("../../core/logger.service");
var i10 = require("@ngrx/store");
var i11 = require("../../core/sync-cookie.service");
var i12 = require("../services/zuora-account.service");
var styles_RenewComponent = [];
var RenderType_RenewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RenewComponent, data: {} });
exports.RenderType_RenewComponent = RenderType_RenewComponent;
function View_RenewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "header-title"], ["style", "font-size: 30px;font-weight:600;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Your account is pending cancellation "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Please contact support if you wish to make changes to your plan."]))], null, null); }
function View_RenewComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "header-title"], ["style", "font-size: 30px;font-weight:600;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Unable to renew "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Your account is not able to be renewed currently. Please contact support for further assistance."]))], null, null); }
function View_RenewComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" If the page does not reload automatically, click the renew now button. "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "a", [["class", "btn btn-primary"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Renew Now"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 3, 0, currVal_0); }); }
function View_RenewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "col-sm-12 header-box-withmenu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "sync-error", [], null, null, null, i1.View_ErrorComponent_0, i1.RenderType_ErrorComponent)), i0.ɵdid(2, 114688, null, 0, i2.ErrorComponent, [i3.UserService, i4.NgbModal, i5.DomSanitizer], { errcode: [0, "errcode"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenewComponent_2)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenewComponent_3)), i0.ɵdid(6, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenewComponent_4)), i0.ɵdid(8, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errcode; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.cancelInFuture; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.unableToRenew; _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_co.url && !_co.unableToRenew) && !_co.cancelInFuture); _ck(_v, 8, 0, currVal_3); }, null); }
function View_RenewComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "col-sm-12 content-box-withmenu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-spin fa-spinner"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Processing user information... "])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "hr", [], null, null, null, null, null))], null, null); }
function View_RenewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "container-fluid container-fluid-wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "col-sm-12 affix header-box-withmenu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "header-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Renew your account "])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "right-menu-button right-menu-button-push affix"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenewComponent_1)), i0.ɵdid(7, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenewComponent_5)), i0.ɵdid(9, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.spinner; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.spinner; _ck(_v, 9, 0, currVal_1); }, null); }
exports.View_RenewComponent_0 = View_RenewComponent_0;
function View_RenewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-renew", [], null, null, null, View_RenewComponent_0, RenderType_RenewComponent)), i0.ɵdid(1, 245760, null, 0, i7.RenewComponent, [i8.ApiService, i0.Injector, i9.LoggerService, i5.DomSanitizer, i10.Store, i11.SyncCookieService, i12.ZuoraAccountService, i3.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_RenewComponent_Host_0 = View_RenewComponent_Host_0;
var RenewComponentNgFactory = i0.ɵccf("sync-renew", i7.RenewComponent, View_RenewComponent_Host_0, {}, {}, []);
exports.RenewComponentNgFactory = RenewComponentNgFactory;
