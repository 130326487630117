"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog1_up_component_1 = require("../account/dialog1-up/dialog1-up.component");
var broadcast_service_1 = require("../shared/services/broadcast.service");
/**
 * @description
 *  The event published is "event:keydown:[keycode]" where keycode is the
 *  event keycode:
 *
 *     13 = enter,
 *     37 = left arrow key,
 *     39 = right arrow key,
 *     45 = insert
 *     ...
 *
 */
var GlobalBindsDirective = /** @class */ (function () {
    function GlobalBindsDirective(modalService, broadcastService) {
        this.modalService = modalService;
        this.broadcastService = broadcastService;
        this.konami_keys_map = {
            'ArrowUp': 38,
            'ArrowDown': 40,
            'ArrowLeft': 37,
            'ArrowRight': 39,
            'b': 66,
            'a': 65
        };
        this.konami_keys = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];
        this.konami_index = 0;
    }
    GlobalBindsDirective.prototype.onResize = function (e) {
        this.broadcastService.broadcast('event:window.resize', e);
    };
    GlobalBindsDirective.prototype.onKeydownHandler = function (e) {
        this.broadcastService.broadcast('event:keydown:' + e.which, e);
        if (e.key === this.konami_keys[this.konami_index++]) {
            if (this.konami_index === this.konami_keys.length) {
                this.konami_index = 0;
                var ref = this.modalService.open(dialog1_up_component_1.Dialog1UpComponent, {
                    backdropClass: 'in',
                    windowClass: 'in',
                    backdrop: true,
                });
                ref.result.then(function () { }, function () { });
            }
        }
        else {
            this.konami_index = 0;
        }
    };
    return GlobalBindsDirective;
}());
exports.GlobalBindsDirective = GlobalBindsDirective;
