"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base64_service_1 = require("../../core/base64.service");
var logger_service_1 = require("../../core/logger.service");
var user_service_1 = require("../../core/user.service");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var models_1 = require("../../shared/models");
var i0 = require("@angular/core");
var i1 = require("../../core/base64.service");
var i2 = require("../../core/logger.service");
var i3 = require("../../core/crypt/sync-crypt.service");
var i4 = require("../../core/user.service");
var LinkPasswordService = /** @class */ (function () {
    function LinkPasswordService(base64, log, crypt, user) {
        this.base64 = base64;
        this.log = log;
        this.crypt = crypt;
        this.user = user;
        // create a list of valid characters to create a link password from
        this.PASSWORDCHARS = 'abcdefghijkmnpqrstuvwxyz23456789';
    }
    /**
     * Validates that the password they enter is of the allowed characters.
     *
     * Alpha numeric plus - and _. All characters allowed here must be
     * URL safe (so no equals signs)
     *
     * This function's return value is set up to work with the ui-validate
     * @param  {String} value Plain text version of their password.
     * @returns {Boolean}      Returns true if the password is NOT valid
     */
    LinkPasswordService.prototype.validatePassword = function (value) {
        return !/[^a-zA-Z0-9\-_]/gi.test(value);
    };
    /**
     * Makes a new link password.  Uses SyncCrypt's prng and a list of
     * characters which hopefully don't look a like.  Creates 32^32
     * potential passwords.
     * @param  {Integer=} len Optional length value.  Defaults to 32. It must
     *                       be divisible by 8.
     * @returns {Promise}      The password formatted and separated by dashes
     *                       "-" at every 8th character.
     */
    LinkPasswordService.prototype.makeLinkPassword = function (len) {
        var i = 0, charString = '';
        var password = [], chars = [], passlen = len || 32;
        var rand = this.crypt.getRandom(passlen * 32);
        if (passlen % 8 !== 0) {
            throw new Error('Password length must be divisible by 8');
        }
        for (i = 0; i < passlen; i++) {
            // mod 32, 32 is len of PASSWORDCHARS value.
            chars.push(this.PASSWORDCHARS.charAt(Math.abs(rand[i]) % 32));
        }
        charString = chars.join('');
        // format password to be nice
        for (i = 0; i < passlen; i += 8) {
            password.push(charString.substring(i, i + 8));
        }
        return password.join('-');
    };
    /**
     * Gets a new-style password for a secure link.
     *
     * The password is a number prefix which tells us how it was encrypted
     * @param  {String} enc_password Number prefixed base64 representation
     *                               of the password.
     * @return {Promise}              Returns the password in plain text
     */
    LinkPasswordService.prototype.getPassword = function (enc_password) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pass, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.crypt.linkpasswordDecrypt(enc_password)];
                    case 1:
                        pass = _a.sent();
                        return [2 /*return*/, this.base64.decode(pass)];
                    case 2:
                        ex_1 = _a.sent();
                        this.log.e('Error getting link password ', ex_1);
                        throw models_1.ErrCode.fromException(ex_1);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Retrieves a legacy password which is just the enc_share_key
     * @param  {String} sharekey_id   The share key id.
     * @param  {String} enc_share_key Base64 of the encrypted share key
     * @return {Promise}               Base64 of the share key
     */
    LinkPasswordService.prototype.getLegacyPassword = function (sharekey_id, enc_share_key) {
        return this.crypt.sharekeyDecrypt(enc_share_key, sharekey_id);
    };
    /**
     * Converts the plain text link password to a valid share key.  The share
     * key will be 64 bytes (512 bits)
     * @param  {String} linkPassword The plain text password
     * @param  {Array} salt          A bit array of 16 bytes
     * @param  {Integer} iterations  An iteration count, default is 10000
     * @return {Promise}               A sjcl bitArray pkdbf2'd
     */
    LinkPasswordService.prototype.convertToShareKey = function (linkPassword, saltData, iterations) {
        var salt = saltData;
        if (typeof saltData == 'string') {
            // salt will either be an array or a hex string
            salt = this.crypt.hexToBytes(saltData);
        }
        return this.crypt.keyStretchSlow(linkPassword, salt, iterations || 10000, 32 * 16);
    };
    /**
     * @ngdoc method
     * @name  makePasswordData
     * @methodOf sync.service:LinkPassword
     * @description
     * Generates all password data.
     * @param  {String} linkPassword The plain text password
     * @return {Promise}              Returns an object with the following:
     *                               {
     *                                   enc_share_key: enc_share_key,
     *                                   salt: sjcl.codec.hex.fromBits(salt),
     *                                   sharekey: sharekey,
     *                                   iterations: iterations,
     *                                   enc_password: '50:' + enc_password,
     *                                   enc_comment_key: comment_data_key
     *                               }
     */
    LinkPasswordService.prototype.makePasswordData = function (linkPassword) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pubkey, it, saltData, salt, sharekey, enc_password, sharekeyB64, enc_share_key, ex_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        pubkey = this.user.get('pubkey'), it = 10000;
                        saltData = this.crypt.getRandom(128);
                        salt = this.crypt.bytesToHex(saltData);
                        return [4 /*yield*/, this.convertToShareKey(linkPassword, salt, it)];
                    case 1:
                        sharekey = _a.sent();
                        return [4 /*yield*/, this.crypt.linkpasswordEncrypt(this.base64.encode(linkPassword), pubkey)];
                    case 2:
                        enc_password = _a.sent();
                        sharekeyB64 = this.crypt.bytesToB64(sharekey);
                        return [4 /*yield*/, this.crypt.sharekeyEncrypt(sharekeyB64, pubkey)];
                    case 3:
                        enc_share_key = _a.sent();
                        return [2 /*return*/, {
                                enc_share_key: enc_share_key,
                                salt: salt,
                                sharekey: sharekey,
                                iterations: it,
                                enc_password: enc_password,
                            }];
                    case 4:
                        ex_2 = _a.sent();
                        this.log.e('make password data failed for link ', ex_2);
                        throw models_1.ErrCode.fromException(ex_2);
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    LinkPasswordService.ngInjectableDef = i0.defineInjectable({ factory: function LinkPasswordService_Factory() { return new LinkPasswordService(i0.inject(i1.Base64Service), i0.inject(i2.LoggerService), i0.inject(i3.SyncCryptService), i0.inject(i4.UserService)); }, token: LinkPasswordService, providedIn: "root" });
    return LinkPasswordService;
}());
exports.LinkPasswordService = LinkPasswordService;
