"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DirtyOutEncResultShare = /** @class */ (function () {
    function DirtyOutEncResultShare() {
    }
    return DirtyOutEncResultShare;
}());
exports.DirtyOutEncResultShare = DirtyOutEncResultShare;
var DirtyOutEncResultSync = /** @class */ (function () {
    function DirtyOutEncResultSync() {
    }
    return DirtyOutEncResultSync;
}());
exports.DirtyOutEncResultSync = DirtyOutEncResultSync;
