"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var api_service_1 = require("../../core/api.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var user_service_1 = require("../../core/user.service");
var DialogPasswordHintComponent = /** @class */ (function () {
    function DialogPasswordHintComponent(activeModal, api, userService) {
        this.activeModal = activeModal;
        this.api = api;
        this.userService = userService;
    }
    DialogPasswordHintComponent.prototype.ngOnInit = function () {
        this.spinner = false;
        this.errcode = undefined;
    };
    DialogPasswordHintComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        this.errcode = undefined;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.execute('passwordhintupdate', {
                                password_hint: this.hint
                            })];
                    case 2:
                        _a.sent();
                        this.userService.refresh();
                        this.activeModal.close(true);
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.spinner = false;
                        console.log('save');
                        return [2 /*return*/];
                }
            });
        });
    };
    return DialogPasswordHintComponent;
}());
exports.DialogPasswordHintComponent = DialogPasswordHintComponent;
