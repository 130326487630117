"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./download-compat.component");
var i2 = require("@ng-bootstrap/ng-bootstrap");
var i3 = require("../services/build-transfer-item.service");
var styles_DownloadCompatComponent = [];
var RenderType_DownloadCompatComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DownloadCompatComponent, data: {} });
exports.RenderType_DownloadCompatComponent = RenderType_DownloadCompatComponent;
function View_DownloadCompatComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "h4", [["class", "modal-title"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "error-general-overlay error-general-overlay-inline"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-info-circle"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" You are about to download this file using compatibility mode. "])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "a", [["class", "syncblue"], ["href", "https://www.sync.com/help/compatibility-mode"], ["rel", "noopener"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["More info ..."])), (_l()(), i0.ɵeld(12, 0, null, null, 7, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-compat-download btn-primary pull-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "i", [["class", "ion-arrow-down-c fa-lg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Download file now "])), (_l()(), i0.ɵeld(16, 0, null, null, 1, "button", [["class", "btn btn-compat-open btn-default pull-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Open file "])), (_l()(), i0.ɵeld(18, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Close "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentItem.name; _ck(_v, 4, 0, currVal_0); }); }
exports.View_DownloadCompatComponent_0 = View_DownloadCompatComponent_0;
function View_DownloadCompatComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-download-compat", [], null, null, null, View_DownloadCompatComponent_0, RenderType_DownloadCompatComponent)), i0.ɵdid(1, 114688, null, 0, i1.DownloadCompatComponent, [i2.NgbActiveModal, i3.BuildTransferItemService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DownloadCompatComponent_Host_0 = View_DownloadCompatComponent_Host_0;
var DownloadCompatComponentNgFactory = i0.ɵccf("sync-download-compat", i1.DownloadCompatComponent, View_DownloadCompatComponent_Host_0, { item: "item" }, {}, []);
exports.DownloadCompatComponentNgFactory = DownloadCompatComponentNgFactory;
