"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var dialog_display_avatar_component_1 = require("./../dialog-display-avatar/dialog-display-avatar.component");
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var models_1 = require("../../shared/models");
var rewards_service_1 = require("../services/rewards.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_displayname_component_1 = require("../dialog-displayname/dialog-displayname.component");
var dialog_username_change_component_1 = require("../dialog-username-change/dialog-username-change.component");
var dialog_rewards_component_1 = require("../dialog-rewards/dialog-rewards.component");
var dialog_email_preference_component_1 = require("../dialog-email-preference/dialog-email-preference.component");
var dialog_preview_preference_component_1 = require("../dialog-preview-preference/dialog-preview-preference.component");
var api_service_1 = require("../../core/api.service");
var logger_service_1 = require("../../core/logger.service");
var dialog_cancel_component_1 = require("../dialog-cancel/dialog-cancel.component");
var dialog_custom_brand_component_1 = require("../dialog-custom-brand/dialog-custom-brand.component");
var environment_1 = require("../../../environments/environment");
var user_service_1 = require("../../core/user.service");
var dialog_confirm_password_component_1 = require("../dialog-confirm-password/dialog-confirm-password.component");
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(api, log, store, rewards, modalService, userService) {
        this.api = api;
        this.log = log;
        this.store = store;
        this.rewards = rewards;
        this.modalService = modalService;
        this.userService = userService;
        this.DU_STATUS_INIT = 0;
        this.DU_STATUS_SUCCESS = 1;
        this.DU_STATUS_INVALID = 2;
        this.avatarEndpoint = environment_1.environment.logohost + "avatar/";
        this.errorImageUrl = environment_1.environment.cphost + "/images/anonymous.png";
        this.showCopiedText = false;
        this.du = {
            status: 0,
            spinner: false,
            vaultbytes: 0,
            vaultpercent: 0,
            syncbytes: 0,
            syncpercent: 0,
            unusedbytes: 0,
        };
        this.permissionActions = models_1.PermissionActions;
    }
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sub = this.store
            .select(fromRoot.getAuthState)
            .subscribe(function (data) {
            if (!data.authenticated) {
                _this.user = new models_1.User();
                // this.initialized = false;
                return;
            }
            if (data.user) {
                _this.user = data.user;
                // this.du.syncbytes = this.user.disk_usage_bytes;
                // this.du.syncpercent = this.user.disk_usage_bytes / this.user.disk_limit_bytes * 100;
                // this.du.unusedbytes = this.user.disk_limit_bytes - this.user.disk_usage_bytes;
                if (_this.du.status == _this.DU_STATUS_INIT) {
                    _this.getDiskUsage();
                }
                // this.initialized = true;
            }
        });
        this.rewards.getData().then(function (result) { return (_this.userRewards = result); });
        this.avatarUrl = "" + this.avatarEndpoint + this.user.id;
    };
    DashboardComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    };
    // public invite() {
    //     window.open('http://www.sync-rewards.com/?_sync_refer=' +
    //                       this.user.referral_code,
    //                      'Invite',
    //                      'height=680,width=560');
    // }
    DashboardComponent.prototype.copyReferral = function () {
        var copyInput = (document.getElementById('referral-link').innerText);
        var selBox = document.createElement('input');
        selBox.style.position = 'fixed';
        selBox.value = copyInput;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.log.d('copied link url');
        this.showCopiedText = true;
    };
    DashboardComponent.prototype.getDiskUsage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var vaultBytes, result, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // this.du.syncbytes = this.user.disk_usage_bytes;
                        // this.du.syncpercent = this.user.disk_usage_bytes / this.user.disk_limit_bytes * 100;
                        this.du.unusedbytes =
                            this.user.disk_limit_bytes - this.user.disk_usage_bytes;
                        vaultBytes = 0;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.execute('userdiskusage', {
                                sync_pid: this.user.web_sync_id,
                            })];
                    case 2:
                        result = _a.sent();
                        vaultBytes = result.bytes;
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _a.sent();
                        vaultBytes = 0;
                        this.log.error(ex_1);
                        return [3 /*break*/, 4];
                    case 4:
                        // some legacy
                        if (vaultBytes > this.user.disk_usage_bytes) {
                            this.log.warn("Vault bytes " + vaultBytes + " < usage bytes " + this.user.disk_usage_bytes);
                            this.log.warn('User needs disk usage/vault bytes recalculated');
                            this.du.syncbytes = this.user.disk_usage_bytes;
                            this.du.syncpercent =
                                (this.user.disk_usage_bytes / this.user.disk_limit_bytes) * 100;
                            this.du.unusedbytes =
                                this.user.disk_limit_bytes - this.user.disk_usage_bytes;
                            this.du.status = this.DU_STATUS_INVALID;
                            return [2 /*return*/];
                        }
                        this.du.syncbytes = this.user.disk_usage_bytes - vaultBytes;
                        this.du.vaultbytes = vaultBytes;
                        this.du.syncpercent = Math.ceil((this.du.syncbytes / this.user.disk_limit_bytes) * 100);
                        this.du.vaultpercent = Math.ceil((this.du.vaultbytes / this.user.disk_limit_bytes) * 100);
                        this.du.status = this.DU_STATUS_SUCCESS;
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.openChangeUsernameDialog = function () {
        var ref = this.modalService.open(dialog_username_change_component_1.DialogUsernameChangeComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    };
    DashboardComponent.prototype.openRewardsDialog = function () {
        var ref = this.modalService.open(dialog_rewards_component_1.DialogRewardsComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
        ref.componentInstance.userRewards = this.userRewards;
    };
    DashboardComponent.prototype.openDisplaynameDialog = function () {
        var ref = this.modalService.open(dialog_displayname_component_1.DialogDisplaynameComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    };
    DashboardComponent.prototype.openDisplayAvatarDialog = function () {
        var _this = this;
        var ref = this.modalService.open(dialog_display_avatar_component_1.DialogDisplayAvatarComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
        ref.result
            .then(function () {
            _this.timeStamp = new Date().getTime();
            _this.avatarUrl = _this.avatarUrl.concat("?" + _this.timeStamp);
        })
            .catch(function () { return _this.log.info('Error closing avatar dialog'); });
    };
    DashboardComponent.prototype.openEmailPrefDialog = function () {
        var ref = this.modalService.open(dialog_email_preference_component_1.DialogEmailPreferenceComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    };
    DashboardComponent.prototype.openPreviewPreferences = function () {
        var ref = this.modalService.open(dialog_preview_preference_component_1.DialogPreviewPreferenceComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    };
    DashboardComponent.prototype.confirmPassword = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ref;
            return tslib_1.__generator(this, function (_a) {
                ref = this.modalService.open(dialog_confirm_password_component_1.DialogConfirmPasswordComponent, {
                    backdropClass: 'in',
                    windowClass: 'in',
                    backdrop: 'static',
                });
                return [2 /*return*/, ref.result];
            });
        });
    };
    DashboardComponent.prototype.openCancelDialog = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, ref;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.confirmPassword()];
                    case 1:
                        result = _a.sent();
                        if (result) {
                            ref = this.modalService.open(dialog_cancel_component_1.DialogCancelComponent, {
                                backdropClass: 'in',
                                windowClass: 'in',
                                backdrop: 'static',
                            });
                            ref.componentInstance.user = this.user;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.openCustomBrandDialog = function () {
        var ref = this.modalService.open(dialog_custom_brand_component_1.DialogCustomBrandComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    };
    DashboardComponent.prototype.checkIfUnlimited = function () {
        return this.user.is_unlimited_plan;
    };
    DashboardComponent.prototype.handleMissingImage = function (event) {
        event.target.src = this.errorImageUrl;
    };
    DashboardComponent.prototype.checkPermission = function (permName) {
        return this.userService.checkPermission(permName);
    };
    DashboardComponent.prototype.allowed = function () {
        if (this.user.is_multi_new) {
            if (this.checkPermission(this.permissionActions.MANAGE_BILLING)) {
                return true;
            }
            return false;
        }
        return true;
    };
    return DashboardComponent;
}());
exports.DashboardComponent = DashboardComponent;
