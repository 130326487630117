"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var api_service_1 = require("../../core/api.service");
var models_1 = require("../../shared/models");
var DialogDeviceActivateComponent = /** @class */ (function () {
    function DialogDeviceActivateComponent(activeModal, api) {
        this.activeModal = activeModal;
        this.api = api;
    }
    DialogDeviceActivateComponent.prototype.ngOnInit = function () {
    };
    DialogDeviceActivateComponent.prototype.activate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.execute('deviceactivate', {
                                device_id: this.device.id
                            })];
                    case 1:
                        _a.sent();
                        this.activeModal.close(true);
                        return [3 /*break*/, 3];
                    case 2:
                        ex_1 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return DialogDeviceActivateComponent;
}());
exports.DialogDeviceActivateComponent = DialogDeviceActivateComponent;
