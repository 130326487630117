"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var api_service_1 = require("../../core/api.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var password_service_1 = require("../../auth/services/password.service");
var multiadmin_service_1 = require("../../core/multiadmin.service");
var DialogMultiAdminuserSettingsComponent = /** @class */ (function () {
    function DialogMultiAdminuserSettingsComponent(api, activeModal, password) {
        this.api = api;
        this.activeModal = activeModal;
        this.password = password;
    }
    DialogMultiAdminuserSettingsComponent.prototype.ngOnInit = function () {
        this.spinner = false;
        this.role = this.child.roles[0];
        this.rmPurge = this.child.user_status_str === 'email sent' ? '1' : '0';
    };
    DialogMultiAdminuserSettingsComponent.prototype.removeActiveUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.multiadminService.deleteProvisionedUser(this.child.user_id, this.child.roles)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.api.execute('multiuserupdate', {
                                action: this.child.user_status_str === 'email sent' ? 'suspenduser' : 'remove',
                                email: this.child.email,
                                is_purge: parseInt(this.rmPurge, 10)
                            })];
                    case 3:
                        _a.sent();
                        this.activeModal.close(models_1.ModalCloseStates.REMOVED);
                        return [3 /*break*/, 5];
                    case 4:
                        ex_1 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    DialogMultiAdminuserSettingsComponent.prototype.removeProvisioningUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.multiadminService.deleteProvisioningUser(this.child.job_id)];
                    case 2:
                        _a.sent();
                        this.activeModal.close(models_1.ModalCloseStates.REMOVED);
                        return [3 /*break*/, 4];
                    case 3:
                        ex_2 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DialogMultiAdminuserSettingsComponent.prototype.changePassword = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        if (!this.pass1 || !this.pass2 || !this.parentPass) {
                            this.errcode = new models_1.ErrCode(8305);
                            this.spinner = false;
                            return [2 /*return*/];
                        }
                        if (this.pass1 && !this.password.isPasswordStrong(this.pass1)) {
                            this.errcode = new models_1.ErrCode(8304);
                            this.spinner = false;
                            return [2 /*return*/];
                        }
                        if (this.pass1 && this.pass2 && this.parentPass) {
                            if (this.pass1 != this.pass2) {
                                this.errcode = new models_1.ErrCode(8121);
                                this.spinner = false;
                                return [2 /*return*/];
                            }
                        }
                        return [4 /*yield*/, this.password.resetPasswordMultiUser(this.child.user_id, this.parentPass, this.pass1)];
                    case 2:
                        _a.sent();
                        this.activeModal.close(models_1.ModalCloseStates.PASSWORD_UPDATED);
                        return [3 /*break*/, 4];
                    case 3:
                        ex_3 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_3);
                        return [3 /*break*/, 4];
                    case 4:
                        this.spinner = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogMultiAdminuserSettingsComponent.prototype.removeUserTeamInvite = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, ex_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.multiadminService.deleteProvisioningUser(this.child.job_id)];
                    case 2:
                        result = _a.sent();
                        if (result.success) {
                            this.activeModal.close(models_1.ModalCloseStates.REMOVED);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        ex_4 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_4);
                        return [3 /*break*/, 4];
                    case 4:
                        this.spinner = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogMultiAdminuserSettingsComponent.prototype.updateUserRole = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, ex_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.errcode = null;
                        this.spinner = true;
                        if (this.role === this.child.roles[0]) {
                            this.errcode = new models_1.ErrCode(8123, "This user is already assigned to this role, please select a new one.");
                            this.spinner = false;
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.multiadminService.updateUserRole(this.child.user_id, this.child.roles[0], this.role)];
                    case 2:
                        result = _a.sent();
                        if (result.success) {
                            this.activeModal.close(models_1.ModalCloseStates.USER_ROLE_UPDATED);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        ex_5 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_5);
                        return [3 /*break*/, 4];
                    case 4:
                        this.spinner = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    return DialogMultiAdminuserSettingsComponent;
}());
exports.DialogMultiAdminuserSettingsComponent = DialogMultiAdminuserSettingsComponent;
