"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var forms_1 = require("@angular/forms");
var support_ticket_service_1 = require("../services/support-ticket.service");
var base64_service_1 = require("../../core/base64.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var api_service_1 = require("../../core/api.service");
var environment_1 = require("../../../environments/environment");
var blend_service_1 = require("../../shared/services/blend.service");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var delete_reasons_model_1 = require("src/app/shared/models/delete-reasons.model");
var sku_service_1 = require("src/app/shared/services/sku.service");
var DialogCancelComponent = /** @class */ (function () {
    function DialogCancelComponent(activeModal, api, base64, fb, ticket, blendService, skuService) {
        this.activeModal = activeModal;
        this.api = api;
        this.base64 = base64;
        this.fb = fb;
        this.ticket = ticket;
        this.blendService = blendService;
        this.skuService = skuService;
        this.deleteReasons = delete_reasons_model_1.DELETE_REASONS;
    }
    DialogCancelComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.isAccthold) {
            this.createAcctHoldForm();
            return;
        }
        if (this.user) {
            this.paidUser = this.user.is_pro === 1;
            if (this.sub) {
                this.sub.unsubscribe();
            }
        }
        else {
            this.sub = this.store
                .pipe(store_1.select(fromRoot.getAuthUser))
                .subscribe(function (data) {
                _this.user = data;
                _this.paidUser = _this.user.is_pro === 1;
                if (_this.sub) {
                    _this.sub.unsubscribe();
                }
            });
        }
        this.createForm();
    };
    DialogCancelComponent.prototype.createForm = function () {
        this.cancelForm = this.fb.group({
            name: [
                this.base64.decode(this.user.display_name),
                forms_1.Validators.required,
            ],
            email: [this.user.email, forms_1.Validators.required],
            mainReason: ['', forms_1.Validators.required],
            specificReason: [''],
            description: [''],
            confirm: ['', forms_1.Validators.required],
        });
        this.setupValidators();
    };
    DialogCancelComponent.prototype.createAcctHoldForm = function () {
        this.cancelForm = this.fb.group({
            mainReason: ['', forms_1.Validators.required],
            specificReason: [''],
            description: [''],
            confirm: ['', forms_1.Validators.required],
        });
        this.setupValidators();
    };
    DialogCancelComponent.prototype.setupValidators = function () {
        var _this = this;
        this.cancelForm.get('mainReason').valueChanges.subscribe(function () {
            if (_this.cancelForm.get('mainReason').value == 'Other') {
                _this.cancelForm.controls['description'].setValidators([forms_1.Validators.required]);
                _this.cancelForm.controls['specificReason'].clearValidators();
            }
            else {
                _this.cancelForm.controls['description'].clearValidators();
                _this.cancelForm.controls['specificReason'].setValidators(forms_1.Validators.required);
                _this.cancelForm.controls['specificReason'].setValue('');
            }
            _this.cancelForm.controls['description'].updateValueAndValidity();
            _this.cancelForm.controls['specificReason'].updateValueAndValidity();
        });
        this.cancelForm.get('specificReason').valueChanges.subscribe(function (val) {
            if (_this.cancelForm.get('mainReason').value == 'Other' || _this.cancelForm.get('specificReason').value == 'Other') {
                _this.cancelForm.controls['description'].setValidators([forms_1.Validators.required]);
            }
            else {
                _this.cancelForm.controls['description'].clearValidators();
            }
            _this.cancelForm.controls['description'].updateValueAndValidity();
        });
    };
    DialogCancelComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var reason, specificReason, seats, plan, isTialUser, ex_1, isTrialUserText, description, postData, ex_2, ex_3;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        reason = this.cancelForm.get('mainReason').value;
                        specificReason = this.cancelForm.get('specificReason').value || 'N/A';
                        return [4 /*yield*/, this.skuService.getPanLimit()];
                    case 1:
                        seats = _a.sent();
                        return [4 /*yield*/, this.skuService.getSku()];
                    case 2:
                        plan = _a.sent();
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this.blendService.track(models_1.BlendEvent.DELETE_ACCOUNT, {
                                    reason: reason,
                                    specific_reason: specificReason,
                                    selections: '2024-update',
                                    plan: plan,
                                    seats: seats
                                });
                                setTimeout(function () {
                                    resolve(true);
                                }, 3000); //wait for 3 sec
                            })];
                    case 3:
                        _a.sent();
                        if (!this.paidUser) return [3 /*break*/, 9];
                        isTialUser = this.user.is_on_trial;
                        if (!isTialUser) return [3 /*break*/, 7];
                        _a.label = 4;
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this.api.execute('cancelzuorasubscription', {})];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        ex_1 = _a.sent();
                        this.spinner = false;
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        return [3 /*break*/, 7];
                    case 7: 
                    //Call the Zproxy API and disable the auto-renewal when user fills out the cancellation form.
                    return [4 /*yield*/, this.api.execute('userinfoset', { is_disableautorenew: 1 })];
                    case 8:
                        //Call the Zproxy API and disable the auto-renewal when user fills out the cancellation form.
                        _a.sent();
                        if (environment_1.environment.production) {
                            isTrialUserText = isTialUser ? 'Is Trial User: Yes' : '';
                            description = [
                                'USER ID: ',
                                this.user.uid,
                                isTrialUserText,
                                "Confirm cancellation: " + (this.cancelForm.get('confirm').value ? 'true' : 'false'),
                                '--------',
                                "Specific reason: " + specificReason,
                                '--------',
                                'Cancellation request:',
                                this.cancelForm.get('description').value,
                            ].join('\n\n');
                            postData = {
                                z_requester: this.cancelForm.get('email').value,
                                z_name: this.cancelForm.get('name').value,
                                z_subject: isTialUser
                                    ? 'Trial User - Cancellation - ' + reason
                                    : 'Cancellation - ' + reason,
                                z_description: description,
                            };
                            this.ticket.sendSupportTicket(postData);
                        }
                        else {
                            window.location.href =
                                'https://www.sync.com/support/?thank=you';
                        }
                        return [3 /*break*/, 17];
                    case 9:
                        if (!this.isAccthold) return [3 /*break*/, 14];
                        _a.label = 10;
                    case 10:
                        _a.trys.push([10, 12, , 13]);
                        return [4 /*yield*/, this.api.send('acctcancel', {
                                source: 'accthold',
                                user: this.userId,
                                p: this.plan,
                                sig: this.sig,
                                subject: this.base64.encode('Cancellation - ' + reason),
                                message: this.base64.encode('Specific reason - ' + specificReason + '. message - ' +
                                    this.cancelForm.get('description').value),
                            })];
                    case 11:
                        _a.sent();
                        window.location.href = 'https://www.sync.com/support/?thank=you';
                        return [3 /*break*/, 13];
                    case 12:
                        ex_2 = _a.sent();
                        this.spinner = false;
                        this.errcode = models_1.ErrCode.fromException(ex_2);
                        return [3 /*break*/, 13];
                    case 13: return [3 /*break*/, 17];
                    case 14:
                        _a.trys.push([14, 16, , 17]);
                        return [4 /*yield*/, this.api.execute('acctcancel', {
                                source: 'free',
                                subject: this.base64.encode('Cancellation - ' + reason),
                                message: this.base64.encode('Specific reason - ' + specificReason + '. message - ' +
                                    this.cancelForm.get('description').value),
                            })];
                    case 15:
                        _a.sent();
                        window.location.href =
                            'https://www.sync.com/support/?thank=you';
                        return [3 /*break*/, 17];
                    case 16:
                        ex_3 = _a.sent();
                        this.spinner = false;
                        this.errcode = models_1.ErrCode.fromException(ex_3);
                        return [3 /*break*/, 17];
                    case 17: return [2 /*return*/];
                }
            });
        });
    };
    return DialogCancelComponent;
}());
exports.DialogCancelComponent = DialogCancelComponent;
