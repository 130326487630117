"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var file_item_service_1 = require("../file-item.service");
var dialog_report_link_component_1 = require("../../link-consume/dialog-report-link/dialog-report-link.component");
var logger_service_1 = require("../../core/logger.service");
var link_file_list_service_1 = require("../../link-consume/services/link-file-list.service");
var sync_cookie_service_1 = require("../../core/sync-cookie.service");
var router_1 = require("@angular/router");
var PreviewApplinkComponent = /** @class */ (function () {
    function PreviewApplinkComponent(modalService, fileItemService, loggerService, linkPathListService, syncCookieService, route, router) {
        this.modalService = modalService;
        this.fileItemService = fileItemService;
        this.loggerService = loggerService;
        this.linkPathListService = linkPathListService;
        this.syncCookieService = syncCookieService;
        this.route = route;
        this.router = router;
        this.list = [];
        this.initialized = false;
        this.notice = 0;
        this.upsell = false;
        this.isPro = 0;
        this.isBusiness = 1; // set default 1 so that it's not shown immediately
    }
    PreviewApplinkComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.locId = this.route.snapshot.params['id'];
        // this.ctx = this.$stateParams.ctx;
        var dispatched = false;
        // const rawKey = this.LinkPathList.sanitizeKey(
        //     (this.$stateParams.key)
        //         ? this.$stateParams.key
        //         : this.$location.hash()
        // );
        if (!this.type) {
            this.type = this.route.snapshot.params['type'];
        }
        var linkPass = this.syncCookieService.getLinkPassword(this.route.snapshot.params['cachekey']);
        this.initialized = false;
        this.subscription = this.linkPathListService
            .getSubscription()
            .subscribe(function (data) {
            try {
                if (data.error) {
                    if (data.error instanceof models_1.ErrCode &&
                        data.error.code == 8018) {
                        _this.loggerService.warn('Password is missing or expired');
                        _this.router.navigate(['.'], { relativeTo: _this.route.parent });
                    }
                    else {
                        // this.errcode = data.error;
                    }
                    _this.loggerService.e('An error was received loading external link preview', data.error);
                }
                if (data &&
                    data.pathlist &&
                    data.pathlist.length &&
                    data.loaded &&
                    data.sorted) {
                    _this.list = data.pathlist.filter(function (val) {
                        return _this.fileItemService.isImage(val);
                    });
                    var item = data.pathlist.filter(function (value) {
                        if (value.loc_id == _this.route.snapshot.params['id']) {
                            return true;
                        }
                    });
                    if (item.length == 0) {
                        throw new models_1.ErrCode(1804);
                    }
                    else if (item.length == 1) {
                        _this.item = item[0];
                    }
                    else {
                        throw new models_1.ErrCode(1000, 'This file listing has an error');
                    }
                    if (_this.type != 'default' &&
                        !_this.fileItemService.isPreviewable(_this.item)) {
                        _this.type = 'default';
                        _this.notice = 1;
                    }
                    _this.isPro = data.is_pro;
                    _this.isBusiness = data.is_business;
                    _this.initialized = true;
                }
                else if (!dispatched) {
                    dispatched = true;
                    var input = new models_1.LinkPathListApiInput();
                    input.sync_id = _this.route.snapshot.queryParams['sync_id'];
                    input.publink_id = _this.route.snapshot.params['cachekey'];
                    input.passwordlock = linkPass;
                    console.log('input in preview:', input);
                    _this.linkPathListService.dispatchApp(input);
                    _this.loggerService.info('Dispatched App');
                }
                //get white label attributes
                if (data.loaded && data.sorted) {
                    if (data.image_cachekey) {
                        _this.isWhiteLabel = true;
                        _this.linkTextColor = data.link_text_color;
                        _this.buttonPrimaryColor = data.button_primary_color;
                        _this.buttonTextColor = data.button_text_color;
                    }
                }
            }
            catch (ex) {
                _this.loggerService.e('Cannot process applink', ex);
                // This results in an unknown type and nothing is rendered for errors that occur in this component
                _this.type = 'error';
                _this.errcode = models_1.ErrCode.fromException(ex);
                _this.initialized = true;
            }
        });
    };
    PreviewApplinkComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    PreviewApplinkComponent.prototype.onStateChange = function (event) {
        this.type = event.type;
        this.upsell = event.upsell;
    };
    PreviewApplinkComponent.prototype.showReportDialog = function () {
        var ref = this.modalService.open(dialog_report_link_component_1.DialogReportLinkComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.linkID = this.route.snapshot.params['cachekey'];
    };
    return PreviewApplinkComponent;
}());
exports.PreviewApplinkComponent = PreviewApplinkComponent;
