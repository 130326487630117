"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var fromRoot = require("./reducers");
var store_1 = require("@ngrx/store");
var environment_1 = require("../environments/environment");
var platform_browser_1 = require("@angular/platform-browser");
var version_service_1 = require("./shared/services/version.service");
var AppComponent = /** @class */ (function () {
    function AppComponent(store, sanitizer, versionService) {
        this.store = store;
        this.sanitizer = sanitizer;
        this.versionService = versionService;
        this.title = 'syncCp';
    }
    AppComponent.prototype.ngOnInit = function () {
        // This needs to be uncommented when we fix the refresh prompt
        // setInterval(async () => {
        //     // Check for version change recursively
        //     this.versionChanged = await this.versionService.loadVersion();
        // }, 150000); // 150000 milliseconds (adjust as needed)
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        this.store
            .pipe(store_1.select(fromRoot.getAuthUser))
            .subscribe(function (data) {
            var synccp = document.getElementById('synccpIframe');
            if (synccp) {
                var storageItems = { localstorage: tslib_1.__assign({}, localStorage), cookie: document.cookie };
                synccp.contentWindow.postMessage(storageItems, environment_1.environment.syncCpHost);
            }
        });
    };
    return AppComponent;
}());
exports.AppComponent = AppComponent;
