"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Device = /** @class */ (function () {
    function Device(params) {
        this.cre_servtime = 0;
        this.del_servtime = 0;
        this.devicetype_name = '';
        this.devicetype_os_id = 0;
        this.id = 0;
        this.is_deleted = 0;
        this.is_desktop = 0;
        this.is_mobile = 0;
        this.is_suspended = false;
        this.lastaction_servtime = 0;
        this.name = '';
        this.needs_activate = 0;
        this.permission = {
            perCHANGEPASS: 'Change Password'
        };
        this.version = '';
        console.log(params);
        if (params) {
            this.deserialize(params);
        }
    }
    Device.prototype.deserialize = function (params) {
        for (var _i = 0, _a = Object.entries(params); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], val = _b[1];
            if (key in this) {
                this[key] = val;
            }
        }
        if (this.version == '0.10.0' && this.is_mobile) {
            this.version = '1.1.0';
        }
        return this;
    };
    return Device;
}());
exports.Device = Device;
