"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var PathListApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(PathListApiOutput, _super);
    function PathListApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PathListApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.PathListApiOutput = PathListApiOutput;
var PathListApiInput = /** @class */ (function () {
    function PathListApiInput() {
    }
    return PathListApiInput;
}());
exports.PathListApiInput = PathListApiInput;
