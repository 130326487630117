"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// This mapping file maps old CP route path to new CP route path.
exports.NewRoutesMapping = {
    '/account/info': '/settings/profile',
    '/events': '/settings/activitylogs',
    '/account/devices': '/settings/devices',
    '/account/security': '/settings/security',
    '/account/billing': '/settings/billing',
    '/files': '/files'
};
