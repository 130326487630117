"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AuthUserPassword = /** @class */ (function () {
    function AuthUserPassword(username, password, twofacode, cachekey, // used for shares
    wrapKey // used for shares
    ) {
        this.username = username;
        this.password = password;
        this.twofacode = twofacode;
        this.cachekey = cachekey;
        this.wrapKey = wrapKey;
    }
    return AuthUserPassword;
}());
exports.AuthUserPassword = AuthUserPassword;
