"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CORE_CLEAR = '[Core] Store clear';
exports.CORE_SAVE_VALUE = '[Core] Save value';
var StoreClearAction = /** @class */ (function () {
    function StoreClearAction() {
        this.type = exports.CORE_CLEAR;
    }
    return StoreClearAction;
}());
exports.StoreClearAction = StoreClearAction;
var SetValueAction = /** @class */ (function () {
    function SetValueAction(payload) {
        this.payload = payload;
        this.type = exports.CORE_SAVE_VALUE;
    }
    return SetValueAction;
}());
exports.SetValueAction = SetValueAction;
