"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var TrimDirective = /** @class */ (function () {
    function TrimDirective(renderer, elementRef, ngModel, control) {
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.ngModel = ngModel;
        this.control = control;
    }
    TrimDirective.prototype.onBlur = function () {
        this.trimValue();
    };
    TrimDirective.prototype.onChange = function () {
        this.trimValue();
    };
    TrimDirective.prototype.trimValue = function () {
        var value = this.ngModel ? this.ngModel.model : (this.control ? this.control.value : this.elementRef.nativeElement.value);
        if (value) {
            value = value.trim();
            this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
            this.renderer.setAttribute(this.elementRef.nativeElement, 'value', value);
            if (this.ngModel) {
                this.ngModel.update.emit(value);
            }
            if (typeof this.control.value === 'string') {
                this.control.control.setValue(value);
            }
        }
    };
    return TrimDirective;
}());
exports.TrimDirective = TrimDirective;
