"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("../core/api.service");
var sync_crypt_service_1 = require("../core/crypt/sync-crypt.service");
var logger_service_1 = require("../core/logger.service");
var notifications_service_1 = require("../core/notifications.service");
var broadcast_service_1 = require("../shared/services/broadcast.service");
var batch_action_service_1 = require("./batch-action.service");
var models_1 = require("../shared/models");
var i0 = require("@angular/core");
var i1 = require("../shared/services/broadcast.service");
var i2 = require("../core/logger.service");
var i3 = require("../core/crypt/sync-crypt.service");
var i4 = require("../core/notifications.service");
var i5 = require("../core/api.service");
var BatchCopyService = /** @class */ (function (_super) {
    tslib_1.__extends(BatchCopyService, _super);
    function BatchCopyService(broadcastService, loggerService, syncCryptService, notificationsService, apiService) {
        var _this = _super.call(this, broadcastService, loggerService, syncCryptService, notificationsService, apiService) || this;
        _this.broadcastService = broadcastService;
        _this.loggerService = loggerService;
        _this.syncCryptService = syncCryptService;
        _this.notificationsService = notificationsService;
        _this.apiService = apiService;
        return _this;
    }
    BatchCopyService.prototype.execute = function (syncIds, destSyncId, delOld, duplicateNameMap) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var syncArray;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ACTION = this.ACT_COPY;
                        this.loggerService.info("batchCopy(" + syncIds.toString() + ", " + destSyncId);
                        this.loggerService.info(delOld ? 'Move' : 'Copy');
                        this.view.globalCancel = false;
                        this.view.completedBatch = [];
                        if (!syncIds.length) {
                            return [2 /*return*/];
                        }
                        syncArray = syncIds.slice();
                        return [4 /*yield*/, this._copyFolder(syncArray, destSyncId, delOld ? models_1.WebpathActionType.MOVE : models_1.WebpathActionType.COPY, duplicateNameMap)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    BatchCopyService.prototype._copyFolder = function (syncIds, destSyncId, action, duplicateNameMap) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var syncHash, syncIdList, syncArrayResolve, syncId, data, key, errData_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        syncHash = {};
                        syncIdList = [], syncArrayResolve = syncIds.slice(), syncId = syncIds.pop();
                        this.resetProgress();
                        this.view.spinner = syncId;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        return [4 /*yield*/, this.dumpDirs(syncId)];
                    case 2:
                        data = _a.sent();
                        syncHash = data.sync_ids;
                        if (!syncHash[syncId]) {
                            this.loggerService.error('Could not find the sync id in the batchdumpdiers result');
                            throw { errcode: 9000 };
                        }
                        syncHash[syncId].new_sync_id = destSyncId;
                        this.view.total = data.cnt;
                        for (key in syncHash) {
                            syncIdList.push(syncHash[key]);
                        }
                        return [4 /*yield*/, this._copyFolderContents(syncIdList, 0, action, duplicateNameMap)];
                    case 3:
                        _a.sent();
                        if (!syncIds.length) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._copyFolder(syncIds, destSyncId, action, duplicateNameMap)];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        this.view.spinner = 0;
                        return [2 /*return*/, syncArrayResolve];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        errData_1 = _a.sent();
                        this.loggerService.handleError('_copyFolder', errData_1);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    BatchCopyService.prototype._copyFolderContents = function (syncIds, idx, action, duplicateNameMap) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var item, syncId, data, _a, i, len, errData_2;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!syncIds[idx]) {
                            this.loggerService.error('idx not found, resolving');
                            return [2 /*return*/, syncIds];
                        }
                        if (this.view.globalCancel) {
                            this.handleCancel();
                            return [2 /*return*/];
                        }
                        item = syncIds[idx];
                        syncId = parseInt(item.sync_id, 10);
                        if (!item.new_sync_id) {
                            this.loggerService.warn("ICopyData is incorrect " + JSON.stringify(item));
                        }
                        if (!item.nameData && duplicateNameMap) {
                            item.nameData = duplicateNameMap[syncId];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 11, , 17]);
                        if (!item.nameData) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.apiService.execute('batchcopy', {
                                sync_id: syncId,
                                sync_pid: item.new_sync_id,
                                new_name: item.nameData.new_name,
                                share_names: item.nameData.share_names,
                                is_duplicate_copy: duplicateNameMap &&
                                    Object.keys(duplicateNameMap).length > 0,
                                action: action,
                            })];
                    case 2:
                        _a = _b.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.apiService.execute('batchcopy', {
                            sync_id: syncId,
                            sync_pid: item.new_sync_id,
                            action: action,
                        })];
                    case 4:
                        _a = _b.sent();
                        _b.label = 5;
                    case 5:
                        data = _a;
                        this.updateProgress(syncId);
                        this.copyRetryAmt = 0;
                        if (item.synctype & 128 && item.synctype & 16) {
                            this.loggerService.info('batchCopy found a share root folder');
                            item.new_metaname = data.new_metaname;
                            item.new_sync_pid = data.new_sync_pid;
                        }
                        if (!data['continue']) return [3 /*break*/, 9];
                        for (i = 0, len = syncIds.length; i < len; i++) {
                            if (syncIds[i].sync_pid == syncId) {
                                syncIds[i].new_sync_id = data.new_sync_id;
                            }
                        }
                        idx++;
                        if (!!syncIds[idx]) return [3 /*break*/, 6];
                        return [2 /*return*/, syncIds];
                    case 6: return [4 /*yield*/, this._copyFolderContents(syncIds, idx, action)];
                    case 7: return [2 /*return*/, _b.sent()];
                    case 8: return [3 /*break*/, 10];
                    case 9: return [2 /*return*/, syncIds];
                    case 10: return [3 /*break*/, 17];
                    case 11:
                        errData_2 = _b.sent();
                        if (!(this.copyRetryAmt < 2 &&
                            errData_2 &&
                            typeof errData_2 == 'object')) return [3 /*break*/, 15];
                        if (!(errData_2.error_code >= 8000 && errData_2.error_code <= 8200)) return [3 /*break*/, 12];
                        return [2 /*return*/, this.loggerService.handleError('_copyFolderContents', errData_2)];
                    case 12:
                        this.copyRetryAmt++;
                        this.loggerService.warn('Retrying ' + errData_2.error_msg);
                        return [4 /*yield*/, new Promise(function (resolve) {
                                return setTimeout(function () {
                                    resolve(_this._copyFolderContents(syncIds, idx, action));
                                }, 10000);
                            })];
                    case 13:
                        _b.sent();
                        _b.label = 14;
                    case 14: return [3 /*break*/, 16];
                    case 15:
                        this.copyRetryAmt = 0;
                        this.loggerService.error(JSON.stringify(errData_2));
                        this.loggerService.handleError('_copyFolderContents', 'Copy has encountered an error,  stopping operation');
                        _b.label = 16;
                    case 16: return [3 /*break*/, 17];
                    case 17: return [2 /*return*/];
                }
            });
        });
    };
    BatchCopyService.ngInjectableDef = i0.defineInjectable({ factory: function BatchCopyService_Factory() { return new BatchCopyService(i0.inject(i1.BroadcastService), i0.inject(i2.LoggerService), i0.inject(i3.SyncCryptService), i0.inject(i4.NotificationsService), i0.inject(i5.ApiService)); }, token: BatchCopyService, providedIn: "root" });
    return BatchCopyService;
}(batch_action_service_1.BatchActionService));
exports.BatchCopyService = BatchCopyService;
