"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var api_service_1 = require("../../core/api.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var DialogDeviceRenameComponent = /** @class */ (function () {
    function DialogDeviceRenameComponent(activeModal, api) {
        this.activeModal = activeModal;
        this.api = api;
    }
    DialogDeviceRenameComponent.prototype.ngOnInit = function () {
    };
    DialogDeviceRenameComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.execute('devicerename', {
                            device_id: this.device.id,
                            devicename: this.newName
                        })];
                    case 1:
                        _a.sent();
                        this.activeModal.close(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    return DialogDeviceRenameComponent;
}());
exports.DialogDeviceRenameComponent = DialogDeviceRenameComponent;
