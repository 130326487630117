"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var DirtyOutTask = /** @class */ (function () {
    function DirtyOutTask() {
    }
    return DirtyOutTask;
}());
exports.DirtyOutTask = DirtyOutTask;
var DirtyOutTaskResult = /** @class */ (function () {
    function DirtyOutTaskResult() {
    }
    return DirtyOutTaskResult;
}());
exports.DirtyOutTaskResult = DirtyOutTaskResult;
var DirtyOutTaskDataKeyResult = /** @class */ (function (_super) {
    tslib_1.__extends(DirtyOutTaskDataKeyResult, _super);
    function DirtyOutTaskDataKeyResult() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return DirtyOutTaskDataKeyResult;
}(DirtyOutTaskResult));
exports.DirtyOutTaskDataKeyResult = DirtyOutTaskDataKeyResult;
var DirtyOutTaskShareNameResult = /** @class */ (function (_super) {
    tslib_1.__extends(DirtyOutTaskShareNameResult, _super);
    function DirtyOutTaskShareNameResult() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return DirtyOutTaskShareNameResult;
}(DirtyOutTaskResult));
exports.DirtyOutTaskShareNameResult = DirtyOutTaskShareNameResult;
