"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var notifications_service_1 = require("../../../core/notifications.service");
var validate_service_1 = require("../../../core/validate.service");
var batch_rename_service_1 = require("../../../file-actions/batch-rename.service");
var file_list_service_1 = require("../../../file-list/services/file-list.service");
var feedback_service_1 = require("../../../notifications/feedback.service");
var constants_1 = require("../../../transfer/constants");
var broadcast_service_1 = require("../../services/broadcast.service");
var PathRenameFormComponent = /** @class */ (function () {
    function PathRenameFormComponent(fileListService, feedbackService, validateService, notificationsService, batchRenameService, broadcastService) {
        this.fileListService = fileListService;
        this.feedbackService = feedbackService;
        this.validateService = validateService;
        this.notificationsService = notificationsService;
        this.batchRenameService = batchRenameService;
        this.broadcastService = broadcastService;
        this.maxFileLength = constants_1.Constants.MAX_FILE_NAME_LEN;
        this.renameform = new forms_1.FormGroup({
            originalname: new forms_1.FormControl('', [
                forms_1.Validators.required,
                forms_1.Validators.maxLength(this.maxFileLength),
                this.leadingSpace.bind(this),
                this.trailingChar.bind(this),
                this.reservedNames.bind(this),
                this.illegalCharacters.bind(this),
                this.exist.bind(this),
            ]),
        });
    }
    PathRenameFormComponent.prototype.leadingSpace = function (control) {
        var fileName = control.value;
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        return fileName && fileName.charAt(0) !== ' '
            ? null
            : { leadingSpace: true };
    };
    PathRenameFormComponent.prototype.trailingChar = function (control) {
        var fileName = control.value;
        var lastChar = fileName ? fileName.charAt(fileName.length - 1) : '';
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        return !(lastChar === '.' || lastChar === ' ')
            ? null
            : { trailingChar: true };
    };
    PathRenameFormComponent.prototype.reservedNames = function (control) {
        var fileName = control.value;
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        return !/^(CLOCK\$|PRN|AUX|NUL|CON|COM[0-9]|LPT[0-9])$/i.test(fileName) &&
            fileName.toLowerCase() !== '.syncinfo' &&
            fileName.toLowerCase() !== 'thumbs.db' &&
            fileName.toLowerCase() !== 'desktop.ini' &&
            fileName.toLowerCase() !== 'sync.cache' &&
            fileName.toLowerCase() !== '$recycle.bin' &&
            fileName.indexOf('.~') !== 0 &&
            fileName.indexOf('~') !== 0 &&
            fileName.indexOf('~$') === -1 &&
            !/^(PRN\.[a-z0-9]+|AUX\.[a-z0-9]+|NUL\.[a-z0-9]+|CON\.[a-z0-9]+|COM[0-9]\.[a-z0-9]+|LPT[0-9]\.[a-z0-9]+)$/i.test(fileName)
            ? null
            : { reserved: true };
    };
    PathRenameFormComponent.prototype.illegalCharacters = function (control) {
        var fileName = control.value;
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        return this.validateService.illegalCharacters(fileName) ? null : { 'illegal': true };
    };
    PathRenameFormComponent.prototype.exist = function (control) {
        var fileName = control.value;
        if (!this.pathlist) {
            return true;
        }
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        var found = this.pathlist.filter(function (val) {
            return val.name.toLowerCase() == fileName.toLowerCase();
        });
        return !(found && found.length > 0 && control.dirty) ? null : { exist: true };
    };
    PathRenameFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.batchView = this.batchRenameService.view;
        this.extensionIndex = this.item.name.lastIndexOf('.');
        if (this.extensionIndex > -1 && this.item.type === 'file') {
            this.originalname = this.item.name.substring(0, this.extensionIndex);
            this.fileExtension = this.item.name.substring(this.extensionIndex + 1, this.item.name.length);
        }
        else {
            this.originalname = this.item.name;
        }
        this.renameform.patchValue({ originalname: this.originalname });
        this.broadcastService.on('event:hide.tools').subscribe(function () {
            setTimeout(function () {
                _this.reset();
            }, 0);
        });
        this.fileListService.getListSubscription().subscribe(function (data) {
            if (data && data.pathlist.length) {
                _this.pathlist = data.pathlist;
            }
        });
    };
    PathRenameFormComponent.prototype.reset = function () {
        this.batchView.total = 0;
        this.batchView.completed = 0;
        this.batchView.percent = 0;
        this.item.rename = false;
        this.renameform.patchValue({ originalname: null });
        this.renameform.markAsPristine();
        this.spinner = false;
    };
    PathRenameFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, error_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, , 6]);
                        if (!this.renameform.valid || !this.renameform.dirty) {
                            return [2 /*return*/, false];
                        }
                        if (this.renameform.value.originalname.toLowerCase() == this.item.name.toLowerCase()) {
                            this.renameform.controls.originalname.setErrors({ sameCase: true });
                            this.renameform.setErrors({ 'invalid': true });
                            return [2 /*return*/, false];
                        }
                        if (this.extensionIndex > -1 && this.item.type === 'file') {
                            this.renameform.controls.originalname.setValue([this.renameform.controls.originalname.value, this.fileExtension].join('.'));
                        }
                        this.spinner = true;
                        this.notificationsService.stopNotificationLoop();
                        if (!(this.item.type == 'dir')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.batchRenameService.execute(this.item.sync_id, this.item.pid, this.renameform.controls.originalname.value)];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.batchRenameService.executeFile(this.item.sync_id, this.item.pid, this.renameform.controls.originalname.value)];
                    case 3:
                        _a = _b.sent();
                        _b.label = 4;
                    case 4:
                        _a;
                        this.reset();
                        this.notificationsService.startNotificationLoop();
                        this.broadcastService.broadcast('event:file-list.reload');
                        return [3 /*break*/, 6];
                    case 5:
                        error_1 = _b.sent();
                        this.reset();
                        this.feedbackService.setFeedback('danger', "An error occurred renaming the " + (this.item.type == 'dir' ? 'folder' : 'file'), 10000);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return PathRenameFormComponent;
}());
exports.PathRenameFormComponent = PathRenameFormComponent;
