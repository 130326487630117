"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var BytesToSizePipe = /** @class */ (function () {
    function BytesToSizePipe() {
    }
    BytesToSizePipe.prototype.transform = function (bytes, args) {
        if (!bytes && bytes !== 0) {
            return '';
        }
        // var sizes = ['bytes', 'KB', 'MB', 'GB'];
        var sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        if (bytes === 0) {
            return '0 bytes';
        }
        var x = Math.log(bytes) / Math.log(1024);
        var i = Math.floor(x);
        if (i === 0) {
            return (bytes / Math.pow(1024, i)) + ' ' + sizes[i];
        }
        return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
    };
    return BytesToSizePipe;
}());
exports.BytesToSizePipe = BytesToSizePipe;
