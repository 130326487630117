"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESET = '[LinkList] Reset';
exports.REFRESH = '[LinkList] Refresh';
exports.ERROR = '[LinkList] Error';
exports.SUCCESS = '[LinkList] Success';
exports.SORT = '[LinkList] Sort';
var LinkListSuccessAction = /** @class */ (function () {
    function LinkListSuccessAction(payload) {
        this.payload = payload;
        this.type = exports.SUCCESS;
    }
    return LinkListSuccessAction;
}());
exports.LinkListSuccessAction = LinkListSuccessAction;
var LinkListResetAction = /** @class */ (function () {
    function LinkListResetAction() {
        this.type = exports.RESET;
    }
    return LinkListResetAction;
}());
exports.LinkListResetAction = LinkListResetAction;
var LinkListRefreshAction = /** @class */ (function () {
    function LinkListRefreshAction() {
        this.type = exports.REFRESH;
    }
    return LinkListRefreshAction;
}());
exports.LinkListRefreshAction = LinkListRefreshAction;
var LinkListErrorAction = /** @class */ (function () {
    function LinkListErrorAction(payload) {
        this.payload = payload;
        this.type = exports.ERROR;
    }
    return LinkListErrorAction;
}());
exports.LinkListErrorAction = LinkListErrorAction;
var LinkListSortAction = /** @class */ (function () {
    function LinkListSortAction(payload) {
        this.payload = payload;
        this.type = exports.SORT;
    }
    return LinkListSortAction;
}());
exports.LinkListSortAction = LinkListSortAction;
