"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var operators_1 = require("rxjs/operators");
var i0 = require("@angular/core");
exports.DEFAULT_TIMEOUT = new core_1.InjectionToken('defaultTimeout');
var TimeoutInterceptor = /** @class */ (function () {
    function TimeoutInterceptor(defaultTimeout) {
        this.defaultTimeout = defaultTimeout;
    }
    TimeoutInterceptor.prototype.intercept = function (req, next) {
        var timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
        var timeoutValueNumeric = Number(timeoutValue);
        return next.handle(req).pipe(operators_1.timeout(timeoutValueNumeric));
    };
    TimeoutInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function TimeoutInterceptor_Factory() { return new TimeoutInterceptor(i0.inject(exports.DEFAULT_TIMEOUT)); }, token: TimeoutInterceptor, providedIn: "root" });
    return TimeoutInterceptor;
}());
exports.TimeoutInterceptor = TimeoutInterceptor;
