"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_link_component_1 = require("../links/dialog-link/dialog-link.component");
var store_1 = require("@ngrx/store");
var LinkListActions = require("../actions/link-list.actions");
var i0 = require("@angular/core");
var i1 = require("@ng-bootstrap/ng-bootstrap");
var i2 = require("@ngrx/store");
var CompatService = /** @class */ (function () {
    function CompatService(modalService, store) {
        this.modalService = modalService;
        this.store = store;
    }
    CompatService.prototype.showLinkDialog = function (item, openState) {
        var _this = this;
        var ref = this.modalService.open(dialog_link_component_1.DialogLinkComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.item = item;
        ref.componentInstance.openState = openState || 1;
        ref.result.then(function (result) {
            if (result === true) {
                _this.store.dispatch(new LinkListActions.LinkListRefreshAction());
            }
        });
    };
    CompatService.prototype.showLinkDialogPromise = function (item, openState) {
        var ref = this.modalService.open(dialog_link_component_1.DialogLinkComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.item = item;
        ref.componentInstance.openState = openState || 1;
        return ref.result;
    };
    CompatService.ngInjectableDef = i0.defineInjectable({ factory: function CompatService_Factory() { return new CompatService(i0.inject(i1.NgbModal), i0.inject(i2.Store)); }, token: CompatService, providedIn: "root" });
    return CompatService;
}());
exports.CompatService = CompatService;
