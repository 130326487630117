"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var LinkListApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(LinkListApiOutput, _super);
    function LinkListApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LinkListApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.LinkListApiOutput = LinkListApiOutput;
var LinkListApiInput = /** @class */ (function () {
    function LinkListApiInput() {
    }
    return LinkListApiInput;
}());
exports.LinkListApiInput = LinkListApiInput;
