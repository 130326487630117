"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("../../core/api.service");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var FileListActions = require("../../actions/file-list.actions");
var models_1 = require("../../shared/models");
var url_service_1 = require("../../core/url.service");
var dirty_out_service_1 = require("../../core/crypt/dirty-out.service");
var rxjs_1 = require("rxjs");
var logger_service_1 = require("../../core/logger.service");
var file_item_service_1 = require("../../file/file-item.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var router_1 = require("@angular/router");
var user_service_1 = require("../../core/user.service");
var operators_1 = require("rxjs/operators");
var i0 = require("@angular/core");
var i1 = require("../../core/api.service");
var i2 = require("../../core/crypt/sync-crypt.service");
var i3 = require("../../core/crypt/dirty-out.service");
var i4 = require("@ngrx/store");
var i5 = require("../../core/url.service");
var i6 = require("../../core/logger.service");
var i7 = require("../../file/file-item.service");
var i8 = require("@ng-bootstrap/ng-bootstrap");
var i9 = require("@angular/router");
var i10 = require("../../core/user.service");
var FileListService = /** @class */ (function () {
    function FileListService(api, crypt, dirtyOut, store, url, log, fileItemService, modalService, router, userService) {
        this.api = api;
        this.crypt = crypt;
        this.dirtyOut = dirtyOut;
        this.store = store;
        this.url = url;
        this.log = log;
        this.fileItemService = fileItemService;
        this.modalService = modalService;
        this.router = router;
        this.userService = userService;
        this.totalFavoriteSubject = new rxjs_1.BehaviorSubject(0);
        this.total_favorite$ = this.totalFavoriteSubject.asObservable();
    }
    FileListService.prototype.reload = function () {
        this.store.dispatch(new FileListActions.FileListReloadAction());
    };
    FileListService.prototype.getData = function (syncId, histId, showdeleted, is_vault) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pathlist, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.load(syncId, histId, showdeleted, '0', [], is_vault)];
                    case 1:
                        pathlist = _a.sent();
                        return [2 /*return*/, pathlist];
                    case 2:
                        e_1 = _a.sent();
                        throw models_1.ErrCode.fromException(e_1);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    FileListService.prototype.getPathList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pathlist;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.store.pipe(store_1.select(fromRoot.fileSelector), operators_1.take(1)).toPromise()];
                    case 1:
                        pathlist = _a.sent();
                        return [2 /*return*/, pathlist];
                }
            });
        });
    };
    // legacy helper
    FileListService.prototype.getListSubscription = function () {
        return this.store.select(fromRoot.getFileListState);
    };
    // legacy helper
    FileListService.prototype.getCwdSubscription = function () {
        return this.store.select(fromRoot.getFileListCwd);
    };
    FileListService.prototype.sortList = function (predicate, reverse) {
        var sortData = {
            direction: (reverse) ? 'desc' : 'asc',
            active: predicate
        };
        this.store.dispatch(new FileListActions.FileListSortAction(sortData));
    };
    FileListService.prototype.getListTypeSubscription = function () {
    };
    // legacy helper
    FileListService.prototype.dispatch = function (input) {
        this.store.dispatch(new FileListActions.FileListRefreshAction(input));
    };
    FileListService.prototype.reset = function () {
        this.store.dispatch(new FileListActions.FileListResetAction());
    };
    FileListService.prototype.getDataNoState = function (syncId, offset, pathlist) {
        if (pathlist === void 0) { pathlist = []; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var d, _a, i, len, _b, _c;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.api.execute('pathlist', {
                            showdeleted: 0,
                            hist_id: 0,
                            sync_id: syncId,
                            offset_metaname_digest: offset
                        })];
                    case 1:
                        d = _d.sent();
                        if (!d.cwd) return [3 /*break*/, 3];
                        _a = d;
                        return [4 /*yield*/, this.fileItemService.format(d.cwd)];
                    case 2:
                        _a.cwd = _d.sent();
                        _d.label = 3;
                    case 3:
                        if (!(d.pathlist && d.pathlist.length)) return [3 /*break*/, 9];
                        i = 0, len = d.pathlist.length;
                        _d.label = 4;
                    case 4:
                        if (!(i < len)) return [3 /*break*/, 7];
                        _c = (_b = pathlist).push;
                        return [4 /*yield*/, this.fileItemService.format(d.pathlist[i])];
                    case 5:
                        _c.apply(_b, [_d.sent()]);
                        _d.label = 6;
                    case 6:
                        i++;
                        return [3 /*break*/, 4];
                    case 7: return [4 /*yield*/, this.getDataNoState(syncId, d.last_metaname_digest, pathlist)];
                    case 8: return [2 /*return*/, _d.sent()];
                    case 9:
                        d.pathlist = pathlist;
                        return [2 /*return*/, d];
                }
            });
        });
    };
    FileListService.prototype.load = function (syncId, histId, showdeleted, offset, pathlist, is_vault) {
        if (pathlist === void 0) { pathlist = []; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var d, cwd, parents, i, len, name_1, _a, _b, _loop_1, this_1, i, len;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.api.execute('pathlist', {
                            showdeleted: showdeleted,
                            hist_id: histId,
                            sync_id: syncId,
                            offset_metaname_digest: offset
                        })];
                    case 1:
                        d = _c.sent();
                        this.store.dispatch(new FileListActions.FileListTotalFavoriteAction(d.total_favorite));
                        this.url.setHostMulti(d);
                        this.is_starred = this.router.url.includes('/starred') ? true : false;
                        if (!(offset === '0')) return [3 /*break*/, 11];
                        if (!d.cwd) {
                            throw new models_1.ErrCode(1803);
                        }
                        return [4 /*yield*/, this.fileItemService.format(d.cwd)];
                    case 2:
                        cwd = _c.sent();
                        this.store.dispatch(new FileListActions.FileListSetCwdAction(cwd));
                        if (!d.parents) return [3 /*break*/, 11];
                        parents = [];
                        i = 0, len = d.parents.length;
                        _c.label = 3;
                    case 3:
                        if (!(i < len)) return [3 /*break*/, 10];
                        if (!(d.parents[i].metaname == 'web' ||
                            d.parents[i].metaname == 'Web Uploads')) return [3 /*break*/, 4];
                        _a = 'Vault';
                        return [3 /*break*/, 8];
                    case 4:
                        if (!(this.is_starred &&
                            d.parents[i].loc_id ==
                                this.userService.get('syncus_sync_id'))) return [3 /*break*/, 5];
                        _b = 'Starred';
                        return [3 /*break*/, 7];
                    case 5: return [4 /*yield*/, this.crypt.filenameDecrypt(d.parents[i].metaname)];
                    case 6:
                        _b = _c.sent();
                        _c.label = 7;
                    case 7:
                        _a = _b;
                        _c.label = 8;
                    case 8:
                        name_1 = _a;
                        parents.push(new models_1.EncFile({
                            loc_id: d.parents[i].loc_id,
                            metaname: d.parents[i].metaname,
                            name: name_1,
                        }));
                        _c.label = 9;
                    case 9:
                        i++;
                        return [3 /*break*/, 3];
                    case 10:
                        this.store.dispatch(new FileListActions.FileListSetParentsAction(parents));
                        _c.label = 11;
                    case 11:
                        this.store.dispatch(new FileListActions.FileListSetProgressAction(d.progress));
                        if (d.hist_id && d.hist_id > 0) {
                            this.store.dispatch(new FileListActions.FileListSetHistIdAction(d.hist_id));
                        }
                        if (!(d.pathlist && d.pathlist.length)) return [3 /*break*/, 17];
                        _loop_1 = function (i, len) {
                            var file, _a, _b;
                            return tslib_1.__generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        if (pathlist.some(function (file) {
                                            return (d.pathlist[i].sync_id === file.sync_id);
                                        })) {
                                            this_1.log.error("Duplicate sync ids '" + d.pathlist[i].sync_id + "' in pid '" + syncId + "'");
                                            throw new models_1.ErrCode(9000, 'Multiple files with the same Sync_id');
                                        }
                                        file = d.pathlist[i];
                                        file.is_vault = is_vault;
                                        _b = (_a = pathlist).push;
                                        return [4 /*yield*/, this_1.fileItemService.format(file)];
                                    case 1:
                                        _b.apply(_a, [_c.sent()]);
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        i = 0, len = d.pathlist.length;
                        _c.label = 12;
                    case 12:
                        if (!(i < len)) return [3 /*break*/, 15];
                        return [5 /*yield**/, _loop_1(i, len)];
                    case 13:
                        _c.sent();
                        _c.label = 14;
                    case 14:
                        i++;
                        return [3 /*break*/, 12];
                    case 15: return [4 /*yield*/, this.load(syncId, histId, showdeleted, d.last_metaname_digest, pathlist, is_vault)];
                    case 16: return [2 /*return*/, _c.sent()];
                    case 17: return [2 /*return*/, pathlist];
                }
            });
        });
    };
    FileListService.prototype.mkdir = function (syncPid, name) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var keyArray, enc_name;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dirtyOut.executeForSync(syncPid, name)];
                    case 1:
                        keyArray = _a.sent();
                        return [4 /*yield*/, this.crypt.filenameEncrypt(name)];
                    case 2:
                        enc_name = _a.sent();
                        return [4 /*yield*/, this.api.execute('pathadd', {
                                sync_pid: syncPid,
                                new_name: enc_name,
                                share_names: keyArray
                            })];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    FileListService.ngInjectableDef = i0.defineInjectable({ factory: function FileListService_Factory() { return new FileListService(i0.inject(i1.ApiService), i0.inject(i2.SyncCryptService), i0.inject(i3.DirtyOutService), i0.inject(i4.Store), i0.inject(i5.UrlService), i0.inject(i6.LoggerService), i0.inject(i7.FileItemService), i0.inject(i8.NgbModal), i0.inject(i9.Router), i0.inject(i10.UserService)); }, token: FileListService, providedIn: "root" });
    return FileListService;
}());
exports.FileListService = FileListService;
