"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var models_1 = require("../../shared/models");
var provision_service_1 = require("../../account/services/provision.service");
var api_service_1 = require("../../core/api.service");
var user_service_1 = require("../../core/user.service");
var logger_service_1 = require("../../core/logger.service");
var environment_1 = require("../../../environments/environment");
var sync_cookie_service_1 = require("../../core/sync-cookie.service");
var multiadmin_service_1 = require("../../core/multiadmin.service");
var router_1 = require("@angular/router");
var zuora_account_service_1 = require("../../account/services/zuora-account.service");
var blend_service_1 = require("src/app/shared/services/blend.service");
var sku_service_1 = require("src/app/shared/services/sku.service");
var DialogMultiAdminuserAddComponent = /** @class */ (function () {
    function DialogMultiAdminuserAddComponent(activeModal, api, syncCookie, log, provision, userService, skuService, router, zuora, blendService) {
        this.activeModal = activeModal;
        this.api = api;
        this.syncCookie = syncCookie;
        this.log = log;
        this.provision = provision;
        this.userService = userService;
        this.skuService = skuService;
        this.router = router;
        this.zuora = zuora;
        this.blendService = blendService;
        this.currentList = [];
        this.spinner = false;
        this.emailField = '';
        this.emails = [];
        this.selectedFilename = '';
        this.users = [];
        this.passreset = '1';
        this.purgefiles = '0';
        this.permissionActions = models_1.PermissionActions;
        this.sku = '';
        this.addUserAmount = '1';
        this.extraUsers = false;
        this.existingEmailsInOtherTeam = [];
        this.existingEmailsInCurrentTeam = [];
        this.isUserInvited = false;
    }
    DialogMultiAdminuserAddComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.spinner = false;
                        this.role = this.rolesList && this.rolesList.length > 0 ? this.rolesList[0].name : this.userService.DEFAULT_ROLE;
                        if (this.openState == 4) {
                            this.addAction = 'newusers';
                        }
                        else if (this.openState == 3) {
                            if (this.userService.get('is_on_trial')) {
                                this.showPurchasePlan();
                            }
                            else {
                                this.addAction = 'order';
                            }
                        }
                        else if (this.openState == 5) {
                            this.addAction = 'upgrade';
                        }
                        else {
                            this.addAction = 'newusers';
                            this.openState = 1;
                        }
                        this.isEmailVerified = this.userService.get('is_verified');
                        _a = this;
                        return [4 /*yield*/, this.skuService.getSku()];
                    case 1:
                        _a.sku = _b.sent();
                        this.userLimit = Array(300 - this.plan_user_limit).fill(0).map(function (x, i) { return i + 1; });
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogMultiAdminuserAddComponent.prototype.checkPermission = function (permName) {
        return this.userService.checkPermission(permName);
    };
    /**
     * Bound to add user button click
     */
    DialogMultiAdminuserAddComponent.prototype.addUser = function () {
        if (this.addAction == 'newusers') {
            this.addMultipleUsers();
        }
        else if (this.addAction == 'order') {
            this.orderAddUser();
        }
        else {
            this.errcode = new models_1.ErrCode(8220);
        }
    };
    /**
     * If the input is valid, the add button will be enabled.
     */
    DialogMultiAdminuserAddComponent.prototype.inputValid = function () {
        if (this.addAction == 'newusers') {
            return (this.email && this.provision.validEmail(this.email) && this.password);
        }
        else if (this.addAction == 'order') {
            return true;
        }
        else {
            return false;
        }
    };
    DialogMultiAdminuserAddComponent.prototype.goNext = function () {
        if (this.addAction == 'newusers') {
            this.openState = 4;
        }
        else if (this.addAction == 'order') {
            this.openState = 3;
        }
        else {
            return false;
        }
    };
    DialogMultiAdminuserAddComponent.prototype.showPurchaseUsers = function () {
        if (this.userService.get('is_on_trial')) {
            this.showPurchasePlan();
        }
        else {
            this.addAction = 'order';
            this.openState = 3;
        }
    };
    DialogMultiAdminuserAddComponent.prototype.showPurchasePlan = function () {
        this.addAction = 'upgrade';
        this.openState = 5;
    };
    DialogMultiAdminuserAddComponent.prototype.redirectToUpgrade = function () {
        this.router.navigate(['/account/upgrade']);
        this.activeModal.close();
    };
    DialogMultiAdminuserAddComponent.prototype.orderAddUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var additionalUsers, p, rp, rpc, qty, isMultiNew, isMultiAdmin, zprofile, result, url, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        additionalUsers = parseInt(this.addUserAmount, 10);
                        if (!this.userService.checkPermission(this.permissionActions.ADD_EDIT_USERS)) return [3 /*break*/, 8];
                        p = this.plan_product_id;
                        rp = this.plan_rate_plan_id;
                        rpc = this.plan_rate_plan_charge_id;
                        qty = this.plan_user_limit;
                        isMultiNew = this.userService.get('is_multi_new');
                        isMultiAdmin = this.userService.get('is_multi_admin');
                        return [4 /*yield*/, this.zuora.getProfile()];
                    case 1:
                        zprofile = _a.sent();
                        if (!((isMultiNew || (!isMultiNew && isMultiAdmin)) && zprofile && zprofile.balance <= 0 && zprofile.unpaidInvoiceCount >= 0)) return [3 /*break*/, 6];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.api.execute('ordersessionnew', {})];
                    case 3:
                        result = _a.sent();
                        this.syncCookie.setOrderSessionId(result.sessionId);
                        url = environment_1.environment.ordershost
                            + '/upgrade/'
                            + p
                            + '?rp=' + rp
                            + '&rpc=' + rpc
                            + '&qty=' + (qty + additionalUsers);
                        this.log.info("Sending user to upgrade " + url);
                        window.location.href = url;
                        return [3 /*break*/, 5];
                    case 4:
                        ex_1 = _a.sent();
                        this.errcode = new models_1.ErrCode(8550);
                        this.log.error("Error creating order session to add user " + JSON.stringify(ex_1));
                        return [3 /*break*/, 5];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        this.router.navigate(['/account/upgrade']);
                        this.activeModal.close();
                        _a.label = 7;
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        this.errcode = new models_1.ErrCode(8505);
                        _a.label = 9;
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    DialogMultiAdminuserAddComponent.prototype.sanitizeEmail = function (email) {
        return email.toLowerCase().trim();
    };
    DialogMultiAdminuserAddComponent.prototype.uploadListener = function ($event) {
        var _this = this;
        var files = $event.srcElement.files;
        if (!files) {
            files = $event.dataTransfer.files;
        }
        if (this.isValidCSVFile(files[0])) {
            var reader_1 = new FileReader();
            reader_1.readAsText(files[0]);
            this.selectedFilename = files[0].name;
            reader_1.onload = function () {
                _this.errcode = null;
                _this.alertMsg = null;
                var csvData = reader_1.result;
                var csvRecordsArray = csvData.split(/\r\n|\n/);
                _this.users = [];
                var header = csvRecordsArray[0];
                var headerProps = header.split(',');
                if (headerProps.length < 2 || headerProps[0].trim() != 'EmailAddress' || headerProps[1].trim() != 'RoleName') {
                    _this.handleCSVErrors("Header text is incorrect or missing.");
                    return;
                }
                var csvUsers = [];
                var emptyCells = [];
                var invalidEmails = [];
                var invalidRoles = [];
                var _loop_1 = function (i) {
                    var props = csvRecordsArray[i];
                    var p = props.split(',');
                    if (props != '') {
                        var cellEmail = p[0].trim();
                        var cellRole_1 = p[1].trim() || _this.userService.DEFAULT_ROLE;
                        var selectedRole = _this.rolesList.filter(function (role) { return role.name.toLowerCase().trim() === cellRole_1.toLowerCase(); });
                        if (!cellEmail) {
                            emptyCells.push(i + 1);
                        }
                        else {
                            if (!_this.provision.validEmail(cellEmail)) {
                                invalidEmails.push(i + 1);
                            }
                            if (selectedRole.length === 0 && !invalidRoles.includes(cellRole_1)) {
                                invalidRoles.push(cellRole_1);
                            }
                        }
                        var role = selectedRole.length ? selectedRole[0].name : cellRole_1;
                        csvUsers.push({ email: cellEmail, role: role });
                    }
                };
                for (var i = 1; i < csvRecordsArray.length; i++) {
                    _loop_1(i);
                }
                if (csvUsers.length > _this.plan_user_limit) {
                    _this.handleCSVErrors("Your plan includes only " + _this.plan_user_limit + " users, the CSV includes " + csvUsers.length + " users");
                    return;
                }
                if (csvUsers.length > _this.remainingUsersAmount) {
                    _this.handleCSVErrors("You only have " + _this.remainingUsersAmount + " user" + (_this.remainingUsersAmount > 1 ? 's' : '') + " available, the CSV includes " + csvUsers.length + " new users");
                    return;
                }
                if (emptyCells.length) {
                    if (emptyCells.length > 1) {
                        _this.handleCSVErrors("row " + (emptyCells.slice(0, -1).join(', ') + ' and ' + emptyCells.slice(-1)) + " are missing email");
                    }
                    else {
                        _this.handleCSVErrors("row " + emptyCells.toString() + " is missing email");
                    }
                    return;
                }
                if (invalidEmails.length) {
                    if (invalidEmails.length > 1) {
                        _this.handleCSVErrors("The email addresses on rows " + (invalidEmails.slice(0, -1).join(', ') + ' and ' + invalidEmails.slice(-1)) + " are invalid.");
                    }
                    else {
                        _this.handleCSVErrors("The email address on row " + invalidEmails.toString() + " is invalid.");
                    }
                    return;
                }
                if (invalidRoles.length) {
                    if (invalidRoles.length > 1) {
                        _this.handleCSVErrors(invalidRoles.slice(0, -1).join(', ') + ' and ' + invalidRoles.slice(-1) + " do not exist as roles.");
                    }
                    else {
                        _this.handleCSVErrors(invalidRoles.toString() + " does not exist as a role.");
                    }
                    return;
                }
                for (var i = 0; i < csvUsers.length; i++) {
                    _this.addUsers(csvUsers[i]);
                }
            };
            reader_1.onerror = function () {
                _this.handleCSVErrors("Error is occured while reading file.");
            };
        }
        else {
            this.alertMsg = new models_1.AlertMessage("Please import valid .csv file.", models_1.AlertMessageType.ERROR);
            this.fileReset();
        }
    };
    DialogMultiAdminuserAddComponent.prototype.handleFileUploadErrors = function (code, msg) {
        this.errcode = new models_1.ErrCode(code, msg);
        this.fileReset();
        return false;
    };
    DialogMultiAdminuserAddComponent.prototype.handleCSVErrors = function (msg) {
        this.alertMsg = new models_1.AlertMessage(msg, models_1.AlertMessageType.ERROR);
    };
    DialogMultiAdminuserAddComponent.prototype.isValidCSVFile = function (file) {
        return file && file.name.endsWith('.csv');
    };
    DialogMultiAdminuserAddComponent.prototype.fileReset = function () {
        if (this.csvReader && this.csvReader.nativeElement && this.csvReader.nativeElement.value) {
            this.csvReader.nativeElement.value = '';
        }
        this.selectedFilename = '';
        this.users = [];
    };
    DialogMultiAdminuserAddComponent.prototype.parseEmails = function () {
        var _this = this;
        this.users = [];
        this.emails.forEach(function (email) {
            if (email !== '') {
                _this.addUsers({ email: email, role: _this.role });
            }
        });
    };
    DialogMultiAdminuserAddComponent.prototype.addEmails = function () {
        var _this = this;
        this.emailField.split(',').forEach(function (email) {
            if (email.trim() !== '') {
                _this.emails.push(email.trim());
            }
        });
        this.emailField = '';
    };
    DialogMultiAdminuserAddComponent.prototype.removeEmail = function (email) {
        var index = this.emails.indexOf(email, 0);
        if (index > -1) {
            this.emails.splice(index, 1);
        }
    };
    DialogMultiAdminuserAddComponent.prototype.addUsers = function (user) {
        this.users.push({
            usernameB64: btoa(this.sanitizeEmail(user.email)),
            password: '',
            role: user.role,
            signup: {
                encPassPriv: '',
                encPassMeta: '',
                enableReset: 0,
                encSyncRootName: '',
                lastNameB64: '',
                firstNameB64: '',
                pubKey: '',
                encPrivKey: '',
                encMetaKey: '',
                encShareKey: '',
                encPassword: ''
            }
        });
    };
    DialogMultiAdminuserAddComponent.prototype.getUsersList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.multiadminService.getUserList()];
                    case 1:
                        result = _a.sent();
                        this.plan_user_limit = result.user_limit;
                        this.remainingUsersAmount = this.plan_user_limit - result.users.length;
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.log.error(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DialogMultiAdminuserAddComponent.prototype.addMultipleUsers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var invalidEmails, result, allexistingUsersEmails, ex_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        return [4 /*yield*/, this.getUsersList()];
                    case 1:
                        _a.sent();
                        this.errcode = null;
                        this.alertMsg = null;
                        this.isSeatsNeeded = false;
                        if (this.selectedFilename === '') {
                            this.parseEmails();
                        }
                        if (this.users.length === 0) {
                            this.alertMsg = new models_1.AlertMessage('Please select a csv file or add user email(s) in textbox.', models_1.AlertMessageType.ERROR);
                            this.spinner = false;
                            return [2 /*return*/];
                        }
                        else if (this.users.length > this.plan_user_limit) {
                            this.alertMsg = new models_1.AlertMessage("Number of users to invite exceeds the allowed user for current plan, Your plan includes only " + this.plan_user_limit + " users.", models_1.AlertMessageType.ERROR);
                            this.spinner = false;
                            return [2 /*return*/];
                        }
                        else if (this.users.length > this.remainingUsersAmount) {
                            this.isSeatsNeeded = true;
                            this.spinner = false;
                            return [2 /*return*/];
                        }
                        invalidEmails = [];
                        this.users.forEach(function (user) {
                            var email = atob(user.usernameB64);
                            if (!_this.provision.validEmail(email)) {
                                invalidEmails.push(email);
                            }
                        });
                        if (invalidEmails.length) {
                            if (invalidEmails.length > 1) {
                                this.alertMsg = new models_1.AlertMessage("The email addresses " + (invalidEmails.slice(0, -1).join(', ') + ' and ' + invalidEmails.slice(-1)) + " are invalid.", models_1.AlertMessageType.ERROR);
                            }
                            else {
                                this.alertMsg = new models_1.AlertMessage("The email address " + invalidEmails.toString() + " is invalid.", models_1.AlertMessageType.ERROR);
                            }
                            this.fileReset();
                            this.spinner = false;
                            return [2 /*return*/];
                        }
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 7, , 8]);
                        return [4 /*yield*/, this.multiadminService.checkExistingUsers({
                                'teamId': this.userService.get('uid'),
                                'users': this.users
                            })];
                    case 3:
                        result = _a.sent();
                        if (!result.usersExisting.length) return [3 /*break*/, 4];
                        //finding users which are existing in current team or another team
                        this.existingEmailsInCurrentTeam = [];
                        this.existingEmailsInOtherTeam = [];
                        result.usersExisting.forEach(function (user) {
                            if (_this.currentList.some(function (item) { return item.email === user.usernameB64; }) || user.usernameB64 === _this.currentUser.email) {
                                _this.existingEmailsInCurrentTeam.push(user.usernameB64);
                            }
                            else {
                                _this.existingEmailsInOtherTeam.push(user.usernameB64);
                            }
                        });
                        if (this.existingEmailsInOtherTeam.length && this.existingEmailsInCurrentTeam.length) {
                            allexistingUsersEmails = this.existingEmailsInOtherTeam.concat(this.existingEmailsInCurrentTeam);
                            if (allexistingUsersEmails.length > 9) {
                                this.alertMsg = new models_1.AlertMessage(allexistingUsersEmails.length + " users are part of current/another team and cannot be invited.", models_1.AlertMessageType.ERROR);
                            }
                            else {
                                this.alertMsg = new models_1.AlertMessage(allexistingUsersEmails.slice(0, -1).join(', ') + ' and ' + allexistingUsersEmails.slice(-1) + " are part of current/another team and cannot be invited.", models_1.AlertMessageType.ERROR);
                            }
                        }
                        else if (this.existingEmailsInOtherTeam.length) {
                            if (this.existingEmailsInOtherTeam.length > 9) {
                                this.alertMsg = new models_1.AlertMessage(this.existingEmailsInOtherTeam.length + " users are part of another team and cannot be invited.", models_1.AlertMessageType.ERROR);
                            }
                            else if (this.existingEmailsInOtherTeam.length > 1) {
                                this.alertMsg = new models_1.AlertMessage(this.existingEmailsInOtherTeam.slice(0, -1).join(', ') + ' and ' + this.existingEmailsInOtherTeam.slice(-1) + " are part of another team and cannot be invited.", models_1.AlertMessageType.ERROR);
                            }
                            else {
                                this.alertMsg = new models_1.AlertMessage(this.existingEmailsInOtherTeam.toString() + " is part of another team and cannot be invited.", models_1.AlertMessageType.ERROR);
                            }
                        }
                        else {
                            if (this.existingEmailsInCurrentTeam.length > 9) {
                                this.alertMsg = new models_1.AlertMessage(this.existingEmailsInCurrentTeam.length + " users already exist.", models_1.AlertMessageType.ERROR);
                            }
                            else if (this.existingEmailsInCurrentTeam.length > 1) {
                                this.alertMsg = new models_1.AlertMessage(this.existingEmailsInCurrentTeam.slice(0, -1).join(', ') + ' and ' + this.existingEmailsInCurrentTeam.slice(-1) + " are already exist.", models_1.AlertMessageType.ERROR);
                            }
                            else {
                                this.alertMsg = new models_1.AlertMessage(this.existingEmailsInCurrentTeam.toString() + " already exist.", models_1.AlertMessageType.ERROR);
                            }
                        }
                        if (result.usersExisting.length < this.users.length) {
                            this.extraUsers = true;
                        }
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.proceedAddUser()];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        ex_2 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_2);
                        return [3 /*break*/, 8];
                    case 8:
                        this.spinner = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogMultiAdminuserAddComponent.prototype.proceedAddUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var addMethod, existingUserCount, addedUserCount, ex_3;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.errcode = null;
                        this.alertMsg = null;
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.multiadminService.addMultipleUsers({
                                'teamId': this.userService.get('uid'),
                                'users': this.users
                            })];
                    case 2:
                        _a.sent();
                        addMethod = this.selectedFilename == '' ? 'Manual' : 'CSV';
                        existingUserCount = this.existingEmailsInCurrentTeam.length + this.existingEmailsInOtherTeam.length;
                        addedUserCount = this.users.length - existingUserCount;
                        if (existingUserCount) {
                            this.alertMsg = new models_1.AlertMessage(addedUserCount + " user" + (addedUserCount > 1 ? 's were' : ' was') + " added, " + existingUserCount + " user" + (existingUserCount > 1 ? 's' : '') + " already exist.");
                        }
                        else {
                            this.alertMsg = new models_1.AlertMessage(addedUserCount + " user" + (addedUserCount > 1 ? 's were' : ' was') + " added.");
                        }
                        this.isUserInvited = true;
                        this.fileReset();
                        this.emails = [];
                        this.blendService.track(models_1.BlendEvent.SUB_USER_ADDED, { num_users: addedUserCount, sku: this.sku, method: addMethod });
                        setTimeout(function () {
                            _this.activeModal.close();
                        }, 5000);
                        return [3 /*break*/, 4];
                    case 3:
                        ex_3 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_3);
                        return [3 /*break*/, 4];
                    case 4:
                        this.spinner = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogMultiAdminuserAddComponent.prototype.resetFields = function () {
        this.errcode = null;
        this.extraUsers = false;
        this.emailField = '';
        this.emails = [];
        this.fileReset();
        this.alertMsg = null;
    };
    DialogMultiAdminuserAddComponent.prototype.onDrop = function (event) {
        event.preventDefault();
        this.uploadListener(event);
    };
    DialogMultiAdminuserAddComponent.prototype.onDragOver = function (event) {
        event.stopPropagation();
        event.preventDefault();
    };
    return DialogMultiAdminuserAddComponent;
}());
exports.DialogMultiAdminuserAddComponent = DialogMultiAdminuserAddComponent;
