"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AuthSSO = /** @class */ (function () {
    function AuthSSO(lookupkey, password, twofacode) {
        this.lookupkey = lookupkey;
        this.password = password;
        this.twofacode = twofacode;
    }
    return AuthSSO;
}());
exports.AuthSSO = AuthSSO;
