"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AUTH_LOGIN = '[Auth] Login';
exports.AUTH_LOGIN_FAILURE = '[Auth] Login failure';
exports.AUTH_LOGIN_SUCCESS = '[Auth] Login success';
exports.AUTH_LOGIN_SSO = '[Auth] Login SSO';
exports.AUTH_REFRESH = '[Auth] refresh';
exports.AUTH_UPDATE_USER = '[Auth] Update user';
exports.AUTH_LOGOUT = '[Auth] Logout';
exports.AUTH_LOGIN_PROGRESS = '[Auth] Login progress';
exports.AUTH_LOGIN_TWOFACTOR = '[Auth] Two-factor required';
var LoginSuccessAction = /** @class */ (function () {
    function LoginSuccessAction(payload) {
        this.payload = payload;
        this.type = exports.AUTH_LOGIN_SUCCESS;
    }
    return LoginSuccessAction;
}());
exports.LoginSuccessAction = LoginSuccessAction;
var LoginFailureAction = /** @class */ (function () {
    function LoginFailureAction(payload) {
        this.payload = payload;
        this.type = exports.AUTH_LOGIN_FAILURE;
    }
    return LoginFailureAction;
}());
exports.LoginFailureAction = LoginFailureAction;
var LoginAction = /** @class */ (function () {
    function LoginAction(payload) {
        this.payload = payload;
        this.type = exports.AUTH_LOGIN;
    }
    return LoginAction;
}());
exports.LoginAction = LoginAction;
var LoginSSOAction = /** @class */ (function () {
    function LoginSSOAction(payload) {
        this.payload = payload;
        this.type = exports.AUTH_LOGIN_SSO;
    }
    return LoginSSOAction;
}());
exports.LoginSSOAction = LoginSSOAction;
var LogoutAction = /** @class */ (function () {
    function LogoutAction() {
        this.type = exports.AUTH_LOGOUT;
    }
    return LogoutAction;
}());
exports.LogoutAction = LogoutAction;
var RefreshAction = /** @class */ (function () {
    function RefreshAction() {
        this.type = exports.AUTH_REFRESH;
    }
    return RefreshAction;
}());
exports.RefreshAction = RefreshAction;
var UpdateAction = /** @class */ (function () {
    function UpdateAction(payload) {
        this.payload = payload;
        this.type = exports.AUTH_UPDATE_USER;
    }
    return UpdateAction;
}());
exports.UpdateAction = UpdateAction;
var LoginProgressAction = /** @class */ (function () {
    function LoginProgressAction(payload) {
        this.payload = payload;
        this.type = exports.AUTH_LOGIN_PROGRESS;
    }
    return LoginProgressAction;
}());
exports.LoginProgressAction = LoginProgressAction;
var LoginTwoFactorRequiredAction = /** @class */ (function () {
    function LoginTwoFactorRequiredAction() {
        this.type = exports.AUTH_LOGIN_TWOFACTOR;
    }
    return LoginTwoFactorRequiredAction;
}());
exports.LoginTwoFactorRequiredAction = LoginTwoFactorRequiredAction;
