"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var user_service_1 = require("../../core/user.service");
var link_file_list_service_1 = require("../../link-consume/services/link-file-list.service");
var func_1 = require("../../shared/func");
var build_transfer_item_service_1 = require("../../transfer/services/build-transfer-item.service");
var models_1 = require("../../shared/models");
var transfer_service_1 = require("../../transfer/transfer.service");
var file_item_service_1 = require("../file-item.service");
var router_1 = require("@angular/router");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var wopi_upgrade_component_1 = require("../wopi-upgrade/wopi-upgrade.component");
var blend_service_1 = require("../../shared/services/blend.service");
var PreviewDefaultComponent = /** @class */ (function () {
    function PreviewDefaultComponent(buildTransferItemService, transferService, linkPathListService, sanitizer, userService, fileItemService, router, route, modalService, blendService) {
        var _this = this;
        this.buildTransferItemService = buildTransferItemService;
        this.transferService = transferService;
        this.linkPathListService = linkPathListService;
        this.sanitizer = sanitizer;
        this.userService = userService;
        this.fileItemService = fileItemService;
        this.router = router;
        this.route = route;
        this.modalService = modalService;
        this.blendService = blendService;
        this.openSpinner = false;
        this.compat = 0;
        this.onCommentToggle = function (isCommentExpanded) {
            _this.isCommentExpanded = isCommentExpanded;
        };
    }
    PreviewDefaultComponent.prototype.bypassSecurityTrustUrl = function (url) {
        if (url) {
            return this.sanitizer.bypassSecurityTrustUrl(url);
        }
        return null;
    };
    PreviewDefaultComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                console.log(this.item);
                // console.log(this.upsell);
                this.isAuth = this.userService.isAuthenticated();
                this.user = this.userService.getUser();
                this.sub = this.linkPathListService
                    .getSubscription()
                    .subscribe(function (data) {
                    if (data.loaded && data.sorted) {
                        _this.isUploadAllowed = data.upload;
                        _this.compat = data.compat;
                        _this.isFileEditAllowed = data.file_edit;
                        _this.isCwdShared = data.cwd.is_shared;
                        if (data.image_cachekey) {
                            _this.isWhiteLabel = true;
                            _this.buttonPrimaryColor = data.button_primary_color;
                            _this.buttonTextColor = data.button_text_color;
                            _this.linkTextColor = data.link_text_color;
                        }
                        _this.allowComment = data.allow_comment;
                    }
                });
                this.blendService.track(models_1.BlendEvent.VIEW_FILE, {
                    type: 'default',
                    preview_preference: 'N/A',
                    fileSize: this.item.filesize,
                    mimeType: this.item.mime_type
                });
                return [2 /*return*/];
            });
        });
    };
    PreviewDefaultComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    };
    PreviewDefaultComponent.prototype.isOpenable = function () {
        if (this.item.context == 'link' && (this.item.previewonly == 8 || !this.compat)) {
            return false;
        }
        if (this.item.context == 'link' &&
            func_1.getFileExt(this.item.name) == 'pdf' &&
            !this.item.link_is_business) {
            return false;
        }
        if (this.item.context == 'link' && this.fileItemService.isMSOffice(this.item, 'edit')) {
            return (this.fileItemService.isOfficeLinkEdit(this.item, this.isUploadAllowed, this.isFileEditAllowed, this.isCwdShared));
        }
        if (!func_1.canBrowserOpen(this.item.name)) {
            return false;
        }
        // return this.item.compat
        //     ? canLinkBrowserOpen(this.item.name)
        //     : canBrowserOpen(this.item.name);
        return true;
    };
    PreviewDefaultComponent.prototype.openFile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.isOfficeEdit()) return [3 /*break*/, 1];
                        this.openOfficeEdit();
                        return [3 /*break*/, 5];
                    case 1:
                        if (!(this.item.context == 'applink')) return [3 /*break*/, 2];
                        this.url = this.item.compaturl;
                        return [3 /*break*/, 4];
                    case 2:
                        _a = this;
                        return [4 /*yield*/, this.buildTransferItemService.getCompatUrl(this.item, this.buildTransferItemService.ACT_PREVIEW)];
                    case 3:
                        _a.url = _b.sent();
                        _b.label = 4;
                    case 4:
                        window.location.href = this.url;
                        _b.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PreviewDefaultComponent.prototype.isOfficeEdit = function () {
        return (this.fileItemService.isMSOffice(this.item, 'edit') && this.isAuth);
    };
    PreviewDefaultComponent.prototype.openOfficeEdit = function () {
        var isLinkEdit = this.item.context == 'link'
            ? this.fileItemService.isOfficeLinkEdit(this.item, this.isUploadAllowed, this.isFileEditAllowed, this.isCwdShared)
            : false;
        if (isLinkEdit) {
            var navigationParams = ['/dl', this.route.snapshot.params['cachekey'], 'view', 'officefse', this.item.sync_id];
            // add key in navigation params array if it exists in router params, otherwise key after # in fragment will be used
            if (this.route.snapshot.params['key']) {
                navigationParams.splice(2, 0, this.route.snapshot.params['key']);
            }
            this.router.navigate(navigationParams, tslib_1.__assign({}, this.linkPathListService.decorateQueryParams(this.route.snapshot.queryParams), this.linkPathListService.decorateFragment(this.compat, this.route.snapshot.fragment)));
        }
        else if (this.item.context == 'files') {
            if (this.user.can_preview) {
                this.router.navigate(['/file', this.item.sync_id, 'view', 'officefse'], {
                    queryParams: {
                        view: this.route.snapshot.queryParams['view'],
                        page: this.route.snapshot.queryParams['page']
                    }
                });
            }
            else {
                var ref = this.modalService.open(wopi_upgrade_component_1.WopiUpgradeComponent, {
                    backdropClass: 'in',
                    windowClass: 'in',
                    backdrop: 'static',
                });
                ref.componentInstance.id = this.route.snapshot.params['id'];
            }
        }
    };
    PreviewDefaultComponent.prototype.downloadCompat = function () {
        var _this = this;
        if (this.item.context == 'applink') {
            window.location.href = this.item.compaturl_dl;
        }
        else {
            this.buildTransferItemService.getCompatUrl(this.item, this.buildTransferItemService.ACT_DOWNLOAD).
                then(function (url) {
                _this.item.compaturl_dl = url;
                window.location.href = _this.item.compaturl_dl;
            });
        }
    };
    PreviewDefaultComponent.prototype.download = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // this.spinner = true;
                        this.item.blobtype = 'btFILE';
                        if (!(this.item.compat && this.item.context == 'link')) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.buildTransferItemService.getCompatUrl(this.item, this.buildTransferItemService.ACT_DOWNLOAD)];
                    case 1:
                        _a.url = _b.sent();
                        window.location.href = this.url;
                        return [3 /*break*/, 3];
                    case 2:
                        if (this.item.compat && this.item.context == 'applink') {
                            this.url = this.item.compaturl_dl;
                            window.location.href = this.url;
                        }
                        else if (this.item.context == 'link' && !this.item.compat) {
                            this.transferService.queueDownload([this.item]);
                        }
                        else {
                            this.transferService.queueDownload([this.item]);
                        }
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return PreviewDefaultComponent;
}());
exports.PreviewDefaultComponent = PreviewDefaultComponent;
