"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SharememberItem = /** @class */ (function () {
    function SharememberItem() {
    }
    SharememberItem.prototype.deserialize = function (input) {
        this.sharememberId = input.uniqid;
        this.userId = input.userid;
        this.email = input.email;
        this.displayname = input.displayname;
        this.status = input.status;
        this.pubkeyId = input.pubkey_id;
        this.pubkey = input.pubkey;
        this.shareId = input.share_id;
        this.permissions = input.permissions;
        this.num_apps = input.num_apps;
        return this;
    };
    return SharememberItem;
}());
exports.SharememberItem = SharememberItem;
