"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("../../../pipes/b64.pipe");
var i3 = require("../../../../core/base64.service");
var i4 = require("./header-links.component");
var i5 = require("@ngrx/store");
var i6 = require("../../../../core/api.service");
var i7 = require("../../../../core/logger.service");
var i8 = require("@angular/platform-browser");
var i9 = require("@ng-bootstrap/ng-bootstrap");
var styles_HeaderLinksComponent = [];
var RenderType_HeaderLinksComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeaderLinksComponent, data: {} });
exports.RenderType_HeaderLinksComponent = RenderType_HeaderLinksComponent;
function View_HeaderLinksComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "hidden-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["href", "https://www.sync.com/?_m=h73"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Create account"]))], null, null); }
function View_HeaderLinksComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "li", [["class", "hidden-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "navbar-avatar-bigbox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["class", "img-circle navbar-avatar-header-bar"], ["onerror", "this.onerror=null;this.src='../../../../../images/sync-construction.png';"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "navbar-avatar-smalltext hidden-xs"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Signed in as: "])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "navbar-avatar-bigtext hidden-xs"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), i0.ɵppd(8, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.cpHost + "/account/info"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.avatarEndpoint + _co.user.id); _ck(_v, 3, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v.parent.parent, 0), _co.user.display_name)); _ck(_v, 7, 0, currVal_2); }); }
function View_HeaderLinksComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "visible-xs hidden-sm hidden-md hidden-lg hidden-xl"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["href", "/account/info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["class", "img-circle navbar-avatar-header-bar"], ["onerror", "this.onerror=null;this.src='../../../../../images/sync-construction.png';"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.avatarEndpoint + _co.user.id); _ck(_v, 2, 0, currVal_0); }); }
function View_HeaderLinksComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "nav", [["class", "navbar navbar-default navbar-fixed-top-link navbar-white"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "navbar-header navbar-white-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["class", "navbar-brand"], ["href", "https://www.sync.com/?_m=h73"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["class", "navbar-white-q"], ["id", "navbar-logo"], ["src", "/images/sync-q-lightbg.svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 10, "div", [["class", "n"], ["id", "navbar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 9, "ul", [["class", "nav navbar-nav navbar-right navbar-white-right"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderLinksComponent_2)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderLinksComponent_3)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderLinksComponent_4)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openContactSupport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Help"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_co.user || !_co.user.exists) && _co.userLoaded); _ck(_v, 7, 0, currVal_0); var currVal_1 = ((_co.user && _co.user.exists) && _co.userLoaded); _ck(_v, 9, 0, currVal_1); var currVal_2 = ((_co.user && _co.user.exists) && _co.userLoaded); _ck(_v, 11, 0, currVal_2); }, null); }
function View_HeaderLinksComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "hidden-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["href", "https://www.sync.com/?_m=h73"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Create account"]))], null, null); }
function View_HeaderLinksComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "li", [["class", "hidden-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "navbar-avatar-bigbox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["class", "img-circle navbar-avatar-header-bar"], ["onerror", "this.onerror=null;this.src='../../../../../images/sync-construction.png';"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "navbar-avatar-smalltext hidden-xs"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Signed in as: "])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "navbar-avatar-bigtext hidden-xs"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), i0.ɵppd(8, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.cpHost + "/account/info"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.avatarEndpoint + _co.user.id); _ck(_v, 3, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v.parent.parent, 0), _co.user.display_name)); _ck(_v, 7, 0, currVal_2); }); }
function View_HeaderLinksComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "visible-xs hidden-sm hidden-md hidden-lg hidden-xl"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["href", "/account/info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["class", "img-circle navbar-avatar-header-bar"], ["onerror", "this.onerror=null;this.src='../../../../../images/sync-construction.png';"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.avatarEndpoint + _co.user.id); _ck(_v, 2, 0, currVal_0); }); }
function View_HeaderLinksComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "nav", [["class", "navbar navbar-fixed-top-link navbar-white-custom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "navbar-header navbar-white-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["class", "navbar-brand"], ["href", "https://www.sync.com/?_m=h73"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["class", "navbar-white-q logo-custom"], ["id", "navbar-logo"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 10, "div", [["class", "n"], ["id", "navbar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 9, "ul", [["class", "nav navbar-nav navbar-right navbar-white-right-custom"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderLinksComponent_6)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderLinksComponent_7)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderLinksComponent_8)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openContactSupport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Help"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((!_co.user || !_co.user.exists) && _co.userLoaded); _ck(_v, 7, 0, currVal_1); var currVal_2 = ((_co.user && _co.user.exists) && _co.userLoaded); _ck(_v, 9, 0, currVal_2); var currVal_3 = (_co.user && _co.user.exists); _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.logoHost + _co.imageCachekey); _ck(_v, 3, 0, currVal_0); }); }
function View_HeaderLinksComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.B64Pipe, [i3.Base64Service]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderLinksComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderLinksComponent_5)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.whiteLabelLoaded && !_co.imageCachekey); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.whiteLabelLoaded && _co.imageCachekey); _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_HeaderLinksComponent_0 = View_HeaderLinksComponent_0;
function View_HeaderLinksComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-header-links", [], [[1, "style", 2]], null, null, View_HeaderLinksComponent_0, RenderType_HeaderLinksComponent)), i0.ɵdid(1, 245760, null, 0, i4.HeaderLinksComponent, [i5.Store, i6.ApiService, i7.LoggerService, i8.DomSanitizer, i9.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).customColorsCss; _ck(_v, 0, 0, currVal_0); }); }
exports.View_HeaderLinksComponent_Host_0 = View_HeaderLinksComponent_Host_0;
var HeaderLinksComponentNgFactory = i0.ɵccf("sync-header-links", i4.HeaderLinksComponent, View_HeaderLinksComponent_Host_0, {}, {}, []);
exports.HeaderLinksComponentNgFactory = HeaderLinksComponentNgFactory;
