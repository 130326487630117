"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var DialogShareChooseOwnerComponent = /** @class */ (function () {
    function DialogShareChooseOwnerComponent(activeModal) {
        this.activeModal = activeModal;
        this.members = [];
    }
    DialogShareChooseOwnerComponent.prototype.ngOnInit = function () {
        this.members = this.shareData.people.filter(function (member) { return !(member.isMe ||
            ['Waiting Signup', 'Waiting'].includes(member.status)); });
        if (this.members.length === 0) {
            this.activeModal.close();
        }
    };
    DialogShareChooseOwnerComponent.prototype.onSelectOwner = function (selectedEmail) {
        this.selectedMember = this.members.find(function (member) { return member.email === selectedEmail; });
    };
    DialogShareChooseOwnerComponent.prototype.onChangeOwnership = function () {
        if (!this.selectedMember) {
            return;
        }
        this.close(this.selectedMember.email);
    };
    DialogShareChooseOwnerComponent.prototype.close = function (newOwnerEmail) {
        this.activeModal.close(newOwnerEmail);
    };
    return DialogShareChooseOwnerComponent;
}());
exports.DialogShareChooseOwnerComponent = DialogShareChooseOwnerComponent;
