"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorMessages = {
    '1000': 'An error occurred',
    // These are shard errors where the input has been mangled so much that
    // the sharding algorithm cannot find the correct table.
    '1001': 'Upload canceled',
    '1100': 'An internal error occurred.',
    '1101': 'The public link URL is incorrect or invalid',
    '1102': 'The share invitation is invalid',
    '1103': 'Invalid reward code given',
    '1104': 'Invalid confirmation key',
    '1500': 'The file listing encountered an error.',
    '1501': 'Renaming the file encountered an error.',
    '1502': 'Renaming the folder encountered an error.',
    '1600': 'This invite cannot be found',
    '1601': 'This invite was not for you.  Please log in as the invited user',
    '1602': 'Unable to locate the invited member',
    '1603': 'This invite has an invalid state.',
    '1604': 'This is an invalid share',
    '1605': 'You must invite at least 1 person',
    '1606': 'There was an error with this share',
    '1607': 'Could not decrypt the share folder name',
    '1608': 'Could not decrypt the share folder name',
    '1609': 'Share verification did not return successfully',
    '1610': 'You are already sharing a folder inside.  Nested share folders are not supported.',
    '1611': 'Could not process the share invitation',
    '1612': 'An error occurred accepting this share',
    '1613': 'An error occurred declining the share',
    '1614': 'An error occurred accepting this share',
    '1620': 'The share password was incorrect',
    '1621': 'Please provide the share password',
    '1650': 'You cannot invite yourself to your own share',
    '1651': 'This person is already on the share',
    '1652': 'You can add up to 20 users at a time. Click the button below to add / send invites to these users now, and then continue to add more users as needed.',
    '1653': 'This person is alerady queued to be invited',
    '1654': 'You must enter a display name',
    '1655': 'A maximum of 5 emails may be queued.',
    '1656': 'No email addresses were queued',
    '1657': 'An error occurred attempting to send billing documents via email.  Please try again later',
    '1658': 'Unable to locate the billing document',
    '1659': 'Display name cannot contain URLs',
    '1666': 'New shares cannot exceed 30000 files.',
    '1680': 'Your Sync Account is currently processing changes to folders and files. Please leave your browser open and try again in a few minutes.',
    '1681': 'Internal server error. Visit <a href=\'https://www.sync.com/support/\' target=\'_blank\'>https://www.sync.com/support/</a>',
    '1700': 'This link has been deleted by the owner.',
    '1701': 'Role name can not contain special characters.',
    //invite related
    '1702': 'The url is missing the invite id',
    // Pathlist/favourite errors
    '1800': 'Unable to access your starred right now. Please retry later.',
    '1803': 'No current working directory found',
    '1804': 'This file was not found',
    // APP Rating Errors
    '1900': 'Invalid rating input',
    // Encryption/decryption errors
    '2000': 'Missing or invalid share key',
    '2005': 'Missing or invalid public key',
    '2006': 'Missing or invalid private key',
    '2010': 'Missing or invalid data key',
    '2015': 'Missing or invalid link key',
    '2020': 'Encrypted data may be corrupt, incorrect authentication header',
    '2021': 'Encrypted file could not be decrypted.',
    '2022': 'Unable to encrypt the file name',
    '2023': 'Unable to decrypt the file name',
    '2024': 'Encrypted data is invalid.',
    '2050': 'Error decrypting file data',
    '2100': 'Error decrypting the link or share password',
    '2110': 'The link key is incorrect',
    '2115': 'The share wrap key is incorrect',
    '2120': 'Error encrypting link share keys',
    '2121': 'Error decrypting link share keys',
    '2122': 'Error encrypting a share key',
    '2123': 'The encrypted password is invalid or incorrect',
    '2200': 'Import key failed for WebCrypto',
    '2501': 'Encryption failed',
    '2502': 'Decryption failed',
    '2503': 'Encryption failed',
    '2504': 'Decryption failed',
    '2505': 'Key stretch failed',
    // error codes 3000 -> 3200 are related to private downloads
    // download errors
    '3000': 'Your web browser is not fully supported.',
    '3010': 'Your web browser is not fully supported.',
    '3011': 'Your web browser is not fully supported.',
    '3012': 'Your web browser is not fully supported.',
    '3020': 'File too large to download with browser decryption.',
    // storage filesystem
    '3050': 'Your web browser encountered an error.',
    '3100': 'Your web browser encountered an error.',
    '3101': 'Your web browser encountered an error.',
    '3110': 'Your web browser encountered an error.',
    '3111': 'Your web browser encountered an error.',
    '3112': 'Your web browser encountered an error.',
    // previews
    '3200': 'Preview encountered an error',
    '3201': 'Preview encountered a network error',
    '3202': 'Preview encountered an unknown error',
    '3210': 'This request was not initiated by the same client',
    '3211': 'This request has expired, please try again.',
    '3212': 'Preview is not allowed on this account',
    '3213': 'Document password is incorrect',
    '3500': 'Incompatible Characters or Filetypes detected. Please see <a href=\'https://www.sync.com/help/why-arent-certain-files-on-my-computer-syncing/\' target=\'_blank\'>this page</a>.',
    '3610': 'You cannot copy/move the file to the same location.',
    '4001': 'The browser does not support JSON.',
    '4002': 'This browser is very old and cannot support addEventListener',
    '4101': 'Local storage was unsuccessful',
    '4102': 'Local storage was unsuccessful.  Private browsing mode may be enabled.  Please disable private browsing mode',
    '5001': 'Your browser is unable to download and decrypt data.  We suggest upgrading to the latest version.',
    '6001': 'Incorrect login credentials',
    '6002': 'Error logging in, please contact support',
    '7000': 'Connection error uploading',
    '7001': 'Received invalid response attempting to download this file',
    '7005': 'Upload timed out.',
    '7010': 'Error reading source file for upload',
    '7011': 'Uploaded payload does not match expected size',
    '7012': 'Uploaded file has changed, please re-queue',
    '7020': 'Invalid response received during file upload',
    '7021': 'Uploads are not enabled',
    '7022': 'Invalid response received while finishing the backup',
    '7023': 'No response recieved during upload',
    '7024': 'Error finishing the backup, did not receive success',
    '7030': 'File already exists.  Rename and try again.',
    '7100': 'Connection error downloading',
    '7101': 'Timeout error downloading',
    '7102': 'The file is unavailble',
    '7103': 'This file may be corrupted or damaged',
    '7200': 'Uploading folders are not supported',
    '7201': 'Cannot determine file name',
    '7202': 'Empty file (0kb) cannot be uploaded',
    '7210': 'Cancelled upload of folder',
    '7211': 'Error occurred reading directory',
    '7212': 'Error creating a directory',
    '7213': 'Could not determine location to upload files.  Please refresh the page and try again.',
    '7214': 'An unknown error occurred with folder upload',
    '7215': 'Error preparing file to upload',
    '7216': 'Could not retrieve the file to upload',
    '7240': 'For large folder uploads you will need to upload via the Sync desktop app',
    '7241': 'Please enter a name for this file',
    '7242': 'Please select the type of file',
    '7243': 'Filenames must not contains slashes (/ or \)',
    '7244': 'Error in create document',
    // Zip error codes
    '7500': 'Fatal error preparing zip file.',
    '7501': 'Empty folders cannot be zipped',
    '7502': 'Server error: Not enough space',
    '7503': 'Server error: Unknown status code',
    // errors from server Authorization
    '8000': 'You must have a pro account',
    '8001': 'Your email must be verified in order to use this feature',
    '8002': 'This is a read-only folder',
    '8003': 'Disk space limit reached',
    '8004': 'Your ip address has been rate-limited for 30 minutes',
    '8005': 'You must be the share admin to use this feature',
    '8006': 'Your account has been rate-limited for sending out too many emails',
    '8007': 'As a child account, this feature is unavailable.',
    '8008': 'You must be the admin of your Multi User account',
    '8009': "You've hit the email change limit. We're ensuring security. Please wait a bit before trying again. Thanks!",
    '8018': 'Please enter the link password.',
    '8019': 'The link password is incorrect',
    '8020': 'This link has expired, please contact the owner of the link',
    '8021': 'This link has reached the download limit.  Please contact the owner of the link',
    '8022': 'Please enter your Authentication Token',
    '8023': 'Authentication credentials are incorrect',
    '8024': 'Authentication credentials are incorrect',
    '8025': 'This link has been suspended',
    '8026': 'This link has been deleted by the owner.',
    '8027': 'Folder not found, please make sure you are accessing the correct link.',
    '8030': 'Please verify your email address to enable sharing features.',
    '8031': 'Link has reached daily download limit. Contact link owner or upgrade account to get unlimited downloads.',
    '8050': 'This account is cancelled',
    '8051': 'Account suspended.',
    '8052': 'This link is currently unavailable, please contact the link owner',
    '8053': 'This account has been closed',
    '8055': 'Account migrating',
    '8056': 'Account migrated',
    '8060': 'Authentication credentials are incorrect',
    '8061': 'Authentication credentials are incorrect',
    '8100': 'This is already your email address',
    '8101': 'Incorrect password',
    '8102': 'This email is already in use',
    '8103': 'Please verify your current email',
    '8104': 'This email change request is invalid or already used',
    '8105': 'This email change request has expired',
    '8106': 'This email is taken, please choose a new one',
    '8107': 'This email is an invalid format.  Please contact support if this is a mistake',
    '8108': 'The single sign-on authentication credentials are incorrect',
    '8109': 'Single sign-on is not available on this account',
    '8110': 'Single sign-on is not available on this account',
    '8111': 'One or more emails are in invalid format.',
    '8112': 'Error is occured while reading file.',
    '8113': 'Please import valid .csv file.',
    '8114': 'Please select a csv file or add user email(s) in textbox.',
    '8115': 'One or more emails doesn\'t have a role defined.',
    '8116': 'Number of emails to add exceeds allowed limit.',
    '8117': 'The CSV file format is not valid.',
    '8118': 'One or more emails doesn\'t exist in CSV.',
    '8119': 'Number of users to add exceeds the allowed user for current team.',
    '8120': 'An error occurred changing your password.  Your password remains the same',
    '8121': 'Your passwords do not match',
    '8122': 'One or more users are already part of another team or are already in provisioning. The following users will not be added:',
    '8123': 'This user is already assigned to this role, please select a new one.',
    '8124': 'One or more roles does not exist on this account.',
    '8130': 'Password reset was not enabled on this account',
    '8131': 'An error occurred sending the password reset.  Please try again in a few minutes',
    '8132': 'An error occurred encrypting reset keys',
    '8133': 'An error occurred decrypting reset keys',
    '8134': 'An error occurred with the password reset private key',
    '8135': 'An error occurred enabling password reset',
    '8136': 'An error occurred completing the password reset request',
    '8137': 'The invitation has expired, please contact your admin.',
    '8200': 'This would exceed your limit for your multi user plan',
    '8201': 'Cannot locate this user',
    '8202': 'This user does not exist, or is not a child of your multi user plan',
    '8203': 'The referral code is incorrect',
    '8205': 'You may only add free accounts to your Teams plan.',
    '8206': 'This user is already under your multi user plan',
    '8207': 'Parent user does not have a valid subscription, please contact support at https://www.sync.com/support/',
    '8210': 'We currently allow a maximum of 99 users.  Please contact us if you require more.',
    '8211': 'A file exists with this name already',
    '8212': 'The file extension must be (.docx, .pptx, .xlsx)',
    '8213': 'File name is not valid',
    //related to multi user
    '8220': 'Invalid action for adding a new user',
    '8221': 'Action does not exist',
    '8300': 'You must accept the terms of service and privacy policy to sign up',
    '8301': 'Your passwords do not match',
    '8302': 'This account has already signed up, please log in to join this share',
    '8303': 'Missing password',
    '8304': 'Password should be minimum of 6 characters and maximum of 72 characters',
    '8305': 'Missing one or more passwords',
    '8500': 'An error occurred loading your billing profile',
    '8501': 'An error occurred loading your billing settings',
    '8505': 'Legacy Account: Please contact billing@sync.com if you wish to make changes to your plan',
    '8506': 'Please contact billing to complete the upgrade',
    '8520': 'An error occurred loading the products',
    '8550': 'An error occurred creating the order session',
    '8551': 'Your subscription is in a cancelled state.  Please contact support at https://www.sync.com/support/ for assistance',
    '8401': 'Permission denied',
    '9000': 'An error occurred talking to the API',
    '9001': 'An empty result was returned from the API',
    // These are HTTP STATUS code errors.  CODE - 9000 = HTTP status.  E.g., 9429 = http status 429
    '9400': 'Not authorized',
    '9401': 'You are not logged in',
    '9402': 'Your session has expired',
    '9429': 'IP has been rate limited for 30 minutes due to incorrect password attempts',
    '9451': 'Authentication credentials are incorrect',
    '9452': 'Authentication credentials are incorrect',
    '9500': 'Internal server error. Visit <a href=\'https://www.sync.com/support/\' target=\'_blank\'>https://www.sync.com/support/</a>',
    '9502': 'Timeout error',
    '9504': 'Gateway timeout error',
    '9521': 'Our servers are a bit busy. Please try again shortly.',
    '9522': 'Our servers are a bit busy. Please try again shortly.',
    '9523': 'Our servers are a bit busy. Please try again shortly.',
    '9524': 'Our servers are a bit busy. Please try again shortly.',
    '9525': 'Our servers are a bit busy. Please try again shortly.',
    '9526': 'Our servers are a bit busy. Please try again shortly.',
    '9527': 'Our servers are a bit busy. Please try again shortly.',
    '9528': 'Our servers are a bit busy. Please try again shortly.',
    '9529': 'Our servers are a bit busy. Please try again shortly.',
    // Office Errors
    '11000': 'An error occurred sending the file to Microsoft',
    '11001': 'This file is too out of date to work on collaboratively; please wait or contact support.',
    '11002': 'An error occurred sending the file to Microsoft',
    '11003': 'An error occurred sending the file to Microsoft',
    '11004': 'The handshake could not be started to initialize communication with Microsoft',
    '11005': 'An error occurred sending the file to Microsoft',
    '11006': 'An error occurred sending the file to Microsoft, request took too long',
    '11009': 'This operation is not allowed',
    '11010': 'This file is not compatible with Office 365',
    // OAuth Errors
    '11100': 'An unknown error occured during authentication',
    '11101': 'This link is not valid',
    '11102': 'This link has expired',
    '11103': 'You cannot link with this app',
    '11104': 'You already have this app linked, remove it before attempting to link again',
    // Apps/App Share Errors
    '11200': 'An error occured connecting apps to this folder',
    '11201': 'An error occured sharing this folder with the app',
    '11202': 'An error occured with the folder connecting to the app',
    '11203': 'You do not have permission to connect apps to this share; contact the share owner',
    '11210': 'This feature is not available for your account at this moment',
    '11211': 'This folder is too large too display',
    // ZIP server errors are extracted from API result
    '151000': 'An error occurred creating zip',
    '151001': 'An error occurred creating zip',
    '151002': 'An error occurred creating zip',
    '151003': 'An error occurred creating zip',
    '151005': 'An error occurred creating zip',
    '151006': 'An error occurred creating zip',
    '151007': 'An error occurred creating zip',
    '151008': 'An error occurred creating zip',
    '151009': 'An error occurred creating zip',
    '151010': 'An error occurred creating zip',
    '151011': 'An error occurred creating zip',
    '151012': 'An error occurred creating zip',
    '151013': 'This folder is too large to create a zip file.',
};
