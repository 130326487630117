"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var run_upload_folder_service_1 = require("../services/run-upload-folder.service");
var DialogFolderUploadComponent = /** @class */ (function () {
    function DialogFolderUploadComponent(activeModal, runUploadFolderService) {
        this.activeModal = activeModal;
        this.runUploadFolderService = runUploadFolderService;
    }
    DialogFolderUploadComponent.prototype.ngOnInit = function () {
        this.view = this.runUploadFolderService.view;
    };
    DialogFolderUploadComponent.prototype.isFinished = function () {
        return (this.view.filePendingCount === 0 &&
            this.view.pendingBytes === 0 &&
            !this.view.isProcessing &&
            !this.view.isScanning);
    };
    DialogFolderUploadComponent.prototype.restart = function () {
        this.runUploadFolderService.restart();
    };
    DialogFolderUploadComponent.prototype.getMakeDirPercent = function () {
        return ((this.view.folderMadeCount / this.view.folderCount) *
            100).toFixed(3);
    };
    DialogFolderUploadComponent.prototype.cancel = function () {
        this.runUploadFolderService.cancel();
        this.activeModal.close();
    };
    return DialogFolderUploadComponent;
}());
exports.DialogFolderUploadComponent = DialogFolderUploadComponent;
