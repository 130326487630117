"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var DisplayPaginationComponent = /** @class */ (function () {
    function DisplayPaginationComponent() {
    }
    DisplayPaginationComponent.prototype.ngOnInit = function () { };
    return DisplayPaginationComponent;
}());
exports.DisplayPaginationComponent = DisplayPaginationComponent;
