"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base64_service_1 = require("./base64.service");
var sync_digest_service_1 = require("./crypt/sync-digest.service");
var store_1 = require("@ngrx/store");
var fromRoot = require("../reducers");
var environment_1 = require("../../environments/environment");
var http_1 = require("@angular/common/http");
var logger_service_1 = require("./logger.service");
var i0 = require("@angular/core");
var i1 = require("./base64.service");
var i2 = require("./crypt/sync-digest.service");
var i3 = require("@angular/common/http");
var i4 = require("./logger.service");
var i5 = require("@ngrx/store");
// import ko
var UrlService = /** @class */ (function () {
    function UrlService(base64, digest, http, log, store) {
        this.base64 = base64;
        this.digest = digest;
        this.http = http;
        this.log = log;
        this.store = store;
        this.downloadhosts = ['/mfs'];
        this.uploadhosts = ['/mfs'];
        this.hosts = {
            web: ['/mfs'],
            compat: ['/mfs'],
            preview: ['/mfs']
        };
        this.init();
    }
    UrlService.prototype.init = function () {
        var _this = this;
        this.store
            .select(fromRoot.getAuthState)
            .subscribe(function (data) {
            if (!data.authenticated) {
                _this.userId = 0;
                _this.deviceId = 0;
            }
            else if (data.user && data.authenticated) {
                _this.userId = data.user.uid;
                _this.deviceId = data.user.web_device_id;
            }
            else {
                _this.userId = 0;
                _this.deviceId = 0;
            }
        });
    };
    UrlService.prototype.setHostMulti = function (pathlist) {
        var _this = this;
        var hostTypes = ['web', 'compat', 'preview'];
        hostTypes.forEach(function (hostType) {
            if (pathlist['servers_' + hostType]) {
                _this.setHostByType(hostType, pathlist['servers_' + hostType]);
            }
        });
        // this.hosts['web'].reverse();
        this.log.info("Using hosts " + JSON.stringify(this.hosts));
    };
    UrlService.prototype.setHostByType = function (type, hosts) {
        if (hosts && hosts.length) {
            hosts = hosts.map(function (val) { return 'https://' + val; });
            this.hosts[type] = hosts;
            if (type == 'web') {
                this.uploadhosts = hosts.slice(0);
                this.downloadhosts = hosts.slice(0);
                // this.uploadhosts.reverse();
                // this.downloadhosts.reverse();
            }
        }
    };
    // get $stateParams(): StateParams {
    //     return this.injector.get('$stateParams');
    // }
    // get $state(): StateService {
    //     return this.injector.get('$state');
    // }
    // handles both a shell-expanded string and plain string
    // e.g.,
    // https://{secure11.sync.com,secure21.sync.com}/proxyapi.fcgi?command=
    // and
    // https://secure11.sync.com/proxyapi.fcgi?command=
    // public setHosts(hosts: sync.IServerAddresses) {
    //     if (hosts.downloadbaseurl && hosts.downloadbaseurl.length) {
    //         let hostStr = this.base64.decode(hosts.downloadbaseurl[0]);
    //         this.downloadhosts = this.getHosts(hostStr);
    //     }
    //     if (hosts.uploadbaseurl && hosts.uploadbaseurl.length) {
    //         let hostStr = this.base64.decode(hosts.uploadbaseurl[0]);
    //         this.uploadhosts = this.getHosts(hostStr);
    //     }
    //     // This is a user id that is even.
    //     if (this.userId % 2 === 1) {
    //         // reverse the hosts.
    //         this.uploadhosts.reverse();
    //         this.downloadhosts.reverse();
    //     }
    //     // console.log(`U: ${angular.toJson(this.uploadhosts)}, D: ${angular.toJson(this.downloadhosts)}`);
    // }
    // private getHosts(hosts: string) {
    //     const braces = /\{(.*)\}/;
    //     return hosts.match(braces)
    //       ? hosts.match(braces)[1].split(',').map(item => hosts.replace(braces, item))
    //       : [ hosts ];
    // }
    /**
     * This method is used to proxy the request locally if a password is enabled on the acct.
     * @param cachekey link chache key
     */
    UrlService.prototype.getCompatDownloadPath = function (cachekey) {
        // console.log('getCompatDownloadHost ' + this.downloadhosts[0].replace('/proxyapi.fcgi?command=',''));
        // if not cors, return /mfs-${cachekey}
        return "/mfs-" + cachekey;
    };
    /**
     * Gets the return to value from the URL and returns it as a URL Encoded
     * component to be added to the login/logout state.
     */
    UrlService.prototype.getReturnTo = function () {
        var returnTo = '';
        if (window.location.pathname.indexOf('logout') > -1 || window.location.pathname.indexOf('login') > -1) {
            returnTo = undefined;
        }
        else {
            returnTo = encodeURIComponent(window.location.pathname + window.location.search);
        }
        return returnTo;
    };
    UrlService.prototype.mkSubscribe = function () {
        return [
            '/sub?cid=t_',
            this.userId,
            '&timestamp=', Date.now()
        ].join('');
    };
    UrlService.prototype.mkApi = function (host, apiName) {
        return this.decorateUrl([
            host, '?command=', apiName
        ]);
    };
    // called in legacy, no "references" will be found - see upload-service
    UrlService.prototype.mkUpload = function (host, apiName) {
        return this.decorateUrl([host, '/upload.fcgi?command=', apiName]);
    };
    UrlService.prototype.mkDownloadMulti = function () {
        return this.decorateUrl([this.downloadhosts[0], '/proxyapi.fcgi?command=downloadmulti']);
    };
    UrlService.prototype.mkDownloadPubLink = function (tItem, params, retry) {
        if (retry === void 0) { retry = 0; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var baseDomain, host, result, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        baseDomain = '';
                        host = (environment_1.environment.production) ? 'compat' : 'web';
                        this.log.info("Make download publink host: " + this.hosts[host][retry]);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 7]);
                        return [4 /*yield*/, this.http.get(this.hosts[host][retry] + '/isup.txt', { responseType: 'text' }).toPromise()];
                    case 2:
                        result = _a.sent();
                        baseDomain = this.hosts[host][retry];
                        return [2 /*return*/, baseDomain + this.mkDownloadPubLinkPassword(tItem, params)];
                    case 3:
                        ex_1 = _a.sent();
                        this.log.warn("Isup failed for " + this.hosts[host][retry] + "/isup.txt, retryamt = " + retry + ", hosts: " + this.hosts[host].length);
                        if (!this.hosts[host][retry + 1]) return [3 /*break*/, 5];
                        this.log.warn("Retrying " + host + " publink " + retry);
                        return [4 /*yield*/, this.mkDownloadPubLink(tItem, params, retry + 1)];
                    case 4: return [2 /*return*/, _a.sent()];
                    case 5:
                        this.log.error("Failed to find a valid host, falling back to proxy");
                        return [2 /*return*/, this.mkDownloadPubLinkPassword(tItem, params)];
                    case 6: return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    UrlService.prototype.mkDownloadPubLinkPassword = function (tItem, params) {
        var qs = [];
        for (var key in params) {
            qs.push(key + "=" + params[key]);
        }
        qs.push('cachekey=' + tItem.cachekey);
        return [
            this.getCompatDownloadPath(tItem.cachekey),
            '/p/',
            encodeURIComponent(tItem.filename),
            '?',
            qs.join('&')
        ].join('');
    };
    UrlService.prototype.getDownloadPubLinkParams = function (tItem, rsaDatakey, encHost) {
        /**
         * The weird Content-Disposition line is a requirement due to oddities with
         * UTF-8 names, browsers and HTTP headers requirement to be ascii.
         *
         * When given a file name such as: Naïve file.txt
         *
         * Modern browsers will support rfc5987:
         * Content-Disposition: attachment; filename*=UTF-8''Na%C3%AFve%20file.txt
         *
         * Other browsers (e.g., Safari 5) do their own thing:
         * Content-Disposition: attachment; filename=Naïve file.txt
         *
         * Therefore we combine the two in one-header which has proven to be
         * compatible with all browsers we have tested.
         *
         * Here is some information:
         *
         * - We need to ensure when a browser downloads file, the name remains
         * - The challenge is that HTTP headers can only be ASCII.
         * - Force downloads are done via Content-Disposition attachment
         *   HTTP header.
         *
         * - Therefore, we encodeURI(Naïve file.txt) which results in: Na%C3%AFve%20file.txt
         * - if given to the browser in a content-disposition header, the browser
         *   will literally download the file as "Na%C3%AFve%20file.txt" and not
         *   convert it.
         * - This is why we have the convulted Content-Disposition header.
         *
         * Please only alter it if you've tested it on every browser known to man.
         *  - or just don't edit it.
         *
         * https://stackoverflow.com/questions/93551/how-to-encode-the-filename-parameter-of-content-disposition-header-in-http
         */
        return {
            'sharelink_id': tItem.sync_id,
            'linkoid': tItem.link_owner_id,
            'linkcachekey': tItem.linkID,
            'mode': 101,
            'datakey': rsaDatakey.replace(/=/g, ''),
            'header1': this.base64.encode('Content-Type: ' + tItem.mimetype).replace(/=/g, ''),
            'header2': this.base64.encode([
                'Content-Disposition: ',
                tItem.disposition,
                '; filename="',
                encodeURIComponent(tItem.filename),
                '";',
                'filename*=UTF-8\'\'',
                encodeURIComponent(tItem.filename),
                ';'
            ].join('')).replace(/=/g, ''),
            'uagent': this.digest.hash(navigator.userAgent),
            'ipaddress': 's',
            'errurl': encHost,
            'timestamp': Date.now(),
            'engine': "ln-" + environment_1.environment.version,
        };
    };
    UrlService.prototype.mkPreviewPubUser = function (tItem, rsaDatakey, timestamp) {
        /**
         * The weird Content-Disposition line is a requirement due to oddities with
         * UTF-8 names, browsers and HTTP headers requirement to be ascii.
         *
         * Don't touch unless you read the full comment in getDownloadPubLinkParams()
         *
         * @TODO merge the URL with the output of getDownloadPubLinkParams()
         */
        return this.decorateUrl([
            this.getCompatDownloadPath(tItem.cachekey),
            '/u/', encodeURIComponent(tItem.filename),
            '?cachekey=', tItem.cachekey,
            '&datakey=', rsaDatakey.replace(/=/g, ''),
            '&mode=101',
            '&action=stream',
            '&preview=pdf',
            '&api_version=1',
            '&header1=',
            this.base64.encode('Content-Type: ' + tItem.mimetype).replace(/=/g, ''),
            '&header2=',
            this.base64.encode([
                'Content-Disposition: ',
                tItem.disposition,
                '; filename="',
                encodeURIComponent(tItem.filename),
                '";',
                'filename*=UTF-8\'\'',
                encodeURIComponent(tItem.filename),
                ';'
            ].join('')).replace(/=/g, ''),
            '&servtime=', timestamp
        ]);
    };
    UrlService.prototype.mkDownloadPubUser = function (tItem, rsaDatakey, timestamp) {
        /**
         * The weird Content-Disposition line is a requirement due to oddities with
         * UTF-8 names, browsers and HTTP headers requirement to be ascii.
         *
         * Don't touch unless you read the full comment in getDownloadPubLinkParams()
         *
         * @TODO merge the URL with the output of getDownloadPubLinkParams()
         */
        return this.decorateUrl([
            this.getCompatDownloadPath(tItem.cachekey),
            '/u/', encodeURIComponent(tItem.filename),
            '?cachekey=', tItem.cachekey,
            '&datakey=', rsaDatakey.replace(/=/g, ''),
            '&mode=101',
            '&api_version=1',
            '&header1=',
            this.base64.encode('Content-Type: ' + tItem.mimetype).replace(/=/g, ''),
            '&header2=',
            this.base64.encode([
                'Content-Disposition: ',
                tItem.disposition,
                '; filename="',
                encodeURIComponent(tItem.filename),
                '";',
                'filename*=UTF-8\'\'',
                encodeURIComponent(tItem.filename),
                ';'
            ].join('')).replace(/=/g, ''),
            '&servtime=', timestamp
        ]);
    };
    UrlService.prototype.internalDownloadMfsUrl = function (tItem, rsaDatakey, timestamp) {
        return this.decorateUrl([
            '/u/', encodeURIComponent(tItem.filename),
            '?cachekey=', tItem.cachekey,
            '&datakey=', rsaDatakey.replace(/=/g, ''),
            '&mode=101',
            '&api_version=1',
            '&header1=',
            this.base64.encode('Content-Type: ' + tItem.mimetype).replace(/=/g, ''),
            '&header2=',
            this.base64.encode([
                'Content-Disposition: ',
                tItem.disposition,
                '; filename="',
                encodeURIComponent(tItem.filename),
                '";',
                'filename*=UTF-8\'\'',
                encodeURIComponent(tItem.filename),
                ';'
            ].join('')).replace(/=/g, ''),
            '&servtime=', timestamp
        ]);
    };
    UrlService.prototype.internalDownloadPubLink = function (tItem, params, retry) {
        if (retry === void 0) { retry = 0; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var baseDomain, host, result, ex_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        baseDomain = '';
                        host = (environment_1.environment.production) ? 'compat' : 'web';
                        this.log.info("Make download publink host: " + this.hosts[host][retry]);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 7]);
                        return [4 /*yield*/, this.http.get(this.hosts[host][retry] + '/isup.txt', { responseType: 'text' }).toPromise()];
                    case 2:
                        result = _a.sent();
                        baseDomain = this.hosts[host][retry];
                        return [2 /*return*/, baseDomain + this.internalDownloadPubLinkPassword(tItem, params)];
                    case 3:
                        ex_2 = _a.sent();
                        this.log.warn("Isup failed for " + this.hosts[host][retry] + "/isup.txt, retryamt = " + retry + ", hosts: " + this.hosts[host].length);
                        if (!this.hosts[host][retry + 1]) return [3 /*break*/, 5];
                        this.log.warn("Retrying " + host + " publink " + retry);
                        return [4 /*yield*/, this.internalDownloadPubLink(tItem, params, retry + 1)];
                    case 4: return [2 /*return*/, _a.sent()];
                    case 5:
                        this.log.error("Failed to find a valid host, falling back to proxy");
                        return [2 /*return*/, this.internalDownloadPubLinkPassword(tItem, params)];
                    case 6: return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    UrlService.prototype.internalDownloadPubLinkPassword = function (tItem, params) {
        var qs = [];
        for (var key in params) {
            qs.push(key + "=" + params[key]);
        }
        qs.push('cachekey=' + tItem.cachekey);
        qs.push('nocache=1');
        return [
            '/p/',
            encodeURIComponent(tItem.filename),
            '?',
            qs.join('&')
        ].join('');
    };
    UrlService.prototype.mkDownloadUrl = function (tItem, offset, reqLength, retry) {
        if (retry === void 0) { retry = 0; }
        // console.error('MAKE  DOWNLOAD URL = ' + this.hosts['web'][0])
        var urlParts = [this.hosts['web'][retry], '/proxyapi.fcgi?command=download',
            '&cachekey=', tItem.cachekey,
            '&blobtype=', tItem.blobtype,
            '&offset=', offset,
            '&engine=', 'cp-' + environment_1.environment.version,
            '&userid=', this.userId || 0,
            '&deviceid=', this.deviceId || 0,
            '&devicetypeid=3'
        ];
        if (tItem.blobtype == 'btFILE') {
            urlParts.push('&length=' + reqLength);
        }
        return this.decorateUrl(urlParts);
    };
    UrlService.prototype.mkExtApiUrl = function (path) {
        return this.decorateUrl([
            environment_1.environment.extapihost,
            "/" + path,
            '?'
        ]);
    };
    UrlService.prototype.decorateUrl = function (urlParts) {
        return urlParts.concat([
            '&engine=', 'cp-' + environment_1.environment.version,
            '&userid=', this.userId,
            '&deviceid=', this.deviceId,
            '&devicetypeid=3'
        ]).join('');
    };
    UrlService.ngInjectableDef = i0.defineInjectable({ factory: function UrlService_Factory() { return new UrlService(i0.inject(i1.Base64Service), i0.inject(i2.SyncDigestService), i0.inject(i3.HttpClient), i0.inject(i4.LoggerService), i0.inject(i5.Store)); }, token: UrlService, providedIn: "root" });
    return UrlService;
}());
exports.UrlService = UrlService;
