"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var compat_service_1 = require("../../core/compat.service");
var DialogboxLinkDirective = /** @class */ (function () {
    function DialogboxLinkDirective(compatService) {
        this.compatService = compatService;
        this.selected = [];
    }
    DialogboxLinkDirective.prototype.click = function (event) {
        var item = Array.isArray(this.selected)
            ? this.selected[0]
            : this.selected;
        var initialState = item.is_publink ? 1 : 0;
        var state = initialState;
        if (this.openState && !Number.isNaN(parseInt(this.openState, 10))) {
            state = parseInt(this.openState, 10);
        }
        this.compatService.showLinkDialog(item, state);
    };
    return DialogboxLinkDirective;
}());
exports.DialogboxLinkDirective = DialogboxLinkDirective;
