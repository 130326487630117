"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("../../core/api.service");
var logger_service_1 = require("../../core/logger.service");
var models_1 = require("../../shared/models");
var environment_1 = require("../../../environments/environment");
var i0 = require("@angular/core");
var i1 = require("../../core/api.service");
var i2 = require("../../core/logger.service");
var ProvisionWorkerService = /** @class */ (function () {
    function ProvisionWorkerService(api, log) {
        this.api = api;
        this.log = log;
    }
    ProvisionWorkerService.prototype.execute = function (username, password, passReset, sso) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pubkey;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.fetchText('/key/password-recover/pubkey')];
                    case 1:
                        pubkey = _a.sent();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                _this.WorkerObj = new Worker(environment_1.environment.jslibpath + 'provision-worker.js?d=' + Date.now());
                                _this.WorkerObj.addEventListener('message', function (ev) {
                                    var agentCmd = ev.data.agentcmd, cmdData = ev.data.data;
                                    switch (agentCmd) {
                                        case 'error':
                                            _this.log.error(cmdData);
                                            _this.log.error(ev.data.errcode);
                                            var errcode = parseInt(ev.data.errcode, 10) || 1000;
                                            _this.log.error(errcode + ' error code download-chunk-worker');
                                            reject(new models_1.ErrCode(errcode));
                                            break;
                                        case 'log':
                                            _this.log.info(cmdData);
                                            break;
                                        case 'runFinished':
                                            console.log('Resolved promise ');
                                            console.log(cmdData);
                                            resolve(cmdData);
                                            break;
                                        default:
                                            _this.log.error(agentCmd + " does not exist");
                                            reject(new models_1.ErrCode(8221));
                                            break;
                                    }
                                }, false);
                                _this.WorkerObj.postMessage({
                                    workercmd: 'run',
                                    data: {
                                        jsliburl: environment_1.environment.jslibpath,
                                        username: username,
                                        password: password,
                                        passReset: passReset,
                                        resetPubKey: pubkey,
                                        sso: sso
                                    }
                                });
                            })];
                }
            });
        });
    };
    ProvisionWorkerService.prototype.completed = function () {
        this.log.info('calling terminate on workerobj');
        this.WorkerObj.terminate();
        this.WorkerObj = null;
    };
    ProvisionWorkerService.ngInjectableDef = i0.defineInjectable({ factory: function ProvisionWorkerService_Factory() { return new ProvisionWorkerService(i0.inject(i1.ApiService), i0.inject(i2.LoggerService)); }, token: ProvisionWorkerService, providedIn: "root" });
    return ProvisionWorkerService;
}());
exports.ProvisionWorkerService = ProvisionWorkerService;
