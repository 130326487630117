"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base64_service_1 = require("../core/base64.service");
var dirty_out_service_1 = require("../core/crypt/dirty-out.service");
var sync_crypt_service_1 = require("../core/crypt/sync-crypt.service");
var logger_service_1 = require("../core/logger.service");
var user_service_1 = require("../core/user.service");
var models_1 = require("../shared/models");
var link_password_service_1 = require("../links/services/link-password.service");
var api_service_1 = require("../core/api.service");
var rxjs_1 = require("rxjs");
var notifications_service_1 = require("../core/notifications.service");
var blend_service_1 = require("../shared/services/blend.service");
var i0 = require("@angular/core");
var i1 = require("../core/logger.service");
var i2 = require("../core/base64.service");
var i3 = require("../core/user.service");
var i4 = require("../core/crypt/sync-crypt.service");
var i5 = require("../core/crypt/dirty-out.service");
var i6 = require("../links/services/link-password.service");
var i7 = require("../core/api.service");
var i8 = require("../core/notifications.service");
var i9 = require("../shared/services/blend.service");
var ShareNewService = /** @class */ (function () {
    function ShareNewService(loggerService, base64Service, userService, syncCryptService, DirtyOutService, linkPasswordService, apiService, notificationsService, blendService) {
        this.loggerService = loggerService;
        this.base64Service = base64Service;
        this.userService = userService;
        this.syncCryptService = syncCryptService;
        this.DirtyOutService = DirtyOutService;
        this.linkPasswordService = linkPasswordService;
        this.apiService = apiService;
        this.notificationsService = notificationsService;
        this.blendService = blendService;
    }
    ShareNewService.prototype.checkShare = function (syncId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.execute('sharecheck', {
                            sync_id: syncId,
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ShareNewService.prototype.addAppShare = function (syncId, shareId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, err_1, linkPass, linkpassword, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.notificationsService.stopNotificationLoop();
                        this.loggerService.info("Adding Apps to share " + shareId);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiService
                                .execute('shareintegrationadd', { sync_id: syncId })];
                    case 2:
                        data = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.loggerService.info("ShareNew Error creating app share on syncId " + syncId);
                        this.loggerService.error(err_1);
                        throw err_1;
                    case 4: return [4 /*yield*/, this.sanitizeShare(shareId)];
                    case 5:
                        linkPass = _a.sent();
                        linkpassword = this.base64Service.encode(linkPass);
                        _a.label = 6;
                    case 6:
                        _a.trys.push([6, 8, , 9]);
                        return [4 /*yield*/, this.encShare(shareId, 1000, undefined)];
                    case 7:
                        _a.sent();
                        this.loggerService.info('Successfully added App Share');
                        this.notificationsService.startNotificationLoop();
                        return [2 /*return*/];
                    case 8:
                        err_2 = _a.sent();
                        this.loggerService.info("ShareNew Error adding member to share " + shareId);
                        this.loggerService.error(err_2);
                        this.notificationsService.startNotificationLoop();
                        throw err_2;
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    ShareNewService.prototype.mkShare = function (syncId, filename, label, sharememberInvite, appShare, bg) {
        var _this = this;
        var subject = new rxjs_1.Subject();
        var shareId;
        this.shareDataProcess(syncId, filename, label, sharememberInvite, subject, appShare, bg).then(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var emaillist;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shareId = data.apiData.share_id;
                        if (!(data.bg == 0)) return [3 /*break*/, 3];
                        emaillist = sharememberInvite.queue.concat(sharememberInvite.roleQueue);
                        if (!!appShare) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.apiService.execute('sharemailinvite', {
                                share_id: shareId,
                                emaillist: emaillist,
                                enc_password: data.inviteKey,
                                pm_b64: this.base64Service.encode(sharememberInvite.privateMessage),
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.notificationsService.startNotificationLoop();
                        _a.label = 3;
                    case 3:
                        this.loggerService.info("Share created successfully " + shareId);
                        this.blendService.track(models_1.BlendEvent.SHARED_FOLDER_CREATED);
                        subject.next({
                            shareId: shareId,
                            inviteKey: data.inviteKey,
                            cachekey: data.apiData.cachekey,
                            sharekey: data.sharekey,
                            bg: data.bg,
                        });
                        subject.complete();
                        return [2 /*return*/];
                }
            });
        }); }).catch(function (err) {
            _this.notificationsService.startNotificationLoop();
            _this.loggerService.info("ShareNew Error creating share " + shareId);
            _this.loggerService.error(err);
            subject.error(err);
            subject.complete();
        });
        return subject;
    };
    ShareNewService.prototype.shareMailInvite = function (shareId, inviteKey, sharememberInvite, appShare) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var emaillist, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loggerService.info({ func: 'shareMailInvite', shareId: shareId });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        emaillist = sharememberInvite.queue.concat(sharememberInvite.roleQueue);
                        if (!!appShare) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.apiService.execute('sharemailinvite', {
                                share_id: shareId,
                                emaillist: emaillist,
                                enc_password: inviteKey,
                                pm_b64: this.base64Service.encode(sharememberInvite.privateMessage),
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        this.loggerService.info("Share created successfully " + shareId);
                        this.notificationsService.startNotificationLoop();
                        return [2 /*return*/, true];
                    case 4:
                        err_3 = _a.sent();
                        this.notificationsService.startNotificationLoop();
                        this.loggerService.info("ShareNew Error creating share " + shareId);
                        this.loggerService.error(err_3);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ShareNewService.prototype.postshareDataProcess = function (sync_id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cnt, subject, apiResult, inviteKey, shareId, newData, data, shareNewData, linkPass, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loggerService.info({ func: 'postshareDataProcess', sync_id: sync_id });
                        cnt = 0;
                        subject = new rxjs_1.Subject();
                        return [4 /*yield*/, this.apiService.execute('keysneedsharekeys', {
                                sync_id: sync_id,
                            })];
                    case 1:
                        data = _a.sent();
                        shareId = data.share_id;
                        cnt = data.cnt;
                        apiResult = data;
                        shareNewData = {};
                        if (!!data.enc_password) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.linkPasswordService.makeLinkPassword(24)];
                    case 2:
                        linkPass = _a.sent();
                        inviteKey = linkPass;
                        return [4 /*yield*/, this.syncCryptService.backfillShareData(linkPass, data.enc_share_keys, this.userService.get('pubkey'))];
                    case 3:
                        shareNewData = _a.sent();
                        _a.label = 4;
                    case 4:
                        newData = shareNewData;
                        this.loggerService.info('sanitizeShare encShareKeyListWithPubkeys');
                        return [4 /*yield*/, this.syncCryptService.encShareKeyListWithPubkeys(apiResult.enc_share_keys, apiResult.pubkeys)];
                    case 5:
                        result = _a.sent();
                        return [4 /*yield*/, this.setShareKeys(shareId, result, newData)];
                    case 6:
                        _a.sent();
                        return [4 /*yield*/, this.encShare(shareId, cnt, subject)];
                    case 7:
                        _a.sent();
                        return [2 /*return*/, { apiData: data, shareId: shareId, cnt: cnt }];
                }
            });
        });
    };
    ShareNewService.prototype.shareDataProcess = function (syncId, filename, label, sharememberInvite, subject, appShare, bg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var shareId, sharekey, inviteKey, cnt, apiData, shareData, sData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cnt = 0;
                        this.notificationsService.stopNotificationLoop();
                        return [4 /*yield*/, this.mkShareData(syncId, filename, label, sharememberInvite, appShare, bg)];
                    case 1:
                        shareData = _a.sent();
                        sharekey = this.syncCryptService.bytesToB64(shareData.share_key);
                        shareData.share_key = undefined;
                        inviteKey = shareData.invite_key; // url password
                        shareData.invite_key = undefined;
                        return [4 /*yield*/, this.shareAdd(shareData)];
                    case 2:
                        sData = _a.sent();
                        apiData = sData;
                        shareId = apiData.share_id;
                        cnt = apiData.cnt;
                        if (!(apiData.bg == 0)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.sanitizeShare(shareId)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.encShare(shareId, cnt, subject)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2 /*return*/, { apiData: apiData, cnt: cnt, inviteKey: inviteKey, bg: apiData.bg, sharekey: sharekey }];
                }
            });
        });
    };
    ShareNewService.prototype.addMember = function (shareData, sharememberInvite) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var emaillist, linkPass, linkpassword, _a, err_4;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.notificationsService.stopNotificationLoop();
                        this.loggerService.info("Adding member to share " + JSON.stringify(shareData));
                        emaillist = sharememberInvite.queue.concat(sharememberInvite.roleQueue);
                        return [4 /*yield*/, this.apiService.execute('sharememberadd', {
                                sync_id: shareData.syncId,
                                share_id: shareData.shareId,
                                pm_b64: this.base64Service.encode(sharememberInvite.privateMessage),
                                emaillist: emaillist,
                                permissions: sharememberInvite.permissions,
                                displayname: sharememberInvite.displayName,
                            })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.sanitizeShare(shareData.shareId)];
                    case 2:
                        linkPass = _b.sent();
                        if (!shareData.encPassword) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.syncCryptService.linkpasswordDecrypt(shareData.encPassword)];
                    case 3:
                        _a = _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        _a = this.base64Service.encode(linkPass);
                        _b.label = 5;
                    case 5:
                        linkpassword = _a;
                        return [4 /*yield*/, this.apiService.execute('sharemailinvite', {
                                share_id: shareData.shareId,
                                emaillist: emaillist,
                                enc_password: linkpassword,
                                pm_b64: this.base64Service.encode(sharememberInvite.privateMessage),
                            }, false)];
                    case 6:
                        _b.sent();
                        _b.label = 7;
                    case 7:
                        _b.trys.push([7, 9, , 10]);
                        return [4 /*yield*/, this.encShare(shareData.shareId, 1000, undefined)];
                    case 8:
                        _b.sent();
                        this.loggerService.info('Successfully invited a share member');
                        this.notificationsService.startNotificationLoop();
                        return [2 /*return*/];
                    case 9:
                        err_4 = _b.sent();
                        this.loggerService.info("ShareNew Error adding member to share " + shareData.shareId);
                        this.loggerService.error(err_4);
                        this.notificationsService.startNotificationLoop();
                        throw err_4;
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    ShareNewService.prototype.removeSelected = function (shareData, removeMembers, remainMembers, purge) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pubkeys, sharekey, i, len, pub, i, len, pub, selected_1, encKeys, linkShareKey, resp;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.notificationsService.stopNotificationLoop();
                        pubkeys = [];
                        if (!(removeMembers.length && remainMembers.length)) return [3 /*break*/, 4];
                        sharekey = this.syncCryptService.bytesToB64(this.syncCryptService.mkShareKey());
                        for (i = 0, len = remainMembers.length; i < len; i++) {
                            if (remainMembers[i].pubkey) {
                                pub = new models_1.PubkeyData();
                                (pub.share_id = shareData.shareId),
                                    (pub.share_sequence = shareData.shareSequence);
                                pub.id = remainMembers[i].pubkeyId;
                                pub.key = remainMembers[i].pubkey;
                                pubkeys.push(pub);
                            }
                        }
                        if (shareData.peoplex && shareData.peoplex.length) {
                            this.loggerService.info("Encing " + shareData.peoplex.length + " peoplex");
                            for (i = 0, len = shareData.peoplex.length; i < len; i++) {
                                if (shareData.peoplex[i].pubkey) {
                                    pub = new models_1.PubkeyData();
                                    (pub.share_id = shareData.shareId),
                                        (pub.share_sequence = shareData.shareSequence);
                                    pub.id = shareData.peoplex[i].pubkeyId;
                                    pub.key = shareData.peoplex[i].pubkey;
                                    pubkeys.push(pub);
                                }
                            }
                        }
                        selected_1 = [];
                        removeMembers.map(function (cur) {
                            selected_1.push(cur.sharememberId);
                        });
                        // if we don't have an encPassword, we backfill
                        if (!shareData.encPassword) {
                            this.loggerService.warn('No enc password, backfill required');
                        }
                        return [4 /*yield*/, this.syncCryptService.encShareKeyWithPubkeys(sharekey, pubkeys)];
                    case 1:
                        encKeys = _a.sent();
                        return [4 /*yield*/, this.syncCryptService.linksharekeyReEncrypt(shareData.encPassword, sharekey, shareData.salt, shareData.iterations)];
                    case 2:
                        linkShareKey = _a.sent();
                        return [4 /*yield*/, this.apiService.execute('sharememberdelete', {
                                share_id: shareData.shareId,
                                sharemembers: selected_1,
                                sharedata: encKeys,
                                link_share_key: linkShareKey,
                                purge: purge,
                            })];
                    case 3:
                        resp = _a.sent();
                        return [2 /*return*/, resp];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ShareNewService.prototype.sanitizeShare = function (shareId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var apiResult, newData, inviteKey, data, shareNewData, linkPass, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loggerService.info("sanitizeShare shareId = " + shareId);
                        return [4 /*yield*/, this.apiService.execute('keysneedsharekeys', {
                                share_id: shareId,
                            })];
                    case 1:
                        data = _a.sent();
                        this.loggerService.info("KeysApi need shareKeys " + JSON.stringify(data));
                        apiResult = data;
                        shareNewData = {};
                        if (!!data.enc_password) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.linkPasswordService.makeLinkPassword(24)];
                    case 2:
                        linkPass = _a.sent();
                        inviteKey = linkPass;
                        return [4 /*yield*/, this.syncCryptService.backfillShareData(linkPass, data.enc_share_keys, this.userService.get('pubkey'))];
                    case 3:
                        shareNewData = _a.sent();
                        _a.label = 4;
                    case 4:
                        newData = shareNewData;
                        this.loggerService.info('sanitizeShare encShareKeyListWithPubkeys');
                        return [4 /*yield*/, this.syncCryptService.encShareKeyListWithPubkeys(apiResult.enc_share_keys, apiResult.pubkeys)];
                    case 5:
                        result = _a.sent();
                        return [4 /*yield*/, this.setShareKeys(shareId, result, newData)];
                    case 6:
                        _a.sent();
                        this.loggerService.info('sanitizeShare set sharekeys');
                        return [2 /*return*/, inviteKey];
                }
            });
        });
    };
    /**
     * @ngdoc method
     * @name  setShareKeys
     * @methodOf sync.service:KeysApi
     * @description
     * Creates a new share key and saves it.
     * @param  {Object} sharedata The share data to save
     * @returns {Promise} The promise from the API call
     */
    ShareNewService.prototype.setShareKeys = function (shareId, sharekeydata, sharenewData) {
        var result = {
            sharedata: sharekeydata,
            backfill_link: undefined,
        };
        if (sharenewData != undefined && (JSON.stringify({}) !== JSON.stringify(sharenewData))) {
            result.backfill_link = {
                share_id: shareId,
                link_sharekeys: sharenewData.link_sharekeys,
                salt: sharenewData.salt,
                enc_password: sharenewData.enc_password,
                iterations: sharenewData.iterations,
            };
        }
        return this.apiService.execute('keyssetsharekeys', result);
    };
    ShareNewService.prototype.shareAdd = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var chkCount, apiData, err_5, isShared_1, syncId;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        chkCount = 0;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiService.execute('shareadd', params, false)];
                    case 2:
                        apiData = _a.sent();
                        return [2 /*return*/, apiData];
                    case 3:
                        err_5 = _a.sent();
                        isShared_1 = function () {
                            return tslib_1.__awaiter(this, void 0, void 0, function () {
                                var pathitem, chkErr_1;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (chkCount > 200) {
                                                this.loggerService.error('Waited for 10 minutes for the share to complete');
                                                throw { code: 1606 };
                                            }
                                            this.loggerService.warn('Checking if shared pathitem');
                                            _a.label = 1;
                                        case 1:
                                            _a.trys.push([1, 3, , 4]);
                                            return [4 /*yield*/, this.apiService.execute('pathget', { sync_id: params.sync_id })];
                                        case 2:
                                            pathitem = _a.sent();
                                            if (pathitem.is_shared) {
                                                err_5.share_id = pathitem.share_id;
                                                throw err_5;
                                            }
                                            else {
                                                chkCount++;
                                                setTimeout(function () {
                                                    isShared_1();
                                                }, 3000);
                                            }
                                            return [3 /*break*/, 4];
                                        case 3:
                                            chkErr_1 = _a.sent();
                                            this.loggerService.error('Failed getting path for isShared');
                                            this.loggerService.error(chkErr_1);
                                            chkCount++;
                                            setTimeout(function () {
                                                isShared_1();
                                            }, 3000);
                                            return [3 /*break*/, 4];
                                        case 4: return [2 /*return*/];
                                    }
                                });
                            });
                        };
                        if (err_5.httpstatus > 499 || err_5.httpstatus == 0) {
                            isShared_1();
                        }
                        else {
                            if (typeof err_5 == 'object' && err_5.code == 1610) {
                                syncId = void 0;
                                if (err_5.msg) {
                                    syncId = parseInt(err_5.msg.split('share in a share sync_id')[1], 10);
                                }
                                throw { code: 1610, data: syncId };
                            }
                            this.loggerService.error('Rejecting shareAdd');
                            throw err_5;
                        }
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ShareNewService.prototype.mkShareData = function (syncId, filename, label, sharememberInvite, appShare, bg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var emaillist, linkPass, shareData, encName, params;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        emaillist = sharememberInvite.queue.concat(sharememberInvite.roleQueue);
                        if (!emaillist.length && !appShare) {
                            this.handleErr({ error_code: 1605 });
                        }
                        return [4 /*yield*/, this.linkPasswordService.makeLinkPassword(24)];
                    case 1:
                        linkPass = _a.sent();
                        return [4 /*yield*/, this.syncCryptService.mkShareData(linkPass, this.userService.get('pubkey'))];
                    case 2:
                        shareData = _a.sent();
                        return [4 /*yield*/, this.syncCryptService.filenameEncrypt(filename, this.syncCryptService.bytesToB64(shareData.sharekey))];
                    case 3:
                        encName = _a.sent();
                        params = {
                            share_key: shareData.sharekey,
                            invite_key: this.base64Service.encode(shareData.invite_key),
                            enc_name: encName,
                            sync_id: syncId,
                            enc_share_key: shareData.enc_share_key,
                            link_share_key: this.syncCryptService.bytesToB64(shareData.link_sharekey),
                            label: this.base64Service.encode(label),
                            emaillist: emaillist,
                            pm_b64: this.base64Service.encode(sharememberInvite.privateMessage),
                            permissions: sharememberInvite.permissions,
                            display_name: sharememberInvite.displayName,
                            enc_password: shareData.enc_password,
                            iterations: shareData.iterations,
                            salt: shareData.salt,
                            app_integrated: appShare ? true : false,
                            bg: bg
                        };
                        return [2 /*return*/, params];
                }
            });
        });
    };
    ShareNewService.prototype.encShareKeys = function (shareId, data, sharekey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var linkPass, shareData, sharekeys;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.linkPasswordService.makeLinkPassword(24)];
                    case 1:
                        linkPass = _a.sent();
                        return [4 /*yield*/, this.syncCryptService.mkShareData(linkPass, this.userService.get('pubkey'))];
                    case 2:
                        shareData = _a.sent();
                        if (!data.pubkeys.length) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.syncCryptService.encShareKeyWithPubkeys(sharekey, data.pubkeys)];
                    case 3:
                        sharekeys = _a.sent();
                        _a.label = 4;
                    case 4: return [4 /*yield*/, this.setShareKeys(shareId, sharekeys, shareData)];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ShareNewService.prototype.encMembers = function (shareId, sharekey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var keyData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.execute('keysneedsharekeys', { share_id: shareId })];
                    case 1:
                        keyData = _a.sent();
                        if (!(keyData.pubkeys && keyData.pubkeys.length)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.encShareKeys(shareId, keyData, sharekey)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ShareNewService.prototype.encShare = function (shareId, cnt, subject, dfd) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!dfd) {
                            dfd = Promise.resolve();
                        }
                        this.loggerService.info('func: encShare ' + 'encShare ' + shareId + ' cnt = ' + cnt);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.DirtyOutService.executeForShare(shareId)];
                    case 2:
                        data = _a.sent();
                        this.loggerService.info('Execute for share ' + shareId);
                        console.warn(data);
                        if (!data || (Array.isArray(data) && data.length === 0)) {
                            this.loggerService.info('No more items in list, stopping');
                            if (subject) {
                                subject.next({
                                    count: cnt,
                                    completed: cnt,
                                });
                            }
                            return [2 /*return*/];
                        }
                        else if (data && data.completed) {
                            if (subject) {
                                subject.next({
                                    count: cnt,
                                    completed: data.completed,
                                });
                            }
                            this.encShare(shareId, cnt, subject, dfd);
                        }
                        else {
                            if (subject) {
                                subject.next({
                                    count: cnt,
                                    completed: cnt,
                                });
                            }
                            return [2 /*return*/];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_6 = _a.sent();
                        throw err_6;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ShareNewService.prototype.handleErr = function (errData) {
        this.notificationsService.startNotificationLoop();
        if (typeof errData == 'object' && errData.error_code) {
            throw errData;
        }
        else {
            throw {
                code: 9000,
                msg: 'Unknown error creating a share',
            };
        }
    };
    ShareNewService.ngInjectableDef = i0.defineInjectable({ factory: function ShareNewService_Factory() { return new ShareNewService(i0.inject(i1.LoggerService), i0.inject(i2.Base64Service), i0.inject(i3.UserService), i0.inject(i4.SyncCryptService), i0.inject(i5.DirtyOutService), i0.inject(i6.LinkPasswordService), i0.inject(i7.ApiService), i0.inject(i8.NotificationsService), i0.inject(i9.BlendService)); }, token: ShareNewService, providedIn: "root" });
    return ShareNewService;
}());
exports.ShareNewService = ShareNewService;
