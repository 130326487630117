"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./header-default.component.css.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/router");
var i3 = require("@angular/common");
var i4 = require("./header-default.component");
var i5 = require("@ng-bootstrap/ng-bootstrap");
var styles_HeaderDefaultComponent = [i0.styles];
var RenderType_HeaderDefaultComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HeaderDefaultComponent, data: {} });
exports.RenderType_HeaderDefaultComponent = RenderType_HeaderDefaultComponent;
function View_HeaderDefaultComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "nav", [["class", "navbar navbar-default navbar-fixed-top navbar-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "navbar-header navbar-white-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["class", "navbar-brand"], ["routerLink", "/login"], ["style", "width:100%"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["id", "navbar-logo"], ["src", "images/sync_logo.svg"], ["style", "height: 46px;width:172px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "n"], ["id", "navbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "ul", [["class", "nav navbar-nav navbar-right navbar-white-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "li", [["class", "hidden-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["href", "https://www.sync.com/signup?from=cpsignout"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Create account"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openContactSupport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Help"]))], function (_ck, _v) { var currVal_2 = "/login"; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
exports.View_HeaderDefaultComponent_0 = View_HeaderDefaultComponent_0;
function View_HeaderDefaultComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sync-header-default", [], null, null, null, View_HeaderDefaultComponent_0, RenderType_HeaderDefaultComponent)), i1.ɵdid(1, 114688, null, 0, i4.HeaderDefaultComponent, [i5.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_HeaderDefaultComponent_Host_0 = View_HeaderDefaultComponent_Host_0;
var HeaderDefaultComponentNgFactory = i1.ɵccf("sync-header-default", i4.HeaderDefaultComponent, View_HeaderDefaultComponent_Host_0, {}, {}, []);
exports.HeaderDefaultComponentNgFactory = HeaderDefaultComponentNgFactory;
