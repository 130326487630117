"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var api_service_1 = require("../../core/api.service");
var BillingInvoiceComponent = /** @class */ (function () {
    function BillingInvoiceComponent(api, route) {
        this.api = api;
        this.route = route;
    }
    BillingInvoiceComponent.prototype.ngOnInit = function () {
        console.log('Loading invoice for ' + this.route.snapshot.params['id']);
        this.loadInvoice();
    };
    BillingInvoiceComponent.prototype.loadInvoice = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.execute('orderview', {
                                invoice_id: this.route.snapshot.params['id']
                            })];
                    case 1:
                        _a.invoice = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return BillingInvoiceComponent;
}());
exports.BillingInvoiceComponent = BillingInvoiceComponent;
