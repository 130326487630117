"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var rewards_service_1 = require("../services/rewards.service");
var user_service_1 = require("../../core/user.service");
var models_1 = require("../../shared/models");
var router_1 = require("@angular/router");
var GetstartedComponent = /** @class */ (function () {
    function GetstartedComponent(rewards, userService, router) {
        this.rewards = rewards;
        this.userService = userService;
        this.router = router;
        this.required = 5;
        this.returnCode = -1;
    }
    GetstartedComponent.prototype.ngOnInit = function () {
        this.bonusData = this.userService.getStarted();
        if (this.userService.get('has_gettingstarted')) {
            this.router.navigate(['/files']);
        }
    };
    GetstartedComponent.prototype.isValid = function () {
        return (this.userService.get('getstarted_completed') < this.required);
    };
    GetstartedComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.errcode = undefined;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.rewards.claimRewardOnce('sync-getting-started')];
                    case 2:
                        _a.sent();
                        this.userService.refresh();
                        this.returnCode = 0;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return GetstartedComponent;
}());
exports.GetstartedComponent = GetstartedComponent;
