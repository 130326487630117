"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("../../core/api.service");
var models_1 = require("../../shared/models");
var base64_service_1 = require("../../core/base64.service");
var link_password_service_1 = require("./link-password.service");
var environment_1 = require("../../../environments/environment");
var file_item_service_1 = require("../../file/file-item.service");
var blend_service_1 = require("src/app/shared/services/blend.service");
var i0 = require("@angular/core");
var i1 = require("../../core/api.service");
var i2 = require("../../core/base64.service");
var i3 = require("./link-password.service");
var i4 = require("../../file/file-item.service");
var i5 = require("../../shared/services/blend.service");
var LinkListService = /** @class */ (function () {
    function LinkListService(api, base64, linkPassword, fileItemService, blendService) {
        this.api = api;
        this.base64 = base64;
        this.linkPassword = linkPassword;
        this.fileItemService = fileItemService;
        this.blendService = blendService;
    }
    /**
     * Gets the link list data from the backend
     *
     * This will toggle the spinner property in the view.
     *
     * Formats the list for display in the CP
     * @returns {Promise} Resolves on completion.
     */
    LinkListService.prototype.getData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, list, i, len, _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.api.execute('linklist', {})];
                    case 1:
                        data = _c.sent();
                        list = [];
                        i = 0, len = data.linklist.length;
                        _c.label = 2;
                    case 2:
                        if (!(i < len)) return [3 /*break*/, 5];
                        data.linklist[i].download_count = data.linklist[i].download_count;
                        data.linklist[i].download_limit = data.linklist[i].download_limit;
                        data.linklist[i].is_expired = this.isExpired(data.linklist[i]);
                        data.linklist[i].is_download_limit = this.isDownloadLimit(data.linklist[i]);
                        data.linklist[i].is_download_close = this.isDownloadClose(data.linklist[i]);
                        data.linklist[i].loc_id = data.linklist[i].share_id + "-0-" + data.linklist[i].publink_id;
                        if (data.linklist[i].label) {
                            data.linklist[i].label = this.base64.decode(data.linklist[i].label);
                        }
                        data.linklist[i].parent_id = (data.linklist[i].type == 'dir')
                            ? data.linklist[i].sync_id
                            : data.linklist[i].pid;
                        _b = (_a = list).push;
                        return [4 /*yield*/, this.fileItemService.format(data.linklist[i])];
                    case 3:
                        _b.apply(_a, [_c.sent()]);
                        _c.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/, list];
                }
            });
        });
    };
    /**
     * Checks if the link has expired
     * @param  {Object}  item The link list item.
     * @return {Boolean}      True if the link is expired
     */
    // public getItemByKey<T>(key: string, value: T): sync.IFile {
    //     let obj;
    //     for (let i = 0, len = this.view.linklist.length; i < len; i++) {
    //         var curItem = this.view.linklist[i];
    //         if (curItem[key] == value) {
    //             obj = curItem;
    //             break;
    //         }
    //     }
    //     return obj;
    // }
    /**
     * Checks if the link has expired
     * @param  {Object}  item The link list item.
     * @return {Boolean}      True if the link is expired
     */
    LinkListService.prototype.isExpired = function (item) {
        return (item.exp_servtime && item.exp_servtime < Date.now());
    };
    /**
     * Checks if the link has reached the download limit
     * @param  {Object}  item The link list item.
     * @returns {Boolean}      True if the limit has been reached
     */
    LinkListService.prototype.isDownloadLimit = function (item) {
        return (item.download_limit > 0 &&
            item.download_count >= item.download_limit);
    };
    /**
     * Checks if the link download count is 75% of the way to the limit
     * @param  {Object}  item The link list item.
     * @return {Boolean}      True if >=75%
     */
    LinkListService.prototype.isDownloadClose = function (item) {
        return (item.download_count / item.download_limit * 100 > 75 &&
            !this.isDownloadLimit(item) &&
            item.download_limit > 0);
    };
    LinkListService.prototype.getLink = function (syncId, name) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var link, linkItem, _a, _b, joinStr;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.api.execute('linkget', {
                            sync_id: syncId
                        })];
                    case 1:
                        link = _c.sent();
                        linkItem = new models_1.LinkItem(link);
                        if (link.password) {
                            linkItem.passwordprotect = this.base64.decode(link.password);
                            linkItem.has_passwordprotect = true;
                        }
                        linkItem.is_expired = this.isExpired(linkItem);
                        linkItem.is_download_close = this.isDownloadClose(linkItem);
                        linkItem.is_download_limit = this.isDownloadLimit(linkItem);
                        if (name) {
                            linkItem.name = name;
                        }
                        _a = linkItem;
                        if (!(linkItem.linkversion == 2)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.linkPassword.getPassword(link.enc_key)];
                    case 2:
                        _b = _c.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.linkPassword.getLegacyPassword(link.share_id + '-1', link.enc_share_key)];
                    case 4:
                        _b = _c.sent();
                        _c.label = 5;
                    case 5:
                        _a.key = _b;
                        linkItem.togglePassword = true;
                        if (linkItem.linkversion == 2) {
                            linkItem.togglePassword = (linkItem.key.length === 35 && linkItem.key.indexOf('-') === 8);
                        }
                        linkItem.publinkOutput = '';
                        if (linkItem.is_app_link) {
                            linkItem.baseUrl = [environment_1.environment.linkhost, 'al'].join('');
                            linkItem.linkUrl = [
                                linkItem.baseUrl, '/', linkItem.cachekey,
                            ].join('');
                            linkItem.separatedUrlBase = [linkItem.baseUrl, '/', linkItem].join('');
                        }
                        else {
                            linkItem.baseUrl = [environment_1.environment.linkhost, 'dl'].join('');
                            joinStr = (linkItem.compat) ? '/' : '#';
                            linkItem.linkUrl = [
                                linkItem.baseUrl, '/', linkItem.cachekey,
                                joinStr, linkItem.key
                            ].join('');
                            linkItem.separatedUrlBase = [linkItem.baseUrl, '/', linkItem].join('');
                        }
                        this.blendService.track(models_1.BlendEvent.SHARE_LINK, {});
                        return [2 /*return*/, linkItem];
                }
            });
        });
    };
    LinkListService.ngInjectableDef = i0.defineInjectable({ factory: function LinkListService_Factory() { return new LinkListService(i0.inject(i1.ApiService), i0.inject(i2.Base64Service), i0.inject(i3.LinkPasswordService), i0.inject(i4.FileItemService), i0.inject(i5.BlendService)); }, token: LinkListService, providedIn: "root" });
    return LinkListService;
}());
exports.LinkListService = LinkListService;
