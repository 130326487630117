"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../shared/components/error/error.component.ngfactory");
var i2 = require("../../shared/components/error/error.component");
var i3 = require("../../core/user.service");
var i4 = require("@ng-bootstrap/ng-bootstrap");
var i5 = require("@angular/platform-browser");
var i6 = require("@angular/forms");
var i7 = require("./dialog-device-permission.component");
var i8 = require("../../core/api.service");
var styles_DialogDevicePermissionComponent = [];
var RenderType_DialogDevicePermissionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogDevicePermissionComponent, data: {} });
exports.RenderType_DialogDevicePermissionComponent = RenderType_DialogDevicePermissionComponent;
function View_DialogDevicePermissionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Permissions"])), (_l()(), i0.ɵeld(6, 0, null, null, 28, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "sync-error", [], null, null, null, i1.View_ErrorComponent_0, i1.RenderType_ErrorComponent)), i0.ɵdid(8, 114688, null, 0, i2.ErrorComponent, [i3.UserService, i4.NgbModal, i5.DomSanitizer], { errcode: [0, "errcode"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 25, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [["class", "col-sm-4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Change password:"])), (_l()(), i0.ɵeld(12, 0, null, null, 22, "div", [["class", "col-sm-8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 9, "div", [["class", "radio radio-top"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 8, "label", [], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 6, "input", [["id", "device-changepass-enable"], ["name", "changepass"], ["type", "radio"], ["value", "1"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 16)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 16).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 16)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 16)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i0.ɵnov(_v, 17).onChange() !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (i0.ɵnov(_v, 17).onTouched() !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = ((_co.changepass = $event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(16, 16384, null, 0, i6.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i6.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(17, 212992, null, 0, i6.RadioControlValueAccessor, [i0.Renderer2, i0.ElementRef, i6.ɵangular_packages_forms_forms_j, i0.Injector], { name: [0, "name"], value: [1, "value"] }, null), i0.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i6.DefaultValueAccessor, i6.RadioControlValueAccessor]), i0.ɵdid(19, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i0.ɵdid(21, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i0.ɵted(-1, null, [" Enabled "])), (_l()(), i0.ɵeld(23, 0, null, null, 9, "div", [["class", "radio radio-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 8, "label", [], null, null, null, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 6, "input", [["id", "device-changepass-disable"], ["name", "changepass"], ["type", "radio"], ["value", "0"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 26)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 26).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 26)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 26)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i0.ɵnov(_v, 27).onChange() !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (i0.ɵnov(_v, 27).onTouched() !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = ((_co.changepass = $event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(26, 16384, null, 0, i6.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i6.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(27, 212992, null, 0, i6.RadioControlValueAccessor, [i0.Renderer2, i0.ElementRef, i6.ɵangular_packages_forms_forms_j, i0.Injector], { name: [0, "name"], value: [1, "value"] }, null), i0.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i6.DefaultValueAccessor, i6.RadioControlValueAccessor]), i0.ɵdid(29, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i0.ɵdid(31, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i0.ɵted(-1, null, [" Disabled "])), (_l()(), i0.ɵeld(33, 0, null, null, 1, "div", [["class", "subtext"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Allow this device to change your account's password. "])), (_l()(), i0.ɵeld(35, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(36, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Save settings"])), (_l()(), i0.ɵeld(38, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errcode; _ck(_v, 8, 0, currVal_0); var currVal_8 = "changepass"; var currVal_9 = "1"; _ck(_v, 17, 0, currVal_8, currVal_9); var currVal_10 = "changepass"; var currVal_11 = _co.changepass; _ck(_v, 19, 0, currVal_10, currVal_11); var currVal_19 = "changepass"; var currVal_20 = "0"; _ck(_v, 27, 0, currVal_19, currVal_20); var currVal_21 = "changepass"; var currVal_22 = _co.changepass; _ck(_v, 29, 0, currVal_21, currVal_22); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 21).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 21).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 21).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 21).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 21).ngClassValid; var currVal_6 = i0.ɵnov(_v, 21).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 21).ngClassPending; _ck(_v, 15, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_12 = i0.ɵnov(_v, 31).ngClassUntouched; var currVal_13 = i0.ɵnov(_v, 31).ngClassTouched; var currVal_14 = i0.ɵnov(_v, 31).ngClassPristine; var currVal_15 = i0.ɵnov(_v, 31).ngClassDirty; var currVal_16 = i0.ɵnov(_v, 31).ngClassValid; var currVal_17 = i0.ɵnov(_v, 31).ngClassInvalid; var currVal_18 = i0.ɵnov(_v, 31).ngClassPending; _ck(_v, 25, 0, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18); }); }
exports.View_DialogDevicePermissionComponent_0 = View_DialogDevicePermissionComponent_0;
function View_DialogDevicePermissionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-dialog-device-permission", [], null, null, null, View_DialogDevicePermissionComponent_0, RenderType_DialogDevicePermissionComponent)), i0.ɵdid(1, 114688, null, 0, i7.DialogDevicePermissionComponent, [i4.NgbActiveModal, i8.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DialogDevicePermissionComponent_Host_0 = View_DialogDevicePermissionComponent_Host_0;
var DialogDevicePermissionComponentNgFactory = i0.ɵccf("sync-dialog-device-permission", i7.DialogDevicePermissionComponent, View_DialogDevicePermissionComponent_Host_0, { device: "device" }, {}, []);
exports.DialogDevicePermissionComponentNgFactory = DialogDevicePermissionComponentNgFactory;
