"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Actions = require("../actions/link-file-list.actions");
var func_1 = require("../shared/func");
exports.initialState = {
    cwd: undefined,
    pathlist: [],
    sharekey_id: '',
    owner_id: 0,
    sync_id: '',
    is_root: true,
    is_pro: 0,
    is_business: 0,
    compat: 1,
    previewonly: 0,
    file_edit: 0,
    upload: 0,
    passwordlock: '',
    error: undefined,
    loaded: false,
    sorted: false,
    predicate: 'default',
    direction: '',
    linkkey: '',
    publink_id: '',
    linkversion: 2,
    iterations: 0,
    salt: '',
    //whitelabel attributes
    image_cachekey: '',
    header_primary_color: '',
    header_text_color: '',
    button_primary_color: '',
    button_text_color: '',
    link_text_color: '',
    whitelabel_loaded: false,
    //comment feature
    allow_comment: 0,
    enc_comment_key: '',
    comment_notification: 1,
    //comment-block iframe
    iframeReady: false,
    //owner info
    id: 0,
    team_id: 0,
    email: '',
    sku: '',
    is_multi_admin: false,
    is_on_trial: false
};
function reducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case Actions.LINK_FILE_LIST_RESET:
            return exports.initialState;
        case Actions.LINK_FILE_LIST_SUCCESS:
            if (!action.payload) {
                return state;
            }
            return Object.assign({}, state, {
                cwd: action.payload.cwd,
                loaded: true,
                error: undefined,
                pathlist: action.payload.pathitems,
                sharekey_id: action.payload.share_id + "-1",
                owner_id: action.payload.oid,
                sync_id: action.payload.sync_id,
                is_root: action.payload.root_sync_id == action.payload.sync_id,
                root_sync_id: action.payload.root_sync_id,
                is_pro: action.payload.is_pro,
                is_business: action.payload.is_business,
                compat: action.payload.compat,
                previewonly: action.payload.previewonly,
                file_edit: action.payload.file_edit,
                upload: action.payload.upload,
                linkversion: action.payload.linkversion,
                iterations: action.payload.iterations,
                salt: action.payload.salt,
                //whitelabel attributes
                image_cachekey: action.payload.image_cachekey,
                header_primary_color: action.payload.header_primary_color,
                header_text_color: action.payload.header_text_color,
                button_primary_color: action.payload.button_primary_color,
                button_text_color: action.payload.button_text_color,
                link_text_color: action.payload.link_text_color,
                //comment feature
                allow_comment: action.payload.allow_comment,
                enc_comment_key: action.payload.enc_comment_key,
                comment_notification: action.payload.comment_notification,
                id: action.payload.id,
                team_id: action.payload.team_id,
                email: action.payload.email,
                sku: action.payload.sku,
                is_multi_admin: action.payload.is_multi_admin,
                is_on_trial: action.payload.is_on_trial,
            });
        case Actions.LINK_FILE_LIST_SUCCESS_WHITELABEL:
            return Object.assign({}, state, {
                whitelabel_loaded: true,
                //whitelabel attributes
                image_cachekey: action.payload.image_cachekey,
                header_primary_color: action.payload.header_primary_color,
                header_text_color: action.payload.header_text_color,
                button_primary_color: action.payload.button_primary_color,
                button_text_color: action.payload.button_text_color,
                link_text_color: action.payload.link_text_color,
            });
        case Actions.LINK_FILE_LIST_ERROR:
            return Object.assign({}, state, { error: action.payload });
        case Actions.IFRAME_READY:
            return Object.assign({}, state, { iframeReady: action.ready });
        case Actions.LINK_FILE_LIST_LOAD:
            return Object.assign({}, state, {
                loaded: false,
                sorted: false,
                publink_id: action.payload.publink_id,
                linkkey: action.payload.key,
                passwordlock: action.payload.passwordlock,
            });
        case Actions.LINK_FILE_LIST_LOAD_APP:
            return Object.assign({}, state, {
                loaded: false,
                sorted: false,
                publink_id: action.payload.publink_id,
                sync_id: action.payload.publink_id,
                passwordlock: action.payload.passwordlock,
            });
        case Actions.LINK_FILE_LIST_REFRESH:
            return Object.assign({}, state, {
                loaded: false,
                sorted: false,
            });
        case Actions.LINK_FILE_LIST_SET_PASSWORD:
            return Object.assign({}, state, {
                passwordlock: action.payload,
            });
        case Actions.LINK_FILE_LIST_SORT:
            var isAsc_1 = action.payload.direction === 'asc';
            var newlist = state.pathlist.slice(0);
            if (action.payload.direction === '') {
                newlist.sort(function (a, b) { return func_1.compareWebPath(a, b, isAsc_1, true); });
            }
            else {
                switch (action.payload.active) {
                    case 'name':
                        newlist.sort(function (a, b) {
                            return func_1.compareWebPath(a, b, isAsc_1, false);
                        });
                        break;
                    case 'usertime':
                        newlist.sort(function (a, b) {
                            return func_1.compare(a.usertime, b.usertime, isAsc_1);
                        });
                        break;
                    case 'size':
                        newlist.sort(function (a, b) {
                            return a.is_dir - b.is_dir ||
                                func_1.compare(a.size, b.size, isAsc_1);
                        });
                        break;
                    case 'default':
                        newlist.sort(function (a, b) {
                            return func_1.compareWebPath(a, b, isAsc_1, true);
                        });
                        break;
                    default:
                        newlist.sort(function (a, b) {
                            return func_1.compareWebPath(a, b, isAsc_1, true);
                        });
                        break;
                }
            }
            return Object.assign({}, state, {
                pathlist: newlist,
                sorted: true,
                predicate: action.payload.active,
                direction: action.payload.direction,
            });
        default:
            return state;
    }
}
exports.reducer = reducer;
exports.getCwd = function (state) { return state.cwd; };
exports.getState = function (state) { return state; };
exports.getPassword = function (state) { return state.passwordlock; };
