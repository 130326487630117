import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { LinkItem, ErrCode, LinkSendUrlApiInput } from '../../shared/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../core/user.service';
import { Base64Service } from '../../core/base64.service';
import { LoggerService } from '../../core/logger.service';
import { ApiService } from '../../core/api.service';
import { ValidateService } from '../../core/validate.service';
import { NotificationsService } from '../../core/notifications.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sync-link-manage',
    templateUrl: './link-manage.component.html'
})
export class LinkManageComponent implements OnInit, OnDestroy {

    @Input() link: LinkItem;


    @Output() state = new EventEmitter<number>();
    @Output() close = new EventEmitter<boolean>();

    public shareForm: FormGroup;
    public isPro = 0;
    public linkSendResult = 0;
    public isOnTrial = 0;
    public isEnced = true;

    public spinner = false;
    public errcode: ErrCode;

    public copyText = 'Copy link';
    private subscription: Subscription;

    constructor(
        private api: ApiService,
        private base64: Base64Service,
        private fb: FormBuilder,
        private log: LoggerService,
        private userService: UserService,
        private validateService: ValidateService,
        private notificationsService: NotificationsService
    ) { }

    ngOnInit() {
        this.isPro = this.userService.get('is_pro');
        this.isOnTrial = this.userService.get('is_on_trial');
        this.shareForm = this.fb.group({
            email: ['', [Validators.email, Validators.required]],
            message: [''],
            displayname: [this.base64.decode(this.userService.get('display_name')), Validators.required]
        });
        this.isEnced = this.link.cnt > 0 ? false : true;

        if (!this.isEnced) {
            this.subscription = this.notificationsService.getNotificationStatus().subscribe(isTaskRunning => {
                if (!isTaskRunning && !this.isEnced) {
                    this.isEnced = true;
                    this.subscription.unsubscribe();
                }
            });
        }
    }

    public copy(elemId) {
        const copyInput = <HTMLInputElement>document.getElementById(elemId);
        if (!copyInput) {
            this.log.warn('Could not find #' + elemId + ' ignoring');
            return;
        } else {
            this.copyText = 'Copied';
            copyInput.select();
            document.execCommand('copy');
            this.log.d('copied link url');
            window.setTimeout(() => {
                this.copyText = 'Copy link';
            }, 2000);
        }
    }

    public setState(state: number) {
        this.state.emit(state);
    }

    public closeDialog() {
        this.close.emit(false);
    }

    public async onSubmit() {
        const email = this.shareForm.get('email').value;
        const displayName = this.shareForm.get('displayname').value;

        try {
            this.linkSendResult = 0;
            this.spinner = true;

            if (!this.validateService.isDisplayNameValid(displayName)) {
                throw { code: 1659 };
            }

            this.errcode = null;
            const label = this.link.label || this.base64.encode('(No name provided)');
            await this.api.execute('linksendurl', {
                publink_id: this.link.cachekey,
                sync_id: this.link.sync_id,
                displayname: this.shareForm.get('displayname').value,
                linkname: label,
                linkurl: this.base64.encode(this.link.linkUrl),
                emailaddress: this.base64.encode(email),
                message: (this.userService.get('is_pro'))
                    ? this.base64.encode(this.shareForm.get('message').value)
                    : ''
            } as LinkSendUrlApiInput);
            this.linkSendResult = 1;
        } catch (ex) {
            this.spinner = false;
            this.errcode = ErrCode.fromException(ex);
            this.log.e('Error sending email to ' + email, ex);
        }
        this.spinner = false;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
