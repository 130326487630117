"use strict";
/**
 * category: {
 *  viewable: is this file viewable in the browser
 *  editable: is this file editable in the browser
 *  unicode: unused, should be removed from structure
 *  kind: a high-level grouping of the category.
 *  css-clas: the icon image to use found in images folder
 *  extensions: for each extension of a given category, override the values
 * }
 *
 * This const needs some re-work and cleaning up.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileType = {
    archive: {
        viewable: false,
        editable: false,
        unicode: false,
        kind: 'archive',
        cssclass: 'mime-archive',
        extensions: {
            '7z': {
                mimetype: 'application/x-7z-compressed'
            },
            'zip': {
                mimetype: 'application/zip'
            },
            'rar': {
                mimetype: 'application/x-rar-compressed'
            },
            'gz': {
                mimetype: 'application/x-gzip'
            },
            'gzip': {
                mimetype: 'application/x-gzip'
            },
            'tar': {
                mimetype: 'application/x-tar'
            },
            'bz2': {
                mimetype: 'application/x-bzip2'
            },
            'tgz': {
                mimetype: 'application/x-gtar'
            },
            'tbz2': {
                mimetype: 'application/x-gtar'
            },
            'dmg': {
                mimetype: 'application/x-apple-diskimage'
            }
        }
    },
    audio: {
        viewable: false,
        editable: false,
        unicode: false,
        kind: 'audio',
        cssclass: 'mime-audio',
        extensions: {
            'wma': {
                mimetype: 'audio/x-ms-wma'
            },
            'mp3': {
                mimetype: 'audio/mpeg',
                kind: 'mp3'
            },
            'ogg': {
                mimetype: 'application/ogg'
            },
            'wav': {
                mimetype: 'audio/x-wav'
            },
            'm4a': {
                mimetype: 'audio/mp4a-latm'
            }
        }
    },
    binary: {
        viewable: false,
        editable: false,
        unicode: false,
        kind: 'binary',
        cssclass: 'mime-bin',
        extensions: {
            'exe': {
                mimetype: 'application/x-ms-dos-executable',
                cssclass: 'mime-exe'
            },
            'bin': {
                mimetype: 'application/octet-stream'
            },
            'bat': {
                mimetype: 'application/x-ms-dos-executable',
                cssclass: 'mime-exe'
            },
            'iso': {
                mimetype: 'application/octet-stream',
                cssclass: 'mime-cdimage',
                kind: 'iso'
            }
        }
    },
    doc: {
        viewable: true,
        editable: false,
        unicode: false,
        kind: 'doc',
        cssclass: 'mime-doc',
        extensions: {
            'doc': {
                mimetype: 'application/msword'
            },
            'docx': {
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            },
            'dotx': {
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template'
            },
            'rtf': {
                mimetype: 'application/rtf'
            },
            'odt': {
                mimetype: 'application/vnd.oasis.opendocument.text'
            },
            'pdf': {
                mimetype: 'application/pdf',
                cssclass: 'mime-pdf',
                kind: 'pdf'
            }
        }
    },
    image: {
        viewable: true,
        editable: false,
        unicode: false,
        kind: 'image',
        cssclass: 'mime-image',
        extensions: {
            'jpg': {
                mimetype: 'image/jpeg'
            },
            'jpeg': {
                mimetype: 'image/jpeg'
            },
            'png': {
                mimetype: 'image/png'
            },
            'gif': {
                mimetype: 'image/gif'
            },
            'heic': {
                mimetype: 'image/heic',
                viewable: false
            },
            'heif': {
                mimetype: 'image/heif',
                viewable: false
            },
            'ai': {
                mimetype: 'application/illustrator',
                viewable: false,
                cssclass: 'mime-illustrator'
            },
            'ico': {
                mimetype: 'image/vnd.microsoft.icon',
                viewable: false
            },
            'psd': {
                mimetype: 'image/x-photoshop',
                viewable: false
            },
            'bmp': {
                mimetype: 'image/x-ms-bmp',
                viewable: false
            },
            'svg': {
                mimetype: 'image/svg+xml',
                viewable: false
            }
        }
    },
    office: {
        viewable: false,
        editable: false,
        unicode: false,
        kind: 'doc',
        cssclass: 'mime-doc',
        extensions: {
            'mdb': {
                mimetype: 'application/x-msaccess',
                cssclass: 'mime-mdb'
            },
            'ppt': {
                viewable: true,
                mimetype: 'application/vnd.ms-powerpoint',
                cssclass: 'mime-presentation'
            },
            'pptx': {
                viewable: true,
                mimetype: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                cssclass: 'mime-presentation'
            }
        }
    },
    spreadsheet: {
        viewable: true,
        editable: false,
        unicode: false,
        kind: 'spreadsheet',
        cssclass: 'mime-spreadsheet',
        extensions: {
            'xls': {
                mimetype: 'application/vnd.ms-excel'
            },
            'xlsx': {
                mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            'ods': {
                mimetype: 'application/vnd.oasis.opendocument.spreadsheet'
            }
        }
    },
    text: {
        viewable: true,
        editable: true,
        unicode: true,
        kind: 'text',
        cssclass: 'mime-txt',
        extensions: {
            'txt': {
                mimetype: 'text/plain'
            },
            'log': {
                mimetype: 'text/plain'
            },
            'ini': {
                mimetype: 'text/plain'
            },
            'csv': {
                mimetype: 'text/plain'
            },
            'sh': {
                mimetype: 'text/plain',
                cssclass: 'mime-shell',
                kind: 'script'
            },
            'php': {
                mimetype: 'text/plain',
                cssclass: 'mime-php',
                kind: 'script'
            },
            'py': {
                mimetype: 'text/plain',
                cssclass: 'mime-python',
                kind: 'script'
            },
            'pl': {
                mimetype: 'text/plain',
                cssclass: 'mime-perl',
                kind: 'script'
            },
            'html': {
                mimetype: 'text/plain',
                cssclass: 'mime-html'
            },
            'js': {
                mimetype: 'text/plain',
                cssclass: 'mime-javascript',
                kind: 'script'
            },
            'json': {
                mimetype: 'text/plain'
            },
            'css': {
                mimetype: 'text/plain',
                cssclass: 'mime-css'
            },
            'rb': {
                mimetype: 'text/plain',
                cssclass: 'mime-ruby',
                kind: 'script'
            },
            'xml': {
                mimetype: 'text/plain',
                cssclass: 'mime-xml',
                kind: 'script'
            },
            'sql': {
                mimetype: 'text/plain',
                cssclass: 'mime-sql'
            },
            'md': {
                mimetype: 'text/plain',
                cssclass: 'mime-markdown'
            },
            'markdown': {
                mimetype: 'text/plain',
                cssclass: 'mime-markdown'
            }
        }
    },
    video: {
        viewable: false,
        editable: false,
        unicode: false,
        kind: 'video',
        cssclass: 'mime-video',
        extensions: {
            'wmv': {
                mimetype: 'video/x-ms-wmv'
            },
            'avi': {
                mimetype: 'video/avi'
            },
            'flv': {
                mimetype: 'video/x-flv'
            },
            'mpg': {
                mimetype: 'video/mpeg'
            },
            'mpeg': {
                mimetype: 'video/mpeg'
            },
            'mov': {
                mimetype: 'video/quicktime'
            },
            'mkv': {
                mimetype: 'video/x-matroska '
            },
            'mp4': {
                mimetype: 'video/mp4'
            }
        }
    }
};
