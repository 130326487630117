import { LinkFileListService } from './../../link-consume/services/link-file-list.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LinkListItem, LinkItem, ErrCode, BlendEvent } from '../../shared/models';
import { Base64Service } from '../../core/base64.service';
import { ApiService } from '../../core/api.service';
import { UserService } from '../../core/user.service';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from '../../core/logger.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as LinkListActions from '../../actions/link-list.actions';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { Router } from '@angular/router';
import { BlendService } from '../../shared/services/blend.service';
import { renameLinkOptions } from '../../util/blend-property-names';
import { FileItemService } from '../../file/file-item.service';

@Component({
    selector: 'sync-link-settings',
    templateUrl: './link-settings.component.html',
})
export class LinkSettingsComponent implements OnInit {
    @Input() link: LinkItem;
    @Input() item: LinkListItem | sync.IFile;
    @Output() state = new EventEmitter<number>();
    @Output() close = new EventEmitter<boolean>();

    public expDate: NgbDateStruct;
    public expDateMin: NgbDateStruct;

    public isPro = false;
    public errcode: ErrCode;
    public spinner = false;
    public expTime: NgbTimeStruct;
    public hasComment = false;
    public hasBetaAccess = false;
    public isAppLink = false;

    public linkForm: FormGroup;
    public previewFieldValue: string;

    public isMSOfficeFile = false;

    constructor(
        private api: ApiService,
        private base64: Base64Service,
        private fb: FormBuilder,
        private log: LoggerService,
        private store: Store<fromRoot.State>,
        private userService: UserService,
        private router: Router,
        private crypt: SyncCryptService,
        private linkFileList: LinkFileListService,
        private blendService: BlendService,
        private fileItemService: FileItemService,
    ) {}

    ngOnInit() {
        const dt = new Date();
        this.expDateMin = {
            year: dt.getFullYear(),
            month: dt.getMonth() + 1,
            day: dt.getDate(),
        };

        this.isMSOfficeFile = this.fileItemService.isMSOffice(this.item as sync.IFile, 'edit');

        this.isPro = this.userService.get('is_pro');
        this.hasComment = this.userService.get('has_comment');
        this.hasBetaAccess = this.userService.get('has_beta_access');
        this.isAppLink = this.link.baseUrl.includes('/al');
        console.log('beta:', this.hasBetaAccess);
        console.log('hascomment:', this.hasComment);
        console.log('applink:', this.link.baseUrl.includes('/al'));
        if (this.link.exp_servtime) {
            const exp = new Date(this.link.exp_servtime);
            this.expDate = {
                year: exp.getFullYear(),
                month: exp.getMonth() + 1,
                day: exp.getDate(),
            };
            this.expTime = {
                hour: exp.getHours(),
                minute: exp.getMinutes(),
                second: 0,
            };
        }

        //if file_edit is enabled, file is not shared and file is MSOffice editable, enable the third radio button for edit file in file permissions
        //else check previewonly link setting and enable preview/download radio button
        if (this.link.file_edit === 1 && (!this.item.is_shared && (this.link.type != 'file' || this.isMSOfficeFile))) {
            this.previewFieldValue = 'edit';
        } else {
            this.previewFieldValue = this.link.previewonly === 0 ? 'download' : 'preview';
        }

        this.linkForm = this.fb.group({
            label: [this.base64.decode(this.link.label)],
            passwordprotect: [this.link.passwordprotect],
            key: [this.link.key],
            upload: [this.link.upload.toString() || '0', Validators.required],
            previewonly: [
                this.previewFieldValue,
                Validators.required,
            ],
            download_limit: [this.link.download_limit],
            email_notification: [
                this.link.email_notification.toString() || '0',
                Validators.required,
            ],
            compat: [this.link.compat.toString() || '0', Validators.required],
            exp_date: [this.expDate],
            exp_time: [this.expTime],
            allow_comment: [
                this.link.allow_comment.toString() || '1',
                Validators.required,
            ],
            comment_notification: [
                this.link.comment_notification.toString() || '1',
                Validators.required,
            ],
        });

        this.linkForm.controls.upload.valueChanges.subscribe((uploadFieldValue) => {
            // file permission radio buttons are disbled according to the upload radio values
            // reset previewonly field value when all file permission radio buttons are disabled
            // or when the edit file radio button is disabled
            if (this.link.type === 'dir') {
                if (uploadFieldValue === '2' || (uploadFieldValue === '0' && this.linkForm.value.previewonly === 'edit')) {
                    this.linkForm.controls.previewonly.setValue(this.link.previewonly === 0 ? 'download' : 'preview');
                }
            }
        });
    }

    public onDateSelection(date: NgbDateStruct) {
        if (!this.link.exp_servtime) {
            const dt = new Date();
            this.linkForm.get('exp_time').setValue({
                hour: dt.getHours(),
                minute: dt.getMinutes(),
                second: 0,
            });
        }
    }

    public clearExp() {
        this.linkForm.get('exp_date').setValue(null);
        this.linkForm.get('exp_time').setValue(null);
    }

    public viewUpgrades() {
        this.blendService.addEventsForUpgradeButton();
        this.router.navigate(['/account/upgrade']);
        this.close.emit(false);
    }

    /**
     * @ngdoc method
     * @name  setLinkOptions
     * @methodOf sync.service:Link
     * @description
     * Sets options on the link.
     * @return {Promise} Resolves the API call
     */
    public async onSubmit() {
        try {
            this.errcode = null;
            this.spinner = true;

            const expDate = this.linkForm.get('exp_date').value;
            let expTime = this.linkForm.get('exp_time').value;
            let expServtime;
            let downloadLimit = this.linkForm.get('download_limit').value;
            if (expDate) {
                if (!expTime) {
                    const exp = new Date();
                    expTime = {
                        hour: exp.getHours(),
                        minute: exp.getMinutes(),
                        second: 0,
                    };
                }
                const dt = new Date(
                    expDate.year,
                    expDate.month - 1,
                    expDate.day,
                    expTime.hour,
                    expTime.minute,
                    expTime.second
                );
                expServtime = dt.getTime();
            }
            if (downloadLimit === undefined) {
                downloadLimit = 0;
            }

            const allowComment = parseInt(
                this.linkForm.get('allow_comment').value,
                10
            );

            const linkSetOptions = {
                sync_id: this.link.sync_id,
                share_id: this.link.share_id,
                publink_id: this.link.cachekey,
                label: this.base64.encode(this.linkForm.get('label').value),
                exp_servtime: parseInt(expServtime, 10),
                download_limit: parseInt(downloadLimit, 10),
                email_notification: parseInt(
                    this.linkForm.get('email_notification').value,
                    10
                ),
                upload: parseInt(this.linkForm.get('upload').value, 10),

                // if preview only option is selected from file permissions, set previewonly in link options
                previewonly: this.linkForm.value.previewonly === 'preview' ? 1 : 0,

                // if edit option is selected from file permissions, set file_edit in link options
                // this will set the lnkFILEEDIT in LinkFeatures
                file_edit: this.linkForm.value.previewonly === 'edit' ? 1 : 0,
                compat: parseInt(this.linkForm.get('compat').value, 10),
                allow_comment: allowComment,
                comment_notification: parseInt(
                    this.linkForm.get('comment_notification').value,
                    10
                )
            };

            // if the link is to a file and edit permission is enabled, enable the upload permission as well
            if (this.link.type === 'file') {
                linkSetOptions.upload = this.linkForm.value.previewonly === 'edit' ? 1 : 0;
            }

            await this.api.execute('linksetoptions', linkSetOptions);

            const password = this.base64.encode(
                this.linkForm.get('passwordprotect').value
            );
            await this.api.execute('linksetpassword', {
                sync_id: this.link.sync_id,
                share_id: this.link.share_id,
                publink_id: this.link.cachekey,
                password: password,
            });

            this.blendService.track(BlendEvent.SAVE_LINK_SETTINGS, renameLinkOptions({ ...linkSetOptions, type: this.link.type, file_extension: this.item.file_extension }));

            this.log.info('Saved link for ' + this.link.cachekey);
            this.store.dispatch(new LinkListActions.LinkListRefreshAction());
        } catch (ex) {
            this.log.e(
                'Error saving link settings for ' + this.link.cachekey,
                ex
            );
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;
    }

    public onState(state: number) {
        this.state.emit(state);
    }
}
