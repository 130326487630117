"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var transfer_model_1 = require("../transfer.model");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var user_service_1 = require("../../core/user.service");
var logger_service_1 = require("../../core/logger.service");
var file_item_service_1 = require("../../file/file-item.service");
var i0 = require("@angular/core");
var i1 = require("../../core/crypt/sync-crypt.service");
var i2 = require("../../core/logger.service");
var i3 = require("../../core/user.service");
var i4 = require("../../file/file-item.service");
var TransferItemService = /** @class */ (function () {
    function TransferItemService(crypt, log, userService, fileItemService) {
        this.crypt = crypt;
        this.log = log;
        this.userService = userService;
        this.fileItemService = fileItemService;
        this.TYPE_DOWNLOAD = 10;
        this.TYPE_UPLOAD = 20;
        this.TYPE_PREVIEW = 50;
        this.transferItemUpload = {
            _initTime: 0,
            type: this.TYPE_UPLOAD,
            status: transfer_model_1.TransferStatus.STATUS_WAITING,
            filename: '',
            fileobjdata: null,
            filedata: null,
            filesize: 0,
            filedate: 0,
            mimetype: '',
            uniqid: '',
            sync_id: 0,
            data_key: [],
            bytes_sent: 0,
            share_id: 0,
            share_sequence: 0,
            share_key_id: '',
            chunkamt: 0,
            progress_percent: 0,
            progress_network: 0,
            progress_crypt: 0,
            stats: '',
            share_key: '',
            enc_share_key: '',
            enc_data_key: '',
            sha1_digest: null,
            enc_name: '',
            sync_pid: 0,
            fileobj: null,
            blob_id: 0,
            servtime: 0,
            backup_id: '',
            device_id: 0,
            device_sig_b64: '',
            chunkqueue: []
        };
        this.transferItemDownload = {
            _initTime: 0,
            type: this.TYPE_DOWNLOAD,
            status: transfer_model_1.TransferStatus.STATUS_WAITING,
            filename: '',
            filesize: 0,
            filedate: 0,
            mimetype: '',
            uniqid: '',
            sync_id: 0,
            // keys and encryption.
            data_key: [],
            enc_data_key: '',
            share_key: '',
            enc_share_key: '',
            bytes_sent: 0,
            progress_percent: 0,
            progress_network: 0,
            progress_crypt: 0,
            blobtype: '',
            cachekey: '',
            dl_ready: false,
            dl_href: '',
            dl_method: '',
            renderFile: undefined,
            enc_filesize: 0,
            filedata: undefined,
        };
    }
    TransferItemService.prototype.getChunkQueue = function (tItem) {
        // this is a file upload.
        var chunkamt = Math.ceil(tItem.filesize / this.crypt.GCM_PAYLOAD_SIZE);
        var queue = [];
        for (var i = 0; i < chunkamt; i++) {
            var offset = i * this.crypt.GCM_PAYLOAD_SIZE, len = (offset + this.crypt.GCM_PAYLOAD_SIZE > tItem.filesize)
                ? tItem.filesize - offset
                : this.crypt.GCM_PAYLOAD_SIZE;
            queue.push({
                offset: offset,
                enc_offset: offset + 36 * i,
                chunklen: len
            });
        }
        return queue;
    };
    TransferItemService.prototype.getUploadItem = function (settings) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tItem, enc_name, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        settings.retryCount = 0;
                        settings.chunkqueue = this.getChunkQueue(settings);
                        settings.chunkamt = settings.chunkqueue.length;
                        settings.data_key = this.crypt.mkDataKey(); // mk new datakey
                        if (!(this.userService.isAuthenticated() && !settings.linkID && !settings.enc_name)) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.crypt.filenameEncrypt(settings.filename)];
                    case 2:
                        enc_name = _a.sent();
                        settings.enc_name = enc_name;
                        tItem = Object.assign({}, this.transferItemUpload, settings);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        settings.status = 2022;
                        this.log.error('TransferItem enc file name failed');
                        this.log.error(err_1);
                        tItem = Object.assign({}, this.transferItemUpload, settings);
                        tItem.status = transfer_model_1.TransferStatus.STATUS_ERROR;
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        // Logger.info('q all promises success');
                        tItem = Object.assign({}, this.transferItemUpload, settings);
                        _a.label = 6;
                    case 6: return [2 /*return*/, tItem];
                }
            });
        });
    };
    TransferItemService.prototype.getDownloadItem = function (settings) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tItem_1, _a, ex_1, tItem_2, tItem, datakey, err_2;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        settings.enc_filesize = Math.ceil(settings.filesize / this.crypt.GCM_PAYLOAD_SIZE) * 36 + settings.filesize;
                        settings.uniqid = this.crypt.getRandomHex(32);
                        if (settings.context == 'applink') {
                            tItem_1 = Object.assign({}, this.transferItemDownload, settings);
                            return [2 /*return*/, tItem_1];
                        }
                        if (!(!settings.share_key && settings.enc_share_key)) return [3 /*break*/, 4];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        _a = settings;
                        return [4 /*yield*/, this.crypt.sharekeyDecrypt(settings.enc_share_key, settings.share_key_id)];
                    case 2:
                        _a.share_key = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _b.sent();
                        tItem_2 = Object.assign({}, this.transferItemDownload, settings);
                        tItem_2.status = transfer_model_1.TransferStatus.STATUS_ERROR;
                        return [2 /*return*/, tItem_2];
                    case 4:
                        _b.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this.crypt.datakeyDecrypt(settings.enc_data_key, settings.share_key)];
                    case 5:
                        datakey = _b.sent();
                        settings.data_key = this.crypt.b64ToBytes(datakey);
                        tItem = Object.assign({}, this.transferItemDownload, settings);
                        return [3 /*break*/, 7];
                    case 6:
                        err_2 = _b.sent();
                        this.log.error(err_2);
                        this.log.error('datakey decrypt failed for TransferItem');
                        tItem = Object.assign({}, this.transferItemDownload, settings);
                        tItem.status = 2010;
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/, tItem];
                }
            });
        });
    };
    /**
     * @ngdoc method
     * @name  get
     * @methodOf sync.service:TransferItem
     * @description
     * Creates a TransferItem object using the default template and merging
     * in the settings object
     * @param  {Object} settings Overrides default template for transferItem.
     * @return {Promise}         Resolved promise with a transfer item
     */
    TransferItemService.prototype.get = function (settings) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!settings.filename) {
                            this.log.error('TransferItem.get() passed empty file name.');
                            settings.status = 7201;
                        }
                        if (!settings.filesize) {
                            this.log.error('TransferItem.get() passed 0 byte file size.');
                            settings.status = 7202;
                        }
                        settings._initTime = Date.now();
                        settings.filedata = this.fileItemService.getFileTypeData(settings.filename);
                        if (!(settings.type == this.TYPE_UPLOAD)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getUploadItem(settings)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this.getDownloadItem(settings)];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    TransferItemService.ngInjectableDef = i0.defineInjectable({ factory: function TransferItemService_Factory() { return new TransferItemService(i0.inject(i1.SyncCryptService), i0.inject(i2.LoggerService), i0.inject(i3.UserService), i0.inject(i4.FileItemService)); }, token: TransferItemService, providedIn: "root" });
    return TransferItemService;
}());
exports.TransferItemService = TransferItemService;
