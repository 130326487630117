"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/forms");
var i2 = require("@angular/common");
var i3 = require("./dialog-choose-share-owner.component");
var i4 = require("@ng-bootstrap/ng-bootstrap");
var styles_DialogShareChooseOwnerComponent = [];
var RenderType_DialogShareChooseOwnerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogShareChooseOwnerComponent, data: {} });
exports.RenderType_DialogShareChooseOwnerComponent = RenderType_DialogShareChooseOwnerComponent;
function View_DialogShareChooseOwnerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "option", [["style", "cursor: pointer; padding: 4px 9px; border-radius: 4px;"]], null, null, null, null, null)), i0.ɵdid(1, 147456, null, 0, i1.NgSelectOption, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), i0.ɵdid(2, 147456, null, 0, i1.ɵangular_packages_forms_forms_s, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.email; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.email; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.email; _ck(_v, 3, 0, currVal_2); }); }
function View_DialogShareChooseOwnerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "ion-android-close fa-lg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "ion-android-people fa-lg"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, [" Manage Share ", " "])), (_l()(), i0.ɵeld(6, 0, null, null, 19, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 10, "div", [["style", "display: flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "table-modalsharename"], ["style", "width: 30%"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Select new owner: "])), (_l()(), i0.ɵeld(10, 0, null, null, 7, "div", [["style", "border-radius: 10px; width: 80%; align-items: center; margin-left: 40px; position: relative;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 6, "select", [["class", "form-control"], ["id", "autologout-ttl"], ["name", "owner selector"], ["style", "width: 100%; padding: 7px; border-radius: 5px;"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onSelectOwner($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 3, "option", [["value", ""]], null, null, null, null, null)), i0.ɵdid(13, 147456, null, 0, i1.NgSelectOption, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), i0.ɵdid(14, 147456, null, 0, i1.ɵangular_packages_forms_forms_s, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i0.ɵted(-1, null, ["Select new owner:"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogShareChooseOwnerComponent_1)), i0.ɵdid(17, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(18, 0, null, null, 7, "div", [["style", "display: flex; margin-top: 40px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 1, "div", [["class", "table-modalsharename"], ["style", "width: 30%"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Warning: "])), (_l()(), i0.ɵeld(21, 0, null, null, 4, "div", [["style", "width: 70%; margin-left: 12px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" You are about to change the ownership of your shared folder "])), (_l()(), i0.ɵeld(23, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(24, null, ["", ""])), (_l()(), i0.ɵted(-1, null, [" to the share member selected above. After this change you will still have access to the share folder, but permissions will no longer be managed by you. This action cannot be undone. "])), (_l()(), i0.ɵeld(26, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(27, 0, null, null, 1, "button", [["class", "btn btn-default"], ["id", "btn-share-new-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Cancel "])), (_l()(), i0.ɵeld(29, 0, null, null, 1, "button", [["class", "btn btn-primary pull-right"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onChangeOwnership() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Continue"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ""; _ck(_v, 13, 0, currVal_1); var currVal_2 = ""; _ck(_v, 14, 0, currVal_2); var currVal_3 = _co.members; _ck(_v, 17, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shareData.name; _ck(_v, 5, 0, currVal_0); var currVal_4 = _co.shareData.name; _ck(_v, 24, 0, currVal_4); var currVal_5 = !_co.selectedMember; _ck(_v, 29, 0, currVal_5); }); }
exports.View_DialogShareChooseOwnerComponent_0 = View_DialogShareChooseOwnerComponent_0;
function View_DialogShareChooseOwnerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-dialog-share-choose-owner", [], null, null, null, View_DialogShareChooseOwnerComponent_0, RenderType_DialogShareChooseOwnerComponent)), i0.ɵdid(1, 114688, null, 0, i3.DialogShareChooseOwnerComponent, [i4.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DialogShareChooseOwnerComponent_Host_0 = View_DialogShareChooseOwnerComponent_Host_0;
var DialogShareChooseOwnerComponentNgFactory = i0.ɵccf("sync-dialog-share-choose-owner", i3.DialogShareChooseOwnerComponent, View_DialogShareChooseOwnerComponent_Host_0, { shareData: "selected" }, {}, []);
exports.DialogShareChooseOwnerComponentNgFactory = DialogShareChooseOwnerComponentNgFactory;
