"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var DeviceListApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(DeviceListApiOutput, _super);
    function DeviceListApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return DeviceListApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.DeviceListApiOutput = DeviceListApiOutput;
var DeviceListApiInput = /** @class */ (function (_super) {
    tslib_1.__extends(DeviceListApiInput, _super);
    function DeviceListApiInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return DeviceListApiInput;
}(base_api_model_1.BaseApiInput));
exports.DeviceListApiInput = DeviceListApiInput;
