"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var models_1 = require("../../shared/models");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_twofactor_component_1 = require("../dialog-twofactor/dialog-twofactor.component");
var dialog_password_hint_component_1 = require("../dialog-password-hint/dialog-password-hint.component");
var dialog_password_reset_component_1 = require("../dialog-password-reset/dialog-password-reset.component");
var dialog_auto_logout_component_1 = require("../dialog-auto-logout/dialog-auto-logout.component");
var dialog_sso_component_1 = require("../dialog-sso/dialog-sso.component");
var user_service_1 = require("../../core/user.service");
var dialog_analytics_component_1 = require("../dialog-analytics/dialog-analytics.component");
var SecurityComponent = /** @class */ (function () {
    function SecurityComponent(injector, modalService, store, userService) {
        this.injector = injector;
        this.modalService = modalService;
        this.store = store;
        this.userService = userService;
        this.permissionActions = models_1.PermissionActions;
        this.is2FaEditable = true;
    }
    SecurityComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store
            .select(fromRoot.getAuthState)
            .subscribe(function (data) {
            if (!data.authenticated) {
                console.warn('User is not authenicated');
                _this.user = new models_1.User();
                // this.initialized = false;
                return;
            }
            if (data.user) {
                console.log('Setting user');
                _this.user = data.user;
                _this.isPassResetHidden = _this.user.is_multi_new ? _this.user.is_multi_new : (_this.user.is_multi_child && _this.user.is_resetpassword == 1);
            }
            if (_this.user.is_multi_new) {
                _this.is2FaEditable = !_this.userService.getPermssionByKey(models_1.PermissionActions.FORCE_2FA_ON_FIRST_LOGIN);
            }
        });
    };
    SecurityComponent.prototype.openTwoFa = function () {
        var ref = this.modalService.open(dialog_twofactor_component_1.DialogTwofactorComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
        ref.result.then(function (result) {
            if (result === true) {
                console.log('received a true response from result');
            }
        });
    };
    SecurityComponent.prototype.openPasswordHint = function () {
        var ref = this.modalService.open(dialog_password_hint_component_1.DialogPasswordHintComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    };
    SecurityComponent.prototype.openPasswordReset = function () {
        var ref = this.modalService.open(dialog_password_reset_component_1.DialogPasswordResetComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    };
    SecurityComponent.prototype.openAutoLogout = function () {
        var ref = this.modalService.open(dialog_auto_logout_component_1.DialogAutoLogoutComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    };
    SecurityComponent.prototype.openDisableSso = function () {
        var ref = this.modalService.open(dialog_sso_component_1.DialogSsoComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    };
    SecurityComponent.prototype.openDisableAnalytics = function () {
        var ref = this.modalService.open(dialog_analytics_component_1.DialogAnalyticsComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    };
    return SecurityComponent;
}());
exports.SecurityComponent = SecurityComponent;
