"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("../../core/api.service");
var logger_service_1 = require("../../core/logger.service");
var i0 = require("@angular/core");
var i1 = require("../../core/api.service");
var i2 = require("../../core/logger.service");
var ZuoraAccountService = /** @class */ (function () {
    function ZuoraAccountService(api, log) {
        this.api = api;
        this.log = log;
    }
    ZuoraAccountService.prototype.getProfileAndQueue = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var retval;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.execute('orderprofile', {}, true)];
                    case 1:
                        retval = _a.sent();
                        return [2 /*return*/, (retval.exists) ? {
                                zprofile: retval.zprofile,
                                zbillingqueue: retval.zbillingqueue,
                            } : null];
                }
            });
        });
    };
    ZuoraAccountService.prototype.getProfile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var retval;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.execute('orderprofile', {}, true)];
                    case 1:
                        retval = _a.sent();
                        return [2 /*return*/, (retval.exists) ? retval.zprofile : null];
                }
            });
        });
    };
    ZuoraAccountService.prototype.getInvoices = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var retval;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.execute('orderprofile', {}, true)];
                    case 1:
                        retval = _a.sent();
                        return [2 /*return*/, (retval.exists) ? retval.zprofile : null];
                }
            });
        });
    };
    ZuoraAccountService.prototype.emailBillingDocument = function (emails, docType, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.execute('orderemail', {
                            type: docType,
                            id: id,
                            emails: emails.join(',')
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ZuoraAccountService.ngInjectableDef = i0.defineInjectable({ factory: function ZuoraAccountService_Factory() { return new ZuoraAccountService(i0.inject(i1.ApiService), i0.inject(i2.LoggerService)); }, token: ZuoraAccountService, providedIn: "root" });
    return ZuoraAccountService;
}());
exports.ZuoraAccountService = ZuoraAccountService;
