"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var no_signup_layout_component_1 = require("../layout/no-signup-layout/no-signup-layout.component");
var share_invite_layout_component_1 = require("../layout/share-invite-layout/share-invite-layout.component");
var auth_guard_1 = require("../shared/guards/auth.guard");
var share_invite_component_1 = require("./share-invite/share-invite.component");
var share_list_component_1 = require("./share-list/share-list.component");
var share_provision_component_1 = require("./share-provision/share-provision.component");
var footer_layout_component_1 = require("../layout/footer-layout/footer-layout.component");
var external_share_list_component_1 = require("./external-share-list/external-share-list.component");
var routes = [
    {
        path: '', component: footer_layout_component_1.FooterLayoutComponent,
        canActivate: [auth_guard_1.AuthGuard],
        children: [
            { path: '', component: share_list_component_1.ShareListComponent },
            { path: 'externalshares', component: external_share_list_component_1.ExternalShareListComponent },
            { path: 'invite', component: share_invite_component_1.ShareInviteComponent },
            { path: 'rejoin', component: share_invite_component_1.ShareInviteComponent },
        ]
    },
    {
        path: 'invite/:invite_id', component: share_invite_layout_component_1.ShareInviteLayoutComponent,
        children: [
            { path: '', component: share_invite_component_1.ShareInviteComponent }
        ]
    },
    {
        path: ':cachekey', component: no_signup_layout_component_1.NoSignupLayoutComponent,
        children: [
            { path: '', component: share_provision_component_1.ShareProvisionComponent }
        ]
    },
];
var SharesRoutingModule = /** @class */ (function () {
    function SharesRoutingModule() {
    }
    return SharesRoutingModule;
}());
exports.SharesRoutingModule = SharesRoutingModule;
