"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_1 = require("@angular/common/http");
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var user_service_1 = require("src/app/core/user.service");
var environment_1 = require("../../../environments/environment");
var api_service_1 = require("../../core/api.service");
var base64_service_1 = require("../../core/base64.service");
var logger_service_1 = require("../../core/logger.service");
var link_file_list_service_1 = require("../../link-consume/services/link-file-list.service");
var func_1 = require("../../shared/func");
var models_1 = require("../../shared/models");
var build_transfer_item_service_1 = require("../../transfer/services/build-transfer-item.service");
var transfer_service_1 = require("../../transfer/transfer.service");
var file_item_service_1 = require("../file-item.service");
var wopi_optin_component_1 = require("../wopi-optin/wopi-optin.component");
var router_1 = require("@angular/router");
var blend_service_1 = require("../../shared/services/blend.service");
var PreviewDocComponent = /** @class */ (function () {
    function PreviewDocComponent(linkFileListService, loggerService, transferService, buildTransferItemService, sanitizer, apiService, base64Service, http, fileItemService, userService, modalService, router, route, blendService) {
        var _this = this;
        this.linkFileListService = linkFileListService;
        this.loggerService = loggerService;
        this.transferService = transferService;
        this.buildTransferItemService = buildTransferItemService;
        this.sanitizer = sanitizer;
        this.apiService = apiService;
        this.base64Service = base64Service;
        this.http = http;
        this.fileItemService = fileItemService;
        this.userService = userService;
        this.modalService = modalService;
        this.router = router;
        this.route = route;
        this.blendService = blendService;
        this.stateChange = new core_1.EventEmitter();
        this.onCommentToggle = function (isCommentExpanded) {
            _this.isCommentExpanded = isCommentExpanded;
        };
    }
    PreviewDocComponent.prototype.bypassSecurityTrustResourceUrl = function (value) {
        if (value) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(value);
        }
        return null;
    };
    PreviewDocComponent.prototype.ngOnInit = function () {
        this.viewEvent = this.handleViewerReady.bind(this);
        this.init();
    };
    PreviewDocComponent.prototype.getHost = function () {
        var ext = func_1.getFileExt(this.item.name);
        if (['xls', 'xlsx'].indexOf(ext) > -1) {
            return environment_1.environment.previewhost1;
        }
        if (ext == 'pdf' &&
            this.item.linkID &&
            this.item.size <= 256000 &&
            !this.item.link_is_pro) {
            return environment_1.environment.previewhost3;
        }
        return environment_1.environment.previewhost2;
    };
    PreviewDocComponent.prototype.init = function (password) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tItem, doc, keys, ref, ex_1, ex_2, ex_3, errcode;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        this.sessionId = null;
                        // white label attributes
                        this.subscription = this.linkFileListService
                            .getSubscription()
                            .subscribe(function (data) {
                            if (data.loaded && data.sorted) {
                                if (data.image_cachekey) {
                                    _this.isWhiteLabel = true;
                                    _this.buttonTextColor = data.button_text_color;
                                    _this.buttonPrimaryColor = data.button_primary_color;
                                }
                                _this.allowComment = data.allow_comment;
                            }
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!(this.item.context == 'applink')) return [3 /*break*/, 2];
                        if (!this.item.previewtoken ||
                            this.item.previewtoken == '' ||
                            this.item.previewtoken == 'error') {
                            this.loggerService.info('No preview token provided by extapi ' +
                                this.item.sync_id);
                            this.stateChange.emit({ type: 'default', upsell: true });
                            return [2 /*return*/];
                        }
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.buildTransferItemService.getFileKeys([
                            this.item,
                        ])];
                    case 3:
                        keys = _a.sent();
                        if (keys && (!keys.previewtoken || keys.previewtoken == '')) {
                            this.loggerService.info('No preview token available ' + this.item.sync_id);
                            this.stateChange.emit({ type: 'default', upsell: true });
                            return [2 /*return*/];
                        }
                        _a.label = 4;
                    case 4: return [4 /*yield*/, this.transferService.getFileDocPreview(this.item)];
                    case 5:
                        tItem = _a.sent();
                        if (this.display_office_optin(this.item)) {
                            ref = this.modalService.open(wopi_optin_component_1.WopiOptinComponent, {
                                backdropClass: 'in',
                                windowClass: 'in',
                                backdrop: 'static',
                            });
                            ref.result.then(function (shouldDisplayInOffice) {
                                if (shouldDisplayInOffice) {
                                    var prev_1 = _this.router.routeReuseStrategy.shouldReuseRoute;
                                    _this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
                                    _this.router.navigate(['/file', _this.route.snapshot.params['id'], 'view', 'office'], {
                                        queryParams: {
                                            view: _this.route.snapshot.queryParams['view'],
                                            page: _this.route.snapshot.queryParams['page']
                                        }
                                    }).then(function () {
                                        _this.router.routeReuseStrategy.shouldReuseRoute = prev_1;
                                    });
                                }
                            });
                        }
                        return [3 /*break*/, 7];
                    case 6:
                        ex_1 = _a.sent();
                        this.loggerService.error(ex_1);
                        this.spinner = false;
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        // this.errorMsg = 'Unable to download the file';
                        this.loggerService.error('Error doing doc preview for ' + this.item.sync_id);
                        return [3 /*break*/, 7];
                    case 7:
                        this.blendService.track(models_1.BlendEvent.VIEW_FILE, {
                            type: this.fileItemService.isPDF(this.item) ? 'pdf' : 'doc',
                            preview_preference: 'Sync',
                            fileSize: this.item.filesize,
                            mimeType: this.item.mime_type
                        });
                        this.host = this.getHost();
                        this.loggerService.warn('Environment' +
                            environment_1.environment.viewhost +
                            ' - ' +
                            this.host +
                            ' for ' +
                            func_1.getFileExt(this.item.name));
                        _a.label = 8;
                    case 8:
                        _a.trys.push([8, 10, , 11]);
                        return [4 /*yield*/, this.makePreview(this.host, tItem, password)];
                    case 9:
                        doc = _a.sent();
                        return [3 /*break*/, 11];
                    case 10:
                        ex_2 = _a.sent();
                        this.loggerService.error(ex_2);
                        this.spinner = false;
                        this.errcode = models_1.ErrCode.fromException(ex_2);
                        this.loggerService.error('Error preparing doc preview for ' + this.item.sync_id);
                        return [3 /*break*/, 11];
                    case 11:
                        // The API did not return a previewtoken, so we set it to "error" so that
                        // the preview pas server can correctly reject the request.
                        if (!tItem.previewtoken) {
                            this.loggerService.info('No preview token available');
                            tItem.previewtoken = 'error';
                            this.stateChange.emit({ type: 'default', upsell: true });
                            return [2 /*return*/];
                        }
                        this.url = tItem.renderFile.url;
                        _a.label = 12;
                    case 12:
                        _a.trys.push([12, 14, , 15]);
                        return [4 /*yield*/, this.sendForPreview(this.host, tItem.renderFile.url, doc.viewingSessionId, tItem.previewtoken, func_1.getFileExt(tItem.name))];
                    case 13:
                        _a.sent();
                        this.spinner = false;
                        this.errcode = undefined;
                        this.accusoftErr = undefined;
                        this.sessionId = doc.viewingSessionId;
                        if (tItem.linkID) {
                            this.loggerService.info('Consume link');
                            this.consume([tItem.linkID]);
                        }
                        return [3 /*break*/, 15];
                    case 14:
                        ex_3 = _a.sent();
                        this.loggerService.error("An error occurred putting file for preview");
                        switch (ex_3.status) {
                            case -1:
                            case 0:
                                this.errcode = new models_1.ErrCode(3201);
                                break;
                            case 403:
                                errcode = parseInt(ex_3.headers('x-preview-error'), 10);
                                if (errcode) {
                                    this.errcode = new models_1.ErrCode(errcode);
                                }
                                else {
                                    this.errcode = new models_1.ErrCode(9403, 'Error accessing this resource');
                                }
                                break;
                            default:
                                this.errcode = new models_1.ErrCode(3202);
                                break;
                        }
                        this.spinner = false;
                        return [3 /*break*/, 15];
                    case 15: return [2 /*return*/];
                }
            });
        });
    };
    PreviewDocComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        window.removeEventListener('message', this.viewEvent);
    };
    PreviewDocComponent.prototype.ngAfterViewInit = function () {
        // need to tell sce that the viewhost is a trusted resource.
        this.viewUrl = this.bypassSecurityTrustResourceUrl(environment_1.environment.viewhost);
        window.addEventListener('message', this.viewEvent);
    };
    /**
     * @description
     * Consumes a link and increments the download counter
     *
     * This is a non-authenticated call.
     * @param  {Array<string>} publink_id The link cachekey
     * @returns {Promise} The promise from the API call
     */
    PreviewDocComponent.prototype.consume = function (links) {
        var publinks = [];
        for (var i = 0, len = links.length; i < len; i++) {
            publinks.push({ publink_id: links[i] });
        }
        return this.apiService.execute('linkconsume', { links: publinks });
    };
    PreviewDocComponent.prototype.submitPassword = function () {
        this.spinner = true;
        this.init(this.docPassword);
    };
    PreviewDocComponent.prototype.handleViewerReady = function (e) {
        // if (e.origin !== 'http://the-trusted-iframe-origin.com') return;
        //  || e.message === "myevent"
        var options = {
            encryption: false,
            // Set the `options.documentID` to the session ID provided by PCCIS.
            // This property is *required*.
            documentID: this.sessionId,
            // Set the `options.documentDisplayName` to name of the document we use.
            // This property is *required*.
            documentDisplayName: this.item.name,
            // The `options.template` object specifies all of the templates
            // that layout the UI of the client.
            // This property is *required*.
            // The `options.icons` specifies the svg icons to be used in the viewer
            // This property is *required*.
            // icons : exampleHelper.icons,
            // The `options.imageHandlerUrl` specifies the path to the web tier services, which the
            // viewer uses.
            // The default value is 'pcc.ashx', so it must be set if using non-.NET web tiers.
            imageHandlerUrl: this.host,
            // The `options.resourcePath` specifies the path to images used by the viewer control.
            // The default value is 'img'.
            viewerAssetsPath: '/',
            // immediateActionMenuActionsFilter: {
            //     copy: true,
            //     hyperlink: true,
            //     cancel: true,
            //     redact: false,
            //     highlight: false,
            //     comment: false
            // },
            // The `options.redactionReasons` specifies the path to images used by the viewer control.
            // The parameter is required
            // redactionReasons: exampleHelper.redactionReasons
            uiElements: {
                fullScreenOnInit: true,
                // Use `options.uiElements.viewTab` to hide or show the View Tab.
                // Default is `true`.
                viewTab: false,
                // Hide or show the E-Signature Tab. Default is true.
                esignTab: false,
                // Use `options.uiElements.searchTab` to hide or show the Search Tab.
                // Default is `true`.
                searchTab: false,
                // Use `options.uiElements.annotateTab` to hide or show the Annotate tab.
                // Default is `true`.
                annotateTab: false,
                // Use `options.uiElements.redactTab` to hide or show the Redact tab.
                // Default is `true`.
                redactTab: false,
                // Use `options.uiElements.copyPaste` to hide or show the Text Select Tool.
                // Default is `true`.
                copyPaste: !this.item.previewonly,
                // Use `options.uiElements.download` to hide or show the Download Button.
                // Default is `true`.
                download: false,
                // Use `options.uiElements.printing` to hide or show the Print Button.
                // Default is `true`.
                printing: false,
            },
        };
        // devtools with ngrx-store causes errors and breaks the handler.
        if (e.data && e.data.source && e.data.source.indexOf('devtools') > -1) {
            return;
        }
        if (e.data === 'sync-viewer-ready' &&
            e.origin == environment_1.environment.viewhost) {
            var iframe = (document.getElementById('sync-viewer')).contentWindow;
            iframe.postMessage(options, environment_1.environment.viewhost);
        }
        else if (e.data === 'sync-viewer-error' &&
            e.origin == environment_1.environment.viewhost) {
            this.loggerService.error('An error occurred loading the link');
            this.loggerService.error('Session ID: ' + this.sessionId);
            this.loggerService.error('SyncId: ' + this.item.sync_id);
            this.loggerService.error('Link: ' + this.item.linkID);
            this.loggerService.error('Blob: ' + this.item.blob_id);
        }
        else if (e &&
            e.data &&
            e.data.indexOf('sync-viewer-error-number') > -1 &&
            e.origin == environment_1.environment.viewhost) {
            var num = parseInt(e.data.replace('sync-viewer-error-number-', ''), 10);
            switch (num) {
                case 4001:
                    this.loggerService.warn('The document requires a password');
                    this.sessionId = undefined;
                    if (this.docPassword) {
                        // user submitted a bad password
                        this.errcode = new models_1.ErrCode(3213);
                    }
                    break;
                case 5001:
                    //accusoft error occurred : Unable to generate page with code 5001
                    this.stateChange.emit({ type: 'default', upsell: false });
                    break;
                default:
                    this.loggerService.error('Error was ' + num);
                    this.errcode = new models_1.ErrCode(3200, 'An error was received ' + num);
            }
            this.accusoftErr = num;
        }
    };
    PreviewDocComponent.prototype.makePreview = function (host, tItem, password) {
        var _this = this;
        var ext = func_1.getFileExt(tItem.filename);
        var markupId = this.base64Service.encode(tItem.blob_id);
        var opts = {
            source: {
                type: 'upload',
                displayName: tItem.filename,
                fileExtension: ext,
                markupId: markupId,
            },
            serverSideSearch: 'disabled',
            origin: {
                blob_id: tItem.blob_id,
                name: this.base64Service.encode(tItem.filename),
                size: tItem.filesize,
            },
            render: {
                html5: {
                    alwaysUseRaster: false,
                    vectorTolerance: 1.0,
                },
            },
        };
        if (password) {
            opts['password'] = password;
        }
        if (func_1.getFileExt(tItem.filename) == 'xls' ||
            func_1.getFileExt(tItem.filename) == 'xlsx') {
            opts['serverCaching'] = 'none';
        }
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.http
                    .post(host + '/ViewingSession', opts, { withCredentials: true })
                    .subscribe(function (result) {
                    resolve(result);
                }, function (err) {
                    _this.loggerService.error('An error occurred making preview viewing session');
                    _this.loggerService.error('INPUT: ' +
                        JSON.stringify({
                            type: 'upload',
                            displayName: tItem.filename,
                            fileExtension: ext,
                            markupId: markupId,
                        }));
                    _this.loggerService.error('OUTPUT: ' + JSON.stringify(err));
                    reject({ errcode: 3200 });
                });
                return [2 /*return*/];
            });
        }); });
    };
    PreviewDocComponent.prototype.sendForPreview = function (host, url, sessionId, token, ext) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.http
                    .get(url, { responseType: 'arraybuffer', withCredentials: true })
                    .subscribe(function (resp) {
                    if (resp) {
                        console.log('previewhost = ' + environment_1.environment.previewhost2);
                        // send the data to the preview server
                        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.http.put([
                                            host,
                                            '/ViewingSession/u',
                                            sessionId,
                                            '/SourceFile?FileExtension=',
                                            ext,
                                        ].join(''), new Blob([resp]), {
                                            withCredentials: true,
                                            headers: {
                                                'Content-Type': 'application/octet-stream',
                                                'X-Preview-Token': token,
                                            },
                                        }).subscribe()];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    PreviewDocComponent.prototype.display_office_optin = function (item) {
        return (this.fileItemService.isMSOffice(item, 'embedview') &&
            // Only enabled in files, for now
            item.context === 'files' &&
            this.userService.get('is_displayedofficeoptin') === 0 &&
            this.userService.get('can_preview') === 1);
    };
    return PreviewDocComponent;
}());
exports.PreviewDocComponent = PreviewDocComponent;
