"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var slack_layout_component_1 = require("./layout/slack-layout/slack-layout.component");
var auth_guard_1 = require("./shared/guards/auth.guard");
var resolve_is_admin_resolver_1 = require("./shared/resolver/resolve-is-admin.resolver");
var base_guard_1 = require("./shared/guards/base.guard");
var footer_layout_component_1 = require("./layout/footer-layout/footer-layout.component");
var ɵ0 = { defaultSyncIdKey: 'syncus_sync_id' }, ɵ1 = { defaultSyncIdKey: 'syncus_sync_id' }, ɵ2 = { defaultSyncIdKey: 'web_sync_id' };
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
var routes = [
    {
        path: '',
        loadChildren: './auth/auth.module#AuthModule',
        canActivate: [base_guard_1.BaseGuard]
    },
    {
        path: 'files',
        loadChildren: './file-list/file-list.module#FileListModule',
        data: ɵ0,
        canActivate: [auth_guard_1.AuthGuard]
    },
    {
        path: 'starred',
        loadChildren: './file-list/file-list.module#FileListModule',
        data: ɵ1,
        canActivate: [auth_guard_1.AuthGuard]
    },
    {
        path: 'starred/:sync_id',
        loadChildren: './file-list/file-list.module#FileListModule',
        canActivate: [auth_guard_1.AuthGuard]
    },
    {
        path: 'files/:sync_id',
        loadChildren: './file-list/file-list.module#FileListModule',
        canActivate: [auth_guard_1.AuthGuard]
    },
    {
        path: 'vault',
        loadChildren: './file-list/file-list.module#FileListModule',
        data: ɵ2,
        canActivate: [auth_guard_1.AuthGuard]
    },
    {
        path: 'vault/:sync_id',
        loadChildren: './file-list/file-list.module#FileListModule',
        canActivate: [auth_guard_1.AuthGuard]
    },
    {
        path: 'events',
        loadChildren: './events/events.module#EventsModule',
        canActivate: [auth_guard_1.AuthGuard]
    },
    {
        path: 'recents',
        loadChildren: './recents/recents.module#RecentsModule',
        canActivate: [auth_guard_1.AuthGuard]
    },
    {
        path: 'health',
        loadChildren: './health/health.module#HealthModule',
    },
    {
        path: 'links',
        loadChildren: './links/links.module#LinksModule',
        canActivate: [auth_guard_1.AuthGuard]
    },
    {
        path: 'file',
        loadChildren: './file/file.module#FileModule'
    },
    {
        path: 'shares',
        loadChildren: './shares/shares.module#SharesModule'
    },
    {
        path: 'signup-share',
        loadChildren: './shares/shares.module#SharesModule'
    },
    {
        path: 'dl',
        loadChildren: './link-consume/link-consume.module#LinkConsumeModule'
    },
    {
        path: 'al',
        loadChildren: './link-consume/link-consume.module#LinkConsumeModule'
    },
    {
        path: 'dl/:cachekey',
        loadChildren: './link-consume/link-consume.module#LinkConsumeModule'
    },
    {
        path: 'al/:cachekey',
        loadChildren: './link-consume/link-consume.module#LinkConsumeModule'
    },
    {
        path: 'dl/:cachekey/view/:type/:id',
        loadChildren: './link-consume/link-consume.module#LinkConsumeModule'
    },
    {
        path: 'al/:cachekey/view/:type/:id',
        loadChildren: './link-consume/link-consume.module#LinkConsumeModule'
    },
    {
        path: 'comment-iframe',
        loadChildren: './link-consume/link-consume.module#LinkConsumeModule',
    },
    {
        path: 'account',
        loadChildren: './account/account.module#AccountModule',
    },
    {
        path: 'apps',
        loadChildren: './apps/apps.module#AppsModule',
    },
    {
        path: 'eapps',
        component: slack_layout_component_1.SlackLayoutComponent,
        loadChildren: './apps/apps.module#AppsModule',
    },
    {
        path: 'multi-user',
        component: footer_layout_component_1.FooterLayoutComponent,
        loadChildren: './multi-user/multi-user.module#MultiUserModule',
        canActivate: [auth_guard_1.AuthGuard],
        resolve: { isAdmin: resolve_is_admin_resolver_1.ResolveIsAdminResolver }
    },
    { path: '', redirectTo: 'files', pathMatch: 'full' },
    { path: 'getspace', redirectTo: '/account/upgrade' },
    { path: 'upgrade', redirectTo: '/account/upgrade' },
    { path: '**', redirectTo: 'files' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
exports.AppRoutingModule = AppRoutingModule;
