"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var logger_service_1 = require("src/app/core/logger.service");
var file_item_service_1 = require("../file-item.service");
var video_js_1 = require("video.js");
require("video.js/dist/video-js.css");
var link_file_list_service_1 = require("../../link-consume/services/link-file-list.service");
var models_1 = require("../../shared/models");
var blend_service_1 = require("../../shared/services/blend.service");
var PreviewVideoComponent = /** @class */ (function () {
    // private sub: Subscription;
    function PreviewVideoComponent(loggerService, fileItemService, linkPathListService, blendService) {
        var _this = this;
        this.loggerService = loggerService;
        this.fileItemService = fileItemService;
        this.linkPathListService = linkPathListService;
        this.blendService = blendService;
        this.spinner = false;
        this.onCommentToggle = function (isCommentExpanded) {
            _this.isCommentExpanded = isCommentExpanded;
        };
    }
    PreviewVideoComponent.prototype.ngOnInit = function () {
        var _this = this;
        try {
            this.sub = this.linkPathListService
                .getSubscription()
                .subscribe(function (data) {
                if (data.loaded && data.sorted) {
                    _this.allowComment = data.allow_comment;
                }
            });
            this.url = this.videoUrl;
            this.loggerService.info("url : " + this.url);
            this.loggerService.info("mimeType : " + this.mimeType);
            var options = {
                maxWidth: 700,
                maxHeight: 500,
                aspectRatio: '16:8',
                fluid: true,
                responsive: true,
                preload: 'auto',
                controls: true,
                autoplay: true,
                overrideNative: true,
                techOrder: ['html5', 'flash'],
                html5: {
                    nativeVideoTracks: false,
                    nativeAudioTracks: false,
                    nativeTextTracks: false,
                    hls: {
                        withCredentials: false,
                        overrideNative: true,
                        debug: true,
                    },
                },
                sources: [
                    {
                        src: this.url,
                        type: this.mimeType,
                    },
                ],
                playbackRates: [0.5, 1, 1.5, 2, 3],
                controlBar: {
                    skipButtons: {
                        forward: 10,
                    },
                },
            };
            this.player = video_js_1.default('my-video', options);
            this.blendService.track(models_1.BlendEvent.VIEW_FILE, {
                type: 'video',
                preview_preference: 'N/A',
                fileSize: this.item.filesize,
                mimeType: this.item.mime_type
            });
        }
        catch (err) {
            this.loggerService.error("video.js error " + err);
        }
    };
    PreviewVideoComponent.prototype.ngOnDestroy = function () {
        if (this.player) {
            this.player.dispose();
        }
    };
    return PreviewVideoComponent;
}());
exports.PreviewVideoComponent = PreviewVideoComponent;
