"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var UserSessionListApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(UserSessionListApiOutput, _super);
    function UserSessionListApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserSessionListApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.UserSessionListApiOutput = UserSessionListApiOutput;
var UserSessionListApiInput = /** @class */ (function (_super) {
    tslib_1.__extends(UserSessionListApiInput, _super);
    function UserSessionListApiInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserSessionListApiInput;
}(base_api_model_1.BaseApiInput));
exports.UserSessionListApiInput = UserSessionListApiInput;
