"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../shared/components/header/links/header-links.component.ngfactory");
var i2 = require("../../shared/components/header/links/header-links.component");
var i3 = require("@ngrx/store");
var i4 = require("../../core/api.service");
var i5 = require("../../core/logger.service");
var i6 = require("@angular/platform-browser");
var i7 = require("@ng-bootstrap/ng-bootstrap");
var i8 = require("@angular/router");
var i9 = require("@angular/common");
var i10 = require("./preview-error.component");
var styles_PreviewErrorComponent = [];
var RenderType_PreviewErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PreviewErrorComponent, data: {} });
exports.RenderType_PreviewErrorComponent = RenderType_PreviewErrorComponent;
function View_PreviewErrorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "navbar-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Error"]))], null, null); }
function View_PreviewErrorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-header-links", [], [[1, "style", 2]], null, null, i1.View_HeaderLinksComponent_0, i1.RenderType_HeaderLinksComponent)), i0.ɵdid(1, 245760, null, 0, i2.HeaderLinksComponent, [i3.Store, i4.ApiService, i5.LoggerService, i6.DomSanitizer, i7.NgbModal], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 20, "div", [["class", "container-fluid container-fluid-wrap container-fluid-wrap-preview"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 11, "nav", [["class", "navbar navbar-default navbar-fixed-top"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "navbar-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "a", [["class", "navbar-brand"], ["routerLink", "/files"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 671744, null, 0, i8.RouterLinkWithHref, [i8.Router, i8.ActivatedRoute, i9.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 0, "i", [["class", "zmdi zmdi-chevron-left zmdi-hc-2x"], ["style", "margin-top: 3px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 6, "div", [["id", "navbar"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreviewErrorComponent_1)), i0.ɵdid(10, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 3, "ul", [["class", "nav navbar-nav navbar-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openContactSupport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Help"])), (_l()(), i0.ɵeld(15, 0, null, null, 7, "div", [["class", "preview-wrap preview-wrap-no-overflow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 6, "div", [["class", "preview-wrap-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 5, "div", [["class", "preview-wrap-icon-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 0, "img", [["class", "preview-wrap-icon-inner-file-icon"], ["src", "/images/icons/mime-unknown.svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 3, "div", [["class", "preview-wrap-icon-inner-file-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Error, unable to preview this file. "])), (_l()(), i0.ɵeld(21, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(22, null, [" ", " ", " "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_3 = "/files"; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.item; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).customColorsCss; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 6).target; var currVal_2 = i0.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_5 = _co.errcode.code; var currVal_6 = _co.errcode.msg; _ck(_v, 22, 0, currVal_5, currVal_6); }); }
exports.View_PreviewErrorComponent_0 = View_PreviewErrorComponent_0;
function View_PreviewErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-preview-error", [], null, null, null, View_PreviewErrorComponent_0, RenderType_PreviewErrorComponent)), i0.ɵdid(1, 114688, null, 0, i10.PreviewErrorComponent, [i7.NgbModal, i5.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_PreviewErrorComponent_Host_0 = View_PreviewErrorComponent_Host_0;
var PreviewErrorComponentNgFactory = i0.ɵccf("sync-preview-error", i10.PreviewErrorComponent, View_PreviewErrorComponent_Host_0, { errcode: "errcode", syncId: "syncId", item: "item" }, {}, []);
exports.PreviewErrorComponentNgFactory = PreviewErrorComponentNgFactory;
