"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./dialog-multiadminuser-delete.component");
var i2 = require("@ng-bootstrap/ng-bootstrap");
var styles_DialogMultiAdminuserDeleteComponent = [];
var RenderType_DialogMultiAdminuserDeleteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogMultiAdminuserDeleteComponent, data: {} });
exports.RenderType_DialogMultiAdminuserDeleteComponent = RenderType_DialogMultiAdminuserDeleteComponent;
function View_DialogMultiAdminuserDeleteComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "ion-android-close fa-lg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "ion-android-people fa-lg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Delete Role "])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "alert alert-warning"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Are you sure you want to delete this role? Users in this role will be moved to the default role. "])), (_l()(), i0.ɵeld(9, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-default"], ["id", "btn-share-new-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" No "])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-primary pull-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Yes "]))], null, null); }
exports.View_DialogMultiAdminuserDeleteComponent_0 = View_DialogMultiAdminuserDeleteComponent_0;
function View_DialogMultiAdminuserDeleteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-dialog-multiadminuser-delete", [], null, null, null, View_DialogMultiAdminuserDeleteComponent_0, RenderType_DialogMultiAdminuserDeleteComponent)), i0.ɵdid(1, 114688, null, 0, i1.DialogMultiAdminuserDeleteComponent, [i2.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DialogMultiAdminuserDeleteComponent_Host_0 = View_DialogMultiAdminuserDeleteComponent_Host_0;
var DialogMultiAdminuserDeleteComponentNgFactory = i0.ɵccf("sync-dialog-multiadminuser-delete", i1.DialogMultiAdminuserDeleteComponent, View_DialogMultiAdminuserDeleteComponent_Host_0, { shareData: "shareData" }, {}, []);
exports.DialogMultiAdminuserDeleteComponentNgFactory = DialogMultiAdminuserDeleteComponentNgFactory;
