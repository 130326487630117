"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_1 = require("@angular/common/http");
var store_1 = require("@ngrx/store");
var api_service_1 = require("src/app/core/api.service");
var logger_service_1 = require("src/app/core/logger.service");
var link_file_list_service_1 = require("src/app/link-consume/services/link-file-list.service");
var environment_1 = require("src/environments/environment");
var models_1 = require("../models");
var sku_service_1 = require("./sku.service");
var fromRoot = require("../../reducers");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var memoization_service_1 = require("./memoization.service");
var router_1 = require("@angular/router");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("./sku.service");
var i3 = require("../../core/logger.service");
var i4 = require("../../core/api.service");
var i5 = require("../../link-consume/services/link-file-list.service");
var i6 = require("./memoization.service");
var i7 = require("@ngrx/store");
var i8 = require("@angular/router");
var FeatureService = /** @class */ (function () {
    function FeatureService(httpClient, skuService, log, api, LinkPathList, memoizationService, store, router) {
        this.httpClient = httpClient;
        this.skuService = skuService;
        this.log = log;
        this.api = api;
        this.LinkPathList = LinkPathList;
        this.memoizationService = memoizationService;
        this.store = store;
        this.router = router;
        this.path = environment_1.environment.featureHost;
        this.features = [];
        this.EXPIRY = 43200000; // 12 hours
        this.getFeatureData();
    }
    FeatureService.prototype.getUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve) {
                            if (_this.user) {
                                resolve(true);
                                return;
                            }
                            if (_this.router.url.includes('/dl')) {
                                _this.linkSubscribe =
                                    _this.LinkPathList.getSubscription().subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                        return tslib_1.__generator(this, function (_a) {
                                            if (data &&
                                                data.owner_id) {
                                                this.user = new models_1.User();
                                                this.user.id = data.owner_id;
                                                this.user.team_id = data.team_id;
                                                this.user.email = data.email;
                                                this.user.sku = data.sku;
                                                this.user.is_multi_admin =
                                                    data.is_multi_admin;
                                                this.user.is_on_trial =
                                                    data.is_on_trial;
                                                this.user['is_link_user'] = true;
                                                if (this.linkSubscribe) {
                                                    this.linkSubscribe.unsubscribe();
                                                }
                                                resolve(true);
                                            }
                                            return [2 /*return*/];
                                        });
                                    }); });
                            }
                            else {
                                _this.userSubscribe = _this.store
                                    .pipe(store_1.select(fromRoot.getAuthUser))
                                    .subscribe(function (data) {
                                    if (data) {
                                        _this.user = data;
                                        if (_this.userSubscribe) {
                                            _this.userSubscribe.unsubscribe();
                                        }
                                        resolve(true);
                                    }
                                });
                            }
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    FeatureService.prototype.getFeatureData = function () {
        var _this = this;
        if (this.featureSubscribe) {
            this.featureSubscribe.unsubscribe();
        }
        this.featureSubscribe = this.getFeatures().subscribe(function (response) {
            try {
                if (response) {
                    _this.features = response;
                }
                else {
                    _this.features = [];
                }
            }
            catch (error) {
                _this.log.e("error in feature API response", error);
                _this.features = [];
            }
        }, function (error) {
            _this.features = [];
            _this.log.e('Failed API feature', error);
        });
    };
    FeatureService.prototype.getFeatures = function () {
        var _this = this;
        var featuresUrl = this.path + "features";
        // Use memoization to cache the result of the HTTP request
        return this.memoizationService.memoize('memoize_feature', function () {
            return _this.httpClient.get(featuresUrl).pipe(operators_1.retryWhen(function (errors) {
                return errors.pipe(
                // Retry up to 3 times with a delay of 1 second between retries
                operators_1.delay(1000), operators_1.take(3), operators_1.catchError(function (error) {
                    // Log the error
                    _this.features = [];
                    // Log the error
                    _this.log.e('API request error', error);
                    return _this.features;
                }));
            }), operators_1.tap(function (value) {
                return value;
            }));
        });
    };
    FeatureService.prototype.isExpiredData = function () {
        return this.user.id != this.previousUserId;
    };
    FeatureService.prototype.getfeatureconfigs = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var featureFlag, sku;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        featureFlag = {};
                        sku = 'free';
                        if (!!this.user['is_link_user']) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.skuService.getSku()];
                    case 1:
                        sku = _a.sent();
                        this.user.sku = sku;
                        return [3 /*break*/, 3];
                    case 2:
                        this.user.sku = this.user.sku || sku;
                        _a.label = 3;
                    case 3:
                        if (this.features && this.features.length > 0) {
                            this.features.map(function (feature) {
                                featureFlag[feature.feature_name] =
                                    feature.enabled &&
                                        _this.isFeatureAllowed(feature, _this.user);
                            });
                        }
                        else {
                            this.getFeatureData();
                        }
                        return [2 /*return*/, featureFlag];
                }
            });
        });
    };
    FeatureService.prototype.isAllowed = function (featureName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.getUser()];
                    case 1:
                        _b.sent();
                        if (!(!this.featureFlag || this.isExpiredData())) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this.getfeatureconfigs()];
                    case 2:
                        _a.featureFlag = _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/, this.featureFlag[featureName] || false];
                }
            });
        });
    };
    FeatureService.prototype.isFeatureAllowed = function (feature, user) {
        if (!this.isValueEndsWithCriteria(feature.userid_criteria, user.id)) {
            return false;
        }
        if (!this.isValueEndsWithCriteria(feature.email_criteria, user.email)) {
            return false;
        }
        if (!this.isValueHasCriteria(feature.plan_criteria, user.sku)) {
            return false;
        }
        if (!this.isValueEndsWithCriteria(feature.teams_criteria, user.team_id)) {
            return false;
        }
        if (!this.isValueHasCriteria(feature.country_criteria, Intl.DateTimeFormat().resolvedOptions().timeZone)) {
            return false;
        }
        return true;
    };
    FeatureService.prototype.isValueEndsWithCriteria = function (criteria, value) {
        value = value || '';
        if (criteria) {
            var regex = new RegExp("(" + criteria + ")$", 'i');
            var result = regex.test(value.toString());
            return result;
        }
        return true;
    };
    FeatureService.prototype.isValueHasCriteria = function (criteria, sku) {
        if (criteria) {
            var regex = new RegExp("(" + criteria + ")", 'i');
            var result = regex.test(sku);
            return result;
        }
        return true;
    };
    FeatureService.prototype.resetFeatureFlags = function () {
        this.featureFlag = null;
        this.user = null;
    };
    FeatureService.ngInjectableDef = i0.defineInjectable({ factory: function FeatureService_Factory() { return new FeatureService(i0.inject(i1.HttpClient), i0.inject(i2.SkuService), i0.inject(i3.LoggerService), i0.inject(i4.ApiService), i0.inject(i5.LinkFileListService), i0.inject(i6.MemoizationService), i0.inject(i7.Store), i0.inject(i8.Router)); }, token: FeatureService, providedIn: "root" });
    return FeatureService;
}());
exports.FeatureService = FeatureService;
