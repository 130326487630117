"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var KeysSetEncApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(KeysSetEncApiOutput, _super);
    function KeysSetEncApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return KeysSetEncApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.KeysSetEncApiOutput = KeysSetEncApiOutput;
var KeysSetEncApiInput = /** @class */ (function () {
    function KeysSetEncApiInput() {
    }
    return KeysSetEncApiInput;
}());
exports.KeysSetEncApiInput = KeysSetEncApiInput;
