"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var LinkApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(LinkApiOutput, _super);
    function LinkApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LinkApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.LinkApiOutput = LinkApiOutput;
var LinkApiInput = /** @class */ (function () {
    function LinkApiInput() {
        this.command = 'linkget';
    }
    return LinkApiInput;
}());
exports.LinkApiInput = LinkApiInput;
