"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var api_service_1 = require("../../core/api.service");
var logger_service_1 = require("../../core/logger.service");
var store_1 = require("@ngrx/store");
var FileListActions = require("../../actions/file-list.actions");
var LinkDeleteComponent = /** @class */ (function () {
    function LinkDeleteComponent(api, log, store) {
        this.api = api;
        this.log = log;
        this.store = store;
        this.state = new core_1.EventEmitter();
        this.close = new core_1.EventEmitter();
    }
    LinkDeleteComponent.prototype.ngOnInit = function () {
        this.spinner = false;
    };
    LinkDeleteComponent.prototype.closeDialog = function () {
        this.close.emit(false);
    };
    LinkDeleteComponent.prototype.delete = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.errcode = null;
                        this.spinner = true;
                        return [4 /*yield*/, this.api.execute('linkdelete', { sync_id: this.link.sync_id })];
                    case 1:
                        _a.sent();
                        this.spinner = false;
                        this.store.dispatch(new FileListActions.FileListReloadAction());
                        this.close.emit(true);
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        this.log.e('Error deleting link ' + this.link.cachekey, e_1);
                        this.errcode = models_1.ErrCode.fromException(e_1);
                        this.spinner = false;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return LinkDeleteComponent;
}());
exports.LinkDeleteComponent = LinkDeleteComponent;
