"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var validate_service_1 = require("../../core/validate.service");
var feedback_service_1 = require("../../notifications/feedback.service");
var constants_1 = require("../../transfer/constants");
var file_list_service_1 = require("../services/file-list.service");
var NewFolderComponent = /** @class */ (function () {
    function NewFolderComponent(fileListService, feedbackService, validateService) {
        this.fileListService = fileListService;
        this.feedbackService = feedbackService;
        this.validateService = validateService;
        this.showNewFolderChange = new core_1.EventEmitter();
        this.spinner = false;
        this.MAX_FOLDER_NAME_LEN = constants_1.Constants.MAX_FILE_NAME_LEN; // Maximum length of a folder name
        this.pathitemform = new forms_1.FormGroup({
            pathname: new forms_1.FormControl('', [forms_1.Validators.maxLength(this.MAX_FOLDER_NAME_LEN), this.leadingSpace.bind(this), this.trailingChar.bind(this), this.reservedNames.bind(this),
                this.illegalCharacters.bind(this), this.exist.bind(this)])
        });
    }
    NewFolderComponent.prototype.leadingSpace = function (control) {
        var fileName = control.value;
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        return (fileName && fileName.charAt(0) !== ' ') ? null : { 'leadingSpace': true };
    };
    NewFolderComponent.prototype.trailingChar = function (control) {
        var fileName = control.value;
        var lastChar = fileName ? fileName.charAt(fileName.length - 1) : '';
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        return !(lastChar === '.' || lastChar === ' ') ? null : { 'trailingChar': true };
    };
    NewFolderComponent.prototype.reservedNames = function (control) {
        var fileName = control.value;
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        return !(/^(CLOCK\$|PRN|AUX|NUL|CON|COM[0-9]|LPT[0-9])$/i).test(fileName) &&
            fileName.toLowerCase() !== '.syncinfo' &&
            fileName.toLowerCase() !== 'thumbs.db' &&
            fileName.toLowerCase() !== 'desktop.ini' &&
            fileName.toLowerCase() !== 'sync.cache' &&
            fileName.toLowerCase() !== '$recycle.bin' &&
            fileName.indexOf('.~') !== 0 &&
            fileName.indexOf('~') !== 0 &&
            fileName.indexOf('~$') === -1 &&
            !(/^(PRN\.[a-z0-9]+|AUX\.[a-z0-9]+|NUL\.[a-z0-9]+|CON\.[a-z0-9]+|COM[0-9]\.[a-z0-9]+|LPT[0-9]\.[a-z0-9]+)$/i).test(fileName) ? null : { 'reserved': true };
    };
    NewFolderComponent.prototype.illegalCharacters = function (control) {
        var fileName = control.value;
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        return this.validateService.illegalCharacters(fileName) ? null : { 'illegal': true };
    };
    NewFolderComponent.prototype.exist = function (control) {
        var fileName = control.value;
        if (!this.pathlist) {
            return true;
        }
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        var found = this.pathlist.filter(function (val) {
            return (val.name.toLowerCase() == fileName.toLowerCase());
        });
        return !(found && found.length > 0) ? null : { 'exist': true };
    };
    NewFolderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fileListService.getListSubscription().subscribe(function (data) {
            if (data && data.pathlist.length) {
                _this.pathlist = data.pathlist;
            }
        });
    };
    NewFolderComponent.prototype.reset = function () {
        this.pathitemform.controls['pathname'].reset();
        this.showNewFolder = false;
        this.showNewFolderChange.emit(false);
        this.pathitemform.markAsPristine();
        this.spinner = false;
    };
    NewFolderComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pid, name, errData_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.pathitemform.valid ||
                            !this.pathitemform.dirty ||
                            !this.pathitemform.controls.pathname.value ||
                            this.pathitemform.controls.pathname.value == '') {
                            return [2 /*return*/];
                        }
                        pid = this.syncPid, name = this.pathitemform.controls.pathname.value;
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.fileListService.mkdir(pid, name)];
                    case 2:
                        _a.sent();
                        this.fileListService.reload();
                        this.reset();
                        this.spinner = false;
                        return [3 /*break*/, 4];
                    case 3:
                        errData_1 = _a.sent();
                        this.feedbackService.setFeedback('error', errData_1.msg);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return NewFolderComponent;
}());
exports.NewFolderComponent = NewFolderComponent;
