"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./dialog-edit-link-auth.component");
var i2 = require("@ng-bootstrap/ng-bootstrap");
var styles_DialogEditLinkAuthComponent = [];
var RenderType_DialogEditLinkAuthComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogEditLinkAuthComponent, data: {} });
exports.RenderType_DialogEditLinkAuthComponent = RenderType_DialogEditLinkAuthComponent;
function View_DialogEditLinkAuthComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Edit with Microsoft Office "])), (_l()(), i0.ɵeld(5, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 5, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Sign into your Sync account to edit this document online. "])), (_l()(), i0.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Online collaborative editing is available for Microsoft Word, Excel, and PowerPoint documents authorized by the file owner. "])), (_l()(), i0.ɵeld(13, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "a", [["class", "btn btn-default pull-left"], ["href", "https://www.sync.com/?_m=h73"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Create Account"])), (_l()(), i0.ɵeld(16, 0, null, null, 1, "a", [["class", "btn btn-primary pull-right"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sign in now"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.loginUrl + "?return_to_link=") + _co.currentPath); _ck(_v, 16, 0, currVal_0); }); }
exports.View_DialogEditLinkAuthComponent_0 = View_DialogEditLinkAuthComponent_0;
function View_DialogEditLinkAuthComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-dialog-edit-link-auth", [], null, null, null, View_DialogEditLinkAuthComponent_0, RenderType_DialogEditLinkAuthComponent)), i0.ɵdid(1, 114688, null, 0, i1.DialogEditLinkAuthComponent, [i2.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DialogEditLinkAuthComponent_Host_0 = View_DialogEditLinkAuthComponent_Host_0;
var DialogEditLinkAuthComponentNgFactory = i0.ɵccf("sync-dialog-edit-link-auth", i1.DialogEditLinkAuthComponent, View_DialogEditLinkAuthComponent_Host_0, {}, {}, []);
exports.DialogEditLinkAuthComponentNgFactory = DialogEditLinkAuthComponentNgFactory;
