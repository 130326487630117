"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ngx_cookie_service_1 = require("ngx-cookie-service");
var environment_1 = require("../../environments/environment");
var i0 = require("@angular/core");
var i1 = require("ngx-cookie-service");
var SyncCookieService = /** @class */ (function () {
    function SyncCookieService(cookies) {
        this.cookies = cookies;
    }
    SyncCookieService.prototype.setViewedLink = function () {
        var expDate = new Date();
        expDate.setMonth(expDate.getMonth() + 3);
        this.cookies.set('sync_link', '1', expDate, '/', environment_1.environment.cookiedomain, environment_1.environment.cookiesecure, 'Lax');
    };
    SyncCookieService.prototype.setLinkPassword = function (linkId, linkPassword) {
        var expDate = Date.now() + 600000; // 10 minutes
        this.cookies.set("linkpassword-" + linkId, linkPassword, new Date(expDate), '/', environment_1.environment.cookiedomain, environment_1.environment.cookiesecure, 'Lax');
    };
    SyncCookieService.prototype.getLinkPassword = function (linkId) {
        return this.cookies.get("linkpassword-" + linkId);
    };
    SyncCookieService.prototype.deleteLinkPassword = function (linkId) {
        return this.cookies.delete("linkpassword-" + linkId, '/');
    };
    SyncCookieService.prototype.setOrderSessionId = function (sessionId) {
        var expDate = Date.now() + 10800 * 1000; // 3 hours
        this.cookies.set("sync-order-session-id", sessionId, new Date(expDate), '/', environment_1.environment.cookiedomain, environment_1.environment.cookiesecure, 'Lax');
    };
    SyncCookieService.prototype.setOrderSessionHashData = function (saltData) {
        var expDate = Date.now() + 10800 * 1000; // 3 hours
        this.cookies.set("sync-order-session-hash-data", saltData, new Date(expDate), '/', environment_1.environment.cookiedomain, environment_1.environment.cookiesecure, 'Lax');
    };
    SyncCookieService.prototype.setOrderSessionSaltData = function (saltData) {
        var expDate = Date.now() + 10800 * 1000; // 3 hours
        this.cookies.set("sync-order-session-salt-data", saltData, new Date(expDate), '/', environment_1.environment.cookiedomain, environment_1.environment.cookiesecure, 'Lax');
    };
    SyncCookieService.prototype.setDownloadPubLink = function (passwordlock) {
        this.cookies.set('passwordlock', encodeURIComponent(passwordlock), undefined, '/', environment_1.environment.cookiedomain, environment_1.environment.cookiesecure, 'Lax');
    };
    SyncCookieService.prototype.setDownloadPubUser = function (signature) {
        this.cookies.set('signature', signature, undefined, '/', environment_1.environment.cookiedomain, environment_1.environment.cookiesecure, 'Lax');
    };
    SyncCookieService.prototype.setThumbnailSignature = function (signature) {
        this.cookies.set('thumbnail_sign', signature, undefined, '/', environment_1.environment.cookiedomain, environment_1.environment.cookiesecure, 'Lax');
    };
    SyncCookieService.prototype.setDisableAnalytics = function (disabled) {
        this.cookies.set('is_disableanalytics', disabled, undefined, '/', environment_1.environment.cookiedomain, environment_1.environment.cookiesecure, 'Lax');
    };
    SyncCookieService.prototype.deleteCookie = function (name) {
        this.cookies.delete(name, '/', environment_1.environment.cookiedomain);
    };
    SyncCookieService.prototype.getMarketingRef = function () {
        return this.cookies.get('_sync_mktg');
    };
    SyncCookieService.ngInjectableDef = i0.defineInjectable({ factory: function SyncCookieService_Factory() { return new SyncCookieService(i0.inject(i1.CookieService)); }, token: SyncCookieService, providedIn: "root" });
    return SyncCookieService;
}());
exports.SyncCookieService = SyncCookieService;
