"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../shared/components/error/error.component.ngfactory");
var i2 = require("../../shared/components/error/error.component");
var i3 = require("../../core/user.service");
var i4 = require("@ng-bootstrap/ng-bootstrap");
var i5 = require("@angular/platform-browser");
var i6 = require("@angular/common");
var i7 = require("./link-delete.component");
var i8 = require("../../core/api.service");
var i9 = require("../../core/logger.service");
var i10 = require("@ngrx/store");
var styles_LinkDeleteComponent = [];
var RenderType_LinkDeleteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LinkDeleteComponent, data: {} });
exports.RenderType_LinkDeleteComponent = RenderType_LinkDeleteComponent;
function View_LinkDeleteComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-spin fa-spinner"]], null, null, null, null, null))], null, null); }
function View_LinkDeleteComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["Delete link ", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "sync-error", [], null, null, null, i1.View_ErrorComponent_0, i1.RenderType_ErrorComponent)), i0.ɵdid(10, 114688, null, 0, i2.ErrorComponent, [i3.UserService, i4.NgbModal, i5.DomSanitizer], { errcode: [0, "errcode"] }, null), (_l()(), i0.ɵted(-1, null, [" This link will stop working. "])), (_l()(), i0.ɵeld(12, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 3, "button", [["class", "btn btn-primary"], ["id", "delete-link-dialog-btn"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LinkDeleteComponent_1)), i0.ɵdid(15, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, [" Delete link"])), (_l()(), i0.ɵeld(17, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.errcode; _ck(_v, 10, 0, currVal_1); var currVal_3 = _co.spinner; _ck(_v, 15, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link.name; _ck(_v, 5, 0, currVal_0); var currVal_2 = _co.spinner; _ck(_v, 13, 0, currVal_2); }); }
exports.View_LinkDeleteComponent_0 = View_LinkDeleteComponent_0;
function View_LinkDeleteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-link-delete", [], null, null, null, View_LinkDeleteComponent_0, RenderType_LinkDeleteComponent)), i0.ɵdid(1, 114688, null, 0, i7.LinkDeleteComponent, [i8.ApiService, i9.LoggerService, i10.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_LinkDeleteComponent_Host_0 = View_LinkDeleteComponent_Host_0;
var LinkDeleteComponentNgFactory = i0.ɵccf("sync-link-delete", i7.LinkDeleteComponent, View_LinkDeleteComponent_Host_0, { link: "link" }, { state: "state", close: "close" }, []);
exports.LinkDeleteComponentNgFactory = LinkDeleteComponentNgFactory;
