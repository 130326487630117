"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var OrderUpgradesApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(OrderUpgradesApiOutput, _super);
    function OrderUpgradesApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return OrderUpgradesApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.OrderUpgradesApiOutput = OrderUpgradesApiOutput;
var OrderUpgradesApiInput = /** @class */ (function (_super) {
    tslib_1.__extends(OrderUpgradesApiInput, _super);
    function OrderUpgradesApiInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return OrderUpgradesApiInput;
}(base_api_model_1.BaseApiInput));
exports.OrderUpgradesApiInput = OrderUpgradesApiInput;
