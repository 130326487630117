"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("../../core/api.service");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var FileListActions = require("../../actions/file-list.actions");
var models_1 = require("../../shared/models");
var url_service_1 = require("../../core/url.service");
var dirty_out_service_1 = require("../../core/crypt/dirty-out.service");
var rxjs_1 = require("rxjs");
var logger_service_1 = require("../../core/logger.service");
var file_item_service_1 = require("../../file/file-item.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_account_upgrade_component_1 = require("../../account/dialog-account-upgrade/dialog-account-upgrade.component");
var file_list_service_1 = require("./file-list.service");
var i0 = require("@angular/core");
var i1 = require("../../core/api.service");
var i2 = require("../../core/crypt/sync-crypt.service");
var i3 = require("../../core/crypt/dirty-out.service");
var i4 = require("@ngrx/store");
var i5 = require("../../core/url.service");
var i6 = require("../../core/logger.service");
var i7 = require("../../file/file-item.service");
var i8 = require("./file-list.service");
var i9 = require("@ng-bootstrap/ng-bootstrap");
var StarredListService = /** @class */ (function () {
    function StarredListService(api, crypt, dirtyOut, store, url, log, fileItemService, fileListService, modalService) {
        this.api = api;
        this.crypt = crypt;
        this.dirtyOut = dirtyOut;
        this.store = store;
        this.url = url;
        this.log = log;
        this.fileItemService = fileItemService;
        this.fileListService = fileListService;
        this.modalService = modalService;
        this.totalFavoriteSubject = new rxjs_1.BehaviorSubject(0);
        this.total_favorite$ = this.totalFavoriteSubject.asObservable();
    }
    StarredListService.prototype.addTag = function (syncId, UserId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var d, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.execute('addtag', {
                                sync_id: syncId,
                                user_id: UserId,
                            })];
                    case 1:
                        d = _a.sent();
                        this.store.dispatch(new FileListActions.FileListTotalFavoriteAction(this.totalFavoriteSubject.value + syncId.length));
                        this.totalFavoriteSubject.next(this.totalFavoriteSubject.value + syncId.length);
                        return [2 /*return*/, d];
                    case 2:
                        err_1 = _a.sent();
                        throw new models_1.ErrCode(1801, 'Failed to add star');
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    StarredListService.prototype.showFavorites = function (syncId) {
        this.store.dispatch(new FileListActions.FileListShowFavoritesAction({ syncId: syncId }));
    };
    StarredListService.prototype.getFavoritsList = function () {
        return this.store.select(fromRoot.getFavoritsListState);
    };
    StarredListService.prototype.showFavoritesList = function (syncId, pathlist) {
        if (pathlist === void 0) { pathlist = []; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var d_1, cwd, parents, i, len, name_1, _a, _loop_1, this_1, i, len, error_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 15, , 16]);
                        return [4 /*yield*/, this.api.execute('gettags', {
                                sync_id: syncId,
                            })];
                    case 1:
                        d_1 = _b.sent();
                        return [4 /*yield*/, this.fileItemService.format(d_1.cwd)];
                    case 2:
                        cwd = _b.sent();
                        this.store.dispatch(new FileListActions.FileListSetCwdAction(cwd));
                        if (!d_1.parents) return [3 /*break*/, 8];
                        parents = [];
                        i = 0, len = d_1.parents.length;
                        _b.label = 3;
                    case 3:
                        if (!(i < len)) return [3 /*break*/, 8];
                        if (!(d_1.parents[i].metaname == 'web' ||
                            d_1.parents[i].metaname == 'Web Uploads')) return [3 /*break*/, 4];
                        _a = 'Vault';
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.crypt.filenameDecrypt(d_1.parents[i].metaname)];
                    case 5:
                        _a = _b.sent();
                        _b.label = 6;
                    case 6:
                        name_1 = _a;
                        parents.push(new models_1.EncFile({
                            loc_id: d_1.parents[i].loc_id,
                            metaname: d_1.parents[i].metaname,
                            name: name_1,
                        }));
                        _b.label = 7;
                    case 7:
                        i++;
                        return [3 /*break*/, 3];
                    case 8:
                        if (!(d_1.pathlist && d_1.pathlist.length)) return [3 /*break*/, 13];
                        _loop_1 = function (i, len) {
                            var file, _a, _b;
                            return tslib_1.__generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        if (pathlist.some(function (file) {
                                            return d_1.pathlist[i].sync_id === file.sync_id;
                                        })) {
                                            this_1.log.error("Duplicate sync ids '" + d_1.pathlist[i].sync_id + "' in pid '" + syncId + "'");
                                            throw new models_1.ErrCode(1800, 'Unable to access your starred files/folders right now. Please retry later.');
                                        }
                                        file = d_1.pathlist[i];
                                        file.rootpath = d_1.pathlist[i].rootpath;
                                        _b = (_a = pathlist).push;
                                        return [4 /*yield*/, this_1.fileItemService.format(file)];
                                    case 1:
                                        _b.apply(_a, [_c.sent()]);
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        i = 0, len = d_1.pathlist.length;
                        _b.label = 9;
                    case 9:
                        if (!(i < len)) return [3 /*break*/, 12];
                        return [5 /*yield**/, _loop_1(i, len)];
                    case 10:
                        _b.sent();
                        _b.label = 11;
                    case 11:
                        i++;
                        return [3 /*break*/, 9];
                    case 12: return [2 /*return*/, pathlist];
                    case 13: return [2 /*return*/, pathlist];
                    case 14: return [3 /*break*/, 16];
                    case 15:
                        error_1 = _b.sent();
                        throw models_1.ErrCode.fromException(error_1);
                    case 16: return [2 /*return*/];
                }
            });
        });
    };
    StarredListService.prototype.removeTag = function (sync_id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var d, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.execute('removetag', {
                                sync_id: sync_id,
                            })];
                    case 1:
                        d = _a.sent();
                        this.store.dispatch(new FileListActions.FileListTotalFavoriteAction(this.totalFavoriteSubject.value - sync_id.length));
                        this.totalFavoriteSubject.next(this.totalFavoriteSubject.value - sync_id.length);
                        return [2 /*return*/, d];
                    case 2:
                        err_2 = _a.sent();
                        throw new models_1.ErrCode(1802, 'Failed to remove star');
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    StarredListService.prototype.setTotalFavorite = function (total_favorite) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.store.dispatch(new FileListActions.FileListTotalFavoriteAction(total_favorite));
                this.totalFavoriteSubject.next(total_favorite);
                return [2 /*return*/];
            });
        });
    };
    StarredListService.prototype.openAccountUpgradeDialog = function () {
        var ref = this.modalService.open(dialog_account_upgrade_component_1.DialogAccountUpgradeComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
        });
        ref.componentInstance.featureName = 'Star';
        ref.componentInstance.title = 'Get more with Sync Pro';
        ref.componentInstance.message =
            'Upgrade to Pro to instantly star more than 3 files.';
        ref.componentInstance.bodyHeader =
            ' Supercharge your Sync with these Pro features';
        ref.componentInstance.bodyItems = [
            'Unlimited stars',
            'Go from gigabytes to terabytes of storage',
            'Save space with Sync CloudFiles',
            'Unlimited sharing and file requests',
            '30-day money back guarantee',
        ];
    };
    StarredListService.ngInjectableDef = i0.defineInjectable({ factory: function StarredListService_Factory() { return new StarredListService(i0.inject(i1.ApiService), i0.inject(i2.SyncCryptService), i0.inject(i3.DirtyOutService), i0.inject(i4.Store), i0.inject(i5.UrlService), i0.inject(i6.LoggerService), i0.inject(i7.FileItemService), i0.inject(i8.FileListService), i0.inject(i9.NgbModal)); }, token: StarredListService, providedIn: "root" });
    return StarredListService;
}());
exports.StarredListService = StarredListService;
