"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var LinkSetOptionsApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(LinkSetOptionsApiOutput, _super);
    function LinkSetOptionsApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LinkSetOptionsApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.LinkSetOptionsApiOutput = LinkSetOptionsApiOutput;
