"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./header-nosignup.component");
var i2 = require("@ng-bootstrap/ng-bootstrap");
var styles_HeaderNosignupComponent = [];
var RenderType_HeaderNosignupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeaderNosignupComponent, data: {} });
exports.RenderType_HeaderNosignupComponent = RenderType_HeaderNosignupComponent;
function View_HeaderNosignupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "nav", [["class", "navbar navbar-default navbar-fixed-top navbar-white"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "navbar-header navbar-white-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "a", [["class", "navbar-brand"], ["href", "/"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["class", "navbar-white-q"], ["id", "navbar-logo"], ["src", "images/sync-q-lightbg.svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [["src", "/images/sync-w-lightbg.svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "n"], ["id", "navbar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "ul", [["class", "nav navbar-nav navbar-right navbar-white-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openContactSupport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Help"]))], null, null); }
exports.View_HeaderNosignupComponent_0 = View_HeaderNosignupComponent_0;
function View_HeaderNosignupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-header-nosignup", [], null, null, null, View_HeaderNosignupComponent_0, RenderType_HeaderNosignupComponent)), i0.ɵdid(1, 114688, null, 0, i1.HeaderNosignupComponent, [i2.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_HeaderNosignupComponent_Host_0 = View_HeaderNosignupComponent_Host_0;
var HeaderNosignupComponentNgFactory = i0.ɵccf("sync-header-nosignup", i1.HeaderNosignupComponent, View_HeaderNosignupComponent_Host_0, {}, {}, []);
exports.HeaderNosignupComponentNgFactory = HeaderNosignupComponentNgFactory;
