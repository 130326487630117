"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_1 = require("./api");
var LinkItem = /** @class */ (function (_super) {
    tslib_1.__extends(LinkItem, _super);
    function LinkItem(params) {
        var _this = _super.call(this) || this;
        _this.type = 'file';
        _this.linkversion = 0;
        _this.sync_id = 0;
        _this.share_id = 0;
        _this.share_sequence = 0;
        _this.enc_share_key = '';
        _this.enc_key = '';
        _this.password = '';
        _this.salt = '';
        _this.is_suspended = 0;
        _this.exp_servtime = 0;
        _this.download_limit = 0;
        _this.download_count = 0;
        _this.email_notification = 0;
        _this.compat = 1;
        _this.previewonly = 0;
        _this.file_edit = 0;
        _this.upload = 0;
        _this.cnt = 0;
        _this.cachekey = '';
        _this.label = '';
        _this.is_app_link = 0;
        _this.enc_comment_key = '';
        _this.allow_comment = 1;
        _this.comment_notification = 1;
        // derived values
        _this.is_expired = false;
        _this.is_download_close = false;
        _this.is_download_limit = false;
        _this.key = '';
        _this.togglePassword = true;
        _this.name = '';
        if (params) {
            _this.deserialize(params);
        }
        return _this;
    }
    LinkItem.prototype.deserialize = function (params) {
        for (var _i = 0, _a = Object.entries(params); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], val = _b[1];
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    };
    return LinkItem;
}(api_1.LinkApiOutput));
exports.LinkItem = LinkItem;
