"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var router_1 = require("@angular/router");
var api_service_1 = require("../../core/api.service");
var logger_service_1 = require("../../core/logger.service");
var i0 = require("@angular/core");
var i1 = require("../../core/api.service");
var i2 = require("../../core/logger.service");
var i3 = require("@angular/router");
var BaseGuard = /** @class */ (function () {
    function BaseGuard(apiService, loggerService, router) {
        this.apiService = apiService;
        this.loggerService = loggerService;
        this.router = router;
    }
    BaseGuard.prototype.canActivate = function (next, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(window.location.pathname == '/')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.apiService.execute('sessionvalid', {})];
                    case 1:
                        _a.sent();
                        this.router.navigate(['/files']);
                        return [2 /*return*/, false];
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.loggerService.error('Session is not valid');
                        this.loggerService.error(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, true];
                }
            });
        });
    };
    BaseGuard.ngInjectableDef = i0.defineInjectable({ factory: function BaseGuard_Factory() { return new BaseGuard(i0.inject(i1.ApiService), i0.inject(i2.LoggerService), i0.inject(i3.Router)); }, token: BaseGuard, providedIn: "root" });
    return BaseGuard;
}());
exports.BaseGuard = BaseGuard;
