"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var UserPasswordForgotListApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(UserPasswordForgotListApiOutput, _super);
    function UserPasswordForgotListApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserPasswordForgotListApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.UserPasswordForgotListApiOutput = UserPasswordForgotListApiOutput;
var UserPasswordForgotListApiInput = /** @class */ (function (_super) {
    tslib_1.__extends(UserPasswordForgotListApiInput, _super);
    function UserPasswordForgotListApiInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserPasswordForgotListApiInput;
}(base_api_model_1.BaseApiInput));
exports.UserPasswordForgotListApiInput = UserPasswordForgotListApiInput;
