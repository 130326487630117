"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var fromRoot = require("../../../../reducers");
var store_1 = require("@ngrx/store");
var logger_service_1 = require("../../../../core/logger.service");
var platform_browser_1 = require("@angular/platform-browser");
var environment_1 = require("../../../../../environments/environment");
var api_service_1 = require("../../../../core/api.service");
var dialog_contact_support_component_1 = require("../../../../account/dialog-contact-support/dialog-contact-support.component");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var HeaderLinksComponent = /** @class */ (function () {
    function HeaderLinksComponent(store, api, log, sanitizer, modalService) {
        this.store = store;
        this.api = api;
        this.log = log;
        this.sanitizer = sanitizer;
        this.modalService = modalService;
        this.avatarEndpoint = environment_1.environment.logohost + "avatar/";
        this.cpHost = environment_1.environment.cphost;
    }
    HeaderLinksComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sub = this.store
            .pipe(store_1.select(fromRoot.getAuthState))
            .subscribe(function (data) {
            if (data) {
                // console.log('data loaded');
                // console.log(data.user);
                _this.user = data.user;
            }
        });
        this.sub1 = this.store
            .pipe(store_1.select(fromRoot.getLinkFileListState))
            .subscribe(function (data) {
            if (data.whitelabel_loaded) {
                _this.imageCachekey = data.image_cachekey;
                _this.primaryColor = data.header_primary_color;
                _this.textColor = data.header_text_color;
                _this.linkColor = data.link_text_color;
                _this.whiteLabelLoaded = data.whitelabel_loaded;
                _this.logoHost = environment_1.environment.logohost;
            }
            //observe when iframe in comment-block completely loaded
            if (data.iframeReady) {
                _this.userLoaded = true;
            }
            // this.log.info('LINK FILE LIST');
            // this.log.info(data);
        });
    };
    HeaderLinksComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.sub1) {
            this.sub1.unsubscribe();
        }
    };
    HeaderLinksComponent.prototype.openContactSupport = function () {
        var ref = this.modalService.open(dialog_contact_support_component_1.DialogContactSupportComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
    };
    Object.defineProperty(HeaderLinksComponent.prototype, "customColorsCss", {
        /**
         * this function updates css variables based on changes of this component's attributes
         */
        get: function () {
            return this.sanitizer
                .bypassSecurityTrustStyle("--headerPrimaryColor: " + this.primaryColor + ";\n                                                        --headerTextColor: " + this.textColor + ";\n                                                        --linkTextColor: " + this.linkColor + ";\n                                                    ");
        },
        enumerable: true,
        configurable: true
    });
    return HeaderLinksComponent;
}());
exports.HeaderLinksComponent = HeaderLinksComponent;
