"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var logger_service_1 = require("../core/logger.service");
var file_list_service_1 = require("./services/file-list.service");
var user_service_1 = require("../core/user.service");
var models_1 = require("../shared/models");
var i0 = require("@angular/core");
var i1 = require("../core/logger.service");
var i2 = require("./services/file-list.service");
var i3 = require("../core/user.service");
var DirPickerService = /** @class */ (function () {
    function DirPickerService(loggerService, FileList, User) {
        this.loggerService = loggerService;
        this.FileList = FileList;
        this.User = User;
        this.view = {
            dirlist: [],
            skipSyncIds: [],
            selected: undefined,
            cwd: undefined,
            root: 'sync',
        };
        this.INIT_SKIPSHARE = 1;
        this.INIT_ALL = 0;
        this.rootSyncId = User.get('syncus_sync_id');
        this.rootVaultId = User.get('web_sync_id');
    }
    DirPickerService.prototype.init = function (mode) {
        this.skipShareFolders = mode == this.INIT_SKIPSHARE;
        this.view.dirlist = [];
        this.view.cwd = undefined;
        this.view.selected = undefined;
        this.view.root = 'sync';
        this.destinationId = 0;
    };
    DirPickerService.prototype.cd = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        item.spinner = true;
                        return [4 /*yield*/, this.getData(item.sync_id)];
                    case 1:
                        _a.sent();
                        if (!this.isSelected(item)) {
                            this.select(item);
                        }
                        item.spinner = false;
                        return [2 /*return*/, item];
                    case 2:
                        err_1 = _a.sent();
                        this.loggerService.error('Error changing directory for cd');
                        throw new Error(err_1);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DirPickerService.prototype.inRoot = function (name) {
        return this.view.root == name;
    };
    DirPickerService.prototype.isSelected = function (item) {
        if (!this.view.selected) {
            return false;
        }
        return this.view.selected && item.sync_id == this.view.selected.sync_id;
    };
    DirPickerService.prototype.isSelectedByName = function (name) {
        var item = this.translateRootToItem(name);
        return this.isSelected(item);
    };
    DirPickerService.prototype.select = function (item) {
        this.view.selected = item;
    };
    DirPickerService.prototype.selectByName = function (name) {
        var item = this.translateRootToItem(name);
        return this.select(item);
    };
    DirPickerService.prototype.getDestination = function () {
        var dest;
        if (this.view.selected && this.view.selected.name) {
            dest = this.view.selected.name;
            this.destinationId = this.view.selected.sync_id;
        }
        else if (this.view.cwd && this.view.cwd.sync_id == this.rootSyncId) {
            dest = 'Sync Folder';
            this.destinationId = this.rootSyncId;
        }
        else if (this.view.cwd && this.view.cwd.sync_id == this.rootVaultId) {
            dest = 'Vault';
            this.destinationId = this.rootVaultId;
        }
        else {
            this.destinationId = this.rootSyncId;
            dest = 'Sync Folder';
        }
        return dest;
    };
    DirPickerService.prototype.nameExists = function (name) {
        for (var i = 0, len = this.view.dirlist.length; i < len; i++) {
            if (this.view.dirlist[i].name &&
                this.view.dirlist[i].name.toLowerCase() == name.toLowerCase()) {
                return true;
            }
        }
        return false;
    };
    DirPickerService.prototype.getData = function (syncId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, pathlist, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!syncId) {
                            syncId = this.rootSyncId;
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.FileList.getDataNoState(syncId, '0')];
                    case 2:
                        data = _a.sent();
                        pathlist = this.processList(data);
                        this.view.dirlist = pathlist;
                        return [2 /*return*/];
                    case 3:
                        ex_1 = _a.sent();
                        throw models_1.ErrCode.fromException(ex_1);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DirPickerService.prototype.exists = function (key, value) {
        for (var i = 0, len = this.view.dirlist.length; i < len; i++) {
            var item = this.view.dirlist[i];
            if (key == 'name' &&
                typeof value == 'string' &&
                item[key].toLowerCase() == value.toLowerCase() &&
                !item.is_deleted) {
                return true;
            }
            if (item[key] === value && !item.is_deleted) {
                return true;
            }
        }
        return false;
    };
    DirPickerService.prototype.openRoot = function (name) {
        this.view.root = name;
        this.cd(this.translateRootToItem(name));
    };
    DirPickerService.prototype.processList = function (data) {
        var _this = this;
        var dirs = data.pathlist.filter(function (item, idx) {
            return ((!_this.skipShareFolders ||
                (_this.skipShareFolders && !item.is_shared)) &&
                item.type == 'dir' &&
                _this.view.skipSyncIds.indexOf(item.sync_id) === -1);
        });
        if (data.cwd.sync_id == this.rootSyncId) {
            data.cwd.name = 'Sync Folder';
        }
        else if (data.cwd.sync_id == this.rootVaultId) {
            data.cwd.name = 'Vault';
        }
        this.view.cwd = data.cwd;
        return dirs;
    };
    DirPickerService.prototype.translateRootToItem = function (name) {
        var item;
        switch (name) {
            case 'sync':
                item = { name: 'Sync Folder', sync_id: this.rootSyncId };
                break;
            case 'vault':
                item = { name: 'Vault', sync_id: this.rootVaultId };
                break;
            default:
                this.loggerService.error('An unknown root was provided to DirPicker to translate ' +
                    name);
        }
        return item;
    };
    DirPickerService.ngInjectableDef = i0.defineInjectable({ factory: function DirPickerService_Factory() { return new DirPickerService(i0.inject(i1.LoggerService), i0.inject(i2.FileListService), i0.inject(i3.UserService)); }, token: DirPickerService, providedIn: "root" });
    return DirPickerService;
}());
exports.DirPickerService = DirPickerService;
