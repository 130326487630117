"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var base64_service_1 = require("../../core/base64.service");
var logger_service_1 = require("../../core/logger.service");
var user_service_1 = require("../../core/user.service");
var share_new_service_1 = require("../share-new.service");
var share_service_1 = require("../share.service");
var SharememberInviteComponent = /** @class */ (function () {
    function SharememberInviteComponent(userService, base64Service, loggerService, shareService, shareNewService) {
        this.userService = userService;
        this.base64Service = base64Service;
        this.loggerService = loggerService;
        this.shareService = shareService;
        this.shareNewService = shareNewService;
        this.errors = [];
        this.sharememberInvite = {
            permissions: {
                perDOWNLOAD: 1,
                perUPLOAD: 1,
                perINVITE: 0,
                perSEEOTHERS: 1,
            },
            displayName: '',
            queue: [],
            roleQueue: [],
            roleName: '',
            privateMessage: '',
        };
        this.isPro = false;
        this.isVerified = false;
    }
    SharememberInviteComponent.prototype.ngOnInit = function () {
        this.isVerified = this.userService.get('is_verified');
        this.isPro = this.userService.get('is_pro');
        this.sharememberInvite.displayName = this.base64Service.decode(this.userService.get('display_name'));
        if (!this.shareData) {
            this.loggerService.error('Unable to find shareData for sharemember-list');
            this.loggerService.error(JSON.stringify(this.shareData));
            this.errors.push({
                code: 1000,
                msg: 'No share member information provided',
            });
        }
    };
    SharememberInviteComponent.prototype.isValidInvite = function () {
        return (this.sharememberInvite.displayName &&
            (this.sharememberInvite.queue.length > 0 || this.sharememberInvite.roleQueue.length > 0));
    };
    SharememberInviteComponent.prototype.sendInvites = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var shareData, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        this.spinner = true;
                        this.errors = [];
                        this.invitedEmails = '';
                        return [4 /*yield*/, this.shareNewService.addMember(this.shareData, this.sharememberInvite)];
                    case 1:
                        _a.sent();
                        this.spinner = false;
                        this.loggerService.info('Finished adding share member');
                        this.invitedEmails = this.sharememberInvite.queue.join(', ');
                        if (this.invitedEmails && this.sharememberInvite.roleName) {
                            this.invitedEmails += ", " + this.sharememberInvite.roleName;
                        }
                        else if (!this.invitedEmails && this.sharememberInvite.roleName) {
                            this.invitedEmails = this.sharememberInvite.roleName;
                        }
                        this.invitedEmails = this.invitedEmails.replace(/,(?=[^,]+$)/, ' and');
                        this.sharememberInvite.queue = [];
                        this.sharememberInvite.roleQueue = [];
                        this.sharememberInvite.roleName = '';
                        return [4 /*yield*/, this.shareService.getShare(this.shareData.shareId)];
                    case 2:
                        shareData = _a.sent();
                        this.shareData = shareData;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.errors.push(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return SharememberInviteComponent;
}());
exports.SharememberInviteComponent = SharememberInviteComponent;
