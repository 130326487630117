"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var batch_delete_service_1 = require("../batch-delete.service");
var feedback_service_1 = require("../../notifications/feedback.service");
var blend_service_1 = require("src/app/shared/services/blend.service");
var models_1 = require("src/app/shared/models");
var DialogDeleteComponent = /** @class */ (function () {
    function DialogDeleteComponent(batchDeleteService, activeModal, feedbackService, blendService) {
        this.batchDeleteService = batchDeleteService;
        this.activeModal = activeModal;
        this.feedbackService = feedbackService;
        this.blendService = blendService;
        this.selected = [];
    }
    DialogDeleteComponent.prototype.ngOnInit = function () {
        this.dlgView = {
            itemlist: this.selected,
            action: 'delete',
            title: 'Delete items',
            btnExecuteText: 'Delete',
            btnExecuteClass: 'btn btn-warning',
            completedClass: '',
            confirm: {
                check: false,
                show: false,
                notices: [],
                txt: ' Deleting files free up your account\'s disk usage',
            },
        };
        this.batchView = this.batchDeleteService.view;
        this.batchDeleteService.init();
    };
    DialogDeleteComponent.prototype.isCompleted = function (syncId) {
        this.batchDeleteService.isCompleted(syncId);
    };
    DialogDeleteComponent.prototype.getClass = function (syncID) {
        return this.batchDeleteService.isCompleted(syncID) ? 'deleted' : '';
    };
    DialogDeleteComponent.prototype.closeDialog = function () {
        if (this.batchDeleteService.view.spinner !== 0) {
            var c = window.confirm('Are you sure you want to cancel?');
            if (c) {
                this.batchDeleteService.cancel();
                this.activeModal.close();
            }
        }
        else {
            this.activeModal.close();
        }
    };
    DialogDeleteComponent.prototype.removeFromList = function (listitem) {
        for (var i = this.dlgView.itemlist.length - 1; i >= 0; i--) {
            if (this.dlgView.itemlist[i].sync_id == listitem.sync_id) {
                this.dlgView.itemlist.splice(i, 1);
                break;
            }
        }
    };
    DialogDeleteComponent.prototype.sortList = function (a, b) {
        if (a.type < b.type) {
            return -1;
        }
        else if (a.type > b.type) {
            return 1;
        }
        else {
            if (a.search_name < b.search_name) {
                return -1;
            }
            else if (a.search_name > b.search_name) {
                return 1;
            }
        }
    };
    DialogDeleteComponent.prototype.execute = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sync_id_array, list, files, directories, i, error_1, errorMsg;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sync_id_array = [], list = this.dlgView.itemlist
                            ? this.dlgView.itemlist.sort(function (a, b) { return _this.sortList(a, b); })
                            : [];
                        files = 0, directories = 0;
                        for (i = list.length - 1; i >= 0; i--) {
                            sync_id_array.push(list[i].sync_id);
                            if (list[i].type && list[i].type.toUpperCase() === 'DIR') {
                                directories++;
                            }
                            else {
                                files++;
                            }
                            this.blendService.track(models_1.BlendEvent.DELETE_ITEM, {
                                size: list[i].size,
                                type: list[i].type && list[i].type.toUpperCase() === 'DIR' ? 'folder' : 'file',
                                mime_type: list[i].mime_type
                            });
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.batchDeleteService.execute(sync_id_array).toPromise()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        errorMsg = list.length + " file" + (files > 1 ? 's' : '') + " failed to delete";
                        if (directories && files) {
                            errorMsg = list.length + " items failed to delete";
                        }
                        else if (directories) {
                            errorMsg = list.length + " folder" + (directories > 1 ? 's' : '') + " failed to delete";
                        }
                        this.feedbackService.setFeedback('danger', errorMsg, 10000);
                        return [3 /*break*/, 5];
                    case 4:
                        this.activeModal.close();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return DialogDeleteComponent;
}());
exports.DialogDeleteComponent = DialogDeleteComponent;
