"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var func_1 = require("../../shared/func");
var platform_browser_1 = require("@angular/platform-browser");
var compat_service_1 = require("../../core/compat.service");
var user_service_1 = require("../../core/user.service");
var models_1 = require("../../shared/models");
var link_file_list_service_1 = require("../../link-consume/services/link-file-list.service");
var api_service_1 = require("../../core/api.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var transfer_service_1 = require("../../transfer/transfer.service");
var build_transfer_item_service_1 = require("../../transfer/services/build-transfer-item.service");
var wopi_upgrade_component_1 = require("../wopi-upgrade/wopi-upgrade.component");
var router_1 = require("@angular/router");
var file_item_service_1 = require("../file-item.service");
var dialog_edit_link_auth_component_1 = require("../dialog-edit-link-auth/dialog-edit-link-auth.component");
var PreviewMenuComponent = /** @class */ (function () {
    function PreviewMenuComponent(CompatService, userService, LinkPathList, apiService, sanitizer, modalService, buildTransferItemService, fileItemService, transferService, router, route) {
        this.CompatService = CompatService;
        this.userService = userService;
        this.LinkPathList = LinkPathList;
        this.apiService = apiService;
        this.sanitizer = sanitizer;
        this.modalService = modalService;
        this.buildTransferItemService = buildTransferItemService;
        this.fileItemService = fileItemService;
        this.transferService = transferService;
        this.router = router;
        this.route = route;
        this.spinner = false;
        this.permissionActions = models_1.PermissionActions;
        this.compat = 0;
    }
    PreviewMenuComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isAuth = this.userService.isAuthenticated();
                this.user = this.userService.getUser();
                //white label attributes
                this.subscription = this.LinkPathList.getSubscription().subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (data.loaded && data.sorted) {
                            this.isUploadAllowed = data.upload;
                            this.compat = data.compat;
                            this.isFileEditAllowed = data.file_edit;
                            this.isCwdShared = data.cwd.is_shared;
                            if (data.image_cachekey) {
                                this.isWhiteLabel = true;
                                this.linkTextColor = data.link_text_color;
                                this.buttonPrimaryColor = data.button_primary_color;
                                this.buttonTextColor = data.button_text_color;
                            }
                            //comment feature: show comment block if allow_comment = 1 or 2
                            if (data.allow_comment) {
                                this.allowComment = data.allow_comment;
                            }
                        }
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    PreviewMenuComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.buildTransferItemService.view.single = undefined;
        this.transferItem = undefined;
    };
    PreviewMenuComponent.prototype.bypassSecurityTrustUrl = function (url) {
        if (url) {
            return this.sanitizer.bypassSecurityTrustUrl(url);
        }
        return null;
    };
    PreviewMenuComponent.prototype.hasPermission = function (permissionKey) {
        return (this.userService.getPermssionByKey(permissionKey));
    };
    PreviewMenuComponent.prototype.isOpenable = function () {
        if (this.item.context == 'link' && (this.item.previewonly == 8 || !this.compat)) {
            return false;
        }
        if (this.item.context == 'link' &&
            this.fileItemService.isStreamable(this.item.kind)) {
            return false;
        }
        if (this.item.context == 'link' &&
            func_1.getFileExt(this.item.name) == 'pdf' &&
            !this.item.link_is_business) {
            return false;
        }
        if (this.isMSOffice()) {
            return false;
        }
        if (!func_1.canBrowserOpen(this.item.name)) {
            return false;
        }
        // return this.item.compat
        //     ? canLinkBrowserOpen(this.item.name)
        //     : canBrowserOpen(this.item.name);
        return true;
    };
    PreviewMenuComponent.prototype.openFile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.item.context == 'applink')) return [3 /*break*/, 1];
                        this.url = this.item.compaturl;
                        return [3 /*break*/, 3];
                    case 1:
                        _a = this;
                        return [4 /*yield*/, this.buildTransferItemService.getCompatUrl(this.item, this.buildTransferItemService.ACT_PREVIEW)];
                    case 2:
                        _a.url = _b.sent();
                        _b.label = 3;
                    case 3:
                        window.location.href = this.url;
                        return [2 /*return*/];
                }
            });
        });
    };
    PreviewMenuComponent.prototype.hasActions = function () {
        return (this.item.context == 'files' ||
            this.isOpenable() ||
            this.showDownload() ||
            this.showCompatDownload());
    };
    PreviewMenuComponent.prototype.openLinkDialog = function () {
        var _this = this;
        this.CompatService.showLinkDialogPromise(this.item, this.item.is_publink ? 1 : 0).then(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var item, _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = this.fileItemService).format;
                        return [4 /*yield*/, this.apiService.execute('pathget', {
                                sync_id: this.item.sync_id
                            })];
                    case 1: return [4 /*yield*/, _b.apply(_a, [_c.sent()])];
                    case 2:
                        item = _c.sent();
                        this.item.is_publink = item.is_publink;
                        return [2 /*return*/];
                }
            });
        }); });
    };
    PreviewMenuComponent.prototype.downloadCompat = function () {
        var _this = this;
        if (this.item.context == 'applink') {
            window.location.href = this.item.compaturl_dl;
        }
        else {
            this.buildTransferItemService.getCompatUrl(this.item, this.buildTransferItemService.ACT_DOWNLOAD).
                then(function (url) {
                _this.item.compaturl_dl = url;
                window.location.href = _this.item.compaturl_dl;
            });
        }
    };
    PreviewMenuComponent.prototype.open = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var compatUrl, compatUrl, tItem;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.item.compat && this.item.context == 'link')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.buildTransferItemService.getCompatUrl(this.item, this.buildTransferItemService.ACT_PREVIEW)];
                    case 1:
                        compatUrl = _a.sent();
                        window.location.href = compatUrl;
                        return [3 /*break*/, 6];
                    case 2:
                        if (!(this.item.compat && this.item.context == 'applink')) return [3 /*break*/, 3];
                        compatUrl = this.item.compaturl;
                        window.location.href = compatUrl;
                        return [3 /*break*/, 6];
                    case 3:
                        if (!this.url) return [3 /*break*/, 4];
                        window.location.href = this.url;
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.transferService.getFile(this.item, true)];
                    case 5:
                        tItem = _a.sent();
                        window.location.href = tItem.renderFile.url;
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    PreviewMenuComponent.prototype.showCompatDownload = function () {
        if (this.item.context == 'link') {
            return false;
        }
        else if (this.item.size > 524288000) {
            return true;
        }
        else {
            return false;
        }
    };
    PreviewMenuComponent.prototype.showDownload = function () {
        if ((this.item.context == 'link' || this.item.context == 'applink') &&
            this.item.previewonly == 8) {
            return false;
        }
        else {
            return true;
        }
    };
    PreviewMenuComponent.prototype.showOfficeView = function () {
        return (this.fileItemService.isMSOffice(this.item, 'view') &&
            this.isAuth &&
            this.user.is_office &&
            this.user.is_pro);
    };
    PreviewMenuComponent.prototype.showOfficeEdit = function () {
        var inLink = this.item.context !== 'files';
        if (inLink) {
            return (this.compat && this.fileItemService.isOfficeLinkEdit(this.item, this.isUploadAllowed, this.isFileEditAllowed, this.isCwdShared, false));
        }
        return this.fileItemService.isMSOffice(this.item, 'edit') && this.isAuth;
    };
    PreviewMenuComponent.prototype.isMSOffice = function () {
        var app = this.fileItemService.getMSOfficeApp(this.item);
        if (app === 'None') {
            return false;
        }
        return true;
    };
    PreviewMenuComponent.prototype.newFeature = function () {
        return this.user.new_feature;
    };
    PreviewMenuComponent.prototype.openOfficeView = function () {
        this.router.navigate(['/file', this.item.sync_id, 'view', 'officefsv'], {
            queryParams: {
                view: this.route.snapshot.queryParams['view'],
                page: this.route.snapshot.queryParams['page']
            }
        });
    };
    PreviewMenuComponent.prototype.openOfficeEdit = function () {
        var _a = this.route.snapshot, params = _a.params, queryParams = _a.queryParams, fragment = _a.fragment;
        if (this.item.context === 'link') {
            var isLinkEditAuth = this.fileItemService.isOfficeLinkEdit(this.item, this.isUploadAllowed, this.isFileEditAllowed, this.isCwdShared);
            if (isLinkEditAuth) {
                var navigationParams = ['/dl', params['cachekey'], 'view', 'officefse', this.item.sync_id];
                if (params['key']) {
                    navigationParams.splice(2, 0, params['key']);
                }
                this.router.navigate(navigationParams, tslib_1.__assign({}, this.LinkPathList.decorateQueryParams(queryParams), this.LinkPathList.decorateFragment(this.compat, fragment)));
            }
            else {
                this.modalService.open(dialog_edit_link_auth_component_1.DialogEditLinkAuthComponent, {
                    backdropClass: 'in',
                    windowClass: 'in',
                    backdrop: 'static',
                });
            }
        }
        else if (this.item.context === 'files') {
            if (this.user.can_preview) {
                this.router.navigate(['/file', this.item.sync_id, 'view', 'officefse'], {
                    queryParams: {
                        view: queryParams['view'],
                        page: queryParams['page']
                    }
                });
            }
            else {
                var ref = this.modalService.open(wopi_upgrade_component_1.WopiUpgradeComponent, {
                    backdropClass: 'in',
                    windowClass: 'in',
                    backdrop: 'static',
                });
                ref.componentInstance.id = params['id'];
            }
        }
    };
    PreviewMenuComponent.prototype.downloadOriginal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.item.blobtype = 'btFILE';
                        if (!(this.item.compat && this.item.context == 'link')) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.buildTransferItemService.getCompatUrl(this.item, this.buildTransferItemService.ACT_DOWNLOAD)];
                    case 1:
                        _a.url = _c.sent();
                        window.location.href = this.url;
                        return [3 /*break*/, 6];
                    case 2:
                        if (!(this.item.compat && this.item.context == 'applink')) return [3 /*break*/, 3];
                        window.location.href = this.item.compaturl_dl;
                        return [3 /*break*/, 6];
                    case 3:
                        if (!(this.item.context == 'link')) return [3 /*break*/, 4];
                        this.transferService.queueDownload([this.item]);
                        return [3 /*break*/, 6];
                    case 4:
                        _b = this;
                        return [4 /*yield*/, this.buildTransferItemService.getCompatUrl(this.item, this.buildTransferItemService.ACT_DOWNLOAD)];
                    case 5:
                        _b.url = _c.sent();
                        window.location.href = this.url;
                        _c.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    PreviewMenuComponent.prototype.toggleComments = function () {
        this.showComments = !this.showComments;
    };
    return PreviewMenuComponent;
}());
exports.PreviewMenuComponent = PreviewMenuComponent;
