"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var logger_service_1 = require("../logger.service");
var sync_crypt_service_1 = require("./sync-crypt.service");
var models_1 = require("../../shared/models");
var api_service_1 = require("../api.service");
var DirtyInService = /** @class */ (function () {
    function DirtyInService(api, log, crypt) {
        this.api = api;
        this.log = log;
        this.crypt = crypt;
    }
    DirtyInService.prototype.processDirtyIn = function (worklist) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var metalist, items, sharekeys, i, len, item, sharekey, plainName, encName, result, e_1, msg, retval;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        metalist = [], items = worklist.items || [], sharekeys = worklist.sharekeys;
                        i = 0, len = items.length;
                        _a.label = 1;
                    case 1:
                        if (!(i < len)) return [3 /*break*/, 8];
                        item = items[i];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 6, , 7]);
                        if (!sharekeys || !sharekeys[item.sharekey_id]) {
                            throw new Error('No share key found for ' + item.sharekey_id);
                        }
                        return [4 /*yield*/, this.crypt.sharekeyDecrypt(sharekeys[item.sharekey_id], item.sharekey_id)];
                    case 3:
                        sharekey = _a.sent();
                        return [4 /*yield*/, this.crypt.filenameDecrypt(item.enc_share_name, sharekey)];
                    case 4:
                        plainName = _a.sent();
                        return [4 /*yield*/, this.crypt.filenameEncrypt(plainName)];
                    case 5:
                        encName = _a.sent();
                        result = new models_1.DirtyInTaskResult();
                        result.id = item.id;
                        result.pid = item.pid;
                        result.enc_name = encName;
                        result.hist_id = item.hist_id;
                        result.share_id = item.share_id;
                        result.sharename_digest = item.sharename_digest;
                        metalist.push(result);
                        return [3 /*break*/, 7];
                    case 6:
                        e_1 = _a.sent();
                        this.log.e('An error occurred attempting to process dirty in', e_1);
                        msg = [
                            ' ID: ', item.id,
                            ' PID: ', item.pid,
                            ' SHAREID: ', item.share_id,
                            ' Sharekey ID: ', item.sharekey_id
                        ].join('');
                        this.log.error(msg);
                        if (e_1 instanceof Error) {
                            throw e_1;
                        }
                        else {
                            throw new Error(msg);
                        }
                        return [3 /*break*/, 7];
                    case 7:
                        i++;
                        return [3 /*break*/, 1];
                    case 8:
                        if (metalist.length) {
                            retval = this.api.execute('pathsetmetanamelist', {
                                list: metalist
                            });
                            return [2 /*return*/];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return DirtyInService;
}());
exports.DirtyInService = DirtyInService;
