"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var transfer_service_1 = require("../../transfer/transfer.service");
var DialogTransferDirective = /** @class */ (function () {
    function DialogTransferDirective(transferService) {
        this.transferService = transferService;
    }
    DialogTransferDirective.prototype.click = function (event) {
        this.transferService.showTransfer();
    };
    return DialogTransferDirective;
}());
exports.DialogTransferDirective = DialogTransferDirective;
