"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var auth_service_1 = require("./auth.service");
var api_service_1 = require("../../core/api.service");
var models_1 = require("../../shared/models");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var logger_service_1 = require("../../core/logger.service");
var user_service_1 = require("../../core/user.service");
var i0 = require("@angular/core");
var i1 = require("../../core/api.service");
var i2 = require("./auth.service");
var i3 = require("../../core/crypt/sync-crypt.service");
var i4 = require("../../core/logger.service");
var i5 = require("../../core/user.service");
var PasswordService = /** @class */ (function () {
    function PasswordService(api, auth, crypt, log, user) {
        this.api = api;
        this.auth = auth;
        this.crypt = crypt;
        this.log = log;
        this.user = user;
        this.bCrypt = new bCrypt();
    }
    PasswordService.prototype.passwordValid = function (username, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var hashPass, keyData, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.auth.getHashedPassword(username, password)];
                    case 1:
                        hashPass = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 6]);
                        return [4 /*yield*/, this.api.send('userverifypassword', {
                                username: username,
                                password: hashPass
                            })];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.api.send('getuserkeys', {
                                username: username,
                                password: hashPass
                            })];
                    case 4:
                        keyData = _a.sent();
                        return [2 /*return*/, keyData];
                    case 5:
                        e_1 = _a.sent();
                        throw new models_1.ErrCode(8101);
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * RSA encrypts their new password with the Sync.com pubkey.
     * @param {string} code the code the user enters
     * @param {string} cachekey the cachekey in the URL
     * @param {string} passwordPlain their new plain text password
     * @returns {Promise}
     */
    PasswordService.prototype.resetPasswordMultiUser = function (childUserId, parentPassword, passwordPlain) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pubkey, hashPass, passResetKeys, pubkeyData, encPassword, parentPass;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.fetchText('/key/password-recover/pubkey')];
                    case 1:
                        pubkey = _a.sent();
                        return [4 /*yield*/, this.auth.hashPassword(this.bCrypt.gensalt(8), passwordPlain)];
                    case 2:
                        hashPass = _a.sent();
                        return [4 /*yield*/, this.crypt.prepareResetPasswords(passwordPlain, pubkey)];
                    case 3:
                        passResetKeys = _a.sent();
                        return [4 /*yield*/, this.api.execute('getuserpubkey', {
                                target_user_id: childUserId
                            })];
                    case 4:
                        pubkeyData = _a.sent();
                        return [4 /*yield*/, this.crypt.userpasswordEncrypt(passwordPlain, pubkeyData.pub_key)];
                    case 5:
                        encPassword = _a.sent();
                        return [4 /*yield*/, this.auth.getHashedPassword(this.user.getUser().email, parentPassword)];
                    case 6:
                        parentPass = _a.sent();
                        return [4 /*yield*/, this.api.execute('multiuserpasswordreset', {
                                enc_pass_meta: passResetKeys.encPassMeta,
                                salt_meta: passResetKeys.metaSalt,
                                enc_pass_priv: passResetKeys.encPassPriv,
                                salt_priv: passResetKeys.privSalt,
                                password_hash: hashPass,
                                enc_password: encPassword,
                                child_user_id: childUserId,
                                parent_password: parentPass
                            })];
                    case 7: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PasswordService.prototype.disablePassReset = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.execute('userinfoset', {
                            passreset: { enable_reset: 0 }
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PasswordService.prototype.isPasswordStrong = function (pwd) {
        var hasMinMaxLenght = pwd.length > 5 && pwd.length < 73;
        // let hasNumber = /\d/.test(pwd);
        // let hasUpper = /[A-Z]/.test(pwd);
        // let hasLower = /[a-z]/.test(pwd);
        // let hasSpecialCharacter = /[^a-zA-Z0-9]/.test(pwd);
        // kept this so that it can be used when we work on the whole strong password epic
        return hasMinMaxLenght;
    };
    PasswordService.prototype.enablePassReset = function (plainPass, keyData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pubkey, encPassMeta, encPassPriv;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.fetchText('/key/password-recover/pubkey')];
                    case 1:
                        pubkey = _a.sent();
                        return [4 /*yield*/, this.crypt.prepareResetKey(keyData.enc_meta_key, plainPass, pubkey)];
                    case 2:
                        encPassMeta = _a.sent();
                        return [4 /*yield*/, this.crypt.prepareResetKey(keyData.enc_priv_key, plainPass, pubkey)];
                    case 3:
                        encPassPriv = _a.sent();
                        return [4 /*yield*/, this.api.execute('userinfoset', {
                                passreset: {
                                    enable_reset: 1,
                                    enc_pass_meta: encPassMeta,
                                    enc_pass_priv: encPassPriv
                                }
                            })];
                    case 4: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PasswordService.ngInjectableDef = i0.defineInjectable({ factory: function PasswordService_Factory() { return new PasswordService(i0.inject(i1.ApiService), i0.inject(i2.AuthService), i0.inject(i3.SyncCryptService), i0.inject(i4.LoggerService), i0.inject(i5.UserService)); }, token: PasswordService, providedIn: "root" });
    return PasswordService;
}());
exports.PasswordService = PasswordService;
