"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var logger_service_1 = require("../../core/logger.service");
var validate_service_1 = require("../../core/validate.service");
var file_list_service_1 = require("../services/file-list.service");
var constants_1 = require("../../transfer/constants");
var transfer_service_1 = require("../../transfer/transfer.service");
var blend_service_1 = require("../../shared/services/blend.service");
var models_1 = require("src/app/shared/models");
var file_item_service_1 = require("../../file/file-item.service");
var DialogCreateBlankDocumentComponent = /** @class */ (function () {
    function DialogCreateBlankDocumentComponent(fileListService, loggerService, http, transferService, validateService, activeModal, blendService, fileItemService) {
        this.fileListService = fileListService;
        this.loggerService = loggerService;
        this.http = http;
        this.transferService = transferService;
        this.validateService = validateService;
        this.activeModal = activeModal;
        this.blendService = blendService;
        this.fileItemService = fileItemService;
        this.fileName = 'untitled.docx';
        this.documents = [];
        this.maxFileNameLength = constants_1.Constants.MAX_FILE_NAME_LEN;
    }
    DialogCreateBlankDocumentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.errors = [];
        this.prepareDocumentList();
        this.onSelectDocType('.docx');
        this.fileListService.getListSubscription().subscribe(function (data) {
            if (data &&
                data.pathlist.length &&
                !(_this.pathlist !== undefined && _this.pathlist.length)) {
                _this.pathlist = data.pathlist;
                _this.onSelectDocType('.docx');
            }
        });
    };
    /**
     * set doc type based on file selecetion
     * @param docType
     */
    DialogCreateBlankDocumentComponent.prototype.onSelectDocType = function (docType) {
        if (docType) {
            this.errors = [];
            this.selectedDocType = docType;
            if (this.fileName.includes('.')) {
                this.fileName = this.fileName.substring(0, this.fileName.lastIndexOf('.'));
            }
            this.fileName = this.createFileName(this.selectedDocType);
        }
        else {
            this.selectedDocType = constants_1.Constants.TEXT_FILE_TYPE;
        }
    };
    /**
     * create document
     */
    DialogCreateBlankDocumentComponent.prototype.createDocument = function () {
        this.errors = [];
        try {
            if (this.isValid()) {
                var docType = this.selectedDocType;
                this.blendService.track(models_1.BlendEvent.CREATE_FILE, {
                    fileType: docType,
                    mimeType: this.fileItemService.getmimeType(docType)
                });
                switch (docType) {
                    case constants_1.Constants.DOC_FILE_TYPE:
                        this.createDocxDocument(this.createFileName(docType));
                        break;
                    case constants_1.Constants.PPT_FILE_TYPE:
                        this.createPptDocument(this.createFileName(docType));
                        break;
                    case constants_1.Constants.EXCEL_FILE_TYPE:
                        this.createExcelDocument(this.createFileName(docType));
                        break;
                    case constants_1.Constants.TEXT_FILE_TYPE:
                        this.createTextDocument(this.fileName.trim());
                        break;
                }
            }
            else {
                this.loggerService.warn('create document method document type is not available');
            }
        }
        catch (err) {
            this.errors.push({ code: err.code });
            this.loggerService.error('Error in create Document ' + err);
        }
    };
    /**
     * prepare document list based on constants
     */
    DialogCreateBlankDocumentComponent.prototype.prepareDocumentList = function () {
        var _this = this;
        try {
            constants_1.Constants.DOCUMENT_TYPE_LIST.map(function (document, index) {
                var documentObject = {
                    id: index,
                    name: document,
                    extension: constants_1.Constants.FILE_TYPE_LIST[index],
                    svg: constants_1.Constants.DOCUMENT_SVG_IMAGE_LIST[index],
                };
                _this.documents.push(documentObject);
            });
        }
        catch (err) {
            this.loggerService.error('Error on prepare document list ' + err);
        }
    };
    /**
     * create excel file object
     * @param fileName
     */
    DialogCreateBlankDocumentComponent.prototype.createExcelDocument = function (fileName) {
        var _this = this;
        try {
            var url = constants_1.Constants.DUMMY_TEMPLATES_PATH +
                constants_1.Constants.DUMMY_MS_EXCEL_FILE_NAME;
            this.http.get(url, { responseType: 'arraybuffer' }).subscribe(function (response) {
                _this.createFile(response, constants_1.Constants.EXCEL_BLOB_TYPE, fileName);
            }, function (err) {
                _this.loggerService.error('Error while get request, reading file ' + err);
                throw err;
            });
        }
        catch (err) {
            this.loggerService.error('Error in create Excel document ' + err);
            throw err;
        }
    };
    /**
     * create ppt file blank document
     * @param fileName
     */
    DialogCreateBlankDocumentComponent.prototype.createPptDocument = function (fileName) {
        var _this = this;
        try {
            var url = constants_1.Constants.DUMMY_TEMPLATES_PATH +
                constants_1.Constants.DUMMY_MS_PPT_FILE_NAME;
            this.http.get(url, { responseType: 'arraybuffer' }).subscribe(function (response) {
                _this.createFile(response, constants_1.Constants.PPT_BLOB_TYPE, fileName);
            }, function (err) {
                _this.loggerService.error('Error while get request, reading file ' + err);
                throw err;
            });
        }
        catch (err) {
            this.loggerService.error('Error in create pptx document ' + err);
            throw err;
        }
    };
    /**
     * create text file object
     * @param fileName
     */
    DialogCreateBlankDocumentComponent.prototype.createTextDocument = function (fileName) {
        var _this = this;
        try {
            var url = constants_1.Constants.DUMMY_TEMPLATES_PATH + constants_1.Constants.DUMMY_TEXT_FILE_NAME;
            this.http.get(url, { responseType: 'arraybuffer' }).subscribe(function (response) {
                _this.createFile(response, constants_1.Constants.TEXT_BLOB_TYPE, fileName);
            }, function (err) {
                _this.loggerService.error('Error while get request, reading file ' + err);
                throw err;
            });
        }
        catch (err) {
            this.loggerService.warn('error in create Text doucment ' + err);
            throw err;
        }
    };
    /**
     * create docx file object
     * @param fileExtension
     */
    DialogCreateBlankDocumentComponent.prototype.createDocxDocument = function (fileName) {
        var _this = this;
        try {
            var url = constants_1.Constants.DUMMY_TEMPLATES_PATH +
                constants_1.Constants.DUMMY_MS_WORD_FILE_NAME;
            this.http.get(url, { responseType: 'arraybuffer' }).subscribe(function (response) {
                _this.createFile(response, constants_1.Constants.DOCX_BLOB_TYPE, fileName);
            }, function (err) {
                _this.loggerService.error('Error while get request, reading file ' + err);
                throw err;
            });
        }
        catch (err) {
            this.loggerService.error('Error in Create docx document ' + err);
            throw err;
        }
    };
    /**
     * convert blob object into file.
     * @param theBlob
     * @param fileName
     * @returns
     */
    DialogCreateBlankDocumentComponent.prototype.blobToFile = function (theBlob, fileName) {
        var blob = theBlob;
        blob.lastModifiedDate = new Date();
        blob.name = fileName;
        return theBlob;
    };
    /**
     * upload blank document.
     * @param file
     */
    DialogCreateBlankDocumentComponent.prototype.uploadFile = function (file) {
        var _this = this;
        this.transferService
            .queuePublicBlankDocUpload(file)
            .then(function (success) {
            _this.loggerService.info('file upload successfully' + JSON.stringify(success));
            _this.activeModal.close();
        })
            .catch(function (err) {
            _this.loggerService.error('Error in upload file ' + err);
            throw { code: 7244 };
        });
    };
    /**
     * check if file type selected & filename is given
     * won't allow to put .(dot) in filename
     * @returns boolean true/false
     */
    DialogCreateBlankDocumentComponent.prototype.isValid = function () {
        if (!this.selectedDocType) {
            throw { code: 7242 };
        }
        if (!this.fileName.length) {
            throw { code: 7241 };
        }
        if (!this.validateService.isNameValid(this.fileName)) {
            this.loggerService.warn('File name ' + this.fileName + ' is not allowed');
            throw { code: 8213 };
        }
        if (this.fileExists(this.fileName)) {
            throw { code: 8211 };
        }
        return true;
    };
    /**
     * create file Name with extension
     * @param fileExtension
     * @returns
     */
    DialogCreateBlankDocumentComponent.prototype.createFileName = function (fileExtension) {
        var fileName = this.fileName;
        if (fileName.includes('.')) {
            var extension = fileName.substring(fileName.lastIndexOf('.'), fileName.length);
            if (extension !== this.selectedDocType) {
                return (this.fileName + fileExtension).trim();
            }
            return this.fileName.trim();
        }
        return (this.fileName + fileExtension).trim();
    };
    /**
     * create File object
     * @param data
     * @param blobType
     * @param fileName
     */
    DialogCreateBlankDocumentComponent.prototype.createFile = function (data, blobType, fileName) {
        try {
            var file = new File([data], fileName);
            this.uploadFile(file);
        }
        catch (err) {
            this.loggerService.error('Error in createFile method, ' + err);
            throw err;
        }
    };
    /**
     * check if file with same name exists
     * @param value
     */
    DialogCreateBlankDocumentComponent.prototype.fileExists = function (fileName) {
        if (fileName === undefined || fileName === '' || fileName === null) {
            return false;
        }
        if (!this.pathlist) {
            return false;
        }
        if (!fileName.endsWith(this.selectedDocType)) {
            fileName = fileName + this.selectedDocType;
        }
        var matchingFileName = this.pathlist.find(function (f) { return f.name.toLowerCase() === fileName.toLowerCase(); });
        return matchingFileName !== undefined;
    };
    /**
     * on text change event
     * @param value
     */
    DialogCreateBlankDocumentComponent.prototype.change = function () {
        this.errors = [];
    };
    return DialogCreateBlankDocumentComponent;
}());
exports.DialogCreateBlankDocumentComponent = DialogCreateBlankDocumentComponent;
