"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var logger_service_1 = require("./logger.service");
var sync_crypt_service_1 = require("./crypt/sync-crypt.service");
var models_1 = require("../shared/models");
var url_service_1 = require("./url.service");
var environment_1 = require("../../environments/environment");
var router_1 = require("@angular/router");
var i0 = require("@angular/core");
var i1 = require("./crypt/sync-crypt.service");
var i2 = require("@angular/common/http");
var i3 = require("./logger.service");
var i4 = require("./url.service");
var i5 = require("@angular/router");
var ApiService = /** @class */ (function () {
    function ApiService(crypt, http, injector, log, url, router) {
        this.crypt = crypt;
        this.http = http;
        this.injector = injector;
        this.log = log;
        this.url = url;
        this.router = router;
    }
    ApiService.prototype.exec = function (name, params, retryEnabled) {
        var _this = this;
        if (retryEnabled === void 0) { retryEnabled = true; }
        var url = this.url.mkApi(environment_1.environment.apihosts[0], name);
        return this.http
            .post(url, params)
            .pipe(operators_1.map(function (result) {
            if (result.success === 0) {
                _this.log.error("200 " + url + " error occurred");
                switch (result.error_code) {
                    case 8050:
                    case 8051:
                        // suspended
                        _this.router.navigate(['/account-suspended'], { queryParams: result.data });
                        break;
                    case 8054:
                        // incomplete
                        _this.router.navigate(['/account-suspended'], { queryParams: tslib_1.__assign({}, result.data, { m: 2 }) });
                        break;
                    case 8055:
                        // migrating
                        _this.router.navigate(['/account-suspended'], { queryParams: tslib_1.__assign({}, result.data, { m: 1 }) });
                        break;
                    case 8052:
                        // accounting hold
                        _this.router.navigate(['/accounting-hold'], { queryParams: result.data });
                        break;
                    case 8053:
                        // accounting hold
                        _this.router.navigate(['/account-closed']);
                        break;
                    default:
                        _this.log.error('Received an error code from the server ' + result.error_code);
                        break;
                }
                throw models_1.ErrCode.fromApiLegacy(result);
            }
            else if (result.success === 1 && result.logout === 1 && name != 'sessiondelete') {
                _this.log.error("Received a logout from API on " + url);
                _this.handleLogout();
                return;
            }
            _this.log.info("200 " + url + "  successful");
            return result;
        }), operators_1.retryWhen(function (errors) {
            return errors.pipe(operators_1.delay(1000), operators_1.take(3), operators_1.switchMap(function (httpErr) {
                if ((httpErr instanceof http_1.HttpErrorResponse) && retryEnabled === true) {
                    var shouldRetry = rxjs_1.of(httpErr); //= observableThrowError(httpErr);
                    _this.log.error("ERROR INPUT: " + JSON.stringify(params));
                    _this.log.error("ERROR OUTPUT: " + httpErr.status + " " + httpErr.statusText + " :: " + httpErr.url);
                    // this.log.error(`Error output ${JSON.stringify(response)}`);
                    switch (httpErr.status) {
                        case 401:
                            //this.handleLogout();
                            shouldRetry = rxjs_1.throwError(httpErr);
                            break;
                        case 400:
                        case 429:
                        case 500:
                            // do not retry, this is an error
                            shouldRetry = rxjs_1.throwError(httpErr);
                            break;
                        case 501:
                        case 502:
                        case 503:
                        case 504:
                            // this may be a temporary error
                            // shouldRetry = observableThrowError(httpErr);
                            break;
                        default:
                            // default is to retry
                            shouldRetry = rxjs_1.throwError(httpErr);
                            break;
                    }
                    return shouldRetry;
                }
                else {
                    // httpErr is an API result
                    return rxjs_1.throwError(httpErr);
                }
            }), operators_1.concat(rxjs_1.throwError(new models_1.ErrCode(9000))));
        }));
    };
    /**
     * This sends an unsigned request ot the API
     * @param name the API name
     * @param params the API input parameters
     */
    ApiService.prototype.send = function (name, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.exec(name, params).toPromise()];
                    case 1:
                        response = _a.sent();
                        if (!response) {
                            throw new models_1.ErrCode(9000);
                        }
                        return [2 /*return*/, response];
                    case 2:
                        err_1 = _a.sent();
                        this.handleError(name, params, err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * This builds the HTTP request to the EXTAPI.
     * Retry is enabled always. Regardless of the error produced from the ExtApi
     */
    ApiService.prototype.execExt = function (path, method, params) {
        var _this = this;
        var retryEnabled = true;
        var url = this.url.mkExtApiUrl(path);
        // console.log(`Extapi requests to ${url}`);
        if (method === 'GET') {
            // Note: params for GET are query params
            return this.http
                .get(url, { params: params })
                .pipe(operators_1.map(function (result) {
                if (result.success === 0) {
                    _this.log.error("EXTAPI 200 " + url + " error occurred");
                    _this.log.error('EXTAPI Received an error code from the server ' + result.error_code);
                    throw models_1.ErrCode.fromApiLegacy(result);
                }
                _this.log.info("200 " + url + "  successful");
                return result;
            }), operators_1.retryWhen(function (errors) {
                return errors.pipe(operators_1.delay(1000), operators_1.take(3), operators_1.switchMap(function (httpErr) {
                    if ((httpErr instanceof http_1.HttpErrorResponse) && retryEnabled === true) {
                        var shouldRetry = rxjs_1.of(httpErr); //= observableThrowError(httpErr);
                        _this.log.error("EXTAPI ERROR INPUT: " + JSON.stringify(params));
                        _this.log.error("EXTAPI ERROR OUTPUT: " + httpErr.status + " " + httpErr.statusText + " :: " + httpErr.url);
                        // this.log.error(`Error output ${JSON.stringify(response)}`);
                        if (httpErr.status > 300 && httpErr.status < 500) {
                            // Throw on 3xx and 4xx
                            shouldRetry = rxjs_1.throwError(httpErr);
                        }
                        else {
                            // Retry on 5xx
                        }
                        return shouldRetry;
                    }
                    else {
                        // httpErr is an API result
                        return rxjs_1.throwError(httpErr);
                    }
                }), operators_1.concat(rxjs_1.throwError(new models_1.ErrCode(9000))));
            }));
        }
        else if (method === 'POST') {
            // Note: params for POST are the body
            return this.http
                .post(url, params)
                .pipe(operators_1.map(function (result) {
                if (result.success === 0) {
                    _this.log.error("EXTAPI 200 " + url + " error occurred");
                    _this.log.error('EXTAPI Received an error code from the server ' + result.error_code);
                    throw models_1.ErrCode.fromApiLegacy(result);
                }
                _this.log.info("200 " + url + "  successful");
                return result;
            }), operators_1.retryWhen(function (errors) {
                return errors.pipe(operators_1.delay(1000), operators_1.take(3), operators_1.switchMap(function (httpErr) {
                    if ((httpErr instanceof http_1.HttpErrorResponse) && retryEnabled === true) {
                        var shouldRetry = rxjs_1.of(httpErr); //= observableThrowError(httpErr);
                        _this.log.error("EXTAPI ERROR INPUT: " + JSON.stringify(params));
                        _this.log.error("EXTAPI ERROR OUTPUT: " + httpErr.status + " " + httpErr.statusText + " :: " + httpErr.url);
                        // this.log.error(`Error output ${JSON.stringify(response)}`);
                        if (httpErr.status > 300 && httpErr.status < 500) {
                            // Throw on 3xx and 4xx
                            shouldRetry = rxjs_1.throwError(httpErr);
                        }
                        else {
                            // Retry on 5xx
                        }
                        return shouldRetry;
                    }
                    else {
                        // httpErr is an API result
                        return rxjs_1.throwError(httpErr);
                    }
                }), operators_1.concat(rxjs_1.throwError(new models_1.ErrCode(9000))));
            }));
        }
    };
    /**
     * This sends an unsigned request to the EXTERNAL API
     * @param name the API name
     * @param params the API input parameters
     */
    ApiService.prototype.sendExt = function (path, method, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.execExt(path, method, params).toPromise()];
                    case 1:
                        response = _a.sent();
                        if (!response) {
                            throw new models_1.ErrCode(9000);
                        }
                        return [2 /*return*/, response];
                    case 2:
                        err_2 = _a.sent();
                        this.handleError(name, params, err_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    // sends a signed request
    ApiService.prototype.execute = function (name, params, retry) {
        if (retry === void 0) { retry = true; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var input, response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.crypt.signApiReq(params)];
                    case 1:
                        input = _a.sent();
                        return [4 /*yield*/, this.exec(name, input, retry).toPromise()];
                    case 2:
                        response = _a.sent();
                        if (!response) {
                            throw new models_1.ErrCode(9000);
                        }
                        return [2 /*return*/, response];
                    case 3:
                        err_3 = _a.sent();
                        this.handleError(name, params, err_3);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ApiService.prototype.fetchText = function (url) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(url, { observe: 'response', responseType: 'text' }).toPromise()];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                }
            });
        });
    };
    ApiService.prototype.fetchThumbs = function (items) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post(this.url.mkDownloadMulti(), { items: items }, { responseType: 'arraybuffer' }).toPromise()];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    ApiService.prototype.fetchFile = function (tItem, offset, reqLength, retry) {
        var _this = this;
        if (retry === void 0) { retry = 0; }
        var req = new http_1.HttpRequest('GET', this.url.mkDownloadUrl(tItem, offset, reqLength, retry), {
            reportProgress: true,
            responseType: 'arraybuffer',
        });
        return this.http.request(req)
            .pipe(operators_1.retryWhen(function (errors) {
            return errors.pipe(operators_1.delay(1000), operators_1.take(2), operators_1.switchMap(function (httpErr) {
                var shouldRetry = rxjs_1.of(httpErr); //= observableThrowError(httpErr);
                if (httpErr instanceof http_1.HttpErrorResponse) {
                    // let shouldRetry = of(httpErr);
                    console.log(httpErr);
                    _this.log.warn("Received status " + httpErr.status + " " + httpErr.statusText);
                    switch (httpErr.status) {
                        case -1:
                        case 0:
                            // do not retry, this is an error
                            _this.log.error('Status -1, timeout ' + tItem.cachekey);
                            // shouldRetry = observableThrowError(new ErrCode(7101));
                            break;
                        case 404:
                            _this.log.error('404 error ' + tItem.cachekey);
                            shouldRetry = rxjs_1.throwError(new models_1.ErrCode(7102));
                            break;
                        case 500:
                        case 501:
                        case 502:
                        case 503:
                        case 504:
                            // this may be a temporary error
                            // shouldRetry = observableThrowError(httpErr);
                            break;
                        case 510:
                            _this.log.error('Blobchunk failed for ' + tItem.cachekey);
                            shouldRetry = rxjs_1.throwError(new models_1.ErrCode(7103));
                            break;
                        default:
                            // default is to retry
                            _this.log.error('Unknown error occurred ' + tItem.cachekey);
                            shouldRetry = rxjs_1.throwError(new models_1.ErrCode(7100));
                            break;
                    }
                    return shouldRetry;
                }
                else {
                    // httpErr is an API result
                    return rxjs_1.throwError(httpErr);
                }
            }), operators_1.concat(rxjs_1.throwError(new models_1.ErrCode(7100))));
        }));
    };
    ApiService.prototype.fetchFilePlain = function (tItem, retry) {
        var _this = this;
        if (retry === void 0) { retry = 0; }
        var req = new http_1.HttpRequest('GET', tItem.renderFile.url, {
            reportProgress: false,
            responseType: 'arraybuffer',
        });
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.http.request(req)
                    .pipe(operators_1.retryWhen(function (errors) {
                    return errors.pipe(operators_1.delay(1000), operators_1.take(2), operators_1.switchMap(function (httpErr) {
                        var shouldRetry = rxjs_1.of(httpErr); //= observableThrowError(httpErr);
                        if (httpErr instanceof http_1.HttpErrorResponse) {
                            // let shouldRetry = of(httpErr);
                            console.log(httpErr);
                            _this.log.warn("Received status " + httpErr.status + " " + httpErr.statusText);
                            switch (httpErr.status) {
                                case -1:
                                case 0:
                                    // do not retry, this is an error
                                    _this.log.error('Status -1, timeout ' + tItem.cachekey);
                                    // shouldRetry = observableThrowError(new ErrCode(7101));
                                    break;
                                case 404:
                                    _this.log.error('404 error ' + tItem.cachekey);
                                    shouldRetry = rxjs_1.throwError(new models_1.ErrCode(7102));
                                    break;
                                case 500:
                                case 501:
                                case 502:
                                case 503:
                                case 504:
                                    // this may be a temporary error
                                    // shouldRetry = observableThrowError(httpErr);
                                    break;
                                case 510:
                                    _this.log.error('Blobchunk failed for ' + tItem.cachekey);
                                    shouldRetry = rxjs_1.throwError(new models_1.ErrCode(7103));
                                    break;
                                default:
                                    // default is to retry
                                    _this.log.error('Unknown error occurred ' + tItem.cachekey);
                                    shouldRetry = rxjs_1.throwError(new models_1.ErrCode(7100));
                                    break;
                            }
                            return shouldRetry;
                        }
                        else {
                            // httpErr is an API result
                            return rxjs_1.throwError(httpErr);
                        }
                    }), operators_1.concat(rxjs_1.throwError(new models_1.ErrCode(7100))));
                }))
                    .subscribe(function (resp) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var respBuffer;
                    return tslib_1.__generator(this, function (_a) {
                        if (resp.type === http_1.HttpEventType.Response) {
                            respBuffer = resp.body;
                            resolve(respBuffer);
                        }
                        else {
                            this.log.info('Recieved HTTP event : ' + resp);
                        }
                        return [2 /*return*/];
                    });
                }); }, function (err) {
                    // reject(err);
                    _this.log.e('Error subscribing to fetch file in download-worker', err);
                    if (_this.url.downloadhosts[retry + 1]) {
                        _this.log.warn("Retrying download on host " + _this.url.downloadhosts[retry + 1]);
                        resolve(_this.fetchFilePlain(tItem, retry + 1));
                    }
                    else {
                        _this.log.error("Attempted " + retry + " retries and failed, rejecting file");
                        reject(err);
                    }
                });
                return [2 /*return*/];
            });
        }); });
    };
    ApiService.prototype.subscribe = function () {
        var headers = new http_1.HttpHeaders({
            'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            'Pragma': 'no-cache',
            'Expires': '0',
            'timeout': '600000',
        });
        return this.http.get(this.url.mkSubscribe(), { headers: headers })
            .pipe(operators_1.retry(3));
    };
    ApiService.prototype.subscribeJob = function (jobId) {
        var headers = new http_1.HttpHeaders({
            'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            'Pragma': 'no-cache',
            'Expires': '0',
            'timeout': '600000',
        });
        return this.http.get('/sub?cid=' + jobId, { headers: headers })
            .pipe(operators_1.retry(99));
    };
    ApiService.prototype.handleLogout = function () {
        var returnTo = this.url.getReturnTo();
        this.router.navigate(['/logout'], { queryParams: { return_to: returnTo } });
    };
    ApiService.prototype.handleError = function (name, params, err) {
        if (err instanceof http_1.HttpErrorResponse) {
            if (err.status === 429) {
                throw new models_1.ErrCode(9429, 'IP has been rate limited for 30 minutes to due to incorrect login attempts');
            }
            else if (err.error && err.error.error_code) {
                throw new models_1.ErrCode(err.error.error_code, err.error.error_msg);
            }
            else if (err.status) {
                throw new models_1.ErrCode(parseInt('9' + err.status, 10));
            }
            else {
                throw models_1.ErrCode.fromApiLegacy(err.error);
            }
        }
        else if (err.error_code != undefined && err.error_msg != undefined) {
            if (err.error_code == 0 && err.error_msg.indexOf('NO CONFIG DATABASE') > -1) {
                this.log.error('no config database ' + err.error_msg);
                throw new models_1.ErrCode(this.getCodeForShardErr(err.error_msg));
            }
            else {
                this.log.error("API error " + name);
                this.log.error("ERROR INPUT: " + JSON.stringify(params));
                this.log.error("ERROR OUTPUT " + JSON.stringify(err));
                throw models_1.ErrCode.fromApiLegacy(err);
            }
        }
        else if (err instanceof models_1.ErrCode) {
            this.log.error("API error " + name);
            this.log.error("ERROR INPUT: " + JSON.stringify(params));
            this.log.error("ERROR OUTPUT " + JSON.stringify(err));
            if (err.code === 1680 || err.code === 1666) {
                throw new models_1.ErrCode(err.code);
            }
            throw err;
        }
        else if (err.success === 0) {
            this.log.error("ERROR INPUT: " + JSON.stringify(params));
            this.log.error("ERROR OUTPUT " + JSON.stringify(err));
            throw new models_1.ErrCode(9000);
        }
        else {
            throw new models_1.ErrCode(9000);
        }
    };
    ApiService.prototype.getCodeForShardErr = function (errMsg) {
        var code = 1100;
        if (errMsg.indexOf('linkcachekeys_') > -1) {
            code = 1101;
        }
        else if (errMsg.indexOf('invitecachekeys_') > -1) {
            code = 1102;
        }
        else if (errMsg.indexOf('refcodes_') > -1) {
            code = 1103;
        }
        else if (errMsg.indexOf('verifyusers_') > -1) {
            code = 1104;
        }
        return code;
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.SyncCryptService), i0.inject(i2.HttpClient), i0.inject(i0.INJECTOR), i0.inject(i3.LoggerService), i0.inject(i4.UrlService), i0.inject(i5.Router)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
exports.ApiService = ApiService;
