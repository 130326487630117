"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var logger_service_1 = require("../../core/logger.service");
var storage_service_1 = require("../../core/storage/storage.service");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var transfer_item_service_1 = require("./transfer-item.service");
var transfer_model_1 = require("../transfer.model");
var download_worker_service_1 = require("./download-worker.service");
var download_legacy_service_1 = require("./download-legacy.service");
var browser_support_service_1 = require("../../core/browser-support.service");
var api_service_1 = require("../../core/api.service");
var crypt_engine_model_1 = require("../../shared/models/crypt-engine.model");
var i0 = require("@angular/core");
var i1 = require("../../core/browser-support.service");
var i2 = require("../../core/crypt/sync-crypt.service");
var i3 = require("./download-legacy.service");
var i4 = require("./download-worker.service");
var i5 = require("../../core/logger.service");
var i6 = require("../../core/storage/storage.service");
var i7 = require("./transfer-item.service");
var i8 = require("../../core/api.service");
var DownloadService = /** @class */ (function () {
    function DownloadService(browser, crypt, downloadLegacy, downloadWorker, log, storage, transferItem, api) {
        this.browser = browser;
        this.crypt = crypt;
        this.downloadLegacy = downloadLegacy;
        this.downloadWorker = downloadWorker;
        this.log = log;
        this.storage = storage;
        this.transferItem = transferItem;
        this.api = api;
        // old = 131072
        // 1mb = 1048576
        // 2mb = 2097152
        // 3mb = 3145728
        // 4mb = 4194304
        // 5mb = 5242880
        // 10mb = 10485760
        this.chunksize = 1024 * 1024 * 25; // 10mb
        this.init();
    }
    DownloadService.prototype.init = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var Downloader;
            return tslib_1.__generator(this, function (_a) {
                switch (this.browser.getEncryptionEngine()) {
                    case crypt_engine_model_1.CryptEngine.NATIVE:
                        Downloader = this.downloadWorker;
                        break;
                    case crypt_engine_model_1.CryptEngine.BUFFER:
                        Downloader = this.downloadWorker;
                        break;
                    case crypt_engine_model_1.CryptEngine.LEGACY:
                        Downloader = this.downloadLegacy;
                        break;
                    default:
                        Downloader = this.downloadLegacy;
                        break;
                }
                this._Downloader = Downloader;
                this.log.info('TRANSFER download method = ' + this.browser.getEncryptionEngine());
                this._storeThumbs = this.storage.getMemoryStorage();
                return [2 /*return*/];
            });
        });
    };
    DownloadService.prototype.downloadCompat = function (tItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.prepare()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.downloadPlain(this.storage.getMemoryStorage(), tItem)];
                }
            });
        });
    };
    DownloadService.prototype.downloadPreview = function (tItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.prepare()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.download(this.storage.getMemoryStorage(), tItem)];
                }
            });
        });
    };
    DownloadService.prototype.downloadItem = function (tItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.prepare()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.download(this.storage.getDefaultStorage(), tItem)];
                }
            });
        });
    };
    DownloadService.prototype.saveThumb = function (tItem, bytes) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fileSize, start, decStart, data, decEnd, dlData, packetAmt, gcmOffset, writeOffset, wr, we, renderData, re, renderFile, pe, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fileSize = bytes.byteLength - Math.ceil(bytes.byteLength / 131072) * 36;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        start = Date.now();
                        this._storeThumbs.init({
                            dirname: 'syncpreview',
                            filesize: fileSize,
                            filename: tItem.filename,
                            mimetype: tItem.mimetype,
                            cachekey: tItem.cachekey,
                            blobtype: tItem.blobtype,
                            path: ''
                        });
                        this.log.d('  - ' + (Date.now() - start) + ' ms to initialize - resolved');
                        decStart = Date.now();
                        return [4 /*yield*/, this.crypt.filedataDecrypt(new Uint8Array(bytes), tItem.data_key, 0)];
                    case 2:
                        data = _a.sent();
                        decEnd = Date.now();
                        this.log.d('  - ' + (decEnd - decStart) + ' ms to decrypt data');
                        dlData = {
                            bytearray: data,
                            offset: 0,
                            reqlength: 0,
                            chunkLength: bytes.byteLength,
                            tItem: tItem
                        };
                        packetAmt = Math.ceil(dlData.offset / 131072), gcmOffset = packetAmt * 36;
                        writeOffset = dlData.offset - gcmOffset;
                        if (writeOffset < 0) {
                            writeOffset = 0;
                        }
                        wr = Date.now();
                        return [4 /*yield*/, this._storeThumbs.writeChunk(dlData.tItem, dlData.bytearray, writeOffset)];
                    case 3:
                        _a.sent();
                        we = Date.now();
                        this.log.d('  - ' + (we - wr) + ' ms of ThumbStore.writeChunk()');
                        return [4 /*yield*/, this._storeThumbs.getRenderData(tItem)];
                    case 4:
                        renderData = _a.sent();
                        re = Date.now();
                        this.log.d('  - ' + (re - we) + ' ms of ThumbStore.getRenderData()');
                        return [4 /*yield*/, this.prepareRenderData(renderData)];
                    case 5:
                        renderFile = _a.sent();
                        pe = Date.now();
                        this.log.d('  - ' + (pe - re) + ' ms of prepareRenderData()');
                        tItem.renderFile = renderFile;
                        tItem.status = transfer_model_1.TransferStatus.STATUS_SUCCESS;
                        this.log.d('  - ' + (Date.now() - start) + ' ms to saveThumb()');
                        return [3 /*break*/, 7];
                    case 6:
                        err_1 = _a.sent();
                        tItem.status = (err_1 && err_1.errcode) ? err_1.errcode : transfer_model_1.TransferStatus.STATUS_ERROR;
                        this.log.error('Caught an error in saveData for thumbs');
                        this.log.error(err_1);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/, tItem];
                }
            });
        });
    };
    DownloadService.prototype.download = function (store, tItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var stTime, renderData, renderFile, timeTaken, bps, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        stTime = Date.now();
                        tItem = this.checkDownload(store, tItem);
                        if (tItem.status !== transfer_model_1.TransferStatus.STATUS_WAITING) {
                            this.log.warn('Item is not waiting, returning ' + tItem.status);
                            return [2 /*return*/, tItem];
                        }
                        tItem.status = transfer_model_1.TransferStatus.STATUS_WORKING;
                        return [4 /*yield*/, store.init({
                                dirname: 'syncpreview',
                                filesize: tItem.filesize,
                                filename: tItem.filename,
                                mimetype: tItem.mimetype,
                                cachekey: tItem.cachekey,
                                blobtype: tItem.blobtype,
                                path: ''
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 6]);
                        return [4 /*yield*/, this.processChunks(store, tItem)];
                    case 3:
                        tItem = _a.sent();
                        // console.log('--------------- tItem = ' + tItem.status);
                        if (tItem.status >= transfer_model_1.TransferStatus.STATUS_ERROR) {
                            return [2 /*return*/, tItem];
                        }
                        return [4 /*yield*/, store.getRenderData(tItem)];
                    case 4:
                        renderData = _a.sent();
                        renderFile = this.prepareRenderData(renderData);
                        tItem.renderFile = renderFile;
                        timeTaken = (Date.now() - stTime) / 1000;
                        bps = tItem.filesize / timeTaken;
                        this.log.info("Download completed " + timeTaken + " seconds " + bps / 1000 + " kbps");
                        tItem.status = transfer_model_1.TransferStatus.STATUS_SUCCESS;
                        this._Downloader.completed(tItem);
                        return [3 /*break*/, 6];
                    case 5:
                        ex_1 = _a.sent();
                        if (!tItem.status || tItem.status == transfer_model_1.TransferStatus.STATUS_WORKING) {
                            tItem.status = transfer_model_1.TransferStatus.STATUS_ERROR;
                        }
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/, tItem];
                }
            });
        });
    };
    DownloadService.prototype.downloadPlain = function (store, tItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var stTime, result, renderData, renderFile, timeTaken, bps, ex_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        stTime = Date.now();
                        tItem = this.checkDownload(store, tItem);
                        if (tItem.status !== transfer_model_1.TransferStatus.STATUS_WAITING) {
                            this.log.warn('Item is not waiting, returning ' + tItem.status);
                            return [2 /*return*/, tItem];
                        }
                        tItem.status = transfer_model_1.TransferStatus.STATUS_WORKING;
                        return [4 /*yield*/, store.init({
                                dirname: 'syncpreview',
                                filesize: tItem.filesize,
                                filename: tItem.filename,
                                mimetype: tItem.mimetype,
                                cachekey: tItem.cachekey,
                                blobtype: tItem.blobtype,
                                path: ''
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 6]);
                        return [4 /*yield*/, this.api.fetchFilePlain(tItem)
                                .catch(function (err) {
                                _this.log.error('An error occured fetching plain file');
                                tItem.status = transfer_model_1.TransferStatus.STATUS_ERROR;
                            })];
                    case 3:
                        result = _a.sent();
                        // console.log(result);
                        if (result) {
                            this.storeData(store, {
                                bytearray: new Uint8Array(result, 0),
                                // We get these values all at once, so no need to keep track of position
                                offset: 0,
                                reqlength: 0,
                                chunkLength: 0,
                                tItem: tItem,
                            });
                        }
                        else {
                            this.log.error('An error occured');
                        }
                        // tItem = await this.processChunks(store, tItem);
                        // console.log('--------------- tItem = ' + tItem.status);
                        if (tItem.status >= transfer_model_1.TransferStatus.STATUS_ERROR) {
                            return [2 /*return*/, tItem];
                        }
                        return [4 /*yield*/, store.getRenderData(tItem)];
                    case 4:
                        renderData = _a.sent();
                        renderFile = this.prepareRenderData(renderData);
                        tItem.renderFile = renderFile;
                        timeTaken = (Date.now() - stTime) / 1000;
                        bps = tItem.filesize / timeTaken;
                        this.log.info("Download completed " + timeTaken + " seconds " + bps / 1000 + " kbps");
                        tItem.status = transfer_model_1.TransferStatus.STATUS_SUCCESS;
                        this._Downloader.completed(tItem);
                        return [3 /*break*/, 6];
                    case 5:
                        ex_2 = _a.sent();
                        if (!tItem.status || tItem.status == transfer_model_1.TransferStatus.STATUS_WORKING) {
                            tItem.status = transfer_model_1.TransferStatus.STATUS_ERROR;
                        }
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/, tItem];
                }
            });
        });
    };
    DownloadService.prototype.checkDownload = function (StorageMethod, tItem) {
        if (StorageMethod.maxsize > 0 && tItem.filesize > StorageMethod.maxsize) {
            this.log.warn('File is too large for this browser');
            this.log.warn(' - filesize: ' + tItem.filesize);
            this.log.warn(' - Max StorageFactory size: ' + StorageMethod.maxsize);
            this.log.warn(' - Save method: ' + this.browser.getFileSaveMethod());
            this.log.warn(' - StorageFactory method: ' + this.browser.getFileStorageMethod());
            tItem.status = transfer_model_1.TransferStatus.STATUS_ERR_SIZE;
        }
        else if ((!tItem.filedata.viewable && this.browser.getFileSaveMethod() == 'none')) {
            this.log.warn('File cannot be opened in this browser');
            this.log.warn(' - filesize: ' + tItem.filesize);
            this.log.warn(' - Save method: ' + this.browser.getFileSaveMethod());
            this.log.warn(' - StorageFactory method: ' + this.browser.getFileStorageMethod());
            tItem.status = transfer_model_1.TransferStatus.STATUS_ERR_OPEN;
        }
        else if (tItem.filedata.viewable &&
            this.browser.getFileSaveMethod() == 'none' &&
            tItem.type != this.transferItem.TYPE_PREVIEW) {
            this.log.warn('File save is incompatible with your browser');
            this.log.warn(' - filesize: ' + tItem.filesize);
            this.log.warn(' - Save method: ' + this.browser.getFileSaveMethod());
            this.log.warn(' - StorageFactory method: ' + this.browser.getFileStorageMethod());
            tItem.status = 3011;
        }
        else if (!this.browser.testXhr2()) {
            tItem.status = 3012;
        }
        return tItem;
    };
    DownloadService.prototype.storeData = function (StorageMethod, dlData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var packetAmt, gcmOffset, writeOffset;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        packetAmt = Math.ceil(dlData.offset / 131072), gcmOffset = packetAmt * 36;
                        writeOffset = dlData.offset - gcmOffset;
                        if (writeOffset < 0) {
                            writeOffset = 0;
                        }
                        return [4 /*yield*/, StorageMethod.writeChunk(dlData.tItem, dlData.bytearray, writeOffset)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, dlData];
                }
            });
        });
    };
    DownloadService.prototype.processChunks = function (StorageMethod, tItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var offset, stTime, remaining, reqChunk, result, dlData, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        offset = 0;
                        _a.label = 1;
                    case 1:
                        if (!(offset < tItem.enc_filesize)) return [3 /*break*/, 7];
                        // for (let len = tItem.enc_filesize, offset = 0; offset < len; offset + this.chunksize) {
                        this.log.d(offset + ' --- < --- ' + tItem.enc_filesize);
                        if (offset >= tItem.enc_filesize) {
                            tItem.status = transfer_model_1.TransferStatus.STATUS_SUCCESS;
                            return [2 /*return*/, tItem];
                        }
                        stTime = Date.now();
                        remaining = tItem.enc_filesize - offset;
                        reqChunk = (remaining > this.chunksize) ? this.chunksize : remaining;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 6]);
                        return [4 /*yield*/, this._Downloader.getChunk(tItem, offset, reqChunk)];
                    case 3:
                        result = _a.sent();
                        return [4 /*yield*/, this.storeData(StorageMethod, result)];
                    case 4:
                        dlData = _a.sent();
                        this.log.info("processChunk " + (Date.now() - stTime) + " ms");
                        this.log.info('filesize: ' + tItem.enc_filesize + ' Offset = ' + offset + ' reqChunk = ' + reqChunk + ' -- received = ' + dlData.chunkLength);
                        // received chunk length, make next request with that offset
                        offset += dlData.chunkLength;
                        if (dlData.chunkLength + offset == offset) {
                            this.log.error('Chunk length was 0, something has gone wrong');
                            tItem.status = transfer_model_1.TransferStatus.STATUS_ERROR;
                            return [2 /*return*/, tItem];
                        }
                        if (offset >= tItem.enc_filesize) {
                            tItem.status = transfer_model_1.TransferStatus.STATUS_SUCCESS;
                            return [2 /*return*/, tItem];
                        }
                        else if (tItem.blobtype.indexOf('btTHUMB') > -1) {
                            // when it's a thumbnail, we don't know the size of the file.
                            // MFS should always give the thumbnail in it's entirety so
                            // if we got a response, we should assume it's the entire
                            // thumbnail.
                            return [2 /*return*/, tItem];
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        err_2 = _a.sent();
                        this.log.error(err_2);
                        this.log.error('Error downloading chunk or storing it');
                        tItem.status = (err_2 && err_2.errcode) ? err_2.errcode : transfer_model_1.TransferStatus.STATUS_ERROR;
                        return [2 /*return*/, tItem];
                    case 6: return [3 /*break*/, 1];
                    case 7: return [2 /*return*/, tItem];
                }
            });
        });
    };
    DownloadService.prototype.prepareRenderData = function (renderData) {
        var decStart = Date.now();
        var renderFile = {
            dl_ready: true,
            type: renderData.type,
            url: renderData.url,
            savemethod: this.browser.getFileSaveMethod()
        };
        // if (tItem.type == this.TransferItem.TYPE_PREVIEW)
        // this.Logger.info(`prepareRender save method = ${renderFile.savemethod}`);
        if (renderFile.savemethod == 'msblob') {
            renderFile.msblob = renderData.blob;
        }
        this.log.d('  - ' + (Date.now() - decStart) + ' ms to prepareRenderData()');
        return renderFile;
    };
    DownloadService.ngInjectableDef = i0.defineInjectable({ factory: function DownloadService_Factory() { return new DownloadService(i0.inject(i1.BrowserSupportService), i0.inject(i2.SyncCryptService), i0.inject(i3.DownloadLegacyService), i0.inject(i4.DownloadWorkerService), i0.inject(i5.LoggerService), i0.inject(i6.StorageService), i0.inject(i7.TransferItemService), i0.inject(i8.ApiService)); }, token: DownloadService, providedIn: "root" });
    return DownloadService;
}());
exports.DownloadService = DownloadService;
