"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var GetloginsaltApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(GetloginsaltApiOutput, _super);
    function GetloginsaltApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return GetloginsaltApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.GetloginsaltApiOutput = GetloginsaltApiOutput;
var GetloginsaltApiIntput = /** @class */ (function () {
    function GetloginsaltApiIntput() {
    }
    return GetloginsaltApiIntput;
}());
exports.GetloginsaltApiIntput = GetloginsaltApiIntput;
