"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var ShareListApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(ShareListApiOutput, _super);
    function ShareListApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ShareListApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.ShareListApiOutput = ShareListApiOutput;
var ShareListApiInput = /** @class */ (function () {
    function ShareListApiInput() {
    }
    return ShareListApiInput;
}());
exports.ShareListApiInput = ShareListApiInput;
