"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var broadcast_service_1 = require("../shared/services/broadcast.service");
/**
 * @description
 * Toggles the rename form widget on a click event.
 *
 * Listens for `event:hide.tools` to hide the form input box
 */
var PathRenameDirective = /** @class */ (function () {
    function PathRenameDirective(broadcastService) {
        this.broadcastService = broadcastService;
        this.selected = [];
    }
    PathRenameDirective.prototype.click = function (event) {
        var _this = this;
        setTimeout(function () {
            _this.broadcastService.broadcast('event:file-list.rename', _this.selected[0].sync_id);
        }, 100);
    };
    return PathRenameDirective;
}());
exports.PathRenameDirective = PathRenameDirective;
