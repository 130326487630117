"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var ShareGetApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(ShareGetApiOutput, _super);
    function ShareGetApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ShareGetApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.ShareGetApiOutput = ShareGetApiOutput;
var ShareDataApiOutput = /** @class */ (function () {
    function ShareDataApiOutput() {
    }
    return ShareDataApiOutput;
}());
exports.ShareDataApiOutput = ShareDataApiOutput;
var ShareGetApiInput = /** @class */ (function () {
    function ShareGetApiInput() {
    }
    return ShareGetApiInput;
}());
exports.ShareGetApiInput = ShareGetApiInput;
var ShareMemberApiOutput = /** @class */ (function () {
    function ShareMemberApiOutput() {
    }
    return ShareMemberApiOutput;
}());
exports.ShareMemberApiOutput = ShareMemberApiOutput;
