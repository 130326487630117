"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var feedback_service_1 = require("../../notifications/feedback.service");
var broadcast_service_1 = require("../../shared/services/broadcast.service");
var share_service_1 = require("../share.service");
var ShareLeaveComponent = /** @class */ (function () {
    function ShareLeaveComponent(shareService, feedbackService, broadcastService) {
        this.shareService = shareService;
        this.feedbackService = feedbackService;
        this.broadcastService = broadcastService;
    }
    ShareLeaveComponent.prototype.ngOnInit = function () { };
    ShareLeaveComponent.prototype.leaveShare = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.shareService.leaveShare(this.shareData.shareId, this.shareData.sharememberId)];
                    case 1:
                        _a.sent();
                        this.broadcastService.broadcast('event:file-list.reload');
                        this.broadcastService.broadcast('event:share-list.reload');
                        this.feedbackService.setFeedback('success', "You have left The share " + this.shareData.name, 2000);
                        this.modalInstance.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    return ShareLeaveComponent;
}());
exports.ShareLeaveComponent = ShareLeaveComponent;
