"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var logger_service_1 = require("../../core/logger.service");
var user_service_1 = require("../../core/user.service");
var broadcast_service_1 = require("../../shared/services/broadcast.service");
var share_service_1 = require("../share.service");
var ShareOptionsComponent = /** @class */ (function () {
    function ShareOptionsComponent(userService, loggerService, shareService, broadcastService) {
        this.userService = userService;
        this.loggerService = loggerService;
        this.shareService = shareService;
        this.broadcastService = broadcastService;
        this.openStateChange = new core_1.EventEmitter();
        this.errors = [];
        this.isPro = false;
        this.spinners = {
            label: false,
        };
    }
    ShareOptionsComponent.prototype.ngOnInit = function () {
        this.isPro = this.userService.get('is_pro');
        if (!this.shareData) {
            this.loggerService.error('Unable to find shareData for sharemember-list');
            this.loggerService.error(JSON.stringify(this.shareData));
            this.errors.push({
                code: 9000,
                msg: 'No share member information provided',
            });
        }
    };
    ShareOptionsComponent.prototype.setLabel = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.spinners.label = true;
                        this.errors = [];
                        return [4 /*yield*/, this.shareService.setLabel(this.shareData.shareId, this.shareData.label)];
                    case 1:
                        _a.sent();
                        this.broadcastService.broadcast('event:file-list.reload');
                        this.broadcastService.broadcast('event:share-list.reload');
                        this.spinners.label = false;
                        this.modalInstance.close();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.spinners.label = false;
                        this.errors.push(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ShareOptionsComponent.prototype.openPopup = function (state) {
        this.openStateChange.next(state);
    };
    return ShareOptionsComponent;
}());
exports.ShareOptionsComponent = ShareOptionsComponent;
