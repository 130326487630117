"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../../../../../environments/environment");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var models_1 = require("../../../models");
var platform_browser_1 = require("@angular/platform-browser");
var fromLinkFileList = require("../../../../reducers/link-file-list.reducer");
var ReplyComponent = /** @class */ (function () {
    function ReplyComponent(sanitizer) {
        this.sanitizer = sanitizer;
        this.replyCreationEvent = new core_1.EventEmitter();
        this.replyEditionEvent = new core_1.EventEmitter();
        this.replyDeletionEvent = new core_1.EventEmitter();
        this.toggleReplyInputBoxEvent = new core_1.EventEmitter();
        this.avatarEndpoint = environment_1.environment.logohost + "avatar";
        this.contentLength = 200;
    }
    ReplyComponent.prototype.ngOnInit = function () {
        var contentValidators = [
            forms_1.Validators.maxLength(this.contentLength),
            forms_1.Validators.minLength(1),
        ];
        if (this.isInputBox) {
            this.replyCreationForm = new forms_1.FormControl('', contentValidators);
        }
        if (this.reply) {
            this.replyEditionForm = new forms_1.FormControl({ value: this.reply.content, disabled: true }, contentValidators);
        }
        else {
            this.replyEditionForm = new forms_1.FormControl({ value: '', disabled: true }, contentValidators);
        }
        if (this.user && this.user.exists) {
            this.userAvatar = this.avatarEndpoint + "/" + this.user.id;
        }
        else {
            this.userAvatar = '../../../images/anonymous.png';
        }
        if (this.reply && !this.reply.temp_session) {
            this.authorAvatar = this.avatarEndpoint + "/" + this.reply.author_id;
        }
        else {
            this.authorAvatar = '../../../images/anonymous.png';
        }
        if (this.link.image_cachekey) {
            this.buttonPrimaryColor = this.link.button_primary_color;
            this.buttonTextColor = this.link.button_text_color;
        }
    };
    ReplyComponent.prototype.ngOnChanges = function (changes) {
        if (changes.user) {
            this.user = changes.user.currentValue;
        }
        if (changes.tempSession) {
            this.tempSession = changes.tempSession.currentValue;
        }
        if (changes.detailEnabled) {
            this.detailEnabled = changes.detailEnabled.currentValue;
        }
        if (changes.link) {
            this.link = changes.link.currentValue;
        }
    };
    ReplyComponent.prototype.createReply = function () {
        var newReply;
        newReply = {
            parent_id: this.commentID,
            author_id: this.user.id,
            author_name: this.user.display_name,
            content: this.replyCreationForm.value,
            temp_session: this.tempSession,
        };
        this.replyCreationEvent.emit(newReply);
        this.replyCreationForm.reset();
    };
    ReplyComponent.prototype.editReply = function () {
        this.toggleEditMode();
        this.replyEditionForm.enable();
    };
    ReplyComponent.prototype.updateReply = function () {
        var data;
        data = {
            parent_id: this.reply.parent_id,
            reply_id: this.reply.reply_id,
            author_id: this.user.id,
            content: this.replyEditionForm.value,
            temp_session: this.tempSession,
        };
        this.replyEditionEvent.emit(data);
        this.editMode = false;
        this.replyEditionForm.disable();
    };
    ReplyComponent.prototype.deleteReply = function () {
        var data;
        data = {
            parent_id: this.reply.parent_id,
            reply_id: this.reply.reply_id,
            author_id: this.user.id,
            temp_session: this.tempSession,
        };
        this.replyDeletionEvent.emit(data);
    };
    ReplyComponent.prototype.toggleEditMode = function () {
        this.editMode = !this.editMode;
    };
    ReplyComponent.prototype.toggleReplyInputBox = function () {
        this.toggleReplyInputBoxEvent.emit();
    };
    ReplyComponent.prototype.toggleConfirm = function () {
        this.confirmedDelete = !this.confirmedDelete;
    };
    ReplyComponent.prototype.cancelEditMode = function () {
        this.editMode = false;
        this.replyEditionForm.setValue(this.reply.content);
    };
    ReplyComponent.prototype.cancelReplyCreation = function () {
        this.replyCreationForm.reset();
        this.replyCreationForm.setValue('');
    };
    ReplyComponent.prototype.cancelConfirm = function () {
        this.confirmedDelete = false;
    };
    ReplyComponent.prototype.validContent = function (value) {
        return value != undefined && value.trim().length !== 0;
    };
    Object.defineProperty(ReplyComponent.prototype, "customColorsCss", {
        /**
         * this function updates css variables based on changes of this component's attributes
         */
        get: function () {
            return this.sanitizer
                .bypassSecurityTrustStyle("--buttonPrimaryColor: " + this.buttonPrimaryColor + ";\n                                                        --buttonTextColor: " + this.buttonTextColor + ";\n                                                    ");
        },
        enumerable: true,
        configurable: true
    });
    return ReplyComponent;
}());
exports.ReplyComponent = ReplyComponent;
