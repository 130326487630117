"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./shared/directives/hide-tools.directive");
var i2 = require("./shared/services/broadcast.service");
var i3 = require("./util/global-binds.directive");
var i4 = require("@ng-bootstrap/ng-bootstrap");
var i5 = require("@angular/router");
var i6 = require("./app.component");
var i7 = require("@ngrx/store");
var i8 = require("@angular/platform-browser");
var i9 = require("./shared/services/version.service");
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
exports.RenderType_AppComponent = RenderType_AppComponent;
function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["syncGlobalBinds", ""], ["syncHideTools", ""]], null, [[null, "click"], ["window", "resize"], ["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).click($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2).onKeydownHandler($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.HideToolsDirective, [i2.BroadcastService], null, null), i0.ɵdid(2, 16384, null, 0, i3.GlobalBindsDirective, [i4.NgbModal, i2.BroadcastService], null, null), (_l()(), i0.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(4, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
exports.View_AppComponent_0 = View_AppComponent_0;
function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 4308992, null, 0, i6.AppComponent, [i7.Store, i8.DomSanitizer, i9.VersionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AppComponent_Host_0 = View_AppComponent_Host_0;
var AppComponentNgFactory = i0.ɵccf("sync-app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
exports.AppComponentNgFactory = AppComponentNgFactory;
