"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../error-display/error-display.component.ngfactory");
var i2 = require("../error-display/error-display.component");
var i3 = require("../../core/logger.service");
var i4 = require("../../core/user.service");
var i5 = require("@angular/platform-browser");
var i6 = require("@angular/common");
var i7 = require("./error-display-hash.component");
var styles_ErrorDisplayHashComponent = [];
var RenderType_ErrorDisplayHashComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ErrorDisplayHashComponent, data: {} });
exports.RenderType_ErrorDisplayHashComponent = RenderType_ErrorDisplayHashComponent;
function View_ErrorDisplayHashComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "sync-error-display", [], null, null, null, i1.View_ErrorDisplayComponent_0, i1.RenderType_ErrorDisplayComponent)), i0.ɵdid(2, 114688, null, 0, i2.ErrorDisplayComponent, [i3.LoggerService, i4.UserService, i5.DomSanitizer], { errorItem: [0, "errorItem"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ErrorDisplayHashComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "alert alert-warning"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorDisplayHashComponent_2)), i0.ɵdid(2, 278528, null, 0, i6.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ErrorDisplayHashComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorDisplayHashComponent_1)), i0.ɵdid(1, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.errors && _co.errors.length); _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_ErrorDisplayHashComponent_0 = View_ErrorDisplayHashComponent_0;
function View_ErrorDisplayHashComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-error-display-hash", [], null, null, null, View_ErrorDisplayHashComponent_0, RenderType_ErrorDisplayHashComponent)), i0.ɵdid(1, 114688, null, 0, i7.ErrorDisplayHashComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ErrorDisplayHashComponent_Host_0 = View_ErrorDisplayHashComponent_Host_0;
var ErrorDisplayHashComponentNgFactory = i0.ɵccf("sync-error-display-hash", i7.ErrorDisplayHashComponent, View_ErrorDisplayHashComponent_Host_0, { errors: "errors" }, {}, []);
exports.ErrorDisplayHashComponentNgFactory = ErrorDisplayHashComponentNgFactory;
