"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var logger_service_1 = require("../logger.service");
var models_1 = require("../../shared/models");
var crypt_buffer_service_1 = require("./crypt-buffer.service");
var i0 = require("@angular/core");
var i1 = require("../logger.service");
/* tslint:disable:no-bitwise */
var CryptNativeService = /** @class */ (function (_super) {
    tslib_1.__extends(CryptNativeService, _super);
    function CryptNativeService(logService) {
        var _this = _super.call(this, logService) || this;
        _this.logService = logService;
        _this.mCrypto = window.crypto || window.msCrypto;
        _this.mCryptoSubtle = (_this.mCrypto && _this.mCrypto.subtle) ? _this.mCrypto.subtle : null;
        _this.mCache = {};
        return _this;
    }
    /**
     * Encrypts data using symmetric encryption via asmCrypto
     * @param  {Uint8Array} key    The AES key
     * @param  {Uint8Array} plain  [description]
     * @param  {Uint8Array} iv     [description]
     * @param  {Uint8Array|null} header [description]
     * @return {Promise}        [description]
     */
    CryptNativeService.prototype.symmetricEncrypt = function (key, plain, iv, header) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var aesKey, encBuffer, data, len, result, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        // let startMs = window.performance.now();
                        // console.warn('Using crypt-native encrypt');
                        if (!header) {
                            header = new Uint8Array(0);
                        }
                        return [4 /*yield*/, this.getSymmetricKey(key)];
                    case 1:
                        aesKey = _a.sent();
                        return [4 /*yield*/, this.mCryptoSubtle.encrypt({
                                name: 'AES-GCM',
                                iv: iv,
                                additionalData: header,
                                tagLength: 96
                            }, aesKey, plain)];
                    case 2:
                        encBuffer = _a.sent();
                        data = new Uint8Array(encBuffer);
                        len = header.length + iv.length + data.length;
                        result = new Uint8Array(len);
                        result.set(header, 0);
                        result.set(iv, header.length);
                        result.set(data, header.length + iv.length);
                        //            console.warn(' - encrypt done = ' + (window.performance.now()  - startMs));
                        return [2 /*return*/, result];
                    case 3:
                        ex_1 = _a.sent();
                        this.logService.error("CryptNative.symmetricEncrypt() failed " + ex_1.toString());
                        throw new models_1.ErrCode(2503);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Encrypts data using symmetric encryption via asmCrypto
     * @param  {Uint8Array} key    The AES key
     * @param  {Uint8Array} crypted  [description]
     * @param  {Uint8Array} iv     [description]
     * @param  {Uint8Array|null} header [description]
     * @return {Promise}        [description]
     */
    CryptNativeService.prototype.symmetricDecrypt = function (key, crypted, iv, header) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var aesKey, decBuffer, ex_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!header) {
                            header = new Uint8Array(0);
                        }
                        return [4 /*yield*/, this.getSymmetricKey(key)];
                    case 1:
                        aesKey = _a.sent();
                        return [4 /*yield*/, this.mCryptoSubtle.decrypt({
                                name: 'AES-GCM',
                                iv: iv,
                                additionalData: header,
                                tagLength: 96
                            }, aesKey, crypted)];
                    case 2:
                        decBuffer = _a.sent();
                        return [2 /*return*/, new Uint8Array(decBuffer)];
                    case 3:
                        ex_2 = _a.sent();
                        this.logService.error("CryptNative.symmetricDecrypt() failed " + ex_2.toString());
                        throw new models_1.ErrCode(2504);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Stretches data quickly
     * @todo Combine this with keystretch and use the iteration size
     *       to determine which method to use.
     * @param  {String|Uint8Array} password   [description]
     * @param  {Uint8Array} salt       [description]
     * @param  {Integer} iterations [description]
     * @param  {Integer} length     [description]
     * @return {Promise}            [description]
     */
    CryptNativeService.prototype.keyStretch = function (password, salt, iterations, length) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var saltBuff, passBytes, key, buffer, ex_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        saltBuff = (typeof salt === 'string')
                            ? this.hexToBytes(salt)
                            : salt;
                        passBytes = this.stringToBytes(password);
                        return [4 /*yield*/, this.mCryptoSubtle.importKey('raw', passBytes, 'PBKDF2', false, ['deriveBits'])];
                    case 1:
                        key = _a.sent();
                        return [4 /*yield*/, this.mCryptoSubtle.deriveBits({
                                name: 'PBKDF2',
                                salt: saltBuff,
                                iterations: iterations,
                                hash: { name: 'SHA-256' }
                            }, key, length)];
                    case 2:
                        buffer = _a.sent();
                        return [2 /*return*/, new Uint8Array(buffer)];
                    case 3:
                        ex_3 = _a.sent();
                        this.logService.e('Failed PBKDF2', ex_3);
                        throw new models_1.ErrCode(2505);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Converts the key into a CryptoKey usable for webcrypto functions.
     *
     * It will cache the result of importKey() since each call takes 5-10 ms.
     *
     * @param key the encryption key
     * @returns CryptoKey
     */
    CryptNativeService.prototype.getSymmetricKey = function (key) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var aesKeyId, _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        aesKeyId = this.bytesToB64(key);
                        if (!!this.mCache[aesKeyId]) return [3 /*break*/, 2];
                        _a = this.mCache;
                        _b = aesKeyId;
                        return [4 /*yield*/, this.mCryptoSubtle.importKey('raw', key, 'AES-GCM', false, // extractable
                            ['encrypt', 'decrypt'])];
                    case 1:
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [2 /*return*/, this.mCache[aesKeyId]];
                }
            });
        });
    };
    CryptNativeService.ngInjectableDef = i0.defineInjectable({ factory: function CryptNativeService_Factory() { return new CryptNativeService(i0.inject(i1.LoggerService)); }, token: CryptNativeService, providedIn: "root" });
    return CryptNativeService;
}(crypt_buffer_service_1.CryptBufferService));
exports.CryptNativeService = CryptNativeService;
