"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../dir-picker/dir-picker.component.ngfactory");
var i2 = require("../dir-picker/dir-picker.component");
var i3 = require("../dir-picker.service");
var i4 = require("./dialog-dir-picker.component");
var i5 = require("@ng-bootstrap/ng-bootstrap");
var styles_DialogDirPickerComponent = [];
var RenderType_DialogDirPickerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogDirPickerComponent, data: {} });
exports.RenderType_DialogDirPickerComponent = RenderType_DialogDirPickerComponent;
function View_DialogDirPickerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "ion-android-close fa-lg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-history"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Choose a directory "])), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Destination: "])), (_l()(), i0.ɵeld(9, 0, null, null, 0, "strong", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "sync-dir-picker", [], null, null, null, i1.View_DirPickerComponent_0, i1.RenderType_DirPickerComponent)), i0.ɵdid(11, 114688, null, 0, i2.DirPickerComponent, [i3.DirPickerService], { skipShareFolders: [0, "skipShareFolders"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Done "]))], function (_ck, _v) { var currVal_1 = 1; _ck(_v, 11, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getDestination(); _ck(_v, 9, 0, currVal_0); }); }
exports.View_DialogDirPickerComponent_0 = View_DialogDirPickerComponent_0;
function View_DialogDirPickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-dialog-dir-picker", [], null, null, null, View_DialogDirPickerComponent_0, RenderType_DialogDirPickerComponent)), i0.ɵdid(1, 114688, null, 0, i4.DialogDirPickerComponent, [i3.DirPickerService, i5.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DialogDirPickerComponent_Host_0 = View_DialogDirPickerComponent_Host_0;
var DialogDirPickerComponentNgFactory = i0.ɵccf("sync-dialog-dir-picker", i4.DialogDirPickerComponent, View_DialogDirPickerComponent_Host_0, {}, {}, []);
exports.DialogDirPickerComponentNgFactory = DialogDirPickerComponentNgFactory;
