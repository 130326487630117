"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var KeysNeedShareKeysApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(KeysNeedShareKeysApiOutput, _super);
    function KeysNeedShareKeysApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return KeysNeedShareKeysApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.KeysNeedShareKeysApiOutput = KeysNeedShareKeysApiOutput;
var KeysNeedShareKeysApiInput = /** @class */ (function () {
    function KeysNeedShareKeysApiInput() {
    }
    return KeysNeedShareKeysApiInput;
}());
exports.KeysNeedShareKeysApiInput = KeysNeedShareKeysApiInput;
