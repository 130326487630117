"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var models_1 = require("../../shared/models");
var api_service_1 = require("../../core/api.service");
var sync_cookie_service_1 = require("../../core/sync-cookie.service");
var rxjs_1 = require("rxjs");
var environment_1 = require("../../../environments/environment");
var zuora_account_service_1 = require("../services/zuora-account.service");
var logger_service_1 = require("../../core/logger.service");
var operators_1 = require("rxjs/operators");
var platform_browser_1 = require("@angular/platform-browser");
var user_service_1 = require("../../core/user.service");
var RenewComponent = /** @class */ (function () {
    function RenewComponent(api, injector, log, sanitize, store, syncCookie, zuora, userService) {
        this.api = api;
        this.injector = injector;
        this.log = log;
        this.sanitize = sanitize;
        this.store = store;
        this.syncCookie = syncCookie;
        this.zuora = zuora;
        this.userService = userService;
        this.permissionActions = models_1.PermissionActions;
        this.unsub = new rxjs_1.Subject();
    }
    RenewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store
            .pipe(store_1.select(fromRoot.getAuthState), operators_1.takeUntil(this.unsub))
            .subscribe(function (data) {
            if (!data.authenticated) {
                _this.user = new models_1.User();
                // this.initialized = false;
                return;
            }
            if (data.user) {
                _this.user = data.user;
                _this.doRenew(data.user);
                _this.unsub.next();
                _this.unsub.complete();
                // this.initialized = true;
            }
        });
    };
    RenewComponent.prototype.ngOnDestroy = function () {
    };
    RenewComponent.prototype.doRenew = function (user) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var zprofile, result, unsafeUrl;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.userService.checkPermission(this.permissionActions.MANAGE_BILLING)) return [3 /*break*/, 7];
                        this.spinner = true;
                        return [4 /*yield*/, this.zuora.getProfile()];
                    case 1:
                        zprofile = _a.sent();
                        if (!zprofile.subscription) return [3 /*break*/, 5];
                        if (!(zprofile.subscription.status === 'Cancelled')) return [3 /*break*/, 2];
                        this.cancelInFuture = zprofile.subscription.status === 'Cancelled';
                        this.spinner = false;
                        return [2 /*return*/];
                    case 2: return [4 /*yield*/, this.api.execute('ordersessionnew', {})];
                    case 3:
                        result = _a.sent();
                        if (result && result.sessionId) {
                            this.syncCookie.setOrderSessionId(result.sessionId);
                            unsafeUrl = (zprofile.balance > 0 && zprofile.unpaidInvoiceCount > 0)
                                ? environment_1.environment.ordershost + "/invoice"
                                : environment_1.environment.ordershost + "/renew";
                            this.url = this.sanitize.bypassSecurityTrustUrl(unsafeUrl);
                            window.location.replace(unsafeUrl);
                        }
                        else {
                            this.errcode = new models_1.ErrCode(8550);
                        }
                        this.spinner = false;
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this.errcode = new models_1.ErrCode(8551);
                        this.spinner = false;
                        _a.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        this.unableToRenew = true;
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    return RenewComponent;
}());
exports.RenewComponent = RenewComponent;
