import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NgModuleFactoryLoader, SystemJsNgModuleLoader } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { effects } from './effects';
import { AccountModule } from './account/account.module';
import { LinksModule } from './links/links.module';
import { SharedModule } from './shared/shared.module';
import { TransferModule } from './transfer/transfer.module';
import { environment, extModules } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { UtilModule } from './util/util-module';
import { SharesModule } from './shares/shares.module';
import { OpenDialogDirective } from './util/open-dialog.directive';
import { FileActionsModule } from './file-actions/file-actions-module';
import { BlendService } from './shared/services/blend.service';
import { NotificationsModule } from './notifications/notifications-module';
import { AppRoutingModule } from './app-routing.module';
import { DatePipe } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { CoreModule } from './core/core.module';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NewRoutesMapping } from './shared/models/new-route-mapping';
import { UserService } from './core/user.service';
import { FeatureService } from './shared/services/feature.service';

export function windowFactory() {
    return window;
}
@NgModule({
    declarations: [
        AppComponent,
        OpenDialogDirective,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        // Instrumentation must be imported after importing StoreModule
        extModules,
        EffectsModule.forRoot(effects),
        CoreModule,
        SharedModule,
        AppRoutingModule,
        LayoutModule,
        AccountModule,
        LinksModule,
        TransferModule,
        UtilModule,
        SharesModule,
        FileActionsModule,
        NotificationsModule,
    ],
    providers: [
        CookieService,
        DatePipe,
        // Provide the SystemJsNgModuleLoader when using Angular lazy loading
        { provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader },
        { provide: 'window', useFactory: windowFactory }
    ],
    bootstrap: [AppComponent] // commented out because we are using upgrade
})

export class AppModule {
    constructor(private router: Router, private blendService: BlendService, private userService: UserService, private featureService: FeatureService) {
        this.router.events.subscribe(async (routeEvent) => {
            if (routeEvent instanceof NavigationStart) {
                let newRoute = NewRoutesMapping[routeEvent.url];
                if (routeEvent.url.includes('/files')) {
                    if (await this.featureService.isAllowed('filesDirectoryPage')) {
                        newRoute = routeEvent.url;
                    } else {
                        newRoute = '';
                    }
                }
                if (this.userService.get('is_newui_enabled') && newRoute) {
                    window.location.href = environment.syncCpHost + newRoute;
                }
            }
            if (routeEvent instanceof NavigationEnd) {
                window.scrollTo(0, 0);
                    this.blendService.trackPageview();
            }
        });
    }
}
