"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var base64_service_1 = require("../../core/base64.service");
var B64Pipe = /** @class */ (function () {
    function B64Pipe(base64) {
        this.base64 = base64;
    }
    B64Pipe.prototype.transform = function (value, encode) {
        if (encode === true) {
            return this.base64.encode(value);
        }
        else {
            return this.base64.decode(value);
        }
    };
    return B64Pipe;
}());
exports.B64Pipe = B64Pipe;
