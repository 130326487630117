import { Component, OnInit, Input } from '@angular/core';
import { LinkListItem, User, LinkApiOutput } from '../../shared/models';
import { UserService } from '../../core/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LinkListService } from '../services/link-list.service';

@Component({
    selector: 'sync-dialog-link',
    templateUrl: './dialog-link.component.html',
})
export class DialogLinkComponent implements OnInit {
    @Input() item: LinkListItem | sync.IFile;

    @Input() pid: number;

    @Input() openState: number;

    public user: User;
    public link: LinkApiOutput;
    public initialized = false;

    constructor(
        private activeModal: NgbActiveModal,
        private linkList: LinkListService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.user = this.userService.getUser();
        if (!this.item.is_publink) {
            this.openState = 0;
            this.initialized = true;
        } else {
            if (this.openState == undefined) {
                this.openState = 1;
            }
            this.linkList
                .getLink(this.item.sync_id, this.item.name)
                .then((data) => {
                    this.link = data;
                    this.initialized = true;
                });
        }
    }

    public async onStateChange(state: number) {
        if ((this.openState == 0 || this.openState == 2) && state == 1) {
            // The user
            const data = await this.linkList.getLink(
                this.item.sync_id,
                this.item.name
            );
            this.initialized = true;
            this.link = data;
        }
        this.openState = state;
    }

    public onClose(refresh: boolean) {
        this.activeModal.close(refresh);
    }
}
