"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var operators_1 = require("rxjs/operators");
var browser_support_service_1 = require("../../core/browser-support.service");
var logger_service_1 = require("../../core/logger.service");
var file_list_service_1 = require("../../file-list/services/file-list.service");
var transfer_service_1 = require("../../transfer/transfer.service");
var upload_folder_service_1 = require("../../transfer/upload-folder.service");
var blend_service_1 = require("../services/blend.service");
var file_item_service_1 = require("../../file/file-item.service");
/**
 * @description
 * Binds the drag and drop events for a given element and when a file is
 * dragged on to this element, it will trigger a file upload.
 *
 * The drop event filters out directories.
 */
var UploadDropBoxDirective = /** @class */ (function () {
    function UploadDropBoxDirective(browserSupportService, loggerService, fileListService, transferService, renderer, element, uploadFolderService, blendService, fileItemService) {
        this.browserSupportService = browserSupportService;
        this.loggerService = loggerService;
        this.fileListService = fileListService;
        this.transferService = transferService;
        this.renderer = renderer;
        this.element = element;
        this.uploadFolderService = uploadFolderService;
        this.blendService = blendService;
        this.fileItemService = fileItemService;
    }
    UploadDropBoxDirective.prototype.ngOnInit = function () {
        if (!this.browserSupportService.testFileUpload()) {
            this.loggerService.info('Browser is unable to upload files');
            return;
        }
    };
    UploadDropBoxDirective.prototype.onDragStart = function (evt) {
        evt.preventDefault();
        evt.dataTransfer.effectAllowed = 'all';
    };
    UploadDropBoxDirective.prototype.onDragEnter = function (evt) {
        evt.preventDefault();
        evt.dataTransfer.effectAllowed = 'all';
        this.renderer.addClass(this.element.nativeElement, 'fileupload-drop-over');
    };
    UploadDropBoxDirective.prototype.onDragOver = function (evt) {
        evt.dataTransfer.effectAllowed = 'all';
        this.renderer.addClass(this.element.nativeElement, 'fileupload-drop-over');
        return false;
    };
    UploadDropBoxDirective.prototype.onDragLeave = function (evt) {
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'none';
        this.renderer.removeClass(this.element.nativeElement, 'fileupload-drop-over');
    };
    UploadDropBoxDirective.prototype.onDrop = function (evt) {
        var _this = this;
        evt.preventDefault();
        this.renderer.removeClass(this.element.nativeElement, 'fileupload-drop-over');
        var list = evt.dataTransfer.items;
        if (list && list.length > 0) {
            if (this.browserSupportService.testDirUpload()) {
                var hasDirs = false;
                var items_1 = [];
                for (var i = 0, len = list.length; i < len; i++) {
                    var item = list[i].webkitGetAsEntry();
                    if (item) {
                        items_1.push(item);
                        if (!hasDirs && item.isDirectory) {
                            hasDirs = true;
                        }
                    }
                }
                if (hasDirs) {
                    this.loggerService.info('Found directories for upload');
                    this.fileListService
                        .getCwdSubscription()
                        .pipe(operators_1.first())
                        .subscribe(function (data) {
                        if (data) {
                            _this.uploadFolderService.run(data, items_1);
                        }
                        else {
                            _this.loggerService.warn('No cwd found in upload');
                            _this.loggerService.warn(data);
                        }
                    });
                }
                else {
                    this.transferService.queueUpload(evt.dataTransfer.files, true);
                }
            }
            else {
                this.transferService.queueUpload(evt.dataTransfer.files, true);
            }
        }
        else if (evt.dataTransfer.files.length) {
            this.transferService.queueUpload(evt.dataTransfer.files, true);
        }
        else {
            this.loggerService.error('No files were dropped for upload');
        }
    };
    UploadDropBoxDirective.prototype.onDragEnd = function (evt) {
        evt.preventDefault();
        evt.dataTransfer.effectAllowed = 'none';
        evt.dataTransfer.dropEffect = 'none';
        this.renderer.removeClass(this.element.nativeElement, 'fileupload-drop-over');
    };
    return UploadDropBoxDirective;
}());
exports.UploadDropBoxDirective = UploadDropBoxDirective;
