"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var DialogMultiAdminuserDeleteComponent = /** @class */ (function () {
    function DialogMultiAdminuserDeleteComponent(activeModal) {
        this.activeModal = activeModal;
    }
    DialogMultiAdminuserDeleteComponent.prototype.ngOnInit = function () { };
    DialogMultiAdminuserDeleteComponent.prototype.onConfirm = function () {
        this.activeModal.close(true);
    };
    DialogMultiAdminuserDeleteComponent.prototype.onCancel = function () {
        this.activeModal.close(false);
    };
    return DialogMultiAdminuserDeleteComponent;
}());
exports.DialogMultiAdminuserDeleteComponent = DialogMultiAdminuserDeleteComponent;
