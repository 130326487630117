"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var effects_1 = require("@ngrx/effects");
var reducers_1 = require("../reducers");
var LinkFileListActions = require("../actions/link-file-list.actions");
var link_file_list_service_1 = require("../link-consume/services/link-file-list.service");
var models_1 = require("../shared/models");
var LinkFileListEffects = /** @class */ (function () {
    // get linkFileList(): LinkFileListService {
    //     return this.injector.get('LinkFileListService');
    // }
    // get auth(): AuthService {
    //     return this.injector.get('AuthService');
    // }
    // get user(): UserService {
    //     return this.injector.get('UserService');
    // }
    function LinkFileListEffects(injector, 
    // private auth: AuthService,
    actions$, linkFileList, store$
    // private user: UserService
    ) {
        var _this = this;
        this.injector = injector;
        this.actions$ = actions$;
        this.linkFileList = linkFileList;
        this.store$ = store$;
        this.load$ = this.actions$.pipe(effects_1.ofType(LinkFileListActions.LINK_FILE_LIST_LOAD), operators_1.mergeMap(function (action) {
            return rxjs_1.from(_this.linkFileList.getData(action.payload.key, action.payload.publink_id, action.payload.sync_id, action.payload.passwordlock)).pipe(operators_1.map(function (data) { return new LinkFileListActions.SuccessAction(data); }), operators_1.catchError(function (err) { return rxjs_1.of(new LinkFileListActions.ErrorAction(err)); }));
        }));
        this.loadapp$ = this.actions$.pipe(effects_1.ofType(LinkFileListActions.LINK_FILE_LIST_LOAD_APP), operators_1.mergeMap(function (action) {
            return rxjs_1.from(_this.linkFileList.getDataExternal(action.payload.publink_id, action.payload.sync_id, action.payload.passwordlock)).pipe(operators_1.map(function (data) { return new LinkFileListActions.SuccessAction(data); }), operators_1.catchError(function (err) { return rxjs_1.of(new LinkFileListActions.ErrorAction(err)); }));
        }));
        this.success$ = this.actions$.pipe(effects_1.ofType(LinkFileListActions.LINK_FILE_LIST_SUCCESS), operators_1.withLatestFrom(this.store$.select(reducers_1.getLinkFileListState)), operators_1.map(function (_a) {
            var action = _a[0], storeState = _a[1];
            return new LinkFileListActions.SortAction({
                active: storeState.predicate,
                direction: storeState.direction,
            });
        }));
        this.refresh$ = this.actions$.pipe(effects_1.ofType(LinkFileListActions.LINK_FILE_LIST_REFRESH), operators_1.withLatestFrom(this.store$.select(reducers_1.getLinkFileListState)), operators_1.map(function (_a) {
            var action = _a[0], storeState = _a[1];
            var input = new models_1.LinkPathListApiInput();
            input.key = storeState.linkkey;
            input.sync_id = parseInt(storeState.sync_id, 10);
            input.publink_id = storeState.publink_id;
            input.passwordlock = storeState.passwordlock;
            return new LinkFileListActions.LoadAction(input);
        }));
        this.cd$ = this.actions$.pipe(effects_1.ofType(LinkFileListActions.LINK_FILE_LIST_CD), operators_1.withLatestFrom(this.store$.select(reducers_1.getLinkFileListState)), operators_1.map(function (_a) {
            var action = _a[0], storeState = _a[1];
            var input = new models_1.LinkPathListApiInput();
            input.key = storeState.linkkey;
            input.sync_id = action.payload;
            input.publink_id = storeState.publink_id;
            input.passwordlock = storeState.passwordlock;
            return new LinkFileListActions.LoadAction(input);
        }));
        this.loadWhiteLabel$ = this.actions$.pipe(effects_1.ofType(LinkFileListActions.LINK_FILE_LIST_LOAD_WHITELABEL), operators_1.mergeMap(function (action) {
            return rxjs_1.from(_this.linkFileList.getWhiteLabel(action.payload))
                .pipe(operators_1.map(function (data) { return new LinkFileListActions.WhiteLabelSuccessAction(data); }), operators_1.catchError(function (err) { return rxjs_1.of(new LinkFileListActions.ErrorAction(err)); }));
        }));
    }
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], LinkFileListEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], LinkFileListEffects.prototype, "loadapp$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], LinkFileListEffects.prototype, "success$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], LinkFileListEffects.prototype, "refresh$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], LinkFileListEffects.prototype, "cd$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], LinkFileListEffects.prototype, "loadWhiteLabel$", void 0);
    return LinkFileListEffects;
}());
exports.LinkFileListEffects = LinkFileListEffects;
