"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var effects_1 = require("@ngrx/effects");
var models_1 = require("../shared/models");
var AuthActions = require("../actions/auth.actions");
var auth_service_1 = require("../auth/services/auth.service");
// import { Router } from '@angular/router';
var user_service_1 = require("../core/user.service");
var AuthEffects = /** @class */ (function () {
    function AuthEffects(authService, userService, 
    // private auth: AuthService,
    actions$) {
        var _this = this;
        this.authService = authService;
        this.userService = userService;
        this.actions$ = actions$;
        // Listen for the 'LOGIN' action
        this.login$ = this.actions$.pipe(effects_1.ofType(AuthActions.AUTH_LOGIN), operators_1.mergeMap(function (action) {
            // console.log(action.payload);
            return rxjs_1.from(_this.authService.authenticate(action.payload.username, action.payload.password, action.payload.twofacode)).pipe(operators_1.map(function (data) { return new AuthActions.LoginSuccessAction(data); }), operators_1.catchError(function (err) { return rxjs_1.of(new AuthActions.LoginFailureAction(err)); }));
        }));
        this.loginSSO$ = this.actions$.pipe(effects_1.ofType(AuthActions.AUTH_LOGIN_SSO), operators_1.mergeMap(function (action) {
            // console.log(action.payload);
            return rxjs_1.from(_this.authService.loginSSO(action.payload)).pipe(operators_1.map(function (user) { return new AuthActions.LoginSuccessAction(user); }), operators_1.catchError(function (err) { return rxjs_1.of(new AuthActions.LoginFailureAction(err)); }));
        }));
        this.refresh$ = this.actions$.pipe(effects_1.ofType(AuthActions.AUTH_REFRESH), operators_1.mergeMap(function (action) {
            return rxjs_1.from(_this.userService.reload()).pipe(operators_1.map(function (data) {
                var user = new models_1.User().deserialize(data);
                return new AuthActions.UpdateAction(user);
            }), operators_1.catchError(function (err) {
                return rxjs_1.of(new AuthActions.LoginFailureAction(err));
            }));
        }));
    }
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], AuthEffects.prototype, "login$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], AuthEffects.prototype, "loginSSO$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], AuthEffects.prototype, "refresh$", void 0);
    return AuthEffects;
}());
exports.AuthEffects = AuthEffects;
