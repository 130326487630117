"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var DialogConfirmOwnershipChangeComponent = /** @class */ (function () {
    function DialogConfirmOwnershipChangeComponent(activeModal) {
        this.activeModal = activeModal;
    }
    DialogConfirmOwnershipChangeComponent.prototype.ngOnInit = function () { };
    DialogConfirmOwnershipChangeComponent.prototype.onConfirm = function () {
        this.activeModal.close(true);
    };
    DialogConfirmOwnershipChangeComponent.prototype.onCancel = function () {
        this.activeModal.close(false);
    };
    return DialogConfirmOwnershipChangeComponent;
}());
exports.DialogConfirmOwnershipChangeComponent = DialogConfirmOwnershipChangeComponent;
