"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var UserListActions = require("../../actions/user-list.actions");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var UserSelectionService = /** @class */ (function () {
    function UserSelectionService(store) {
        this.store = store;
        this.selected = [];
        this.selectTypes = {
            active: 'active',
            invited: 'invited',
            all: 'all'
        };
        this.init();
    }
    UserSelectionService.prototype.init = function () {
        var _this = this;
        this.watchSelected()
            .subscribe(function (result) {
            _this.selected = result;
        });
    };
    UserSelectionService.prototype.setList = function (item) {
        this.selected = [];
        return this.store.dispatch(new UserListActions.UserListSuccessAction(item));
    };
    UserSelectionService.prototype.watchSelected = function () {
        return this.store.select(fromRoot.getUserListSelected);
    };
    UserSelectionService.prototype.getSelected = function () {
        return this.selected;
    };
    UserSelectionService.prototype.toggle = function (item) {
        this.store.dispatch(new UserListActions.UserListSelectToggleAction(item));
    };
    UserSelectionService.prototype.selectAll = function (selectType, active) {
        if (active === void 0) { active = false; }
        if (selectType === this.selectTypes.active) {
            this.store.dispatch(new UserListActions.UserListSelectAllActiveAction());
        }
        else if (selectType === this.selectTypes.invited) {
            this.store.dispatch(new UserListActions.UserListSelectAllInvitedAction());
        }
        else {
            this.store.dispatch(new UserListActions.UserListSelectAllAction({
                active: active
            }));
        }
    };
    UserSelectionService.ngInjectableDef = i0.defineInjectable({ factory: function UserSelectionService_Factory() { return new UserSelectionService(i0.inject(i1.Store)); }, token: UserSelectionService, providedIn: "root" });
    return UserSelectionService;
}());
exports.UserSelectionService = UserSelectionService;
