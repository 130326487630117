"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var preview_layout_component_1 = require("../layout/preview-layout/preview-layout.component");
var auth_guard_1 = require("../shared/guards/auth.guard");
var history_component_1 = require("./history/history.component");
var preview_component_1 = require("./preview/preview.component");
var footer_layout_component_1 = require("../layout/footer-layout/footer-layout.component");
var routes = [
    {
        path: ':id/view/:type', component: preview_layout_component_1.PreviewLayoutComponent,
        children: [
            { path: '', component: preview_component_1.PreviewComponent }
        ]
    },
    {
        path: ':id/history',
        component: footer_layout_component_1.FooterLayoutComponent,
        canActivate: [auth_guard_1.AuthGuard],
        children: [
            { path: '', component: history_component_1.HistoryComponent }
        ]
    }
];
var FileRoutingModule = /** @class */ (function () {
    function FileRoutingModule() {
    }
    return FileRoutingModule;
}());
exports.FileRoutingModule = FileRoutingModule;
