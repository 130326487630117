"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("../core/api.service");
var dirty_out_service_1 = require("../core/crypt/dirty-out.service");
var sync_crypt_service_1 = require("../core/crypt/sync-crypt.service");
var logger_service_1 = require("../core/logger.service");
var notifications_service_1 = require("../core/notifications.service");
var broadcast_service_1 = require("../shared/services/broadcast.service");
var batch_action_service_1 = require("./batch-action.service");
var batch_move_service_1 = require("./batch-move.service");
var i0 = require("@angular/core");
var i1 = require("../shared/services/broadcast.service");
var i2 = require("../core/logger.service");
var i3 = require("../core/crypt/sync-crypt.service");
var i4 = require("../core/notifications.service");
var i5 = require("../core/api.service");
var i6 = require("../core/crypt/dirty-out.service");
var i7 = require("./batch-move.service");
var BatchRenameService = /** @class */ (function (_super) {
    tslib_1.__extends(BatchRenameService, _super);
    function BatchRenameService(broadcastService, loggerService, syncCryptService, notificationsService, apiService, dirtyOutService, batchMoveService) {
        var _this = _super.call(this, broadcastService, loggerService, syncCryptService, notificationsService, apiService) || this;
        _this.broadcastService = broadcastService;
        _this.loggerService = loggerService;
        _this.syncCryptService = syncCryptService;
        _this.notificationsService = notificationsService;
        _this.apiService = apiService;
        _this.dirtyOutService = dirtyOutService;
        _this.batchMoveService = batchMoveService;
        return _this;
    }
    /**
     * @ngdoc method
     * @name  execute
     * @methodOf sync.service:BatchRename
     * @description
     * Renames a given sync id to the new pid location recursively
     * @param  {Integer} syncID    The sync id to rename
     * @param  {Integer} syncPid   The destination sync pid
     * @param  {String} folderName The new name for the item
     * @return {Promise}           Resolved on completion of the rename
     */
    BatchRenameService.prototype.execute = function (syncId, syncPid, folderName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var nameData, keyArray, enc_name, err_1, errData_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loggerService.info("batchRename(" + syncId + ", " + syncPid + ", " + folderName + ")");
                        this.view.globalCancel = false;
                        this.ACTION = this.ACT_RENAME_RECURSIVE;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 9]);
                        return [4 /*yield*/, this.dirtyOutService.executeForSync(syncPid, folderName)];
                    case 2:
                        keyArray = _a.sent();
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 6, , 7]);
                        return [4 /*yield*/, this.syncCryptService.filenameEncrypt(folderName)];
                    case 4:
                        enc_name = _a.sent();
                        nameData = {
                            share_names: keyArray,
                            new_name: enc_name,
                        };
                        return [4 /*yield*/, this.batchMoveService._moveFolder([syncId], syncPid, nameData)];
                    case 5: return [2 /*return*/, _a.sent()];
                    case 6:
                        err_1 = _a.sent();
                        this.loggerService.handleError('batchRename.filenameEncrypt', err_1);
                        return [3 /*break*/, 7];
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        errData_1 = _a.sent();
                        this.loggerService.handleError('batchRename.executeForSync', errData_1);
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * @ngdoc method
     * @name  executeFile
     * @methodOf sync.service:BatchRename
     * @description
     * Renames a given sync id to the new pid location for a file only
     * @param  {Integer} syncID    The sync id to rename
     * @param  {Integer} syncPid   The destination sync pid
     * @param  {String} newName The new name for the item
     * @return {Promise}           Resolved on completion of the rename
     */
    BatchRenameService.prototype.executeFile = function (syncId, syncPid, newName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var keyArray, i, len, encName, data, err_2, errData_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loggerService.info("batchRenameFile(" + syncId + ", " + syncPid + ", " + newName + ")");
                        this.view.globalCancel = false;
                        this.ACTION = this.ACT_RENAME_SINGLE;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 9]);
                        return [4 /*yield*/, this.dirtyOutService.executeForSync(syncPid, newName)];
                    case 2:
                        keyArray = _a.sent();
                        for (i = 0, len = keyArray.length; i < len; i++) {
                            keyArray[i].action = 'rename';
                        }
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 6, , 7]);
                        return [4 /*yield*/, this.syncCryptService.filenameEncrypt(newName)];
                    case 4:
                        encName = _a.sent();
                        return [4 /*yield*/, this.apiService.execute('pathrename', {
                                sync_id: syncId,
                                new_name: encName,
                                share_names: keyArray,
                            })];
                    case 5:
                        data = _a.sent();
                        return [2 /*return*/, data];
                    case 6:
                        err_2 = _a.sent();
                        this.loggerService.handleError('batchRename.filenameEncrypt', err_2);
                        return [3 /*break*/, 7];
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        errData_2 = _a.sent();
                        this.loggerService.handleError('batchRename.executeFile', errData_2);
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    BatchRenameService.ngInjectableDef = i0.defineInjectable({ factory: function BatchRenameService_Factory() { return new BatchRenameService(i0.inject(i1.BroadcastService), i0.inject(i2.LoggerService), i0.inject(i3.SyncCryptService), i0.inject(i4.NotificationsService), i0.inject(i5.ApiService), i0.inject(i6.DirtyOutService), i0.inject(i7.BatchMoveService)); }, token: BatchRenameService, providedIn: "root" });
    return BatchRenameService;
}(batch_action_service_1.BatchActionService));
exports.BatchRenameService = BatchRenameService;
