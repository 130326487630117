"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../error/error-display-hash/error-display-hash.component.ngfactory");
var i2 = require("../../error/error-display-hash/error-display-hash.component");
var i3 = require("@angular/forms");
var i4 = require("./share-options.component");
var i5 = require("../../core/user.service");
var i6 = require("../../core/logger.service");
var i7 = require("../share.service");
var i8 = require("../../shared/services/broadcast.service");
var styles_ShareOptionsComponent = [];
var RenderType_ShareOptionsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShareOptionsComponent, data: {} });
exports.RenderType_ShareOptionsComponent = RenderType_ShareOptionsComponent;
function View_ShareOptionsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalInstance.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["Manage share ", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 15, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "sync-error-display-hash", [], null, null, null, i1.View_ErrorDisplayHashComponent_0, i1.RenderType_ErrorDisplayHashComponent)), i0.ɵdid(8, 114688, null, 0, i2.ErrorDisplayHashComponent, [], { errors: [0, "errors"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [["class", "col-sm-4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Share label:"])), (_l()(), i0.ɵeld(12, 0, null, null, 8, "div", [["class", "col-sm-8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 7, "input", [["class", "form-control"], ["maxlength", "80"], ["type", "text"]], [[8, "placeholder", 0], [1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 14).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 14)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 14)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.shareData.label = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 16384, null, 0, i3.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(15, 540672, null, 0, i3.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i0.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.MaxLengthValidator]), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i0.ɵdid(18, 671744, null, 0, i3.NgModel, [[8, null], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(20, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i0.ɵeld(21, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 7, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 1, "button", [["class", "btn btn-default pull-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPopup(5) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Unshare folder"])), (_l()(), i0.ɵeld(25, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setLabel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 0, "i", [["class", "fa fa-spinner fa-spin"]], [[2, "hide", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Save settings "])), (_l()(), i0.ɵeld(28, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalInstance.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.errors; _ck(_v, 8, 0, currVal_1); var currVal_11 = "80"; _ck(_v, 15, 0, currVal_11); var currVal_12 = _co.shareData.label; _ck(_v, 18, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shareData.name; _ck(_v, 5, 0, currVal_0); var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.shareData.name, ""); var currVal_3 = (i0.ɵnov(_v, 15).maxlength ? i0.ɵnov(_v, 15).maxlength : null); var currVal_4 = i0.ɵnov(_v, 20).ngClassUntouched; var currVal_5 = i0.ɵnov(_v, 20).ngClassTouched; var currVal_6 = i0.ɵnov(_v, 20).ngClassPristine; var currVal_7 = i0.ɵnov(_v, 20).ngClassDirty; var currVal_8 = i0.ɵnov(_v, 20).ngClassValid; var currVal_9 = i0.ɵnov(_v, 20).ngClassInvalid; var currVal_10 = i0.ɵnov(_v, 20).ngClassPending; _ck(_v, 13, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_13 = !_co.spinners.label; _ck(_v, 26, 0, currVal_13); }); }
exports.View_ShareOptionsComponent_0 = View_ShareOptionsComponent_0;
function View_ShareOptionsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-share-options", [], null, null, null, View_ShareOptionsComponent_0, RenderType_ShareOptionsComponent)), i0.ɵdid(1, 114688, null, 0, i4.ShareOptionsComponent, [i5.UserService, i6.LoggerService, i7.ShareService, i8.BroadcastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ShareOptionsComponent_Host_0 = View_ShareOptionsComponent_Host_0;
var ShareOptionsComponentNgFactory = i0.ɵccf("sync-share-options", i4.ShareOptionsComponent, View_ShareOptionsComponent_Host_0, { shareData: "shareData", modalInstance: "modalInstance", openState: "openState" }, { openStateChange: "openStateChange" }, []);
exports.ShareOptionsComponentNgFactory = ShareOptionsComponentNgFactory;
