"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var ShareMailInviteApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(ShareMailInviteApiOutput, _super);
    function ShareMailInviteApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ShareMailInviteApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.ShareMailInviteApiOutput = ShareMailInviteApiOutput;
var ShareMailInviteApiInput = /** @class */ (function () {
    function ShareMailInviteApiInput() {
    }
    return ShareMailInviteApiInput;
}());
exports.ShareMailInviteApiInput = ShareMailInviteApiInput;
