"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Session = /** @class */ (function () {
    function Session(params) {
        this.device_id = 0;
        this.device_name = '';
        this.device_type_id = 0;
        this.id = '';
        this.ipaddr = '';
        this.is_current = 0;
        this.lastaction_servtime = 0;
        this.login_servtime = 0;
        this.useragent_browser = '';
        this.useragent_platform = '';
        this.useragent_version = '';
        if (params) {
            this.deserialize(params);
        }
    }
    Session.prototype.deserialize = function (params) {
        for (var _i = 0, _a = Object.entries(params); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], val = _b[1];
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    };
    return Session;
}());
exports.Session = Session;
