"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("../core/api.service");
var base64_service_1 = require("../core/base64.service");
var sync_crypt_service_1 = require("../core/crypt/sync-crypt.service");
var logger_service_1 = require("../core/logger.service");
var models_1 = require("../shared/models");
var store_1 = require("@ngrx/store");
var joblock_service_1 = require("../core/joblock.service");
var i0 = require("@angular/core");
var i1 = require("../core/api.service");
var i2 = require("../core/logger.service");
var i3 = require("../core/crypt/sync-crypt.service");
var i4 = require("../core/base64.service");
var i5 = require("@ngrx/store");
var i6 = require("../core/joblock.service");
var ShareInviteService = /** @class */ (function () {
    function ShareInviteService(apiService, loggerService, syncCryptService, base64Service, store, joblockService) {
        this.apiService = apiService;
        this.loggerService = loggerService;
        this.syncCryptService = syncCryptService;
        this.base64Service = base64Service;
        this.store = store;
        this.joblockService = joblockService;
    }
    ShareInviteService.prototype.getInvite = function (inviteId, wrapKey, shareId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.execute('shareinvite', {
                            invite_id: inviteId,
                            share_id: shareId,
                        }, false)];
                    case 1:
                        data = _a.sent();
                        return [4 /*yield*/, this.process(data, wrapKey)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ShareInviteService.prototype.getInvitePublic = function (inviteId, wrapKey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.execute('shareinvitepublic', {
                            invite_id: inviteId,
                        })];
                    case 1:
                        data = _a.sent();
                        return [4 /*yield*/, this.process(data, wrapKey)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ShareInviteService.prototype.decline = function (inviteData) {
        return this.apiService.execute('shareaccept', {
            uniqid: inviteData.sharememberId,
            accept: 0,
        });
    };
    ShareInviteService.prototype.accept = function (wrapKey, syncPid, inviteData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var encTask, _a, encName, shareAcceptJob, bg, response, err_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 9, , 10]);
                        if (!wrapKey) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.syncCryptService.encLinkShareKeyListWithPubkeys(wrapKey, inviteData)];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.syncCryptService.encLinkShareKeyWithPubkeys(inviteData.linkShareKeys, inviteData.sharekeyB64, inviteData.pubkey, inviteData.pubkey_id)];
                    case 3:
                        _a = _b.sent();
                        _b.label = 4;
                    case 4:
                        encTask = _a;
                        this.loggerService.info('accept inviteData = ' + JSON.stringify(encTask));
                        return [4 /*yield*/, this.syncCryptService.filenameEncrypt(inviteData.name)];
                    case 5:
                        encName = _b.sent();
                        return [4 /*yield*/, this.apiService.execute('shareaccept', {
                                uniqid: inviteData.sharememberId,
                                enc_name: encName,
                                accept: 1,
                                sync_pid: syncPid,
                                sharedata: encTask,
                                displayname: this.base64Service.encode(inviteData.displayName),
                                bg: 1
                            })];
                    case 6:
                        shareAcceptJob = _b.sent();
                        bg = shareAcceptJob['bg'];
                        if (!(bg == 1)) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.joblockService.pollJobStatus()];
                    case 7:
                        response = _b.sent();
                        _b.label = 8;
                    case 8: return [2 /*return*/, shareAcceptJob];
                    case 9:
                        err_1 = _b.sent();
                        this.loggerService.error('An error occurred accepting data');
                        throw err_1;
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    ShareInviteService.prototype.needConfirm = function (inviteId) {
        return this.apiService.execute('shareneedconfirm', {
            invite_id: inviteId,
        });
    };
    ShareInviteService.prototype.process = function (data, wrapKey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ret, linkShareKey, shareKeyB64, fname, err_2, err_3, sharekey, fname, err_4, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loggerService.info("Process share invite " + data.share_key_id);
                        ret = {
                            linkShareKeys: data.link_sharekeys,
                            sharekeyB64: '',
                            name: '',
                            originalFilename: '',
                            inviter_name: this.base64Service.decode(data.inviter_name),
                            inviter_email: data.inviter_email,
                            inviter_msg: data.message
                                ? this.base64Service.decode(data.message)
                                : '',
                            label: data.label,
                            sharememberId: data.id,
                            salt: data.salt,
                            iterations: data.iterations,
                            syncId: data.sync_id,
                            shareId: parseInt(data.share_key_id.split('-')[0], 10),
                            shareSequence: parseInt(data.share_key_id.split('-')[1], 10),
                            pubkey_id: data.pubkeys.id,
                            pubkey: data.pubkeys.key,
                            displayName: this.base64Service.decode(data.displayname),
                            //white label attributes
                            image_cachekey: data.image_cachekey,
                            header_primary_color: data.header_primary_color,
                            header_text_color: data.header_text_color,
                            button_primary_color: data.button_primary_color,
                            button_text_color: data.button_text_color,
                            link_text_color: data.link_text_color,
                        };
                        if (!data.link_sharekeys) return [3 /*break*/, 9];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        if (!wrapKey) {
                            throw new models_1.ErrCode(1621, data.label);
                        }
                        return [4 /*yield*/, this.getLinkShareKey(wrapKey, data.salt, data.iterations, data.link_sharekeys[data.share_key_id])];
                    case 2:
                        linkShareKey = _a.sent();
                        shareKeyB64 = this.syncCryptService.bytesToB64(linkShareKey);
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, this.syncCryptService.filenameDecrypt(data.enc_share_name, shareKeyB64)];
                    case 4:
                        fname = _a.sent();
                        ret.sharekeyB64 = shareKeyB64;
                        ret.name = fname;
                        ret.originalFilename = fname;
                        return [2 /*return*/, ret];
                    case 5:
                        err_2 = _a.sent();
                        this.loggerService.error('Could not decrypt the folder name share invite');
                        throw new models_1.ErrCode(1607);
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        err_3 = _a.sent();
                        throw new models_1.ErrCode(1609);
                    case 8: return [3 /*break*/, 17];
                    case 9:
                        if (!data.sharekeys) return [3 /*break*/, 17];
                        _a.label = 10;
                    case 10:
                        _a.trys.push([10, 16, , 17]);
                        return [4 /*yield*/, this.syncCryptService.sharekeyDecrypt(data.sharekeys[data.share_key_id], data.share_key_id)];
                    case 11:
                        sharekey = _a.sent();
                        _a.label = 12;
                    case 12:
                        _a.trys.push([12, 14, , 15]);
                        return [4 /*yield*/, this.syncCryptService.filenameDecrypt(data.enc_share_name, sharekey)];
                    case 13:
                        fname = _a.sent();
                        ret.sharekeyB64 = sharekey;
                        ret.name = fname;
                        ret.originalFilename = fname;
                        return [2 /*return*/, ret];
                    case 14:
                        err_4 = _a.sent();
                        this.loggerService.error(err_4);
                        this.loggerService.error('Share key exists, could not decrypt name');
                        this.loggerService.error('Could not decrypt the folder name share invite');
                        throw new models_1.ErrCode(1608);
                    case 15: return [3 /*break*/, 17];
                    case 16:
                        err_5 = _a.sent();
                        throw err_5;
                    case 17: return [2 /*return*/];
                }
            });
        });
    };
    ShareInviteService.prototype.getLinkShareKey = function (wrapKey, salt, it, encLinkShareKey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sharekey, res, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.getLinkShareKeyWrapper(wrapKey, salt, it)];
                    case 1:
                        sharekey = _a.sent();
                        return [4 /*yield*/, this.syncCryptService.linksharekeyDecrypt(encLinkShareKey, sharekey)];
                    case 2:
                        res = _a.sent();
                        return [2 /*return*/, res];
                    case 3:
                        err_6 = _a.sent();
                        this.loggerService.error('The share password is incorrect');
                        this.loggerService.error('Could not decrypt link share key');
                        throw new models_1.ErrCode(1620);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ShareInviteService.prototype.getLinkShareKeyWrapper = function (wrap, salt, iterations) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var wrapKeyBytes, sharekey, err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.syncCryptService.convertToShareKey(wrap, salt, iterations)];
                    case 1:
                        wrapKeyBytes = _a.sent();
                        return [4 /*yield*/, this.syncCryptService.bytesToB64(wrapKeyBytes)];
                    case 2:
                        sharekey = _a.sent();
                        if (!sharekey) {
                            throw new models_1.ErrCode(2115);
                        }
                        return [2 /*return*/, sharekey];
                    case 3:
                        err_7 = _a.sent();
                        throw err_7;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ShareInviteService.ngInjectableDef = i0.defineInjectable({ factory: function ShareInviteService_Factory() { return new ShareInviteService(i0.inject(i1.ApiService), i0.inject(i2.LoggerService), i0.inject(i3.SyncCryptService), i0.inject(i4.Base64Service), i0.inject(i5.Store), i0.inject(i6.JoblockService)); }, token: ShareInviteService, providedIn: "root" });
    return ShareInviteService;
}());
exports.ShareInviteService = ShareInviteService;
