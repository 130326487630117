"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var environment_1 = require("../../../environments/environment");
var DialogEditLinkAuthComponent = /** @class */ (function () {
    function DialogEditLinkAuthComponent(activeModal) {
        this.activeModal = activeModal;
    }
    DialogEditLinkAuthComponent.prototype.ngOnInit = function () {
        var pathname = window.location.pathname.substring(1, window.location.pathname.length);
        this.currentPath = pathname.concat(window.location.hash); // append #hash part if any for enhanced privacy links
        this.loginUrl = environment_1.environment.cphost + "/login";
    };
    return DialogEditLinkAuthComponent;
}());
exports.DialogEditLinkAuthComponent = DialogEditLinkAuthComponent;
