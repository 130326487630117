"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../shared/components/header/nosignup/header-nosignup.component.ngfactory");
var i2 = require("../../shared/components/header/nosignup/header-nosignup.component");
var i3 = require("@ng-bootstrap/ng-bootstrap");
var i4 = require("@angular/router");
var i5 = require("./no-signup-layout.component");
var styles_NoSignupLayoutComponent = [];
var RenderType_NoSignupLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NoSignupLayoutComponent, data: {} });
exports.RenderType_NoSignupLayoutComponent = RenderType_NoSignupLayoutComponent;
function View_NoSignupLayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-header-nosignup", [], null, null, null, i1.View_HeaderNosignupComponent_0, i1.RenderType_HeaderNosignupComponent)), i0.ɵdid(1, 114688, null, 0, i2.HeaderNosignupComponent, [i3.NgbModal], null, null), (_l()(), i0.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(3, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, null); }
exports.View_NoSignupLayoutComponent_0 = View_NoSignupLayoutComponent_0;
function View_NoSignupLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-no-signup-layout", [], null, null, null, View_NoSignupLayoutComponent_0, RenderType_NoSignupLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i5.NoSignupLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_NoSignupLayoutComponent_Host_0 = View_NoSignupLayoutComponent_Host_0;
var NoSignupLayoutComponentNgFactory = i0.ɵccf("sync-no-signup-layout", i5.NoSignupLayoutComponent, View_NoSignupLayoutComponent_Host_0, {}, {}, []);
exports.NoSignupLayoutComponentNgFactory = NoSignupLayoutComponentNgFactory;
