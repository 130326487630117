"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./avatar-stack.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../avatar/avatar.component.ngfactory");
var i3 = require("../avatar/avatar.component");
var i4 = require("../../../core/logger.service");
var i5 = require("@angular/common");
var i6 = require("@ng-bootstrap/ng-bootstrap");
var i7 = require("./avatar-stack.component");
var i8 = require("../../../core/user.service");
var styles_AvatarStackComponent = [i0.styles];
var RenderType_AvatarStackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AvatarStackComponent, data: {} });
exports.RenderType_AvatarStackComponent = RenderType_AvatarStackComponent;
function View_AvatarStackComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "popover-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "popover-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "popover-avatar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "sync-avatar", [], null, null, null, i2.View_AvatarComponent_0, i2.RenderType_AvatarComponent)), i1.ɵdid(4, 114688, null, 0, i3.AvatarComponent, [i4.LoggerService], { user: [0, "user"], avatarIndex: [1, "avatarIndex"], disabled: [2, "disabled"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "avatar-info"]], null, null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "disabled": 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "avatar-displayname"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; var currVal_1 = _v.parent.context.index; var currVal_2 = _v.parent.context.$implicit.disabled; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "avatar-info"; var currVal_4 = _ck(_v, 7, 0, _v.parent.context.$implicit.disabled); _ck(_v, 6, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_5 = _v.parent.context.$implicit.decodedDisplayName; _ck(_v, 9, 0, currVal_5); }); }
function View_AvatarStackComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "div", [["placement", "top"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i6.NgbPopover, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i6.NgbPopoverConfig, i1.NgZone, i5.DOCUMENT], { ngbPopover: [0, "ngbPopover"], placement: [1, "placement"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "sync-avatar", [], null, null, null, i2.View_AvatarComponent_0, i2.RenderType_AvatarComponent)), i1.ɵdid(3, 114688, null, 0, i3.AvatarComponent, [i4.LoggerService], { user: [0, "user"], avatarIndex: [1, "avatarIndex"], disabled: [2, "disabled"] }, null), (_l()(), i1.ɵand(0, [["displayNamePopover", 2]], null, 0, null, View_AvatarStackComponent_2)), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4); var currVal_1 = "top"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit; var currVal_3 = _v.context.index; var currVal_4 = _v.context.$implicit.disabled; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); }, null); }
function View_AvatarStackComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "popover-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "popover-avatar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "sync-avatar", [], null, null, null, i2.View_AvatarComponent_0, i2.RenderType_AvatarComponent)), i1.ɵdid(3, 114688, null, 0, i3.AvatarComponent, [i4.LoggerService], { user: [0, "user"], avatarIndex: [1, "avatarIndex"], disabled: [2, "disabled"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "avatar-info"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "disabled": 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "avatar-displayname"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = (_co.maxDisplayAvatar + _v.context.index); var currVal_2 = _v.context.$implicit.disabled; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "avatar-info"; var currVal_4 = _ck(_v, 6, 0, _v.context.$implicit.disabled); _ck(_v, 5, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_5 = _v.context.$implicit.decodedDisplayName; _ck(_v, 8, 0, currVal_5); }); }
function View_AvatarStackComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "popover-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvatarStackComponent_5)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "popover-view-all"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onViewAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["View All"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.overflowUsers; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AvatarStackComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "div", [["placement", "bottom"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i6.NgbPopover, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i6.NgbPopoverConfig, i1.NgZone, i5.DOCUMENT], { ngbPopover: [0, "ngbPopover"], placement: [1, "placement"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "sync-avatar", [], null, null, null, i2.View_AvatarComponent_0, i2.RenderType_AvatarComponent)), i1.ɵdid(3, 114688, null, 0, i3.AvatarComponent, [i4.LoggerService], { avatarIndex: [0, "avatarIndex"], overflowCount: [1, "overflowCount"] }, null), (_l()(), i1.ɵand(0, [["displayNamesPopover", 2]], null, 0, null, View_AvatarStackComponent_4)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "hover-effect"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4); var currVal_1 = "bottom"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.maxDisplayAvatar - 1); var currVal_3 = _co.overflowUsersCount; _ck(_v, 3, 0, currVal_2, currVal_3); }, null); }
function View_AvatarStackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvatarStackComponent_1)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvatarStackComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.avatarUsers; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.overflowUsersCount; _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_AvatarStackComponent_0 = View_AvatarStackComponent_0;
function View_AvatarStackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sync-avatar-stack", [], null, null, null, View_AvatarStackComponent_0, RenderType_AvatarStackComponent)), i1.ɵdid(1, 638976, null, 0, i7.AvatarStackComponent, [i8.UserService, i4.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AvatarStackComponent_Host_0 = View_AvatarStackComponent_Host_0;
var AvatarStackComponentNgFactory = i1.ɵccf("sync-avatar-stack", i7.AvatarStackComponent, View_AvatarStackComponent_Host_0, { users: "users", maxDisplayAvatar: "maxDisplayAvatar", maxOverflowAvatar: "maxOverflowAvatar", onViewAll: "onViewAll" }, {}, []);
exports.AvatarStackComponentNgFactory = AvatarStackComponentNgFactory;
