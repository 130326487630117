"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var comment_service_1 = require("./../../core/comment.service");
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var LinkListActions = require("../../actions/link-list.actions");
var logger_service_1 = require("../../core/logger.service");
var dispatch_service_1 = require("../../core/dispatch.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_link_component_1 = require("../dialog-link/dialog-link.component");
var link_list_service_1 = require("../services/link-list.service");
var ng_bootstrap_2 = require("@ng-bootstrap/ng-bootstrap");
var router_1 = require("@angular/router");
var user_service_1 = require("src/app/core/user.service");
var LinkListComponent = /** @class */ (function () {
    function LinkListComponent(dispatch, linkList, userService, log, modalService, store, commentService, config, router, route) {
        this.dispatch = dispatch;
        this.linkList = linkList;
        this.userService = userService;
        this.log = log;
        this.modalService = modalService;
        this.store = store;
        this.commentService = commentService;
        this.router = router;
        this.route = route;
        this.list = [];
        this.isTeamsPlusUser = false;
        this.paginate = {
            total: 0,
            currentPage: 1,
            itemsPerPage: 50,
            numPages: 0,
            viewOffset: 0,
            viewEnd: 0
        };
        config.placement = 'left-top';
    }
    LinkListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.store.dispatch(new LinkListActions.LinkListRefreshAction());
        this.isTeamsPlusUser =
            this.userService.get('is_multi_new') &&
                this.userService.get('is_unlimited_plan')
                ? true
                : false;
        this.userSub = this.store.pipe(store_1.select(fromRoot.getAuthUser)).subscribe(function (data) {
            _this.user = data;
        });
        this.sub = this.store.select(fromRoot.getLinkListState).subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var locData_1, item;
            return tslib_1.__generator(this, function (_a) {
                // console.log('======================');
                // console.log('link list subscription');
                // console.log(data);
                if (data.error) {
                    this.log.e('An error occurred loading links', data.error);
                    this.errcode = data.error;
                }
                if (data.sorted && data.loaded) {
                    this.loading = false;
                    this.list = data.items;
                    this.paginate.total = this.list.length;
                    this.paginate.numPages = Math.ceil(this.list.length / this.paginate.itemsPerPage);
                    if (this.route.snapshot.queryParams['page'] &&
                        parseInt(this.route.snapshot.queryParams['page'], 10) <=
                            this.paginate.numPages) {
                        this.paginate.currentPage =
                            parseInt(this.route.snapshot.queryParams['page'], 10) || 1;
                    }
                    else {
                        this.paginate.currentPage = 1;
                    }
                    this.paginate.viewOffset = this.paginate.itemsPerPage * (this.paginate.currentPage - 1);
                    this.paginate.viewEnd = this.paginate.itemsPerPage + this.paginate.viewOffset;
                    this.getCommentsCount();
                    locData_1 = this.dispatch.getDipatchData();
                    if (locData_1 && locData_1.sync_id) {
                        item = this.list.filter(function (val) {
                            if (val.sync_id == locData_1.sync_id) {
                                return true;
                            }
                        });
                        if (item.length) {
                            this.showLinkDialog(item[0], 1);
                        }
                        this.dispatch.removeDispatch();
                    }
                }
                return [2 /*return*/];
            });
        }); });
    };
    LinkListComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.userSub) {
            this.userSub.unsubscribe();
        }
    };
    LinkListComponent.prototype.onPageChange = function (page) {
        this.paginate.currentPage = page;
        this.paginate.viewOffset = this.paginate.itemsPerPage * (page - 1);
        this.paginate.viewEnd = this.paginate.itemsPerPage + this.paginate.viewOffset;
        var pageParam = (this.paginate.currentPage > 1)
            ? this.paginate.currentPage
            : undefined;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { page: pageParam },
            queryParamsHandling: 'merge'
        });
        this.getCommentsCount();
    };
    LinkListComponent.prototype.getCommentsCount = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, viewOffset, itemsPerPage, total, start, end, pageList, locIds, countResult, _i, pageList_1, item;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.paginate, viewOffset = _a.viewOffset, itemsPerPage = _a.itemsPerPage, total = _a.total;
                        start = viewOffset;
                        end = Math.min(start + itemsPerPage, total);
                        pageList = this.list.slice(start, end);
                        locIds = pageList.map(function (item) { return item.loc_id; });
                        return [4 /*yield*/, this.commentService.getCommentCount(locIds)];
                    case 1:
                        countResult = _b.sent();
                        for (_i = 0, pageList_1 = pageList; _i < pageList_1.length; _i++) {
                            item = pageList_1[_i];
                            item.comment_count = countResult ? (countResult[item.loc_id] || 0) : 0;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    LinkListComponent.prototype.getPredicateClass = function (field) {
        var cssClass = 'fa fa-sortx text-muted';
        if (this.predicate && this.predicate.indexOf(field) > -1 && this.predicateReverse) {
            cssClass = 'fa fa-sort-down';
        }
        else if (this.predicate && this.predicate.indexOf(field) > -1 && !this.predicateReverse) {
            cssClass = 'fa fa-sort-up';
        }
        return cssClass;
    };
    LinkListComponent.prototype.setPredicate = function (field) {
        if (this.predicate == 'name' && this.predicateReverse === true) {
            this.predicate = 'default';
            this.predicateReverse = true;
        }
        else {
            this.predicate = field;
            this.predicateReverse = !this.predicateReverse;
        }
        var sortData = {
            direction: (this.predicateReverse) ? 'desc' : 'asc',
            active: this.predicate
        };
        this.store.dispatch(new LinkListActions.LinkListSortAction(sortData));
    };
    LinkListComponent.prototype.loadLink = function (link) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var item;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        link.spinner = true;
                        return [4 /*yield*/, this.linkList.getLink(link.sync_id, link.name)];
                    case 1:
                        item = _a.sent();
                        link.spinner = false;
                        window.location.href = item.linkUrl;
                        return [2 /*return*/];
                }
            });
        });
    };
    LinkListComponent.prototype.showLinkDialog = function (item, openState) {
        var _this = this;
        var ref = this.modalService.open(dialog_link_component_1.DialogLinkComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.item = item;
        ref.componentInstance.openState = openState || 1;
        ref.result.then(function (result) {
            if (result === true) {
                _this.store.dispatch(new LinkListActions.LinkListRefreshAction());
            }
        });
    };
    return LinkListComponent;
}());
exports.LinkListComponent = LinkListComponent;
