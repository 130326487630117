"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var store_1 = require("@ngrx/store");
var effects_1 = require("@ngrx/effects");
var LinkListActions = require("../actions/link-list.actions");
var link_list_service_1 = require("../links/services/link-list.service");
var reducers_1 = require("../reducers");
var LinkListEffects = /** @class */ (function () {
    function LinkListEffects(links, actions$, store$) {
        var _this = this;
        this.links = links;
        this.actions$ = actions$;
        this.store$ = store$;
        this.refresh$ = this.actions$.pipe(effects_1.ofType(LinkListActions.REFRESH), operators_1.mergeMap(function (action) {
            return rxjs_1.from(_this.links.getData()).pipe(operators_1.map(function (data) { return new LinkListActions.LinkListSuccessAction(data); }), operators_1.catchError(function (err) {
                return rxjs_1.of(new LinkListActions.LinkListErrorAction(err));
            }));
        }));
        this.success$ = this.actions$.pipe(effects_1.ofType(LinkListActions.SUCCESS), operators_1.withLatestFrom(this.store$.select(reducers_1.getLinkListState)), operators_1.map(function (_a) {
            var action = _a[0], storeState = _a[1];
            return new LinkListActions.LinkListSortAction({
                active: storeState.predicate,
                direction: storeState.direction,
            });
        }));
    }
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], LinkListEffects.prototype, "refresh$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], LinkListEffects.prototype, "success$", void 0);
    return LinkListEffects;
}());
exports.LinkListEffects = LinkListEffects;
