"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EncFile = /** @class */ (function () {
    function EncFile(params) {
        this.loc_id = '';
        this.metaname = '';
        this.name = '';
        if (params) {
            this.deserialize(params);
        }
    }
    EncFile.prototype.deserialize = function (params) {
        for (var _i = 0, _a = Object.entries(params); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], val = _b[1];
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    };
    return EncFile;
}());
exports.EncFile = EncFile;
