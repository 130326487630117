"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../transfer/constants");
var i0 = require("@angular/core");
var ValidateService = /** @class */ (function () {
    function ValidateService() {
    }
    /**
     * Loosely tests the value that it's an email, returns tru if email.  Since
     * email addresses can be just about anything from unicode to not, the test
     * needs to account for all types of email addresses.
     * @param value email address
     */
    ValidateService.prototype.isEmail = function (value) {
        var rgx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return rgx.test(value);
    };
    /**
     * Checks for illegal characters \, /, :, *, ?, <, >, |
     * @param  {String} value The input value to test
     * @return {Boolean}      True if value passes test
     */
    ValidateService.prototype.illegalCharacters = function (name) {
        if (this.isEmpty(name)) {
            return true;
        }
        return (/^[^\/:\*\?"<>\|\\]+$/).test(name);
    };
    /**
     * Checks for a leading space in the value
     * @param  {String} value The input value to test
     * @return {Boolean}      True if value passes test
     */
    ValidateService.prototype.leadingSpace = function (value) {
        if (this.isEmpty(value)) {
            return true;
        }
        return (value && value.charAt(0) !== ' ');
    };
    /**
     * Makes sure the trailing character is not a dot or space character
     * @param  {String} value The input value to test
     * @return {Boolean}      True if value passes test
     */
    ValidateService.prototype.trailingChar = function (value) {
        if (this.isEmpty(value)) {
            return true;
        }
        var lastChar = value.charAt(value.length - 1);
        return !(lastChar === '.' || lastChar === ' ');
    };
    /**
     * These are a list of names that are reserved for use by the OS and/or
     * Sync.
     *
     * Windows does not like: com1, com1.txt, etc and many other special names
     *
     * The other names here are a list of names that the agent will ignore,
     * so we should ensure the web does not allow any names to be created that
     * the agent will ignore.
     *
     * @param  {String} value The input value to test
     * @return {Boolean}      True if value passes test
     */
    ValidateService.prototype.reservedNames = function (value) {
        if (this.isEmpty(value)) {
            return true;
        }
        return !(/^(CLOCK\$|PRN|AUX|NUL|CON|COM[0-9]|LPT[0-9])$/i).test(value) &&
            value.toLowerCase() !== '.syncinfo' &&
            value.toLowerCase() !== 'thumbs.db' &&
            value.toLowerCase() !== 'desktop.ini' &&
            value.toLowerCase() !== 'sync.cache' &&
            value.toLowerCase() !== '$recycle.bin' &&
            value.indexOf('.~') !== 0 &&
            value.indexOf('~') !== 0 &&
            value.indexOf('~$') === -1 &&
            !(/^(PRN\.[a-z0-9]+|AUX\.[a-z0-9]+|NUL\.[a-z0-9]+|CON\.[a-z0-9]+|COM[0-9]\.[a-z0-9]+|LPT[0-9]\.[a-z0-9]+)$/i).test(value);
    };
    /**
     * Ensures the length of the value is less than or equal to a certain number
     * @param  {String}  value The input value to test
     * @param  {Number}  Maximum input length
     * @return {Boolean} True if value passes test
     */
    ValidateService.prototype.maxLength = function (value, maxLength) {
        if (this.isEmpty(value)) {
            return true;
        }
        return value.length <= maxLength;
    };
    /**
     * Ensures value is not empty
     * @param  {String} value The input value to test
     * @return {Boolean}      True if value passes test
     */
    ValidateService.prototype.empty = function (value) {
        return (value !== null && value !== undefined && value !== '');
    };
    /**
     * If the value is uninitialized or an empty string, return true.
     * @param value boolean;
     */
    ValidateService.prototype.isEmpty = function (value) {
        return (value === undefined || value === '' || value === null);
    };
    ValidateService.prototype.isNameValid = function (name) {
        return (this.illegalCharacters(name) &&
            this.empty(name) &&
            this.reservedNames(name) &&
            this.leadingSpace(name) &&
            this.trailingChar(name)) &&
            this.maxLength(name, constants_1.Constants.MAX_FILE_NAME_LEN);
    };
    ValidateService.prototype.isDisplayNameValid = function (name) {
        return name === this.stripUrls(name);
    };
    ValidateService.prototype.isAlphaNumeric = function (value) {
        if (this.isEmpty(value)) {
            return true;
        }
        return (/^[A-Za-z0-9 ]*$/).test(value);
    };
    // PRIVATE HELPER FUNCTIONS
    // ========================
    ValidateService.prototype.stripUrls = function (str) {
        return str.replace(/\b((https?|ftp|file):\/\/|www\.)[-A-Z0-9+&@#\/%?=~_|$!:,.;]*[A-Z0-9+&@#\/%=~_|$]/i, ' ');
    };
    ValidateService.ngInjectableDef = i0.defineInjectable({ factory: function ValidateService_Factory() { return new ValidateService(); }, token: ValidateService, providedIn: "root" });
    return ValidateService;
}());
exports.ValidateService = ValidateService;
