"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../../environments/environment");
var models_1 = require("../shared/models");
var LoggerService = /** @class */ (function () {
    function LoggerService() {
        var _this = this;
        this.logs = [];
        this.keepLogs = [];
        this.showLogger = !environment_1.environment.production;
        this._log = console.log;
        this._error = console.error;
        this._warn = console.warn;
        console.log = console.info = function (msg, data) {
            if (data) {
                _this.info(_this.getMsg(msg) + ' ' + _this.getMsg(data));
            }
            else {
                _this.info(_this.getMsg(msg));
            }
        };
        console.error = function (msg, data) {
            if (data) {
                _this.error(_this.getMsg(msg) + ' ' + _this.getMsg(data));
            }
            else {
                _this.error(_this.getMsg(msg));
            }
        };
        console.warn = function (msg, data) {
            if (data) {
                _this.warn(_this.getMsg(msg) + ' ' + _this.getMsg(data));
            }
            else {
                _this.warn(_this.getMsg(msg));
            }
        };
    }
    /**
     * @ngdoc method
     * @name  info
     * @methodOf sync.service:Logger
     * @description
     * Logs an informational message and stores it with a timestamp.
     * @param  {String} msg The message to log
     */
    LoggerService.prototype.info = function (msg) {
        var log = { msg: this.getMsg(msg), type: 'info', date: Date.now() };
        this.logs.push(log);
        if (this.logs.length >= 1000) {
            this.logs.splice(this.logs.length - 1000);
        }
        if (this.showLogger) {
            this._log(msg);
        }
    };
    /**
     * @ngdoc method
     * @name  warn
     * @methodOf sync.service:Logger
     * @description
     * Logs an warning message and stores it with a timestamp.
     * @param  {String} msg The message to log
     */
    LoggerService.prototype.warn = function (msg) {
        var log = { msg: this.getMsg(msg), type: 'warn', date: Date.now() };
        this.keepLogs.push(log);
        this._warn(msg);
    };
    /**
     * Logs an error message and stores it with a timestamp.
     * @param  {String} msg The message to log
     */
    LoggerService.prototype.error = function (msg) {
        var log = { msg: this.getMsg(msg), type: 'danger', date: Date.now() };
        this.keepLogs.push(log);
        this._error(msg);
        // console.trace();
    };
    LoggerService.prototype.errcode = function (e) {
        this.error(['ErrCode: ', e.code, ' : ', e.msg].join(''));
    };
    LoggerService.prototype.d = function (msg) {
        if (!environment_1.environment.production) {
            // tslint:disable-next-line: no-console
            console.debug(msg);
        }
    };
    LoggerService.prototype.e = function (msg, ex) {
        this.error(msg);
        if (ex instanceof models_1.ErrCode) {
            this.errcode(ex);
        }
        else if (ex instanceof Error || ex instanceof DOMError) {
            this.error(ex);
        }
    };
    LoggerService.prototype.handleError = function (tag, data, reject) {
        if (reject === void 0) { reject = true; }
        var err = new models_1.ErrCode(1000);
        if (typeof data === 'object') {
            if (data.error_code) {
                err.code = data.error_code;
            }
            else if (data.code) {
                err.code = data.code;
            }
            else {
            }
        }
        else if (typeof data === 'string') {
            // assume this is a regular error message
            err.msg = data;
        }
        this.error([
            tag, ':: - code: ',
            err.code, ' msg: ', err.msg
        ].join(''));
        if (reject) {
            throw (err);
        }
        return err;
    };
    /**
     * @ngdoc method
     * @name  apiError
     * @methodOf sync.service:Logger
     * @description
     * Handles api errors by logging the error message from the API error hash
     * @param  {Object} msg The standard API error hash
     * @return {Promise}    A rejected promise
     */
    LoggerService.prototype.apiError = function (msg) {
        var errMsg = (typeof msg === 'object' && msg.error_msg)
            ? msg.error_msg
            : msg;
        this.error(errMsg);
        return Promise.reject(errMsg);
    };
    /**
     * @ngdoc method
     * @name  dumpLogsText
     * @methodOf sync.service:Logger
     * @description
     * Formats a given type of logs into a string, parsing the timestamp.
     * This will be human readable and sorted by date
     * @param  {String} type 'info', 'error', 'warn' are valid types
     * @return {String}      The resulting logs with \n characters
     */
    LoggerService.prototype.dumpLogsText = function (type) {
        var logs = this.logs.concat(this.keepLogs);
        var formatted = [];
        if (this.logs.length >= 1000) {
            formatted.push('-- Potentially truncated --\n\n');
        }
        logs.sort(function (a, b) {
            return a.date - b.date;
        });
        // logs = this.$filter('orderBy')(logs, 'date', true);
        for (var i = 0, len = logs.length; i < len; i++) {
            var d = new Date(logs[i].date);
            formatted.push(['(', logs[i].type, ') ',
                d.toLocaleString(),
                ' - ', logs[i].msg].join(''));
        }
        return formatted.join('\n\n');
    };
    /**
     * @ngdoc method
     * @name  clear
     * @methodOf sync.service:Logger
     * @description
     * Clear the stored logs
     */
    LoggerService.prototype.clear = function () {
        this.logs = [];
        this.keepLogs = [];
    };
    LoggerService.prototype.getMsg = function (msg) {
        if (typeof msg === 'string') {
            return msg;
        }
        else {
            try {
                return JSON.stringify(msg);
            }
            catch (e) {
                this._error('An error occurred writing log ' + e);
                return msg;
            }
        }
    };
    return LoggerService;
}());
exports.LoggerService = LoggerService;
