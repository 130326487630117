"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ErrorDisplayHashComponent = /** @class */ (function () {
    function ErrorDisplayHashComponent() {
        this.errors = [];
    }
    ErrorDisplayHashComponent.prototype.ngOnInit = function () {
    };
    return ErrorDisplayHashComponent;
}());
exports.ErrorDisplayHashComponent = ErrorDisplayHashComponent;
