"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("../core/api.service");
var sync_crypt_service_1 = require("../core/crypt/sync-crypt.service");
var logger_service_1 = require("../core/logger.service");
var notifications_service_1 = require("../core/notifications.service");
var broadcast_service_1 = require("../shared/services/broadcast.service");
var i0 = require("@angular/core");
var i1 = require("../shared/services/broadcast.service");
var i2 = require("../core/logger.service");
var i3 = require("../core/crypt/sync-crypt.service");
var i4 = require("../core/notifications.service");
var i5 = require("../core/api.service");
var BatchActionService = /** @class */ (function () {
    function BatchActionService(broadcastService, loggerService, syncCryptService, notificationsService, apiService) {
        this.broadcastService = broadcastService;
        this.loggerService = loggerService;
        this.syncCryptService = syncCryptService;
        this.notificationsService = notificationsService;
        this.apiService = apiService;
        this.retryAmt = 0;
        this.ACT_MOVE = 1;
        this.ACT_COPY = 2;
        this.ACT_DELETE = 3;
        this.ACT_PURGE = 4;
        this.ACT_RESTORE = 5;
        this.ACT_RENAME_RECURSIVE = 6;
        this.ACT_RENAME_SINGLE = 7;
        this.ACTION = 0;
        this.copyRetryAmt = 0;
        this.view = {
            spinner: 0,
            total: 0,
            completed: 0,
            status: {},
            globalCancel: false,
            percent: 0,
            completedBatch: [],
            errors: [],
            action: 0,
        };
    }
    /**
     * @ngdoc method
     * @name  init
     * @methodOf sync.service:BatchActionFactory
     * @description
     * Initializes and resets the batch action state
     */
    BatchActionService.prototype.init = function () {
        this.resetProgress();
        this.ACTION = 0;
        this.view.globalCancel = false;
        this.view.spinner = 0;
        this.view.completedBatch = [];
        this.view.errors = [];
    };
    /**
     * @ngdoc method
     * @name  cancel
     * @methodOf sync.service:BatchAction
     * @description
     * Allows cancelling the current batch action.  It will not stop in the
     * middle of processing but when it's time to move on to the next batch
     * the cancel value will be set and stopped
     */
    BatchActionService.prototype.cancel = function () {
        this.loggerService.info('Cancelling the batch action task');
        this.view.globalCancel = true;
    };
    /**
     * @ngdoc method
     * @name  isCompleted
     * @methodOf sync.service:BatchAction
     * @description
     * Check if the given sync id has been completed
     * @param  {Integer}  syncID The path sync id
     * @return {Boolean}        True if completed
     */
    BatchActionService.prototype.isCompleted = function (syncId) {
        return this.view.completedBatch.indexOf(syncId) > -1;
    };
    /**
     * @ngdoc method
     * @name  setStatus
     * @methodOf sync.service:BatchActionFactory
     * @description
     * Set the status message of the current batch action
     * @param {Integer} syncID    The affected sync id
     * @param {String} statusMsg The message
     */
    BatchActionService.prototype.setStatus = function (syncId, statusMsg) {
        this.view.status[syncId] = { msg: statusMsg };
    };
    BatchActionService.prototype.dumpDirs = function (syncId, activeLevel) {
        this.view.completed = 0;
        var string = activeLevel || 1;
        return this.apiService.execute('batchdumpdirs', {
            sync_id: syncId,
            active_level: string.toString(),
        });
    };
    BatchActionService.prototype.runBatchItems = function (syncIds, subject) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var syncId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                syncId = syncIds.pop();
                if (this.view.globalCancel) {
                    return [2 /*return*/, this.handleCancel()];
                }
                else {
                    this.prepareItemForAction(syncId)
                        .then(function () {
                        subject.next({ sync_id: syncId });
                        if (syncIds.length) {
                            _this.runBatchItems(syncIds, subject);
                        }
                        else {
                            _this.batchSuccess();
                            subject.complete();
                        }
                    }).catch(function (errData) {
                        subject.error(errData);
                        subject.complete();
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    BatchActionService.prototype.batchSuccess = function () {
        // reload the file list
        this.broadcastService.broadcast('event:file-list.reload', { hist_id: 0 });
        this.init();
        this.resetProgress();
    };
    BatchActionService.prototype.runProcessTask = function (syncIds, idx) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var syncId, errData_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.view.globalCancel) {
                            return [2 /*return*/, this.handleCancel()];
                        }
                        syncId = syncIds[idx];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 10]);
                        return [4 /*yield*/, this.apiExecuteTask(syncId.sync_id)];
                    case 2:
                        _a.sent();
                        this.loggerService.info('Run Process Task api successful');
                        this.updateProgress(syncId);
                        idx++;
                        if (!syncIds[idx]) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.runProcessTask(syncIds, idx)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4: return [2 /*return*/];
                    case 5: return [3 /*break*/, 10];
                    case 6:
                        errData_1 = _a.sent();
                        if (errData_1 &&
                            errData_1.error_code > 8000 &&
                            errData_1.error_code < 8200) {
                            this.notificationsService.startNotificationLoop();
                            this.retryAmt = 0;
                            return [2 /*return*/, this.loggerService.handleError('runProcessTask.notauthorized', errData_1)];
                        }
                        if (!(this.retryAmt > 2)) return [3 /*break*/, 7];
                        this.notificationsService.startNotificationLoop();
                        this.retryAmt = 0;
                        return [2 /*return*/, this.loggerService.handleError('runProcessTask', errData_1)];
                    case 7:
                        this.retryAmt += 1;
                        this.loggerService.error("run process api execute failed for " + syncId);
                        return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(function () {
                                resolve(_this.runProcessTask(syncIds, idx));
                            }, 10000); })];
                    case 8:
                        _a.sent();
                        _a.label = 9;
                    case 9: return [3 /*break*/, 10];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    BatchActionService.prototype.prepareItemForAction = function (syncId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loggerService.error('Must be overwritten');
                        return [4 /*yield*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    reject();
                                    return [2 /*return*/];
                                });
                            }); })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    BatchActionService.prototype.apiExecuteTask = function (syncId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loggerService.error('Must be overwritten');
                        return [4 /*yield*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    reject();
                                    return [2 /*return*/];
                                });
                            }); })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    BatchActionService.prototype.updateProgress = function (syncId) {
        this.view.completed += 1;
        var percent = Math.floor((this.view.completed / this.view.total) * 100);
        // percent = (
        //     this.ACTION == this.ACT_MOVE
        //     || this.ACTION == this.ACT_RENAME_RECURSIVE
        // ) ? percent / 2 : percent;
        if (percent >= 100) {
            percent = 100;
            this.view.completedBatch.push(syncId);
        }
        this.view.percent = percent;
    };
    BatchActionService.prototype.convertToArray = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var list, key;
            return tslib_1.__generator(this, function (_a) {
                list = [];
                for (key in data.sync_ids) {
                    list.push(data.sync_ids[key]);
                }
                return [2 /*return*/, Promise.resolve(list)];
            });
        });
    };
    BatchActionService.prototype.dropShare = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var errData_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(data.share && data.share.share_id && data.share.sharemember_id)) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiService.execute('sharememberleave', {
                                share_id: data.share.share_id,
                                sharemember_id: data.share.sharemember_id,
                                bg: 0,
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, data];
                    case 3:
                        errData_2 = _a.sent();
                        this.loggerService.handleError('dropShare', errData_2);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5: return [2 /*return*/, data];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    BatchActionService.prototype.handleCancel = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.loggerService.warn('Cancelled action');
                this.notificationsService.startNotificationLoop();
                this.view.spinner = 0;
                throw new Error();
            });
        });
    };
    BatchActionService.prototype.resetProgress = function () {
        // publicApi.view.spinner = false;
        this.view.completed = 0;
        this.view.percent = 0;
        this.view.total = 0;
    };
    BatchActionService.ngInjectableDef = i0.defineInjectable({ factory: function BatchActionService_Factory() { return new BatchActionService(i0.inject(i1.BroadcastService), i0.inject(i2.LoggerService), i0.inject(i3.SyncCryptService), i0.inject(i4.NotificationsService), i0.inject(i5.ApiService)); }, token: BatchActionService, providedIn: "root" });
    return BatchActionService;
}());
exports.BatchActionService = BatchActionService;
