"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var sjcl = require("sjcl");
var Base64Service = /** @class */ (function () {
    function Base64Service() {
    }
    Base64Service.prototype.encode = function (str) {
        if (!str) {
            return str;
        }
        return sjcl.codec.base64.fromBits(sjcl.codec.utf8String.toBits(str));
    };
    /**
     * @ngdoc method
     * @name  decode
     * @methodOf sync.service:Base64
     * @description
     * Decodes b64 string. Use sjcl for base64 work because of UTF8 issues.
     * @param  {string} b64 The string to decode
     * @return {string}     plain text string
     */
    Base64Service.prototype.decode = function (b64) {
        if (!b64) {
            return b64;
        }
        try {
            return sjcl.codec.utf8String.fromBits(sjcl.codec.base64.toBits(b64));
        }
        catch (ex) {
            return b64;
        }
    };
    /**
     * @ngdoc method
     * @name  encodeBits
     * @methodOf sync.service:Base64
     * @description
     * Encode a bitArray as a b64 string
     * @param  {Array} bitArray The sjcl bitArray to decode
     * @return {string}     b64 string
     */
    Base64Service.prototype.encodeBits = function (bitArray) {
        return sjcl.codec.base64.fromBits(bitArray);
    };
    /**
     * @ngdoc method
     * @name  decodeBits
     * @methodOf sync.service:Base64
     * @description
     * Encode a b64 string into a bitArray
     * @param  {String} b64 The b64 string to encode to a sjcl bitarray
     * @return {Array}      a SJCL bitArray.
     */
    Base64Service.prototype.decodeBits = function (b64) {
        return sjcl.codec.base64.toBits(b64);
    };
    return Base64Service;
}());
exports.Base64Service = Base64Service;
