"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var router_1 = require("@angular/router");
var api_service_1 = require("../../core/api.service");
var logger_service_1 = require("../../core/logger.service");
var user_service_1 = require("../../core/user.service");
var i0 = require("@angular/core");
var i1 = require("../../core/api.service");
var i2 = require("../../core/logger.service");
var i3 = require("../../core/user.service");
var i4 = require("@angular/router");
var AuthGuard = /** @class */ (function () {
    function AuthGuard(apiService, loggerService, userService, router) {
        this.apiService = apiService;
        this.loggerService = loggerService;
        this.userService = userService;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_1, return_to;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.apiService.execute('sessionvalid', {})];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 2:
                        err_1 = _a.sent();
                        this.loggerService.error('Session is not valid');
                        this.loggerService.error(err_1);
                        return_to = (window.location.pathname || '') + (window.location.search || '');
                        this.router.navigate(['/login'], { queryParams: { return_to: return_to } });
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.ApiService), i0.inject(i2.LoggerService), i0.inject(i3.UserService), i0.inject(i4.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
exports.AuthGuard = AuthGuard;
