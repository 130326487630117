import { LinkApiOutput } from './api';
import { Deserializable } from './deserializable.model';

export class LinkItem extends LinkApiOutput implements Deserializable<LinkItem> {

    public type: 'file' | 'dir' = 'file';
    public linkversion = 0;
    public sync_id = 0;
    public share_id = 0;
    public share_sequence = 0;
    public enc_share_key = '';
    public enc_key = '';
    public password = '';
    public salt = '';
    public is_suspended: 0 | 1 = 0;
    public exp_servtime = 0;
    public download_limit = 0;
    public download_count = 0;
    public email_notification: 0 | 1 = 0;
    public compat: 1 | 0 = 1;
    public previewonly: 0 | 1 = 0;
    public file_edit: 0 | 1 = 0;
    public upload: 0 | 1 | 2 = 0;
    public cnt = 0;
    public cachekey = '';
    public label = '';
    public is_app_link = 0;
    public enc_comment_key = '';
    public allow_comment: 0 | 1 | 2 = 1;
    public comment_notification: 0 | 1 = 1;

    // derived values

    public is_expired = false;
    public is_download_close = false;
    public is_download_limit = false;
    public key = '';
    public togglePassword = true;
    public name = '';


    public baseUrl: string;
    public linkUrl: string;
    public separatedUrlBase: string;
    public publinkOutput: string;
    public has_passwordprotect: boolean;
    public passwordprotect: string;


    constructor(params?: {[k: string]: any}) {
        super();
        if (params) {
            this.deserialize(params);
        }
    }

    public deserialize(params: any) {
        for (const [key, val] of Object.entries(params)) {
            if (key in this) {
                this[key] = val;
            }
        }

        return this;
    }
}
