"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var KeysNeedEncByShareApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(KeysNeedEncByShareApiOutput, _super);
    function KeysNeedEncByShareApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return KeysNeedEncByShareApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.KeysNeedEncByShareApiOutput = KeysNeedEncByShareApiOutput;
var KeysNeedEncByShareApiInput = /** @class */ (function () {
    function KeysNeedEncByShareApiInput() {
    }
    return KeysNeedEncByShareApiInput;
}());
exports.KeysNeedEncByShareApiInput = KeysNeedEncByShareApiInput;
