"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/forms");
var i2 = require("./dialog-device-delete.component");
var i3 = require("@ng-bootstrap/ng-bootstrap");
var i4 = require("../../core/api.service");
var styles_DialogDeviceDeleteComponent = [];
var RenderType_DialogDeviceDeleteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogDeviceDeleteComponent, data: {} });
exports.RenderType_DialogDeviceDeleteComponent = RenderType_DialogDeviceDeleteComponent;
function View_DialogDeviceDeleteComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Delete device?"])), (_l()(), i0.ɵeld(6, 0, null, null, 17, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" This will delete this device and disable it from working with Sync. "])), (_l()(), i0.ɵeld(10, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "col-sm-4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Confirm:"])), (_l()(), i0.ɵeld(14, 0, null, null, 9, "div", [["class", "col-sm-8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 8, "div", [["class", "checkbox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 7, "label", [], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 5, "input", [["name", "confirm"], ["type", "checkbox"], ["value", "1"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 18).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 18).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.confirm = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(18, 16384, null, 0, i1.CheckboxControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.CheckboxControlValueAccessor]), i0.ɵdid(20, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(22, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵted(-1, null, [" I confirm that I want to delete this device "])), (_l()(), i0.ɵeld(24, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Delete"])), (_l()(), i0.ɵeld(27, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = "confirm"; var currVal_8 = _co.confirm; _ck(_v, 20, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 22).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 22).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 22).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 22).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 22).ngClassValid; var currVal_5 = i0.ɵnov(_v, 22).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 22).ngClassPending; _ck(_v, 17, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = !_co.confirm; _ck(_v, 25, 0, currVal_9); }); }
exports.View_DialogDeviceDeleteComponent_0 = View_DialogDeviceDeleteComponent_0;
function View_DialogDeviceDeleteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-dialog-device-delete", [], null, null, null, View_DialogDeviceDeleteComponent_0, RenderType_DialogDeviceDeleteComponent)), i0.ɵdid(1, 114688, null, 0, i2.DialogDeviceDeleteComponent, [i3.NgbActiveModal, i4.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DialogDeviceDeleteComponent_Host_0 = View_DialogDeviceDeleteComponent_Host_0;
var DialogDeviceDeleteComponentNgFactory = i0.ɵccf("sync-dialog-device-delete", i2.DialogDeviceDeleteComponent, View_DialogDeviceDeleteComponent_Host_0, { device: "device" }, {}, []);
exports.DialogDeviceDeleteComponentNgFactory = DialogDeviceDeleteComponentNgFactory;
