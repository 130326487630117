"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var sync_crypt_service_1 = require("../core/crypt/sync-crypt.service");
var logger_service_1 = require("../core/logger.service");
var user_service_1 = require("../core/user.service");
var common_1 = require("@angular/common");
var file_type_model_1 = require("../shared/models/file-type.model");
var store_1 = require("@ngrx/store");
var fromRoot = require("../reducers");
var mimeData_1 = require("../../mimeData");
var router_1 = require("@angular/router");
var i0 = require("@angular/core");
var i1 = require("../core/user.service");
var i2 = require("../core/logger.service");
var i3 = require("../core/crypt/sync-crypt.service");
var i4 = require("@angular/common");
var i5 = require("@ngrx/store");
var i6 = require("@angular/router");
var FileItemService = /** @class */ (function () {
    function FileItemService(userService, loggerService, syncCryptService, datePipe, decimalPipe, store, router, route) {
        this.userService = userService;
        this.loggerService = loggerService;
        this.syncCryptService = syncCryptService;
        this.datePipe = datePipe;
        this.decimalPipe = decimalPipe;
        this.store = store;
        this.router = router;
        this.route = route;
        this.MAX_PREVIEW_SIZE = 10485760 * 4;
        this.MAX_PREVIEW_SIZE_XLS = 10485760;
        this.MAX_PREVIEW_SIZE_PDF = 262144;
        this.FileTypes = file_type_model_1.FileType;
        this.fileMimeType = {
            'txt': 'text/plain',
        };
        this.init();
    }
    FileItemService.prototype.init = function () {
        var _this = this;
        this.store
            .pipe(store_1.select(fromRoot.getAuthUser))
            .subscribe(function (data) {
            if (data) {
                _this.user = data;
            }
            else {
                _this.user = null;
            }
        });
    };
    FileItemService.prototype.decryptName = function (item, sharekeys) {
        var encName = item.enc_name;
        var key = '';
        if (item.name) {
            return Promise.resolve(item.name);
        }
        if (!encName) {
            key = sharekeys[item.share_key_id];
        }
        return this.syncCryptService.filenameDecrypt(encName, key);
    };
    FileItemService.prototype.getFileTypeData = function (fname) {
        var extension = fname ? fname.split('.').pop() : '', ext = extension.toLowerCase();
        var data = {
            viewable: false,
            editable: false,
            unicode: false,
            cssclass: 'mime-unknown',
            kind: extension.toLowerCase(),
        }, category = {}, cat, fileext;
        if (extension === fname) {
            data.kind = 'unknown';
            return data; // return a default value if file has no extension
        }
        for (var keyCat in this.FileTypes) {
            var catVal = this.FileTypes[keyCat];
            for (var keyExt in catVal.extensions) {
                if (keyExt === ext) {
                    cat = keyCat;
                    fileext = keyExt;
                    break; // break out of inner loop
                }
            }
            if (fileext !== undefined) {
                break; // break out of loop
            }
        }
        if (fileext !== undefined) {
            category = this.FileTypes[cat];
            data = Object.assign(data, category, category.extensions[fileext]);
            data.extensions = null;
        }
        return data;
    };
    FileItemService.prototype.isPDF = function (item) {
        var name = item.name;
        var ext = name.substr(name.lastIndexOf('.') + 1).toLowerCase();
        return ext === 'pdf' && ext != name.toLowerCase();
    };
    FileItemService.prototype.isImage = function (item) {
        var name = item.name;
        var ext = name.substr(name.lastIndexOf('.') + 1).toLowerCase();
        var canPreview = false;
        switch (ext) {
            case 'jpg':
            case 'png':
            case 'jpeg':
            case 'gif':
            case 'svg':
                canPreview = true;
                break;
            case 'heic':
            case 'heif':
            case 'webp':
            case 'avif':
            case 'tiff':
                canPreview = item.has_thumb2 != 0;
                break;
            default:
                canPreview = false;
        }
        return canPreview && ext != name.toLowerCase();
    };
    FileItemService.prototype.isText = function (item) {
        return item.filetype.mimetype == 'text/plain';
    };
    // https://help.accusoft.com/PrizmDoc/v13.5/HTML/webframe.html#supported-file-formats.html
    FileItemService.prototype.isDocument = function (item) {
        var name = item.name;
        var ext = name.substr(name.lastIndexOf('.') + 1).toLowerCase();
        var canPreview = false;
        // PDF is left out of this switch as it's handled on it's own.
        switch (ext) {
            case 'odt': // open office doc
            case 'ott':
            case 'fodt':
            case 'odf': // openoffice math formula
            case 'ods': // openoffice spreadsheet
            case 'ots':
            case 'fods':
            case 'odp': // openoffice presentation
            case 'otp':
            case 'fodp':
            case 'odg': // openoffice drawing
            case 'otg':
            case 'fodg':
            case 'rtf':
            case 'sxw':
            case 'doc': // ms word
            case 'dot':
            case 'docx':
            case 'dotx':
            case 'docm':
            case 'dotm':
            case 'ppt': // ms power point
            case 'pot':
            case 'pps':
            case 'pptm':
            case 'potx':
            case 'potm':
            case 'ppsx':
            case 'ppsm':
            case 'pptx':
            case 'xls': // ms excel
            case 'xlt':
            case 'xlsx':
            case 'xlsm':
            case 'xltx':
            case 'xltm':
            case 'vsd': // ms visio
            case 'vsdx':
            case 'vsdm':
            case 'vdx':
            case 'rtf': // generic documents
            // case 'csv':
            case 'eml': // email formats
            case 'msg':
            case 'dwg': // autocad formats
            case 'dwf':
            case 'dxf':
            // case 'dgn': // doesn't seem to work too well
            case 'dcm': // medical format
            case 'dicom':
            case 'dcim':
            case 'dicm':
            case 'psd': // image formats
            case 'tif':
            case 'tiff':
            case 'ras':
            case 'cal':
            case 'cals':
            case 'dib':
            case 'bmp':
            case 'ico':
            case 'pbm':
            case 'pgm':
            case 'ppm':
            case 'img':
            case 'cur':
            case 'ncr':
            case 'sgi':
            case 'pct':
            case 'pcx':
            case 'pcd':
            case 'dcx':
            case 'wmf':
            case 'emf':
            case 'tga':
            case 'tpic':
                canPreview = true;
                break;
            default:
                canPreview = false;
        }
        return canPreview && ext != name.toLowerCase();
    };
    FileItemService.prototype.isOfficeLinkEdit = function (item, isUploadAllowed, isFileEditAllowed, isCwdShared, requireUser) {
        if (requireUser === void 0) { requireUser = true; }
        return (this.isMSOffice(item, 'edit')
            && (requireUser ? (this.user && this.user.exists) : true)
            && isUploadAllowed === 1
            && isFileEditAllowed === 1
            && isCwdShared === 0);
    };
    FileItemService.prototype.isMSOffice = function (item, action) {
        var application = this.getMSOfficeApp(item);
        var maxSize = {
            Excel: 5242880,
            Word: 52428800,
            PowerPoint: 104857600,
            WopiTest: 10485760,
        };
        if (application === 'None' || !this.isMSActionAllowed(item, action)) {
            return false;
        }
        else if (maxSize[application] === undefined ||
            maxSize[application] < item.size) {
            return false;
        }
        else {
            return true;
        }
    };
    FileItemService.prototype.getMSOfficeApp = function (item) {
        var ext = item.name.split('.').pop();
        switch (ext) {
            case 'csv':
            case 'ods':
            case 'xls':
            case 'xlsb':
            case 'xlsm':
            case 'xlsx':
                return 'Excel';
            case 'odp':
            case 'pot':
            case 'potm':
            case 'potx':
            case 'pps':
            case 'ppsm':
            case 'ppsx':
            case 'ppt':
            case 'pptm':
            case 'pptx':
                return 'PowerPoint';
            case 'wopitest':
            case 'wopitestx':
                return 'WopiTest';
            case 'doc':
            case 'docm':
            case 'docx':
            case 'dot':
            case 'dotm':
            case 'dotx':
            case 'odt':
            case 'rtf':
                return 'Word';
            default:
                return 'None';
        }
    };
    FileItemService.prototype.isMSActionAllowed = function (item, action) {
        var ext = this.getFileExt(item.name);
        // Note: These are most available actions defined by wopiDiscovery c. 12/2019.
        // Many of these are not used, for example we don't allow any Onenote or Visio files
        // as they're not defined above in this.getMSOfficeApp().
        // Other actions, such as convert and editnew, are defined here but we don't
        // have support for them within WOPI
        var supportedActions = {
            // convert: [
            //     'csv', 'xls',
            //     'doc',
            //     'pps', 'ppt'
            // ],
            edit: [
                'docm',
                'docx',
                'odt',
                'odp',
                'ppsx',
                'pptx',
                'ods',
                'xlsb',
                'xlsm',
                'xlsx',
                // 'one', 'onetoc2',
                // 'vsdx',
                'wopitest',
                'wopitestx',
            ],
            // editnew: [
            //     'b', 'fluid',
            //     'docx', 'dotx', 'odt',
            //     'odp', 'pptx',
            //     'ods', 'xlsx',
            //     'one', 'onepkg',
            //     'vsdx'
            // ],
            embedview: [
                'doc',
                'docm',
                'docx',
                'dot',
                'dotm',
                'dotx',
                'odt',
                'rtf',
                'odp',
                'pot',
                'potm',
                'potx',
                'pps',
                'ppsm',
                'ppsx',
                'ppt',
                'pptm',
                'pptx',
                'ods',
                'xlsb',
                'xlsm',
                'xlsx',
            ],
            // interactivepreview: [
            //     'doc', 'docm', 'docx', 'dot', 'dotm', 'dotx', 'odt', 'rtf',
            //     'odp', 'pot', 'potm', 'potx', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx',
            //     'pdf',
            //     'vsd', 'vsdm', 'vsdx',
            //     'xlsb', 'xlsm', 'xlsx'
            // ],
            // mobileView: [
            //     'csv', 'ods', 'xls', 'xlsb', 'xlsm', 'xlsx',
            //     'doc', 'docm', 'docx', 'dot', 'dotm', 'dotx',
            //     'pot', 'potm', 'potx', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx',
            // ],
            // preloadview: [
            //     'doc', 'docm', 'docx', 'dot', 'dotm', 'dotx', 'odt', 'rtf',
            //     'odp', 'pot', 'potm', 'potx', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx',
            //     'ods', 'xls', 'xlsb', 'xlsm', 'xlsx',
            //     'one', 'onetoc2',
            //     'pdf',
            //     'vsd', 'vsdm', 'vsdx'
            // ],
            view: [
                'doc',
                'docm',
                'docx',
                'dot',
                'dotm',
                'dotx',
                'odt',
                'rtf',
                'odp',
                'pot',
                'potm',
                'potx',
                'pps',
                'ppsm',
                'ppsx',
                'ppt',
                'pptm',
                'pptx',
                'csv',
                'ods',
                'xls',
                'xlsb',
                'xlsm',
                'xlsx',
                // 'one', 'onetoc2',
                // 'pdf',
                // 'vsd', 'vsdm', 'vsdx',
                'wopitest',
                'wopitestx',
            ],
        };
        return (supportedActions[action] && supportedActions[action].includes(ext));
    };
    FileItemService.prototype.isPreviewable = function (item) {
        if (this.isImage(item)) {
            return true;
        }
        if ((this.getFileExt(item.name) == 'xls' ||
            this.getFileExt(item.name) == 'xlsx') &&
            item.size > this.MAX_PREVIEW_SIZE_XLS) {
            return false;
        }
        if (item.size > this.MAX_PREVIEW_SIZE) {
            return false;
        }
        return true;
    };
    FileItemService.prototype.bytesToSize = function (bytes) {
        if (!bytes && bytes != 0) {
            return '';
        }
        // var sizes = ['bytes', 'KB', 'MB', 'GB'];
        var sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        if (bytes == 0) {
            return '0 bytes';
        }
        var x = Math.log(bytes) / Math.log(1024);
        var i = Math.floor(x);
        var formattedNumber = bytes / Math.pow(1024, i);
        if (i === 0) {
            return this.decimalPipe.transform(formattedNumber, '1.0', 'en-US') + ' ' + sizes[i];
        }
        return this.decimalPipe.transform(parseFloat(formattedNumber.toFixed(1)), '1.0', 'en-US') + ' ' + sizes[i];
    };
    FileItemService.prototype.decorate = function (item) {
        item.search_name = item.name.toLowerCase();
        item.usertime = item.usertime ? item.usertime : 0;
        item.date_medium = this.datePipe.transform(item.usertime, 'MMM d, y h:mm a');
        item.filesize = this.bytesToSize(item.size);
        item.active = false;
        item.context = 'files';
        item.compat = false;
        item.file_extension = this.getFileExt(item.name);
        item.mime_type = this.getmimeType(this.getFileExt(item.name));
        if (item.synctype) {
            item.type = (item.synctype & 1) === 1 ? 'dir' : 'file';
            item.is_dir = (item.synctype & 1) === 1 ? 1 : 0;
            item.is_readonly = (item.synctype & 2) === 2 ? 0 : 1;
            item.is_hide_share = (item.synctype & 4) === 4 ? 1 : 0;
            item.is_app_share = (item.synctype & 8) === 8 ? 1 : 0;
            item.is_shared = (item.synctype & 16) === 16 ? 1 : 0;
            item.is_publink = (item.synctype & 32) === 32 ? 1 : 0;
            item.is_share_root =
                (item.synctype & 128) === 128 && item.is_shared ? 1 : 0;
        }
        if (item.type == 'dir') {
            // hardcode this here  because on publinks, there is no synctype
            item.is_dir = 1;
            var cssclass = '';
            if (item.is_deleted) {
                cssclass = 'dir-deleted';
            }
            else {
                if (item.is_share_root) {
                    if (item.is_app_share) {
                        if (item.is_hide_share) {
                            cssclass = 'dir-app';
                        }
                        else {
                            cssclass = 'dir-app-user';
                        }
                    }
                    else {
                        if (item.is_hide_share) {
                            cssclass = 'dir';
                        }
                        else {
                            cssclass = 'dir-sync';
                        }
                    }
                }
                else {
                    cssclass = 'dir';
                }
            }
            item.cssclass = cssclass;
            item.kind = item.is_deleted
                ? 'deleted folder'
                : item.is_share_root
                    ? 'shared folder'
                    : 'folder';
        }
        else if (item.type == 'file') {
            item.is_dir = 0;
            item.filetype = this.getFileTypeData(item.name);
            item.kind = item.is_deleted ? 'deleted file' : item.filetype.kind;
            item.cssclass = item.is_deleted
                ? 'file-deleted'
                : item.filetype.cssclass;
        }
        else {
            this.loggerService.error('Unknown type for ' + item.sync_id);
        }
        item.imgsrc = 'images/icons/' + item.cssclass + '.svg';
        return item;
    };
    /**
     * Formats the API result for a path item
     */
    FileItemService.prototype.format = function (item, sharekeys) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fname, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (item.sync_id == this.userService.get('web_sync_id')) {
                            item.name = 'Vault';
                        }
                        else if (item.sync_id == this.userService.get('syncus_sync_id')) {
                            item.name = 'Sync';
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.decryptName(item, sharekeys)];
                    case 2:
                        fname = _a.sent();
                        if (fname !== undefined && fname === '') {
                            // somehow a user uploaded a file with an empty name.
                            item.name = 'SYNCERROR - File name is empty, try renaming - ' + item.sync_id;
                            this.loggerService.error("file " + item.sync_id + " was uploaded with an empty name, try renaming it");
                        }
                        else {
                            item.name = fname;
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.loggerService.error("Error decrypting name for " + item.sync_id);
                        item.name = 'SYNCERROR - File decyrption failed - ' + item.sync_id;
                        this.loggerService.error('Could not decrypt file name');
                        this.loggerService.error("Sync ID: " + item.sync_id);
                        this.loggerService.error("enc_name: " + item.enc_name);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, this.decorate(item)];
                }
            });
        });
    };
    /**
     * function get file extension from file name
     * @param fileName
     * @returns
     */
    FileItemService.prototype.getFileExt = function (fileName) {
        if (fileName) {
            var tmp = fileName.split('.');
            if (tmp.length) {
                return tmp.pop();
            }
        }
        return null;
    };
    FileItemService.prototype.isStreamable = function (fileType) {
        if (['video', 'audio', 'webm', 'mp3', 'asf'].includes(fileType)) {
            return true;
        }
        return false;
    };
    FileItemService.prototype.getmimeType = function (extName) {
        var data = mimeData_1.default.find(function (item) { return item.types.includes(extName.includes('.') ? extName : "." + extName); });
        if (data) {
            return data.name ? data.name : 'application/octet-stream';
        }
        else {
            return 'application/octet-stream';
        }
    };
    FileItemService.prototype.previewFileType = function (item) {
        var type = 'default';
        if (this.isImage(item)) {
            type = 'image';
        }
        else if (this.isText(item)) {
            type = 'text';
        }
        else if (this.isPDF(item) &&
            (item.context == 'link' || item.context == 'applink') &&
            item.previewonly) {
            type = 'doc';
        }
        else if (this.isPDF(item) &&
            item.context == 'files') {
            type = 'doc';
        }
        else if (this.display_office(item)) {
            type = 'office';
        }
        else if (this.display_doc(item)) {
            type = 'doc';
        }
        else {
            type = 'default';
        }
        return type;
    };
    FileItemService.prototype.display_doc = function (item) {
        return (this.isDocument(item) &&
            // only compat links
            ((item.compat &&
                (item.context == 'link' || item.context == 'applink')) ||
                item.context == 'files'));
    };
    FileItemService.prototype.display_office = function (item) {
        return (this.isMSOffice(item, 'embedview') &&
            // Only enabled in files, for now
            item.context === 'files' &&
            this.user.is_office === 1);
    };
    FileItemService.prototype.generateFileObj = function (item) {
        var extensions = this.getFileExt(item.name);
        if (this.isMSOffice(item, 'embedview')) {
            item.context = 'files';
        }
        else if (this.isDocument(item)) {
            item.context = 'true';
        }
        return { mimetype: this.fileMimeType[extensions], extensions: extensions };
    };
    FileItemService.ngInjectableDef = i0.defineInjectable({ factory: function FileItemService_Factory() { return new FileItemService(i0.inject(i1.UserService), i0.inject(i2.LoggerService), i0.inject(i3.SyncCryptService), i0.inject(i4.DatePipe), i0.inject(i4.DecimalPipe), i0.inject(i5.Store), i0.inject(i6.Router), i0.inject(i6.ActivatedRoute)); }, token: FileItemService, providedIn: "root" });
    return FileItemService;
}());
exports.FileItemService = FileItemService;
