"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Actions = require("../actions/link-list.actions");
var func_1 = require("../shared/func");
exports.initialState = {
    items: [],
    error: undefined,
    loaded: false,
    sorted: false,
    predicate: 'default',
    direction: ''
};
function reducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case Actions.SUCCESS:
            return Object.assign({}, state, {
                loaded: true,
                items: action.payload
            });
        case Actions.RESET:
            return exports.initialState;
        case Actions.ERROR:
            return Object.assign({}, state, { error: action.payload });
        case Actions.REFRESH:
            return Object.assign({}, state, {
                loaded: false,
                sorted: false
            });
        case Actions.SORT:
            var isAsc_1 = action.payload.direction === 'asc';
            var newlist = state.items.slice(0);
            if (action.payload.direction === '') {
                newlist.sort(function (a, b) { return a.name.toString().localeCompare(b.name); });
            }
            else {
                switch (action.payload.active) {
                    case 'name':
                        // newlist.sort((a, b) => compare(a.sortName, b.sortName, isAsc));
                        newlist.sort(function (a, b) {
                            if (isAsc_1) {
                                return a.name.toString().localeCompare(b.name);
                            }
                            else {
                                return b.name.toString().localeCompare(a.name);
                            }
                        });
                        break;
                    case 'date':
                        newlist.sort(function (a, b) { return func_1.compare(a.date, b.date, isAsc_1); });
                        break;
                    case 'downloads':
                        newlist.sort(function (a, b) { return func_1.compare(a.download_count, b.download_count, isAsc_1); });
                        break;
                    case 'default':
                        newlist.sort(function (a, b) { return a.name.toString().localeCompare(b.name); });
                        break;
                    default:
                        newlist.sort(function (a, b) { return a.name.toString().localeCompare(b.name); });
                        break;
                }
            }
            return Object.assign({}, state, {
                items: newlist,
                predicate: action.payload.active,
                direction: action.payload.direction,
                sorted: true
            });
        default:
            return state;
    }
}
exports.reducer = reducer;
// export const getAuthenticated = (state: State) => state.authenticated;
// export const getUser = (state: State) => state.user;
// export const getLoginProgress = (state: State) => state.progress;
// export const getErrCode = (state: State) => state.error;
