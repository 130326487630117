"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var logger_service_1 = require("../../core/logger.service");
var base64_service_1 = require("../../core/base64.service");
var user_service_1 = require("../../core/user.service");
var auth_service_1 = require("../../auth/services/auth.service");
var provision_service_1 = require("../../account/services/provision.service");
var forms_1 = require("@angular/forms");
var share_invite_service_1 = require("../share-invite.service");
var notifications_service_1 = require("../../core/notifications.service");
var router_1 = require("@angular/router");
var password_service_1 = require("../../auth/services/password.service");
var joblock_service_1 = require("../../core/joblock.service");
var blend_service_1 = require("src/app/shared/services/blend.service");
var sync_cookie_service_1 = require("src/app/core/sync-cookie.service");
var ShareProvisionComponent = /** @class */ (function () {
    function ShareProvisionComponent(notificationsService, loggerService, base64Service, userService, authService, provisionService, shareInviteService, router, route, pwdService, JoblockService, syncCookieService, blendService) {
        this.notificationsService = notificationsService;
        this.loggerService = loggerService;
        this.base64Service = base64Service;
        this.userService = userService;
        this.authService = authService;
        this.provisionService = provisionService;
        this.shareInviteService = shareInviteService;
        this.router = router;
        this.route = route;
        this.pwdService = pwdService;
        this.JoblockService = JoblockService;
        this.syncCookieService = syncCookieService;
        this.blendService = blendService;
        this.errors = [];
        this.terms = false;
        this.reset = true;
        this.spinners = {
            invite: false,
            login: false,
            provision: false,
        };
        this.submitted = false;
    }
    ShareProvisionComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.shareReady = false;
                        this.cachekey = this.route.snapshot.params['cachekey'];
                        this.syncReferCode = this.route.snapshot.queryParams['_sync_refer'];
                        this.loggerService.info("Share invite non user cachekey: " + this.cachekey);
                        if (this.route.snapshot.fragment) {
                            this.wrapKey = this.route.snapshot.fragment;
                        }
                        else {
                            this.loggerService.warn('No share password provided');
                        }
                        if (!this.route.snapshot.queryParams['eid']) return [3 /*break*/, 6];
                        this.username = this.base64Service.decode(this.route.snapshot.queryParams['eid']);
                        this.loginUsername = this.username;
                        this.loggerService.info("Suggested email to use: " + this.username);
                        if (!this.userService.isAuthenticated()) return [3 /*break*/, 4];
                        this.notificationsService.stopNotificationLoop();
                        if (!(this.wrapKey &&
                            this.userService.get('email') == this.username)) return [3 /*break*/, 1];
                        this.loggerService.info('We have all we need, redirect to invites page');
                        this.loggerService.info("Redirect /shares/invite/" + this.cachekey);
                        window.location.replace("/shares/invite/" + this.cachekey + "#" + this.wrapKey);
                        return [2 /*return*/];
                    case 1:
                        this.loggerService.warn('LOGGED IN AS SOMEONE ELSE, LOGOUT');
                        return [4 /*yield*/, this.authService.logout()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        this.loggerService.error('USER IS NOT LOGGED IN');
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        this.loggerService.info('No email was passed to this share invite');
                        _a.label = 7;
                    case 7:
                        if (!this.wrapKey) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.getInvite()];
                    case 8:
                        _a.sent();
                        _a.label = 9;
                    case 9:
                        this.sub = this.authService.authState().subscribe(function (data) {
                            if (data.error) {
                                _this.loggerService.e('authState().subscribe - share provision - An error occurred ', data.error);
                            }
                            if (data.authenticated &&
                                data.user &&
                                _this.username == data.user.email &&
                                _this.shareReady) {
                                _this.loggerService.info("authenticated and share ready " + _this.username + " - call success");
                                _this.shareSuccess();
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ShareProvisionComponent.prototype.setWrapKey = function () {
        this.spinners.invite = true;
        this.wrapKey = this.uWrapKey;
        this.getInvite();
    };
    ShareProvisionComponent.prototype.getInvite = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var exists, data, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.provisionService.emailExists(this.username)];
                    case 1:
                        exists = _a.sent();
                        if (!exists) return [3 /*break*/, 2];
                        this.loggerService.info('Email exists, user already provisioned, redirect to login');
                        this.redirectToLogin();
                        return [3 /*break*/, 5];
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        this.loggerService.info('Email does not exist in system, continue');
                        return [4 /*yield*/, this.shareInviteService.getInvitePublic(this.route.snapshot.params['cachekey'], this.wrapKey)];
                    case 3:
                        data = _a.sent();
                        this.foldername = data.name;
                        this.label = data.label;
                        this.spinners.invite = false;
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        if (typeof err_1 === 'object' && err_1.code && err_1.code == 1620) {
                            this.wrapKey = '';
                        }
                        this.errors.push(err_1);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ShareProvisionComponent.prototype.provision = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var provuser, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.submitted = true;
                        if (!this.provisionForm.valid) {
                            return [2 /*return*/];
                        }
                        this.errors = [];
                        if (!this.pwdService.isPasswordStrong(this.password)) {
                            this.errors.push({ code: 8304 });
                            return [2 /*return*/];
                        }
                        if (!this.terms) {
                            this.errors.push({ code: 8300 });
                            return [2 /*return*/];
                        }
                        if (this.password !== this.password2) {
                            this.errors.push({ code: 8121 });
                            return [2 /*return*/];
                        }
                        this.spinners.provision = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.provisionService.provision('share', //source of provision
                            this.username, this.password, this.syncReferCode, this.reset ? 1 : 0, // passreset
                            1 // sso enable
                            )];
                    case 2:
                        provuser = _a.sent();
                        // Awaiting this prevents the connection from being severed due to reroute
                        return [4 /*yield*/, this.blendService.trackSignUp(provuser.user_id, {
                                Signup_type: 'Free',
                                product_type: 'Free',
                                Signup_from: 'shareinvite',
                                Attribution_Mtags: this.syncCookieService.getMarketingRef(),
                                password_reset: this.reset,
                            })];
                    case 3:
                        // Awaiting this prevents the connection from being severed due to reroute
                        _a.sent();
                        this.authService.login(this.username, this.password);
                        console.log(' ---- authenticate successful');
                        this.shareReady = true;
                        console.log(' ---- shareready true');
                        return [3 /*break*/, 5];
                    case 4:
                        err_2 = _a.sent();
                        this.loggerService.error('An error occurred provisioning or authenticating');
                        this.loggerService.error(err_2);
                        this.spinners.provision = false;
                        // errcode 8106 is that this email already exists
                        if (typeof err_2 == 'object' && err_2.code && err_2.code == 8106) {
                            this.loggerService.info('This user already exists, showing login');
                            window.location.replace("/login?ctx=share&username=\n                    " + this.base64Service.encode(this.username) + "\n                    &cachekey=" + this.cachekey + "#" + this.wrapKey);
                            this.errors.push({ code: 8302 });
                        }
                        else {
                            this.errors.push(err_2);
                        }
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ShareProvisionComponent.prototype.login = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.errors = [];
                        this.spinners.login = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.authService.authenticate(this.loginUsername, this.loginPassword)];
                    case 2:
                        _a.sent();
                        this.shareReady = true;
                        return [3 /*break*/, 4];
                    case 3:
                        err_3 = _a.sent();
                        this.errors.push(err_3);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ShareProvisionComponent.prototype.redirectToLogin = function () {
        window.location.replace("/login?ctx=share&username=\n            " + this.base64Service.encode(this.username) + "\n            &cachekey=" + this.cachekey + "#" + this.wrapKey);
    };
    ShareProvisionComponent.prototype.getInviteAccept = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var inviteData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loggerService.info("Retrieve invite for " + this.cachekey);
                        return [4 /*yield*/, this.shareInviteService.getInvite(this.cachekey, this.wrapKey)];
                    case 1:
                        inviteData = _a.sent();
                        this.loggerService.info('Accepting the invite');
                        inviteData.displayName = this.base64Service.decode(inviteData.displayName);
                        return [4 /*yield*/, this.shareInviteService.accept(this.wrapKey, 0, inviteData)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ShareProvisionComponent.prototype.shareSuccess = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getInviteAccept()];
                    case 1:
                        _a.sent();
                        this.spinners.login = false;
                        this.spinners.provision = false;
                        this.loggerService.info('Share has been joined successfully');
                        this.router.navigate(['/shares'], { queryParams: undefined });
                        return [2 /*return*/];
                }
            });
        });
    };
    ShareProvisionComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    };
    return ShareProvisionComponent;
}());
exports.ShareProvisionComponent = ShareProvisionComponent;
