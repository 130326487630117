"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var root_layout_component_1 = require("../layout/root-layout/root-layout.component");
var file_list_component_1 = require("./file-list/file-list.component");
var routes = [
    {
        path: '', component: root_layout_component_1.RootLayoutComponent, pathMatch: 'full',
        children: [
            { path: '', component: file_list_component_1.FileListComponent },
        ]
    }
];
var FileListRoutingModule = /** @class */ (function () {
    function FileListRoutingModule() {
    }
    return FileListRoutingModule;
}());
exports.FileListRoutingModule = FileListRoutingModule;
