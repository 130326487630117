"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var broadcast_service_1 = require("../../shared/services/broadcast.service");
var share_new_service_1 = require("../share-new.service");
var share_service_1 = require("../share.service");
var joblock_service_1 = require("src/app/core/joblock.service");
var store_1 = require("@ngrx/store");
var logger_service_1 = require("../../core/logger.service");
var notifications_service_1 = require("../../core/notifications.service");
var SharememberRemoveComponent = /** @class */ (function () {
    function SharememberRemoveComponent(shareService, shareNewService, broadcastService, JobLockService, loggerService, store, notificationsService) {
        this.shareService = shareService;
        this.shareNewService = shareNewService;
        this.broadcastService = broadcastService;
        this.JobLockService = JobLockService;
        this.loggerService = loggerService;
        this.store = store;
        this.notificationsService = notificationsService;
        this.state = new core_1.EventEmitter();
        this.selectedPurge = '1'; // default true
        this.spinner = false;
        this.errors = [];
        this.stateChangeEvent = new core_1.EventEmitter();
        this.removeMembers = [];
        this.remainMembers = [];
        this.bg = 0;
    }
    SharememberRemoveComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.removeMembers = [];
        this.remainMembers = [];
        this.shareData.people.map(function (cur) {
            if (cur.selected) {
                _this.removeMembers.push(cur);
            }
            else {
                _this.remainMembers.push(cur);
            }
        });
    };
    SharememberRemoveComponent.prototype.postremoveShareMemberAction = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.removeMemberJob.bg == 1)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.JobLockService.pollJobStatus()];
                    case 1:
                        response = _a.sent();
                        this.loggerService.info({ func: 'postremoveShareMemberAction' });
                        _a.label = 2;
                    case 2:
                        this.broadcastService.broadcast('event:share-list.reload');
                        this.spinner = false;
                        this.stateChange(1);
                        return [2 /*return*/];
                }
            });
        });
    };
    SharememberRemoveComponent.prototype.removeSelectedMembers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, err_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        this.errors = [];
                        this.spinner = true;
                        _a = this;
                        return [4 /*yield*/, this.shareNewService.removeSelected(this.shareData, this.removeMembers, this.remainMembers, this.selectedPurge == '1')];
                    case 1:
                        _a.removeMemberJob = _b.sent();
                        if (this.removeMemberJob.bg == 0) {
                            this.notificationsService.startNotificationLoop();
                        }
                        return [4 /*yield*/, this.postremoveShareMemberAction()];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _b.sent();
                        this.spinner = false;
                        this.errors.push(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SharememberRemoveComponent.prototype.stateChange = function (change) {
        this.stateChangeEvent.next(change);
    };
    return SharememberRemoveComponent;
}());
exports.SharememberRemoveComponent = SharememberRemoveComponent;
