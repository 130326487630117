"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var operators_1 = require("rxjs/operators");
var api_service_1 = require("../../core/api.service");
var logger_service_1 = require("../../core/logger.service");
var multiadmin_service_1 = require("../../core/multiadmin.service");
var user_service_1 = require("../../core/user.service");
var models_1 = require("../../shared/models");
var validate_service_1 = require("../../core/validate.service");
var SharememberAddComponent = /** @class */ (function () {
    function SharememberAddComponent(userService, apiService, loggerService, activeModal, multiadminService, validateService) {
        var _this = this;
        this.userService = userService;
        this.apiService = apiService;
        this.loggerService = loggerService;
        this.activeModal = activeModal;
        this.multiadminService = multiadminService;
        this.validateService = validateService;
        this.errors = [];
        this.isPro = false;
        this.isVerified = false;
        // must be initialized as an array or email validation fails.
        this.contacts = [];
        this.invitedUserIds = [];
        this.permission = '1';
        this.disableValidation = false;
        this.isOnTrial = false;
        this.permissionActions = models_1.PermissionActions;
        this.isRolesAllowed = false;
        this.rolesList = [];
        this.selectedRoleNames = [];
        this.teamUsers = [];
        this.isLoading = true;
        this.search = function (text$) {
            return text$.pipe(operators_1.debounceTime(200), operators_1.map(function (term) { return term === '' ? []
                : _this.contacts.filter(function (v) { return v.toLowerCase().indexOf(term.toLowerCase()) > -1; }).slice(0, 10); }));
        };
    }
    SharememberAddComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isPro = (this.userService.get('is_pro'));
                        this.isVerified = (this.userService.get('is_verified'));
                        this.isOnTrial = (this.userService.get('is_on_trial'));
                        this.apiService.execute('contactlist', {}).then(function (data) {
                            _this.contacts = data.contacts.map(function (val) { return val.value; });
                        });
                        this.isRolesAllowed = this.userService.get('is_multi_new') && this.checkPermission(this.permissionActions.VIEW_ROLES) && this.userService.get('is_unlimited_plan');
                        this.invitedUserIds = this.shareData && this.shareData.people ? this.shareData.people.filter(function (value) { return value.user_status_id === 1; }).map(function (val) { return val.userId; }) : [];
                        if (!this.isRolesAllowed) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getUsersList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getRolesList()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SharememberAddComponent.prototype.permissionChanged = function (newVal) {
        if (newVal == 1) {
            this.sharememberInvite.permissions.perDOWNLOAD = 1;
            this.sharememberInvite.permissions.perUPLOAD = 1;
            this.sharememberInvite.permissions.perSEEOTHERS = 1;
            this.sharememberInvite.permissions.perINVITE = 0;
            this.sharememberInvite.permissions.perADDAPP = 0;
            console.log('set permissions to readwrite');
        }
        else if (newVal == 2) {
            this.sharememberInvite.permissions.perDOWNLOAD = 1;
            this.sharememberInvite.permissions.perUPLOAD = 0;
            this.sharememberInvite.permissions.perSEEOTHERS = 1;
            this.sharememberInvite.permissions.perINVITE = 0;
            this.sharememberInvite.permissions.perADDAPP = 0;
            console.log('set permissions to readonly');
        }
        else {
            this.loggerService.warn('unknown permission set for share');
        }
    };
    SharememberAddComponent.prototype.togglePerm = function (perm) {
        if (perm == 'perUPLOAD' && !this.isPro) {
            this.loggerService.info('PER UPLOAD BUT user is not pro');
            return false;
        }
        this.sharememberInvite.permissions[perm] = (this.sharememberInvite.permissions[perm]) ? 0 : 1;
    };
    SharememberAddComponent.prototype.getContactName = function (email) {
        for (var i = 0, len = this.contacts.length; i < len; i++) {
            if (this.contacts[i].toLowerCase() == email.toLowerCase()) {
                return this.contacts[i];
            }
        }
        return email;
    };
    SharememberAddComponent.prototype.removePerson = function (email) {
        this.sharememberInvite.queue.splice(this.sharememberInvite.queue.indexOf(email.toLowerCase()), 1);
    };
    SharememberAddComponent.prototype.onFocus = function () {
        this.disableValidation = false;
    };
    SharememberAddComponent.prototype.queueInvite = function (f) {
        var _this = this;
        this.disableValidation = true;
        if (!this.emailfield || !f.valid) {
            return false;
        }
        this.errors = [];
        var email;
        email = this.emailfield.toLowerCase();
        if (!this.validateService.isEmail(email)) {
            f.controls['email'].setErrors({ 'email': true });
            return false;
        }
        if (email == this.userService.get('email').toLowerCase()) {
            this.errors.push({
                code: 1650
            });
            this.loggerService.error('cannot queue yourself');
            return;
        }
        if (this.shareData && this.shareData.people && this.shareData.people.length) {
            for (var i = 0, len = this.shareData.people.length; i < len; i++) {
                var person = this.shareData.people[i];
                if (person.email.toLowerCase() == email &&
                    person.status == 'Active') {
                    this.errors.push({
                        code: 1651,
                        msg: "The user " + email + " is already on this share"
                    });
                    this.loggerService.warn('${email}  is already on the share');
                    return false;
                }
            }
        }
        if (this.sharememberInvite.queue.length) {
            this.sharememberInvite.queue.map(function (val) {
                if (val == email) {
                    _this.errors.push({
                        code: 1653,
                        msg: "The user " + email + " is in your invite queue already"
                    });
                    _this.loggerService.error('this person is already queued');
                    return false;
                }
            });
        }
        if (this.errors.length) {
            return false;
        }
        if (this.sharememberInvite.queue.length >= 20) {
            this.errors.push({
                code: 1652
            });
            return;
        }
        this.sharememberInvite.queue.push(email);
        this.emailfield = '';
        this.disableValidation = false;
        return true;
    };
    SharememberAddComponent.prototype.onRoleSelect = function (selectedRoles) {
        var _this = this;
        this.sharememberInvite.roleQueue = [];
        this.sharememberInvite.roleName = '';
        this.selectedRoleNames = selectedRoles.map(function (role) { return role.name; });
        this.sharememberInvite.roleName = this.selectedRoleNames.join(', ');
        this.teamUsers.forEach(function (user) {
            if (_this.selectedRoleNames.includes(user.roles.toString()) && _this.userService.get('email').toLowerCase() != user.email.toLowerCase() && !_this.invitedUserIds.includes(user.user_id) && user.user_status_id === 1) {
                _this.sharememberInvite.roleQueue.push(user.email);
            }
        });
    };
    SharememberAddComponent.prototype.getRolesList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, rolesList, multiSelectItems_1, ex_1, error;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.multiadminService.getRoles()];
                    case 1:
                        response = _a.sent();
                        rolesList = response['roles'];
                        multiSelectItems_1 = [];
                        rolesList.forEach(function (role) {
                            var multiSelectItem;
                            // Excluding current logged in user
                            role.users = role.users.filter(function (user) { return user.user_id != _this.userService.get('id') && !_this.invitedUserIds.includes(user.user_id); });
                            multiSelectItem = role;
                            multiSelectItem.displayName = role.name + " (" + role.users.length + (!role.users.length ? ' Users' :
                                (role.users.length > 1 ? ' Users will be invited' : ' User will be invited')) + ")";
                            multiSelectItem.disabled = role.users.length === 0;
                            multiSelectItems_1.push(multiSelectItem);
                        });
                        this.rolesList = multiSelectItems_1;
                        return [3 /*break*/, 3];
                    case 2:
                        ex_1 = _a.sent();
                        this.loggerService.error(ex_1);
                        error = models_1.ErrCode.fromException(ex_1);
                        this.errors.push({
                            code: error.code,
                            msg: error.msg.toString()
                        });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SharememberAddComponent.prototype.getUsersList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, ex_2, error;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.multiadminService.getUserList()];
                    case 1:
                        result = _a.sent();
                        this.teamUsers = result.users;
                        return [3 /*break*/, 3];
                    case 2:
                        ex_2 = _a.sent();
                        this.loggerService.error(ex_2);
                        error = models_1.ErrCode.fromException(ex_2);
                        this.errors.push({
                            code: error.code,
                            msg: error.msg.toString()
                        });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SharememberAddComponent.prototype.checkPermission = function (permName) {
        return this.userService.checkPermission(permName);
    };
    Object.defineProperty(SharememberAddComponent.prototype, "roleSelectedText", {
        get: function () {
            if (!this.selectedRoleNames.length) {
                return '';
            }
            return "" + this.selectedRoleNames.length + (this.selectedRoleNames.length == 1 ? ' role selected' : ' roles selected');
        },
        enumerable: true,
        configurable: true
    });
    return SharememberAddComponent;
}());
exports.SharememberAddComponent = SharememberAddComponent;
