"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var link_file_list_service_1 = require("../../link-consume/services/link-file-list.service");
var logger_service_1 = require("../../core/logger.service");
var file_item_service_1 = require("../file-item.service");
var dialog_report_link_component_1 = require("../../link-consume/dialog-report-link/dialog-report-link.component");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var sync_cookie_service_1 = require("../../core/sync-cookie.service");
var router_1 = require("@angular/router");
var build_transfer_item_service_1 = require("src/app/transfer/services/build-transfer-item.service");
var PreviewLinkComponent = /** @class */ (function () {
    function PreviewLinkComponent(linkPathListService, loggerService, fileItemService, modalService, syncCookieService, route, router, buildTransferItemService) {
        this.linkPathListService = linkPathListService;
        this.loggerService = loggerService;
        this.fileItemService = fileItemService;
        this.modalService = modalService;
        this.syncCookieService = syncCookieService;
        this.route = route;
        this.router = router;
        this.buildTransferItemService = buildTransferItemService;
        this.list = [];
        this.initialized = false;
        this.notice = 0;
        this.upsell = false;
        this.isPro = 0;
        this.isBusiness = 1; // set default 1 so that it's not shown immediately
        this.compat = 0;
    }
    PreviewLinkComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.prevRouterState = this.router.routeReuseStrategy.shouldReuseRoute;
        this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
        this.locId = this.route.snapshot.params['id'];
        // this.ctx = this.$stateParams.ctx;
        var dispatched = false;
        var rawKey = this.linkPathListService.sanitizeKey(this.route.snapshot.params['key']
            ? this.route.snapshot.params['key']
            : this.route.snapshot.fragment);
        if (!this.type) {
            this.type = this.route.snapshot.params['type'];
        }
        var linkPass = this.syncCookieService.getLinkPassword(this.route.snapshot.params['cachekey']);
        this.initialized = false;
        if (this.type == 'doc' || this.type == 'office' || this.type == 'officefse' || this.type == 'officefsv') {
            // fetch the latest linkpathlisting for the current file
            // the doc/wopi component should have the latest copy of pathitem for preview
            // note: this will only fetch the pathlisting for a single file, not the entire parent folder
            dispatched = true;
            var input = new models_1.LinkPathListApiInput();
            input.sync_id = this.route.snapshot.params['id']; // fetch pathlist for current file only
            input.publink_id = this.route.snapshot.params['cachekey'];
            input.key = rawKey;
            input.passwordlock = linkPass;
            this.linkPathListService.dispatch(input);
        }
        this.subscription = this.linkPathListService
            .getSubscription()
            .subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, params, queryParams, fragment, errorCode, navigationParams, warningMessage, item, streamItem, input, ex_1;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, , 6]);
                        if (data.error) {
                            if (data.error instanceof models_1.ErrCode) {
                                _a = this.route.snapshot, params = _a.params, queryParams = _a.queryParams, fragment = _a.fragment;
                                errorCode = data.error.code;
                                navigationParams = ['/dl', params['cachekey']];
                                if (params['key']) {
                                    navigationParams.splice(2, 0, params['key']);
                                }
                                warningMessage = '';
                                switch (errorCode) {
                                    case 8018:
                                        warningMessage = 'Password is missing or expired';
                                        break;
                                    case 8026:
                                        warningMessage = 'File has been deleted';
                                        break;
                                    case 400:
                                        warningMessage = 'Link has been removed';
                                        break;
                                    default:
                                        this.loggerService.e('An error was received loading link preview', data.error);
                                        throw new models_1.ErrCode(errorCode, data.error.msg);
                                }
                                if (warningMessage) {
                                    this.loggerService.warn(warningMessage);
                                    this.router.navigate(navigationParams, tslib_1.__assign({}, this.linkPathListService.decorateQueryParams(queryParams), this.linkPathListService.decorateFragment(true, fragment)));
                                }
                            }
                        }
                        if (!(data &&
                            data.pathlist &&
                            data.pathlist.length &&
                            data.loaded &&
                            data.sorted)) return [3 /*break*/, 3];
                        this.compat = data.compat;
                        if (!this.compat &&
                            (this.type == 'video' ||
                                this.type == 'doc' ||
                                this.type == 'pdf' ||
                                this.type == 'office' ||
                                this.type == 'officefse' ||
                                this.type == 'officefsv')) {
                            this.type = 'default';
                        }
                        this.list = data.pathlist.filter(function (val) {
                            return _this.fileItemService.isImage(val);
                        });
                        item = data.pathlist.filter(function (value) {
                            if (value.sync_id == _this.route.snapshot.params['id']) {
                                return true;
                            }
                        });
                        if (item.length == 0) {
                            throw new models_1.ErrCode(1804);
                        }
                        else if (item.length == 1) {
                            this.item = item[0];
                        }
                        else {
                            throw new models_1.ErrCode(1000, 'This file listing has an error');
                        }
                        if (!this.compat) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.buildTransferItemService.getStreamingDetails(this.item)];
                    case 1:
                        streamItem = _b.sent();
                        if (streamItem.url && streamItem.mimeType) {
                            this.type = 'video';
                            this.url = streamItem.url;
                            this.mimeType = streamItem.mimeType;
                        }
                        _b.label = 2;
                    case 2:
                        if ((this.type != 'default' && this.type != 'office' && this.type != 'officefse' && this.type != 'officefsv') &&
                            !this.fileItemService.isPreviewable(this.item) &&
                            !this.mimeType) {
                            this.type = 'default';
                            this.notice = 1;
                        }
                        this.isPro = data.is_pro;
                        this.isBusiness = data.is_business;
                        this.initialized = true;
                        return [3 /*break*/, 4];
                    case 3:
                        if (!dispatched) {
                            dispatched = true;
                            input = new models_1.LinkPathListApiInput();
                            input.sync_id = this.route.snapshot.queryParams['sync_id'];
                            input.publink_id = this.route.snapshot.params['cachekey'];
                            input.key = rawKey;
                            input.passwordlock = linkPass;
                            this.linkPathListService.dispatch(input);
                            this.loggerService.info('Dispatched');
                        }
                        _b.label = 4;
                    case 4:
                        //get white label attributes
                        if (data.loaded && data.sorted) {
                            if (data.image_cachekey) {
                                this.isWhiteLabel = true;
                                this.linkTextColor = data.link_text_color;
                                this.buttonPrimaryColor = data.button_primary_color;
                                this.buttonTextColor = data.button_text_color;
                            }
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        ex_1 = _b.sent();
                        this.loggerService.e('Cannot process link', ex_1);
                        this.type = 'error';
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        this.initialized = true;
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); });
    };
    PreviewLinkComponent.prototype.onStateChange = function (event) {
        this.type = event.type;
        this.upsell = event.upsell;
    };
    PreviewLinkComponent.prototype.showReportDialog = function () {
        var ref = this.modalService.open(dialog_report_link_component_1.DialogReportLinkComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.linkID = this.route.snapshot.params['cachekey'];
    };
    PreviewLinkComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.router.routeReuseStrategy.shouldReuseRoute = this.prevRouterState;
    };
    return PreviewLinkComponent;
}());
exports.PreviewLinkComponent = PreviewLinkComponent;
