"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var apps_service_1 = require("../../apps/services/apps.service");
var logger_service_1 = require("../../core/logger.service");
var file_list_service_1 = require("../../file-list/services/file-list.service");
var share_service_1 = require("../share.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var router_1 = require("@angular/router");
var DialogShareAppListComponent = /** @class */ (function () {
    function DialogShareAppListComponent(appsService, loggerService, shareService, fileListService, activeModal, router) {
        this.appsService = appsService;
        this.loggerService = loggerService;
        this.shareService = shareService;
        this.fileListService = fileListService;
        this.activeModal = activeModal;
        this.router = router;
        this.errors = [];
        this.spinners = {
            loading: false,
            enabling: false,
            disabling: false,
        };
        this.apps = [];
    }
    DialogShareAppListComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.spinners.loading = true;
                        this.currentItem = this.item;
                        return [4 /*yield*/, this.checkShare()];
                    case 1:
                        _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.appsService.readApps(this.shareId)];
                    case 2:
                        _a.apps = _b.sent();
                        this.spinners.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogShareAppListComponent.prototype.checkShare = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var share, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.item.is_shared) return [3 /*break*/, 5];
                        this.loggerService.info('Loading apps for a Shared dir');
                        this.shareId = this.item.share_id;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.shareService.getShare(this.shareId)];
                    case 2:
                        share = _a.sent();
                        this.shareData = share;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.handleErr(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this.loggerService.info('Loading apps for an unshared dir');
                        // This will let us simply get the apps from a user
                        this.shareId = 0;
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    DialogShareAppListComponent.prototype.checkIfRemovingAllActiveApps = function (apps) {
        if (apps) {
            for (var _i = 0, apps_1 = apps; _i < apps_1.length; _i++) {
                var cur = apps_1[_i];
                if (cur.enabled && !cur.selected) {
                    return false;
                }
            }
            return true;
        }
        else {
            return false;
        }
    };
    DialogShareAppListComponent.prototype.getSelectedAmtApps = function (apps) {
        var sum = 0;
        if (!apps) {
            return 0;
        }
        for (var _i = 0, apps_2 = apps; _i < apps_2.length; _i++) {
            var cur = apps_2[_i];
            sum += cur.selected ? 1 : 0;
        }
        return sum;
    };
    DialogShareAppListComponent.prototype.getEnabledAmtApps = function (apps) {
        var sum = 0;
        if (!apps) {
            return 0;
        }
        for (var _i = 0, apps_3 = apps; _i < apps_3.length; _i++) {
            var cur = apps_3[_i];
            sum += cur.enabled ? 1 : 0;
        }
        return sum;
    };
    DialogShareAppListComponent.prototype.enableApps = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_2, err_3, shareId, err_4, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.spinners.enabling = true;
                        return [4 /*yield*/, this.checkShare()];
                    case 1:
                        _b.sent();
                        if (this.item.is_shared && this.shareData) {
                            if (this.shareData.isAddApp === 0 && this.shareData.isOwner === 0) {
                                this.errors.push({
                                    code: 11203,
                                    msg: 'You Do not have permission to add apps to this share.',
                                });
                                this.spinners.enabling = false;
                                return [2 /*return*/];
                            }
                        }
                        if (!(this.getEnabledAmtApps(this.apps) > 0)) return [3 /*break*/, 2];
                        if (!this.item.is_app_share) {
                            this.loggerService.error('Sync Type (stAPP) isn\'t set. Not enabling more apps');
                            this.errors.push({ code: 11202 });
                            this.spinners.enabling = false;
                            return [2 /*return*/];
                        }
                        else if (!this.shareData || !this.shareData.app_integrated) {
                            this.loggerService.error('Share Type (shAPP) isn\'t set. Not enabling more apps');
                            this.errors.push({ code: 11201 });
                            this.spinners.enabling = false;
                            return [2 /*return*/];
                        }
                        else {
                            this.loggerService.info('Share and Webpaths look good. Enabling apps');
                        }
                        return [3 /*break*/, 18];
                    case 2:
                        if (!(this.shareData && this.shareData.app_integrated)) return [3 /*break*/, 9];
                        if (!this.item.is_app_share) return [3 /*break*/, 3];
                        // Even though no apps are active, somehow the sync type is already set.
                        // Its an error but lets not throw.
                        this.loggerService.error('Sync Type (stAPP) shouldn\'t be set if no apps are enabled!');
                        return [3 /*break*/, 8];
                    case 3:
                        this.loggerService.info('Setting user\'s Sync Type (share already exists). Enabling apps');
                        _b.label = 4;
                    case 4:
                        _b.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this.appsService.addAppToExistingShare(this.item.sync_id, this.shareId)];
                    case 5:
                        _b.sent();
                        this.item.is_app_share = 1;
                        return [3 /*break*/, 7];
                    case 6:
                        err_2 = _b.sent();
                        this.handleErr(err_2);
                        return [2 /*return*/];
                    case 7:
                        this.refreshPage();
                        _b.label = 8;
                    case 8: return [3 /*break*/, 18];
                    case 9:
                        if (!this.item.is_shared) return [3 /*break*/, 14];
                        this.loggerService.info('Adding app share to existing share. Enabling apps');
                        _b.label = 10;
                    case 10:
                        _b.trys.push([10, 12, , 13]);
                        return [4 /*yield*/, this.appsService.addAppToExistingShare(this.item.sync_id, this.shareId)];
                    case 11:
                        _b.sent();
                        return [3 /*break*/, 13];
                    case 12:
                        err_3 = _b.sent();
                        this.handleErr(err_3);
                        return [2 /*return*/];
                    case 13:
                        this.item.is_app_share = 1;
                        this.refreshPage();
                        return [3 /*break*/, 18];
                    case 14:
                        this.loggerService.info("Creating share on " + this.item.sync_id + " and " + this.item.name + ". Enabling apps");
                        _b.label = 15;
                    case 15:
                        _b.trys.push([15, 17, , 18]);
                        return [4 /*yield*/, this.appsService.addAppAndCreateShare(this.item.sync_id, this.item.name)];
                    case 16:
                        shareId = _b.sent();
                        this.shareId = shareId;
                        this.item.is_app_share = 1;
                        this.item.is_shared = 1;
                        this.item.is_hide_share = 1;
                        this.item.share_id = this.shareId;
                        return [3 /*break*/, 18];
                    case 17:
                        err_4 = _b.sent();
                        this.handleErr(err_4);
                        return [2 /*return*/];
                    case 18:
                        _a = this;
                        return [4 /*yield*/, this.appsService.writeApps(this.shareId, this.apps, true)];
                    case 19:
                        _a.apps = _b.sent();
                        this.spinners.enabling = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogShareAppListComponent.prototype.disableApps = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_5, apps, err_6, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.spinners.disabling = true;
                        return [4 /*yield*/, this.checkShare()];
                    case 1:
                        _b.sent();
                        if (!(this.item.is_shared && this.shareData)) return [3 /*break*/, 13];
                        if (!this.checkIfRemovingAllActiveApps(this.apps)) return [3 /*break*/, 10];
                        this.loggerService.info("Removing all active apps on share.");
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        // We need to remove the PERSONAL stAPP
                        return [4 /*yield*/, this.appsService.disableAppShare(this.item.sync_id)];
                    case 3:
                        // We need to remove the PERSONAL stAPP
                        _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        err_5 = _b.sent();
                        this.handleErr(err_5);
                        return [2 /*return*/];
                    case 5:
                        this.item.is_app_share = 0;
                        _b.label = 6;
                    case 6:
                        _b.trys.push([6, 8, , 9]);
                        return [4 /*yield*/, this.appsService.writeApps(this.shareId, this.apps, false)];
                    case 7:
                        apps = _b.sent();
                        this.apps = apps;
                        return [3 /*break*/, 9];
                    case 8:
                        err_6 = _b.sent();
                        this.handleErr(err_6);
                        return [2 /*return*/];
                    case 9:
                        this.refreshPage();
                        return [3 /*break*/, 12];
                    case 10:
                        this.loggerService.info("Removing some apps, other apps remain active. Leaving share intact.");
                        // No Op, we don't need to worry about ripping out the app share
                        _a = this;
                        return [4 /*yield*/, this.appsService.writeApps(this.shareId, this.apps, false)];
                    case 11:
                        // No Op, we don't need to worry about ripping out the app share
                        _a.apps = _b.sent();
                        _b.label = 12;
                    case 12: return [3 /*break*/, 14];
                    case 13:
                        this.loggerService.warn('Share doesn\'t exist, can\'t remove apps from nothing.');
                        if (this.getEnabledAmtApps(this.apps) > 0) {
                            this.loggerService.error('Apps should not be enabled since share doesn\'t exist');
                            this.errors.push({
                                code: 11201,
                                msg: 'Cannot disable app as share doesn\'t exit. Please contact support.',
                            });
                        }
                        _b.label = 14;
                    case 14:
                        this.spinners.disabling = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogShareAppListComponent.prototype.handleErr = function (errData) {
        this.loggerService.error("Dialog Share App List - Got error " + JSON.stringify(errData));
        if (typeof errData == 'object' && errData.error_code) {
            this.errors.push(errData);
        }
        else if (typeof errData == 'object' && errData.code) {
            // Legacy (?) error
            this.errors.push(errData);
        }
        else {
            this.errors.push({
                code: 1000,
                msg: 'An unknown error occured. Please contact support.',
            });
        }
    };
    DialogShareAppListComponent.prototype.refreshPage = function () {
        this.fileListService.reload();
        this.router.navigate(['/apps']);
    };
    DialogShareAppListComponent.prototype.closeModal = function () {
        this.activeModal.close();
    };
    return DialogShareAppListComponent;
}());
exports.DialogShareAppListComponent = DialogShareAppListComponent;
