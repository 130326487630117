"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var forms_1 = require("@angular/forms");
var FieldControlService = /** @class */ (function () {
    function FieldControlService() {
    }
    FieldControlService.prototype.toFormGroup = function (fields) {
        var group = {};
        fields.forEach(function (field) {
            group[field.key] = field.required ? new forms_1.FormControl({ value: field.value, disabled: field.disabled }, forms_1.Validators.required)
                : new forms_1.FormControl({ value: field.value, disabled: field.disabled });
        });
        return new forms_1.FormGroup(group);
    };
    return FieldControlService;
}());
exports.FieldControlService = FieldControlService;
