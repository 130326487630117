"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var forms_1 = require("@angular/forms");
var base64_service_1 = require("../../core/base64.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var api_service_1 = require("../../core/api.service");
var DialogAcctholdCancelComponent = /** @class */ (function () {
    function DialogAcctholdCancelComponent(activeModal, api, base64, fb) {
        this.activeModal = activeModal;
        this.api = api;
        this.base64 = base64;
        this.fb = fb;
    }
    DialogAcctholdCancelComponent.prototype.ngOnInit = function () {
        this.createForm();
    };
    DialogAcctholdCancelComponent.prototype.createForm = function () {
        this.cancelForm = this.fb.group({
            subject: ['', forms_1.Validators.required],
            description: ['', forms_1.Validators.required],
            confirm: ['', forms_1.Validators.required]
        });
    };
    DialogAcctholdCancelComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.spinner = true;
                        return [4 /*yield*/, this.api.send('acctcancel', {
                                source: 'accthold',
                                user: this.userId,
                                p: this.plan,
                                sig: this.sig,
                                subject: this.base64.encode(this.cancelForm.get('subject').value),
                                message: this.base64.encode(this.cancelForm.get('description').value)
                            })];
                    case 1:
                        _a.sent();
                        window.location.href = 'https://www.sync.com/support/?thank=you';
                        return [3 /*break*/, 3];
                    case 2:
                        ex_1 = _a.sent();
                        this.spinner = false;
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return DialogAcctholdCancelComponent;
}());
exports.DialogAcctholdCancelComponent = DialogAcctholdCancelComponent;
