"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var asmCrypto = require("asmcrypto.js");
var logger_service_1 = require("../logger.service");
var models_1 = require("../../shared/models");
var i0 = require("@angular/core");
var i1 = require("../logger.service");
// seed the crypto as soon as it's imported
seedCrypto();
function seedCrypto() {
    var seed = false;
    function randString() {
        var text = [];
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (var i = 0; i < 128; i++) {
            text.push(possible.charAt(Math.floor(Math.random() * possible.length)));
        }
        return text.join('');
    }
    //start up the random number seeding
    var crypto = window.crypto || window.msCrypto;
    var buffer, ArrayView;
    try {
        if (Uint8Array) {
            asmCrypto.random.skipSystemRNGWarning = true;
            while (!seed) {
                buffer = new Int32Array(2);
                buffer[0] = Date.now();
                buffer[1] = Math.random();
                // buffer[2] = $window.performance.now();
                seed = asmCrypto.random.seed(buffer);
                var ab = new Uint8Array(96);
                seed = asmCrypto.random.seed(window.crypto.getRandomValues(ab));
                seed = asmCrypto.random.seed(asmCrypto.string_to_bytes(randString()));
                seed = asmCrypto.random.seed(asmCrypto.string_to_bytes(randString()));
            }
            asmCrypto.random.skipSystemRNGWarning = false;
            ArrayView = new Uint32Array(32);
            crypto.getRandomValues(ArrayView);
        }
        // asmCrypto.random.getValues.skipSystemRNGWarning = true;
    }
    catch (ex) {
    }
}
/* tslint:disable:no-bitwise */
var CryptBufferService = /** @class */ (function () {
    function CryptBufferService(Logger) {
        this.Logger = Logger;
        var seed = false;
        asmCrypto.random.skipSystemRNGWarning = true;
        while (!seed) {
            if (window.crypto && window.crypto.getRandomValues) {
                var ab = new Uint8Array(96);
                seed = asmCrypto.random.seed(window.crypto.getRandomValues(ab));
            }
            else {
                var buffer = new Int32Array(2);
                buffer[0] = Date.now();
                buffer[1] = Math.random();
                seed = asmCrypto.random.seed(buffer);
            }
        }
        asmCrypto.random.skipSystemRNGWarning = false;
    }
    CryptBufferService.prototype.bytesToB64 = function (bits) {
        return asmCrypto.bytes_to_base64(bits);
    };
    CryptBufferService.prototype.bytesToString = function (bits) {
        return asmCrypto.bytes_to_string(bits);
    };
    CryptBufferService.prototype.bytesToHex = function (bits) {
        return asmCrypto.bytes_to_hex(bits);
    };
    CryptBufferService.prototype.hexToBytes = function (hex) {
        return asmCrypto.hex_to_bytes(hex);
    };
    CryptBufferService.prototype.stringToBytes = function (str) {
        return asmCrypto.string_to_bytes(str);
    };
    CryptBufferService.prototype.b64ToBytes = function (b64) {
        return asmCrypto.base64_to_bytes(b64);
    };
    /**
     * Encrypts data using symmetric encryption via asmCrypto
     * @param  {Uint8Array} key    The AES key
     * @param  {Uint8Array} plain  [description]
     * @param  {Uint8Array} iv     [description]
     * @param  {Uint8Array|null} header [description]
     * @return {Promise}        [description]
     */
    CryptBufferService.prototype.symmetricEncrypt = function (key, plain, iv, header) {
        try {
            if (!header) {
                header = new Uint8Array(0);
            }
            var data = asmCrypto.AES_GCM.encrypt(plain, key, iv, header, 12);
            var len = header.length + iv.length + data.length;
            var result = new Uint8Array(len);
            result.set(header, 0);
            result.set(iv, header.length);
            result.set(data, header.length + iv.length);
            return Promise.resolve(result);
        }
        catch (ex) {
            this.Logger.error(ex.toString());
            this.Logger.error('SyncCryptBuffer.symmetricEncrypt() failed');
            throw new models_1.ErrCode(2501);
        }
    };
    /**
     * Encrypts data using symmetric encryption via asmCrypto
     * @param  {Uint8Array} key    The AES key
     * @param  {Uint8Array} crypted  [description]
     * @param  {Uint8Array} iv     [description]
     * @param  {Uint8Array|null} header [description]
     * @return {Promise}        [description]
     */
    CryptBufferService.prototype.symmetricDecrypt = function (key, crypted, iv, header) {
        try {
            if (!header) {
                header = new Uint8Array(0);
            }
            return Promise.resolve(asmCrypto.AES_GCM.decrypt(crypted, key, iv, header, 12));
        }
        catch (ex) {
            this.Logger.error('SyncCryptBuffer.symmetricDecrypt() failed');
            this.Logger.error(ex.toString());
            throw new models_1.ErrCode(2502);
        }
    };
    /**
     * Decrypts data using a string password that is keystretched
     * @param  {String} b64_crypted A num-prefixed b64 string
     * @param  {String|Uint8Array} password    The AES key
     * @param  {Integer} iterations  iterations for keystretch
     * @return {Promise}        [description]
     */
    CryptBufferService.prototype.passwordDecrypt = function (b64_crypted, password, iterations) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var raw, salt, iv, rawbytes, key, decBytes;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(b64_crypted.substring(0, 3) === '30:')) return [3 /*break*/, 3];
                        raw = asmCrypto.base64_to_bytes(b64_crypted.substring(3)), salt = this.getPartialBytes(raw, 0, 96), iv = this.getPartialBytes(raw, 96, 192), rawbytes = this.getPartialBytes(raw, 192);
                        return [4 /*yield*/, this.keyStretch(password, salt, iterations, 256)];
                    case 1:
                        key = _a.sent();
                        return [4 /*yield*/, this.symmetricDecrypt(key, rawbytes, iv)];
                    case 2:
                        decBytes = _a.sent();
                        return [2 /*return*/, asmCrypto.bytes_to_string(decBytes)];
                    case 3:
                        this.Logger.error('SyncCryptBuffer.passwordDecrypt() failed');
                        this.Logger.error('Badly formatted password');
                        throw new models_1.ErrCode(9000, 'badly formatted passwd encrypted string ' + b64_crypted);
                }
            });
        });
    };
    /**
     * Encrypts data using a string password that is keystretched
     * @param  {String} plain A num-prefixed b64 string
     * @param  {String|Uint8Array} password    The AES key
     * @param  {Integer} iterations  iterations for keystretch
     * @return {Promise}
     */
    CryptBufferService.prototype.passwordEncrypt = function (plain, password, iterations) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var salt, key, iv, encData, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        salt = this.getRandom(96);
                        return [4 /*yield*/, this.keyStretch(password, salt, iterations, 256)];
                    case 1:
                        key = _a.sent();
                        iv = this.getRandom(3 * 32);
                        return [4 /*yield*/, this.symmetricEncrypt(key, this.stringToBytes(plain), iv)];
                    case 2:
                        encData = _a.sent();
                        result = new Uint8Array(salt.length + encData.length);
                        result.set(salt);
                        result.set(encData, salt.length);
                        return [2 /*return*/, '30:' + asmCrypto.bytes_to_base64(result)];
                }
            });
        });
    };
    /**
     * Stretches data, uses higher iteration count and runs it in a worker
     * to prevent browser lockups
     * @todo Combine this with keystretch and use the iteration size
     *       to determine which method to use.
     * @param  {String|Uint8Array} password   [description]
     * @param  {Uint8Array} salt       [description]
     * @param  {Integer} iterations [description]
     * @param  {Integer} length     [description]
     * @return {Promise}            [description]
     */
    CryptBufferService.prototype.keyStretchSlow = function (password, salt, iterations, length) {
        // if (window.Worker) {
        //     this.KeystretchWorker.init();
        //     let passBytes = this.stringToBytes(password);
        //     return this.KeystretchWorker.run(
        //                         passBytes,
        //                         salt,
        //                         iterations,
        //                         length / 8).
        //                 then((result) => {
        //                     this.KeystretchWorker.completed();
        //                     return this.$q.when(result);
        //                 });
        // } else {
        return this.keyStretch(password, salt, iterations, length);
        // }
    };
    /**
     * Stretches data quickly
     * @todo Combine this with keystretch and use the iteration size
     *       to determine which method to use.
     * @param  {String|Uint8Array} password   [description]
     * @param  {Uint8Array} salt       [description]
     * @param  {Integer} iterations [description]
     * @param  {Integer} length     [description]
     * @return {Promise}            [description]
     */
    CryptBufferService.prototype.keyStretch = function (password, salt, iterations, length) {
        var passBytes = this.stringToBytes(password);
        return Promise.resolve(asmCrypto.PBKDF2_HMAC_SHA256.bytes(passBytes, salt, iterations || 60000, length / 8));
    };
    /**
     * Gets a SHA1 HMAC of the data using key as secret
     * @param  {Uint8Array} key  [description]
     * @param  {Uint8Array} data [description]
     * @return {Promise}      [description]
     */
    CryptBufferService.prototype.getApiHmac = function (key, data) {
        return Promise.resolve(asmCrypto.HMAC_SHA1.bytes(data, key));
    };
    /**
     * Gets random bits.
     * @param  {Integer} bits The bit amount
     * @return {Uint8Array}      [description]
     */
    CryptBufferService.prototype.getRandom = function (bits) {
        if (bits % 32 !== 0) {
            this.Logger.error('Bytes is not modulus 32');
            throw new Error('getRandom(bits) not a modules of 32');
        }
        var buf = new Int8Array(bits / 8);
        var rand = (window && window.crypto && window.crypto.getRandomValues && Int8Array) ?
            window.crypto.getRandomValues(buf) :
            asmCrypto.random.getValues(buf);
        return new Uint8Array(rand);
    };
    /**
     * Gets partial bits from the given data source.
     * @param  {Uint8Array} array     [description]
     * @param  {Integer} byteStart [description]
     * @param  {Integer} byteEnd   [description]
     * @return {Uint8Array}           [description]
     */
    CryptBufferService.prototype.getPartialBytes = function (data, byteStart, byteEnd) {
        byteStart = byteStart / 8;
        byteEnd = (byteEnd) ? byteEnd = byteEnd / 8 : data.length;
        //     console.log(typeof data.slice)
        //                 console.log(typeof data);
        //     console.log(data.toString());
        // if (!data) {
        //     console.log(typeof data);
        //     console.log(data.toString());
        //     throw "data is null!";
        // }
        return data.subarray(byteStart, byteEnd);
    };
    /**
     * @ngdoc method
     * @name  unpackHeader
     * @methodOf sync.service:SyncCryptBuffer
     * @description
     * Unpacks the header array and returns the encrypted offset stored within
     * @param  {Uint8Array} header     [description]
     * @return {Integer}           [description]
     */
    CryptBufferService.prototype.unpackHeader = function (header) {
        var a = new Uint8Array(header);
        var hi = (a[2] << 8) | (a[3] << 0);
        var lo = (a[4] << 24) | (a[5] << 16) | (a[6] << 8) | (a[7] << 0);
        // force to floating point
        hi = hi * 1.0;
        lo = lo * 1.0;
        return hi * (0xFFFFFFFF + 1) + lo;
    };
    /**
     * Packs a header for AES and embeds the offset in the first 8 bytes of the
     * header.  The number is encoded as an 8 byte array with the most significant
     * digit at index 0 and least significant at index 7.
     *
     * @param {Integer} offset the offsset.
     * @return {Uint8Array} a typed array
    */
    CryptBufferService.prototype.packHeader = function (offset) {
        var hi = Math.floor(offset / 0xFFFFFFFF), lo = offset | 0x0;
        if (hi > 0xFF) {
            throw new Error('offset is too big (max = 2^40)');
        }
        var a = 0 |
            (hi & 0xFF000000) |
            (hi & 0x00FF0000) |
            (hi & 0x0000FF00) |
            (hi & 0x000000FF);
        var b = 0 |
            (lo & 0xFF000000) |
            (lo & 0x00FF0000) |
            (lo & 0x0000FF00) |
            (lo & 0x000000FF);
        var dv = new DataView(new ArrayBuffer(12));
        dv.setInt32(0, a);
        dv.setInt32(4, b);
        var result = new Uint8Array(new ArrayBuffer(12));
        for (var i = 0; i < 12; i++) {
            result[i] = dv.getUint8(i);
        }
        return result;
    };
    /**
     * Initializes the SHA1 object
     * @return {asmCrypto.SHA1} [description]
     */
    CryptBufferService.prototype.sha1init = function () {
        return new asmCrypto.SHA1();
    };
    /**
     * Updates a sha1 with more data
     * @param  {asmCrypto.SHA1} sha1Obj [description]
     * @return {asmCrypto.SHA1}         [description]
     */
    CryptBufferService.prototype.sha1reset = function (sha1Obj) {
        return sha1Obj.reset();
    };
    /**
     * Updates a sha1 with more data
     * @param  {asmCrypto.SHA1} sha1Obj [description]
     * @param  {Uint8Array} data    [description]
     * @return {asmCrypto.SHA1}         [description]
     */
    CryptBufferService.prototype.sha1update = function (sha1Obj, data) {
        return sha1Obj.process(data);
    };
    /**
     * Finishes and gets the sha1 result
     * @param  {asmCrypto.SHA1} sha1Obj [description]
     * @return {Uint8Array}         [description]
     */
    CryptBufferService.prototype.sha1finish = function (sha1Obj) {
        sha1Obj.finish();
        return sha1Obj.result;
    };
    /**
     * This function doesn't do anything.  It's needed because legacy (sjcl)
     * requires it's own "bitArray" instead of array buffers.
     * @param  {ArrayBuffer} buffer [description]
     * @return {Uint8Array}        [description]
     */
    CryptBufferService.prototype.arraybufferToBytes = function (buffer) {
        return new Uint8Array(buffer);
    };
    /**
     * Prepares data to be sent.  Since this encryption method uses 8bit
     * integers, it doesn't do anything.  This function is needed for
     * compatibility for Legacy.
     * @param  {Uint8Array} data [description]
     * @return {Uint8Array}      [description]
     */
    CryptBufferService.prototype.prepareDataSend = function (data) {
        return data;
    };
    /**
     * Appends file data when uploading/downloading creating a new
     * result.  This will return a new typed array with the old data
     * appended at the end.
     * @param  {Uint8Array} payload         [description]
     * @param  {Uint8Array} appendedPayload [description]
     * @return {Uint8Array}                 [description]
     */
    CryptBufferService.prototype.filedataAppend = function (appendedPayload, maxLength) {
        var result = new Uint8Array(maxLength);
        var offset = 0;
        for (var i = 0; i < appendedPayload.length; i++) {
            result.set(appendedPayload[i], offset);
            offset += appendedPayload[i].length;
        }
        return result;
    };
    /**
     * Verifies that the data is correct
     * @param  {Uint8Array} payload         [description]
     * @param  {number} bits [description]
     * @return {boolean}                 [description]
     */
    CryptBufferService.prototype.checkBitLength = function (data, bits) {
        return (data.byteLength * 8 === bits);
    };
    CryptBufferService.ngInjectableDef = i0.defineInjectable({ factory: function CryptBufferService_Factory() { return new CryptBufferService(i0.inject(i1.LoggerService)); }, token: CryptBufferService, providedIn: "root" });
    return CryptBufferService;
}());
exports.CryptBufferService = CryptBufferService;
