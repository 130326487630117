"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var FileListActions = require("../../actions/file-list.actions");
var user_service_1 = require("../../core/user.service");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var i2 = require("../../core/user.service");
var FileSelectionService = /** @class */ (function () {
    function FileSelectionService(store, userService) {
        this.store = store;
        this.userService = userService;
        this.init();
    }
    FileSelectionService.prototype.init = function () {
        var _this = this;
        this.watchSelected()
            .subscribe(function (result) {
            _this.selected = result;
        });
    };
    FileSelectionService.prototype.watchSelected = function () {
        return this.store.select(fromRoot.getFileListSelected);
    };
    FileSelectionService.prototype.getSelected = function () {
        return this.selected;
    };
    FileSelectionService.prototype.select = function (item, key, value) {
        if (key === void 0) { key = 'sync_id'; }
        return this.store.dispatch(new FileListActions.FileListSelectAction(item));
    };
    FileSelectionService.prototype.selectType = function (key, value, filteredIds) {
        return this.store.dispatch(new FileListActions.FileListSelectTypeAction({
            key: key,
            value: value,
            filteredIds: filteredIds
        }));
    };
    FileSelectionService.prototype.toggle = function (item) {
        return this.store.dispatch(new FileListActions.FileListSelectToggleAction(item));
    };
    FileSelectionService.prototype.selectAll = function (active, filteredIds) {
        if (active === void 0) { active = false; }
        return this.store.dispatch(new FileListActions.FileListSelectAllAction({
            active: active,
            filteredIds: filteredIds
        }));
    };
    FileSelectionService.ngInjectableDef = i0.defineInjectable({ factory: function FileSelectionService_Factory() { return new FileSelectionService(i0.inject(i1.Store), i0.inject(i2.UserService)); }, token: FileSelectionService, providedIn: "root" });
    return FileSelectionService;
}());
exports.FileSelectionService = FileSelectionService;
