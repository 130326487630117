"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AlertMessageType;
(function (AlertMessageType) {
    AlertMessageType["INFO"] = "info";
    AlertMessageType["ERROR"] = "error";
})(AlertMessageType = exports.AlertMessageType || (exports.AlertMessageType = {}));
var AlertMessage = /** @class */ (function () {
    function AlertMessage(message, type) {
        if (type === void 0) { type = AlertMessageType.INFO; }
        this.message = message;
        this.type = type;
    }
    return AlertMessage;
}());
exports.AlertMessage = AlertMessage;
