"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StopPropagationDirective = /** @class */ (function () {
    function StopPropagationDirective() {
    }
    StopPropagationDirective.prototype.click = function (event) {
        event.stopPropagation();
    };
    return StopPropagationDirective;
}());
exports.StopPropagationDirective = StopPropagationDirective;
