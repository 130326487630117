"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var broadcast_service_1 = require("../../shared/services/broadcast.service");
var FilterListComponent = /** @class */ (function () {
    function FilterListComponent(broadcastService) {
        this.broadcastService = broadcastService;
        this.filterToggleChange = new core_1.EventEmitter();
        this.filterTextChange = new core_1.EventEmitter();
    }
    FilterListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.broadcastService.on('event:keydown:70').subscribe(function (evt) { return _this.handleSearch(evt); });
        this.broadcastService.on('event:hide.tools').subscribe(function () { return _this.resetFilter(); });
    };
    FilterListComponent.prototype.resetFilter = function () {
        this.filterToggle = false;
        this.filterText = undefined;
        this.filterToggleChange.emit(false);
        this.filterTextChange.emit(undefined);
    };
    FilterListComponent.prototype.filterTextData = function (event) {
        this.filterTextChange.emit(event.target.value);
    };
    FilterListComponent.prototype.handleSearch = function (evt) {
        if (evt.ctrlKey && evt.shiftKey) {
            this.filterToggle = !this.filterToggle;
            this.filterToggleChange.emit(this.filterToggle);
        }
    };
    return FilterListComponent;
}());
exports.FilterListComponent = FilterListComponent;
