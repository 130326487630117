"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dir_picker_service_1 = require("../../file-list/dir-picker.service");
var DialogDirPickerComponent = /** @class */ (function () {
    function DialogDirPickerComponent(dirPickerService, activeModal) {
        this.dirPickerService = dirPickerService;
        this.activeModal = activeModal;
    }
    DialogDirPickerComponent.prototype.ngOnInit = function () {
        this.dirPickerService.init(this.dirPickerService.INIT_ALL);
        this.dirPickerService.selectByName('sync');
    };
    DialogDirPickerComponent.prototype.getDestination = function () {
        return this.dirPickerService.getDestination();
    };
    DialogDirPickerComponent.prototype.closeDialog = function () {
        this.activeModal.close(this.dirPickerService.view.selected);
    };
    return DialogDirPickerComponent;
}());
exports.DialogDirPickerComponent = DialogDirPickerComponent;
