"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Actions = require("../actions/user-list.actions");
exports.initialState = {
    list: [],
};
function reducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case Actions.USER_LIST_SUCCESS:
            return Object.assign({}, state, {
                list: action.payload
            });
        case Actions.USER_LIST_SELECT_TOGGLE:
            var origToggle = state.list.slice(0);
            for (var i = 0; i < origToggle.length; i++) {
                if (origToggle[i].user_id == action.payload.user_id) {
                    origToggle[i].active = !origToggle[i].active;
                }
            }
            return Object.assign({}, state, {
                list: origToggle
            });
        case Actions.USER_LIST_SELECT_ALL:
            var origAll = state.list.slice(0);
            for (var i = 0; i < origAll.length; i++) {
                origAll[i].active = action.payload.active;
            }
            return Object.assign({}, state, {
                list: origAll
            });
        case Actions.USER_LIST_SELECT_ALL_ACTIVE:
            var origAllActive = state.list.slice(0);
            for (var i = 0; i < origAllActive.length; i++) {
                if (origAllActive[i].user_status_str.toLowerCase() === 'active') {
                    origAllActive[i].active = true;
                }
                else {
                    origAllActive[i].active = false;
                }
            }
            return Object.assign({}, state, {
                list: origAllActive
            });
        case Actions.USER_LIST_SELECT_ALL_INVITED:
            var origAllInvited = state.list.slice(0);
            for (var i = 0; i < origAllInvited.length; i++) {
                if (origAllInvited[i].user_status_str.toLowerCase() !== 'active') {
                    origAllInvited[i].active = true;
                }
                else {
                    origAllInvited[i].active = false;
                }
            }
            return Object.assign({}, state, {
                list: origAllInvited
            });
        default:
            return state;
    }
}
exports.reducer = reducer;
