"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EmailLinkInvite = /** @class */ (function () {
    function EmailLinkInvite(email, msg, displayname, cachekey, linkname, linkUrl) {
        this.email = email;
        this.msg = msg;
        this.displayname = displayname;
        this.cachekey = cachekey;
        this.linkname = linkname;
        this.linkUrl = linkUrl;
    }
    return EmailLinkInvite;
}());
exports.EmailLinkInvite = EmailLinkInvite;
