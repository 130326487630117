"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var notifications_service_1 = require("../../core/notifications.service");
var feedback_service_1 = require("../feedback.service");
var user_service_1 = require("../../core/user.service");
var NotificationsComponent = /** @class */ (function () {
    function NotificationsComponent(feedbackService, notificationsService, userService) {
        this.feedbackService = feedbackService;
        this.notificationsService = notificationsService;
        this.userService = userService;
        this.featureBanner = false;
    }
    NotificationsComponent.prototype.ngOnInit = function () {
        this.notifications = this.notificationsService.settings;
        var plantype_id = this.userService.get('plantype_id');
        this.featureBanner = plantype_id && plantype_id > 1 && localStorage.getItem('collaborativeLinks') == 'true' ? true : false;
        this.feedbackView = this.feedbackService.view;
        this.notificationsService.startNotificationLoop();
    };
    NotificationsComponent.prototype.restartNotifications = function () {
        return this.notificationsService.startNotificationLoop();
    };
    NotificationsComponent.prototype.closeFeedback = function (key) {
        if (key == 'collaborativeLinks') {
            this.featureBanner = false;
            localStorage.removeItem('collaborativeLinks');
            return;
        } // Close specific notification
        return this.feedbackService.hideFeedback();
    };
    return NotificationsComponent;
}());
exports.NotificationsComponent = NotificationsComponent;
