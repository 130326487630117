"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/forms");
var i2 = require("./share-delete.component");
var i3 = require("../../notifications/feedback.service");
var i4 = require("../share.service");
var i5 = require("../../shared/services/broadcast.service");
var i6 = require("../../core/joblock.service");
var i7 = require("../../core/logger.service");
var i8 = require("@ngrx/store");
var styles_ShareDeleteComponent = [];
var RenderType_ShareDeleteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShareDeleteComponent, data: {} });
exports.RenderType_ShareDeleteComponent = RenderType_ShareDeleteComponent;
function View_ShareDeleteComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["Unshare ", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 28, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Everyone will be removed from this folder. You will still keep a copy of this folder in Sync. "])), (_l()(), i0.ɵeld(10, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 23, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "col-sm-4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Remote wipe:"])), (_l()(), i0.ɵeld(14, 0, null, null, 20, "div", [["class", "col-sm-8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 9, "div", [["class", "radio radio-top"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 8, "label", [], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 6, "input", [["checked", ""], ["id", "share-delete-purge-true"], ["name", "selectedPurge"], ["type", "radio"], ["value", "1"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 18)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 18).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 18)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 18)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i0.ɵnov(_v, 19).onChange() !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (i0.ɵnov(_v, 19).onTouched() !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = ((_co.selectedPurge = $event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(18, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(19, 212992, null, 0, i1.RadioControlValueAccessor, [i0.Renderer2, i0.ElementRef, i1.ɵangular_packages_forms_forms_j, i0.Injector], { name: [0, "name"], value: [1, "value"] }, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i1.DefaultValueAccessor, i1.RadioControlValueAccessor]), i0.ɵdid(21, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(23, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵted(-1, null, [" Yes "])), (_l()(), i0.ɵeld(25, 0, null, null, 9, "div", [["class", "radio radio-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 8, "label", [], null, null, null, null, null)), (_l()(), i0.ɵeld(27, 0, null, null, 6, "input", [["id", "share-delete-purge-false"], ["name", "selectedPurge"], ["type", "radio"], ["value", "0"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 28)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 28).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 28)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 28)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i0.ɵnov(_v, 29).onChange() !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (i0.ɵnov(_v, 29).onTouched() !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = ((_co.selectedPurge = $event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(28, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(29, 212992, null, 0, i1.RadioControlValueAccessor, [i0.Renderer2, i0.ElementRef, i1.ɵangular_packages_forms_forms_j, i0.Injector], { name: [0, "name"], value: [1, "value"] }, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i1.DefaultValueAccessor, i1.RadioControlValueAccessor]), i0.ɵdid(31, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(33, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵted(-1, null, [" No (let users keep a copy of this shared folder) "])), (_l()(), i0.ɵeld(35, 0, null, null, 5, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(36, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["id", "unshare-dialog-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stopShare() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(37, 0, null, null, 0, "i", [["class", "fa fa-spin fa-spinner"]], [[2, "hide", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Unshare "])), (_l()(), i0.ɵeld(39, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Cancel "]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = "selectedPurge"; var currVal_9 = "1"; _ck(_v, 19, 0, currVal_8, currVal_9); var currVal_10 = "selectedPurge"; var currVal_11 = _co.selectedPurge; _ck(_v, 21, 0, currVal_10, currVal_11); var currVal_19 = "selectedPurge"; var currVal_20 = "0"; _ck(_v, 29, 0, currVal_19, currVal_20); var currVal_21 = "selectedPurge"; var currVal_22 = _co.selectedPurge; _ck(_v, 31, 0, currVal_21, currVal_22); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shareData.name; _ck(_v, 5, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 23).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 23).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 23).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 23).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 23).ngClassValid; var currVal_6 = i0.ɵnov(_v, 23).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 23).ngClassPending; _ck(_v, 17, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_12 = i0.ɵnov(_v, 33).ngClassUntouched; var currVal_13 = i0.ɵnov(_v, 33).ngClassTouched; var currVal_14 = i0.ɵnov(_v, 33).ngClassPristine; var currVal_15 = i0.ɵnov(_v, 33).ngClassDirty; var currVal_16 = i0.ɵnov(_v, 33).ngClassValid; var currVal_17 = i0.ɵnov(_v, 33).ngClassInvalid; var currVal_18 = i0.ɵnov(_v, 33).ngClassPending; _ck(_v, 27, 0, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18); var currVal_23 = !_co.spinner; _ck(_v, 37, 0, currVal_23); }); }
exports.View_ShareDeleteComponent_0 = View_ShareDeleteComponent_0;
function View_ShareDeleteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-share-delete", [], null, null, null, View_ShareDeleteComponent_0, RenderType_ShareDeleteComponent)), i0.ɵdid(1, 114688, null, 0, i2.ShareDeleteComponent, [i3.FeedbackService, i4.ShareService, i5.BroadcastService, i6.JoblockService, i7.LoggerService, i8.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ShareDeleteComponent_Host_0 = View_ShareDeleteComponent_Host_0;
var ShareDeleteComponentNgFactory = i0.ɵccf("sync-share-delete", i2.ShareDeleteComponent, View_ShareDeleteComponent_Host_0, { openState: "openState", shareData: "shareData", modalInstance: "modalInstance" }, {}, []);
exports.ShareDeleteComponentNgFactory = ShareDeleteComponentNgFactory;
