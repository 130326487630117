"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var api_service_1 = require("../../core/api.service");
var models_1 = require("../../shared/models");
var user_service_1 = require("../../core/user.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_multiuser_settings_component_1 = require("../dialog-multiuser-settings/dialog-multiuser-settings.component");
var dialog_multiuser_add_component_1 = require("../dialog-multiuser-add/dialog-multiuser-add.component");
var router_1 = require("@angular/router");
var UserAdminComponent = /** @class */ (function () {
    function UserAdminComponent(api, modalService, userService, router) {
        this.api = api;
        this.modalService = modalService;
        this.userService = userService;
        this.router = router;
        this.currentList = [];
    }
    UserAdminComponent.prototype.ngOnInit = function () {
        this.getList();
        this.user = this.userService.getUser();
    };
    UserAdminComponent.prototype.getList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.execute('multiuserlist', {})];
                    case 1:
                        result = _a.sent();
                        this.currentList = result.list;
                        this.limit = result.limit;
                        this.lastSyncTime = result.last_filesync_time;
                        return [3 /*break*/, 3];
                    case 2:
                        ex_1 = _a.sent();
                        console.error(ex_1);
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserAdminComponent.prototype.hasEmptySlots = function () {
        return (this.getAmount() < this.limit);
    };
    UserAdminComponent.prototype.getAmount = function () {
        return this.currentList.length + 1;
    };
    UserAdminComponent.prototype.openSettings = function (child, openState) {
        var _this = this;
        var ref = this.modalService.open(dialog_multiuser_settings_component_1.DialogMultiuserSettingsComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
        ref.componentInstance.child = child;
        ref.componentInstance.openState = openState;
        ref.result.then(function (result) {
            if (result === true) {
                _this.getList();
            }
        });
    };
    UserAdminComponent.prototype.addUser = function () {
        var _this = this;
        if (this.limit > 1) {
            var ref = this.modalService.open(dialog_multiuser_add_component_1.DialogMultiuserAddComponent, {
                backdropClass: 'in',
                windowClass: 'in',
                backdrop: 'static',
            });
            ref.componentInstance.hasEmptySlots = this.hasEmptySlots();
            ref.result.then(function (result) {
                if (result === true) {
                    _this.getList();
                }
            });
        }
        else {
            this.router.navigate(['/account/upgrade']);
        }
    };
    return UserAdminComponent;
}());
exports.UserAdminComponent = UserAdminComponent;
