import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { DeviceListApiOutput, UserSessionListApiOutput, Session, Device, ErrCode, User } from '../../shared/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogDeviceRenameComponent } from '../dialog-device-rename/dialog-device-rename.component';
import { DialogDeviceDeleteComponent } from '../dialog-device-delete/dialog-device-delete.component';
import { DialogDevicePermissionComponent } from '../dialog-device-permission/dialog-device-permission.component';
import { DialogDeviceActivateComponent } from '../dialog-device-activate/dialog-device-activate.component';
import { DispatchService } from '../../core/dispatch.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sync-devices',
    templateUrl: './devices.component.html'
})
export class DevicesComponent implements OnInit {

    public sessions: Session[];
    public devices: Device[];
    public errcode: ErrCode;
    public showDeleted: boolean;

    public user: User;
    private sub: Subscription;

    constructor(
        private api: ApiService,
        private dispatch: DispatchService,
        private modalService: NgbModal,
        private store: Store<fromRoot.State>
    ) { }

    async ngOnInit() {
        this.sub = this.store
            .select(fromRoot.getAuthState)
            .subscribe((data) => {
                if (!data.authenticated) {
                    console.warn('User is not authenicated');
                    this.user = new User();
                    // this.initialized = false;
                    return;
                }
                if (data.user) {
                    console.log('Setting user');
                    this.user = data.user;
                    // this.initialized = true;
                }
            });
        await this.loadDevices();
        this.loadSessions();

    }

    public async activate(item: Device) {
        await this.api.execute('deviceactivate', { device_id: item.id });
        this.loadDevices();
    }

    public rename(item: Device) {
        const ref = this.modalService.open(DialogDeviceRenameComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.device = item;
        ref.result.then((result) => {
            if (result === true) {
                this.loadDevices();
            }
        }, () => {});
    }

    public delete(item: Device) {
        const ref = this.modalService.open(DialogDeviceDeleteComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.device = item;
        ref.result.then((result) => {
            if (result === true) {
                this.loadDevices();
            }
        }, () => {});
    }

    public async restore(item: Device) {
        await this.api.execute('devicerestore', { device_id: item.id });
        this.loadDevices();
    }

    public async logout(session: Session) {
        await this.api.execute('usersessionkill', { id: session.id });
        this.loadSessions();
    }

    public setPermission(item: Device) {
        const ref = this.modalService.open(DialogDevicePermissionComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.device = item;
        ref.result.then((result) => {
            if (result === true) {
                this.loadDevices();
            }
        }, () => {});
    }

    public activateDevice(item: Device) {
        const ref = this.modalService.open(DialogDeviceActivateComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.device = item;
        ref.result.then((result) => {
            if (result === true) {
                this.loadDevices();
            }
        }, () => {});
    }


    private async loadSessions() {
        const sessions = await this.api.execute<UserSessionListApiOutput>('usersessionlist', {});
        this.sessions = sessions.sessions;
    }

    private async loadDevices() {
        const devices = await this.api.execute<DeviceListApiOutput>('devicelist', {});
        this.devices = devices.devices;
        const locData = this.dispatch.getDipatchData();
        if (locData && locData.device_id) {
            for (let i = 0, len = this.devices.length; i < len; i++) {
                if (this.devices[i].id == locData.device_id) {
                    this.activateDevice(this.devices[i]);
                    break;
                }
            }
            this.dispatch.removeDispatch();
        }
    }
}
