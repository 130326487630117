"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InvoiceListItem = /** @class */ (function () {
    function InvoiceListItem(params) {
        this.id = 0;
        this.company_name = '';
        this.first_name = '';
        this.last_name = '';
        this.user_id = 0;
        this.username = '';
        this.status_id = 0;
        this.renewal_status_id = 0;
        this.total_amount = 0;
        this.start_servtime = 0;
        this.end_servtime = 0;
        this.plan_name = '';
        this.plan_diskspace = 0;
        if (params) {
            this.deserialize(params);
        }
    }
    InvoiceListItem.prototype.deserialize = function (params) {
        for (var _i = 0, _a = Object.entries(params); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], val = _b[1];
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    };
    return InvoiceListItem;
}());
exports.InvoiceListItem = InvoiceListItem;
