"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var user_service_1 = require("./user.service");
var logger_service_1 = require("./logger.service");
var dirty_in_service_1 = require("./crypt/dirty-in.service");
var dirty_out_service_1 = require("./crypt/dirty-out.service");
var feedback_service_1 = require("../notifications/feedback.service");
var base64_service_1 = require("./base64.service");
var api_service_1 = require("./api.service");
var http_1 = require("@angular/common/http");
var url_service_1 = require("./url.service");
var broadcast_service_1 = require("../shared/services/broadcast.service");
var rxjs_1 = require("rxjs");
var i0 = require("@angular/core");
var i1 = require("./crypt/dirty-in.service");
var i2 = require("./crypt/dirty-out.service");
var i3 = require("./user.service");
var i4 = require("../notifications/feedback.service");
var i5 = require("./logger.service");
var i6 = require("./base64.service");
var i7 = require("./api.service");
var i8 = require("@angular/common/http");
var i9 = require("./url.service");
var i10 = require("../shared/services/broadcast.service");
var NotificationsService = /** @class */ (function () {
    function NotificationsService(dirtyInService, dirtyOutService, userService, feedbackService, loggerService, base64Service, apiService, http, urlService, broadcastService) {
        this.dirtyInService = dirtyInService;
        this.dirtyOutService = dirtyOutService;
        this.userService = userService;
        this.feedbackService = feedbackService;
        this.loggerService = loggerService;
        this.base64Service = base64Service;
        this.apiService = apiService;
        this.http = http;
        this.urlService = urlService;
        this.broadcastService = broadcastService;
        this.didWork = false;
        this.loopShouldRun = true;
        this.isRunning = false;
        this.lastRefreshTime = 0;
        this.subscribeRunning = 0;
        this.taskRunning = false;
        this.maxHistId = 0;
        this.isTaskrunningSharedSubject = new rxjs_1.Subject();
        this.settings = {
            maintenance: false,
            user: this.userService.getUser(),
            globalTaskStatus: 0,
            feedback: this.feedbackService.view,
            pendingShares: 0,
            disk_limit_bytes: 0,
            disk_usage_bytes: 0,
            lastRunTime: 0,
            runCount: 1000,
            runCountLimit: 200,
            errorCount: 0,
            errorCountLimit: 10,
            is_taskrunning: false,
            is_taskrunningShared: false
        };
        if (this.settings.user.uid > 1000000
            && window.SYNCCFG
            && window.SYNCCFG.maintenanceshards
            && window.SYNCCFG.maintenanceshards.length) {
            var shard = this.settings.user.uid % 10000;
            if (window.SYNCCFG.maintenanceshards.indexOf(shard) > -1) {
                this.loggerService.error('Setting user to maintenance readonly due to shards');
                this.settings.maintenance = true;
                this.loopShouldRun = false;
                return;
            }
        }
    }
    NotificationsService.prototype.refreshUser = function () {
        return this.userService.refresh();
    };
    NotificationsService.prototype.stopNotificationLoop = function () {
        this.loggerService.info('Notifications: Stop');
        this.loopShouldRun = false;
    };
    NotificationsService.prototype.startNotificationLoop = function () {
        var _this = this;
        this.loggerService.info('Notifications: Start');
        this.loopShouldRun = true;
        this.settings.runCount = 0;
        this.runLoop().then(function () { return _this.subscribe(); });
    };
    NotificationsService.prototype.handleLogin = function () {
        this.feedbackService.hideFeedback();
        this.refreshUser();
        // this.settings.user = this.User.userattr;
        this.startNotificationLoop();
    };
    NotificationsService.prototype.handleLogout = function () {
        this.feedbackService.hideFeedback();
        this.stopNotificationLoop();
    };
    NotificationsService.prototype.runLoop = function (defer, promiseResolver) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var promise, data, didWork;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promise = defer || new Promise(function (resolve, reject) {
                            promiseResolver = resolve;
                        });
                        if (!!this.userService.isAuthenticated()) return [3 /*break*/, 1];
                        this.loggerService.warn('Not logged in, not running notifications');
                        promiseResolver();
                        return [3 /*break*/, 6];
                    case 1:
                        if (!!this.loopShouldRun) return [3 /*break*/, 2];
                        this.loggerService.warn('Notification loop should stop');
                        promiseResolver();
                        return [3 /*break*/, 6];
                    case 2:
                        if (!this.isRunning) return [3 /*break*/, 3];
                        this.loggerService.warn('Notifications loop is already running');
                        promiseResolver();
                        return [3 /*break*/, 6];
                    case 3:
                        this.isRunning = true;
                        this.loggerService.info('Running notifications ...');
                        return [4 /*yield*/, this.apiService.execute('usernotifications', {})];
                    case 4:
                        data = _a.sent();
                        return [4 /*yield*/, this.processLoop(data)];
                    case 5:
                        didWork = _a.sent();
                        this.isRunning = false;
                        if (didWork) {
                            this.loggerService.info('More work to do');
                            return [2 /*return*/, this.runLoop(promise, promiseResolver)];
                        }
                        else {
                            promiseResolver();
                        }
                        _a.label = 6;
                    case 6: return [2 /*return*/, promise];
                }
            });
        });
    };
    // process the API result
    NotificationsService.prototype.processLoop = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (parseInt(data.locked, 10) === 0) {
                _this.loggerService.info('User is locked on notifications.');
                // when you're locked, continue trying to run notifications
                resolve(true);
            }
            else {
                _this.settings.disk_limit_bytes = data.disk_limit_bytes;
                _this.settings.disk_usage_bytes = data.disk_usage_bytes;
                _this.settings.lastRunTime = Date.now();
                _this.settings.pendingShares = parseInt(data.amount, 10);
                // console.log(['last chec ',
                // this.User.get('is_verified'), '!=' + data.is_verified,
                // this.User.get('is_pro') , '!=' ,
                // this.User.get('display_name') , '!= ' ,
                // this.Base64.decode(data.display_name)
                // ].join(''));
                (_this.userService.get('is_verified') != data.is_verified ||
                    _this.userService.get('is_pro') != data.is_pro ||
                    _this.userService.get('display_name') != _this.base64Service.decode(data.display_name) ||
                    _this.userService.get('sku') != data.sku)
                    ? _this.userService.refresh() // force refresh
                    : _this.userService.update(); // refresh if >5 min since last
                // refresh views if possible.
                _this.loggerService.info([
                    'Checking last refresh:',
                    new Date(_this.lastRefreshTime),
                    'greater than 5s:', (_this.lastRefreshTime < (Date.now() - 5000)),
                    'maxhistid:', _this.maxHistId,
                    'hist_id received:', data.hist_id
                ].join(' '));
                if (_this.lastRefreshTime == 0
                    || _this.lastRefreshTime < (Date.now() - 5000)
                    || _this.maxHistId != data.hist_id) {
                    _this.lastRefreshTime = Date.now();
                    // TODO this may be causing double refreshes on load.
                    _this.broadcastService.broadcast('event:file-list.reload', { hist_id: data.hist_id });
                    _this.broadcastService.broadcast('event:link-list.reload');
                    _this.broadcastService.broadcast('event:share-list.reload');
                    _this.maxHistId = data.hist_id;
                    _this.runTaskLoop();
                }
                else {
                    _this.loggerService.info("skip Refreshing lists " + _this.lastRefreshTime);
                }
                if (_this.settings.errorCount > _this.settings.errorCountLimit) {
                    _this.loggerService.error('Notifications encountered 50+ errors.');
                    _this.loggerService.error('Stopping notifications on account of errors');
                    _this.stopNotificationLoop();
                    _this.settings.is_taskrunning = false;
                    resolve(_this.settings.is_taskrunning);
                    return;
                }
                if (_this.settings.runCount > _this.settings.runCountLimit) {
                    _this.loggerService.error("More flag has run >= " + _this.settings.runCountLimit + " without processing anything");
                    _this.loggerService.error(JSON.stringify(data));
                    _this.settings.is_taskrunning = false;
                    _this.stopNotificationLoop();
                    resolve(_this.settings.is_taskrunning);
                    return;
                }
                if (data.task_dirtyin && data.task_dirtyin.cnt) {
                    // process dirty in first
                    _this.settings.runCount = 0;
                    _this.settings.is_taskrunning = true;
                    _this.settings.is_taskrunningShared = true;
                    _this.isTaskrunningSharedSubject.next(_this.settings.is_taskrunning);
                    _this.loggerService.info('DirtyIn is running: ' + data.task_dirtyin.cnt);
                    _this.dirtyInService.processDirtyIn(data.task_dirtyin).
                        then(function () {
                        _this.loggerService.info('Completed dirty in, running again');
                        resolve(_this.settings.is_taskrunning);
                    }).catch(function (err) {
                        _this.loggerService.error('DirtyIn failed');
                        _this.loggerService.error(JSON.stringify(data.task_dirtyin));
                        _this.settings.errorCount += 1;
                        _this.processLoopError(err);
                        resolve(_this.settings.is_taskrunning);
                    });
                }
                else if (data.task_dirtyout && data.task_dirtyout.length) {
                    // process dirty out
                    // reset run count since there was work to be done.
                    _this.settings.runCount = 0;
                    _this.settings.is_taskrunning = true;
                    _this.settings.is_taskrunningShared = false;
                    _this.isTaskrunningSharedSubject.next(_this.settings.is_taskrunning);
                    _this.loggerService.info('DirtyOut is running = ' + data.task_dirtyout.length);
                    _this.dirtyOutService.processDirtyOut(data.task_dirtyout)
                        .then(function () {
                        _this.loggerService.info('Completed dirty out, running again');
                        resolve(_this.settings.is_taskrunning);
                    }, function (err) {
                        _this.loggerService.error('Dirty out failed');
                        _this.loggerService.error(JSON.stringify(data.task_dirtyout));
                        _this.loggerService.error(err);
                        _this.settings.errorCount += 1;
                        resolve(_this.settings.is_taskrunning);
                    });
                }
                else if (data.more) {
                    // if the data.more flag is set, it means there is more work
                    _this.loggerService.info('More flag was set, re-run notifications');
                    _this.settings.runCount += 1;
                    if (_this.settings.runCount >= _this.settings.runCountLimit) {
                        _this.loggerService.error("More flag has run >= " + _this.settings.runCountLimit + " without processing anything");
                        _this.loggerService.error(JSON.stringify(data));
                        _this.settings.is_taskrunning = false;
                        _this.settings.is_taskrunningShared = false;
                        _this.isTaskrunningSharedSubject.next(_this.settings.is_taskrunning);
                        _this.stopNotificationLoop();
                    }
                    else {
                        _this.settings.is_taskrunning = true;
                        _this.isTaskrunningSharedSubject.next(_this.settings.is_taskrunning);
                    }
                    resolve(_this.settings.is_taskrunning);
                }
                else {
                    // more flag is not set, setting run count to 0
                    _this.settings.runCount = 0;
                    _this.settings.is_taskrunning = false;
                    _this.settings.is_taskrunningShared = false;
                    _this.isTaskrunningSharedSubject.next(_this.settings.is_taskrunning);
                    resolve(_this.settings.is_taskrunning);
                }
            }
        });
    };
    /**
     * If an error occurs with notifications, do not die.  Attempt to re-run
     * it again after 5 seconds.  Log the error to Logger service.
     * @param  {Object} data [description]
     */
    NotificationsService.prototype.processLoopError = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.settings.is_taskrunning = true;
                this.loggerService.error('An error occurred processing dirty in or out');
                this.loggerService.error(data);
                return [2 /*return*/, Promise.resolve(this.settings.is_taskrunning)];
            });
        });
    };
    NotificationsService.prototype.subscribe = function () {
        var _this = this;
        this.loggerService.info('Notifications.subscribe()');
        if (!this.userService.isAuthenticated()) {
            this.loggerService.info('Polling is disabled until authenticated');
            this.subscribeRunning = 0;
        }
        else if (!this.loopShouldRun) {
            this.loggerService.info('Polling is disabled due to a "stopNotificationLoop call');
        }
        else if (this.subscribeRunning != 0) {
            this.loggerService.info('A subscribe thread is already running');
        }
        else {
            this.subscribeRunning = Date.now();
            return this.subscribeNotifications()
                .then(function () {
                _this.loggerService.info("Subscribe awoken " + (Date.now() - _this.subscribeRunning) + " ms");
                return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var _this = this;
                    return tslib_1.__generator(this, function (_a) {
                        this.runLoop().finally(function () {
                            _this.loggerService.info('Subscribe runLoop finally');
                            _this.subscribeRunning = 0;
                            _this.subscribe();
                            resolve();
                        });
                        return [2 /*return*/];
                    });
                }); });
            });
        }
    };
    /**
    * @ngdoc method
    * @name  subscribe
    * @description
    * Subscribes to the long poll on API.  The long poll awakens every
    * 10 minutes, or alternatively is woken up when something changes.
    *
    * Notifications will determine whether or not to run the UserNotifications
    * and process any data.
    * @see  sync.service:Notifications
    * @return {Promise} Resolves when woken up either from a change on the
    *                   acct or after 10 minutes.
    */
    NotificationsService.prototype.subscribeNotifications = function () {
        var _this = this;
        var headers = new http_1.HttpHeaders({
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
            'timeout': '660000'
        });
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.http.get(this.urlService.mkSubscribe(), { headers: headers, responseType: 'text' })
                    .subscribe(function () { return resolve(); }, function () {
                    return resolve();
                });
                return [2 /*return*/];
            });
        }); });
    };
    NotificationsService.prototype.runTaskLoop = function () {
        var _this = this;
        if (!this.userService.isAuthenticated()) {
            this.loggerService.warn('Not logged in, not running notifications');
            return;
        }
        else if (!this.loopShouldRun) {
            this.loggerService.warn('Notification loop should stop');
            return;
        }
        else if (this.isRunning) {
            this.loggerService.warn('Notifications loop is already running');
            return;
        }
        else if (!this.taskRunning) {
            this.taskRunning = true;
            this.apiService.execute('getrewind', {})
                .then(function (result) {
                _this.settings.globalTaskStatus = parseInt(result.job_status_id, 10);
                _this.taskRunning = false;
                if (!result || !result.job_status_id) {
                    // job is done
                    _this.settings.globalTaskStatus = 0;
                }
                else if (result.job_status_id == 1 || result.job_status_id == 2) {
                    setTimeout(function () {
                        _this.runTaskLoop();
                    }, 10000);
                }
            });
        }
    };
    NotificationsService.prototype.getNotificationStatus = function () {
        return this.isTaskrunningSharedSubject;
    };
    NotificationsService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(i0.inject(i1.DirtyInService), i0.inject(i2.DirtyOutService), i0.inject(i3.UserService), i0.inject(i4.FeedbackService), i0.inject(i5.LoggerService), i0.inject(i6.Base64Service), i0.inject(i7.ApiService), i0.inject(i8.HttpClient), i0.inject(i9.UrlService), i0.inject(i10.BroadcastService)); }, token: NotificationsService, providedIn: "root" });
    return NotificationsService;
}());
exports.NotificationsService = NotificationsService;
