"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("../api.service");
var sync_crypt_service_1 = require("./sync-crypt.service");
var logger_service_1 = require("../logger.service");
var models_1 = require("../../shared/models");
var DirtyOutService = /** @class */ (function () {
    function DirtyOutService(api, crypt, log) {
        this.api = api;
        this.crypt = crypt;
        this.log = log;
    }
    DirtyOutService.prototype.processDirtyOut = function (workList) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var processlist, i, len, item, _a, _b, _c, j, len2, _d, _e;
            return tslib_1.__generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        processlist = [];
                        i = 0, len = workList.length;
                        _f.label = 1;
                    case 1:
                        if (!(i < len)) return [3 /*break*/, 11];
                        item = workList[i];
                        _a = item.action;
                        switch (_a) {
                            case 'encnameshare': return [3 /*break*/, 2];
                            case 'encdatakey': return [3 /*break*/, 4];
                        }
                        return [3 /*break*/, 9];
                    case 2:
                        _c = (_b = processlist).push;
                        return [4 /*yield*/, this.encShareName(item)];
                    case 3:
                        _c.apply(_b, [_f.sent()]);
                        return [3 /*break*/, 10];
                    case 4:
                        j = 0, len2 = item.keys.length;
                        _f.label = 5;
                    case 5:
                        if (!(j < len2)) return [3 /*break*/, 8];
                        _e = (_d = processlist).push;
                        return [4 /*yield*/, this.encDataKey(item, item.keys[j])];
                    case 6:
                        _e.apply(_d, [_f.sent()]);
                        _f.label = 7;
                    case 7:
                        j++;
                        return [3 /*break*/, 5];
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        this.log.error('Processing dirty out, no action provided-' + item.toString());
                        return [3 /*break*/, 10];
                    case 10:
                        i++;
                        return [3 /*break*/, 1];
                    case 11:
                        if (!processlist.length) return [3 /*break*/, 13];
                        return [4 /*yield*/, this.api.execute('keyssetdirtyout', {
                                items: processlist
                            })];
                    case 12:
                        _f.sent();
                        return [2 /*return*/];
                    case 13: return [2 /*return*/];
                }
            });
        });
    };
    DirtyOutService.prototype.getKeyArrayForSyncId = function (sharedata, syncId, plainName, dataKey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var keysData, i, len, shareitem, sharekey, e_1, msg, encShareName, encDataKey, _a, keyData, e_2, msg;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        keysData = [];
                        i = 0, len = sharedata.length;
                        _b.label = 1;
                    case 1:
                        if (!(i < len)) return [3 /*break*/, 13];
                        shareitem = sharedata[i];
                        sharekey = void 0;
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.crypt.sharekeyDecrypt(shareitem.enc_share_key, shareitem.sharekey_id)];
                    case 3:
                        sharekey = _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _b.sent();
                        msg = ['Sharekey decrypt failed for ',
                            syncId, ' key id = ', shareitem.sharekey_id,
                            ' enc key = ', shareitem.enc_share_key].join('');
                        this.log.e(msg, e_1);
                        throw new models_1.ErrCode(2000, msg);
                    case 5:
                        if (!sharekey) {
                            throw new Error('No share key provided');
                        }
                        _b.label = 6;
                    case 6:
                        _b.trys.push([6, 11, , 12]);
                        return [4 /*yield*/, this.crypt.filenameEncrypt(plainName, sharekey)];
                    case 7:
                        encShareName = _b.sent();
                        if (!(dataKey)) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.crypt.datakeyEncrypt(dataKey, sharekey)];
                    case 8:
                        _a = _b.sent();
                        return [3 /*break*/, 10];
                    case 9:
                        _a = undefined;
                        _b.label = 10;
                    case 10:
                        encDataKey = _a;
                        keyData = {
                            enc_data_key: encDataKey,
                            sync_id: syncId,
                            sharekey_id: shareitem.sharekey_id,
                            enc_share_name: encShareName,
                            servtime: shareitem.servtime,
                        };
                        keysData.push(keyData);
                        return [3 /*break*/, 12];
                    case 11:
                        e_2 = _b.sent();
                        msg = ['Data key or file name failed ',
                            syncId, ' key id = ', shareitem.sharekey_id,
                            ' enc key = ', shareitem.enc_share_key].join('');
                        this.log.e(msg, e_2);
                        throw new models_1.ErrCode(2010, msg);
                    case 12:
                        i++;
                        return [3 /*break*/, 1];
                    case 13: return [2 /*return*/, keysData];
                }
            });
        });
    };
    DirtyOutService.prototype.getShareKeyDataForSyncId = function (syncId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, sharedata, _i, _a, _b, sharekeyId, encShareKey, item;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.api.execute('keysneedencbysync', {
                            sync_id: syncId
                        })];
                    case 1:
                        result = _c.sent();
                        sharedata = [];
                        for (_i = 0, _a = Object.entries(result.sharekeys); _i < _a.length; _i++) {
                            _b = _a[_i], sharekeyId = _b[0], encShareKey = _b[1];
                            item = new models_1.DirtyOutKeyItemShareKey();
                            item.sharekey_id = sharekeyId;
                            item.enc_share_key = encShareKey;
                            item.servtime = result.servtime;
                            sharedata.push(item);
                        }
                        return [2 /*return*/, sharedata];
                }
            });
        });
    };
    DirtyOutService.prototype.executeForSync = function (syncId, plainName, dataKey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sharedata, result, e_3, msg;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.getShareKeyDataForSyncId(syncId)];
                    case 1:
                        sharedata = _a.sent();
                        return [4 /*yield*/, this.getKeyArrayForSyncId(sharedata, syncId, plainName, dataKey)];
                    case 2:
                        result = _a.sent();
                        return [2 /*return*/, result];
                    case 3:
                        e_3 = _a.sent();
                        msg = ['executeForSync ', syncId].join('');
                        this.log.e(msg, e_3);
                        throw new models_1.ErrCode(2010);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DirtyOutService.prototype.executeForShare = function (shareId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, setItems, sharekeys, i, len, cur, sharekey, filename, encShareName, datakeys;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.execute('keysneedencbyshare', { share_id: shareId })];
                    case 1:
                        data = _a.sent();
                        setItems = [];
                        sharekeys = data.sharekeys;
                        if (!data.items || !data.items.length) {
                            this.log.warn('No items to execute for share id ' + shareId);
                            return [2 /*return*/];
                        }
                        i = 0, len = data.items.length;
                        _a.label = 2;
                    case 2:
                        if (!(i < len)) return [3 /*break*/, 8];
                        cur = data.items[i];
                        return [4 /*yield*/, this.crypt.sharekeyDecrypt(sharekeys[cur.new_key_id], cur.new_key_id)];
                    case 3:
                        sharekey = _a.sent();
                        return [4 /*yield*/, this.crypt.filenameDecrypt(cur.metaname)];
                    case 4:
                        filename = _a.sent();
                        return [4 /*yield*/, this.crypt.filenameEncrypt(filename, sharekey)];
                    case 5:
                        encShareName = _a.sent();
                        return [4 /*yield*/, this.datakeyProcess(cur, cur.data_keys, sharekeys, sharekey)];
                    case 6:
                        datakeys = _a.sent();
                        setItems.push({
                            sync_id: cur.sync_id,
                            share_id: cur.share_id,
                            sharekey_id: cur.new_key_id,
                            servtime: cur.servtime,
                            enc_share_name: encShareName,
                            data_keys: datakeys
                        });
                        _a.label = 7;
                    case 7:
                        i++;
                        return [3 /*break*/, 2];
                    case 8: return [4 /*yield*/, this.api.execute('keyssetenc', {
                            items: setItems
                        })];
                    case 9: 
                    // console.warn('Sending keyssetenc with ' , setItems);
                    return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    DirtyOutService.prototype.datakeyProcess = function (cur, datakeys, sharekeys, newShareKey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newDataKeys, i, len, curDk, plainShareKey, dataKey, newEncDataKey, result, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!datakeys || !datakeys.length) {
                            this.log.info(cur.sync_id + ' has no datakeys to process');
                            return [2 /*return*/, []];
                        }
                        newDataKeys = [];
                        i = 0, len = datakeys.length;
                        _a.label = 1;
                    case 1:
                        if (!(i < len)) return [3 /*break*/, 8];
                        curDk = datakeys[i];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 6, , 7]);
                        return [4 /*yield*/, this.crypt.sharekeyDecrypt(sharekeys[curDk.sharekey_id], curDk.sharekey_id)];
                    case 3:
                        plainShareKey = _a.sent();
                        return [4 /*yield*/, this.crypt.datakeyDecrypt(curDk.enc_data_key, plainShareKey)];
                    case 4:
                        dataKey = _a.sent();
                        return [4 /*yield*/, this.crypt.datakeyEncrypt(dataKey, newShareKey)];
                    case 5:
                        newEncDataKey = _a.sent();
                        result = new models_1.DirtyOutNeedEncDataKeys();
                        result.sync_id = cur.sync_id;
                        result.blob_id = cur.blob_id;
                        result.enc_data_key = newEncDataKey;
                        result.sharekey_id = curDk.sharekey_id;
                        newDataKeys.push(result);
                        return [3 /*break*/, 7];
                    case 6:
                        ex_1 = _a.sent();
                        // add logging but don't deal with the exception, re-throw
                        this.log.error("Error DirtyOut::datakeyProcess - datakey " + JSON.stringify(curDk));
                        this.log.error("Error DirtyOut::datakeyProcess - current item: " + JSON.stringify(cur));
                        throw ex_1;
                    case 7:
                        i++;
                        return [3 /*break*/, 1];
                    case 8: return [2 /*return*/, newDataKeys];
                }
            });
        });
    };
    DirtyOutService.prototype.encDataKey = function (item, key) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var old_sharekey_id, sharekey_id, newShareKey, shareKey, datakey, encDataKey, result, ex_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        old_sharekey_id = key.old_share_id + "-" + key.old_share_sequence, sharekey_id = item.share_id + "-" + item.share_sequence;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, this.crypt.sharekeyDecrypt(key.new_share_key, sharekey_id)];
                    case 2:
                        newShareKey = _a.sent();
                        return [4 /*yield*/, this.crypt.sharekeyDecrypt(key.share_key, old_sharekey_id)];
                    case 3:
                        shareKey = _a.sent();
                        return [4 /*yield*/, this.crypt.datakeyDecrypt(key.data_key, shareKey)];
                    case 4:
                        datakey = _a.sent();
                        return [4 /*yield*/, this.crypt.datakeyEncrypt(datakey, newShareKey)];
                    case 5:
                        encDataKey = _a.sent();
                        result = new models_1.DirtyOutTaskDataKeyResult();
                        result.action = 'encdatakey';
                        result.blob_id = key.blob_id;
                        result.sync_id = item.sync_id;
                        result.share_id = item.share_id;
                        result.share_sequence = item.share_sequence;
                        result.enc_data_key = encDataKey;
                        return [2 /*return*/, result];
                    case 6:
                        ex_2 = _a.sent();
                        // add logging but don't deal with the exception, re-throw
                        this.log.error("Error DirtyOut::encDataKey - sharekey_id " + sharekey_id + " - old_sharekey_id: " + old_sharekey_id);
                        this.log.error("Error DirtyOut::encDataKey - Item: " + JSON.stringify(item));
                        this.log.error("Error DirtyOut::encDataKey - key: " + JSON.stringify(key));
                        throw ex_2;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    DirtyOutService.prototype.encShareName = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var shareKeyId, filename, sharekey, encShareName, result, ex_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shareKeyId = item.share_id + "-" + item.share_sequence;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, this.crypt.filenameDecrypt(item.enc_share_name)];
                    case 2:
                        filename = _a.sent();
                        return [4 /*yield*/, this.crypt.sharekeyDecrypt(item.share_key, shareKeyId)];
                    case 3:
                        sharekey = _a.sent();
                        return [4 /*yield*/, this.crypt.filenameEncrypt(filename, sharekey)];
                    case 4:
                        encShareName = _a.sent();
                        result = new models_1.DirtyOutTaskShareNameResult();
                        result.action = 'encnameshare';
                        result.sync_id = item.sync_id;
                        result.share_id = item.share_id;
                        result.share_sequence = item.share_sequence;
                        result.enc_share_name = encShareName;
                        return [2 /*return*/, result];
                    case 5:
                        ex_3 = _a.sent();
                        // add logging but don't deal with the exception, re-throw
                        this.log.error("Error DirtyOut::encShareName - sharekey_id " + shareKeyId);
                        this.log.error("Error DirtyOut::encShareName - Item: " + JSON.stringify(item));
                        throw ex_3;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return DirtyOutService;
}());
exports.DirtyOutService = DirtyOutService;
