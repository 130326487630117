"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../shared/components/comment/comment-block/comment-block.component.ngfactory");
var i2 = require("../../shared/components/comment/comment-block/comment-block.component");
var i3 = require("../../link-consume/services/link-file-list.service");
var i4 = require("../../core/crypt/sync-crypt.service");
var i5 = require("@ngrx/store");
var i6 = require("../../core/logger.service");
var i7 = require("../../core/comment.service");
var i8 = require("../../core/base64.service");
var i9 = require("../../core/api.service");
var i10 = require("@angular/platform-browser");
var i11 = require("@angular/router");
var i12 = require("../../shared/components/header/previews/header-previews.component.ngfactory");
var i13 = require("../../shared/components/header/previews/header-previews.component");
var i14 = require("@ng-bootstrap/ng-bootstrap");
var i15 = require("../preview-menu/preview-menu.component.ngfactory");
var i16 = require("../preview-menu/preview-menu.component");
var i17 = require("../../core/compat.service");
var i18 = require("../../core/user.service");
var i19 = require("../../transfer/services/build-transfer-item.service");
var i20 = require("../file-item.service");
var i21 = require("../../transfer/transfer.service");
var i22 = require("../../shared/directives/prevent-right-click.directive");
var i23 = require("@angular/common");
var i24 = require("./preview-video.component");
var i25 = require("../../shared/services/blend.service");
var styles_PreviewVideoComponent = ["./preview-video.component.css"];
var RenderType_PreviewVideoComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_PreviewVideoComponent, data: {} });
exports.RenderType_PreviewVideoComponent = RenderType_PreviewVideoComponent;
function View_PreviewVideoComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-comment-block", [], [[1, "style", 2]], null, null, i1.View_CommentBlockComponent_0, i1.RenderType_CommentBlockComponent)), i0.ɵdid(1, 245760, null, 0, i2.CommentBlockComponent, [i3.LinkFileListService, i4.SyncCryptService, i5.Store, i6.LoggerService, i7.CommentService, i8.Base64Service, i9.ApiService, i10.DomSanitizer, i11.ActivatedRoute], { item: [0, "item"], ontoggle: [1, "ontoggle"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item; var currVal_2 = _co.onCommentToggle; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).customColorsCss; _ck(_v, 0, 0, currVal_0); }); }
function View_PreviewVideoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "sync-header-previews", [], [[1, "style", 2]], null, null, i12.View_HeaderPreviewsComponent_0, i12.RenderType_HeaderPreviewsComponent)), i0.ɵdid(1, 245760, null, 0, i13.HeaderPreviewsComponent, [i5.Store, i6.LoggerService, i10.DomSanitizer, i10.Title, i14.NgbModal, i11.Router, i11.ActivatedRoute], { item: [0, "item"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 2, "div", [["class", "hamburger-menu-item visible-xs"], ["hamburgerMenu", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "sync-preview-menu", [], null, null, null, i15.View_PreviewMenuComponent_0, i15.RenderType_PreviewMenuComponent)), i0.ɵdid(4, 245760, null, 0, i16.PreviewMenuComponent, [i17.CompatService, i18.UserService, i3.LinkFileListService, i9.ApiService, i10.DomSanitizer, i14.NgbModal, i19.BuildTransferItemService, i20.FileItemService, i21.TransferService, i11.Router, i11.ActivatedRoute], { url: [0, "url"], item: [1, "item"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["class", "col-md-8 col-lg-9 video-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "video", [["class", "video-js vjs-default-skin vjs-big-play-centered"], ["controls", ""], ["data-setup", "{}"], ["id", "my-video"], ["preload", "auto"], ["syncPreventRightClick", ""]], null, [[null, "contextmenu"]], function (_v, en, $event) { var ad = true; if (("contextmenu" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).click($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i22.PreventRightClickDirective, [], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 3, "p", [["class", "vjs-no-js"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" To view this video please enable JavaScript, and consider upgrading to a web browser that "])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "a", [["href", "https://videojs.com/html5-video-support/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["supports HTML5 video"])), (_l()(), i0.ɵeld(12, 0, null, null, 4, "div", [["class", "col-md-4 col-lg-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "sync-preview-menu", [], null, null, null, i15.View_PreviewMenuComponent_0, i15.RenderType_PreviewMenuComponent)), i0.ɵdid(14, 245760, null, 0, i16.PreviewMenuComponent, [i17.CompatService, i18.UserService, i3.LinkFileListService, i9.ApiService, i10.DomSanitizer, i14.NgbModal, i19.BuildTransferItemService, i20.FileItemService, i21.TransferService, i11.Router, i11.ActivatedRoute], { url: [0, "url"], item: [1, "item"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreviewVideoComponent_1)), i0.ɵdid(16, 16384, null, 0, i23.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(17, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item; _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.url; var currVal_3 = _co.item; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = _co.url; var currVal_5 = _co.item; _ck(_v, 14, 0, currVal_4, currVal_5); var currVal_6 = _co.allowComment; _ck(_v, 16, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).customColorsCss; _ck(_v, 0, 0, currVal_0); }); }
exports.View_PreviewVideoComponent_0 = View_PreviewVideoComponent_0;
function View_PreviewVideoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-preview-video", [], null, null, null, View_PreviewVideoComponent_0, RenderType_PreviewVideoComponent)), i0.ɵdid(1, 245760, null, 0, i24.PreviewVideoComponent, [i6.LoggerService, i20.FileItemService, i3.LinkFileListService, i25.BlendService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_PreviewVideoComponent_Host_0 = View_PreviewVideoComponent_Host_0;
var PreviewVideoComponentNgFactory = i0.ɵccf("sync-preview-video", i24.PreviewVideoComponent, View_PreviewVideoComponent_Host_0, { item: "item", videoUrl: "videoUrl", mimeType: "mimeType" }, {}, []);
exports.PreviewVideoComponentNgFactory = PreviewVideoComponentNgFactory;
