"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var reselect_1 = require("reselect");
/**
 * The compose function is one of our most handy tools. In basic terms, you give
 * it any number of functions and it returns a function. This new function
 * takes a value and chains it through every composed function, returning
 * the output.
 *
 * More: https://drboolean.gitbooks.io/mostly-adequate-guide/content/ch5.html
 */
// import { compose } from '@ngrx/store';
/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
// import { storeFreeze } from 'ngrx-store-freeze';
/**
 * combineReducers is another useful metareducer that takes a map of reducer
 * functions and creates a new reducer that gathers the values
 * of each reducer and stores them using the reducer's key. Think of it
 * almost like a database, where every reducer is a table in the db.
 *
 * More: https://egghead.io/lessons/javascript-redux-implementing-combinereducers-from-scratch
 */
// import { combineReducers } from '@ngrx/store';
// import * as fromRouter from '@ngrx/router-store';
var fromAuth = require("./auth.reducer");
var fromCore = require("./core.reducer");
var fromFileList = require("./file-list.reducer");
var fromLinkFileList = require("./link-file-list.reducer");
var fromLinkList = require("./link-list.reducer");
var fromUserList = require("./user-list.reducer");
// import * as fromLinkItem from './link-item.reducer';
// import * as fromShareList from './share-list.reducer';
// import * as fromShareNew from './share-new.reducer';
var ngrx_store_localstorage_1 = require("ngrx-store-localstorage");
exports.reducers = {
    auth: fromAuth.reducer,
    core: fromCore.reducer,
    filelist: fromFileList.reducer,
    linkfilelist: fromLinkFileList.reducer,
    // linkitem: fromLinkItem.reducer,
    linklist: fromLinkList.reducer,
    userlist: fromUserList.reducer,
    data: undefined
};
/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them useable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function from the reselect library creates
 * very efficient selectors that are memoized and only recompute when arguments change.
 * The created selectors can also be composed together to select different
 * pieces of state.
 */
exports.getAuthState = function (state) { return state.auth; };
exports.getAuthUser = reselect_1.createSelector(exports.getAuthState, fromAuth.getUser);
exports.getAuthenticated = reselect_1.createSelector(exports.getAuthState, fromAuth.getAuthenticated);
exports.getAuthLoginProgress = reselect_1.createSelector(exports.getAuthState, fromAuth.getLoginProgress);
exports.getAuthError = reselect_1.createSelector(exports.getAuthState, fromAuth.getErrCode);
exports.getCoreState = function (state) { return state.core; };
exports.getSignApiData = reselect_1.createSelector(exports.getAuthUser, exports.getCoreState, function (user, core) { return Object.assign({}, user, core); });
exports.getAccessToken = function (state) { return state.core.access_token; };
exports.getFileListState = function (state) { return state.filelist; };
exports.getFavoritsListState = function (state) { return state.filelist; };
exports.getFileListCwd = function (state) { return state.filelist.cwd; };
exports.getFileListItems = function (state) { return state.filelist.pathlist; };
exports.fileSelector = reselect_1.createSelector(exports.getFileListItems, function (state) { return state; });
exports.getFileListError = function (state) { return state.filelist.error; };
exports.getFileListShowDeleted = function (state) { return state.filelist.showdeleted; };
exports.getFileListDeletedAmt = function (state) { return state.filelist.deletedAmt; };
exports.getFileListSelected = function (state) { return state.filelist.pathlist.filter(function (item) { return item.active == true; }); };
exports.getUserListSelected = function (state) { return state.userlist.list.filter(function (item) { return item.active == true; }); };
exports.getLinkFileListState = function (state) { return state.linkfilelist; };
exports.getLinkFileListCwd = function (state) { return state.linkfilelist.cwd; };
exports.getLinkListState = function (state) { return state.linklist; };
exports.getLinkListItems = function (state) { return state.linklist.items; };
// export const getLinkItem = (state: State) => state.linkitem.item;
// export const getShareListState = (state: State) => state.sharelist;
function localStorageSyncReducer(reducer) {
    return ngrx_store_localstorage_1.localStorageSync({
        keys: ['core', 'auth'],
        rehydrate: true,
        storageKeySerializer: function (key) { return 'sync_' + key; }
    })(reducer);
}
exports.localStorageSyncReducer = localStorageSyncReducer;
exports.metaReducers = [localStorageSyncReducer];
