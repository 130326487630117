"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var rxjs_1 = require("rxjs");
var api_service_1 = require("../core/api.service");
var sync_crypt_service_1 = require("../core/crypt/sync-crypt.service");
var logger_service_1 = require("../core/logger.service");
var notifications_service_1 = require("../core/notifications.service");
var broadcast_service_1 = require("../shared/services/broadcast.service");
var batch_action_service_1 = require("./batch-action.service");
var i0 = require("@angular/core");
var i1 = require("../shared/services/broadcast.service");
var i2 = require("../core/logger.service");
var i3 = require("../core/crypt/sync-crypt.service");
var i4 = require("../core/notifications.service");
var i5 = require("../core/api.service");
var BatchPurgeService = /** @class */ (function (_super) {
    tslib_1.__extends(BatchPurgeService, _super);
    function BatchPurgeService(broadcastService, loggerService, syncCryptService, notificationsService, apiService) {
        var _this = _super.call(this, broadcastService, loggerService, syncCryptService, notificationsService, apiService) || this;
        _this.broadcastService = broadcastService;
        _this.loggerService = loggerService;
        _this.syncCryptService = syncCryptService;
        _this.notificationsService = notificationsService;
        _this.apiService = apiService;
        return _this;
    }
    BatchPurgeService.prototype.execute = function (syncIds) {
        var subject = new rxjs_1.Subject();
        this.loggerService.info("batchPurge(" + syncIds.toString() + ")");
        this.ACTION = this.ACT_PURGE;
        this.view.globalCancel = false;
        this.view.completedBatch = [];
        this.view.total = syncIds.length;
        this.runBatchItems(syncIds, subject);
        return subject;
    };
    BatchPurgeService.prototype.prepareItemForAction = function (syncId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dumpList, dumpItems, syncIds, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.view.globalCancel) return [3 /*break*/, 1];
                        return [2 /*return*/, this.handleCancel()];
                    case 1:
                        this.resetProgress();
                        this.view.spinner = syncId;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 7, , 8]);
                        return [4 /*yield*/, this.dumpDirs(syncId, -1)];
                    case 3:
                        dumpList = _a.sent();
                        return [4 /*yield*/, this.dropShare(dumpList)];
                    case 4:
                        dumpItems = _a.sent();
                        return [4 /*yield*/, this.convertToArray(dumpItems)];
                    case 5:
                        syncIds = _a.sent();
                        this.view.total = syncIds.length;
                        if (this.view.globalCancel) {
                            return [2 /*return*/, this.handleCancel()];
                        }
                        syncIds.reverse();
                        return [4 /*yield*/, this.runProcessTask(syncIds, 0)];
                    case 6: return [2 /*return*/, _a.sent()];
                    case 7:
                        err_1 = _a.sent();
                        console.log('batch-purge prepareItemForAction err: ', err_1);
                        return [2 /*return*/, this.loggerService.handleError('batch-purge prepareItemForAction', err_1)];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    BatchPurgeService.prototype.apiExecuteTask = function (syncId) {
        return this.apiService.execute('batchpurge', { sync_id: syncId });
    };
    BatchPurgeService.ngInjectableDef = i0.defineInjectable({ factory: function BatchPurgeService_Factory() { return new BatchPurgeService(i0.inject(i1.BroadcastService), i0.inject(i2.LoggerService), i0.inject(i3.SyncCryptService), i0.inject(i4.NotificationsService), i0.inject(i5.ApiService)); }, token: BatchPurgeService, providedIn: "root" });
    return BatchPurgeService;
}(batch_action_service_1.BatchActionService));
exports.BatchPurgeService = BatchPurgeService;
