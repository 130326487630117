"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("../../core/api.service");
var base64_service_1 = require("../../core/base64.service");
var logger_service_1 = require("../../core/logger.service");
var provision_worker_service_1 = require("./provision-worker.service");
var models_1 = require("../../shared/models");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var environment_1 = require("../../../environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("../../core/api.service");
var i3 = require("../../core/base64.service");
var i4 = require("../../core/logger.service");
var i5 = require("./provision-worker.service");
var ProvisionService = /** @class */ (function () {
    function ProvisionService(httpClient, api, base64, log, worker) {
        this.httpClient = httpClient;
        this.api = api;
        this.base64 = base64;
        this.log = log;
        this.worker = worker;
        this.orderspath = environment_1.environment.ordershost;
        this.cphost = environment_1.environment.cphost;
    }
    ProvisionService.prototype.emailExists = function (email) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var exists, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.send('emailexists', {
                                email_b64: this.base64.encode(this.sanitizeEmail(email))
                            })];
                    case 1:
                        exists = _a.sent();
                        return [2 /*return*/, (exists.exists == 1)];
                    case 2:
                        ex_1 = _a.sent();
                        this.log.error('An error occurred checking emailexists, return false');
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProvisionService.prototype.provision = function (source, username, password, referCode, reset, sso) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var exists, provisionData, ex_2, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        username = this.sanitizeEmail(username);
                        return [4 /*yield*/, this.emailExists(username)];
                    case 1:
                        exists = _a.sent();
                        if (!!exists) return [3 /*break*/, 7];
                        provisionData = void 0;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.mkData(username, password, reset, sso)];
                    case 3:
                        provisionData = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        ex_2 = _a.sent();
                        throw new models_1.ErrCode(8106);
                    case 5:
                        if (!provisionData) {
                            throw new models_1.ErrCode(8106);
                        }
                        provisionData.source = source;
                        provisionData.signup.referral_code = referCode;
                        return [4 /*yield*/, this.api.send('provision2', provisionData)];
                    case 6:
                        result = _a.sent();
                        this.log.info('Successful provision ' + username + ' with id: ' + result.user_id);
                        return [2 /*return*/, result];
                    case 7: throw new models_1.ErrCode(8102);
                }
            });
        });
    };
    ProvisionService.prototype.mkData = function (username, password, passReset, sso) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.worker.execute(username, password, passReset, sso)];
                    case 1:
                        data = _a.sent();
                        this.worker.completed();
                        return [2 /*return*/, data];
                }
            });
        });
    };
    ProvisionService.prototype.validEmail = function (email) {
        return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email);
    };
    ProvisionService.prototype.sanitizeEmail = function (email) {
        return email.toLowerCase().trim();
    };
    /**
     * We have created the order when placeholder create for legacy to team+ migration.
     * @param acctkey string
     * @returns
     */
    ProvisionService.prototype.createNewOrderForMigration = function (acctkey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.send('neworderformigration', { acctkey: acctkey })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, (result.success == 1)];
                    case 2:
                        err_1 = _a.sent();
                        this.handleError(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
    * Since provision tends to have a large payload such as encrypted keys, we don't need to JWT
    * token sign them.  Provision is traditionally a public API so this will ensure that sending
    * this call without JWT authentication.
    *
    * If this becomes JWT authenticated, the request headers end up containing the entire request
    * which can (given other headers) result in the server rejecting the connection due to
    * "Request Headers Too Large".
    *
    * The only real difference between this and "post()" function is that we do not sign via the
    * getHeaders() function.
    */
    ProvisionService.prototype.handleAcceptedInviteConsent = function (params, retryEnabled) {
        if (retryEnabled === void 0) { retryEnabled = true; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.orderspath + "/api/v1/accounts/provision";
                        return [4 /*yield*/, this.httpClient.post(url, params)
                                .pipe(operators_1.map(function (result) { return _this.success(result, url); }), operators_1.retryWhen(function (errors) { return _this.retry(errors, params, retryEnabled); }))
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Account tranfer then we call on success.
     */
    ProvisionService.prototype.success = function (result, url) {
        if (result.success === false) {
            this.handleError(result);
        }
        else if (result.success === true) {
            this.log.info("200 " + url + "  successful");
            return result;
        }
        else {
            this.log.warn("Received a response without a success param for " + url);
            return result;
        }
    };
    ProvisionService.prototype.handleError = function (result) {
        throw new Error('Method not implemented.');
    };
    /**
     * Facing error on retry call.
     * @param errors
     * @param params
     * @param retryEnabled
     * @returns
     */
    ProvisionService.prototype.retry = function (errors, params, retryEnabled) {
        var _this = this;
        return errors.pipe(operators_1.delay(1000), operators_1.take(3), operators_1.switchMap(function (httpErr) {
            if ((httpErr instanceof http_1.HttpErrorResponse) && retryEnabled === true) {
                var shouldRetry = rxjs_1.of(httpErr); // observableThrowError(httpErr);
                _this.log.error("ERROR INPUT: " + JSON.stringify(params));
                _this.log.error("ERROR OUTPUT: " + httpErr.status + " " + httpErr.statusText + " :: " + httpErr.url);
                // this.log.error(`Error output ${JSON.stringify(response)}`);
                switch (httpErr.status) {
                    case 401:
                    case 400:
                    case 429:
                    case 500:
                        // do not retry, this is an error
                        shouldRetry = rxjs_1.throwError(httpErr);
                        break;
                    case 501:
                    case 502:
                    case 503:
                    case 504:
                        // this may be a temporary error
                        // shouldRetry = observableThrowError(httpErr);
                        break;
                    default:
                        // default is to retry
                        shouldRetry = rxjs_1.throwError(httpErr);
                        break;
                }
                return shouldRetry;
            }
            else {
                // httpErr is an API result
                return rxjs_1.throwError(httpErr);
            }
        }), function (o) { return rxjs_1.concat(o, rxjs_1.throwError(new Error('90002'))); });
    };
    /**
     * We have add not in SPAIN when user declined to invitation.
     * @param userId number;
     * @returns boolean;
     */
    ProvisionService.prototype.addDeclinedNoteInSPIN = function (userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.send('useraddnote', { user_id: userId, action: 'declined', })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, (result.success == 1)];
                    case 2:
                        err_2 = _a.sent();
                        this.handleError(err_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProvisionService.ngInjectableDef = i0.defineInjectable({ factory: function ProvisionService_Factory() { return new ProvisionService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.Base64Service), i0.inject(i4.LoggerService), i0.inject(i5.ProvisionWorkerService)); }, token: ProvisionService, providedIn: "root" });
    return ProvisionService;
}());
exports.ProvisionService = ProvisionService;
