"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var download_service_1 = require("./services/download.service");
var logger_service_1 = require("../core/logger.service");
var transfer_item_service_1 = require("./services/transfer-item.service");
var sync_cookie_service_1 = require("../core/sync-cookie.service");
var browser_support_service_1 = require("../core/browser-support.service");
var transfer_component_1 = require("./transfer/transfer.component");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var build_transfer_item_service_1 = require("./services/build-transfer-item.service");
var constants_1 = require("./constants");
var transfer_model_1 = require("./transfer.model");
var models_1 = require("../shared/models");
var broadcast_service_1 = require("../shared/services/broadcast.service");
var file_item_service_1 = require("../file/file-item.service");
var blend_service_1 = require("../shared/services/blend.service");
var i0 = require("@angular/core");
var i1 = require("../core/browser-support.service");
var i2 = require("../core/sync-cookie.service");
var i3 = require("./services/transfer-item.service");
var i4 = require("./services/download.service");
var i5 = require("../core/logger.service");
var i6 = require("@ng-bootstrap/ng-bootstrap");
var i7 = require("./services/build-transfer-item.service");
var i8 = require("../shared/services/broadcast.service");
var i9 = require("../file/file-item.service");
var i10 = require("../shared/services/blend.service");
var TransferService = /** @class */ (function () {
    function TransferService(browserSupportService, syncCookieService, transferItemService, downloadService, loggerService, modalService, buildTransferItemService, broadcastService, fileItemService, blendService) {
        this.browserSupportService = browserSupportService;
        this.syncCookieService = syncCookieService;
        this.transferItemService = transferItemService;
        this.downloadService = downloadService;
        this.loggerService = loggerService;
        this.modalService = modalService;
        this.buildTransferItemService = buildTransferItemService;
        this.broadcastService = broadcastService;
        this.fileItemService = fileItemService;
        this.blendService = blendService;
    }
    TransferService.prototype.queueUpload = function (files, isDragDrop) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ulQueue, file_ext, mime_types, file_size, i, len, file, mimeType, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ulQueue = [];
                        file_ext = [];
                        mime_types = [];
                        file_size = 0;
                        this.loggerService.info('queueing an upload of ' + files.length);
                        if (!this.browserSupportService.testFileUpload()) {
                            this.loggerService.error('files were attempted to be uploaded but the browser does not support it');
                            return [2 /*return*/];
                        }
                        for (i = 0, len = files.length; i < len; i++) {
                            file = files[i];
                            if (file) {
                                if (file.name.length <= constants_1.Constants.MAX_FILE_NAME_LEN) {
                                    ulQueue.push(this.buildTransferItemService.mkuploadItem(file));
                                }
                                file_ext.push(this.fileItemService.getFileExt(file.name));
                                mimeType = (this.fileItemService.getmimeType(this.fileItemService.getFileExt(file.name)));
                                if (mime_types.indexOf(mimeType) === -1) {
                                    mime_types.push(mimeType);
                                }
                                file_size += file.size;
                                this.loggerService.warn("Skipping file upload as length of filename exceeded " + constants_1.Constants.MAX_FILE_NAME_LEN + " characters");
                            }
                        }
                        return [4 /*yield*/, Promise.all(ulQueue)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.buildTransferItemService.addToQueue(res, transfer_model_1.TransferQueueType.UPLOAD)];
                    case 2:
                        _a.sent();
                        this.showTransfer();
                        this.blendService.track(models_1.BlendEvent.UPLOAD_FILE, {
                            isDragDrop: isDragDrop ? 'Yes' : 'No',
                            mimeType: mime_types,
                            fileSize: this.fileItemService.bytesToSize(file_size)
                        });
                        return [2 /*return*/, true];
                }
            });
        });
    };
    TransferService.prototype.queueDownload = function (files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dlQueue, mimeTypes, total_size, file_ext, keyData, i, len, mimeType, result;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dlQueue = [], mimeTypes = [];
                        total_size = 0;
                        file_ext = [];
                        return [4 /*yield*/, this.buildTransferItemService.getFileKeys(files).catch(function (err) { return _this.handleKeyError(err); })];
                    case 1:
                        keyData = _a.sent();
                        for (i = 0, len = files.length; i < len; i++) {
                            files[i].blobtype = 'btFILE';
                            dlQueue.push(this.buildTransferItemService.mkDownloadItem(files[i], keyData));
                            mimeType = files[i].mime_type;
                            if (mimeTypes.indexOf(mimeType) === -1) {
                                mimeTypes.push(mimeType);
                            }
                            total_size = total_size + files[i].size;
                            file_ext.push(files[i].file_extension);
                        }
                        return [4 /*yield*/, Promise.all(dlQueue).catch(function (err) {
                                _this.loggerService.error('Error queing a file');
                                throw new Error(err);
                            })];
                    case 2:
                        result = _a.sent();
                        return [4 /*yield*/, this.buildTransferItemService.addToQueue(result, transfer_model_1.TransferQueueType.DOWNLOAD).catch(function (err) {
                                _this.loggerService.error('Error queuing a file for download');
                                throw new Error(err);
                            })];
                    case 3:
                        _a.sent();
                        this.showTransfer();
                        this.broadcastService.broadcast('sync.track.blend', {
                            eventName: models_1.BlendEvent.DOWNLOAD_FILE,
                            parameters: {
                                isCompat: 'No',
                                mimeTypes: mimeTypes,
                                fileSize: this.fileItemService.bytesToSize(total_size)
                            }
                        });
                        return [2 /*return*/, true];
                }
            });
        });
    };
    TransferService.prototype.queuePublicDownload = function (files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dlQueue, keyData, i, len, result;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dlQueue = [];
                        return [4 /*yield*/, this.buildTransferItemService.getFileKeys(files).catch(function (err) { return _this.handleKeyError(err); })];
                    case 1:
                        keyData = _a.sent();
                        for (i = 0, len = files.length; i < len; i++) {
                            files[i].blobtype = 'btFILE';
                            dlQueue.push(this.buildTransferItemService.mkDownloadItem(files[i], keyData));
                        }
                        return [4 /*yield*/, Promise.all(dlQueue).catch(function (err) {
                                _this.loggerService.error('Error queuePublicDownload a file');
                                throw new Error(err);
                            })];
                    case 2:
                        result = _a.sent();
                        return [4 /*yield*/, this.buildTransferItemService.addToQueue(result, transfer_model_1.TransferQueueType.DOWNLOAD).catch(function (err) {
                                _this.loggerService.error('Error queuePublicDownload a file');
                                throw new Error(err);
                            })];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, true];
                }
            });
        });
    };
    TransferService.prototype.queuePublicUpload = function (files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ulQueue, i, len, file, res;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ulQueue = [];
                        this.loggerService.info('queueing a public upload of ' + files.length);
                        if (!this.browserSupportService.testFileUpload()) {
                            this.loggerService.error('files were attempted to be uploaded but the browser does not support it');
                            return [2 /*return*/, new Error('files were attempted to be uploaded but the browser does not support it')];
                        }
                        for (i = 0, len = files.length; i < len; i++) {
                            file = files[i];
                            if (file && file.size !== 0) {
                                ulQueue.push(this.buildTransferItemService.mkPublicUploadItem(file));
                            }
                            else {
                                this.loggerService.warn('skipping a 0 byte file');
                            }
                        }
                        return [4 /*yield*/, Promise.all(ulQueue).catch(function (err) {
                                _this.loggerService.error('An error queing upload');
                                throw Error(err);
                            })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.buildTransferItemService.addToQueue(res, transfer_model_1.TransferQueueType.UPLOAD).catch(function (err) {
                                _this.loggerService.error('An error queuing upload');
                                throw Error(err);
                            })];
                    case 2:
                        _a.sent();
                        this.showTransfer();
                        return [2 /*return*/, true];
                }
            });
        });
    };
    /**
     * Downloads a specific file, bypassing the queue.  Used for previews.
     * Since previews can occur in a compat link, it must also handle this
     * case where no decryption occurs and the 'renderFile' object is
     * filled out with the necessary URL
     * @param  {Object} pathitem  A pathitem object from a Path List
     * @return {Promise} Promise chain of downloading a file
     */
    TransferService.prototype.getFile = function (file, preview) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tItem, keyData, tItem, url, tItem_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!file.blobtype) {
                            file.blobtype = 'btFILE';
                        }
                        if (!(file.compat && file.context == 'applink' && file.compaturl)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.buildTransferItemService.mkDownloadItem(file, undefined)];
                    case 1:
                        tItem = _a.sent();
                        tItem.type = this.transferItemService.TYPE_PREVIEW;
                        if (tItem.linkpasswordlock) {
                            this.syncCookieService.setDownloadPubLink(tItem.linkpasswordlock);
                        }
                        this.buildTransferItemService.view.single = tItem;
                        this.buildTransferItemService.view.single.renderFile = {
                            dl_ready: true,
                            type: 'url',
                            url: file.compaturl,
                            savemethod: 'dlattr',
                        };
                        return [2 /*return*/, this.buildTransferItemService.view.single];
                    case 2: return [4 /*yield*/, this.buildTransferItemService.getFileKeys([file]).catch(function (err) {
                            _this.loggerService.error('Could not get file');
                            throw err;
                        })];
                    case 3:
                        keyData = _a.sent();
                        return [4 /*yield*/, this.buildTransferItemService.mkDownloadItem(file, keyData).catch(function (err) {
                                _this.loggerService.error('Could not get file');
                                throw err;
                            })];
                    case 4:
                        tItem = _a.sent();
                        tItem.type = this.transferItemService.TYPE_PREVIEW;
                        this.buildTransferItemService.view.single = tItem;
                        if (!(file.compat && !this.isImageHeic(file))) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.buildTransferItemService.mkCompatUrl(tItem, this.buildTransferItemService.ACT_PREVIEW).catch(function (err) { throw err; })];
                    case 5:
                        url = _a.sent();
                        this.buildTransferItemService.view.single.renderFile = {
                            dl_ready: true,
                            type: 'url',
                            url: url,
                            savemethod: 'dlattr',
                        };
                        return [2 /*return*/, this.buildTransferItemService.view.single];
                    case 6:
                        if (!(preview === true)) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.downloadService.downloadPreview(this.buildTransferItemService.view.single).catch(function (err) { throw err; })];
                    case 7:
                        tItem_1 = _a.sent();
                        return [3 /*break*/, 10];
                    case 8: return [4 /*yield*/, this.downloadService.downloadItem(this.buildTransferItemService.view.single).catch(function (err) { throw err; })];
                    case 9:
                        tItem_1 = _a.sent();
                        _a.label = 10;
                    case 10: return [2 /*return*/, tItem_1];
                }
            });
        });
    };
    TransferService.prototype.getFileDocPreview = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tItem, keyData, tItem;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!file.blobtype) {
                            file.blobtype = 'btFILE';
                        }
                        if (!(file.compat && file.context == 'applink' && file.compaturl)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.buildTransferItemService.mkDownloadItem(file, undefined)];
                    case 1:
                        tItem = _a.sent();
                        tItem.type = this.transferItemService.TYPE_PREVIEW;
                        if (tItem.linkpasswordlock) {
                            this.syncCookieService.setDownloadPubLink(tItem.linkpasswordlock);
                        }
                        tItem.previewtoken = file.previewtoken;
                        this.buildTransferItemService.view.single = tItem;
                        this.buildTransferItemService.view.single.renderFile = {
                            dl_ready: true,
                            type: 'url',
                            url: file.compaturl,
                            savemethod: 'dlattr',
                        };
                        return [4 /*yield*/, this.downloadService.downloadCompat(this.buildTransferItemService.view.single).catch(function (err) { throw err; })];
                    case 2:
                        tItem = _a.sent();
                        return [2 /*return*/, tItem];
                    case 3: return [4 /*yield*/, this.buildTransferItemService.getFileKeys([file]).catch(function (err) { throw err; })];
                    case 4:
                        keyData = _a.sent();
                        return [4 /*yield*/, this.buildTransferItemService.mkDownloadItem(file, keyData).catch(function (err) { throw err; })];
                    case 5:
                        tItem = _a.sent();
                        tItem.type = this.transferItemService.TYPE_PREVIEW;
                        this.buildTransferItemService.view.single = tItem;
                        return [4 /*yield*/, this.downloadService.downloadPreview(this.buildTransferItemService.view.single).catch(function (err) { throw err; })];
                    case 6:
                        tItem = _a.sent();
                        return [2 /*return*/, tItem];
                }
            });
        });
    };
    TransferService.prototype.isImageHeic = function (item) {
        var name = item.name;
        var ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
        var canPreview = false;
        switch (ext) {
            case 'heic':
            case 'heif':
                canPreview = item.has_thumb2 != 0;
                break;
            default:
                canPreview = false;
        }
        return canPreview && ext != name.toLowerCase();
    };
    /**
     * @ngdoc method
     * @name  getThumb
     * @methodOf sync.service:Transfer
     * @description
     * Gets a thumbnail for a given path item, typically used in previews.
     * The thumbnail is stored using thumb storage
     *
     * @param  {Object} pathitem [description]
     * @param  {Array.Object} keyData  [description]
     * @param  {Array|ArrayBuffer} bytes    [description]
     * @return {Promise}          [description]
     */
    TransferService.prototype.getThumb = function (file, keyData, bytes) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tItem;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        file.blobtype = file.thumbData.blobtype;
                        return [4 /*yield*/, this.buildTransferItemService.mkDownloadItem(file, keyData)];
                    case 1:
                        tItem = _a.sent();
                        return [4 /*yield*/, this.downloadService.saveThumb(tItem, bytes)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    TransferService.prototype.reset = function () {
        var _a = this.buildTransferItemService.view, download = _a.download, upload = _a.upload;
        [download, upload].forEach(function (view) {
            view.queue = [];
            view.queueIdx = 0;
            view.isProcessing = false;
        });
    };
    TransferService.prototype.showTransfer = function () {
        var _this = this;
        this.hideTransfer();
        this.ModalInstance = this.modalService.open(transfer_component_1.TransferComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
            container: '#upload-modal'
        });
        this.ModalInstance.result.then(function (ShouldClearCanceledItem) {
            if (ShouldClearCanceledItem === void 0) { ShouldClearCanceledItem = false; }
            if (ShouldClearCanceledItem) {
                _this.removeCanceledItemsFromQueue();
            }
        }, function () {
            _this.removeCanceledItemsFromQueue();
        });
    };
    TransferService.prototype.hideTransfer = function () {
        if (this.ModalInstance) {
            this.ModalInstance.close();
        }
    };
    TransferService.prototype.handleKeyError = function (err) {
        this.loggerService.error('Error retrieving keys');
        this.loggerService.error(err);
        try {
            var data = JSON.stringify(err);
            this.loggerService.error(data);
        }
        catch (e) {
            this.loggerService.error(e.toString());
        }
        return new Error(err);
    };
    /**
     * queue upload for blank document
     * @param file
     * @returns
     */
    TransferService.prototype.queuePublicBlankDocUpload = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ulQueue, res;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ulQueue = [];
                        this.loggerService.info('queueing a public upload of ' + file.size);
                        if (!this.browserSupportService.testFileUpload()) {
                            this.loggerService.error('files were attempted to be uploaded but the browser does not support it');
                            return [2 /*return*/];
                        }
                        if (file && file.size !== 0) {
                            ulQueue.push(this.buildTransferItemService.mkuploadItem(file));
                        }
                        else {
                            this.loggerService.warn('skipping a 0 byte file');
                        }
                        return [4 /*yield*/, Promise.all(ulQueue).catch(function (err) {
                                _this.loggerService.error('An error queing upload');
                                throw err;
                            })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.buildTransferItemService.addToQueue(res, transfer_model_1.TransferQueueType.UPLOAD)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, true];
                }
            });
        });
    };
    TransferService.prototype.removeCanceledItemsFromQueue = function () {
        var _a = this.buildTransferItemService.view, download = _a.download, upload = _a.upload;
        download.queue = download.queue.filter(function (transferItem) { return transferItem.status != transfer_model_1.TransferStatus.STATUS_UPLOAD_CANCELLED; });
        upload.queue = upload.queue.filter(function (transferItem) { return transferItem.status != transfer_model_1.TransferStatus.STATUS_UPLOAD_CANCELLED; });
    };
    TransferService.ngInjectableDef = i0.defineInjectable({ factory: function TransferService_Factory() { return new TransferService(i0.inject(i1.BrowserSupportService), i0.inject(i2.SyncCookieService), i0.inject(i3.TransferItemService), i0.inject(i4.DownloadService), i0.inject(i5.LoggerService), i0.inject(i6.NgbModal), i0.inject(i7.BuildTransferItemService), i0.inject(i8.BroadcastService), i0.inject(i9.FileItemService), i0.inject(i10.BlendService)); }, token: TransferService, providedIn: "root" });
    return TransferService;
}());
exports.TransferService = TransferService;
