"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var zuora_account_service_1 = require("../services/zuora-account.service");
var logger_service_1 = require("../../core/logger.service");
var api_service_1 = require("../../core/api.service");
var sync_cookie_service_1 = require("../../core/sync-cookie.service");
var environment_1 = require("../../../environments/environment");
var user_service_1 = require("../../core/user.service");
var router_1 = require("@angular/router");
var sku_service_1 = require("../../shared/services/sku.service");
var plandetails_model_1 = require("../../../../src/app/shared/models/api/plandetails.model");
var blend_service_1 = require("../../shared/services/blend.service");
var BillingStatusComponent = /** @class */ (function () {
    function BillingStatusComponent(api, log, syncCookie, zuora, userService, router, skuService, blendService) {
        this.api = api;
        this.log = log;
        this.syncCookie = syncCookie;
        this.zuora = zuora;
        this.userService = userService;
        this.router = router;
        this.skuService = skuService;
        this.blendService = blendService;
        this.zbillingqueue = [];
        this.zbillingqueuepending = [];
        this.permissionActions = models_1.PermissionActions;
    }
    BillingStatusComponent.prototype.ngOnInit = function () {
        this.planName = this.user.plan_name;
        if (this.checkPermission(this.permissionActions.VIEW_BILLING)) {
            this.loadBillingProfile();
        }
        else {
            this.planExpiry = this.user.account_duedate;
            this.planExpiryClose = (this.planExpiry <= Date.now() + 86400000 * 30);
        }
    };
    BillingStatusComponent.prototype.checkPermission = function (permName) {
        return this.userService.checkPermission(permName);
    };
    BillingStatusComponent.prototype.loadBillingProfile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.zuora.getProfileAndQueue()];
                    case 1:
                        data = _a.sent();
                        this.zprofile = data.zprofile;
                        this.zbillingqueue = data.zbillingqueue;
                        this.zbillingqueuepending = this.zbillingqueue.filter(function (bq) { return bq.status === 'pending'; });
                        if (this.zprofile.subscription) {
                            this.planName = this.zprofile.subscription.productFullName;
                            this.planSku = this.zprofile.subscription.sku;
                            this.cancelInFuture = this.zprofile.subscription.status === 'Cancelled';
                            this.planExpiry = this.user.is_on_trial
                                ? this.userService.dateConversionForTrials(this.zprofile.subscription.termEndDate, this.user.is_on_trial).getTime()
                                : this.getDateFromTerm(this.zprofile.subscription.termEndDate);
                            this.planStart = this.getDateFromTerm(this.zprofile.subscription.termStartDate);
                            this.paidThru = this.getDateFromTerm(this.zprofile.paidThroughDate);
                            this.currentTerm = this.skuService.getPlanTerm(this.planSku);
                            switch (this.currentTerm) {
                                case plandetails_model_1.PlanTerm.ANNUAL:
                                    this.planExpiryClose = (this.planExpiry <= Date.now() + 86400000 * 30);
                                    break;
                                case plandetails_model_1.PlanTerm.MONTHLY:
                                    this.planExpiryClose = (this.planExpiry <= Date.now() + 86400000 * 15);
                                    break;
                                default:
                                    this.planExpiryClose = (this.planExpiry <= Date.now() + 86400000 * 30);
                                    break;
                            }
                            console.log(this.planExpiry + " <= " + (Date.now() + 8640000 * 30) + " > 86400000 * 30");
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        ex_1 = _a.sent();
                        this.log.e('Error loading billing profile', ex_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BillingStatusComponent.prototype.canRenew = function () {
        return ((this.planExpiry && this.planExpiryClose) || (this.zprofile && this.zprofile.balance !== 0)) && !this.cancelInFuture && !this.user.is_on_trial;
    };
    BillingStatusComponent.prototype.renew = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.checkPermission(this.permissionActions.MANAGE_BILLING)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.api.execute('ordersessionnew', {})];
                    case 1:
                        result = _a.sent();
                        if (result && result.sessionId) {
                            this.syncCookie.setOrderSessionId(result.sessionId);
                            window.location.href = environment_1.environment.ordershost + "/renew";
                            console.log('this user is in zuora, redirect to renew');
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    BillingStatusComponent.prototype.onUpgradeClick = function () {
        if (this.user.is_on_trial && this.zprofile && !this.zprofile.defaultPaymentMethod) {
            this.router.navigate(['/account/billing/profile']);
        }
        else {
            this.blendService.addEventsForUpgradeButton();
            this.router.navigate(['/account/upgrade']);
        }
    };
    BillingStatusComponent.prototype.getDateFromTerm = function (term) {
        var termDateStrings = term.split('-');
        if (termDateStrings.length !== 3) {
            this.log.error("An invalid term received " + term);
        }
        return new Date(parseInt(termDateStrings[0], 10), parseInt(termDateStrings[1], 10) - 1, // js date months starts at 0
        parseInt(termDateStrings[2], 10)).getTime();
    };
    return BillingStatusComponent;
}());
exports.BillingStatusComponent = BillingStatusComponent;
