"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var link_list_component_1 = require("./link-list/link-list.component");
var footer_layout_component_1 = require("../layout/footer-layout/footer-layout.component");
var routes = [
    {
        path: '', component: footer_layout_component_1.FooterLayoutComponent, pathMatch: 'full',
        children: [
            { path: '', component: link_list_component_1.LinkListComponent },
        ]
    }
];
var LinksRoutingModule = /** @class */ (function () {
    function LinksRoutingModule() {
    }
    return LinksRoutingModule;
}());
exports.LinksRoutingModule = LinksRoutingModule;
