
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LogItem, ErrCode } from '../shared/models';

@Injectable()

export class LoggerService {
    private showLogger: boolean;

    private logs: LogItem[] = [];
    private keepLogs: LogItem[] = [];
    private _log: Function;
    private _error: Function;
    private _warn: Function;
    constructor() {
        this.showLogger = !environment.production;
        this._log = console.log;
        this._error = console.error;
        this._warn = console.warn;
        console.log = console.info = (msg: any, data?: any) => {
            if (data) {
                this.info(this.getMsg(msg) + ' ' + this.getMsg(data));
            } else {
                this.info(this.getMsg(msg));
            }
        };
        console.error = (msg: any, data?: any) => {
            if (data) {
                this.error(this.getMsg(msg) + ' ' + this.getMsg(data));
            } else {
                this.error(this.getMsg(msg));
            }
        };
        console.warn = (msg: any, data?: any) => {
            if (data) {
                this.warn(this.getMsg(msg) + ' ' + this.getMsg(data));
            } else {
                this.warn(this.getMsg(msg));
            }
        };
   }

    /**
     * @ngdoc method
     * @name  info
     * @methodOf sync.service:Logger
     * @description
     * Logs an informational message and stores it with a timestamp.
     * @param  {String} msg The message to log
     */
    public info(msg: any) {
        const log: LogItem = {msg: this.getMsg(msg), type: 'info', date: Date.now()};
        this.logs.push(log);
        if (this.logs.length >= 1000) {
            this.logs.splice(this.logs.length - 1000);
        }
        if (this.showLogger) {
            this._log(msg);
        }
    }


    /**
     * @ngdoc method
     * @name  warn
     * @methodOf sync.service:Logger
     * @description
     * Logs an warning message and stores it with a timestamp.
     * @param  {String} msg The message to log
     */
    public warn(msg: any) {
        const log: LogItem = {msg: this.getMsg(msg), type: 'warn', date: Date.now()};
        this.keepLogs.push(log);
        this._warn(msg);
    }

    /**
     * Logs an error message and stores it with a timestamp.
     * @param  {String} msg The message to log
     */
    public error(msg: any): void {
        const log: LogItem = {msg: this.getMsg(msg), type: 'danger', date: Date.now()};
        this.keepLogs.push(log);
        this._error(msg);
        // console.trace();
    }

    public errcode(e: ErrCode) {
        this.error(['ErrCode: ', e.code, ' : ', e.msg].join(''));
    }

    public d(msg: any) {
        if (!environment.production) {
            // tslint:disable-next-line: no-console
            console.debug(msg);
        }
    }

    public e(msg: any, ex: Error|ErrCode|DOMError): void {
        this.error(msg);
        if (ex instanceof ErrCode) {
            this.errcode(ex);
        } else if (ex instanceof Error || ex instanceof DOMError) {
            this.error(ex);
        }
    }

    public handleError(tag: string, data: any, reject: boolean = true): ErrCode {
        const err = new ErrCode(1000);
        if (typeof data === 'object') {
            if (data.error_code) {
                err.code = data.error_code;
            } else if (data.code) {
                err.code = data.code;
            } else {
            }
        } else if (typeof data === 'string') {
            // assume this is a regular error message
            err.msg = data;
        }
        this.error([
            tag, ':: - code: ',
            err.code, ' msg: ', err.msg
        ].join(''));

        if (reject) {
            throw (err);
        }
        return err;

    }

    /**
     * @ngdoc method
     * @name  apiError
     * @methodOf sync.service:Logger
     * @description
     * Handles api errors by logging the error message from the API error hash
     * @param  {Object} msg The standard API error hash
     * @return {Promise}    A rejected promise
     */
    public apiError(msg: any) {
        const errMsg = (typeof msg === 'object' && msg.error_msg)
            ? msg.error_msg
            : msg;
        this.error(errMsg);
        return Promise.reject(errMsg);
    }

    /**
     * @ngdoc method
     * @name  dumpLogsText
     * @methodOf sync.service:Logger
     * @description
     * Formats a given type of logs into a string, parsing the timestamp.
     * This will be human readable and sorted by date
     * @param  {String} type 'info', 'error', 'warn' are valid types
     * @return {String}      The resulting logs with \n characters
     */
    public dumpLogsText(type: string): string {
        const logs = this.logs.concat(this.keepLogs);
        const formatted: string[] = [];
        if (this.logs.length >= 1000) {
            formatted.push('-- Potentially truncated --\n\n');
        }
        logs.sort((a, b) => {
            return a.date - b.date;
        });
        // logs = this.$filter('orderBy')(logs, 'date', true);
        for (let i = 0, len = logs.length; i < len; i++) {
            const d = new Date(logs[i].date);
            formatted.push(['(', logs[i].type, ') ',
                d.toLocaleString(),
                ' - ' , logs[i].msg ].join(''));
        }
        return formatted.join('\n\n');
    }

    /**
     * @ngdoc method
     * @name  clear
     * @methodOf sync.service:Logger
     * @description
     * Clear the stored logs
     */
    public clear() {
        this.logs = [];
        this.keepLogs = [];
    }

    private getMsg(msg: any): string {
        if (typeof msg === 'string') {
            return msg;
        } else {
            try {
                return JSON.stringify(msg);
            } catch (e) {
                this._error('An error occurred writing log ' + e);
                return msg;
            }
        }
    }
}
