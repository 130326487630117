"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
/**
 * @description
 * Filter that uppercases the first letter of a string.  It works off words,
 * so if your input string is multiple words, e.g., "sync agent" it will
 * only return `Sync agent`.  It will not capitalize any other words in the
 * input
 */
var UcFirstPipe = /** @class */ (function () {
    function UcFirstPipe() {
    }
    UcFirstPipe.prototype.transform = function (str) {
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
    };
    return UcFirstPipe;
}());
exports.UcFirstPipe = UcFirstPipe;
