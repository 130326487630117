"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var logger_service_1 = require("./logger.service");
var crypt_engine_model_1 = require("../shared/models/crypt-engine.model");
var i0 = require("@angular/core");
var i1 = require("./logger.service");
var BrowserSupportService = /** @class */ (function () {
    function BrowserSupportService(log, window) {
        this.log = log;
        this.window = window;
        this.getEncryptionEngine();
    }
    /**
     * @ngdoc method
     * @name  testblob
     * @methodOf sync.service:BrowserSupport
     * @description
     * Tests if Blob exists and we are able to make blob urls
     * @return boolean
     */
    BrowserSupportService.prototype.testBlob = function () {
        if (!window.Blob || typeof window.Blob.prototype.slice !== 'function') {
            return false;
        }
        var urlObj = window.URL || window.webkitURL;
        if (!urlObj) {
            return false;
        }
        try {
            return !!new Blob();
        }
        catch (e) {
            return false;
        }
    };
    /**
     * @ngdoc method
     * @name  testXhr2
     * @methodOf sync.service:BrowserSupport
     * @description
     * Tests whether the newer XHR2 is available.
     * @return {bool} true if feature passes
     */
    BrowserSupportService.prototype.testXhr2 = function () {
        return (new XMLHttpRequest().upload) ? true : false;
    };
    // test save Methods
    /**
     * @ngdoc method
     * @name  testDownloadAttr
     * @methodOf sync.service:BrowserSupport
     * @description
     * Tests if the download attribute is available on <a> tags.
     * @return {bool} true if feature passes
     */
    BrowserSupportService.prototype.testDownloadAttr = function () {
        return ('download' in document.createElement('a'));
    };
    /**
     * @ngdoc method
     * @name  testMsSaveBlob
     * @methodOf sync.service:BrowserSupport
     * @description
     * Tests if Microsoft 10+ save blob is available.
     * @return {bool} true if feature passes
     */
    BrowserSupportService.prototype.testMsSaveBlob = function () {
        return (navigator.msSaveOrOpenBlob) ? true : false;
    };
    /**
     * @ngdoc method
     * @name testDirUpload
     * @methodOf sync.service:BrowserSupport
     * @description tests if the browser supports dir uploads
     * @return {bool}
     */
    BrowserSupportService.prototype.testDirUpload = function () {
        // var input = document.createElement("input");
        // input.type = "file";
        // return !!("webkitdirectory" in (input || document.querySelectorAll("input[type=file]")[0] ))
        return (typeof DataTransferItem.prototype.webkitGetAsEntry === 'function');
    };
    /**
     * @ngdoc method
     * @name  getFileSaveMethod
     * @methodOf sync.service:BrowserSupport
     * @description
     * Gets the file save method as string.  The strings returned here are
     * handled in other directives/factories
     * @return {String} The name of the save method
     */
    BrowserSupportService.prototype.getFileSaveMethod = function () {
        if (this.testBlob() && this.testMsSaveBlob()) {
            return 'msblob';
        }
        else if (this.testDownloadAttr()) {
            return 'dlattr';
        }
        else {
            return 'none';
        }
    };
    /**
     * @ngdoc method
     * @name  getFileStorageMethod
     * @methodOf sync.service:BrowserSupport
     * @description
     * Gets the file storage method available to this browser cascading
     * to in-memory as the worst storage method.
     * @return {String} A string name for the storage method.
     */
    BrowserSupportService.prototype.getFileStorageMethod = function () {
        return (this.testFileSystemWriter()) ? 'filesystem' : 'memory';
    };
    BrowserSupportService.prototype.getEncryptionEngine = function () {
        if (!this.ENCENGINE || this.ENCENGINE === undefined) {
            if (this.getCrypto() && this.getCryptoSubtle()) {
                this.ENCENGINE = crypt_engine_model_1.CryptEngine.NATIVE;
            }
            else if (this.testWorker()) {
                this.ENCENGINE = crypt_engine_model_1.CryptEngine.BUFFER;
            }
            else {
                this.ENCENGINE = crypt_engine_model_1.CryptEngine.LEGACY;
            }
        }
        return this.ENCENGINE;
    };
    BrowserSupportService.prototype.getCrypto = function () {
        return window.crypto || window.msCrypto;
    };
    BrowserSupportService.prototype.getCryptoSubtle = function () {
        var crypto = this.getCrypto();
        if (crypto && crypto.subtle) {
            return crypto.subtle;
        }
        else if (crypto && crypto.webkitSubtle) {
            return crypto.webkitSubtle;
        }
        else {
            return null;
        }
    };
    /**
     * @ngdoc method
     * @name  testFileUpload
     * @methodOf sync.service:BrowserSupport
     * @description
     * tests if the browser can upload files
     * @return {bool} true if feature passes
     */
    BrowserSupportService.prototype.testFileUpload = function () {
        if (!this.testBlob()) {
            this.log.info('Blob constructor fails');
        }
        return (this.testBlob() && this.testXhr2() && !!window.FileReader);
    };
    /**
     * @ngdoc method
     * @name  testFileSystemWriter
     * @methodOf sync.service:BrowserSupport
     * @description
     * tests if the FileSystem writer API is available
     * @return {bool} true if feature passes
     */
    BrowserSupportService.prototype.testFileSystemWriter = function () {
        window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;
        return (!!window.File && !!window.FileReader && !!window.FileList &&
            !!window.Blob && !!window.ArrayBuffer &&
            !!window.DataView && !!window.requestFileSystem && !!window.Worker);
    };
    /**
     * @ngdoc method
     * @name  testWorker
     * @methodOf sync.service:BrowserSupport
     * @description
     * Tests for Web Worker support
     * @return {bool} true if feature passes
     */
    BrowserSupportService.prototype.testWorker = function () {
        return (window.Worker !== undefined);
    };
    BrowserSupportService.prototype.testCryptoSubtleImportKey = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cryptoObj, key, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cryptoObj = window.crypto || window.msCrypto;
                        if (!cryptoObj || !cryptoObj.subtle) {
                            return [2 /*return*/, false];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, cryptoObj.subtle.importKey('raw', new Uint8Array([
                                122, 94, 39, 230, 46, 23, 151, 80, 131, 230, 3, 101, 80, 234, 143, 9, 251,
                                152, 229, 228, 89, 222, 31, 135, 214, 104, 55, 68, 67, 59, 5, 51
                            ]), 'AES-GCM', false, ['encrypt', 'decrypt'])];
                    case 2:
                        key = _a.sent();
                        return [2 /*return*/, (key != undefined)];
                    case 3:
                        ex_1 = _a.sent();
                        this.log.e('Crypto subtle failed import key', ex_1);
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BrowserSupportService.prototype.testCryptoSubtleDecrypt = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cryptoObj, TESTBYTES, key, data, ex_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cryptoObj = window.crypto || window.msCrypto, TESTBYTES = new Uint8Array([1, 2, 3, 4]);
                        if (!cryptoObj || !cryptoObj.subtle) {
                            return [2 /*return*/, false];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, cryptoObj.subtle.generateKey('AES-GCM', false, ['encrypt'])];
                    case 2:
                        key = _a.sent();
                        return [4 /*yield*/, cryptoObj.subtle.encrypt({
                                name: 'AES-GCM',
                                iv: cryptoObj.getRandomValues(new Uint8Array(12)),
                                additionalData: cryptoObj.getRandomValues(new Uint8Array(256)),
                                tagLength: 128,
                            }, key, TESTBYTES)];
                    case 3:
                        data = _a.sent();
                        return [2 /*return*/, (key != undefined)];
                    case 4:
                        ex_2 = _a.sent();
                        this.log.e('testCryptoSubtleDecrypt failure', ex_2);
                        return [2 /*return*/, false];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BrowserSupportService.ngInjectableDef = i0.defineInjectable({ factory: function BrowserSupportService_Factory() { return new BrowserSupportService(i0.inject(i1.LoggerService), i0.inject("window")); }, token: BrowserSupportService, providedIn: "root" });
    return BrowserSupportService;
}());
exports.BrowserSupportService = BrowserSupportService;
