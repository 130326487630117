"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("../../shared/pipes/order-by.pipe");
var i3 = require("./dir-picker.component");
var i4 = require("../dir-picker.service");
var styles_DirPickerComponent = [];
var RenderType_DirPickerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DirPickerComponent, data: {} });
exports.RenderType_DirPickerComponent = RenderType_DirPickerComponent;
function View_DirPickerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "thead", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "th", [["colspan", "4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerTitle; _ck(_v, 3, 0, currVal_0); }); }
function View_DirPickerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "td", [["class", "table-modalchoosercheck"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "i", [["class", "showhand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectByName("sync") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { "fas fa-check-square ": 0, "far fa-square": 1 }), (_l()(), i0.ɵeld(5, 0, null, null, 1, "td", [["class", "table-modalchoosericon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openRoot("sync") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "img", [["height", "24"], ["src", "images/icons/dir.svg"], ["width", "24"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "td", [["class", "table-modalchoosername"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "tdmore"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "a", [["class", "syncblue showhand"], ["id", "sync-folder-option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openRoot("sync") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Sync Folder"])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "td", [["class", "table-modalchooseroptions"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openRoot("sync") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "i", [["class", "fas fa-angle-right syncblue showhand"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "showhand"; var currVal_1 = _ck(_v, 4, 0, _co.isSelectedByName("sync"), !_co.isSelectedByName("sync")); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_DirPickerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "td", [["class", "table-modalchoosercheck"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "i", [["class", "showhand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectByName("vault") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { "fas fa-check-square": 0, "far fa-square": 1 }), (_l()(), i0.ɵeld(5, 0, null, null, 1, "td", [["class", "table-modalchoosericon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openRoot("vault") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "img", [["height", "24"], ["src", "images/icons/icon-vault.svg"], ["width", "24"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "td", [["class", "table-modalchoosername"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "tdmore"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "a", [["class", "syncblue showhand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openRoot("vault") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Vault"])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "td", [["class", "table-modalchooseroptions"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openRoot("vault") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "i", [["class", "fas fa-angle-right syncblue showhand"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "showhand"; var currVal_1 = _ck(_v, 4, 0, _co.isSelectedByName("vault"), !_co.isSelectedByName("vault")); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_DirPickerComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "tr", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(2, { active: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 3, "td", [["class", "table-modalchoosercheck"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "i", [["class", "showhand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(6, { "fa fa-spin fa-spinner": 0, "fas fa-check-square": 1, "far fa-square": 2 }), (_l()(), i0.ɵeld(7, 0, null, null, 1, "td", [["class", "table-modalchoosericon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cd(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "img", [["height", "24"], ["width", "24"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 3, "td", [["class", "table-modalchoosername"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "tdmore"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "a", [["class", "syncblue showhand"], ["data-sync-stop-propagation", ""]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cd(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Loading ..."])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "td", [["class", "table-modalchooseroptions"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cd(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "i", [["class", "fas fa-angle-right syncblue showhand"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.isSelected(_v.context.$implicit)); _ck(_v, 1, 0, currVal_0); var currVal_1 = "showhand"; var currVal_2 = _ck(_v, 6, 0, _v.context.$implicit.spinner, (!_v.context.$implicit.spinner && _co.isSelected(_v.context.$implicit)), (!_v.context.$implicit.spinner && !_co.isSelected(_v.context.$implicit))); _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = (("images/icons/" + _v.context.$implicit.cssclass) + ".svg"); _ck(_v, 8, 0, currVal_3); var currVal_4 = _v.context.$implicit.name; _ck(_v, 11, 0, currVal_4); }); }
function View_DirPickerComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_DirPickerComponent_5)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵppd(3, 2), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.pickerView.dirlist, "search_name")); _ck(_v, 2, 0, currVal_0); }, null); }
function View_DirPickerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.OrderByPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 16, "ul", [["class", "sync-table"], ["style", "list-style: none; margin: 0px; padding: 0px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 15, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 14, "table", [["class", "table list-table list-table-modal"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DirPickerComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 11, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DirPickerComponent_2)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DirPickerComponent_3)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 4, "tr", [], [[2, "hide", null]], null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 3, "td", [["colspan", "4"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cdUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 2, "span", [["class", "syncblue showhand"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "i", [["class", "fas fa-angle-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Back"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DirPickerComponent_4)), i0.ɵdid(17, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerTitle; _ck(_v, 5, 0, currVal_0); var currVal_1 = !((_co.pickerView == null) ? null : ((_co.pickerView.cwd == null) ? null : _co.pickerView.cwd.sync_id)); _ck(_v, 8, 0, currVal_1); var currVal_2 = !((_co.pickerView == null) ? null : ((_co.pickerView.cwd == null) ? null : _co.pickerView.cwd.sync_id)); _ck(_v, 10, 0, currVal_2); var currVal_4 = ((_co.pickerView == null) ? null : ((_co.pickerView.cwd == null) ? null : _co.pickerView.cwd.sync_id)); _ck(_v, 17, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = (((_co.pickerView == null) ? null : ((_co.pickerView.cwd == null) ? null : _co.pickerView.cwd.pid)) == undefined); _ck(_v, 11, 0, currVal_3); }); }
exports.View_DirPickerComponent_0 = View_DirPickerComponent_0;
function View_DirPickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-dir-picker", [], null, null, null, View_DirPickerComponent_0, RenderType_DirPickerComponent)), i0.ɵdid(1, 114688, null, 0, i3.DirPickerComponent, [i4.DirPickerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DirPickerComponent_Host_0 = View_DirPickerComponent_Host_0;
var DirPickerComponentNgFactory = i0.ɵccf("sync-dir-picker", i3.DirPickerComponent, View_DirPickerComponent_Host_0, { skipShareFolders: "skipShareFolders", headerTitle: "headerTitle" }, {}, []);
exports.DirPickerComponentNgFactory = DirPickerComponentNgFactory;
