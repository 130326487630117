"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DirtyOutKeyItemDataKey = /** @class */ (function () {
    function DirtyOutKeyItemDataKey() {
    }
    return DirtyOutKeyItemDataKey;
}());
exports.DirtyOutKeyItemDataKey = DirtyOutKeyItemDataKey;
var DirtyOutKeyItemShareKey = /** @class */ (function () {
    function DirtyOutKeyItemShareKey() {
    }
    return DirtyOutKeyItemShareKey;
}());
exports.DirtyOutKeyItemShareKey = DirtyOutKeyItemShareKey;
