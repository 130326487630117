"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var models_1 = require("../../shared/models");
var LinkSuspendedComponent = /** @class */ (function () {
    function LinkSuspendedComponent(activeModal) {
        this.activeModal = activeModal;
        this.state = new core_1.EventEmitter();
        this.close = new core_1.EventEmitter();
    }
    LinkSuspendedComponent.prototype.ngOnInit = function () {
    };
    LinkSuspendedComponent.prototype.closeDialog = function () {
        this.close.emit();
    };
    return LinkSuspendedComponent;
}());
exports.LinkSuspendedComponent = LinkSuspendedComponent;
