"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var environment_1 = require("./../../../../../environments/environment");
var core_1 = require("@angular/core");
var user_service_1 = require("../../../../core/user.service");
var fromRoot = require("../../../../reducers");
var store_1 = require("@ngrx/store");
var models_1 = require("../../../models");
var dialog_contact_support_component_1 = require("../../../../account/dialog-contact-support/dialog-contact-support.component");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var logger_service_1 = require("../../../../core/logger.service");
var zuora_account_service_1 = require("../../../../account/services/zuora-account.service");
var router_1 = require("@angular/router");
var blend_service_1 = require("../../../services/blend.service");
var feature_service_1 = require("../../../services/feature.service");
var api_service_1 = require("../../../../core/api.service");
var HeaderUserComponent = /** @class */ (function () {
    function HeaderUserComponent(store, userService, modalService, log, zuora, router, BlendService, api, featureService) {
        this.store = store;
        this.userService = userService;
        this.modalService = modalService;
        this.log = log;
        this.zuora = zuora;
        this.router = router;
        this.BlendService = BlendService;
        this.api = api;
        this.featureService = featureService;
        this.avatarEndpoint = environment_1.environment.logohost + "avatar/";
        this.permissionActions = models_1.PermissionActions;
        this.syncCpHost = environment_1.environment.syncCpHost;
    }
    HeaderUserComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.store.pipe(store_1.select(fromRoot.getAuthUser))
            .subscribe(function (data) {
            _this.user = data;
            _this.loadProfile();
        });
    };
    HeaderUserComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    HeaderUserComponent.prototype.checkPermission = function (permName) {
        return this.userService.checkPermission(permName);
    };
    HeaderUserComponent.prototype.openContactSupport = function (flag) {
        var ref = this.modalService.open(dialog_contact_support_component_1.DialogContactSupportComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        if (flag == 'get_help') {
            this.BlendService.track(models_1.BlendEvent.GET_HELP_EVENT);
        }
        else {
            this.BlendService.track(models_1.BlendEvent.HELP_EVENT);
        }
    };
    HeaderUserComponent.prototype.onUsersClick = function () {
        if ((this.user.is_multi_admin || !this.user.is_multi_admin) &&
            !this.user.is_multi_child &&
            !this.user.is_multi_new) {
            this.log.info('Using old multiuser');
            this.router.navigate(['/multi-user']);
        }
        else if (!this.user.is_multi_admin &&
            this.user.is_multi_child &&
            this.user.is_multi_new) {
            this.log.info('Using new multiadmin');
            if (!this.checkPermission(this.permissionActions.VIEW_USERS) && this.checkPermission(this.permissionActions.VIEW_ROLES)) {
                this.router.navigate(['/multi-user/roles']);
            }
            else {
                this.router.navigate(['/multi-user/users']);
            }
        }
    };
    HeaderUserComponent.prototype.onUpgradeClick = function () {
        if (this.user.is_on_trial && this.zprofile && !this.zprofile.defaultPaymentMethod) {
            this.router.navigate(['/account/billing/profile']);
        }
        else {
            this.BlendService.addEventsForUpgradeButton();
            this.router.navigate(['/account/upgrade']);
        }
    };
    HeaderUserComponent.prototype.toggleNewUi = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newui, result, event_name;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newui = this.user.is_newui_enabled ? 0 : 1;
                        return [4 /*yield*/, this.api.execute('userinfoset', {
                                newui: newui
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.success) {
                            event_name = newui ? models_1.BlendEvent.NEW_UI_OPT_IN : models_1.BlendEvent.NEW_UI_OPT_OUT;
                            this.BlendService.track(event_name, {});
                            this.userService.refresh();
                            if (newui) {
                                window.location.href = environment_1.environment.syncCpHost;
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    HeaderUserComponent.prototype.loadProfile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!this.checkPermission(this.permissionActions.VIEW_BILLING)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.zuora.getProfile()];
                    case 1:
                        _a.zprofile = _d.sent();
                        _d.label = 2;
                    case 2:
                        _b = this;
                        return [4 /*yield*/, this.featureService.isAllowed('recents')];
                    case 3:
                        _b.isRecentsAllowed = _d.sent();
                        _c = this;
                        return [4 /*yield*/, this.featureService.isAllowed('favourite')];
                    case 4:
                        _c.isFavouriteAllowed = _d.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return HeaderUserComponent;
}());
exports.HeaderUserComponent = HeaderUserComponent;
