"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var events_api_service_1 = require("./events-api.service");
var sync_crypt_service_1 = require("../core/crypt/sync-crypt.service");
var file_item_service_1 = require("../file/file-item.service");
var i0 = require("@angular/core");
var i1 = require("./events-api.service");
var i2 = require("../core/crypt/sync-crypt.service");
var i3 = require("../file/file-item.service");
var EventItemService = /** @class */ (function () {
    function EventItemService(eventsApiService, syncCryptService, fileItemService) {
        this.eventsApiService = eventsApiService;
        this.syncCryptService = syncCryptService;
        this.fileItemService = fileItemService;
    }
    EventItemService.prototype.getEvent = function (eventId) {
        return this.eventsApiService.getList({ _id: eventId });
    };
    EventItemService.prototype.format = function (event) {
        if (!event.items) {
            return event;
        }
        if (event.eventtype.indexOf('path-') > -1 && event.items.pathitems) {
            return this.formatPath(event);
        }
        else if (event.eventtype.indexOf('link-') > -1 &&
            event.items.enc_meta_name) {
            return this.formatLink(event);
        }
        else if (event.eventtype.indexOf('share-') > -1 &&
            (event.items.meta_name || event.items.enc_meta_name)) {
            return this.formatLink(event);
        }
        else {
            return event;
        }
    };
    EventItemService.prototype.processPathitem = function (path) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var encMetaName, fileSize;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        encMetaName = path.enc_meta_name || path.meta_name;
                        fileSize = path.size;
                        return [4 /*yield*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var fname, error_1;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!!encMetaName) return [3 /*break*/, 1];
                                            resolve(path);
                                            return [3 /*break*/, 4];
                                        case 1:
                                            _a.trys.push([1, 3, , 4]);
                                            return [4 /*yield*/, this.syncCryptService.filenameDecrypt(encMetaName)];
                                        case 2:
                                            fname = _a.sent();
                                            path.name = fname;
                                            path.file_size = this.fileItemService.bytesToSize(fileSize);
                                            resolve(path);
                                            return [3 /*break*/, 4];
                                        case 3:
                                            error_1 = _a.sent();
                                            path.name = encMetaName;
                                            resolve(path);
                                            return [3 /*break*/, 4];
                                        case 4: return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    EventItemService.prototype.formatLink = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var evtItem;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.processPathitem(event.items)];
                    case 1:
                        evtItem = _a.sent();
                        event.items = evtItem;
                        return [2 /*return*/, event];
                }
            });
        });
    };
    EventItemService.prototype.formatPath = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pathitemPromise, i, len, result, items;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        pathitemPromise = [];
                        for (i = 0, len = event.items.pathitems.length; i < len; i++) {
                            pathitemPromise.push(this.processPathitem(event.items.pathitems[i]));
                        }
                        return [4 /*yield*/, Promise.all(pathitemPromise)];
                    case 1:
                        result = _a.sent();
                        event.items.pathitems = result;
                        if (!event.items.enc_meta_name) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.processPathitem(event.items)];
                    case 2:
                        items = _a.sent();
                        event.items = items;
                        return [2 /*return*/, event];
                    case 3: return [2 /*return*/, event];
                }
            });
        });
    };
    EventItemService.ngInjectableDef = i0.defineInjectable({ factory: function EventItemService_Factory() { return new EventItemService(i0.inject(i1.EventsApiService), i0.inject(i2.SyncCryptService), i0.inject(i3.FileItemService)); }, token: EventItemService, providedIn: "root" });
    return EventItemService;
}());
exports.EventItemService = EventItemService;
