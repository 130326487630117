"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var PreviewLayoutComponent = /** @class */ (function () {
    function PreviewLayoutComponent() {
    }
    PreviewLayoutComponent.prototype.ngOnInit = function () {
    };
    return PreviewLayoutComponent;
}());
exports.PreviewLayoutComponent = PreviewLayoutComponent;
