"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_contact_support_component_1 = require("../../../../account/dialog-contact-support/dialog-contact-support.component");
var HeaderDefaultComponent = /** @class */ (function () {
    function HeaderDefaultComponent(modalService) {
        this.modalService = modalService;
    }
    HeaderDefaultComponent.prototype.ngOnInit = function () {
    };
    HeaderDefaultComponent.prototype.openContactSupport = function () {
        var ref = this.modalService.open(dialog_contact_support_component_1.DialogContactSupportComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
    };
    return HeaderDefaultComponent;
}());
exports.HeaderDefaultComponent = HeaderDefaultComponent;
