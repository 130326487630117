"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var forms_1 = require("@angular/forms");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var http_1 = require("@angular/common/http");
var logger_service_1 = require("../../core/logger.service");
var api_service_1 = require("../../core/api.service");
var user_service_1 = require("../../core/user.service");
var environment_1 = require("../../../environments/environment");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var rxjs_1 = require("rxjs");
var DialogCustomBrandComponent = /** @class */ (function () {
    function DialogCustomBrandComponent(activeModal, fb, httpClient, logger, api, userService, store) {
        this.activeModal = activeModal;
        this.fb = fb;
        this.httpClient = httpClient;
        this.logger = logger;
        this.api = api;
        this.userService = userService;
        this.store = store;
        this.permissionActions = models_1.PermissionActions;
        //   private sub: Subscription;
        this.unsubscribe = new rxjs_1.Subject();
    }
    DialogCustomBrandComponent.prototype.ngOnInit = function () {
        var _this = this;
        // this.user.is_multi_admin = true;
        this.showCustomBranding = (this.user.has_whitelabel && !this.user.is_multi_child && !this.user.is_multi_admin) || (this.user.has_whitelabel && (this.user.is_multi_admin || this.checkPermission(this.permissionActions.MANAGE_BILLING)));
        //check if custom logo exists before loading default values
        this.sub = this.store
            .select(fromRoot.getAuthUser)
            .subscribe(function (data) {
            if (data.whitelabel) {
                _this.defaultLogo = data.whitelabel.image_data;
                _this.defaultHeaderPrimaryColor = data.whitelabel.header_primary_color;
                _this.defaultHeaderTextColor = data.whitelabel.header_text_color;
                _this.defaultButtonPrimaryColor = data.whitelabel.button_primary_color;
                _this.defaultButtonTextColor = data.whitelabel.button_text_color;
                _this.defaultLinkTextColor = data.whitelabel.link_text_color;
            }
        });
        if (!this.defaultLogo) {
            this.defaultLogo = '';
            this.defaultHeaderPrimaryColor = '#00ADEF';
            this.defaultHeaderTextColor = '#FFFFFF';
            this.defaultButtonPrimaryColor = '#00ADEF';
            this.defaultButtonTextColor = '#FFFFFF';
            this.defaultLinkTextColor = '#00ADEF';
            this.logger.info('default whitelabel values loaded');
        }
        this.errcode = null;
        this.logoInput = null;
        //set things to saved settings or default
        this.logo = this.defaultLogo;
        this.headerPrimaryColor = this.defaultHeaderPrimaryColor;
        this.headerTextColor = this.defaultHeaderTextColor;
        this.buttonPrimaryColor = this.defaultButtonPrimaryColor;
        this.buttonTextColor = this.defaultButtonTextColor;
        this.linkTextColor = this.defaultLinkTextColor;
        this.spinner = false;
        this.createForm();
        this.OnChanges();
    };
    DialogCustomBrandComponent.prototype.checkPermission = function (permName) {
        return this.userService.checkPermission(permName);
    };
    /**
     * sets things to Sync settings
     */
    DialogCustomBrandComponent.prototype.setPreferencesToDefault = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.execute('uploadwhitelabel', { 'logoBase64': '',
                                'headerPrimaryColor': '',
                                'headerTextColor': '',
                                'buttonPrimaryColor': '',
                                'buttonTextColor': '',
                                'linkTextColor': '',
                            })];
                    case 1:
                        _a.sent();
                        this.userService.refresh();
                        this.activeModal.close(true);
                        return [3 /*break*/, 3];
                    case 2:
                        ex_1 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DialogCustomBrandComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    };
    DialogCustomBrandComponent.prototype.createForm = function () {
        var regex = new RegExp('^#[0-9A-F]{6}$', 'i'); //case-insensitive
        //logo input is not required if a custom logo exists
        if (this.logo) {
            this.customBrandForm = this.fb.group({
                logo: [this.logoInput],
                headerColorPicker: [this.headerPrimaryColor, forms_1.Validators.pattern(regex)],
                headerTextColorPicker: [this.headerTextColor, forms_1.Validators.pattern(regex)],
                buttonColorPicker: [this.buttonPrimaryColor, forms_1.Validators.pattern(regex)],
                buttonTextColorPicker: [this.buttonTextColor, forms_1.Validators.pattern(regex)],
                linkTextColorPicker: [this.linkTextColor, forms_1.Validators.pattern(regex)],
            });
        }
        else {
            this.customBrandForm = this.fb.group({
                logo: [this.logoInput, forms_1.Validators.required],
                headerColorPicker: [this.headerPrimaryColor, forms_1.Validators.pattern(regex)],
                headerTextColorPicker: [this.headerTextColor, forms_1.Validators.pattern(regex)],
                buttonColorPicker: [this.buttonPrimaryColor, forms_1.Validators.pattern(regex)],
                buttonTextColorPicker: [this.buttonTextColor, forms_1.Validators.pattern(regex)],
                linkTextColorPicker: [this.linkTextColor, forms_1.Validators.pattern(regex)],
            });
        }
    };
    DialogCustomBrandComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.execute('uploadwhitelabel', { 'logoBase64': this.logo,
                                'headerPrimaryColor': this.headerPrimaryColor,
                                'headerTextColor': this.headerTextColor,
                                'buttonPrimaryColor': this.buttonPrimaryColor,
                                'buttonTextColor': this.buttonTextColor,
                                'linkTextColor': this.linkTextColor,
                            })];
                    case 1:
                        _a.sent();
                        this.logger.info('whitelabel header color: ' + this.headerPrimaryColor);
                        this.logger.info('whitelabel header text color: ' + this.headerTextColor);
                        this.logger.info('whitelabel button color: ' + this.buttonPrimaryColor);
                        this.logger.info('whitelabel button text color: ' + this.buttonTextColor);
                        this.logger.info('whitelabel link text color: ' + this.linkTextColor);
                        this.userService.refresh();
                        this.activeModal.close(true);
                        return [3 /*break*/, 3];
                    case 2:
                        ex_2 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DialogCustomBrandComponent.prototype.resetPreferences = function () {
        this.errcode = null;
        this.customBrandForm.reset();
        this.setPreferencesToDefault();
        this.customBrandForm.get('headerColorPicker').setValue(this.defaultHeaderPrimaryColor);
        this.customBrandForm.get('headerTextColorPicker').setValue(this.defaultHeaderTextColor);
        this.customBrandForm.get('buttonColorPicker').setValue(this.defaultButtonPrimaryColor);
        this.customBrandForm.get('buttonTextColorPicker').setValue(this.defaultButtonTextColor);
        this.customBrandForm.get('linkTextColorPicker').setValue(this.defaultLinkTextColor);
    };
    DialogCustomBrandComponent.prototype.uploadFile = function (files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var logoFile, path, formData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!files || files.length == 0) {
                            return [2 /*return*/];
                        }
                        this.errcode = null;
                        this.setSpinner();
                        logoFile = files.item(0);
                        path = '/upload/whitelabel';
                        formData = new FormData();
                        formData.append('file', logoFile, logoFile.name);
                        //api call for image preprocessing
                        return [4 /*yield*/, this.httpClient.post(environment_1.environment.imageconversionhost + path, formData).subscribe(function (response) {
                                if (response['success']) {
                                    _this.logo = response['imgdata'];
                                    _this.setSpinner();
                                }
                            }, function (err) {
                                _this.errcode = new models_1.ErrCode(err.error.error_code, 'The file format is unsupported. Please try again');
                                _this.setSpinner();
                            })];
                    case 1:
                        //api call for image preprocessing
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    //since Angular 7, two-way binding and form group combination is outdated, thus onChanges() is used
    DialogCustomBrandComponent.prototype.OnChanges = function () {
        var _this = this;
        this.customBrandForm.valueChanges.subscribe(function (value) {
            _this.headerPrimaryColor = value.headerColorPicker;
            _this.headerTextColor = value.headerTextColorPicker;
            _this.buttonPrimaryColor = value.buttonColorPicker;
            _this.buttonTextColor = value.buttonTextColorPicker;
            _this.linkTextColor = value.linkTextColorPicker;
        });
    };
    DialogCustomBrandComponent.prototype.setSpinner = function () {
        this.spinner = !this.spinner;
    };
    return DialogCustomBrandComponent;
}());
exports.DialogCustomBrandComponent = DialogCustomBrandComponent;
