"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SuccessRes = /** @class */ (function () {
    function SuccessRes(status, data, message) {
        if (status === void 0) { status = 200; }
        this.status = status;
        this.data = data;
        this.message = message;
        this.success = 1;
        this.status = status;
    }
    return SuccessRes;
}());
exports.SuccessRes = SuccessRes;
/* -------------------------------------------------------------------------- */
/*                                 ENUM TYPES                                 */
/* -------------------------------------------------------------------------- */
var CommentCreationProperties;
(function (CommentCreationProperties) {
    CommentCreationProperties["loc_id"] = "loc_id";
    CommentCreationProperties["author_id"] = "author_id";
    CommentCreationProperties["author_name"] = "author_name";
    CommentCreationProperties["content"] = "content";
    CommentCreationProperties["temp_session"] = "temp_session";
})(CommentCreationProperties = exports.CommentCreationProperties || (exports.CommentCreationProperties = {}));
var CommentUpdateProperties;
(function (CommentUpdateProperties) {
    CommentUpdateProperties["author_id"] = "author_id";
    CommentUpdateProperties["content"] = "content";
    CommentUpdateProperties["temp_session"] = "temp_session";
})(CommentUpdateProperties = exports.CommentUpdateProperties || (exports.CommentUpdateProperties = {}));
var ReplyCreationProperties;
(function (ReplyCreationProperties) {
    ReplyCreationProperties["parent_id"] = "parent_id";
    ReplyCreationProperties["author_id"] = "author_id";
    ReplyCreationProperties["author_name"] = "author_name";
    ReplyCreationProperties["content"] = "content";
    ReplyCreationProperties["temp_session"] = "temp_session";
})(ReplyCreationProperties = exports.ReplyCreationProperties || (exports.ReplyCreationProperties = {}));
var ReplyUpdateProperties;
(function (ReplyUpdateProperties) {
    ReplyUpdateProperties["parent_id"] = "parent_id";
    ReplyUpdateProperties["reply_id"] = "reply_id";
    ReplyUpdateProperties["content"] = "content";
    ReplyUpdateProperties["author_id"] = "author_id";
    ReplyUpdateProperties["temp_session"] = "temp_session";
})(ReplyUpdateProperties = exports.ReplyUpdateProperties || (exports.ReplyUpdateProperties = {}));
var DeleteProperties;
(function (DeleteProperties) {
    DeleteProperties["author_id"] = "author_id";
    DeleteProperties["temp_session"] = "temp_session";
})(DeleteProperties = exports.DeleteProperties || (exports.DeleteProperties = {}));
var CommentUpdateDataProperties;
(function (CommentUpdateDataProperties) {
    CommentUpdateDataProperties["_id"] = "_id";
    CommentUpdateDataProperties["content"] = "content";
    CommentUpdateDataProperties["author_id"] = "author_id";
    CommentUpdateDataProperties["temp_session"] = "temp_session";
})(CommentUpdateDataProperties = exports.CommentUpdateDataProperties || (exports.CommentUpdateDataProperties = {}));
var CommmentDeleteDataProperties;
(function (CommmentDeleteDataProperties) {
    CommmentDeleteDataProperties["_id"] = "_id";
    CommmentDeleteDataProperties["author_id"] = "author_id";
    CommmentDeleteDataProperties["temp_session"] = "temp_session";
})(CommmentDeleteDataProperties = exports.CommmentDeleteDataProperties || (exports.CommmentDeleteDataProperties = {}));
var ReplyDeleteDataProperties;
(function (ReplyDeleteDataProperties) {
    ReplyDeleteDataProperties["parent_id"] = "parent_id";
    ReplyDeleteDataProperties["reply_id"] = "reply_id";
    ReplyDeleteDataProperties["author_id"] = "author_id";
    ReplyDeleteDataProperties["temp_session"] = "temp_session";
})(ReplyDeleteDataProperties = exports.ReplyDeleteDataProperties || (exports.ReplyDeleteDataProperties = {}));
