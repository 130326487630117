"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var models_1 = require("../../shared/models");
var api_service_1 = require("../../core/api.service");
var auth_service_1 = require("../../auth/services/auth.service");
var logger_service_1 = require("../../core/logger.service");
var DialogUsernameChangeComponent = /** @class */ (function () {
    function DialogUsernameChangeComponent(activeModal, api, auth, log) {
        this.activeModal = activeModal;
        this.api = api;
        this.auth = auth;
        this.log = log;
        this.openState = 1;
    }
    DialogUsernameChangeComponent.prototype.ngOnInit = function () {
        this.newEmail = '';
        this.password = '';
        this.openState = 1;
        this.errcode = undefined;
        this.spinner = false;
    };
    DialogUsernameChangeComponent.prototype.requestEmailChange = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var hashPass, ex_1, err;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.auth.getHashedPassword(this.user.email, this.password)];
                    case 2:
                        hashPass = _a.sent();
                        return [4 /*yield*/, this.api.execute('requestemailchange', {
                                newEmail: this.newEmail.toLowerCase(),
                                password: hashPass
                            })];
                    case 3:
                        _a.sent();
                        this.openState = 2;
                        return [3 /*break*/, 5];
                    case 4:
                        ex_1 = _a.sent();
                        err = models_1.ErrCode.fromException(ex_1);
                        console.log(ex_1);
                        this.log.e('Error trying to request an email change', err);
                        if (err.code == 9429) {
                            this.log.error('User is ratelimited for request email change');
                            this.errcode = new models_1.ErrCode(8009, 'Rate-limited for sending too many email change requests');
                        }
                        else if (err instanceof models_1.ErrCode) {
                            this.errcode = err;
                        }
                        this.log.error('An error occurred');
                        return [3 /*break*/, 5];
                    case 5:
                        this.spinner = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    return DialogUsernameChangeComponent;
}());
exports.DialogUsernameChangeComponent = DialogUsernameChangeComponent;
