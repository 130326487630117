import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ApiService } from 'src/app/core/api.service';
import { LoggerService } from 'src/app/core/logger.service';
import { LinkFileListService } from 'src/app/link-consume/services/link-file-list.service';
import { environment } from 'src/environments/environment';
import { User, Feature } from '../models';
import { SkuService } from './sku.service';
import * as fromRoot from '../../reducers';
import { Observable, Subscription, throwError } from 'rxjs';
import { State } from 'src/app/reducers/link-file-list.reducer';
import { catchError, delay, retryWhen, take, tap } from 'rxjs/operators';
import { MemoizationService } from './memoization.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private path = environment.featureHost;
  private features: Feature[] = [];
  public featureFlag: any;
  private EXPIRY = 43200000; // 12 hours
  private user: User;
  private previousUserId: number;
  private userSubscribe: Subscription;
  private linkSubscribe: Subscription;
  private featureSubscribe: Subscription;
  constructor(
    private httpClient: HttpClient,
    private skuService: SkuService,
    private log: LoggerService,
    private api: ApiService,
    private LinkPathList: LinkFileListService,
    private memoizationService: MemoizationService,
    private store: Store<fromRoot.State>,
    private router: Router,
  ) {
    this.getFeatureData();
  }
  private async getUser(): Promise<any> {
    return await new Promise((resolve) => {
      if (this.user) {
        resolve(true);
        return;
      }

      if (this.router.url.includes('/dl')) {
        this.linkSubscribe =
          this.LinkPathList.getSubscription().subscribe(
            async (data) => {
              if (
                data &&
                data.owner_id
              ) {
                this.user = new User();
                this.user.id = data.owner_id;
                this.user.team_id = data.team_id;
                this.user.email = data.email;
                this.user.sku = data.sku;
                this.user.is_multi_admin =
                  data.is_multi_admin;
                this.user.is_on_trial =
                  data.is_on_trial;
                this.user['is_link_user'] = true;
                if (this.linkSubscribe) {
                  this.linkSubscribe.unsubscribe();
                }
                resolve(true);
              }
            }
          );
      } else {
        this.userSubscribe = this.store
          .pipe(select(fromRoot.getAuthUser))
          .subscribe((data) => {
            if (data) {
              this.user = data;
              if (this.userSubscribe) { this.userSubscribe.unsubscribe(); }
              resolve(true);
            }
          });
      }
    });
  }


  private getFeatureData() {
    if (this.featureSubscribe) {
      this.featureSubscribe.unsubscribe();
    }
    this.featureSubscribe = this.getFeatures().subscribe(
      (response) => {
        try {
          if (response) {
            this.features = response;
          } else {
            this.features = [];
          }
        } catch (error) {
          this.log.e(`error in feature API response`, error);
          this.features = [];
        }
      },
      (error) => {
        this.features = [];
        this.log.e('Failed API feature', error);
      }
    );
  }

  getFeatures(): Observable<Feature[]> {
    const featuresUrl = `${this.path}features`;

    // Use memoization to cache the result of the HTTP request
    return this.memoizationService.memoize('memoize_feature', () =>
      this.httpClient.get<Feature[]>(featuresUrl).pipe(
        retryWhen((errors) =>
         errors.pipe(
            // Retry up to 3 times with a delay of 1 second between retries
            delay(1000),
            take(3),
            catchError((error) => {
              // Log the error
              this.features = [];
              // Log the error
              this.log.e('API request error', error);
              return this.features;
            })
          )
        ),
        tap((value) => {
          return value;
        })
      )
    );
  }

  private isExpiredData() {
    return this.user.id != this.previousUserId;
  }
  public async getfeatureconfigs() {
    const featureFlag = {};
    let sku = 'free';
    if (!this.user['is_link_user']) {
      sku = await this.skuService.getSku();
      this.user.sku = sku;
    } else {
      this.user.sku = this.user.sku || sku;
    }
    if (this.features && this.features.length > 0) {
      this.features.map((feature) => {
        featureFlag[feature.feature_name] =
          feature.enabled &&
          this.isFeatureAllowed(feature, this.user);
      });
    } else {
      this.getFeatureData();
    }
    return featureFlag;
  }
  public async isAllowed(featureName: string) {
    await this.getUser();
    if (!this.featureFlag || this.isExpiredData()) {
      this.featureFlag = await this.getfeatureconfigs();
    }
    return this.featureFlag[featureName] || false;
  }
  private isFeatureAllowed(feature: Feature, user: User): boolean {
    if (!this.isValueEndsWithCriteria(feature.userid_criteria, user.id)) {
      return false;
    }
    if (!this.isValueEndsWithCriteria(feature.email_criteria, user.email)) {
      return false;
    }
    if (!this.isValueHasCriteria(feature.plan_criteria, user.sku)) {
      return false;
    }
    if (
      !this.isValueEndsWithCriteria(feature.teams_criteria, user.team_id)
    ) {
      return false;
    }
    if (
      !this.isValueHasCriteria(
        feature.country_criteria,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
    ) {
      return false;
    }
    return true;
  }
  private isValueEndsWithCriteria(
    criteria: string,
    value: number | string
  ): boolean {
    value = value || '';
    if (criteria) {
      const regex = new RegExp(`(${criteria})$`, 'i');
      const result = regex.test(value.toString());
      return result;
    }
    return true;
  }
  private isValueHasCriteria(criteria: string, sku: string): boolean {
    if (criteria) {
      const regex = new RegExp(`(${criteria})`, 'i');
      const result = regex.test(sku);
      return result;
    }
    return true;
  }
  public resetFeatureFlags() {
    this.featureFlag = null;
    this.user = null;
  }
}
