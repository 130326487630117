"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var models_1 = require("../../shared/models");
var provision_service_1 = require("../../account/services/provision.service");
var api_service_1 = require("../../core/api.service");
var user_service_1 = require("../../core/user.service");
var logger_service_1 = require("../../core/logger.service");
var environment_1 = require("../../../environments/environment");
var sync_cookie_service_1 = require("../../core/sync-cookie.service");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var router_1 = require("@angular/router");
var zuora_account_service_1 = require("../../account/services/zuora-account.service");
var password_service_1 = require("../../auth/services/password.service");
var blend_service_1 = require("src/app/shared/services/blend.service");
var sku_service_1 = require("src/app/shared/services/sku.service");
var DialogMultiuserAddComponent = /** @class */ (function () {
    function DialogMultiuserAddComponent(activeModal, api, syncCookie, log, provision, store, userService, skuService, router, zuora, pwdService, blendService) {
        this.activeModal = activeModal;
        this.api = api;
        this.syncCookie = syncCookie;
        this.log = log;
        this.provision = provision;
        this.store = store;
        this.userService = userService;
        this.skuService = skuService;
        this.router = router;
        this.zuora = zuora;
        this.pwdService = pwdService;
        this.blendService = blendService;
        this.spinner = false;
        this.passreset = '1';
        this.purgefiles = '0';
        this.showInputError = {
            email: false,
            password: false,
            refCode: false
        };
        this.sku = '';
        this.addUserAmount = '1';
    }
    DialogMultiuserAddComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.spinner = false;
                        this.sub = this.store
                            .select(fromRoot.getAuthState)
                            .subscribe(function (data) {
                            if (!data.authenticated) {
                                _this.user = new models_1.User();
                                return;
                            }
                            if (data.user) {
                                _this.user = data.user;
                                _this.userLimit = Array((_this.user.is_on_trial ? 10 : 300) - _this.user.plan_user_limit).fill(0).map(function (x, i) { return i + 1; });
                            }
                        });
                        this.openState = (this.openState) ? this.openState : 1;
                        _a = this;
                        return [4 /*yield*/, this.skuService.getSku()];
                    case 1:
                        _a.sku = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogMultiuserAddComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    };
    /**
     * Bound to add user button click
     */
    DialogMultiuserAddComponent.prototype.addUser = function () {
        if (this.addAction == 'existing') {
            this.addExisting();
        }
        else if (this.addAction == 'new') {
            this.signup();
        }
        else if (this.addAction == 'order') {
            this.orderAddUser();
        }
        else {
            this.errcode = new models_1.ErrCode(8220);
        }
    };
    /**
     * Return the error messages for input fields
     */
    DialogMultiuserAddComponent.prototype.getValidationError = function () {
        var errorObj = { email: [], refcode: [], password: [] };
        if (!this.email) {
            errorObj.email.push('Email is required');
        }
        else if (!this.provision.validEmail(this.email)) {
            errorObj.email.push('Invalid Email Address');
        }
        if (this.addAction == 'existing') {
            if (!this.refcode) {
                errorObj.email.push('Ref Code is required');
            }
        }
        else if (this.addAction == 'new') {
            if (!this.password) {
                errorObj.password.push('Password is required');
            }
            else {
                if (!this.pwdService.isPasswordStrong(this.password)) {
                    errorObj.password.push(models_1.ErrorMessages[8304]);
                }
            }
        }
        return errorObj;
    };
    /**
     * If the input is valid, the add button will be enabled.
     * @Dependency - getValidationError()
     */
    DialogMultiuserAddComponent.prototype.isInputValid = function () {
        var validationErrors = this.getValidationError();
        return !Object.keys(validationErrors).some(function (key) { return validationErrors[key].length > 0; });
    };
    DialogMultiuserAddComponent.prototype.goNext = function () {
        if (this.addAction == 'existing') {
            this.openState = 2;
        }
        else if (this.addAction == 'new') {
            this.openState = 2;
        }
        else if (this.addAction == 'order') {
            this.openState = 3;
        }
        else {
            return false;
        }
    };
    DialogMultiuserAddComponent.prototype.signup = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var email, data, result, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.email || !this.password) {
                            return [2 /*return*/];
                        }
                        email = this.sanitizeEmail(this.email);
                        if (!this.provision.validEmail(email)) {
                            this.errcode = new models_1.ErrCode(8107, "The email " + email + " is not valid.");
                            return [2 /*return*/];
                        }
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, this.provision.provision('multiuser', email, this.password, '', parseInt(this.passreset, 10), 0)];
                    case 2:
                        data = _a.sent();
                        this.blendService.track(models_1.BlendEvent.SUB_USER_ADDED, { type: 'new user', num_users: 1, sku: this.sku, method: 'Manual' });
                        this.spinner = false;
                        return [4 /*yield*/, this.api.execute('multiuserupdate', {
                                action: 'addnew',
                                email: email,
                                code: data.referral_code
                            })];
                    case 3:
                        result = _a.sent();
                        if (!(this.purgefiles == '1')) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.api.execute('multiuserpermissions', {
                                child_user_id: data.user_id,
                                disablepurge: 1
                            })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        this.activeModal.close(true);
                        return [3 /*break*/, 7];
                    case 6:
                        ex_1 = _a.sent();
                        this.spinner = false;
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    DialogMultiuserAddComponent.prototype.orderAddUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var additionalUsers, p, rp, rpc, qty, zprofile, result, url, ex_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        additionalUsers = parseInt(this.addUserAmount, 10);
                        if (!this.userService.get('zuora_acct_key')) return [3 /*break*/, 8];
                        p = this.userService.get('plan_product_id');
                        rp = this.userService.get('plan_rate_plan_id');
                        rpc = this.userService.get('plan_rate_plan_charge_id');
                        qty = this.userService.get('plan_user_limit');
                        return [4 /*yield*/, this.zuora.getProfile()];
                    case 1:
                        zprofile = _a.sent();
                        if (!(this.userService.get('is_multi_admin') && zprofile && zprofile.balance <= 0 && zprofile.unpaidInvoiceCount >= 0)) return [3 /*break*/, 6];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.api.execute('ordersessionnew', {})];
                    case 3:
                        result = _a.sent();
                        this.syncCookie.setOrderSessionId(result.sessionId);
                        url = environment_1.environment.ordershost
                            + '/upgrade/'
                            + p
                            + '?rp=' + rp
                            + '&rpc=' + rpc
                            + '&qty=' + (qty + additionalUsers);
                        this.log.info("Sending user to upgrade " + url);
                        window.location.href = url;
                        return [3 /*break*/, 5];
                    case 4:
                        ex_2 = _a.sent();
                        this.errcode = new models_1.ErrCode(8550);
                        this.log.error("Error creating order session to add user " + JSON.stringify(ex_2));
                        return [3 /*break*/, 5];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        this.router.navigate(['/account/upgrade']);
                        this.activeModal.close();
                        _a.label = 7;
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        this.errcode = new models_1.ErrCode(8505);
                        _a.label = 9;
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    DialogMultiuserAddComponent.prototype.addExisting = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.email || !this.refcode) {
                            return [2 /*return*/];
                        }
                        this.spinner = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.execute('multiuserupdate', {
                                action: 'add',
                                email: this.sanitizeEmail(this.email),
                                code: this.refcode
                            })];
                    case 2:
                        _a.sent();
                        this.blendService.track(models_1.BlendEvent.SUB_USER_ADDED, { type: 'existing user', num_users: 1, sku: this.sku, method: 'Manual' });
                        this.spinner = false;
                        this.activeModal.close(true);
                        return [3 /*break*/, 4];
                    case 3:
                        ex_3 = _a.sent();
                        this.spinner = false;
                        this.errcode = models_1.ErrCode.fromException(ex_3);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DialogMultiuserAddComponent.prototype.sanitizeEmail = function (email) {
        return email.toLowerCase().trim();
    };
    return DialogMultiuserAddComponent;
}());
exports.DialogMultiuserAddComponent = DialogMultiuserAddComponent;
