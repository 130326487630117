"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var crypt_buffer_service_1 = require("./crypt-buffer.service");
var crypt_legacy_service_1 = require("./crypt-legacy.service");
var logger_service_1 = require("../logger.service");
var models_1 = require("../../shared/models");
var unorm = require("unorm");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var CoreActions = require("../../actions/core.actions");
var environment_1 = require("../../../environments/environment");
var crypt_native_service_1 = require("./crypt-native.service");
var browser_support_service_1 = require("../browser-support.service");
var crypt_engine_model_1 = require("../../shared/models/crypt-engine.model");
var SyncCryptService = /** @class */ (function () {
    function SyncCryptService(BrowserSupport, CryptNative, CryptBuffer, CryptLegacy, Logger, store) {
        this.BrowserSupport = BrowserSupport;
        this.CryptNative = CryptNative;
        this.CryptBuffer = CryptBuffer;
        this.CryptLegacy = CryptLegacy;
        this.Logger = Logger;
        this.store = store;
        /**
         * Do not modify these values, it will break encryption system wide by
         * creating bad data that nothing will be able to decrypt, other than
         * a cp which has these same values.
         *
         * @see https://syncdev.atlassian.net/wiki/spaces/OV/pages/1507348/File+Data
         */
        this.GCM_PACKET_SIZE = 128 * 1024;
        this.GCM_PAYLOAD_SIZE = 128 * 1024 - 36;
        /**
         * These prefixes are used to identify encryption method.  E.g., a 1: prefix
         * signifies a meta key encrypted string for a file name.
         *
         * Do not alter these prefixes, but add new ones if required.
         */
        this.ENC_PREFIX_FILENAME = 1;
        this.ENC_PREFIX_DATAKEY = 2;
        this.ENC_PREFIX_APP_LINK_DATAKEY = 3;
        this.ENC_PREFIX_DIGEST = 2;
        this.ENC_PREFIX_PASSWORD = 30;
        this.ENC_PREFIX_LINKPASSWORD = 50;
        this.ENC_PREFIX_PASSRESET = 51;
        // this cache is used for memoization to optimize some encryption time
        this.mCache = {
            filename: {},
            datakey: {},
            sharekey: {},
            linkpassword: {},
        };
        switch (this.BrowserSupport.getEncryptionEngine()) {
            case crypt_engine_model_1.CryptEngine.NATIVE:
                this.setCryptMethod(CryptNative);
                break;
            case crypt_engine_model_1.CryptEngine.BUFFER:
                this.setCryptMethod(CryptBuffer);
                break;
            case crypt_engine_model_1.CryptEngine.LEGACY:
                this.setCryptMethod(CryptLegacy);
                break;
            default:
                this.setCryptMethod(CryptLegacy);
                break;
        }
    }
    /**
     * Sets the crypt library to use.
     */
    SyncCryptService.prototype.setCryptMethod = function (crypt) {
        this.mCrypt = crypt;
        this.Logger.info("Using CryptEngine." + this.BrowserSupport.getEncryptionEngine());
    };
    /**
     * Encrypts a value for storage in the browser's local storage
     * @param val The plain text value to encrypt using storage encrypt method
     */
    SyncCryptService.prototype.storeEncrypt = function (val) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sessionPass, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.getSessionPassword()];
                    case 1:
                        sessionPass = _a.sent();
                        return [4 /*yield*/, this.mCrypt.passwordEncrypt(val, sessionPass, 1000)];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3:
                        ex_1 = _a.sent();
                        throw new models_1.ErrCode(2130);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Decrypts a value for storage in the browser's local storage.
     * @param encVal The encrypted value
     */
    SyncCryptService.prototype.storeDecrypt = function (key) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve, reject) {
                            var b = _this.store.select(fromRoot.getCoreState);
                            var sub = b.subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var sessionPass, ret;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!data || !data[key]) {
                                                reject(new Error("The key " + key + " does not exist in core state"));
                                            }
                                            return [4 /*yield*/, this.getSessionPassword()];
                                        case 1:
                                            sessionPass = _a.sent();
                                            return [4 /*yield*/, this.mCrypt.passwordDecrypt(data[key], sessionPass, 1000)];
                                        case 2:
                                            ret = _a.sent();
                                            resolve(ret);
                                            if (sub) {
                                                sub.unsubscribe();
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); });
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SyncCryptService.prototype.userkeyDecrypt = function (encKey, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.CryptLegacy.passwordDecrypt(encKey, password, 10000)];
                    case 1: 
                    // must use cryptlegacy because people's passwords will contain weird chars
                    return [2 /*return*/, _a.sent()];
                    case 2:
                        ex_2 = _a.sent();
                        this.Logger.e('Error decrypting user keys', ex_2);
                        throw new models_1.ErrCode(2140);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SyncCryptService.prototype.userkeyEncrypt = function (plain, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.CryptLegacy.passwordEncrypt(plain, password, 10000)];
                    case 1: 
                    // must use cryptlegacy because people's passwords will contain weird chars
                    return [2 /*return*/, _a.sent()];
                    case 2:
                        ex_3 = _a.sent();
                        this.Logger.e('Error encrypting user key', ex_3);
                        throw new models_1.ErrCode(2141);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SyncCryptService.prototype.userpasswordEncrypt = function (password, pubkey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.CryptLegacy.asymmetricEncrypt(pubkey, password)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        ex_4 = _a.sent();
                        this.Logger.e('Error encrypting user key', ex_4);
                        throw new models_1.ErrCode(2141);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Decrypts a data key
     * @param encDataKey base64 number prefix of encrypted data key
     * @param sharekey base64 of the share key (decrypted)
     */
    SyncCryptService.prototype.datakeyDecrypt = function (encDataKey, sharekey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var bytes, dk, ex_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isValidPrefix(encDataKey, this.ENC_PREFIX_DATAKEY)) {
                            throw new models_1.ErrCode(2010);
                        }
                        if (this.isMemoized('datakey', encDataKey) !== undefined) {
                            return [2 /*return*/, this.isMemoized('datakey', encDataKey)];
                        }
                        bytes = this.mCrypt.b64ToBytes(this.rmPrefix(encDataKey, this.ENC_PREFIX_DATAKEY));
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.decryptDataKey(sharekey, bytes)];
                    case 2:
                        dk = _a.sent();
                        this.memoize('datakey', encDataKey, dk);
                        return [2 /*return*/, dk];
                    case 3:
                        ex_5 = _a.sent();
                        this.Logger.error('Unable to decrypt data key');
                        this.Logger.error(ex_5);
                        throw new models_1.ErrCode(2011);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Decrypts a comment data key
     * @param encDataKey base64 number prefix of encrypted comment data key
     * @param sharekey base64 of the share key (decrypted)
     */
    SyncCryptService.prototype.commentDatakeyDecrypt = function (encDataKey, sharekey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var prefix, bytes, dk, ex_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isValidCommentPrefix(encDataKey)) {
                            throw new models_1.ErrCode(2010);
                        }
                        if (this.isMemoized('datakey', encDataKey) !== undefined) {
                            return [2 /*return*/, this.isMemoized('datakey', encDataKey)];
                        }
                        prefix = encDataKey.split(':')[0];
                        if (prefix === this.ENC_PREFIX_APP_LINK_DATAKEY.toString()) {
                            bytes = this.mCrypt.b64ToBytes(this.rmPrefix(encDataKey, this.ENC_PREFIX_APP_LINK_DATAKEY));
                        }
                        else {
                            bytes = this.mCrypt.b64ToBytes(this.rmPrefix(encDataKey, this.ENC_PREFIX_DATAKEY));
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.decryptDataKey(sharekey, bytes)];
                    case 2:
                        dk = _a.sent();
                        this.memoize('datakey', encDataKey, dk);
                        return [2 /*return*/, dk];
                    case 3:
                        ex_6 = _a.sent();
                        this.Logger.error('Unable to decrypt data key');
                        this.Logger.error(ex_6);
                        throw new models_1.ErrCode(2011);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SyncCryptService.prototype.datakeyEncrypt = function (dataKey, sharekey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var encKey, ex_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.encryptDataKey(dataKey, sharekey)];
                    case 1:
                        encKey = _a.sent();
                        return [2 /*return*/, [this.ENC_PREFIX_DATAKEY, encKey].join(':')];
                    case 2:
                        ex_7 = _a.sent();
                        throw ex_7;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * encrypts datakey for comments on slack links using sharekey.
     * The ecrypted datakey has a different prefix than that for comments on dl links and other datakey types
     */
    SyncCryptService.prototype.appLinkDatakeyEncrypt = function (dataKey, sharekey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var encKey, ex_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.encryptDataKey(dataKey, sharekey)];
                    case 1:
                        encKey = _a.sent();
                        return [2 /*return*/, [this.ENC_PREFIX_APP_LINK_DATAKEY, encKey].join(':')];
                    case 2:
                        ex_8 = _a.sent();
                        throw ex_8;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * This method will always return a string.  Whether it fails or not.
     * @param encName The encrypted name b64 num prefix
     * @param sharekey the b64 of the decrypted share key
     */
    SyncCryptService.prototype.filenameDecrypt = function (encName, sharekey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var encNameBytes, key, rawBytes, iv, fname, nameBytes, ex_9, ex_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isValidPrefix(encName, this.ENC_PREFIX_FILENAME)) {
                            throw new models_1.ErrCode(2009);
                        }
                        encNameBytes = this.CryptLegacy.b64ToBytes(this.rmPrefix(encName, this.ENC_PREFIX_FILENAME));
                        return [4 /*yield*/, this.getKeyOrMeta(sharekey)];
                    case 1:
                        key = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 7, , 8]);
                        rawBytes = this.CryptLegacy.getPartialBytes(encNameBytes, 96), iv = this.CryptLegacy.getPartialBytes(encNameBytes, 0, 96);
                        fname = void 0;
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, this.CryptLegacy.symmetricDecrypt(this.CryptLegacy.getPartialBytes(key, 256), rawBytes, iv)];
                    case 4:
                        nameBytes = _a.sent();
                        fname = this.CryptLegacy.bytesToString(nameBytes);
                        this.memoize('filename', encName, fname);
                        return [3 /*break*/, 6];
                    case 5:
                        ex_9 = _a.sent();
                        this.Logger.e("Unable to decrypt the file name " + encName, ex_9);
                        fname = encName;
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/, fname];
                    case 7:
                        ex_10 = _a.sent();
                        if (sharekey === undefined) {
                            this.Logger.error('Unable to retrieve the meta key');
                        }
                        else {
                            this.Logger.error('unable to retrieve the share key');
                        }
                        this.Logger.e('An error occurred in filenameDecrypt', ex_10);
                        return [2 /*return*/, encName];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    SyncCryptService.prototype.filenameEncrypt = function (plain, sharekey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sanitizedPlain, plainBytes, key, hmacData, siv, encName, ex_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        try {
                            sanitizedPlain = unorm.nfc(plain);
                        }
                        catch (e) {
                            this.Logger.error('Unable to check nfc encoding on file name');
                            this.Logger.error('Continuing');
                            sanitizedPlain = plain.toString();
                        }
                        plainBytes = this.CryptLegacy.stringToBytes(sanitizedPlain);
                        return [4 /*yield*/, this.getKeyOrMeta(sharekey)];
                    case 1:
                        key = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 6]);
                        return [4 /*yield*/, this.CryptLegacy.getApiHmac(this.CryptLegacy.getPartialBytes(key, 0, 256), plainBytes)];
                    case 3:
                        hmacData = _a.sent();
                        siv = this.CryptLegacy.getPartialBytes(hmacData, 0, 96);
                        return [4 /*yield*/, this.CryptLegacy.symmetricEncrypt(this.CryptLegacy.getPartialBytes(key, 256), plainBytes, siv)];
                    case 4:
                        encName = _a.sent();
                        return [2 /*return*/, [
                                this.ENC_PREFIX_FILENAME,
                                this.CryptLegacy.bytesToB64(encName),
                            ].join(':')];
                    case 5:
                        ex_11 = _a.sent();
                        this.Logger.e('Error encrypting file name', ex_11);
                        throw new models_1.ErrCode(2022);
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    SyncCryptService.prototype.sharekeyDecrypt = function (encShareKey, shareKeyId, privatekey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pk, sharekeyB64, ex_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!shareKeyId) {
                            this.Logger.error('Missing share key id, stopping before any bad data can be created');
                            throw new models_1.ErrCode(2124);
                        }
                        if (this.isMemoized('sharekey', shareKeyId)) {
                            return [2 /*return*/, Promise.resolve(this.isMemoized('sharekey', shareKeyId))];
                        }
                        return [4 /*yield*/, this.getKeyOrPrivate(privatekey)];
                    case 1:
                        pk = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.CryptLegacy.asymmetricDecrypt(pk, encShareKey)];
                    case 3:
                        sharekeyB64 = _a.sent();
                        this.memoize('sharekey', shareKeyId, sharekeyB64);
                        return [2 /*return*/, sharekeyB64];
                    case 4:
                        ex_12 = _a.sent();
                        this.Logger.e('Error attempting asymmetric decryption' +
                            ' for ' +
                            shareKeyId +
                            ' ' +
                            encShareKey, ex_12);
                        throw new models_1.ErrCode(2123);
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    SyncCryptService.prototype.sharekeyEncrypt = function (sharekey, pubkey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.CryptLegacy.asymmetricEncrypt(pubkey, sharekey)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Encrypts a file digest of a file
     * @param  {String|Array} digest The file's digest as bytes or hex
     * @param  {String|Array} key    The data key
     * @return {Promise}
     */
    SyncCryptService.prototype.filedigestEncrypt = function (digest, key) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var iv, encDigest;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        iv = this.mCrypt.getRandom(96);
                        return [4 /*yield*/, this.mCrypt.symmetricEncrypt(this.mCrypt.getPartialBytes(key, 0, 256), digest, iv)];
                    case 1:
                        encDigest = _a.sent();
                        return [2 /*return*/, this.ENC_PREFIX_DIGEST + ':' + this.mCrypt.bytesToB64(encDigest)];
                }
            });
        });
    };
    SyncCryptService.prototype.filedataEncrypt = function (data, key, offset) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var iv;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        offset = offset || 0;
                        iv = this.getRandom(96);
                        return [4 /*yield*/, this.mCrypt.symmetricEncrypt(this.mCrypt.getPartialBytes(key, 0, 256), data, iv, this.mCrypt.packHeader(offset))];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * File Data decrypt
     * @param  {Array} data   [description]
     * @param  {Array|String} key    [description]
     * @param  {Integer} offset [description]
     * @return {Promise}
     */
    SyncCryptService.prototype.filedataDecrypt = function (data, key, offset) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var header, iv, encdata;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        header = this.mCrypt.getPartialBytes(data, 0, 96), iv = this.mCrypt.getPartialBytes(data, 96, 192), encdata = this.mCrypt.getPartialBytes(data, 192);
                        if (!this.checkHeaderOffset(header, offset)) {
                            throw new models_1.ErrCode(2020);
                        }
                        return [4 /*yield*/, this.mCrypt.symmetricDecrypt(this.mCrypt.getPartialBytes(key, 0, 256), encdata, iv, header)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Appends file data.  Since file data can be a read-only ArrayBuffer,
     * this function combines them.  appendedPayload is appended AFTER the
     * payload.length byte offset
     * @param  {ArrayLike<number>} payload         [description]
     * @param  {ArrayLike<number>} appendedPayload [description]
     * @return {ArrayLike<number>}
     */
    SyncCryptService.prototype.filedataAppend = function (appendedPayload, maxLength) {
        return this.mCrypt.filedataAppend(appendedPayload, maxLength);
    };
    /**
     * Prepares to send data.  For the legacy encryption engine, Int32's need
     * to have their byte order swapped
     * @param  {Array|ArrayBuffer} data [description]
     * @return {ArrayBuffer}      [description]
     */
    SyncCryptService.prototype.prepareDataSend = function (data) {
        return this.mCrypt.prepareDataSend(data);
    };
    /**
     * Checks if packed in header offset matches the expected offset
     * @param  {Array|ArrayBuffer} header [description]
     * @param  {Integer} offset [description]
     * @return {Boolean}
     */
    SyncCryptService.prototype.checkHeaderOffset = function (header, offset) {
        var isValid = true;
        if (offset % this.GCM_PACKET_SIZE !== 0) {
            this.Logger.error('offset is not a multiple of ' + this.GCM_PACKET_SIZE);
            isValid = false;
        }
        var expected_offset = (offset / this.GCM_PACKET_SIZE) * this.GCM_PAYLOAD_SIZE;
        var stored_offset = this.mCrypt.unpackHeader(header);
        if (expected_offset != stored_offset && stored_offset > -1) {
            isValid = false;
            this.Logger.error('offset mismatch: want ' +
                expected_offset +
                ' got ' +
                stored_offset +
                ' header:' +
                header);
        }
        // else {
        //     this.Logger.info('offset: want ' + expected_offset +
        //               ' got ' + stored_offset +
        //               ' header:' + header);
        // }
        return isValid;
    };
    /**
     * Encrypts a list of share keys with a list of pubkeys using RSA.
     * @param encShareKeys An array of share key data
     * @param pubkeys an array of pubkey data
     * @param privatekey an optional private key value.
     */
    SyncCryptService.prototype.encShareKeyListWithPubkeys = function (encShareKeys, pubkeys, privatekey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newEncKeys, _loop_1, this_1, i, encKeys, result, i, len, ex_13;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!encShareKeys.length) {
                            this.Logger.error('encShareKeyListWithPubkeys - no share keys');
                        }
                        if (!pubkeys.length) {
                            this.Logger.warn('encShareKeyListWithPubkeys - no pubkeys keys');
                            return [2 /*return*/, Promise.resolve([])];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        newEncKeys = [];
                        _loop_1 = function (i) {
                            var curShareKey = encShareKeys[i];
                            var encKey = this_1.sharekeyDecrypt(curShareKey.enc_share_key, [curShareKey.share_id, curShareKey.share_sequence].join('-'), privatekey).then(function (sharekey) {
                                return _this.encShareKeyWithPubkeys(sharekey, pubkeys, curShareKey.share_id, curShareKey.share_sequence);
                            });
                            newEncKeys.push(encKey);
                        };
                        this_1 = this;
                        for (i = 0; i < encShareKeys.length; i++) {
                            _loop_1(i);
                        }
                        return [4 /*yield*/, Promise.all(newEncKeys)];
                    case 2:
                        encKeys = _a.sent();
                        result = [];
                        for (i = 0, len = encKeys.length; i < len; i++) {
                            result = result.concat(encKeys[i]);
                        }
                        return [2 /*return*/, result];
                    case 3:
                        ex_13 = _a.sent();
                        this.Logger.e('An error occurred encShareKeyListWithPubkeys', ex_13);
                        throw new models_1.ErrCode(2123);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Encrypts a list of linkesharekeys with a list of pubkeys.
     * @param wrapKey the wrap key in plain text
     * @param inviteData the data required for a share invite
     */
    SyncCryptService.prototype.encLinkShareKeyListWithPubkeys = function (wrapKey, inviteData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var shareDataArray, wrapKeyBytes, _loop_2, this_2, _i, _a, _b, key, val, shareData, ex_14;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        shareDataArray = [];
                        return [4 /*yield*/, this.convertToShareKey(wrapKey, inviteData.salt, inviteData.iterations)];
                    case 1:
                        wrapKeyBytes = _c.sent();
                        _loop_2 = function (key, val) {
                            var res = this_2.linksharekeyDecrypt(val, this_2.mCrypt.bytesToB64(wrapKeyBytes)).then(function (rawShareKeyBytes) {
                                var shareId = parseInt(key.split('-')[0], 10);
                                var shareSequence = parseInt(key.split('-')[1], 10);
                                var rawShareKey = _this.mCrypt.bytesToB64(rawShareKeyBytes);
                                return _this.sharekeyEncrypt(rawShareKey, inviteData.pubkey)
                                    .then(function (encShareKey) {
                                    var data = {
                                        id: inviteData.pubkey_id,
                                        share_id: shareId,
                                        share_sequence: shareSequence,
                                        enc_share_key: encShareKey,
                                    };
                                    // console.log('shareData encLinkShareKeys ' + JSON.stringify(data));
                                    return data;
                                })
                                    .catch(function (err) {
                                    _this.Logger.error('ShareKeyEncrypt failed in encLinkShareKeyWithPubkeys');
                                    throw new models_1.ErrCode(2122);
                                });
                            }, function (err) {
                                _this.Logger.error('linksharekey decrypt failed for ' + key + ': ' + val);
                                _this.Logger.error(err);
                                throw new models_1.ErrCode(2121);
                            });
                            shareDataArray.push(res);
                        };
                        this_2 = this;
                        for (_i = 0, _a = Object.entries(inviteData.linkShareKeys); _i < _a.length; _i++) {
                            _b = _a[_i], key = _b[0], val = _b[1];
                            _loop_2(key, val);
                        }
                        _c.label = 2;
                    case 2:
                        _c.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, Promise.all(shareDataArray)];
                    case 3:
                        shareData = _c.sent();
                        return [2 /*return*/, shareData];
                    case 4:
                        ex_14 = _c.sent();
                        this.Logger.e('Unable to encLinkShareKeyWithPubkeys', ex_14);
                        throw new models_1.ErrCode(2120);
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Encrypts 1 share key with all pubkeys.
     *
     * TODO: make shareid and sharesequence required
     * @param  {String} sharekey      The share key
     * @param  {Array.Objects} pubkeyarray   An array of pubkey objects
     * @param  {Integer} shareid       Share id
     * @param  {Integer} sharesequence Share sequencer
     * @return {Promise}
     */
    SyncCryptService.prototype.encShareKeyWithPubkeys = function (sharekey, pubkeys, shareId, shareSequence) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newKeys, _loop_3, this_3, i, len, ex_15;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!pubkeys || pubkeys.length === 0) {
                            this.Logger.warn('encShareKeyWIthPubkeys called without pubkeys');
                            return [2 /*return*/, Promise.resolve([])];
                        }
                        newKeys = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        _loop_3 = function (i, len) {
                            var publicKey = pubkeys[i];
                            var data = this_3.sharekeyEncrypt(sharekey, publicKey.key).then(function (encShareKey) {
                                return new Promise(function (resolve, reject) {
                                    resolve({
                                        id: publicKey.id,
                                        share_id: shareId || publicKey.share_id,
                                        share_sequence: shareSequence || publicKey.share_sequence,
                                        enc_share_key: encShareKey,
                                    });
                                });
                            });
                            newKeys.push(data);
                        };
                        this_3 = this;
                        for (i = 0, len = pubkeys.length; i < len; i++) {
                            _loop_3(i, len);
                        }
                        return [4 /*yield*/, Promise.all(newKeys)];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3:
                        ex_15 = _a.sent();
                        this.Logger.e('An error occurred encShareKeyWithPubkeys', ex_15);
                        this.Logger.error(shareId + "-" + shareSequence + " error");
                        throw new models_1.ErrCode(2124);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SyncCryptService.prototype.encLinkShareKeyWithPubkeys = function (linkShareKeys, rawShareKey, publicKey, pubkeyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var shareDataArray, _loop_4, this_4, _i, _a, _b, key, val, shareData, ex_16;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        shareDataArray = [];
                        // A share will have link share keys or share keys depending on the state
                        // of the share.  Since this is called and potentially undefined for linksharekeys,
                        // return an empty array quickly.
                        if (!linkShareKeys) {
                            return [2 /*return*/, []];
                        }
                        _loop_4 = function (key, val) {
                            var shareId = parseInt(key.split('-')[0], 10);
                            var shareSequence = parseInt(key.split('-')[1], 10);
                            var shareData = this_4.sharekeyEncrypt(rawShareKey, publicKey)
                                .then(function (encShareKey) {
                                var data = {
                                    id: pubkeyId,
                                    share_id: shareId,
                                    share_sequence: shareSequence,
                                    enc_share_key: encShareKey,
                                };
                                // console.log('shareData encLinkShareKeys ' + JSON.stringify(data));
                                return data;
                            })
                                .catch(function (err) {
                                _this.Logger.error('ShareKeyEncrypt failed in encLinkShareKeyWithPubkeys');
                                throw new models_1.ErrCode(2122);
                            });
                            shareDataArray.push(shareData);
                        };
                        this_4 = this;
                        for (_i = 0, _a = Object.entries(linkShareKeys); _i < _a.length; _i++) {
                            _b = _a[_i], key = _b[0], val = _b[1];
                            _loop_4(key, val);
                        }
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, Promise.all(shareDataArray)];
                    case 2:
                        shareData = _c.sent();
                        return [2 /*return*/, shareData];
                    case 3:
                        ex_16 = _c.sent();
                        this.Logger.e('Unable to encLinkShareKeyWithPubkeys', ex_16);
                        throw new models_1.ErrCode(2120);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SyncCryptService.prototype.mkShareData = function (inviteKey, pubkey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var salt, it, sharekey, iv, wrapKeyBytes, encPassword, linkShareKey, encShareKey, resolveData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        salt = this.mCrypt.bytesToHex(this.mCrypt.getRandom(128)), it = 10000, sharekey = this.mkShareKey(), iv = this.mCrypt.getRandom(96);
                        if (!pubkey) {
                            this.Logger.error('Missing public key for mkShareData()');
                            throw new models_1.ErrCode(2005);
                        }
                        return [4 /*yield*/, this.keyStretch(inviteKey, salt, it, 32 * 8)];
                    case 1:
                        wrapKeyBytes = _a.sent();
                        return [4 /*yield*/, this.linkpasswordEncrypt(this.mCrypt.bytesToB64(this.mCrypt.stringToBytes(inviteKey)), pubkey)];
                    case 2:
                        encPassword = _a.sent();
                        return [4 /*yield*/, this.mCrypt.symmetricEncrypt(wrapKeyBytes, sharekey, iv)];
                    case 3:
                        linkShareKey = _a.sent();
                        return [4 /*yield*/, this.sharekeyEncrypt(this.mCrypt.bytesToB64(sharekey), pubkey)];
                    case 4:
                        encShareKey = _a.sent();
                        resolveData = {
                            invite_key: inviteKey,
                            enc_password: encPassword,
                            link_sharekey: linkShareKey,
                            link_sharekeys: {},
                            link_share_key_b64: this.mCrypt.bytesToB64(linkShareKey),
                            enc_share_key: encShareKey,
                            salt: salt,
                            sharekey: sharekey,
                            iterations: it,
                        };
                        return [2 /*return*/, resolveData];
                }
            });
        });
    };
    SyncCryptService.prototype.backfillShareData = function (linkPass, encShareKeys, pubkey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var salt, it, keys, linkpassEnc, encPassword, wrapKeyBytes, _loop_5, this_5, i, len, result, shareData, i, len;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        salt = this.mCrypt.bytesToHex(this.mCrypt.getRandom(128)), it = 10000;
                        keys = [];
                        linkpassEnc = this.mCrypt.bytesToB64(this.mCrypt.stringToBytes(linkPass));
                        return [4 /*yield*/, this.linkpasswordEncrypt(linkpassEnc, pubkey)];
                    case 1:
                        encPassword = _a.sent();
                        return [4 /*yield*/, this.keyStretch(linkPass, salt, it, 32 * 8)];
                    case 2:
                        wrapKeyBytes = _a.sent();
                        _loop_5 = function (i, len) {
                            var item = encShareKeys[i];
                            var shareKeyId = item.share_id + "-" + item.share_sequence;
                            if (!shareKeyId) {
                                this_5.Logger.error('No share key id found for backfill, stopping to prevent bad data');
                                throw new models_1.ErrCode(1000, 'Invlaid share key id passed to backfill share');
                            }
                            this_5.Logger.info('backfillShareData() Pushing keys for ' + shareKeyId);
                            var res = this_5.sharekeyDecrypt(item.enc_share_key, shareKeyId).then(function (sharekey) {
                                var shareKeyBytes = _this.mCrypt.b64ToBytes(sharekey);
                                return _this.mCrypt
                                    .symmetricEncrypt(wrapKeyBytes, shareKeyBytes, _this.mCrypt.getRandom(96))
                                    .then(function (encShareKey) {
                                    return {
                                        share_id: item.share_id,
                                        share_sequence: item.share_sequence,
                                        enc_share_key: _this.mCrypt.bytesToB64(encShareKey),
                                    };
                                });
                            });
                            keys.push(res);
                        };
                        this_5 = this;
                        // console.log('wrapKeyBytes.length = ' +  wrapKeyBytes.length)
                        for (i = 0, len = encShareKeys.length; i < len; i++) {
                            _loop_5(i, len);
                        }
                        return [4 /*yield*/, Promise.all(keys)];
                    case 3:
                        result = _a.sent();
                        shareData = {
                            salt: salt,
                            iterations: it,
                            invite_key: undefined,
                            link_share_key_b64: undefined,
                            enc_share_key: undefined,
                            sharekey: undefined,
                            enc_password: encPassword,
                            link_sharekey: undefined,
                            link_sharekeys: {},
                        };
                        for (i = 0, len = result.length; i < len; i++) {
                            shareData.link_sharekeys[result[i].share_id + "-" + result[i].share_sequence] = result[i].enc_share_key;
                        }
                        return [2 /*return*/, shareData];
                }
            });
        });
    };
    /**
     * Encrypts the link password for storage with the user's pk.
     * @param  {String} password Base64
     * @param  {String} pubkey   Base64
     * @return {Promise}
     */
    SyncCryptService.prototype.linkpasswordEncrypt = function (password, pubkey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var encPass;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.CryptLegacy.asymmetricEncrypt(pubkey, password)];
                    case 1:
                        encPass = _a.sent();
                        return [2 /*return*/, this.ENC_PREFIX_LINKPASSWORD + ':' + encPass];
                }
            });
        });
    };
    /**
     * Decrypts a link password, used to display to the creator the
     * password
     * @param  {String} enc_password [description]
     * @param  {String|null} privatekey   [description]
     * @return {Promise}
     */
    SyncCryptService.prototype.linkpasswordDecrypt = function (encPassword, privatekey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var encPass, privkey, password, ex_17;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isValidPrefix(encPassword, this.ENC_PREFIX_LINKPASSWORD)) {
                            this.Logger.error('Unknown link password prefix' +
                                encPassword +
                                '--' +
                                this.ENC_PREFIX_LINKPASSWORD);
                            throw new models_1.ErrCode(2007);
                        }
                        encPass = this.rmPrefix(encPassword, this.ENC_PREFIX_LINKPASSWORD);
                        return [4 /*yield*/, this.getKeyOrPrivate(privatekey)];
                    case 1:
                        privkey = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.CryptLegacy.asymmetricDecrypt(privkey, encPass)];
                    case 3:
                        password = _a.sent();
                        // this.memoize('linkpassword', encPassword, password);
                        // console.log('linkpassword encPassword ' + password);
                        return [2 /*return*/, password];
                    case 4:
                        ex_17 = _a.sent();
                        this.Logger.e('Could not decrypt the link password', ex_17);
                        throw new models_1.ErrCode(2100);
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    SyncCryptService.prototype.userPasswordDecrypt = function (encPassword, privatekey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var privkey, password, ex_18;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getKeyOrPrivate(privatekey)];
                    case 1:
                        privkey = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.CryptLegacy.asymmetricDecrypt(privkey, encPassword)];
                    case 3:
                        password = _a.sent();
                        return [2 /*return*/, password];
                    case 4:
                        ex_18 = _a.sent();
                        this.Logger.e('Could not decrypt the user password', ex_18);
                        throw new models_1.ErrCode(2100);
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    SyncCryptService.prototype.linksharekeyDecrypt = function (linksharekey, key) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var keyBytes, bytes, raw, iv, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        keyBytes = this.mCrypt.b64ToBytes(key);
                        bytes = this.mCrypt.b64ToBytes(linksharekey);
                        raw = this.mCrypt.getPartialBytes(bytes, 96), iv = this.mCrypt.getPartialBytes(bytes, 0, 96);
                        return [4 /*yield*/, this.mCrypt.symmetricDecrypt(keyBytes, raw, iv)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        err_1 = _a.sent();
                        throw err_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SyncCryptService.prototype.linksharekeyReEncrypt = function (encPassword, sharekey, salt, iterations) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var iv, it, inviteKeyB64, inviteKey, shareKeyBytes, wrapKeyBytes, encLinkShareKey, ex_19;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        iv = this.mCrypt.getRandom(96), it = iterations || 10000;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, this.linkpasswordDecrypt(encPassword)];
                    case 2:
                        inviteKeyB64 = _a.sent();
                        inviteKey = this.mCrypt.bytesToString(this.mCrypt.b64ToBytes(inviteKeyB64));
                        shareKeyBytes = this.mCrypt.b64ToBytes(sharekey);
                        this.Logger.info('Converting share key to bytes to re-encrypt');
                        return [4 /*yield*/, this.keyStretch(inviteKey, salt, it, 32 * 8)];
                    case 3:
                        wrapKeyBytes = _a.sent();
                        return [4 /*yield*/, this.mCrypt.symmetricEncrypt(wrapKeyBytes, shareKeyBytes, iv)];
                    case 4:
                        encLinkShareKey = _a.sent();
                        return [2 /*return*/, this.mCrypt.bytesToB64(encLinkShareKey)];
                    case 5:
                        ex_19 = _a.sent();
                        this.Logger.e('Could not re-encrypt link share key', ex_19);
                        throw new models_1.ErrCode(2125);
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * @ngdoc method
     * @name  prepareResetKey
     * @methodOf sync.service:SyncCrypt
     * @description
     * Prepares the reset key by generating a new representation of the plain
     * text password as pbkdf2.
     * @param  {String} encKey    The key to derive the password from.  We only
     *                            need the salt from this value to pbkdf2
     *                            the new password for.
     * @param  {String} plainPass The user's plain text password
     * @param  {String} pubkey    The public key to use when RSA encrypting the
     *                            new RSA result
     * @return {Promise<string>}  A 51:[base64] string fo the password
     */
    SyncCryptService.prototype.prepareResetKey = function (encKey, plainPass, pubkey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var encKeyBytes, salt;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        encKeyBytes = this.mCrypt.b64ToBytes(encKey.substring(3));
                        salt = this.mCrypt.getPartialBytes(encKeyBytes, 0, 96);
                        return [4 /*yield*/, this.prepareNewPassword(plainPass, salt, pubkey)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SyncCryptService.prototype.prepareMigrationKeys = function (pubkey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var metakey, privkey, aeskey, encPass, encMeta, encPriv;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storeDecrypt('meta_key')];
                    case 1:
                        metakey = _a.sent();
                        return [4 /*yield*/, this.storeDecrypt('private_key')];
                    case 2:
                        privkey = _a.sent();
                        aeskey = this.mCrypt.getRandom(256);
                        return [4 /*yield*/, this.passwordResetEncrypt(pubkey, this.bytesToB64(aeskey))];
                    case 3:
                        encPass = _a.sent();
                        return [4 /*yield*/, this.mCrypt.passwordEncrypt(metakey, this.bytesToB64(aeskey), 10000)];
                    case 4:
                        encMeta = _a.sent();
                        return [4 /*yield*/, this.mCrypt.passwordEncrypt(privkey, this.bytesToB64(aeskey), 10000)];
                    case 5:
                        encPriv = _a.sent();
                        return [2 /*return*/, {
                                encMeta: encMeta,
                                encPriv: encPriv,
                                encPass: encPass,
                            }];
                }
            });
        });
    };
    /**
     * @ngdoc method
     * @name  prepareResetPasswords
     * @methodOf sync.service:SyncCrypt
     * @description
     * Prepares new pbkdf2 passwords with the user's NEW plain text password
     * for use in re-encrypting and completing the password reset
     * @param  {String} plainPass The user's plain text password
     * @param  {String} pubkey    The public key to use when RSA encrypting the
     *                            new RSA result
     * @return {Promise<sync.IPasswordResetKeys>}  Reset password data
     */
    SyncCryptService.prototype.prepareResetPasswords = function (plainPass, pubkey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var metaSalt, privSalt, encPassMeta, encPassPriv;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        metaSalt = this.mCrypt.getRandom(96);
                        privSalt = this.mCrypt.getRandom(96);
                        return [4 /*yield*/, this.prepareNewPassword(plainPass, metaSalt, pubkey)];
                    case 1:
                        encPassMeta = _a.sent();
                        return [4 /*yield*/, this.prepareNewPassword(plainPass, privSalt, pubkey)];
                    case 2:
                        encPassPriv = _a.sent();
                        return [2 /*return*/, {
                                encPassMeta: encPassMeta,
                                encPassPriv: encPassPriv,
                                metaSalt: this.mCrypt.bytesToB64(metaSalt),
                                privSalt: this.mCrypt.bytesToB64(privSalt),
                            }];
                }
            });
        });
    };
    /**
     * @ngdoc method
     * @name  prepareNewPassword
     * @methodOf sync.service:SyncCrypt
     * @description
     * Takes the user's plain text password and a given salt and then returns
     * the PBKDF2 stretched password RSA encrypted with the given pubkey
     * @param  {String} plainPass  The user's plain text password
     * @param  {ArrayLike<number>} The random salt to use in the pbkdf2 op
     * @param  {String} pubkey     The public key to use when RSA encrypting the
     *                             new RSA result
     * @return {Promise<string>}   The 51:[base64] string result
     */
    SyncCryptService.prototype.prepareNewPassword = function (plainPass, salt, pubkey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var stretchBytes, payload;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.keyStretch(plainPass, salt, 10000, 256)];
                    case 1:
                        stretchBytes = _a.sent();
                        payload = this.mCrypt.bytesToB64(stretchBytes);
                        return [4 /*yield*/, this.passwordResetEncrypt(pubkey, payload)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * @ngdoc method
     * @name passwordResetEncrypt
     * @methodOf sync.service:SyncCrypt
     * @description
     * Password reset uses the server public key.
     * @return {Promise}
     */
    SyncCryptService.prototype.passwordResetEncrypt = function (pubkey, payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var encPass;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        pubkey = pubkey.split('\n').join('%');
                        return [4 /*yield*/, this.CryptLegacy.asymmetricEncrypt(pubkey, payload)];
                    case 1:
                        encPass = _a.sent();
                        return [2 /*return*/, this.ENC_PREFIX_PASSRESET + ":" + encPass];
                }
            });
        });
    };
    /**
     * Encrypts Billing information.  Specifically used for credit card
     * numbers and CC expiry date
     *
     * DEPRECATED, DO NOT USE
     * @param  {String} plainB64 [description]
     * @return {Promise}          [description]
     */
    SyncCryptService.prototype.billingEncrypt = function (plainB64) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pubkey;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        pubkey = [
                            '-----BEGIN PUBLIC KEY-----',
                            'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvWx5UWnw5PmM1MGYbZZM',
                            'taKAdsoAbw+PpacnzK1QXKrY/SJyF8nsn/6TFNkSKdfR61efQyRWvjS/VIctzOHJ',
                            'xHylWDj9SAoQX4WVXEtQxzPEpLJDylVu3TeLvO/y0IcwD2J9On1Z67SDghiC1Hhe',
                            'u+9Wc6Df3/SXZJlfI+Rd+orXNSy85w/rhhqiVhX3Oet+vLfhB42ZthbjqXrRgAwX',
                            '1N3ZrsfTkRPecgLUI9G893VWm+KgvVCkyZ2DE/9KyLG7KjITbjjzzLaIJXSDW28s',
                            '5KicIwss4Xn8WAH5ZqiWvXsi6Pqh3bvosOXeaSJQyATs/lrqBXAgDydz1EB3kjGP',
                            '1QIDAQAB',
                            '-----END PUBLIC KEY-----',
                        ].join('%');
                        return [4 /*yield*/, this.CryptLegacy.asymmetricEncrypt(pubkey, plainB64)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * @ngdoc method
     * @name  compatDatakeyEncrypt
     * @methodOf sync.service:SyncCrypt
     * @description
     * The compatible links requires that the data key be RSA encrypted
     * prior to being operated on.  This method will encrypt the datakey
     * @param  {String} datakeyB64 [description]
     * @return {Promise}          [description]
     */
    SyncCryptService.prototype.compatDatakeyEncrypt = function (datakeyB64) {
        var pubkey;
        pubkey = environment_1.environment.production
            ? [
                '-----BEGIN PUBLIC KEY-----',
                'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsRA0ObSDjWc1ErNAQeN5',
                '9WJLFNTBLHP5pGsDnRNXJfX0GkGB/PRV3vTv7OOUllZgy2J4sSnc/lZit50DcuNk',
                'TAFU3BvNxh2qJfdVxhzdSPRw2hFnnEz4rN9+VuCbEcz4QGiVX2j3jqZLJyioJr5Q',
                'ei+UeAcOnjHBP47H2On4sMdDyec2pSjTCsh0ZzfqSJRPRgzPJnDwwjCuBTbrV4XK',
                'z/wfw9zFoNmwouu4z72Yg8JPO7DS0jmHR1z1CZwKdoq1BXyg9F3w+eRfaV9lQZ2e',
                'SGbUGps3CYiHYrgqTwAfHEH1CK7ENGQW6Dd41k27N1EJyZKEN56c6G/+lHEGts20',
                'FQIDAQAB',
                '-----END PUBLIC KEY-----',
            ].join('%')
            : [
                '-----BEGIN PUBLIC KEY-----',
                'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAk5AJGSZ+ZgkEu68ZJlE9',
                '86T2xpQPyZnbVzwzO3YqfbFAyJ+3pEJ0VTGryi9yMTNWAY7D+CrZDsy5rOj6nICG',
                '4QtaC0umrx0cyhFgQX/aa9MbedDh7WzGoSN9gX+RU2IqwA8RiIZhxXhS4pA4lSM0',
                '9Geu+GOoXyqk9mzvMq+cIuWzqs9axtzKQXaOFsiF+4+It79iJes4NI5IbGKgCu/7',
                'KIYse1NaD1eBCQtBw6+vVV5z+xUDdJXfaiIXvdl6fRhgL8gm8/AD+e0u3bh+glgs',
                'LFRyfQcuyjGHzLdLzTjD+kZHFD38xFDS0a7VRSUbZfwYOSulGfD2ylh46UKJ9eaS',
                '7QIDAQAB',
                '-----END PUBLIC KEY-----',
            ].join('%');
        return this.CryptLegacy.asymmetricEncrypt(pubkey, datakeyB64);
    };
    SyncCryptService.prototype.mkDataKey = function () {
        return this.mCrypt.getRandom(256);
    };
    SyncCryptService.prototype.mkShareKey = function () {
        return this.mCrypt.getRandom(512);
    };
    SyncCryptService.prototype.mkIv = function () {
        return this.mCrypt.getRandom(96);
    };
    SyncCryptService.prototype.getRandom = function (bits) {
        return this.mCrypt.getRandom(bits);
    };
    /**
     * This is NOT async, and not a promise.
     * @return {String} Hex
     */
    SyncCryptService.prototype.getRandomHex = function (bits) {
        return this.mCrypt.bytesToHex(this.mCrypt.getRandom(bits));
    };
    SyncCryptService.prototype.convertToShareKey = function (inviteKey, salt, iterations) {
        return this.keyStretch(inviteKey, salt, iterations, 32 * 8);
    };
    /**
     * Stretches a key with a lower iteration count
     * @param  {String|Array} plain      [description]
     * @param  {String|Array} salt       [description]
     * @param  {Integer} iterations [description]
     * @param  {Integer} tlen       [description]
     * @return {Promise}
     */
    SyncCryptService.prototype.keyStretch = function (plain, salt, iterations, tlen) {
        return this.mCrypt.keyStretch(plain, salt, iterations, tlen);
    };
    SyncCryptService.prototype.keyStretchSlow = function (plain, salt, iterations, tlen) {
        return this.mCrypt.keyStretch(plain, salt, iterations, tlen);
    };
    // export the helper functions
    SyncCryptService.prototype.hexToBytes = function (hex) {
        return this.mCrypt.hexToBytes(hex);
    };
    SyncCryptService.prototype.stringToBytes = function (str) {
        return this.mCrypt.stringToBytes(str);
    };
    SyncCryptService.prototype.b64ToBytes = function (b64) {
        return this.mCrypt.b64ToBytes(b64);
    };
    SyncCryptService.prototype.bytesToString = function (bytes) {
        return this.mCrypt.bytesToString(bytes);
    };
    SyncCryptService.prototype.bytesToB64 = function (bytes) {
        return this.mCrypt.bytesToB64(bytes);
    };
    SyncCryptService.prototype.bytesToHex = function (bytes) {
        return this.mCrypt.bytesToHex(bytes);
    };
    SyncCryptService.prototype.arraybufferToBytes = function (buffer) {
        return this.mCrypt.arraybufferToBytes(buffer);
    };
    SyncCryptService.prototype.signApiReq = function (defaults) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var state;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        state = Object.assign({}, defaults);
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                var sel = _this.store.select(fromRoot.getSignApiData);
                                var sub = sel.subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                    var accessToken, accessSecret, servtime, sigArray, ex_20;
                                    return tslib_1.__generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (!data.access_token || !data.uid || !data.web_device_id) {
                                                    this.Logger.info('No data, returning unsigned');
                                                    return [2 /*return*/, resolve(defaults)];
                                                }
                                                _a.label = 1;
                                            case 1:
                                                _a.trys.push([1, 4, , 5]);
                                                accessToken = data.access_token;
                                                if (!accessToken) {
                                                    this.Logger.error('No accesstoken - does not exist ' +
                                                        JSON.stringify(data));
                                                    return [2 /*return*/, resolve(defaults)];
                                                }
                                                return [4 /*yield*/, this.storeDecrypt('access_secret')];
                                            case 2:
                                                accessSecret = _a.sent();
                                                if (!accessSecret) {
                                                    this.Logger.error('No accessSecret - does not exist ' +
                                                        JSON.stringify(data));
                                                    return [2 /*return*/, resolve(defaults)];
                                                }
                                                servtime = defaults.servtime || Date.now();
                                                return [4 /*yield*/, this.mCrypt.getApiHmac(this.mCrypt.stringToBytes(accessSecret), this.mCrypt.stringToBytes([
                                                        accessToken,
                                                        data.uid,
                                                        data.web_device_id,
                                                        servtime,
                                                    ].join('')))];
                                            case 3:
                                                sigArray = _a.sent();
                                                state.servtime = servtime;
                                                state.access_token = accessToken;
                                                state.signature = this.mCrypt.bytesToHex(sigArray);
                                                resolve(state);
                                                if (sub) {
                                                    sub.unsubscribe();
                                                }
                                                return [3 /*break*/, 5];
                                            case 4:
                                                ex_20 = _a.sent();
                                                this.Logger.error('Error signing API request');
                                                this.Logger.error(ex_20);
                                                return [3 /*break*/, 5];
                                            case 5: return [2 /*return*/];
                                        }
                                    });
                                }); });
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SyncCryptService.prototype.isValidPrefix = function (crypted, prefix) {
        if (this.getPrefix(crypted) === prefix) {
            return true;
        }
        else {
            this.Logger.error("Invalid prefix: " + crypted + " != " + prefix);
            return false;
        }
    };
    SyncCryptService.prototype.isValidCommentPrefix = function (crypted) {
        var prefix = this.getPrefix(crypted);
        if (prefix === this.ENC_PREFIX_APP_LINK_DATAKEY ||
            prefix === this.ENC_PREFIX_DATAKEY) {
            return true;
        }
        this.Logger.error("Invalid prefix: " + crypted + ". Should be either " + this.ENC_PREFIX_APP_LINK_DATAKEY + " or " + (prefix === this.ENC_PREFIX_DATAKEY));
    };
    SyncCryptService.prototype.rmPrefix = function (crypted, prefix) {
        var prefixStr = prefix.toString();
        var prefixLen = prefixStr.length;
        // add +1 to account for the colon character
        // e.g., 23:[b64 payload];
        return crypted.substring(prefixLen + 1);
    };
    /**
     * Parses the crypted base64 string to determine the prefix.
     */
    SyncCryptService.prototype.getPrefix = function (crypted) {
        return crypted && crypted.indexOf(':') > 0
            ? parseInt(crypted.substring(0, crypted.indexOf(':')), 10)
            : 0;
    };
    /**
     * Gets the session password from browser storage. While in browser
     * storage, it is stored encrypted.  If it does not exist, it will
     * create a 96 bit hex string randomly.
     * @return {String} [description]
     */
    SyncCryptService.prototype.getSessionPassword = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve, reject) {
                            var b = _this.store.select(fromRoot.getCoreState);
                            var sub = b.subscribe(function (data) {
                                if (data.session_password === undefined ||
                                    data.session_password === '') {
                                    var sessionPass = _this.mCrypt.bytesToHex(_this.mCrypt.getRandom(96));
                                    _this.store.dispatch(new CoreActions.SetValueAction({
                                        key: 'session_password',
                                        value: sessionPass,
                                    }));
                                    resolve(sessionPass);
                                }
                                else {
                                    resolve(data.session_password);
                                }
                                if (sub) {
                                    sub.unsubscribe();
                                }
                            });
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SyncCryptService.prototype.shareKeyToBytes = function (sharekey) {
        var bytes = this.mCrypt.b64ToBytes(sharekey);
        if (this.mCrypt.checkBitLength(bytes, 512)) {
            return bytes;
        }
        else {
            this.Logger.error('Sharekey was an invalid length');
            return null;
        }
    };
    SyncCryptService.prototype.getKeyOrMeta = function (sharekey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var keystr, ex_21;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        keystr = void 0;
                        if (!!sharekey) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.storeDecrypt('meta_key')];
                    case 1:
                        keystr = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        keystr = sharekey;
                        _a.label = 3;
                    case 3: return [2 /*return*/, Promise.resolve(this.CryptLegacy.b64ToBytes(keystr))];
                    case 4:
                        ex_21 = _a.sent();
                        if (sharekey === undefined) {
                            this.Logger.error('Unable to retrieve the meta key');
                        }
                        else {
                            this.Logger.error('unable to retrieve the share key');
                        }
                        this.Logger.e('Unable to retrieve key', ex_21);
                        throw new models_1.ErrCode(2001);
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    SyncCryptService.prototype.getKeyOrPrivate = function (privatekey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, ex_22;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 5]);
                        if (!!privatekey) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.storeDecrypt('private_key')];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        _a = Promise.resolve(privatekey);
                        _b.label = 3;
                    case 3: return [2 /*return*/, _a];
                    case 4:
                        ex_22 = _b.sent();
                        this.Logger.e('Error retrieving private key in sharekey decrypt', ex_22);
                        throw new models_1.ErrCode(2006);
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    SyncCryptService.prototype.isMemoized = function (type, key) {
        if (key in this.mCache[type]) {
            return this.mCache[type][key];
        }
        return undefined;
    };
    SyncCryptService.prototype.memoize = function (type, key, value) {
        this.mCache[type][key] = value;
    };
    SyncCryptService.prototype.decryptDataKey = function (sharekey, bytes) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sharekeyBytes, iv, rawDk, datakey;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sharekeyBytes = this.shareKeyToBytes(sharekey);
                        if (sharekeyBytes === null) {
                            throw new models_1.ErrCode(2015);
                        }
                        iv = this.mCrypt.getPartialBytes(bytes, 0, 96), rawDk = this.mCrypt.getPartialBytes(bytes, 96);
                        return [4 /*yield*/, this.mCrypt.symmetricDecrypt(this.mCrypt.getPartialBytes(sharekeyBytes, 0, 256), rawDk, iv)];
                    case 1:
                        datakey = _a.sent();
                        return [2 /*return*/, this.mCrypt.bytesToB64(datakey)];
                }
            });
        });
    };
    SyncCryptService.prototype.encryptDataKey = function (dataKey, sharekey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dkBytes, sharekeyBytes, iv, encDkBytes, ex_23;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dkBytes = this.mCrypt.b64ToBytes(dataKey);
                        sharekeyBytes = this.shareKeyToBytes(sharekey);
                        if (sharekeyBytes === null) {
                            throw new models_1.ErrCode(2015);
                        }
                        iv = this.mkIv();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.mCrypt.symmetricEncrypt(this.mCrypt.getPartialBytes(sharekeyBytes, 0, 256), dkBytes, iv)];
                    case 2:
                        encDkBytes = _a.sent();
                        return [2 /*return*/, this.mCrypt.bytesToB64(encDkBytes)];
                    case 3:
                        ex_23 = _a.sent();
                        this.Logger.e('Error datakey encrypt', ex_23);
                        throw new models_1.ErrCode(2012);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return SyncCryptService;
}());
exports.SyncCryptService = SyncCryptService;
