import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
    LinkListItem,
    WebPath,
    ErrCode,
    LinkApiOutput,
    LinkNewApiOutput,
} from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { DirtyOutService } from '../../core/crypt/dirty-out.service';
import { LinkPasswordService } from '../services/link-password.service';
import { Base64Service } from '../../core/base64.service';
import { UserService } from '../../core/user.service';
import { LoggerService } from '../../core/logger.service';
import { select, Store } from '@ngrx/store';
import * as FileListActions from '../../actions/file-list.actions';

import { CensorSensor } from 'censor-sensor';
import { JoblockService } from 'src/app/core/joblock.service';
import { State } from '../../reducers';
const censor = new CensorSensor();
import { FeatureService } from '../../shared/services/feature.service';

@Component({
    selector: 'sync-link-create',
    templateUrl: './link-create.component.html',
})
export class LinkCreateComponent implements OnInit {
    @Input() openState: number;
    @Input() item: LinkListItem | WebPath;

    @Output() state = new EventEmitter<number>();
    @Output() close = new EventEmitter<boolean>();

    public total = 0;
    public finished = 0;
    public errcode: ErrCode;
    public verified: boolean;
    public isLinkLimit: boolean;
    public spinner: boolean;
    public linkLimitAmt = 0;

    public newlinkjob: LinkApiOutput;
    public newLink: LinkNewApiOutput;
    public bg = 1;
    public cachekey: string;
    public modalText: string;

    constructor(
        private api: ApiService,
        private joblockService: JoblockService,
        private base64: Base64Service,
        private dirtyOut: DirtyOutService,
        private linkPassword: LinkPasswordService,
        private log: LoggerService,
        private store: Store<State>,
        private userService: UserService,
        private FeatureService: FeatureService,
    ) {}

    async ngOnInit() {
        if (this.item.is_publink) {
            this.state.emit(1);
            return;
        }
        this.verified = this.userService.getUser().is_verified == 1;
        this.bg = 1;
        if (this.verified) {
            await this.makeLink();
        }
    }

    public async postLinkCreateAction() {
        this.modalText = 'Encrypting link, Please wait...';
        this.cachekey =
            this.bg == 1 ? this.newlinkjob.cachekey : this.newLink.cachekey;
        this.log.info(
            'cachekey filter  status : ' + censor.isProfaneIsh(this.cachekey)
        );

        this.total = this.bg == 1 ? this.newlinkjob.cnt : this.newLink.cnt;
        this.finished = 0;
        while (this.finished < this.total) {
            const data = await this.dirtyOut.executeForShare(
                this.bg == 1 ? this.newlinkjob.share_id : this.newLink.share_id
            );
            if (!data || (Array.isArray(data) && data.length === 0)) {
                this.finished = this.total;
            }
            if (data && data.completed) {
                this.finished += data.completed;
                if (this.finished >= this.total) {
                    break;
                }
            }
        }
        this.modalText = 'Finalizing Link, Please wait...';
        this.store.dispatch(new FileListActions.FileListReloadAction());

        this.state.emit(1);

        this.spinner = false;
        this.log.info('post link action completed');
    }

    public async getLinkPassword() {
        let pass;
        do {
            pass = this.linkPassword.makeLinkPassword(32);
        } while (censor.isProfaneIsh(pass));

        return this.linkPassword.makePasswordData(pass);
    }

    public async makeLink() {
        this.spinner = true;
        this.modalText = 'Creating link...';

        try {
            const passdata = await this.getLinkPassword();
            const linkParams = {
                sync_id: this.item.sync_id,
                label: this.base64.encode(this.item.name),
                enc_share_key: passdata.enc_share_key,
                enc_password: passdata.enc_password,
                salt: passdata.salt,
                iterations: passdata.iterations,
                bg: this.bg,
            };

            do {
                this.newLink = await this.api.execute<LinkNewApiOutput>('linknew', linkParams);
                this.bg = this.newLink.bg;
                if (this.bg == 1) {
                    const response = await this.joblockService.pollJobStatus();
                    this.newlinkjob = await this.api.execute<LinkApiOutput>(
                        'linkget',
                        {
                            sync_id: this.item.sync_id,
                        }
                    );
                }
            } while (censor.isProfaneIsh(this.bg == 1 ? this.newlinkjob.cachekey : this.newLink.cachekey));

            await this.postLinkCreateAction();
        } catch (ex) {
            if (ex instanceof ErrCode) {
                if (ex.code == 8028) {
                    if (ex.msg) {
                        this.log.warn('Link limit is reached ' + ex.msg);
                        const limit = ex.msg.toString().match(/\(([0-9]+)\)/);
                        this.log.info(limit);
                        if (limit !== null && parseInt(limit[1], 10) > 0) {
                            this.linkLimitAmt = parseInt(limit[1], 10);
                        } else {
                            this.log.error(
                                'Could not determine the link limit from the API, hardcoding to a limit of 3.  Received : "' +
                                    ex.msg +
                                    '"'
                            );
                            this.linkLimitAmt = 3;
                        }
                    }
                    this.isLinkLimit = true;
                } else {
                    this.errcode = ex;
                }
            } else {
                this.errcode = new ErrCode(
                    1000,
                    'An error occurred creating the link'
                );
                this.log.error('An error occurred creating the link');
                this.log.error(ex);
            }

            this.spinner = false;
        }
    }
}
