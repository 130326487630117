"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("../../shared/components/error/error.component.ngfactory");
var i3 = require("../../shared/components/error/error.component");
var i4 = require("../../core/user.service");
var i5 = require("@ng-bootstrap/ng-bootstrap");
var i6 = require("@angular/platform-browser");
var i7 = require("./dialog-billing-profile-delete.component");
var i8 = require("../../core/api.service");
var i9 = require("../../core/logger.service");
var i10 = require("../services/zuora-account.service");
var styles_DialogBillingProfileDeleteComponent = [];
var RenderType_DialogBillingProfileDeleteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogBillingProfileDeleteComponent, data: {} });
exports.RenderType_DialogBillingProfileDeleteComponent = RenderType_DialogBillingProfileDeleteComponent;
function View_DialogBillingProfileDeleteComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-spin fa-spinner"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Loading ... "]))], null, null); }
function View_DialogBillingProfileDeleteComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["You will be sent a manual renewal notice on ", " "])), i0.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), _co.planExpiry, "longDate")); _ck(_v, 1, 0, currVal_0); }); }
function View_DialogBillingProfileDeleteComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" This will delete your billing profile. "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogBillingProfileDeleteComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.planExpiry; _ck(_v, 3, 0, currVal_0); }, null); }
function View_DialogBillingProfileDeleteComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-spin fa-spinner"]], null, null, null, null, null))], null, null); }
function View_DialogBillingProfileDeleteComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Billing profile"])), (_l()(), i0.ɵeld(7, 0, null, null, 8, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "sync-error", [], null, null, null, i2.View_ErrorComponent_0, i2.RenderType_ErrorComponent)), i0.ɵdid(9, 114688, null, 0, i3.ErrorComponent, [i4.UserService, i5.NgbModal, i6.DomSanitizer], { errcode: [0, "errcode"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 4, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogBillingProfileDeleteComponent_1)), i0.ɵdid(13, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogBillingProfileDeleteComponent_2)), i0.ɵdid(15, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(16, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 3, "button", [["class", "btn btn-default pull-left"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeProfile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogBillingProfileDeleteComponent_4)), i0.ɵdid(19, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, [" Remove profile "])), (_l()(), i0.ɵeld(21, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errcode; _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 13, 0, currVal_1); var currVal_2 = !_co.loading; _ck(_v, 15, 0, currVal_2); var currVal_4 = _co.spinner; _ck(_v, 19, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.spinner; _ck(_v, 17, 0, currVal_3); }); }
exports.View_DialogBillingProfileDeleteComponent_0 = View_DialogBillingProfileDeleteComponent_0;
function View_DialogBillingProfileDeleteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-dialog-billing-profile-delete", [], null, null, null, View_DialogBillingProfileDeleteComponent_0, RenderType_DialogBillingProfileDeleteComponent)), i0.ɵdid(1, 114688, null, 0, i7.DialogBillingProfileDeleteComponent, [i5.NgbActiveModal, i8.ApiService, i9.LoggerService, i10.ZuoraAccountService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DialogBillingProfileDeleteComponent_Host_0 = View_DialogBillingProfileDeleteComponent_Host_0;
var DialogBillingProfileDeleteComponentNgFactory = i0.ɵccf("sync-dialog-billing-profile-delete", i7.DialogBillingProfileDeleteComponent, View_DialogBillingProfileDeleteComponent_Host_0, {}, {}, []);
exports.DialogBillingProfileDeleteComponentNgFactory = DialogBillingProfileDeleteComponentNgFactory;
