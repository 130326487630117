"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CoreActions = require("../actions/core.actions");
exports.initialState = {
    access_secret: '',
    access_token: '',
    meta_key: '',
    private_key: '',
    session_password: '',
    multiadmin_jwt_token: ''
};
function reducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case CoreActions.CORE_SAVE_VALUE: {
            // console.log('NGRX: save ' + action.payload.key + ' = ' + action.payload.value)
            var obj = {};
            obj[action.payload.key] = action.payload.value;
            return Object.assign({}, state, obj);
        }
        case CoreActions.CORE_CLEAR:
            state = exports.initialState;
            break;
        default:
            break;
    }
    return state;
}
exports.reducer = reducer;
exports.getMeta = function (state) { return state.meta_key; };
exports.getPK = function (state) { return state.private_key; };
