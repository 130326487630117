"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var MigrateStatusApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(MigrateStatusApiOutput, _super);
    function MigrateStatusApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return MigrateStatusApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.MigrateStatusApiOutput = MigrateStatusApiOutput;
var MigrateStatusApiInput = /** @class */ (function (_super) {
    tslib_1.__extends(MigrateStatusApiInput, _super);
    function MigrateStatusApiInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return MigrateStatusApiInput;
}(base_api_model_1.BaseApiInput));
exports.MigrateStatusApiInput = MigrateStatusApiInput;
