"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_contact_support_component_1 = require("../../../account/dialog-contact-support/dialog-contact-support.component");
var user_service_1 = require("../../../core/user.service");
var platform_browser_1 = require("@angular/platform-browser");
var models_1 = require("../../models");
var ErrorComponent = /** @class */ (function () {
    function ErrorComponent(userService, modalService, sanitizer) {
        this.userService = userService;
        this.modalService = modalService;
        this.sanitizer = sanitizer;
        this.modalOpen = false;
    }
    Object.defineProperty(ErrorComponent.prototype, "errcode", {
        get: function () {
            return this._errCode;
        },
        set: function (errcode) {
            if (errcode && errcode.code) {
                if (models_1.ErrorMessages[errcode.code]) {
                    // console.log('setting to new errcode msg ' + this.appConfig.errors[errcode.code])
                    errcode.msg = this.sanitizer.bypassSecurityTrustHtml(models_1.ErrorMessages[errcode.code]);
                }
            }
            this._errCode = errcode;
        },
        enumerable: true,
        configurable: true
    });
    ErrorComponent.prototype.ngOnInit = function () {
        this.modalOpen = document.body.classList.contains('modal-open');
        this.user = this.userService.getUser();
    };
    ErrorComponent.prototype.openContactSupport = function () {
        var ref = this.modalService.open(dialog_contact_support_component_1.DialogContactSupportComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
    };
    ErrorComponent.prototype.closeAlert = function () {
        this._errCode = null;
    };
    return ErrorComponent;
}());
exports.ErrorComponent = ErrorComponent;
