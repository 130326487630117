"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* tslint:disable:no-bitwise */
var WebPath = /** @class */ (function () {
    function WebPath(params) {
        this.blob_id = 0;
        this.sync_id = 0;
        this.pid = 0;
        this.share_id = 0;
        this.cachekey = '';
        this.size = 0;
        this.is_deleted = 0;
        this.has_thumb1 = 0;
        this.has_thumb2 = 0;
        this.synctype = 0;
        this.date = 0;
        this.usertime = 0;
        this.enc_name = '';
        this.enc_share_name = '';
        this.link_cachekey = '';
        this.share_key_id = ''; // [100001-1] or [shareid-sharesequence];
        this.context = '';
        this.cssclass = '';
        // derived values
        this.search_name = '';
        this.name = '';
        this.imgSrc = '';
        this.type = '';
        this.sortName = '';
        this.is_dir = 0;
        this.kind = '';
        this.imgsrc = '';
        this.is_readonly = 0;
        this.is_shared = 0;
        this.is_share_root = 0;
        this.is_publink = 0;
        if (params) {
            return this.deserialize(params);
        }
    }
    WebPath.prototype.deserialize = function (params) {
        for (var _i = 0, _a = Object.entries(params); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], val = _b[1];
            if (key in this) {
                this[key] = val;
            }
        }
        this.type = ((this.synctype & 1) === 1) ? 'dir' : 'file';
        this.is_dir = ((this.synctype & 1) === 1) ? 1 : 0;
        this.is_shared = ((this.synctype & 16) === 16) ? 1 : 0;
        this.is_readonly = !((this.synctype & 2) === 2) ? 1 : 0;
        this.is_share_root = (((this.synctype & 128) === 128) && this.is_shared) ? 1 : 0;
        this.is_publink = ((this.synctype & 32) === 32) ? 1 : 0;
        if (this.type == 'dir') {
            this.cssclass = (this.is_deleted)
                ? 'dir-deleted'
                : (this.is_share_root) ? 'dir-sync' : 'dir';
            this.kind = (this.is_deleted)
                ? 'deleted folder'
                : (this.is_share_root) ? 'shared folder' : 'folder';
        }
        else if (this.type == 'file') {
            this.kind = (this.is_deleted) ? 'deleted file' : this.filetype.kind;
            this.cssclass = this.filetype.cssclass;
        }
        this.imgsrc = 'images/icons/' + this.cssclass + '.svg';
        return this;
    };
    WebPath.CTX_FILES = 'files';
    WebPath.CTX_LINKS = 'links';
    return WebPath;
}());
exports.WebPath = WebPath;
