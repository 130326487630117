"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base_api_model_1 = require("./base-api.model");
var ProvisionApiOutput = /** @class */ (function (_super) {
    tslib_1.__extends(ProvisionApiOutput, _super);
    function ProvisionApiOutput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ProvisionApiOutput;
}(base_api_model_1.BaseApiOutput));
exports.ProvisionApiOutput = ProvisionApiOutput;
var ProvisionApiInput = /** @class */ (function () {
    function ProvisionApiInput() {
    }
    return ProvisionApiInput;
}());
exports.ProvisionApiInput = ProvisionApiInput;
