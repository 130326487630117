"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./avatar.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./avatar.component");
var i4 = require("../../../core/logger.service");
var styles_AvatarComponent = [i0.styles];
var RenderType_AvatarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AvatarComponent, data: {} });
exports.RenderType_AvatarComponent = RenderType_AvatarComponent;
function View_AvatarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "avatar-overflow"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("+" + _co.overflowCount); _ck(_v, 1, 0, currVal_0); }); }
function View_AvatarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "avatar-initial"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.initial; _ck(_v, 1, 0, currVal_0); }); }
function View_AvatarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "avatar"], ["class", "img-circle display-avatar-setting"]], [[8, "src", 4]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.handleMissingImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.avatarUrl; _ck(_v, 0, 0, currVal_0); }); }
function View_AvatarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "disabled-effect"]], null, null, null, null, null))], null, null); }
function View_AvatarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "avatar-container"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvatarComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvatarComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvatarComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "hover-effect"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvatarComponent_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "avatar-container"; var currVal_1 = ("avatar-" + _co.avatarIndex); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.overflowCount; _ck(_v, 3, 0, currVal_2); var currVal_3 = (_co.initial && !_co.avatarUrl); _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.avatarUrl; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.disabled; _ck(_v, 10, 0, currVal_5); }, null); }
exports.View_AvatarComponent_0 = View_AvatarComponent_0;
function View_AvatarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sync-avatar", [], null, null, null, View_AvatarComponent_0, RenderType_AvatarComponent)), i1.ɵdid(1, 114688, null, 0, i3.AvatarComponent, [i4.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AvatarComponent_Host_0 = View_AvatarComponent_Host_0;
var AvatarComponentNgFactory = i1.ɵccf("sync-avatar", i3.AvatarComponent, View_AvatarComponent_Host_0, { user: "user", avatarIndex: "avatarIndex", overflowCount: "overflowCount", disabled: "disabled" }, {}, []);
exports.AvatarComponentNgFactory = AvatarComponentNgFactory;
