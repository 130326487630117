"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var api_service_1 = require("../../core/api.service");
var browser_support_service_1 = require("../../core/browser-support.service");
var logger_service_1 = require("../../core/logger.service");
var link_file_list_service_1 = require("../../link-consume/services/link-file-list.service");
var func_1 = require("../../shared/func");
var models_1 = require("../../shared/models");
var broadcast_service_1 = require("../../shared/services/broadcast.service");
var build_transfer_item_service_1 = require("../../transfer/services/build-transfer-item.service");
var transfer_model_1 = require("../../transfer/transfer.model");
var transfer_service_1 = require("../../transfer/transfer.service");
var blend_service_1 = require("../../shared/services/blend.service");
var PreviewImageComponent = /** @class */ (function () {
    function PreviewImageComponent(BrowserSupport, Logger, transferService, buildTransferItemService, LinkPathList, sanitizer, api, broadcastService, blendService) {
        var _this = this;
        this.BrowserSupport = BrowserSupport;
        this.Logger = Logger;
        this.transferService = transferService;
        this.buildTransferItemService = buildTransferItemService;
        this.LinkPathList = LinkPathList;
        this.sanitizer = sanitizer;
        this.api = api;
        this.broadcastService = broadcastService;
        this.blendService = blendService;
        this.items = {
            idx: 1,
            amount: 0,
            current: null,
            nextItem: null,
            prevItem: null,
        };
        this.btFile = { renderFile: { dl_ready: false } };
        this.btFileSpinner = false;
        this.fullScreen = false;
        this.spinner = false;
        this.initialized = false;
        this.onCommentToggle = function (isCommentExpanded) {
            _this.isCommentExpanded = isCommentExpanded;
        };
    }
    PreviewImageComponent.prototype.bypassSecurityTrustUrl = function () {
        if (this.transferView.single.renderFile.url) {
            this.imageURL = this.sanitizer.bypassSecurityTrustUrl(this.transferView.single.renderFile.url);
        }
        if (this.item.compaturl) {
            this.imageURL = this.sanitizer.bypassSecurityTrustUrl(this.item.compaturl);
        }
    };
    PreviewImageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.transferView = this.buildTransferItemService.view;
        this.canSave = this.BrowserSupport.getFileSaveMethod() != null;
        this.errorMsg = '';
        this.broadcastService.on('event:keydown:37').subscribe(function () { return _this.navigatePrev(); });
        this.broadcastService.on('event:keydown:39').subscribe(function () { return _this.navigateNext(); });
        this.sub = this.LinkPathList.getSubscription().subscribe(function (data) {
            if (data.loaded && data.sorted) {
                _this.allowComment = data.allow_comment;
            }
        });
        this.blendService.track(models_1.BlendEvent.VIEW_FILE, {
            type: 'image',
            preview_preference: 'N/A',
            fileSize: this.item.filesize,
            mimeType: this.item.mime_type
        });
        this.initialize();
    };
    PreviewImageComponent.prototype.ngOnDestroy = function () {
        this.buildTransferItemService.view.single = undefined;
        this.initialized = false;
        this.transferItem = undefined;
        if (this.sub) {
            this.sub.unsubscribe();
        }
    };
    PreviewImageComponent.prototype.initialize = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.initialized = false;
                this.transferItem = undefined;
                this.navigate(this.item);
                return [2 /*return*/];
            });
        });
    };
    PreviewImageComponent.prototype.isOpenable = function () {
        var openable = false;
        switch (func_1.getFileExt(this.items.current.name)) {
            case 'pdf':
            case 'txt':
            case 'md':
            case 'jpg':
            case 'gif':
            case 'png':
                openable = true;
                break;
            default:
                openable = false;
        }
        return openable;
    };
    PreviewImageComponent.prototype.open = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var link, compatUrl, tItem;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.items.current.compat && this.items.current.context == 'link')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.buildTransferItemService.getCompatUrl(this.items.current, this.buildTransferItemService.ACT_PREVIEW)];
                    case 1:
                        compatUrl = _a.sent();
                        link = compatUrl;
                        return [3 /*break*/, 5];
                    case 2:
                        if (!(this.items.current.compat &&
                            this.items.current.context == 'applink')) return [3 /*break*/, 3];
                        link = this.items.current.compaturl;
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.transferService.getFile(this.items.current, true)];
                    case 4:
                        tItem = _a.sent();
                        link = tItem.renderFile.url;
                        _a.label = 5;
                    case 5:
                        window.open(link, '_blank');
                        return [2 /*return*/];
                }
            });
        });
    };
    PreviewImageComponent.prototype.downloadOriginal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.item.blobtype = 'btFILE';
                        if (!(this.item.compat && this.item.context == 'link')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.buildTransferItemService.getCompatUrl(this.item, this.buildTransferItemService.ACT_DOWNLOAD)];
                    case 1:
                        url = _a.sent();
                        window.open(url, '_blank');
                        return [3 /*break*/, 3];
                    case 2:
                        if (this.item.context == 'link') {
                            this.transferService.queueDownload([this.item]);
                        }
                        else if (this.item.compat && this.item.context == 'applink') {
                            window.open(this.item.compaturl_dl, '_blank');
                        }
                        else {
                            this.transferService.queueDownload([this.item]);
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PreviewImageComponent.prototype.watch = function (pathitem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tItem, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.transferItem = undefined;
                        this.initialized = false;
                        if (!pathitem) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.btFile = { renderFile: { dl_ready: false } };
                        pathitem.blobtype =
                            pathitem.has_thumb2 && pathitem.size > 100 * 1024
                                ? 'btTHUMB2'
                                : 'btFILE';
                        return [4 /*yield*/, this.transferService.getFile(pathitem, true)];
                    case 2:
                        tItem = _a.sent();
                        // GetFile for appLink doesn't properly set this.Transferview.single for URL in open.
                        if (tItem.status == transfer_model_1.TransferStatus.STATUS_ERR_SIZE ||
                            tItem.status == transfer_model_1.TransferStatus.STATUS_ERR_OPEN) {
                            // Logger.error('Cannot view file');
                            this.errorMsg = 'Unable to view this file.';
                            this.transferView.cannotViewSave = true;
                        }
                        if (tItem.linkID) {
                            this.Logger.info('Consume link');
                            this.api.execute('linkconsume', {
                                links: [{ publink_id: tItem.linkID }],
                            });
                        }
                        this.initialized = true;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(err_1);
                        this.transferView.cannotViewSave = true;
                        if (err_1.errors) {
                            this.errorMsg = err_1.errors[0].error_msg;
                        }
                        else {
                            this.errorMsg = 'Unable to view this file.';
                        }
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this.initialized = true;
                        this.item = undefined;
                        this.btFileSpinner = false;
                        this.btFile = { renderFile: { dl_ready: false } };
                        this.errorMsg = 'Invalid file provided';
                        _a.label = 6;
                    case 6:
                        this.bypassSecurityTrustUrl();
                        return [2 /*return*/];
                }
            });
        });
    };
    PreviewImageComponent.prototype.navigate = function (item) {
        this.items.current = item;
        this.watch(item);
        this.getNextPrevItems(item);
    };
    PreviewImageComponent.prototype.navigateNext = function () {
        if (this.items.nextItem) {
            this.navigate(this.items.nextItem);
        }
    };
    PreviewImageComponent.prototype.navigatePrev = function () {
        if (this.items.prevItem) {
            this.navigate(this.items.prevItem);
        }
    };
    PreviewImageComponent.prototype.getNextPrevItems = function (curItem) {
        this.items.amount = this.list.length;
        for (var i = 0; i < this.items.amount; i++) {
            if (this.list[i].sync_id == curItem.sync_id) {
                this.items.idx = i + 1;
                this.items.prevItem = this.list[i - 1]
                    ? this.list[i - 1]
                    : undefined;
                this.items.nextItem = this.list[i + 1]
                    ? this.list[i + 1]
                    : undefined;
                break;
            }
        }
    };
    return PreviewImageComponent;
}());
exports.PreviewImageComponent = PreviewImageComponent;
