"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var file_selection_service_1 = require("../file-list/services/file-selection.service");
var transfer_service_1 = require("./transfer.service");
/**
 * @description
 * Queues the selected files from the PathList factory.
 *
 * This only works in the main Sync of Vault folders.  Link downloads happen
 * inline, so are not queued via this directive
 */
var QueuePathDownloadDirective = /** @class */ (function () {
    function QueuePathDownloadDirective(fileSelectionService, transferService) {
        this.fileSelectionService = fileSelectionService;
        this.transferService = transferService;
    }
    QueuePathDownloadDirective.prototype.click = function (event) {
        var clicks = 0;
        clicks++;
        if (clicks === 1) {
            this.transferService
                .queueDownload(this.fileSelectionService.getSelected())
                .then(function () {
                clicks = 0;
            })
                .catch(function (error) {
                clicks = 0;
                console.error(error);
            });
        }
    };
    return QueuePathDownloadDirective;
}());
exports.QueuePathDownloadDirective = QueuePathDownloadDirective;
