"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var batch_purge_service_1 = require("../batch-purge.service");
var feedback_service_1 = require("../../notifications/feedback.service");
var DialogPurgeComponent = /** @class */ (function () {
    function DialogPurgeComponent(batchPurgeService, activeModal, feedbackService) {
        this.batchPurgeService = batchPurgeService;
        this.activeModal = activeModal;
        this.feedbackService = feedbackService;
        this.selected = [];
    }
    DialogPurgeComponent.prototype.ngOnInit = function () {
        this.batchPurgeService.init();
        this.batchView = this.batchPurgeService.view;
        this.dlgView = {
            itemlist: this.selected,
            action: 'purge',
            title: 'Purge items',
            btnExecuteText: 'Purge',
            btnExecuteClass: 'btn btn-danger',
            confirm: {
                check: false,
                show: true,
                notices: [],
                txt: ' I understand I am permanently deleting these items.',
            },
        };
    };
    DialogPurgeComponent.prototype.getClass = function (syncID) {
        return this.batchPurgeService.isCompleted(syncID) ? 'purged' : 'deleted';
    };
    DialogPurgeComponent.prototype.closeDialog = function () {
        if (this.batchPurgeService.view.spinner !== 0) {
            var c = window.confirm('Are you sure you want to cancel?');
            if (c) {
                this.batchPurgeService.cancel();
                this.activeModal.close();
            }
        }
        else {
            this.activeModal.close();
        }
    };
    DialogPurgeComponent.prototype.removeFromList = function (listitem) {
        for (var i = this.dlgView.itemlist.length - 1; i >= 0; i--) {
            if (this.dlgView.itemlist[i].sync_id == listitem.sync_id) {
                this.dlgView.itemlist.splice(i, 1);
                break;
            }
        }
    };
    DialogPurgeComponent.prototype.execute = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sync_id_array, files, directories, i, element, err_1, errorMsg;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.dlgView.confirm.show && !this.dlgView.confirm.check) {
                            this.dlgView.confirm.notices = [
                                {
                                    type: 'warning',
                                    msg: 'You must confirm this change before clicking purge.',
                                },
                            ];
                            return [2 /*return*/];
                        }
                        sync_id_array = [];
                        files = 0, directories = 0;
                        for (i = this.dlgView.itemlist.length - 1; i >= 0; i--) {
                            element = this.dlgView.itemlist[i];
                            sync_id_array.push(element.sync_id);
                            if (element.type && element.type.toUpperCase() === 'DIR') {
                                directories++;
                            }
                            else {
                                files++;
                            }
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.batchPurgeService.execute(sync_id_array).toPromise()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _a.sent();
                        if (err_1.code) {
                            this.batchView.errors.push(err_1);
                        }
                        this.batchView.spinner = 0;
                        errorMsg = this.dlgView.itemlist.length + " file" + (files > 1 ? 's' : '') + " failed to purge";
                        if (directories && files) {
                            errorMsg = this.dlgView.itemlist.length + " items failed to purge";
                        }
                        else if (directories) {
                            errorMsg = this.dlgView.itemlist.length + " folder" + (directories > 1 ? 's' : '') + " failed to purge";
                        }
                        this.feedbackService.setFeedback('danger', errorMsg, 10000);
                        return [3 /*break*/, 5];
                    case 4:
                        this.activeModal.close();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    DialogPurgeComponent.prototype.confirmAction = function () {
        this.dlgView.confirm.notices = [];
        return;
    };
    return DialogPurgeComponent;
}());
exports.DialogPurgeComponent = DialogPurgeComponent;
