"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var base64_service_1 = require("../base64.service");
var logger_service_1 = require("../logger.service");
var browser_support_service_1 = require("../browser-support.service");
var i0 = require("@angular/core");
var i1 = require("../base64.service");
var i2 = require("../browser-support.service");
var i3 = require("../logger.service");
var StorageMemoryService = /** @class */ (function () {
    function StorageMemoryService(base64, browser, log) {
        this.base64 = base64;
        this.browser = browser;
        this.log = log;
        this.maxsize = 1024 * 1024 * 500;
        this.CONTAINER_BLOB = 1;
        this.CONTAINER_ARRAY = 2;
        this.options = {};
        this.blobObj = {};
        this.dataObj = {};
    }
    StorageMemoryService.prototype.init = function (initOpts) {
        var start = Date.now();
        var key = initOpts.blobtype + '-' + initOpts.cachekey;
        this.options[key] = initOpts;
        if (this.browser.testBlob()) {
            if (this.blobObj[key]) {
                this.blobObj[key] = undefined;
                this.blobObj[key] = [];
            }
            else {
                this.getBlobObj(key);
            }
        }
        else {
            if (this.dataObj[key]) {
                this.dataObj[key] = undefined;
                this.dataObj[key] = [];
            }
            else {
                this.getDataObj(key);
            }
        }
        if (this.options[key].filesize > this.maxsize) {
            return Promise.reject({ errcode: 3020 });
        }
        else {
            this.log.d('  - ' + (Date.now() - start) + ' ms to initialize');
            return Promise.resolve(1);
        }
    };
    StorageMemoryService.prototype.writeChunk = function (tItem, bytearray, offset) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var key, decStart, curBlob, blob, curData;
            return tslib_1.__generator(this, function (_a) {
                key = tItem.blobtype + '-' + tItem.cachekey;
                decStart = Date.now();
                if (this.browser.testBlob()) {
                    curBlob = this.getBlobObj(key);
                    blob = void 0;
                    if (typeof bytearray === 'object' && bytearray instanceof Uint8Array) {
                        blob = new Blob([bytearray], { type: 'application/octet-binary' });
                    }
                    else {
                        blob = new Blob(bytearray, { type: 'application/octet-binary' });
                    }
                    curBlob.push(blob);
                }
                else {
                    curData = this.getDataObj(key);
                    curData = curData.concat(bytearray);
                }
                this.log.d('  - ' + (Date.now() - decStart) + ' ms to write data');
                return [2 /*return*/, Promise.resolve(1)];
            });
        });
    };
    StorageMemoryService.prototype.getRenderData = function (tItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, decStart, key, opt, cur, blob, cur, b64;
            return tslib_1.__generator(this, function (_a) {
                data = {
                    type: '',
                    url: '',
                    blob: null,
                    b64data: ''
                };
                decStart = Date.now();
                key = tItem.blobtype + "-" + tItem.cachekey, opt = this.options[key];
                if (this.browser.testBlob()) {
                    cur = this.getBlobObj(key), blob = new Blob(cur, { type: this.options[key].mimetype });
                    data.type = 'blob';
                    data.url = URL.createObjectURL(blob);
                    data.blob = blob;
                    if (blob.size != opt.filesize && tItem.blobtype == 'btFILE') {
                        this.log.warn("Decrypted blobsize " + blob.size + " is not equal to " + opt.filesize);
                    }
                }
                else {
                    cur = this.getDataObj(key), b64 = this.base64.encodeBits(cur);
                    data.type = 'b64';
                    data.url = [
                        'data:',
                        opt.mimetype,
                        ';base64,',
                        b64
                    ].join('');
                    data.b64data = b64;
                }
                this.log.d('  - ' + (Date.now() - decStart) + ' ms to getRenderData()');
                return [2 /*return*/, Promise.resolve(data)];
            });
        });
    };
    StorageMemoryService.prototype.exists = function (tItem) {
        return Promise.reject(tItem);
    };
    StorageMemoryService.prototype.clearSpace = function (type) {
        return Promise.resolve(type);
    };
    StorageMemoryService.prototype.getBlobObj = function (key) {
        if (this.blobObj[key] == undefined) {
            this.blobObj[key] = [];
        }
        return this.blobObj[key];
    };
    StorageMemoryService.prototype.getDataObj = function (key) {
        if (this.dataObj[key] == undefined) {
            this.dataObj[key] = [];
        }
        return this.dataObj[key];
    };
    StorageMemoryService.ngInjectableDef = i0.defineInjectable({ factory: function StorageMemoryService_Factory() { return new StorageMemoryService(i0.inject(i1.Base64Service), i0.inject(i2.BrowserSupportService), i0.inject(i3.LoggerService)); }, token: StorageMemoryService, providedIn: "root" });
    return StorageMemoryService;
}());
exports.StorageMemoryService = StorageMemoryService;
