"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var api_service_1 = require("../../core/api.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var user_service_1 = require("../../core/user.service");
var platform_browser_1 = require("@angular/platform-browser");
var auth_service_1 = require("../../auth/services/auth.service");
var logger_service_1 = require("../../core/logger.service");
var DialogTwofactorComponent = /** @class */ (function () {
    function DialogTwofactorComponent(activeModal, api, userService, sanitizer, auth, log) {
        this.activeModal = activeModal;
        this.api = api;
        this.userService = userService;
        this.sanitizer = sanitizer;
        this.auth = auth;
        this.log = log;
        this.TWOFATYPE_NONE = '0';
        this.TWOFATYPE_EMAIL = '1';
        this.TWOFATYPE_GOOGLE = '2';
        this.isGoogleAuth2faDisabled = false;
    }
    DialogTwofactorComponent.prototype.ngOnInit = function () {
        this.showBackupCodeDialog = false;
        this.backupCodesString = '';
        this.twofaType = this.user.twofa_type;
        this.isGoogleAuth2faDisabled = this.user.is_google_app_2fa_disabled === 1;
    };
    DialogTwofactorComponent.prototype.disable2FA = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.execute('twofactorupdate', {
                                twofaType: this.twofaType,
                                disable: 1
                            })];
                    case 1:
                        _a.sent();
                        this.userService.refresh();
                        this.activeModal.close();
                        return [3 /*break*/, 3];
                    case 2:
                        ex_1 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DialogTwofactorComponent.prototype.update2FA = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, ex_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('two-factor type:', this.twofaType);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.execute('twofactorupdate', {
                                twofaType: this.twofaType,
                                disable: 0
                            })];
                    case 2:
                        response = _a.sent();
                        this.twofaType = response['twofaType'];
                        this.generateBackupCodes();
                        return [3 /*break*/, 4];
                    case 3:
                        ex_2 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * call api for generating 2FA backup codes and set in-browser backup code
     */
    DialogTwofactorComponent.prototype.generateBackupCodes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var hashedPass, _a, response, ex_3;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, , 6]);
                        this.spinner = true;
                        this.log.info('calling api to get backup codes');
                        if (!(this.password)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.auth.getHashedPassword(this.user.email, this.password)];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        _a = '';
                        _b.label = 3;
                    case 3:
                        hashedPass = _a;
                        return [4 /*yield*/, this.api.execute('generateuserbackupcode', { password: hashedPass })];
                    case 4:
                        response = _b.sent();
                        //show download dialog for user to get the codes
                        this.backupCodes = response['codes'];
                        this.showBackupCodeDialog = true;
                        this.backupCodes.forEach(function (code, idx) {
                            _this.backupCodesString += code;
                            if (idx != _this.backupCodes.length - 1) {
                                _this.backupCodesString += '\n';
                            }
                        });
                        // this.userService.reload();
                        this.userService.refresh();
                        return [3 /*break*/, 6];
                    case 5:
                        ex_3 = _b.sent();
                        this.log.error(ex_3);
                        this.errcode = models_1.ErrCode.fromException(ex_3);
                        return [3 /*break*/, 6];
                    case 6:
                        this.spinner = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * create a downloadable file containing backup codes
     */
    DialogTwofactorComponent.prototype.downloadBackupCodes = function () {
        this.log.info('Creating download file');
        var text = 'Sync.com\'s back-up codes for 2-step verification:\n';
        var fileName = 'backup_codes';
        text += this.backupCodesString;
        var data = new Blob([text], {
            type: 'text/plain'
        });
        this.backupCodeFile = window.URL.createObjectURL(data);
        this.safeBackupCodeLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.backupCodeFile);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //save file for IE
            window.navigator.msSaveOrOpenBlob(data, fileName);
        }
        else {
            //save for other browsers: Chrome, Firefox
            var a = document.createElement('a');
            a.href = this.backupCodeFile;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        URL.revokeObjectURL(this.backupCodeFile);
        document.getElementById('downloadCodes').disabled = true;
        // this.activeModal.close(true);
    };
    DialogTwofactorComponent.prototype.updateGoogleAuth = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, ex_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.execute('twofactorgoogleauth', {
                                twofa_token: this.twofakey,
                                verification_code: this.verificationCode
                            })];
                    case 1:
                        response = _a.sent();
                        this.showGoogleAuthForm = false;
                        this.twofaType = response['twofaType'];
                        this.generateBackupCodes();
                        return [3 /*break*/, 3];
                    case 2:
                        ex_4 = _a.sent();
                        this.log.error(ex_4);
                        this.errcode = models_1.ErrCode.fromException(ex_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DialogTwofactorComponent.prototype.showGoogleAuth = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.execute('twofactorpubkeycreate', {})];
                    case 1:
                        data = _a.sent();
                        // console.log('showGoogleAuth() return '  + data.twofakey);
                        this.showGoogleAuthForm = true;
                        this.twofakey = data.twofakey;
                        this.qrdata = 'otpauth://totp/Sync' + '%20' + this.user.email + '?secret=' + data.twofakey + '&issuer=Sync';
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * copy backup codes to clipboard
     *
     */
    DialogTwofactorComponent.prototype.copyCodesToClipboard = function () {
        var selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.backupCodesString;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        var btnText = document.getElementById('clipboard').innerText;
        if (btnText == 'Copy to Clipboard') {
            document.getElementById('clipboard').innerText = 'Copied';
            document.getElementById('clipboard').disabled = true;
        }
        else {
            document.getElementById('clipboard').innerText = 'Copy to Clipboard';
        }
    };
    return DialogTwofactorComponent;
}());
exports.DialogTwofactorComponent = DialogTwofactorComponent;
